import * as React from 'react';
import {useEffect, useState} from 'react';
// import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {isMobileOnly, isTablet} from 'react-device-detect';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import {Typography, useMediaQuery} from '@material-ui/core';

// import Pagination from '@material-ui/lab/Pagination';
// import {MasterCLassesCategoryCard} from '../daily-content/MasterClassesCategoryCard';
// import {DailyContentDetailsView} from '../daily-content/DailyContentDetailsView';
// import {ContentDialog} from '../dialog/ContentDialog';
// import Loader from '../loader/Loader';
import {
  // DEFAULT_PAGE_START,
  // DEFAULT_PER_PAGE_SIZE,
  // GeneralPaths,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';
import {containsParamInHistory} from '../../../utils/qs-helper';
// import {selectAllDailyContentCategoriesForMasterClasses} from '../../../store/selectors/nourish.selectors';
// import {IDailyContentCategoryDto} from '../../../dtos/IDailyContentCategoryDto';

import useStyles from '../daily-content/daily-content-view.styles';

import {ContentDialog} from '../dialog/ContentDialog';
import {MindfulnessDetailsView} from './MindffulnessDetailView';

interface Props {
  dailyContents: any;
  title: any;
  page: number;
  // count: number;
  // onChangePage: (event: any, page: number) => void;
  // onChangeFilter: (filter: any) => void;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/*eslint-disable @typescript-eslint/no-empty-function*/
export const MindfulnessView: React.FC<Props> = ({dailyContents, title}) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE - 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );

  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);

  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;

  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const history = useHistory();
  // const [slug, setSlug] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  // const [pageScroll, setPageScroll] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<any>([]);
  const [t] = useTranslation();
  // const dailyContentCategories = useSelector(selectAllDailyContentCategoriesForMasterClasses);
  // const totalPagesCount = Math.floor((count + DEFAULT_PER_PAGE_SIZE - 1) / DEFAULT_PER_PAGE_SIZE);

  useEffect(() => {
    if (containsParamInHistory(history, '_slug')) {
      //const params = getHistoryParams(history);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      // handleOnCardClick(params?._slug, true);
    }
  }, []);

  const handleCardClick = (url: string) => {
    setVideoUrl(url);
    setOpen(true);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOnCardClick = (slug: string, deepLinking = false) => {
  //   setSlug(slug!);
  //   setOpen(true);
  //   if (!deepLinking) {
  //     history.replace({
  //       pathname: GeneralPaths.NourishPage,
  //       search: `?type-slug=${NourishType.BETR_PROGRAM_MODE.toString()}&_slug=${slug}`,
  //     });
  //   }
  // };

  // const handleOnCategoryCardClick = (dailyContentCategory: IDailyContentCategoryDto) => {
  //   const filters = {
  //     dailyCategory: dailyContentCategory,
  //   };
  //   onChangeFilter(filters);
  // };
  const handleDismiss = () => {
    setOpen(false);
    // setSlug('');
    // history.replace({
    //   pathname: GeneralPaths.NourishPage,
    //   search: ``,
    // });
  };

  // const renderDailyContent = (array: any[]) =>
  //   array.map((dailyContent: any) => (
  //     <DailyContentCard
  //       dailyContent={dailyContent}
  //       key={dailyContent.id}
  //       // onClick={handleOnCardClick}
  //       onClick={() => {}}
  //       isMobileOrTablet={isMobileOrTablet}
  //       smallScreens={smallScreens}
  //     />
  //   ));

  // const renderDailyContentCategories = () =>
  //   dailyContentCategories.map((dailyContentCategory: IDailyContentCategoryDto) => (
  //     <MasterCLassesCategoryCard
  //       dailyContentCategory={dailyContentCategory}
  //       //onClick={() => handleOnCategoryCardClick(dailyContentCategory)}
  //       onClick={() => {}}
  //       isMobileOrTablet={isMobileOrTablet}
  //       smallScreens={smallScreens}
  //     />
  //   ));

  // useEffect(() => {
  //   setAllDailyContents([]);
  //   setPageScroll(0);
  // }, []);

  // useEffect(() => {

  //   if (smallScreens) {
  //     setAllDailyContents(allDailyContents.concat(dailyContents));
  //   }
  // }, [dailyContents]);

  // const fetchMoreData = () => {
  //   onChangePage('', pageScroll);
  //   setPageScroll(pageScroll + 1);
  // };

  return (
    <div id={'scrollableDiv'} className={classes.mainContainer}>
      <div id={'scrollableDiv'} className={classes.mainContainer}>
        <>
          {dailyContents?.length ? (
            <>
              <Typography variant={'h6'} align="left">
                {title || t('NourishPage.mindfulness.title')}
              </Typography>
              <>
                {dailyContents.map((data: any, index: number) => {
                  return (
                    <>
                      <ContentDialog
                        fullScreen
                        slideUp
                        handleDismiss={handleDismiss}
                        dialogState={open}
                        closeIconClass={classes.closeIconRecipesClass}
                        isCloseButtonHidden={smallScreens}
                        // className={classes.dialogMargin}
                        // closeIconClass={classes.dialogClose}
                        content={
                          <MindfulnessDetailsView
                            isMobileOrTablet={isMobileOrTablet}
                            smallScreens={smallScreens}
                            videoUrl={videoUrl}
                          />
                        }
                      />

                      <div
                        // id={'card' + card.id}
                        // className={cssClass ? classNames(classes.root, cssClass) : classes.root}
                        onClick={() => handleCardClick(data?.media?.url)}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={index}
                      >
                        <img
                          src={data?.thumbnail?.image.url}
                          width={184}
                          height={148}
                          alt={data?.thumbnail.image.name}
                        />
                        <Typography className={classes.headline} variant={'subtitle2'}>
                          {data?.title}
                        </Typography>
                      </div>
                    </>
                  );
                })}
              </>
            </>
          ) : (
            <Typography variant="subtitle1">{t('NourishPage.foodListsFilter.noResult')}</Typography>
          )}
        </>
      </div>
    </div>
  );
};
