/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, post, put} from './api-calls';
import {IActivityDto} from '../dtos/IActivityDto';
import {ICasualTag} from '../models/ITag';

export const getActivityTags = (): Promise<ICasualTag[]> => {
  return get(`/activity/tags`);
};

export const getActivityLogs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IActivityDto[]> => {
  return get(`/activity?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addActivityDatabase = (data: IActivityDto): Promise<any> => {
  return post('/activity', data);
};

export const editActivityDatabase = (data: IActivityDto): Promise<any> => {
  return put(`/activity/${data.id}`, data);
};

export const deleteActivityDatabase = (id: number): Promise<any> => {
  return del(`/activity/${id}`);
};

export const getActivityLogById = (id: number): Promise<IActivityDto> => {
  return get(`/activity/${id}`);
};
