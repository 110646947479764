import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
  },
  outlinedBtn: {
    height: '36px !important',
    marginRight: 16,
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  normalTextField: {
    width: 170,
  },
  heightTextField: {
    width: 50,
    marginRight: '8px !important',
  },
  heightTextFieldRight: {
    width: 50,
    marginLeft: 35,
    marginTop: 20,
  },
  editBtn: {
    display: 'none',
  },
  dateBirthContainer: {
    '& .MuiIconButton-root': {
      display: 'none',
    },
    width: '200px !important',
    marginRight: 24,
  },
  genderSelect: {
    width: 170,
    marginRight: 24,
  },
  measurementUnit: {
    position: 'relative',
  },
  measurementUnitChild: {
    position: 'absolute',
    marginTop: 27,
  },
  measurementUnitChildRight: {
    position: 'absolute',
    marginTop: 27,
    marginLeft: 36,
  },
  heightContainer: {
    display: 'flex',
    marginLeft: 24,
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagsCont: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginBottom: 24,
  },
}));

export default useStyles;
