import {ICholesterolDto} from '../dtos/ICholesterolDto';
import {del, get, post, put} from './api-calls';

export const getCholesterolLogs = (id: string, dateTime: string): Promise<ICholesterolDto[]> => {
  return get(`/cholesterol?UserId=${id}&from=${dateTime}`);
};

export const addCholesterolDatabase = (data: ICholesterolDto): Promise<ICholesterolDto> => {
  return post('/cholesterol', data);
};

export const editCholesterolDatabase = (data: ICholesterolDto): Promise<ICholesterolDto> => {
  return put(`/cholesterol/${data.id}`, data);
};

export const deleteCholesterolDatabase = (id: number): Promise<any> => {
  return del(`/cholesterol/${id}`);
};

export const getCholesterolLogById = (id: number): Promise<ICholesterolDto> => {
  return get(`/cholesterol/${id}`);
};

export const getCholesterolDataLogs = (id: string, fromDate: string, toDate: string): Promise<ICholesterolDto[]> => {
  return get(`/cholesterol?UserId=${id}&From=${fromDate}&To=${toDate}`);
};

