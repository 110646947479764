import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}
const useStyles = makeStyles<Theme, Props>(() => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginStress: {
    marginTop: 32,
  },
  select: {
    width: '164px',
    marginRight: '24px',
  },
  tagsContainer: {
    marginTop: 22,
  },
  rangeSlider: {
    marginTop: (props) => (props.isMobile ? 10 : 0),
    marginLeft: (props) => (props.isMobile ? 0 : 45),
    marginRight: (props) => (props.isMobile ? 0 : 45),
  },
}));

export default useStyles;
