import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import ClockIcon from '../../../assets/icons/rest/clock.svg';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {EMPTY_TIME_PLACEHOLDER} from '../../../utils/constants';

import useStyles from './recipe-details-view.styles';

interface Props {
  prepTime: string;
  cookTime: string;
  totalTime: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

export const TimeContainer: React.FC<Props> = ({
  prepTime,
  cookTime,
  totalTime,
  isMobileOrTablet,
  smallScreens,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const [t] = useTranslation();

  return (
    <div className={classes.timeContainer}>
      <div className={classes.timeBox}>
        <Typography className={classes.timeLabel} variant={'caption'} align={'left'}>
          {t('NourishPage.recipesDetails.prepTime')}
        </Typography>
        <div className={classes.timeValue}>
          <img src={ClockIcon} width={16} alt={'clock-time'} />{' '}
          {prepTime?.substring(0, prepTime?.length - 4) || EMPTY_TIME_PLACEHOLDER}
        </div>
      </div>
      <div className={classes.timeBox}>
        <Typography className={classes.timeLabel} variant={'caption'} align={'center'}>
          {t('NourishPage.recipesDetails.cookTime')}
        </Typography>
        <div className={classes.timeValue}>
          <img src={ClockIcon} width={16} alt={'clock-time'} />{' '}
          {cookTime?.substring(0, cookTime?.length - 4) || EMPTY_TIME_PLACEHOLDER}
        </div>
      </div>
      <div className={classes.timeBox}>
        <Typography className={classes.timeLabel} variant={'caption'} align={'right'}>
          {t('NourishPage.recipesDetails.totalTime')}
        </Typography>
        <div className={classes.timeValue}>
          <img src={ClockIcon} width={16} alt={'clock-time'} />{' '}
          {totalTime?.substring(0, totalTime?.length - 4) || EMPTY_TIME_PLACEHOLDER}
        </div>
      </div>
    </div>
  );
};
