import {createReducer} from '../../utils/helpers';
import {
  LOAD_INGREDIENTS_CATEGORIES,
  LOAD_CATEGORIES_WITH_INGREDIENTS,
  LOAD_COURSES,
  LOAD_CUISINES,
  LOAD_LEVEL_CATEGORIES,
  SAVE_RECIPES,
  SAVE_RECIPES_COUNT,
  SAVE_RECIPE,
  LOAD_CATEGORIES,
  SAVE_MEAL_PLANS,
  SAVE_MEAL_PLAN,
  SAVE_RECIPE_FILTERS,
  SAVE_MEAL_PLANS_FILTERS,
  SAVE_RECIPES_TAGS,
  SAVE_SELECETED_MENU_ITEM,
  SAVE_SELECETED_RECIPES_FILTERS,
  SAVE_SELECTABLE_INGREDIENTS,
  SAVE_SELECTED_MEAL_PLANS_FILTERS,
  SAVE_MEAL_PLANS_TAGS,
  LOAD_CARDS,
  LOAD_DAILY_CONTENT_CATEGORIES,
  LOAD_DAILY_CONTENTS,
  SAVE_BETR_PROGRAM_FILTERS,
  LOAD_DAILY_CONTENT,
  SAVE_DAILY_CONTENTS_COUNT,
  REHAB_ENABLED,
  LOAD_MINDFULNESS,
  LOAD_PODCAST,
  LOAD_RIGHT_CARDS,
  MOVEMENT_ENABLED,
  LOAD_YOGA,
  APPOINTMENT_ENABLED,
  SAVE_YOGA
} from '../actions/nourish/nourish-types';
import {
  ILoadIngredientsCategories,
  ILoadCategoriesWIthIngredients,
  ILoadCourses,
  ILoadCuisines,
  ILoadLevelCategories,
  ISaveRecipes,
  ILoadCategories,
  ISaveMealPlans,
  ISaveMealPlan,
  ISaveRecipesCount,
  ISaveRecipeFilters,
  IMealPlansFilters,
  ISaveSelectedMenuItem,
  ISaveSelectedRecipesFilters,
  ISaveRecipesTags,
  ISaveSelectableIngredients,
  ISaveSelectedMealPlansFilters,
  ISaveMealPlansTags,
  ILoadCards,
  ILoadDailyContentCategories,
  ILoadDailyContents,
  ISaveBetrProgramFilters,
  ILoadDailyContent,
  ISaveDailyContentsCount,
  IRehabEnabled,
  ILoadMindfulness,
  IRightLoadCards,
  ILoadPodCast,
  IMovementEnabled,
  ILoadYoga,
  IAppointmentEnabled,
} from '../actions/nourish/nourish-actions';
import {ICuisineDto} from '../../dtos/ICuisineDto';
import {ICourseDto} from '../../dtos/ICourseDto';
import {ILevelCategoryDto} from '../../dtos/ILevelCategoryDto';
import {ICategoryIngredientsDto, IIngredientDto} from '../../dtos/ICategoryIngredientsDto';
import {ICategoryDto} from '../../dtos/ICategoryDto';
import {IMealPlanCardDto} from '../../dtos/IMealPlanCardDto';
import {IMealPlanDto} from '../../dtos/IMealPlanDto';
import {IRecipeFiltersDto, ISelectedReipesFiltersDto} from '../../dtos/IRecipeFiltersDto';
import {IMealPlansFiltersDto} from '../../dtos/IMealPlansFiltersDto';
import {SideMenuSelectedType} from '../../utils/enums';
import {IRecipeTagsDto} from '../../dtos/IRecipeTagsDto';
import {
  sidebarBetrProgramFiltersModel,
  sidebarMealPlansFiltersModel,
  sidebarRecipeFiltersModel,
} from '../models/nourish/nourish-models';
import {ICardDto} from '../../dtos/ICardDto';
import {IDailyContentCategoryDto} from '../../dtos/IDailyContentCategoryDto';
import {IDailyContentDto} from '../../dtos/IDailyContentDto';
import {IBetrProgramFilterDto} from '../../dtos/IBetrProgramFilterDto';

export interface INourishState {
  recipes: any[];
  recipesCount: number;
  recipe: any;
  recipeFilters: IRecipeFiltersDto;
  recipesTags: IRecipeTagsDto;
  cuisines: ICuisineDto[];
  courses: ICourseDto[];
  levels: ILevelCategoryDto[];
  ingredientsCategories: ICategoryIngredientsDto[];
  categoriesWithIngredients: ICategoryIngredientsDto[];
  categories: ICategoryDto[];
  mealPlans: IMealPlanCardDto[];
  mealPlan: IMealPlanDto[];
  mealPlansFilters: IMealPlansFiltersDto;
  selectedMenuItem: SideMenuSelectedType;
  selectedRecipesFilters: ISelectedReipesFiltersDto;
  selectableIngredients: IIngredientDto[];
  cards: ICardDto[];
  dailyContentCategories: IDailyContentCategoryDto[];
  dailyContents: IDailyContentDto[];
  dailyContentsCount: number;
  dailyContent: IDailyContentDto[];
  betrProgramFilters: IBetrProgramFilterDto;
  rehabEnabled: boolean;
  movementRestricted: any;
}

const initialState: INourishState = {
  recipes: [],
  recipesCount: 0,
  recipe: {},
  cuisines: [],
  courses: [],
  levels: [],
  ingredientsCategories: [],
  categoriesWithIngredients: [],
  categories: [],
  mealPlans: [],
  mealPlan: [],
  selectedMenuItem: SideMenuSelectedType.SIDEMENU_INSIGHTS,
  ...sidebarRecipeFiltersModel,
  ...sidebarMealPlansFiltersModel,
  ...sidebarBetrProgramFiltersModel,
  cards: [],
  dailyContentCategories: [],
  dailyContents: [],
  dailyContent: [],
  dailyContentsCount: 0,
  rehabEnabled: false,
  movementRestricted:[],
};

const nourishReducer = createReducer(initialState, {
  [SAVE_RECIPES](state: INourishState, action: ISaveRecipes) {
    return {
      ...state,
      recipes: action.payload,
    };
  },

  [SAVE_RECIPES_COUNT](state: INourishState, action: ISaveRecipesCount) {
    return {
      ...state,
      recipesCount: action.payload,
    };
  },

  [SAVE_RECIPES_TAGS](state: INourishState, action: ISaveRecipesTags) {
    return {
      ...state,
      recipesTags: action.payload,
    };
  },

  [SAVE_RECIPE](state: INourishState, action: ISaveRecipes) {
    return {
      ...state,
      recipe: action.payload,
    };
  },

  [SAVE_RECIPE_FILTERS](state: INourishState, action: ISaveRecipeFilters) {
    return {
      ...state,
      recipeFilters: action.payload,
    };
  },

  [LOAD_CUISINES](state: INourishState, action: ILoadCuisines) {
    return {
      ...state,
      cuisines: action.payload,
    };
  },

  [LOAD_COURSES](state: INourishState, action: ILoadCourses) {
    return {
      ...state,
      courses: action.payload,
    };
  },
  [LOAD_MINDFULNESS](state: INourishState, action: ILoadMindfulness) {
    return {
      ...state,
      mindfulnessData: action.payload,
    };
  },
  [LOAD_PODCAST](state: INourishState, action: ILoadPodCast) {
    return {
      ...state,
      podCastData: action.payload,
    };
  },
  [SAVE_YOGA](state: INourishState, action: ILoadYoga) {
    return {
      ...state,
      yogaSelectedData: action.payload,
    };
  },
  [LOAD_YOGA](state: INourishState, action: ILoadYoga) {
    return {
      ...state,
      yogaData: action.payload,
    };
  },
  

  [LOAD_LEVEL_CATEGORIES](state: INourishState, action: ILoadLevelCategories) {
    return {
      ...state,
      levels: action.payload,
    };
  },

  [LOAD_INGREDIENTS_CATEGORIES](state: INourishState, action: ILoadIngredientsCategories) {
    return {
      ...state,
      ingredientsCategories: action.payload,
    };
  },
  [LOAD_CATEGORIES_WITH_INGREDIENTS](state: INourishState, action: ILoadCategoriesWIthIngredients) {
    return {
      ...state,
      categoriesWithIngredients: action.payload,
    };
  },

  [SAVE_MEAL_PLANS](state: INourishState, action: ISaveMealPlans) {
    return {
      ...state,
      mealPlans: action.payload,
    };
  },

  [SAVE_MEAL_PLAN](state: INourishState, action: ISaveMealPlan) {
    return {
      ...state,
      mealPlan: action.payload,
    };
  },

  [SAVE_MEAL_PLANS_FILTERS](state: INourishState, action: IMealPlansFilters) {
    return {
      ...state,
      mealPlansFilters: action.payload,
    };
  },

  [SAVE_MEAL_PLANS_TAGS](state: INourishState, action: ISaveMealPlansTags) {
    return {
      ...state,
      mealPlansTags: action.payload,
    };
  },

  [SAVE_SELECTED_MEAL_PLANS_FILTERS](state: INourishState, action: ISaveSelectedMealPlansFilters) {
    return {
      ...state,
      selectedMealPlansFilters: action.payload,
    };
  },
  [SAVE_SELECETED_MENU_ITEM](state: INourishState, action: ISaveSelectedMenuItem) {
    return {
      ...state,
      selectedMenuItem: action.payload,
    };
  },

  [SAVE_SELECETED_RECIPES_FILTERS](state: INourishState, action: ISaveSelectedRecipesFilters) {
    return {
      ...state,
      selectedRecipesFilters: action.payload,
    };
  },

  [SAVE_SELECTABLE_INGREDIENTS](state: INourishState, action: ISaveSelectableIngredients) {
    return {
      ...state,
      selectableIngredients: action.payload,
    };
  },

  [LOAD_CATEGORIES](state: INourishState, action: ILoadCategories) {
    return {
      ...state,
      categories: action.payload,
    };
  },

  [LOAD_CARDS](state: INourishState, action: ILoadCards) {
    return {
      ...state,
      cards: action.payload,
    };
  },
  [LOAD_RIGHT_CARDS](state: INourishState, action: IRightLoadCards) {
    return {
      ...state,
      rightCards: action.payload,
    };
  },

  [LOAD_DAILY_CONTENT_CATEGORIES](state: INourishState, action: ILoadDailyContentCategories) {
    return {
      ...state,
      dailyContentCategories: action.payload,
    };
  },
  [LOAD_DAILY_CONTENTS](state: INourishState, action: ILoadDailyContents) {
    return {
      ...state,
      dailyContents: action.payload,
    };
  },
  [LOAD_DAILY_CONTENT](state: INourishState, action: ILoadDailyContent) {
    return {
      ...state,
      dailyContent: action.payload,
    };
  },
  [SAVE_BETR_PROGRAM_FILTERS](state: INourishState, action: ISaveBetrProgramFilters) {
    return {
      ...state,
      betrProgramFilters: action.payload,
    };
  },
  [SAVE_DAILY_CONTENTS_COUNT](state: INourishState, action: ISaveDailyContentsCount) {
    return {
      ...state,
      dailyContentsCount: action.payload,
    };
  },
  [REHAB_ENABLED](state: INourishState, action: IRehabEnabled) {
    return {
      ...state,
      rehabEnabled: action.payload,
    };
  },
  [APPOINTMENT_ENABLED](state: INourishState, action: IAppointmentEnabled) {
    return {
      ...state,
      appointmentEnabled: action.payload,
    };
  },
  [MOVEMENT_ENABLED](state: INourishState, action: IMovementEnabled) {
    return {
      ...state,
      movementRestricted: action.payload,
    };
  },
});

export default nourishReducer;
