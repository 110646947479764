import {RoleType, RoleTypeDisplay} from './enums';
import {IUser} from '../models/IUser';

export const checkIfUserIsMember = (currentUser: IUser): boolean => {
  if (!currentUser) {
    return false;
  }

  if (Array.isArray(currentUser.roles) && !currentUser.roles?.length) {
    return false;
  }

  if (currentUser.roles?.length > 1) {
    return false;
  }

  return currentUser.roles[0] === RoleType.MEMBER.toString();
};

export const checkIfUserIsTrainer = (currentUser: IUser): boolean => {
  if (!currentUser) {
    return false;
  }

  if (Array.isArray(currentUser.roles) && !currentUser.roles?.length) {
    return false;
  }

  return currentUser.roles.includes(RoleType.TRAINER.toString());
};

export const checkIfUserIsOnlyTrainer = (currentUser: IUser): boolean => {
  if (!currentUser) {
    return false;
  }

  if (Array.isArray(currentUser.roles) && !currentUser.roles?.length) {
    return false;
  }
  return currentUser.roles?.length === 2;
};

export const checkIfUserIsSuperCoach = (currentUser: IUser): boolean => {
  if (!currentUser) {
    return false;
  }

  if (Array.isArray(currentUser.roles) && !currentUser.roles?.length) {
    return false;
  }

  return currentUser.roles.includes(RoleType.SUPER_COACH.toString());
};

export const checkIfUserHasTrainer = (currentUser: IUser): IUser | null => {
  if (!currentUser) {
    return null;
  }

  return currentUser.trainer;
};

export const checkUserRole = (user: IUser | null | undefined): RoleTypeDisplay | null => {
  if (!user) {
    return null;
  }
  if (checkIfUserIsSuperCoach(user)) {
    return RoleTypeDisplay.HEAD_COACH;
  }
  if (checkIfUserIsTrainer(user)) {
    return RoleTypeDisplay.COACH;
  }
  return RoleTypeDisplay.MEMBER;
};

export const returnRoleDisplay = (role: string): string => {
  if (role === RoleType.SUPER_COACH) return RoleTypeDisplay.HEAD_COACH;
  if (role === RoleType.MEMBER) return RoleTypeDisplay.MEMBER;
  if (role === RoleType.TRAINER) return RoleTypeDisplay.COACH;
  return '';
};

export const checkIfUserHasHigherRoles = (currentUser: IUser, admin: boolean): boolean => {
  if (!currentUser) {
    return false;
  }

  if (Array.isArray(currentUser.roles) && !currentUser.roles?.length) {
    return false;
  }
  if (currentUser.roles.includes(RoleType.TRAINER.toString())) return true;
  if (currentUser.roles.includes(RoleType.SUPER_COACH.toString())) return true;
  if (admin) {
    if (currentUser.roles.includes(RoleType.ADMIN.toString())) return true;
  }
  return false;
};
