import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import {Select} from '../../select/Select';
import {TextField} from '../../text-field/TextField';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {IKeyValuePair} from '../../../../models/IKeyValuePair';
import BloodGlucoseIcon from '../../../../assets/icons/side-menu-bar-logging/blood-glucose-icon.svg';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {
  addBloodGlucoseLog,
  editBloodGlucoseLog,
} from '../../../../store/actions/logging-page/logging-page-action';
import {ValidationType} from '../../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../../validation/Validation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {FormType, WidgetType, MealTime} from '../../../../utils/enums';
import {convertToCapitalRemoveUnderscore} from '../../../../utils/StringHelpers';
import {DATE_PICKER_DATE_FORMAT} from '../../../../utils/constants';

import useStyles from './blood-glucose-form.styles';
import useCommonStyles from '../common-for-widgets.styles';

interface IBloodGlucoseFormData {
  date: string;
  time: string;
  bloodGlucose: string;
  mealTimeObject: IKeyValuePair;
  mealTime?: string;
}
interface IBloodGlucoseFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  isMobile: boolean;
  openedFromDialog?: boolean;
  date?: string;
}

const BloodGlucoseForm: React.FC<IBloodGlucoseFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });

  const validationRules = {
    bloodGlucose: [
      {type: ValidationType.REQUIRED},
      {
        type: ValidationType.IS_INT_NUMBER,
        minValue: 20,
        maxValue: 601,
        errorMessage: `${i18n.t('ErrorMessages.notInRangeValue')} 20-600`,
      },
    ],
    mealTime: [{type: ValidationType.REQUIRED}],
    date: [{type: ValidationType.REQUIRED}],
    time: [{type: ValidationType.REQUIRED_DURATION}],
  };

  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const mealTimes = Object.values(MealTime);
  const enumKeys = Object.keys(MealTime).filter((x) => !(parseInt(x) >= 0));
  const [data, setData] = useState<IBloodGlucoseFormData>({
    date: moment().toString(),
    time: moment().toString(),
    bloodGlucose: '',
    mealTimeObject: {key: '', value: ''},
  });
  const [mealTimeOptions, setMealTimeOptions] = useState<IKeyValuePair[]>([{key: '', value: ''}]);
  const formType = props.formType || FormType.ADDING_MODE;

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  const convertEnumToArr = () => {
    const array: IKeyValuePair[] = [];
    enumKeys.forEach((num, index) => {
      array.push({
        key: convertToCapitalRemoveUnderscore(num ? num?.toLowerCase() : '' ),
        value: mealTimes[index],
      });
    });
    setMealTimeOptions(array);
  };

  useEffect(() => {
    convertEnumToArr();
    let initialObject: IBloodGlucoseFormData = {
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
      bloodGlucose: '',
      mealTimeObject: {key: '', value: ''},
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        mealTime: props.data.mealTime || '',
        bloodGlucose: props.data.value || '',
        mealTimeObject: mealTimeOptions.find((option) => option.value === props.data.mealTime) || {
          key: '',
          value: '',
        },
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
      };
    }

    setData(initialObject);
  }, [props.data]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    setData({
      date: moment().toString(),
      time: moment().toString(),
      bloodGlucose: '',
      mealTimeObject: {key: '', value: ''},
      mealTime: '',
    });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'mealTime') {
      setData({
        ...data,
        ['mealTimeObject']: {key: value.key, value: value.value},
        [name]: value.key,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const bloodGlucoseLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        value: data.bloodGlucose,
        mealTime: data.mealTimeObject.key,
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addBloodGlucoseLog(bloodGlucoseLog));
      } else {
        res = await dispatch(editBloodGlucoseLog(bloodGlucoseLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img
            className={commonClasses.imageMargin}
            src={BloodGlucoseIcon}
            alt={'blood-glucose-icon'}
          />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.bloodGlucoseWidget.formTitleEdit')
              : t('LogsPage.bloodGlucoseWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.bloodGlucoseWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.bloodGlucoseWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <div className={commonClasses.centerContainer}>
        <TextField
          name={'bloodGlucose'}
          containerClass={commonClasses.normalTextField}
          required={true}
          onBlur={() => validateFormField('bloodGlucose')}
          value={data.bloodGlucose}
          label={t('LogsPage.bloodGlucoseWidget.bloodGlucoseLabel')}
          placeholder={t('LogsPage.bloodGlucoseWidget.bloodGlucosePlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(errors, 'bloodGlucose')}
          helperText={getError(errors, 'bloodGlucose')}
        />
        <Select
          name={'mealTime'}
          label={t('LogsPage.bloodGlucoseWidget.mealTimeLabel')}
          required={true}
          value={data.mealTimeObject}
          onChange={handleChange}
          className={classes.sourceSelect}
          error={hasError(errors, 'mealTime')}
          helperText={getError(errors, 'mealTime')}
          onBlur={() => {
            validateFormField('mealTime');
          }}
          options={mealTimeOptions}
        />
      </div>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-blood-glucose-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-steps-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.bloodGlucoseWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default BloodGlucoseForm;
