/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import {Backdrop,Box,Modal,Fade,Typography} from '@material-ui/core';





import useStyles from './thankfeedback.styles';
import achievementBadge from '../../../../assets/images/achievementBadge.png';
import bgfeedback from '../../../../assets/images/bgfeedback.png';
import close from '../../../../assets/images/close.png';
import { Link } from 'react-router-dom';

export default function ThankFeedback({isThanksPopup, setIsThanksPopup, isNotNow, workoutCompDetail}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const homeLink = React.useRef();
  const handleClose = () => {
    setOpen(false);
    setIsThanksPopup(false);
    setTimeout(() => {
      homeLink?.current?.click();
    }, 1);
  }

  React.useEffect(() => {
    if(isThanksPopup) {
      handleOpen()
    }
  }, [isThanksPopup])

  return (
    <div>
      {/* <Button onClick={handleOpen}>Thank feedback Popup</Button> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        className={classes.modal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.thankFeedback }>
            <div className="feedback">
              <a href='javascript:void(0)' onClick={handleClose} className="closePopup">
                <img src={close} alt="" />
              </a>
              {isNotNow ?
                <h3 className="h3">Take a look at your progress.</h3>
              :
                <>
                  <Typography variant="h1" className="headingh1">
                    Thank you for feedback!
                  </Typography>
                  <p className="ptag">Take a look at your progress.</p>
                </>
              }
              <div className="poppupForm">
                <h4>Your Progress</h4>
                <p className="para2">You’re moving along on your fitness journey!</p>
                <div className="feedbackConntentBox">
                  <img src={bgfeedback} className="bgfeedback" alt="" />
                  <div className="feedbackrelative">
                    <img src={achievementBadge} alt="" />
                    {/* <p>1st Day Of Challenge</p> */}
                    {/* <div className="line"></div> */}
                    <div style={{padding: '16px 0'}}>
                      {workoutCompDetail?.workout_score && <Typography variant="h4">{workoutCompDetail?.workout_score}%</Typography> }
                      <span>Workout Effectiveness</span>
                    </div>
                    <div className="line2"></div>
                    <h5>‘All progress takes place outside the comfort zone.’</h5>
                    <span>Daily quote </span>
                  </div>
                </div>
                <Link to="/" style={{display: 'none'}} ref={homeLink}>Home</Link>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
