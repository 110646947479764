import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  isMobileOrTablet: {
    isMobile: boolean;
    //isTabletSmall: boolean;
    //isTabletBig: boolean;
  };
  //smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    display: 'flex',
    justifyContent: (props) => (props.isMobileOrTablet.isMobile ? 'space-between' : 'flex-start'),
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    paddingLeft: 40,
    paddingRight: 40,
  },
  header: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginTop: (props) => (props.isMobileOrTablet.isMobile ? 38 : 56),
    marginBottom: 24,
    width: '100%',
  },
  textContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginBottom: 40,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
  },
  finishButton: {
    marginTop: 5,
  },
  contentContainerText: {
    width: (props) => (props.isMobileOrTablet.isMobile ? 'unset' : 548),
  },
  textDesktop: {
    marginTop: 154,
    marginBottom: 154,
  },
  button: {
    width: (props) => (props.isMobileOrTablet.isMobile ? 295 : 548),
  },
}));

export default useStyles;
