import * as React from 'react';
import {useEffect, useState} from 'react';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select as MaterialSelect,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {TABLET_BIG_SIZE, SELECT} from '../../../utils/constants';

import useStyles from './select.styles';

interface ISelectItem {
  key: string;
  value: any;
}

interface Props {
  containerClass?: string;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  className?: string;
  value?: any;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  label?: string;
  name: string;
  required?: boolean;
  options: ISelectItem[];
  selectPlaceholder?: string;
  isDark?: boolean;
}

export const Select: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    return isMobile || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet: isMobileOrTablet,
  });
  const {
    containerClass,
    value,
    label,
    helperText,
    required,
    options,
    disabled,
    selectPlaceholder,
    isDark,
    ...rest
  } = props;
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    setSelectedItem(value.value);
  }, [value]);

  return (
    <div className={classNames(containerClass, props.className)}>
      <FormControl variant="outlined" className={classes.formControl}>
        <div className={classes.labelContainer}>
          {label && (
            <Typography
              variant="caption"
              component="div"
              className={disabled ? classes.labelDisabled : classes.labelText}
            >
              {label}
            </Typography>
          )}

          {required && (
            <Typography variant="caption" component="div" className={classes.requiredText}>
              *
            </Typography>
          )}
        </div>
        <MaterialSelect
          classes={{}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value || ''}
          disabled={disabled ? disabled : false}
          MenuProps={{
            classes: {
              list: isDark ? classes.customMenuProp : classes.customMenuPropDefault,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          {...rest}
          displayEmpty={true}
          renderValue={(selected: any) => {
            if (selected.value === '' || selected.value === 0) {
              return (
                <Typography
                  variant="body1"
                  className={disabled ? classes.placeholderDisabled : classes.placeholderClass}
                >
                  {selectPlaceholder || SELECT}
                </Typography>
              );
            }
            return selected.value;
          }}
        >
          {options.map((oneItem: any) => (
            <MenuItem
              key={oneItem.key}
              value={oneItem}
              className={
                selectedItem === oneItem.value ? classes.selectedItem : classes.notSelected
              }
            >
              {oneItem.value}
            </MenuItem>
          ))}
        </MaterialSelect>

        <FormHelperText error={rest.error} className={classes.helperText}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
