import React, {ReactNode} from 'react';
import {isMobileOnly} from 'react-device-detect';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// eslint-disable-next-line import/no-unresolved
import {TransitionProps} from '@material-ui/core/transitions';
import {Slide, useMediaQuery} from '@material-ui/core';
import {ReactComponent as CloseIcon} from '../../../assets/icons/rest/close-icon.svg';
import {MOBILE_SIZE} from '../../../utils/constants';

import useStyles from './content-dialog.styles';

interface IContentDialogProps {
  handleDismiss: any;
  dialogState: boolean;
  content: ReactNode;
  fullScreen?: boolean;
  slideUp?: boolean;
  className?: string;
  closeIconClass?: string;
  applyMaxWidthFalse?: boolean;
  isCloseButtonHidden?: boolean;
}

const TransitionSlideUp = React.forwardRef<
  unknown,
  TransitionProps & {
    children?: React.ReactElement;
  }
>((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

/* eslint-disable  @typescript-eslint/no-empty-function */
export const ContentDialog: React.FC<IContentDialogProps> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isMobile = React.useMemo(() => {
    return isMobileOnly || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobile,
  });
  const {
    dialogState,
    handleDismiss,
    content,
    fullScreen,
    slideUp,
    applyMaxWidthFalse,
    className,
    isCloseButtonHidden,
    closeIconClass,
  } = props;
  const transition = slideUp && {
    TransitionComponent: TransitionSlideUp,
  };
  return (
    <Dialog
      id={'modal-widget'}
      open={dialogState}
      onClose={handleDismiss}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classNames(classes.root, className)}
      fullScreen={fullScreen}
      maxWidth={applyMaxWidthFalse ? false : undefined}
      {...transition}
    >
      <DialogContent>{content}</DialogContent>
      {!isCloseButtonHidden && (
        <div
          className={classNames(classes.closeIconClass, closeIconClass)}
          onClick={handleDismiss}
          onKeyDown={() => {}}
          role={'button'}
          tabIndex={0}
        >
          <CloseIcon title={'close-img-icon'} width={24} height={24} />
        </div>
      )}
    </Dialog>
  );
};

TransitionSlideUp.displayName = 'TransitionSlideUp';
ContentDialog.displayName = 'ContentDialog';
