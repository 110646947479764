import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IOnBoardingUser} from '../../../models/IOnBoardingUser';
import {HeightType, WeightType} from '../../../utils/constants';
import {OnBoardingSteps} from '../../../utils/enums';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../validation/Validation';
import {ValidationType} from '../../../validation/ValidationType';
import {Button} from '../button/Button';
import {TextField} from '../text-field/TextField';
import useStyles from './height-and-weight.styles';
import {RangeValueSlider} from './RangeValueSlider';
import {calculateHeightToDisplay, calculateHeightToSend} from '../../../utils/helpers';
import Snackbar from '../notistack/SnackbarUtils';
import i18n from 'i18next';

interface IHeightAndWeightFormData {
  heightFeet: number;
  heightInches: number;
  weight: any;
}

interface IProps {
  userData: IOnBoardingUser;
  onContinueClick: (step: OnBoardingSteps) => void;
  onUpdateUser: (user: IOnBoardingUser) => void;
  submitDataForOnBoarding: () => Promise<any>;
}

const feetMarks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const inchMarks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const HeightAndWeightImperialOnBoarding: React.FC<IProps> = (props) => {
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const {userData, onContinueClick, onUpdateUser, submitDataForOnBoarding} = props;
  const validationRules = {
    weight: [
      {
        type: ValidationType.IS_DECIMAL_NUMBER,
        minValue: 40,
        maxValue: 1000,
        errorMessage: `${t('ErrorMessages.notDecimalNumber')}`,
      },
    ],
  };

  const classes = useStyles();
  const [data, setData] = useState<IHeightAndWeightFormData>({
    heightFeet: 4,
    heightInches: 6,
    weight: 0,
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    let heights = ['4', '6'];
    if (userData.height) {
      heights = calculateHeightToDisplay(userData.height, 1);
    }

    const initialObject: IHeightAndWeightFormData = {
      heightFeet: heights[0] ? +heights[0] : 4,
      heightInches: heights[1] ? +heights[1] : 6,
      weight: userData.weight || 0,
    };

    setData(initialObject);
    if (userData.weight) {
      setHasChanges(true);
    }
  }, [props.userData]);

  const handleSliderChange = (_e: any, value: number, name: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
    setHasChanges(true);
  };

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };
  const validateToSubmit = () => {
    if (!hasChanges) {
      return false;
    }
    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const handleSubmit = async () => {
    //TODO 2: call backend to submit data if all right proceed to the next page
    try {
      setLoading(true);
      const heightToSend = calculateHeightToSend(
        data.heightFeet.toString(),
        data.heightInches.toString(),
        1,
      );
      userData.height = heightToSend;
      userData.weight = parseFloat(data.weight);
      onUpdateUser(userData);

      const res = await submitDataForOnBoarding();
      if (res) {
        Snackbar.success(i18n.t('Messages.registeredSuccessfully'));
        onContinueClick(OnBoardingSteps.BOOK_INTRO_CALL);
      }
    } catch (err: any) {
      Snackbar.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>
        <Typography variant="h5" className={classes.title}>
          {t('OnBoardingPage.heightAndWeight.title')}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {t('OnBoardingPage.heightAndWeight.text')}
        </Typography>
        <Typography variant="body2" className={classes.heightLabel}>
          {t('OnBoardingPage.heightAndWeight.height')}
        </Typography>
        <RangeValueSlider
          id={'heightFeetValue'}
          name={'heightFeet'}
          step={1}
          defaultValue={data.heightFeet}
          minValue={0}
          maxValue={9}
          marks={feetMarks}
          unit={HeightType.FT}
          className={classes.slider}
          onChange={(e: any, value: any) => handleSliderChange(e, value, 'heightFeet')}
        />
        <RangeValueSlider
          id={'heightInchesValue'}
          name={'heightInches'}
          step={1}
          defaultValue={data.heightInches}
          minValue={0}
          maxValue={11}
          marks={inchMarks}
          unit={HeightType.IN}
          className={classes.slider}
          onChange={(e: any, value: any) => handleSliderChange(e, value, 'heightInches')}
        />
        <Typography variant="body2" className={classes.weightLabel}>
          {t('OnBoardingPage.heightAndWeight.weight')}
        </Typography>
        <TextField
          name="weight"
          className={classes.inputValueFied}
          placeholder="Value"
          containerClass={classes.textContainerClass}
          value={data.weight}
          onChange={handleChange}
          error={hasError(errors, 'weight')}
          helperText={getError(errors, 'weight')}
          onBlur={() => validateFormField('weight')}
          unit={WeightType.LBS}
          unitClassName={classes.unit}
        />
      </div>
      <Button
        id="continue"
        className={classes.continue}
        onClick={handleSubmit}
        loading={isLoading}
        disabled={!validateToSubmit() || isLoading}
      >
        {t('OnBoardingPage.continue')}
      </Button>
    </div>
  );
};
export default HeightAndWeightImperialOnBoarding;
