import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  connectionsContent: {
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
    },
    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
    },
  },
  line: {
    border: 1,
    borderColor: theme.palette.blacksAndGrays.gainsboro,
    boxSizing: 'border-box',
    borderStyle: 'solid',
    marginBottom: 16,
  },
  collapseIcon: {
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  connectionsSearch: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: '282px !important',
    borderRadius: 8,
    marginBottom: 6,
    marginRight: '0px !important',
    '& .MuiFormControl-root': {
      width: '100%',
 },
  },
  filterSelect: {
    backgroundColor: theme.palette.background.paper,
    width: 282,
  },
  selectCaption: {
    color: theme.palette.blacksAndWhites.davysGray,
  },
}));
export default useStyles;
