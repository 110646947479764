import {Container, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const Privacy = () => {
  const useStyles = makeStyles({
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 64,
      backgroundColor: '#fff',
      color: '#184c88',
      paddingLeft: 10,
      paddingRight: 10,
      justifyContent: 'center',
      textAlign: 'center',
      boxShadow: '0px 1px 4px 0px rgba(0,0,0,.25)',
      position: 'fixed',
      width: '100vw',
    },
    title: {
      flexGrow: 1,
      fontWeight: 500,
      fontSize: 20,
    },
    sectionContent: {
      marginBottom: '1rem',
      fontFamily: "Gantari, sans-serif",
      fontSize: 12,
      color: '#7a7a7a',
      fontWeight: 'bold',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '34px 0px',
      alignItems: 'center',
    },
    footerImage: {
      width: 110,
      height: 46,
    },
    footerNav: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80vh',
    },
  });
  const classes = useStyles();

  return (
    <>
      <div className={classes.appBar}>
        <img
          className={classes.footerImage}
          decoding="async"
          src="https://sp-ao.shortpixel.ai/client/to_webp,q_lossless,ret_img/https://betrhealth.com/wp-content/uploads/2022/08/footer-logo.png"
          alt="Logo"
        />
        <h1 className={classes.title}>Privacy Policy</h1>
      </div>
      <Container maxWidth="md" style={{marginTop: 65}}>
    
        <div className="elementor-text-editor elementor-clearfix">
        <Box className={classes.sectionContent}>
          This Privacy Policy (this “<strong>Policy</strong>”) describes the information that Pure
          Proactive Health Inc., DBA Betr Heath (“<strong>Betr</strong>“, “<strong>we</strong>“, “
          <strong>our</strong>,” or “<strong>us</strong>”) and its affiliates may gather from you on
          or through our website (https://www.Betrhealth.com) (the “<strong>Site</strong>”), mobile
          application (the “<strong>App</strong>”), and activities offline in connection with the
          App or Site, such as invoicing or communicating with employers or health plan providers
          (together with the Site and the App, the “<strong>Service</strong>”); how we use and
          disclose such information; and the steps we take to protect such information. By accessing
          or using the Service, you signify that you have read, understood and agree to our privacy
          practices described in this Policy and the Betr Terms of Service (the “
          <strong>Terms</strong>”). This Policy is incorporated by reference into the Terms. Any
          capitalized terms used but not defined in this Policy have the meaning given to them in
          the Terms. IF YOU ARE A PATIENT USING THE SERVICE, YOU ALSO ACKNOWLEDGE AND AGREE TO THE
          “SPECIAL TERMS FOR PATIENTS” IDENTIFIED IN SECTION 13 BELOW.
          </Box>
          <ol>
          <Box className={classes.sectionContent}>
            <li>
              <strong> The information we collect on the Site:</strong>
            </li>
            </Box>
          </ol>
          <ul>
          <Box className={classes.sectionContent}>
            <li>
              <strong>User-provided Information</strong>. When you use the Service, you may provide
              and we may collect what is generally called “personally identifiable information,” or
              “personal information,” which is information that specifically identifies an
              individual. You may provide us with personal information of various types and in
              various ways on the Service, including, without limitation, when you register for an
              account, use the Service, post or share User Content (as defined in the Terms), make a
              purchase on the Service, interact with other users of the Service through
              communication or messaging features, send us customer service-related requests, or
              otherwise communicate with us. For example, when you register for the Service, we
              collect your name, email address, phone number, date of birth and information about
              what interests you about our Service. We may also collect additional information when
              you continue to use the Service, including without limitation, your mailing address, a
              username, and password and other information that may identify you, such as, gender,
              age, social security number, driver’s license number, geographic area, preferences,
              payment information (such as your payment card number, expiration date, and billing
              address), and insurance information. We may also receive health information from you
              or on your behalf such as information or records relating to your medical or health
              history, health status and laboratory testing results, diagnostic images, and other
              health-related information. Please remember that Betr may, but has no obligation to,
              monitor, record, and store User Content in order to protect your safety or the safety
              of other users, to assist with regulatory or law enforcement efforts, or to protect
              and defend our rights and property. By using the Service, you consent to the
              recording, storage, and disclosure of such communications you send or receive for
              these purposes.
            </li>
            </Box>
            <Box className={classes.sectionContent}>
            <li>
              <strong>“Cookies” Information</strong>. When you use the Service, we, and our
              third-party partners, may send one or more cookies – small text files containing a
              string of alphanumeric characters – to your device. We may use both session cookies
              and persistent cookies. A session cookie disappears after you close your browser. A
              persistent cookie remains after you close your browser and may be used by your browser
              on subsequent visits to the Service. Please review your web browser “Help” file to
              learn the proper way to modify your cookie settings. Please note that if you delete,
              or choose not to accept, cookies from the Service, you may not be able to utilize the
              features of the Service to their fullest potential.
            </li>
            </Box>
            <Box className={classes.sectionContent}>
            <li>
              <strong>“Automatically Collected” Information</strong>. When you use the Service, read
              our emails, or otherwise engage with us, we, and our third-party partners, may
              automatically collect certain information from your device by using various types of
              tracking technology, including “clear gifs” or “web beacons.” This “automatically
              collected” information may include your IP address or other device address or
              identifier, web browser and/or device type, the web pages or sites that you visit just
              before or just after you use the Service, the pages or other content you view or
              otherwise interact with on the Service, and the dates and times that you visit,
              access, or use the Service, and your language preferences. We also may use these
              technologies to collect information regarding your interaction with email messages,
              such as whether you opened, clicked on, or forwarded a message. This information is
              gathered from all users. When you access our Service from a mobile device, we may
              collect unique identification numbers associated with your device or our mobile
              application (including, for example, a UDID, Unique ID for Advertisers (“
              <strong>IDFA</strong>”), Google AdID, or Windows Advertising ID), mobile carrier,
              device type, model and manufacturer, mobile device operating system brand and model,
              phone number, and, depending on your mobile device settings, your geographical
              location data, including GPS coordinates (e.g., latitude and/or longitude) or similar
              information regarding the location of your mobile device, or we may be able to
              approximate a device’s location by analyzing other information, like an IP address. We
              and our third-party partners may also use cookies and tracking technologies for
              advertising purposes. For more information about tracking technologies, please see
              “Third Party Tracking and Online Advertising” below.
            </li>
            </Box>
            <Box className={classes.sectionContent}>
            <li>
              <strong>Third Party Web Beacons and Third Party Buttons</strong>. We may also
              implement third-party content or advertising on the Service that may use clear gifs or
              other forms of web beacons, which allow the third-party content provider to read and
              write cookies to your browser in connection with your viewing of the third-party
              content on the Service. Additionally, we may implement third-party buttons (such as
              Facebook “like” or “share” buttons) that may allow third parties to collect
              information about you through such third parties’ browser cookies, even when you do
              not interact with the button. Information collected through web beacons and buttons is
              collected directly by these third parties, and Betr does not participate in that data
              transmission. Information collected by a third party in this manner is subject to that
              third party’s own data collection, use, and disclosure policies.
            </li>
            </Box>
            <Box className={classes.sectionContent}>
            <li>
              <strong>Do Not Track Signals</strong>. Your device operating system or browser may
              include settings, options, or add-on components to control the placement and presence
              of cookies and access to location information. We do not track our users over time and
              across third-party websites to provide targeted advertising and do not specifically
              respond to Do Not Track (“<strong>DNT</strong>”) signals. However, some third-party
              websites do keep track of your browsing activities, including across other websites on
              the Internet, which enables these websites to tailor what they present to you. If you
              are visiting such websites, your browser may allow you to set a DNT signal on your
              browser so that third parties know you do not want to be tracked.
            </li>
            </Box>
            <Box className={classes.sectionContent}>
            <li>
              <strong>Information from Other Sources</strong>. We may obtain information, including
              personal information, from third parties and sources other than the Service, such as
              our partners, advertisers or Providers (as defined below). If we combine or associate
              information from other sources with personal information that we collect through the
              Service, we will treat the combined information as personal information in accordance
              with this Policy. We and our third-party service providers may obtain information
              regarding your location or the location of your device through which you access our
              Service. Information regarding your location may be obtained directly from you when
              you provide us with information as part of the registration process. In addition, we
              may use Google Analytics, a web analytics tool that helps operators (like Betr)
              understand how users (like you) engage with their applications. Google Analytics uses
              cookies to track your interactions with our Service and to collect information about
              how you use the Service. We then use the information to compile reports that help us
              improve the Service. Google Analytics collects, processes and creates reports about
              website trends without identifying individual users. For more information regarding
              Google Analytics visit “How Google uses data when you use our partners’ sites or apps”
              located at
              <a href="https://www.google.com/policies/privacy/partners/">
                www.google.com/policies/privacy/partners
              </a>
              . We may also work with third-party partners to employ technologies, including the
              application of statistical modeling tools, which permit us to recognize and contact
              you across multiple devices.
            </li>
            </Box>
          </ul>
          <Box className={classes.sectionContent}>
          <ol start={2}>
            <li>
              <strong> How we use the information we collect.</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          We use information we collect on the Service in a variety of ways in providing the Service
          and operating our business, including the following:
          </Box>
          <Box className={classes.sectionContent}>
          <ul>
            <li>
              We may use the information that we collect on or through the Service (i) to operate,
              maintain, enhance and provide all features of the Service; (ii) to provide services
              and information that you request; (iii) to respond to comments and questions and
              communicate with you about the Service; (iv) to verify your identity; (v) to detect,
              prevent, investigate and respond to fraud, intellectual property infringement,
              violations of our Terms, or other misuse of our Service or any other Betr websites or
              services; (vi) process payment card transactions; (vii) otherwise to provide support
              to users; and (viii) for any other use permitted by applicable law, including for
              research purposes.
            </li>
            <li>
              We may use the information that we collect on the Service to understand and analyze
              the usage trends and preferences of our users, to improve the Service, and to develop
              new products, services, features, and functionality.
            </li>
            <li>
              We may use your email address or other information we collect on or through the
              Service (i) to contact you for administrative purposes such as customer service, to
              address intellectual property infringement, right of privacy violations or defamation
              issues related to your User Content (as defined in the Terms) or (ii) to send
              communications, including updates on promotions and events, relating to products and
              services offered by us, by the Providers, and by third parties we work with.
              Generally, you have the ability to opt-out of receiving any promotional communications
              as described below under “Your Choices.”
            </li>
            <li>
              We may use “cookies” information and “automatically collected” information we collect
              on the Service to (i) personalize our services, such as remembering your information
              so that you will not have to reenter it during your visit or the next time you visit
              the Service; (ii) provide customized advertisements, content, and information; (iii)
              monitor and analyze the effectiveness of the Service and third-party marketing
              activities; (iv) monitor aggregate site usage metrics such as total number of visitors
              and pages viewed; and (v) track your entries, submissions, and status in any
              promotions or other activities on the Service.
            </li>
            <li>
              We may access or store your information if it is necessary to detect, prevent or
              address fraud and other illegal activity or to protect the safety, property or rights
              of Betr or others.
            </li>
            <li>
              We may use information regarding your location or the location of your device through
              which you access the Service for a number of purposes, including without limitation to
              confirm you are located in a jurisdiction in which the Service is offered and to
              identify an appropriate Provider.
            </li>
            <li>
              We may collect, analyze, use, publish, create and sell de-identified information, of
              which your protected health information might be a component, for any business or
              other purpose not prohibited by applicable law, including for research and marketing
              purposes.
            </li>
          </ul>
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={3}>
            <li>
              <strong> When we disclose information.</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          Except as described in this Policy, we will not disclose your information that we collect
          on the Service to third parties without your consent. We may disclose information to third
          parties if you consent to us doing so, as well as in the following circumstances:
          Remember, our Service allows you to connect and interact with others.&nbsp;
          <strong>
            Your profile information, including your name, photo, and other personal information,
            will be available publicly to other members of the Service by default when you create a
            profile, interact with others on the Service in public groups, and post content to
            public spaces.
          </strong>
          </Box>
          <Box className={classes.sectionContent}>
          <ul>
            <li>
              Any information that you voluntarily choose to include in a publicly accessible area
              of the Service, such as a public profile page, will be available to anyone who has
              access to that content, including other users. Please do not provide personal
              information in these areas that you would not want to be public;
            </li>
            <li>
              We may disclose your information to Providers for treatment, or payment processing or
              operational purposes;
            </li>
            <li>
              We work with third-party service providers who provide website, payment, application
              development, hosting, maintenance, analytics, identifying and serving targeted
              advertisements and other services for us, including, by way of example, Stripe and
              Google Analytics. These third parties may have access to or process your information
              as part of providing those services for us. Generally, we limit the information
              provided to these service providers to that which is reasonably necessary for them to
              perform their functions;
            </li>
            <li>
              We may disclose your information with other companies and brands owned or controlled
              by Pure Proactive Health Inc., DBA Betr Heath and other companies owned by or under
              common ownership as Pure Proactive Health Inc., DBA Betr Heath, which also includes
              our subsidiaries (i.e., any organization we own or control) or our ultimate holding
              company (i.e., any organization that owns or controls us) and any subsidiaries it
              owns. These companies will use your personal information in the same way as we can
              under this Policy;
            </li>
            <li>
              We may make certain automatically collected, aggregated, or otherwise non-personally
              identifiable information available to third parties or users of the Service for
              various purposes, including (i) compliance with various reporting obligations; (ii)
              for business or marketing purposes; or (iii) to assist such parties in understanding
              our users’ interests, habits, and usage patterns for certain programs, content,
              services, advertisements, promotions, and/or functionality available through the
              Service;
            </li>
            <li>
              We may disclose your information if required to do so by law or in the good-faith
              belief that such action is necessary to comply with state and federal laws (such as
              U.S. copyright law), in response to a court order, judicial or other government
              subpoena or warrant, or to otherwise cooperate with law enforcement or other
              governmental agencies;
            </li>
            <li>
              We also reserve the right to disclose your information that we believe, in good faith,
              is appropriate or necessary to (i) take precautions against liability; (ii) protect
              ourselves or others from fraudulent, abusive, or unlawful uses or activity; (iii)
              investigate and defend ourselves against any third-party claims or allegations; (iv)
              protect the security or integrity of the Service and any facilities or equipment used
              to make the Service available; or (v) protect our property or other legal rights
              (including, but not limited to, enforcement of our agreements and Terms), or the
              rights, property, or safety of others;
            </li>
            <li>
              Information about our users, including personal information, may be disclosed and
              otherwise transferred to an acquirer, or successor or assignee as part of any merger,
              acquisition, debt financing, sale of assets, or similar transaction, as well as in the
              event of an insolvency, bankruptcy, or receivership in which information is
              transferred to one or more third parties as one of our business assets; and
            </li>
            <li>
              We may de-identify your information and disclose such de-identified information for
              any business or other purpose not prohibited by applicable law.
            </li>
          </ul>
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={4}>
            <li>
              <strong> Your Choices</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          You may, of course, decline to share certain personal information with us, in which case
          we may not be able to provide to you some of the features and functionality of the
          Service. You may update, correct, or delete your profile information and preferences at
          any time by accessing your account preferences page on the Service. You may request that
          we provide to you the information we hold about you, update your information, request that
          we remove your name or comments from our Service or publicly displayed content or request
          that we delete your information or correct any inaccuracies by emailing us at
          <a href="mailto:privacy@betrhealth.com"> privacy@betrhealth.com </a>with the subject
          heading “personal information request.” Please note that while any changes you make will
          be reflected in active user databases within a reasonable period of time, we may retain
          all information you submit for backups, archiving, prevention of fraud and abuse,
          analytics, satisfaction of legal obligations, or where we otherwise reasonably believe
          that we have a legitimate reason to do so. We may not be able to modify or delete your
          information in all circumstances. You may ask us to contact you at a specific phone number
          or to send mail or notices to a specific mailing address or email address. If you receive
          commercial email from us, you may unsubscribe at any time by following the instructions
          contained within the email. You may also opt out from receiving commercial email from us,
          and any other promotional communications that we may send to you from time to time, by
          sending your request to us by email at
          <a href="mailto:privacy@betrhealth.com"> privacy@betrhealth.com </a>or by writing to us at
          the address given at the end of this Policy. We may allow you to view and modify settings
          relating to the nature and frequency of promotional communications that you receive from
          us in user account functionality on the Service. Please be aware that if you opt out of
          receiving commercial email from us or otherwise modify the nature or frequency of
          promotional communications you receive from us, it may take up to ten (10) business days
          for us to process your request, and you may receive promotional communications from us
          that you have opted out from during that period. Additionally, even after you opt out from
          receiving commercial messages from us, you will continue to receive administrative
          messages from us regarding the Service.
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={5}>
            <li>
              <strong> Third-Party Tracking and Online Advertising.</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          <strong>Interest-Based Advertising</strong>. We may participate in interest-based
          advertising and use third-party advertising companies to serve you targeted advertisements
          based on your browsing history. We may share or we may permit third-party online
          advertising networks, social media companies, and other third-party services to collect
          information about your use of our website over time so that they may play or display ads
          on other websites, apps, or services, including on Facebook. Typically, though not always,
          the information we share is provided through cookies or similar tracking technologies,
          which recognize the device you are using and collect information, including clickstream
          information, browser type, time and date you visited the site, and other information. We
          and our third-party partners use this information to make the advertisements you see
          online more relevant to your interests, as well as to provide advertising-related services
          such as reporting, attribution, analytics, and market research.
          <strong>Social Media Widgets and Advertising</strong>. Our Service may include social
          media features, such as the Facebook Like button, Google Plus, LinkedIn, Snapchat,
          Instagram, Twitter, or other widgets. These social media companies may recognize you and
          collect information about your visit to our Service, and they may set a cookie or employ
          other tracking technologies. Your interactions with those features are governed by the
          privacy policies of those companies. We may display targeted advertising to you through
          social media platforms, such as Facebook, Twitter, Google+, and others. These companies
          have interest-based advertising programs that allow us to direct advertisements to users
          who have shown interest in our Service while those users are on the social media platform
          or to groups of other users who share similar traits, such as likely commercial interests
          and demographics. These advertisements are governed by the privacy policies of those
          social media companies that provide them.
          <strong>Cross-Device Linking</strong>. We, or our third-party partners, may link your
          various devices so that content you see on one device can result in relevant advertising
          on another device. We do this by collecting information about each device you use when you
          are logged in to our Service. We may also work with third-party partners who employ
          tracking technologies or the application of statistical modeling tools to determine if two
          or more devices are linked to a single user or household. We may share a common account
          identifier (such as an email address or user ID) with third-party advertising partners to
          help recognize you across devices. We, and our partners, can use this cross-device linkage
          to serve interest-based advertising and other personalized content to you across your
          devices, to perform analytics, and to measure the performance of our advertising
          campaigns.
          <strong>Your Choices:</strong>
          </Box>
          <Box className={classes.sectionContent}>
          <ul>
            <li>
              <strong>Cookies</strong>. Most browsers allow you to adjust your browser settings to
              (i) notify you when you receive a cookie, which lets you choose whether or not to
              accept it; (ii) disable existing cookies; or (iii) set your browser to automatically
              reject cookies.Blocking or deleting cookies may negatively impact your experience
              using the Service, as some features and services on our Service may not work properly.
              Deleting cookies does not delete Local Storage Objects (LSOs) such as HTML5.
            </li>
          </ul>
          </Box>
          <Box className={classes.sectionContent}>
          <ul>
            <li>
              <strong>Interest-based advertising</strong>. To learn about interest-based advertising
              and how you may be able to opt out of some of this advertising, you may wish to visit
              the Network Advertising Initiative’s (NAI) online resources at
              <a href="http://www.networkadvertising.org/choices">
                &nbsp;http://www.networkadvertising.org/choices&nbsp;
              </a>
              and/or the DAA’s resources at
              <a href="http://www.aboutads.info/choices">&nbsp;www.aboutads.info/choices.</a>
            </li>
            <li>
              <strong>Cross-device linking</strong>. Please note that opting out of receiving
              interest-based advertising through the NAI’s and DAA’s online resources will only opt
              out a user from receiving interest-based ads on that specific browser or device, but
              the user may still receive interest-based ads on his or her other devices. You must
              perform the opt out on each browser or device you use.
            </li>
            <li>
              <strong>Mobile advertising</strong>. You may also be able to limit interest-based
              advertising through the settings on your mobile device by selecting “limit ad
              tracking” (iOS) or “opt out of interest based ads” (Android). You may also be able to
              opt out of some—but not all—interest-based ads served by mobile ad networks by
              visiting
              <a href="https://youradchoices.com/appchoices">
                &nbsp;http://youradchoices.com/appchoices&nbsp;
              </a>
              and downloading the mobile AppChoices app.
            </li>
            <li>
              Some of these opt outs may not be effective unless your browser is set to accept
              cookies. If you delete cookies, change your browser settings, switch browsers or
              computers, or use another operating system, you will need to opt out again.
            </li>
          </ul>
          </Box>
          <Box className={classes.sectionContent}>
          <strong>Google Analytics and Advertising</strong>. We use Google Analytics to recognize
          you and link the devices you use when you visit our site or Service on your browser or
          mobile device, log in to your account on our Service, or otherwise engage with us. We
          share a unique identifier, like a user ID or hashed email address, with Google to
          facilitate the service. Google Analytics allows us to better understand how our users
          interact with our Service and to tailor our advertisements and content to you. For
          information on how Google Analytics collects and processes data, as well as how you can
          control information sent to Google, review Google’s site “How Google uses data when you
          use our partners’ sites or apps” located at
          <a href="https://www.google.com/policies/privacy/partners/">
            &nbsp;www.google.com/policies/privacy/partners/
          </a>
          . You can learn about Google Analytics’ currently available opt outs, including the Google
          Analytics Browser Ad-On here
          <a href="https://tools.google.com/dlpage/gaoptout/">
            &nbsp;https://tools.google.com/dlpage/gaoptout/.
          </a>
          We may also utilize certain forms of display advertising and other advanced features
          through Google Analytics, such as Remarketing with Google Analytics, Google Display
          Network Impression Reporting, the DoubleClick Campaign Manager Integration, and Google
          Analytics Demographics and Interest Reporting. These features enable us to use first-party
          cookies (such as the Google Analytics cookie) and third-party cookies (such as the
          DoubleClick advertising cookie) or other third-party cookies together to inform, optimize,
          and display ads based on your past visits to the Service. You may control your advertising
          preferences or opt out of certain Google advertising products by visiting the Google Ads
          Preferences Manager, currently available at
          <a href="https://google.com/ads/preferences">&nbsp;https://google.com/ads/preferences</a>,
          or by vising NAI’s online resources at
          <a href="http://www.networkadvertising.org/choices">
            &nbsp;http://www.networkadvertising.org/choices.
          </a>
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={6}>
            <li>
              <strong> Third-Party Services</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          The Service may contain features or links to Web sites and services provided by third
          parties, and the Service may allow you to display, use or make available content, data,
          information, applications or materials from third parties. Any information you provide on
          third-party sites or services is provided directly to the operators of such services and
          is subject to those operators’ policies, if any, governing privacy and security, even if
          accessed through the Service. We are not responsible for the content or privacy and
          security practices and policies of third-party sites or services to which links or access
          are provided through the Service. We encourage you to learn about third parties’ privacy
          and security policies before providing them with information.
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={7}>
            <li>
              <strong> Children’s Privacy</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          Protecting the privacy of young children is especially important. Our Service is not
          directed to children under the age of 13, and we do not knowingly collect personal
          information from children under the age of 13 without obtaining parental consent. If you
          are under 13 years of age, then please do not use or access the Service at any time or in
          any manner. If we learn that a person under 13 years of age has used or accessed the
          Service or any personally identifiable information has been collected on the Service from
          persons under 13 years of age, then we will take the appropriate steps to delete this
          information. If you are a parent or guardian and discover that your child under 13 years
          of age has obtained an account on or otherwise accessed the Service, then you may alert us
          at<a href="mailto:privacy@betrhealth.com">&nbsp;privacy@Betrhealth.com&nbsp;</a>and
          request that we delete that child’s personally identifiable information from our systems.
         </Box>
         <Box className={classes.sectionContent}>
          <ol start={8}>
            <li>
              <strong> Data Security</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          We use certain physical, technical, and administrative measures in an effort to protect
          the integrity and security of personal information that we collect and maintain. We
          cannot, however, ensure or warrant the security of any information you transmit to us or
          store on the Service, and you do so at your own risk. We also cannot guarantee that such
          information may not be accessed, disclosed, altered, or destroyed by breach of any of our
          physical, technical, or administrative measures. As part of providing you the Service, we
          may need to provide you with certain communications (via in app message, text and email),
          such as service announcements and administrative messages. These communications are
          considered part of the Service and may occur via emails, text messages or in app messages.
          You acknowledge that you are aware that email and text messages are not secure methods of
          communication and that your communication with us is not encrypted, and that you agree to
          the risks including the risk that the information contained within emails or texts could
          be read by a third party.
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={9}>
            <li>
              <strong> Privacy Settings</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          Although we may allow you to adjust your privacy settings to limit access to certain
          personal information, please be aware that no security measures are perfect or
          impenetrable. We are not responsible for circumvention of any privacy settings or security
          measures on the Service. Additionally, we cannot control the actions of other users with
          whom you may choose to share your information, including, without limitation, any and all
          User Content or information you share on community platforms. Further, even after
          information posted on the Service is removed, caching and archiving services may have
          saved that information, and other users or third parties may have copied or stored the
          information available on the Service. We cannot and do not guarantee that information you
          post on or transmit to the Service will not be viewed by unauthorized persons.
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={10}>
            <li>
              <strong> Jurisdictional Issues</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          The Service may only be used within certain jurisdictions within the United States as set
          forth in the Terms. Accordingly, this Policy, and our collection, use, and disclosure of
          your information, is governed by U.S. law, and by using the Service, you acknowledge that
          the Service will be governed by U.S. law. Using the Service from outside the United States
          is prohibited under our Terms and may subject you to termination under such Terms. In no
          event will Betr or any of its officers, directors, employees, consultants, subsidiaries,
          agents, and affiliated entities, including Provider Group (defined below) or any Provider,
          be liable for any losses or damages arising from your use of the Service outside of the
          United States, and you waive any claims that may arise under the laws of your location
          outside the United States. Notwithstanding the foregoing, we do not represent or warrant
          that the Service is appropriate or available for use in any particular jurisdiction. If
          you choose to use the Service from the European Union or other regions of the world with
          laws governing data collection and use that may differ from U.S. law, then please note
          that you are transferring your personal information outside of those regions to the United
          States for storage and processing. Also, we may transfer your data from the United States
          to other countries or regions in connection with storage and processing of data,
          fulfilling your requests, and operating the Service. By providing any information,
          including PHI (defined below), on or to the Service, you consent to such transfer,
          storage, and processing.
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={11}>
            <li>
              <strong> Changes and Updates to this Policy</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          Please revisit this page periodically to stay aware of any changes to this Policy, which
          we may update from time to time. If we modify this Policy, we will make it available
          through the Service, and indicate the date of the latest revision. In the event that the
          modifications materially alter your rights or obligations hereunder, we will make
          reasonable efforts to notify you of the change. For example, we may send a message to your
          email address, if we have one on file, or generate a pop-up or similar notification when
          you access the Service for the first time after such material changes are made. Your
          continued use of the Service after the revised Policy has become effective indicates that
          you have read, understood and agreed to the current version of this Policy.
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={12}>
            <li>
              <strong> Our Contact Information</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          Please contact us with any questions or comments about this Policy, your personal
          information, our use and disclosure practices, or your consent choices by email at
          <a href="mailto:privacy@betrhealth.com">&nbsp;privacy@Betrhealth.com.</a>
          Pure Proactive Health Inc., DBA Betr Heath 1 Glenwood Ave #5, Raleigh, North Carolina,
          27603
          </Box>
          <Box className={classes.sectionContent}>
          <ol start={13}>
            <li>
              <strong> Special Terms for Patients</strong>
            </li>
          </ol>
          </Box>
          <Box className={classes.sectionContent}>
          BY USING THE SERVICE, YOU ALSO ACKNOWLEDGE RECEIPT OF THE NOTICE OF PRIVACY PRACTICES (AS
          DEFINED IN THIS SECTION 13) OF THE HEALTH CARE PROVIDER THAT YOU ENGAGE THROUGH OUR
          SERVICE (“<strong>PROVIDER</strong>”). Providers accessible through the Service are
          employed by or contract independently with a professional corporation or other
          professional entity (“<strong>Provider Group</strong>”), which is affiliated with Betr. In
          addition to the information described above in Section 1 of this Policy, you may provide
          us with and we may collect your personal information from you when you (i) post User
          Content; (ii) interact with Providers or your Program Lead (as defined below); and/or
          (iii) provide us or have others provide us on your behalf, either via a request for
          information form or otherwise, with your health information such as information or records
          relating to your medical or health history, health status and laboratory testing results,
          diagnostic images, and other health-related information. In addition to the uses we
          described in Section 2 of this Policy, we may use your information to:
          </Box>
          <Box className={classes.sectionContent}>
          <ul>
            <li>
              Send you communications on behalf of the Providers and to facilitate the provision of
              health care services to you by the Providers;
            </li>
            <li>
              Invite you to participate in IRB-approved research studies performed by Betr. This
              research may be sponsored by, on behalf of, or in collaboration with third parties,
              such as academic institutions, nonprofit organizations, and pharmaceutical companies.
              If you agree to participate in such a study, we will use the information we receive
              for the purposes of the study and share it with study sponsors and collaborators. To
              participate in such a study, you will need to agree to an IRB-approved consent form
              that Betr provides to you. The consent form will provide additional, detailed
              information about the study. If you have not provided your express consent to
              participate in Betr’s IRB-approved research studies, Betr will not use your
              information provided through the Service for such IRB-approved studies. Betr will,
              however, use information for other research purposes as described in this Policy.
            </li>
          </ul>
          </Box>
          <Box className={classes.sectionContent}>
          In addition to the disclosures in Section 3 of this Policy, we may disclose your
          information to third parties in the following circumstances:
          </Box>
          <Box className={classes.sectionContent}>
          <ul>
            <li>
              Information on your progress is shared with your support system, including your
              nutrition coach (“<strong>Program Lead</strong>”) through the portion of the Service
              available to Program Leads (“<strong>Program Lead Portal</strong>”). Your Program Lead
              will have full access to all program data through the Program Lead Portal to provide
              personalized coaching services.
            </li>
            <li>
              In the event that a user discloses, or a Program Lead observes, activities that
              present a concern for the safety or well-being of the user, such as hypertension, a
              sharp increase or decrease in blood glucose levels, rapid weight loss or gain or other
              unhealthy weight loss practices, mental health issues, or physical or mental abuse,
              Betr may alert the appropriate health-care professional and disclose relevant personal
              information as Betr, in its sole discretion, deems necessary or otherwise appropriate.
            </li>
          </ul>
          </Box>
          <Box className={classes.sectionContent}>
          Claims and Pharmacy Data. We seek to give you better service and care. To help us give you
          the right care, in the right place and at the right time, a group health plan (“Health
          Plan”) may be created and, at the direction of your Health Plan, any agent, contractor or
          vendor of your Health Plan may electronically share information with us about your care.
          This information will include things like visits to the doctor or hospital, medical
          conditions, and prescriptions you have had in the past and moving forward. Having this
          information will help Betr give you the best possible care, because we will have the most
          up-to-date information about your health. Your privacy is very important to us, and you
          control the use of your personal information. We put important safeguards in place to make
          sure all your medical information is safe. By agreeing to this Policy, you agree to allow
          your Health Plan to share your personal health information with Betr, except for
          information that the Health Plan cannot share including, but not limited to, certain
          information relating to alcohol or substance abuse treatment. Protected Health
          Information, HIPAA and Communications. Some of the information you submit or that is
          created through your use of the Service may constitute “protected health information” (“
          <strong>PHI</strong>”) as defined by the Health Insurance Portability and Accountability
          Act (“<strong>HIPAA</strong>”). PHI does not include information that has been
          de-identified in accordance with HIPAA. Even though Betr is not a “covered entity” as
          defined by HIPAA, Provider and/or a Provider Group may be a “covered entity” and therefore
          a Provider, Provider Group and/or Betr may be subject to certain requirements under HIPAA.
          As a result, Provider Group has adopted a HIPAA Notice of Privacy Practices (the “
          <strong>Notice of Privacy Practices</strong>”) to describe how Provider Group and the
          Providers use and disclose your PHI. In connection with certain services we provide to or
          on behalf of the Provider Group and/or the Providers, Provider Group has requested that we
          disseminate to you the Notice of Privacy Practices of Provider Group and the Providers,
          which is currently available at &nbsp;
          <a href="https://www.betrhealth.com/privacy-practice">
            https://www.Betrhealth.com/privacy-practice
          </a>
          . Betr may be a “business associate” of Provider Group or a Provider under HIPAA, and as a
          result, as and to the extent Betr is in fact a “business associate” of Provider Group or
          the Provider(s) under HIPAA, Betr’s use and disclosure of PHI will be limited as and to
          the extent required by HIPAA, which may include, for example, providing you with certain
          communications via emails, text messages or in app messages that contain PHI, such as
          appointment reminders, encouragement, and advice.
          <strong>
            &nbsp;If you would prefer not to exchange PHI via email or text message, please notify
            us at
          </strong>
          <a href="mailto:privacy@betrhealth.com">
            <strong>&nbsp;privacy@Betrhealth.com</strong>
          </a>
          <strong>.</strong>&nbsp;You may also request we update, correct, or delete your PHI by
          contacting us at<a href="mailto:privacy@betrhealth.com">&nbsp;privacy@Betrhealth.com</a>;
          provided however, that we may retain any PHI that we are required to maintain in
          accordance with HIPAA. Any information that does not constitute PHI may be used or
          disclosed in any manner permitted under this Policy.{' '}
          </Box>
        </div>
        <div className={classes.footer}>
        <div className="logo">
          <img
            className={classes.footerImage}
            decoding="async"
            src="https://sp-ao.shortpixel.ai/client/to_webp,q_lossless,ret_img/https://betrhealth.com/wp-content/uploads/2022/08/footer-logo.png"
            alt="Logo"
          />
        </div>
        <div className={classes.footerNav}>
          {/* <div className={classes.footerNav}> */}
          <p>Terms of Service</p>
          <p>Privacy Policy</p>
          <p>Betr for Business</p> {/* </div> */}
          <p className="copyright">© 2023 Betr Health</p>
        </div>
      </div>
      </Container>
      
    </>
  );
};

Privacy.propTypes = {};

export default Privacy;
