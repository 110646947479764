import React, {useEffect, useRef, useState} from 'react';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import BowelIcon from '../../../../assets/icons/side-menu-bar-logging/bowel-icon.svg';
import {Button} from '../../button/Button';
import useStyles from './bowel-form.styles';
import {Typography} from '@material-ui/core';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {FormType, WidgetType} from '../../../../utils/enums';
import {ValidationType} from '../../../../validation/ValidationType';
import {getError, hasError, isFormValidToSubmit, validate} from '../../../../validation/Validation';
import moment from 'moment';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {selectBowelTags} from '../../../../store/selectors/loggingPageSelector';
import TagsWithBox from '../../tags-with-box/TagsWithBox';
import {ICasualTag} from '../../../../models/ITag';

import {
  addBowelLog,
  editBowelLogg,
} from '../../../../store/actions/logging-page/logging-page-action';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import useCommonStyles from '../common-for-widgets.styles';
import {DATE_PICKER_DATE_FORMAT} from '../../../../utils/constants';

interface IBowelFormData {
  date: string;
  time: string;
  tags: ICasualTag[];
}
interface IBowelFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  openedFromDialog?: boolean;
  date?: string;
  isMobile: boolean;
}

const validationRules = {
  date: [{type: ValidationType.REQUIRED}],
  time: [{type: ValidationType.REQUIRED_DURATION}],
};

const BowelForm: React.FC<IBowelFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  const [data, setData] = useState<IBowelFormData>({
    date: moment().toString(),
    time: moment().toString(),
    tags: [],
  });
  const formType = props.formType || FormType.ADDING_MODE;
  const initialTagList = useSelector(selectBowelTags);
  const [tagsList, setTagsList] = useState<ICasualTag[]>([]);
  const tagsBoxRef: any = useRef();

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }
    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    const setTags = () => {
      initialTagList.forEach((item) => (item.isVisible = true));
      const filteredTagsArray: ICasualTag[] = [];
      initialTagList.forEach((initialTagObject) => {
        props.data?.tagsObject.forEach((oneTagObject: ICasualTag) => {
          if (initialTagObject.id === oneTagObject.id) {
            initialTagObject.isVisible = false;
          }
        });

        filteredTagsArray.push(initialTagObject);
      });

      setTagsList(filteredTagsArray);
    };

    let initialObject: IBowelFormData = {
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
      tags: [],
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
        tags: props.data?.tagsObject || [],
      };
    }
    if (props.data && props.data?.tags?.length > 0) {
      setTags();
    } else {
      initialTagList.forEach((item) => (item.isVisible = true));
      setTagsList(initialTagList);
    }
    setData(initialObject);
  }, [props.data, initialTagList]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    initialTagList.forEach((item) => (item.isVisible = true));
    setTagsList(initialTagList);
    tagsBoxRef?.current?.clearSelectedTags();
    setData({
      date: moment().toString(),
      time: moment().toString(),
      tags: [],
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const selectedTags = tagsBoxRef?.current?.getSelectedTags().map((tag: ICasualTag) => tag.id);
      const bowelLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        tags: selectedTags,
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addBowelLog(bowelLog));
        setIsLoading(false);
      } else {
        res = await dispatch(editBowelLogg(bowelLog));
        setIsLoading(false);
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img className={commonClasses.imageMargin} src={BowelIcon} alt={'bowel-icon'} />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.bowelWidget.formTitleEdit')
              : t('LogsPage.bowelWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.bowelWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.bowelWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <div className={classes.tagsContainer}>
        <Typography variant="caption">{t('LogsPage.bowelWidget.tagsLabel')}</Typography>
        <TagsWithBox
          id={'tags-with-box-id'}
          name={'tags-with-box-id'}
          originalTagsList={tagsList}
          ref={tagsBoxRef}
        />
      </div>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-bowel-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-bowel-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.bowelWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default BowelForm;
