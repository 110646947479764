import {createReducer} from '../../utils/helpers';
import {IWeightDto} from '../../dtos/IWeightDto';
import * as loggingActionTypes from '../actions/logging-page/logging-page-types';
import {
  IDeleteActivityLog,
  IDeleteBowelLog,
  IDeleteFoodLogg,
  IDeleteSleepLog,
  IDeleteStressLog,
  IDeleteDrinkLog,
  IDeleteWeightLogg,
  ILoadActivityLoggs,
  ILoadActivityTags,
  ILoadBowelLoggs,
  ILoadBowelTags,
  ILoadCategoryTags,
  ILoadFoodLoggs,
  ILoadSleepLoggs,
  ILoadSleepTags,
  ILoadStressLoggs,
  ILoadStressTags,
  ILoadDrinkLogs,
  ILoadWeightLoggs,
  IDeleteStepsLog,
  ILoadStepsLogs,
  IDeleteBloodPressureLog,
  ILoadBloodPressureLogs,
  ILoadBodyMeasurementLogs,
  IDeleteBodyMeasurementLog,
  ILoadUsersTags,
  ICheckAchievement,
  ILoadMoodTags,
  IDeleteBloodGlucoseLog,
  ILoadBloodGlucoseLogs,
  IDeleteCholesterolLog,
  ILoadCholesterolLogs,
  IDeleteA1CLog,
  ILoadA1CLogs,
  IDeleteBodyPainLog,
  ILoadBodyPainLogs,
} from '../actions/logging-page/logging-page-action';
import {ICategoryTagDto} from '../../dtos/ICategoryTagDto';
import {IFoodDto} from '../../dtos/IFoodDto';
import {IActivityDto} from '../../dtos/IActivityDto';
import {ICasualTag} from '../../models/ITag';
import {IStressDto} from '../../dtos/IStressDto';
import {IDrinkDto} from '../../dtos/IDrinkDto';
import {IBowelDto} from '../../dtos/IBowelDto';
import {ISleepDto} from '../../dtos/ISleepDto';
import {IStepsDto} from '../../dtos/IStepDto';
import {IBodyMeasurementDto} from '../../dtos/IBodyMeasurementDto';
import {IBloodPressureDto} from '../../dtos/IBloodPressureDto';
import {IBloodGlucoseDto} from '../../dtos/IBloodGlucoseDto';
import {IAchievementDto} from '../../dtos/IAchievementDto';
import {ICholesterolDto} from '../../dtos/ICholesterolDto';
import {IA1CDto} from '../../dtos/IA1CDto';
import {IBodyPainDto} from '../../dtos/IBodyPainDto';

export interface ILoggingPageState {
  loggedWeights: IWeightDto[] | [];
  loggedFoods: IFoodDto[] | [];
  categoryTags: ICategoryTagDto[] | [];
  loggedActivity: IActivityDto[] | [];
  activityTags: ICasualTag[] | [];
  loggedStress: IStressDto[] | [];
  stressTags: ICasualTag[] | [];
  moodTags: ICasualTag[] | [];
  loggedSleep: ISleepDto[] | [];
  sleepTags: ICasualTag[] | [];
  loggedBowel: IBowelDto[] | [];
  bowelTags: ICasualTag[] | [];
  loggedDrink: IDrinkDto[] | [];
  loggedSteps: IStepsDto[] | [];
  loggedBloodPressure: IBloodPressureDto[] | [];
  loggedBloodGlucose: IBloodGlucoseDto[] | [];
  userTags: ILoadUsersTags[] | [];
  loggedBodyMeasurements: IBodyMeasurementDto[] | [];
  loggedBodyPain: IBodyPainDto[] | [];
  achievement: IAchievementDto | null;
  loggedCholesterol: ICholesterolDto[] | [];
  loggedA1C: IA1CDto[] | [];
}

const initialState: ILoggingPageState = {
  loggedWeights: [],
  loggedFoods: [],
  categoryTags: [],
  loggedActivity: [],
  activityTags: [],
  loggedStress: [],
  stressTags: [],
  moodTags: [],
  loggedDrink: [],
  loggedSleep: [],
  sleepTags: [],
  loggedBowel: [],
  bowelTags: [],
  loggedSteps: [],
  loggedBloodPressure: [],
  loggedBloodGlucose: [],
  userTags: [],
  loggedBodyMeasurements: [],
  achievement: null,
  loggedA1C: [],
  loggedCholesterol: [],
  loggedBodyPain: [],
};

const loggingPageReducer = createReducer(initialState, {
  [loggingActionTypes.LOAD_WEIGHT_LOG_DATA](state: ILoggingPageState, action: ILoadWeightLoggs) {
    return {
      ...state,
      loggedWeights: action.payload,
    };
  },
  [loggingActionTypes.DELETE_WEIGHT_LOGG](state: ILoggingPageState, action: IDeleteWeightLogg) {
    const newState = state.loggedWeights.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedWeights: newState,
    };
  },
  [loggingActionTypes.LOAD_CATEGORY_TAGS](state: ILoggingPageState, action: ILoadCategoryTags) {
    return {
      ...state,
      categoryTags: action.payload,
    };
  },

  [loggingActionTypes.LOAD_USERS_TAGS](state: ILoggingPageState, action: ILoadUsersTags) {
    return {
      ...state,
      userTags: action.payload,
    };
  },

  [loggingActionTypes.LOAD_FOOD_LOG_DATA](state: ILoggingPageState, action: ILoadFoodLoggs) {
    return {
      ...state,
      loggedFoods: action.payload,
    };
  },
  [loggingActionTypes.DELETE_FOOD_LOGG](state: ILoggingPageState, action: IDeleteFoodLogg) {
    const newState = state.loggedFoods.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedFoods: newState,
    };
  },
  [loggingActionTypes.LOAD_ACTIVITY_LOG](state: ILoggingPageState, action: ILoadActivityLoggs) {
    return {
      ...state,
      loggedActivity: action.payload,
    };
  },
  [loggingActionTypes.DELETE_ACTIVITY_LOG](state: ILoggingPageState, action: IDeleteActivityLog) {
    const newState = state.loggedActivity.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedActivity: newState,
    };
  },
  [loggingActionTypes.LOAD_ACTIVITY_TAGS](state: ILoggingPageState, action: ILoadActivityTags) {
    return {
      ...state,
      activityTags: action.payload,
    };
  },

  [loggingActionTypes.LOAD_STRESS_TAGS](state: ILoggingPageState, action: ILoadStressTags) {
    return {
      ...state,
      stressTags: action.payload,
    };
  },
  [loggingActionTypes.LOAD_MOOD_TAGS](state: ILoggingPageState, action: ILoadMoodTags) {
    return {
      ...state,
      moodTags: action.payload,
    };
  },
  [loggingActionTypes.LOAD_STRESS_LOG](state: ILoggingPageState, action: ILoadStressLoggs) {
    return {
      ...state,
      loggedStress: action.payload,
    };
  },
  [loggingActionTypes.DELETE_STRESS_LOG](state: ILoggingPageState, action: IDeleteStressLog) {
    const newState = state.loggedStress.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedStress: newState,
    };
  },

  [loggingActionTypes.DELETE_DRINK_LOG](state: ILoggingPageState, action: IDeleteDrinkLog) {
    const newState = state.loggedDrink.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedDrink: newState,
    };
  },
  [loggingActionTypes.LOAD_DRINK_LOG](state: ILoggingPageState, action: ILoadDrinkLogs) {
    return {
      ...state,
      loggedDrink: action.payload,
    };
  },
  [loggingActionTypes.LOAD_BOWEL_TAGS](state: ILoggingPageState, action: ILoadBowelTags) {
    return {
      ...state,
      bowelTags: action.payload,
    };
  },
  [loggingActionTypes.LOAD_BOWEL_LOG](state: ILoggingPageState, action: ILoadBowelLoggs) {
    return {
      ...state,
      loggedBowel: action.payload,
    };
  },
  [loggingActionTypes.DELETE_BOWEL_LOG](state: ILoggingPageState, action: IDeleteBowelLog) {
    const newState = state.loggedBowel.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedBowel: newState,
    };
  },
  [loggingActionTypes.LOAD_SLEEP_LOG](state: ILoggingPageState, action: ILoadSleepLoggs) {
    return {
      ...state,
      loggedSleep: action.payload,
    };
  },
  [loggingActionTypes.LOAD_SLEEP_TAGS](state: ILoggingPageState, action: ILoadSleepTags) {
    return {
      ...state,
      sleepTags: action.payload,
    };
  },
  [loggingActionTypes.DELETE_SLEEP_LOG](state: ILoggingPageState, action: IDeleteSleepLog) {
    const newState = state.loggedSleep.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedSleep: newState,
    };
  },
  [loggingActionTypes.DELETE_STEPS_LOG](state: ILoggingPageState, action: IDeleteStepsLog) {
    const newState = state.loggedSteps.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedSteps: newState,
    };
  },
  [loggingActionTypes.LOAD_STEPS_LOG](state: ILoggingPageState, action: ILoadStepsLogs) {
    return {
      ...state,
      loggedSteps: action.payload,
    };
  },

  [loggingActionTypes.DELETE_BLOOD_PRESSURE_LOG](
    state: ILoggingPageState,
    action: IDeleteBloodPressureLog,
  ) {
    const newState = state.loggedBloodPressure.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedBloodPressure: newState,
    };
  },
  [loggingActionTypes.LOAD_BLOOD_PRESSURE_LOG](
    state: ILoggingPageState,
    action: ILoadBloodPressureLogs,
  ) {
    return {
      ...state,
      loggedBloodPressure: action.payload,
    };
  },

  [loggingActionTypes.DELETE_BLOOD_GLUCOSE_LOG](
    state: ILoggingPageState,
    action: IDeleteBloodGlucoseLog,
  ) {
    const newState = state.loggedBloodGlucose.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedBloodGlucose: newState,
    };
  },
  [loggingActionTypes.LOAD_BLOOD_GLUCOSE_LOG](
    state: ILoggingPageState,
    action: ILoadBloodGlucoseLogs,
  ) {
    return {
      ...state,
      loggedBloodGlucose: action.payload,
    };
  },

  [loggingActionTypes.LOAD_BODY_MEASUREMENT_LOG](
    state: ILoggingPageState,
    action: ILoadBodyMeasurementLogs,
  ) {
    return {
      ...state,
      loggedBodyMeasurements: action.payload,
    };
  },
  [loggingActionTypes.DELETE_BODY_MEASUREMENT_LOG](
    state: ILoggingPageState,
    action: IDeleteBodyMeasurementLog,
  ) {
    const newState = state.loggedBodyMeasurements.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedBodyMeasurements: newState,
    };
  },
  [loggingActionTypes.CHECK_ACHIEVEMENT](state: ILoggingPageState, action: ICheckAchievement) {
    return {
      ...state,
      achievement: action.payload,
    };
  },
  [loggingActionTypes.DELETE_CHOLESTEROL_LOG](
    state: ILoggingPageState,
    action: IDeleteCholesterolLog,
  ) {
    const newState = state.loggedCholesterol.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedCholesterol: newState,
    };
  },
  [loggingActionTypes.LOAD_CHOLESTEROL_LOG](
    state: ILoggingPageState,
    action: ILoadCholesterolLogs,
  ) {
    return {
      ...state,
      loggedCholesterol: action.payload,
    };
  },
  [loggingActionTypes.DELETE_A1C_LOG](state: ILoggingPageState, action: IDeleteA1CLog) {
    const newState = state.loggedA1C.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedA1C: newState,
    };
  },
  [loggingActionTypes.LOAD_A1C_LOG](state: ILoggingPageState, action: ILoadA1CLogs) {
    return {
      ...state,
      loggedA1C: action.payload,
    };
  },
  [loggingActionTypes.DELETE_BODY_PAIN_LOG](state: ILoggingPageState, action: IDeleteBodyPainLog) {
    const newState = state.loggedBodyPain.filter((item) => item.id != action.payload);
    return {
      ...state,
      loggedBodyPain: newState,
    };
  },
  [loggingActionTypes.LOAD_BODY_PAIN_LOG](state: ILoggingPageState, action: ILoadBodyPainLogs) {
    return {
      ...state,
      loggedBodyPain: action.payload,
    };
  },
});

export default loggingPageReducer;
