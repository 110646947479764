import React from 'react';
import {useDispatch} from 'react-redux';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import {Tag} from '../../tags-with-box/Tag';
import * as sleepLoggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';
import {formatDuration} from '../../../../utils/StringHelpers';

import useStyles from './sleep-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface SleepRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
}

const SleepRecentlyAddedRow: React.FC<SleepRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const handleDismiss = () => {
    dispatch(sleepLoggingActions.deleteSleepLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };
  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        <div className={!isMobile ? commonClasses.infoContainer : ''}>
          <DateTimeString date={data.date} isMobile={isMobile} />
          <Typography variant={'subtitle1'} className={classes.textContent}>
            {formatDuration(data.duration)}
          </Typography>
          {data?.tagsObject?.length > 0 && (
            <div className={classes.tagsContainer}>
              {data.tagsObject.map((oneTag: any) => (
                <Tag
                  key={`tag-selected-${oneTag.id}`}
                  name="chip"
                  isSelected={true}
                  id={oneTag.id || 0}
                  label={oneTag.name}
                />
              ))}
            </div>
          )}
        </div>
        <div className={commonClasses.buttonsContainer}>
          <EllipsisOptions
            handleEdit={handleEdit}
            handleDismiss={handleDismiss}
            fromDevice={data.fromDevice}
          />
        </div>
      </div>
    </div>
  );
};
export default SleepRecentlyAddedRow;
