import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import history from '../../../history';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {Button} from '../../../common/components/button/Button';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';

// import LeafIcon from '../../../assets/icons/landing-page/leaf.svg';
import {MOBILE_SIZE, TABLET_SMALL_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';
import {getUserHomePath} from '../../../routes';
import {buildUrlLinkMiniOrange} from '../../../api/users.api';

import useStyles from './landing-page.styles';

const LandingPage: React.FC = () => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  const user = useSelector(selectCurrentUser);
  const [t] = useTranslation();

  useEffect(() => {
    if (user) {
      history.push(getUserHomePath(user));
    }
  }, [user, history]);

  const callSignInMiniOrange = () => {
    buildUrlLinkMiniOrange();
  };

  return (
    <div className={classes.posterContainer}>
      <div className={classes.textContainer}>
      <Typography className={classes.landingText}>You Deserve</Typography>
        <div className={`${classes.landingPageIcon} ${classes.logoIcon}`}>
          <img src={BetterHealthIcon} alt="better health logo" />
        </div>
        {/* <div className={`${classes.landingPageIcon} ${classes.leafIcon}`}>
          <img src={LeafIcon} alt="leaf" />
        </div> */}

        {/* <Typography className={classes.landingText}>{t('LandingPage.content')}</Typography> */}

        <Button id="log-in-button" className={classes.LogInButton} onClick={callSignInMiniOrange}>
          {t('LandingPage.logInMiniOrange')}
        </Button>
      </div>
    </div>
  );
};

LandingPage.displayName = 'Landing Page';
export default LandingPage;
