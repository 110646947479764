export enum ValidationType {
  REQUIRED,
  REQUIRED_DURATION,
  IS_INT_NUMBER,
  IS_DECIMAL_NUMBER,
  BIRTH_DATE,
  IS_GREATER,
  COMPARE_DATES,
  IS_EMAIL,
  IS_PHONE_NUMBER,
  IS_STRONG_PASSWORD,
}
