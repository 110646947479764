import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  caption: {
    color: `${theme.palette.blacksAndWhites.sonicSilver} !important`,
  },
  heightMargin: {
    marginRight: 4,
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default useStyles;
