import {Button as MaterialButton} from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import useStyles from './button.styles';
import Loader from '../loader/Loader';

interface Props {
  variant?: VariantName;
  loading?: boolean;
  className?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  id: string;
  startIcon?: React.ReactNode;
}

type VariantName = 'text' | 'contained' | 'outlined';

type VariantType = {
  props: {
    variant: VariantName;
  };
  class: VariantName;
};

const variants: {[k in VariantName]: VariantType} = {
  text: {
    props: {
      variant: 'text',
    },
    class: 'text',
  },
  contained: {
    props: {
      variant: 'contained',
    },
    class: 'contained',
  },
  outlined: {
    props: {
      variant: 'outlined',
    },
    class: 'outlined',
  },
};

export const Button: React.FC<Props> = ({
  children,
  variant = 'contained',
  loading,
  onClick,
  ...rest
}) => {
  const classes = useStyles();
  const variantData: VariantType = variants[variant];
  return (
    <MaterialButton
      {...variantData.props}
      size="medium"
      color="primary"
      // disableRipple
      {...rest}
      className={classNames(classes[variantData.class], rest.className)}
      onClick={onClick}
    >
      {loading ? <Loader size={20} loaderColor={variant === 'contained'} /> : children}
    </MaterialButton>
  );
};
