import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
// import BetterHealthIcon from '../../../assets/icons/landing-page/High_Res_Hands_Up.jpg';
import BetterHealthIcon from '../../../assets/icons/landing-page/raiseHand.webp';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  posterContainer: {
    height: '100vh',
    width: '100vw',
    backgroundSize: 'cover', // Ensure the image covers the entire container
    backgroundPosition: 'center 100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage:`url(${BetterHealthIcon}) !important`,
    background: `linear-gradient(180.55deg, #EBEFFF 0.54%, #EBF4FF 33.04%, #EBFFFF 78.95%, #F2FCF6 99.59%)`,
    paddingTop: (props) =>
      props.isMobileOrTablet.isMobile
        ? '10vh'
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? '15vh'
        : '10vh',
  },
  textContainer: {
    color: theme.palette.primary.main,
    maxWidth: '100%',
  },
  landingPageIcon: {
    textAlign: 'center',
  },
  logoIcon: {
    height: (props) => (props.isMobileOrTablet.isMobile ? '34px' : '45px'),
    '& img': {
      height: '100%',
    },
  },
  leafIcon: {
    height: (props) => (props.isMobileOrTablet.isMobile ? '45px' : '60px'),
    marginTop: (props) =>
      props.isMobileOrTablet.isMobile
        ? '10vh'
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? '15vh'
        : '10vh',
    marginBottom: (props) =>
      props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig ? '60px' : '32px',
    '& img': {
      height: '100%',
    },
  },
  landingText: {
    fontFamily: "Gantari, sans-serif",
    fontStyle: 'normal',
    fontSize: (props) => (props.isMobileOrTablet.isMobile ? '28px' : '40px'),
    lineHeight: (props) => (props.isMobileOrTablet.isMobile ? '32px' : '44px'),
    fontWeight: 400,
    letterSpacing: (props) => (props.isMobileOrTablet.isMobile ? '' : '-0.5px'),
    textAlign: 'center',
    width: (props) =>
      props.isMobileOrTablet.isMobile
        ? '286px'
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? '464px'
        : '449px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  LogInButton: {
    width: (props) => (props.smallSize ? 311 : 193),
    height: (props) => (props.smallSize ? 48 : 64),
    color: "#000000",
    position: 'absolute',
    left: '50%',
    backgroundColor:'#ffffff',
    bottom: (props) =>
      props.isMobileOrTablet.isMobile
        ? '30vh'
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? '20vh'
        : '28vh',
    transform: 'translateX(-50%)',
    fontFamily: "Gantari, sans-serif",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: (props) => (props.isMobileOrTablet.isMobile ? '14px' : '24px'),
    lineHeight: (props) => (props.isMobileOrTablet.isMobile ? '17px' : '32px'),
    '&:hover': {
      backgroundColor:"#000000",
      color: "#ffffff",
    },
  },
}));

export default useStyles;
