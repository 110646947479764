import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    totalScore: {
        background: 'radial-gradient(105.09% 77.78% at 46.85% 41.53%, #FFFFFF 0%, #EBFFFF 46.69%, #C5DFFC 74.44%, #D2DAFA 79.17%)',
        textAlign: 'center',
        borderRadius: 12,
        padding: '24px 16px',
        '& h3': {
            margin: '0 0 20px 0',
            fontSize: 24,
        },
        '& h4': {
            margin: '0 0 20px 0',
            fontSize: 16,
            color: '#140802'
        },
        '& p': {
            margin: 0,
            fontSize: 16,
            color: '#140802',
            fontWeight: 600
        },
        '& .grayText': {
            marginTop: 20,
            fontSize: 10,
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: '#5C5754'
        },
        '& .bottomScore': {
            display: 'flex',
            justifyContent: 'space-between',
        }
    }
  });

  export default useStyles;