import * as kemtaiActions from './kemtai-types';
import {Dispatch} from 'redux';
import {
  getKemtaiUserAchievementApi,
  getKemTaiUserChallengeScoreApi,
  getKemTaiUserWorkoutExercisesApi,
  getKemTaiUserWorkoutHistoryApi,
  getKemTaiUserWorkoutsApi,
  getKemTaiUserWorkoutsListApi,
  getKemTaiVideoURLApi,
  getKemTaiWorkoutDetailApi,
  getKemTaiWorkoutGroupsApi,
  postAchievementApi,
  postKemTaiUserWorkoutFeedbackApi,
  postKemTaiUserWorkoutHistoryApi,
  postKemTaiUserWorkoutResultApi,
  postUserExcerciseParticipationApi,
  postYogaIntercomApi,
} from '../../../api/kemtai.api';

export const getKemTaiWorkoutGroups = (
  userID: string | unknown | number,
  groupId: number,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const workoutGroups: any = await getKemTaiWorkoutGroupsApi(userID, groupId);
    if (groupId === 1) {
      await dispatch({
        type: kemtaiActions.LOAD_ROUTINES_WORKOUTGROUPS,
        payload: workoutGroups,
      });
    } else if (groupId === 4) {
      await dispatch({
        type: kemtaiActions.LOAD_YOGA_GROUPS,
        payload: workoutGroups,
      });
    } else {
      await dispatch({
        type: kemtaiActions.LOAD_CHALLENGES_WORKOUTGROUPS,
        payload: workoutGroups,
      });
    }
  };
};

export const getKemTaiUserWorkoutHistory = (userID: string | unknown | number, groupId: string | unknown | number): (() => Promise<void>) => {
  return async () => {
    return await getKemTaiUserWorkoutHistoryApi(userID, groupId);
  };
};

export const postKemTaiUserWorkoutHistory = (groupData: string | unknown | number) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async () => {
    return await postKemTaiUserWorkoutHistoryApi(groupData);
  };
};
export const postYogaIntercomSlug = (groupData: string | unknown | number) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async () => {
    return await postYogaIntercomApi(groupData);
  };
};

export const getKemTaiUserWorkouts = (workoutGroupID: string | unknown | number, workoutID?: string | unknown | number) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async () => {
    return await getKemTaiUserWorkoutsApi(workoutGroupID, workoutID);
  };
};

export const getKemTaiUserWorkoutsList = (
  userID: string | unknown | number,
  workoutGroupID?: string | unknown | number,
  workoutID?: string | unknown | number,
  WorkoutGroupSlug?: string | unknown | number,
  WorkoutSlug?: string | unknown | number,
) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async () => {
    return await getKemTaiUserWorkoutsListApi(
      userID,
      workoutGroupID,
      workoutID,
      WorkoutGroupSlug,
      WorkoutSlug,
    );
  };
};

export const getKemTaiVideoURL = (kemtaiId: string | unknown | number) => {
  return async () => {
    return await getKemTaiVideoURLApi(kemtaiId);
  };
};

export const getKemTaiWorkoutDetail = (kemtaiId: string | unknown | number) => {
  return async () => {
    return await getKemTaiWorkoutDetailApi(kemtaiId);
  };
};

export const getKemTaiUserWorkoutExercises = (userID: string | unknown | number) => {
  return async () => {
    return await getKemTaiUserWorkoutExercisesApi(userID);
  };
};

export const postKemTaiUserWorkoutFeedback = (feedbackDetail: string | unknown | number) => {
  return async () => {
    return await postKemTaiUserWorkoutFeedbackApi(feedbackDetail);
  };
};

export const postKemTaiUserWorkoutResult = (completedDetail: string | unknown | number) => {
  return async () => {
    return await postKemTaiUserWorkoutResultApi(completedDetail);
  };
};

export const getKemTaiUserChallengeScore = (userID: string | unknown | number, workoutGroupID: string | unknown | number) => {
  return async () => {
    return await getKemTaiUserChallengeScoreApi(userID, workoutGroupID);
  };
};

export const getKemtaiUserAchievement = (userID: string | unknown | number, workoutGroupID: string | unknown | number) => {
  return async () => {
    return await getKemtaiUserAchievementApi(userID, workoutGroupID);
  };
};

export const postAchievement = (achivementPayload: string | unknown | number) => {
  return async () => {
    return await postAchievementApi(achivementPayload);
  };
};

export const postUserExcerciseParticipation = (payload: string | unknown | number) => {
  return async () => {
    return await postUserExcerciseParticipationApi(payload);
  };
};
