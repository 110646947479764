import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {handleMargin} from '../../../utils/css-helpers';

interface Props {
  isMobileOrTablet: IResponsiveSize;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  widgetContainer: {
    scrollMargin: '80px',
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 80,
  },
  mainCenterContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: (props) =>
      props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTabletSmall
        ? '100%'
        : props.isMobileOrTablet.isTabletBig
        ? 840
        : 628,
    margin: (props) => handleMargin(props.isMobileOrTablet, '35px'),
  },
  content: {
    minHeight: 200,
    background: theme.palette.background.paper,
    width: '100%',
    margin: '0px 24px',
    marginBottom: 15,
  },
  notificationPanel: {
    marginLeft: 24,
    minWidth: 310,
  },
}));

export default useStyles;
