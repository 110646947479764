import * as React from 'react';
import {Fragment, ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {isMobile} from 'react-device-detect';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {AccordionDetails, useMediaQuery} from '@material-ui/core';
import {SideMenuSelectedType} from '../../../utils/enums';
import {RecipesFilter} from '../../../pages/general/nourish-page/RecipesFilter';
import {MealPlansFilter} from '../../../pages/general/nourish-page/MealPlansFilter';
import {SideMenuBarItem} from './SideMenuBarItem';
import {ExpandIcon} from './ExpandIcon';
import {ExpandableContainer} from '../expandable-container/ExpandableContainer';
import {saveSelectedMenuItem} from '../../../store/actions/nourish/nourish-actions';
import {GeneralPaths, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './side-menu-bar-styles';
import {BetrProgramFilter} from '../../../pages/general/nourish-page/BetrProgramFilter';
import {MasterClassesFilter} from '../../../pages/general/nourish-page/MasterClassesFilter';
import { MovementFilter } from '../../../pages/general/nourish-page/MovementFilter';
import { useHistory } from 'react-router';

export interface IMenuItems {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  elementType: SideMenuSelectedType;
  isCollapsible?: boolean;
  hasFilter?: boolean;
}

interface Props {
  items: IMenuItems[];
  executeScrollOnElement?: any;
  onRecipesFilterChange: any;
  onMealPlansFilterChange: any;
  onBetrProgramFilterChange: any;
  onMasterClassesFilterChange: any;
  pageCallbackFunction?: any;
  initialStateInMenu?: SideMenuSelectedType;
  onMovementFilterChange?: any;
}

export const SideMenuBarBase: React.FC<Props> = ({
  items,
  onRecipesFilterChange,
  onMealPlansFilterChange,
  onBetrProgramFilterChange,
  onMasterClassesFilterChange,
  pageCallbackFunction,
  initialStateInMenu,
  onMovementFilterChange,
}) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const smallScreens = React.useMemo(() => {
    return isMobile || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    smallScreens,
  });
  const [selectedSideMenuItem, setSideMenuItem] = useState(SideMenuSelectedType.SIDEMENU_NONE);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectSideMenuItem = (selectedType: SideMenuSelectedType) => {
    if(selectedType === SideMenuSelectedType.SIDEMENU_MOVEMENT && !history?.location?.pathname.includes('/movement') && (selectedSideMenuItem === SideMenuSelectedType.SIDEMENU_INSIGHTS || selectedSideMenuItem === 'None')){
      history.push(`${GeneralPaths.MovementPage}/fitness`);
    }
    setSideMenuItem(selectedType);
    dispatch(saveSelectedMenuItem(selectedType));
    pageCallbackFunction && pageCallbackFunction(selectedType);
  };

  const selectSideMenuItemClick = (selectedType: SideMenuSelectedType) => {
    if(selectedType === 'Movement'){
      // if(!history?.location?.pathname.includes('/movement')){
        history.push(`${GeneralPaths.MovementPage}`);
      // }
    } else {
      if(!history?.location?.pathname.includes('/nourish')){
        history.push(GeneralPaths.NourishPage);
      }
    }
    setSideMenuItem(selectedType);
    dispatch(saveSelectedMenuItem(selectedType));
    pageCallbackFunction && pageCallbackFunction(selectedType);
  };

  useEffect(() => {
    if (initialStateInMenu) {
      if(history?.location?.pathname.includes('/movement')) {
        selectSideMenuItem(SideMenuSelectedType.SIDEMENU_MOVEMENT);
      } else {
        selectSideMenuItem(initialStateInMenu);
      }
    }
  }, [initialStateInMenu]);

  const returnFilterType = (menuType: SideMenuSelectedType): ReactNode => {
    switch (menuType) {
      case SideMenuSelectedType.SIDEMENU_RECIPES:
        return <RecipesFilter onFilterChange={(filters: any) => onRecipesFilterChange(filters)} />;

      case SideMenuSelectedType.SIDEMENU_MEALPLANS:
        return (
          <MealPlansFilter onFilterChange={(filters: any) => onMealPlansFilterChange(filters)} />
        );

      case SideMenuSelectedType.SIDEMENU_BETR_PROGRAM:
        return (
          <BetrProgramFilter
            onFilterChange={(filters: any) => onBetrProgramFilterChange(filters)}
          />
        );

      case SideMenuSelectedType.SIDEMENU_MASTER_CLASSES:
        return (
          <MasterClassesFilter
            onFilterChange={(filters: any) => onMasterClassesFilterChange(filters)}
          />
        );

      case SideMenuSelectedType.SIDEMENU_MOVEMENT:
          return <MovementFilter onFilterChange={(filters: any) => onMovementFilterChange(filters)} />;

      default:
        return <></>;
    }
  };

  return (
    <Fragment>
      <div className={classes.sideMenuBarContainer} role="menubar">
        {items.map((itemProps: IMenuItems, i) => (
          <React.Fragment key={i}>
            {!itemProps.isCollapsible && (
              <SideMenuBarItem
                {...itemProps}
                key={i}
                onClick={(elementType: any) => selectSideMenuItemClick(elementType)}
                selectedSideMenuItem={selectedSideMenuItem}
              />
            )}

            {itemProps.isCollapsible && (
              <ExpandableContainer
                className={itemProps.elementType === selectedSideMenuItem ? 'active' : ''}
                >
                <Accordion
                // style={itemProps?.text === 'Movement' ? {display: 'none'} : {}}
                // expanded={itemProps.elementType === 'Movement' && history?.location?.pathname.includes('/movement') ? true : false}
                {... itemProps.elementType === 'Movement' && history?.location?.pathname.includes('/movement') ? {expanded:true} : null}
                >
                  <AccordionSummary
                    onClick={() => selectSideMenuItemClick(itemProps.elementType)}
                    expandIcon={
                      <ExpandIcon clicked={itemProps.elementType === selectedSideMenuItem} />
                    }
                  >
                    <SideMenuBarItem
                      {...itemProps}
                      key={i}
                      onClick={(elementType: any) => selectSideMenuItemClick(elementType)}
                      selectedSideMenuItem={selectedSideMenuItem}
                    />
                  </AccordionSummary>
                  {itemProps.hasFilter && (
                    <AccordionDetails>{returnFilterType(itemProps.elementType)}</AccordionDetails>
                  )}
                </Accordion>
              </ExpandableContainer>
            )}
          </React.Fragment>
        ))}
      </div>
    </Fragment>
  );
};
