import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Linkify from 'linkify-react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Paper, Typography} from '@material-ui/core';
import {Button} from '../button/Button';
import {TextField} from '../text-field/TextField';
import {EllipsisOptionsCustom} from '../elipsis-options/EllipsisOptionsCustom';
import {EllipsisOptions} from '../elipsis-options/EllipsisOptions';
import NotesIcon from '../../../assets/icons/rest/notes-icon.svg';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import {selectMember, selectPaginationNotes} from '../../../store/selectors/homepage.selectors';
import {addUserNote, loadNotesPagination} from '../../../store/actions/homepage/homepage-action';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {INotesDto} from '../../../dtos/INotesDto';
import {AM_PM_FORMAT} from '../../../utils/constants';
import {stringToDateTimeFormatString} from '../../../utils/date-time-utils';
import {noteStringSplitter} from '../../../utils/StringHelpers';
import {returnRoleDisplay} from '../../../utils/users-managment';

import useStyles from './notes.styles';

interface INotesProps {
  note?: INotesDto;
  newNote: boolean;
  smallScreens: boolean;
  handleDismiss?: () => void;
}

const Notes: React.FC<INotesProps> = (props) => {
  const {note, newNote, smallScreens, handleDismiss} = props;
  const classes = useStyles({
    smallScreens,
  });
  const [t] = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const [data, setData] = useState<any>({
    note: '',
  });
  const [isFocused, setIsFocused] = React.useState(false);
  const [hasChange, setHasChange] = useState(false);
  const selectedMember = useSelector(selectMember);
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const loadedNotesPagination = useSelector(selectPaginationNotes);
  const [noteContent, setNoteContent] = useState('');
  const [loadMore, setLoadMore] = useState(false);
  const [editMood, setEditMood] = useState(false);

  useEffect(() => {
    if (note?.note && note?.note?.length > 167) {
      setNoteContent(noteStringSplitter(note.note)[0]);
      setLoadMore(true);
    } else setNoteContent(note?.note || '');
  }, [loadedNotesPagination, note]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
    setHasChange(true);
  };

  const handleCancel = () => {
    setIsFocused(false);
    setData({...data, note: ''});
  };

  const handleSubmit = async () => {
    const requestBodyData = {
      userId: selectedMember?.id,
      date: moment().format(),
      note: data.note,
    };
    setIsLoading(true);
    if (editMood && note?.id) {
      const editData = {
        id: note.id,
        note: data.note,
        date: moment().format(),
      };
      await dispatch(homepageActions.editNote(editData));
    } else {
      const res1 = await dispatch(addUserNote(requestBodyData));
      if (!res1) {
        return;
      }
    }
    setIsLoading(false);
    handleCancel();
    await dispatch(loadNotesPagination(selectedMember?.id, 0, loadedNotesPagination.pageSize));
    setEditMood(false);
    if (handleDismiss) {
      handleDismiss();
    }
  };

  const handleLoadMore = () => {
    setNoteContent(note?.note || '');
    setLoadMore(false);
  };

  const handleEditNote = () => {
    setEditMood(true);
    setData({note: note?.note});
  };
  const handleDeleteNote = () => {
    if (note) {
      dispatch(homepageActions.deleteNote(note.id));
    }
  };

  const displayTitle = () => {
    if (newNote) {
      return t('Homepage.notes.new');
    }
    if (editMood) {
      return t('Homepage.notes.editNote');
    }
    return props.smallScreens ? t('Homepage.notes.note') : t('Homepage.notes.coach');
  };

  return (
    <Paper className={classes.card} elevation={0}>
      <div className={classes.header}>
        <div className={classes.title}>
          {!props.smallScreens && (
            <img className={classes.headerIcon} src={NotesIcon} alt="notes-icon" />
          )}
          <Typography variant={'subtitle2'}>{displayTitle()}</Typography>
        </div>
        {!newNote && note?.createdBy === currentUser.id && (
          <EllipsisOptionsCustom
            imageClass={classes.ellipsisImage}
            optionsClass={classes.ellipsisOptions}
            messageBox={
              <EllipsisOptions
                handleDismiss={() => handleDeleteNote()}
                handleEdit={() => handleEditNote()}
                optionsClassName={classes.optionsItemIcon}
              />
            }
          />
        )}
      </div>
      {newNote || editMood ? (
        <>
          <TextField
            name="note"
            multiline
            maxRows={20}
            minRows={10}
            value={data.note}
            onChange={handleChange}
            className={classes.noteInput}
            focusclass={classes.inputOnFocus}
            placeholder={t('Homepage.notes.placeholder')}
            onFocus={() => setIsFocused(true)}
            isFocused={isFocused}
            containerClass={classes.fieldContainer}
          />
          {isFocused && (
            <>
              <div className={classes.buttonContainer}>
                <Button
                  id="cancel-note"
                  className={classes.button}
                  variant="outlined"
                  onClick={handleCancel}
                >
                  {t('Homepage.notes.cancel')}
                </Button>
                <Button
                  id="submit-note"
                  disabled={!hasChange}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {t('Homepage.notes.submit')}
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <Typography variant="body2" className={classes.coach}>{`by ${note?.createdByName} (${
            note?.role && returnRoleDisplay(note?.role)
          })`}</Typography>
          <Typography variant={'body1'} className={classes.content} component="div">
            <Linkify options={{target: '_blank'}}>{noteContent}</Linkify>
          </Typography>
          {loadMore && (
            <Button
              id="load-more-note-content"
              onClick={handleLoadMore}
              variant="text"
              className={classes.loadMore}
            >
              {t('Homepage.sideMenuBar.loadMore')}
            </Button>
          )}
          <Typography align="right" variant="caption" className={classes.noteDate}>
            {stringToDateTimeFormatString(note?.date, AM_PM_FORMAT)}
          </Typography>
        </>
      )}
    </Paper>
  );
};

export default Notes;
