import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    modalContainr: {
        position: 'absolute' as const,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99,
        '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
        },
        '& .closePopup': {
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: "1",
            background: 'none',
            padding: 0,
            boxShadow: 'unset'
        },
        '& .whole-screen': {
            position: "absolute",
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        },
        '& .notifications':  {
            'z-index': 1,
            position: 'absolute',
            width: '30%',
            height: '30%',
            bottom: 0,
            right: 0,
            backgroundColor: 'rgb(218, 221, 20)',
        }
    }
  });

  export default useStyles;