import * as filterTypes from './filter-types';

export interface ISetSelectedFilters {
    type: typeof filterTypes.SET_SELECTED_CONNECTION_FILTERS;
    payload: any
}

export const setSelectedFilters = (payload: any): ISetSelectedFilters => {
    return {
        type: filterTypes.SET_SELECTED_CONNECTION_FILTERS,
        payload
    }
}