import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IMeasurementUnit} from '../../../models/IMeasurementUnit';
import {IOnBoardingUser} from '../../../models/IOnBoardingUser';
import {OnBoardingSteps, Unit} from '../../../utils/enums';
import {Button} from '../button/Button';
import {Select} from '../select/Select';
import useStyles from './unit-system.styles';

interface IProps {
  userData: IOnBoardingUser;
  onContinueClick: (step: OnBoardingSteps) => void;
  onUpdateUser: (user: IOnBoardingUser) => void;
}

const UnitSystemOnBoarding: React.FC<IProps> = (props) => {
  const {userData, onContinueClick, onUpdateUser} = props;
  const [t] = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState({
    unit: {
      key: Unit.IMPERIAL,
      value: Unit.IMPERIAL.toString(),
    },
    unitId: 1,
  });
  const METRIC_UNIT = 'Metric';
  const IMPERIAL_UNIT = 'Imperial';
  const [units /*, setUnits*/] = useState<IMeasurementUnit[]>([
    {
      id: 1,
      name: IMPERIAL_UNIT,
      lengthUnit: 'inch',
      massUnit: 'lbs',
    },
    {
      id: 2,
      name: METRIC_UNIT,
      lengthUnit: 'cm',
      massUnit: 'kg',
    },
  ]);
  const unit = Object.values(Unit);

  useEffect(() => {
    const loadUnit = async () => {
      // const units = await getAllMeasurementUnits();
      // setUnits(units);
    };
    loadUnit();
  }, []);

  const getUnitId = (unit: Unit): number => {
    if (unit === Unit.IMPERIAL) {
      const unitObj = units.find((unit) => unit.name === IMPERIAL_UNIT);
      return unitObj ? unitObj.id : -1;
    } else if (unit === Unit.METRIC) {
      const unitObj = units.find((unit) => unit.name === METRIC_UNIT);
      return unitObj ? unitObj.id : -1;
    } else return -1;
  };

  useEffect(() => {
    const initialObject: any = {
      unit: {
        key: userData.unitSystem,
        value: userData.unitSystem,
      },
      unitId: userData.unitId,
    };

    setData(initialObject);
  }, [props.userData]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setData({
      ...data,
      unit: {key: value.key, value: value.value},
      unitId: getUnitId(value.key),
    });
  };

  const handleSubmit = () => {
    userData.unitSystem = data.unit.key;
    if (data.unitId !== -1) {
      userData.unitId = data.unitId;
    }
    onUpdateUser(userData);
    onContinueClick(OnBoardingSteps.HEIGHT_WEIGHT);
  };
  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>
        <Typography variant="h5" className={classes.title}>
          {t('OnBoardingPage.unitSystem.title')}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {t('OnBoardingPage.unitSystem.text')}
        </Typography>
        <Select
          name={'unit'}
          options={unit.map((item) => ({
            key: item,
            value: item,
          }))}
          value={data.unit}
          onChange={handleChange}
          className={classes.select}
          label={t('OnBoardingPage.unitSystem.unit')}
        />
      </div>
      <Button id="continue" className={classes.continue} onClick={handleSubmit}>
        {t('OnBoardingPage.continue')}
      </Button>
    </div>
  );
};
export default UnitSystemOnBoarding;
