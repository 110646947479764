import React, {ReactNode} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Carousel from 'react-multi-carousel';
import classNames from 'classnames';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {IInsightRow} from '../../../models/IInsights';
import {
  ACTIVITY,
  BEFORE1,
  BEFORE2,
  BEFORE3,
  BOWEL_MOVEMENTS,
  FOOD,
   MOBILE_SIZE,
  SLEEP,
  STEPS,
  STRESS,
  TODAY,
  WATER,
  WEIGHT,
  GLUCOSE,
} from '../../../utils/constants';
import {ContentDialog} from '../dialog/ContentDialog';
import BodyWeightForm from '../logging-widgets/body-weight/BodyWeightForm';
import {FormType} from '../../../utils/enums';
import {addTime} from '../../../utils/date-time-utils';
import FoodForm from '../logging-widgets/food/FoodForm';
import DrinkForm from '../logging-widgets/water/DrinkForm';
import SleepForm from '../logging-widgets/sleep/SleepForm';
import ActivityForm from '../logging-widgets/activity/ActivityForm';
import StepsForm from '../logging-widgets/steps/StepsForm';
import BowelForm from '../logging-widgets/bowel/BowelForm';
import StressForm from '../logging-widgets/stress/StressForm';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import {selectMember} from '../../../store/selectors/homepage.selectors';
import {IUser} from '../../../models/IUser';
import {selectMoodTags, selectStressTags} from '../../../store/selectors/loggingPageSelector';
import {MoodEmoticon} from '../range-slider/MoodEmoticon';
import NoPainVeryMild from '../../../assets/icons/stress-level/no-pain-very-mild-level.svg';
import DiscomfortingTolerable from '../../../assets/icons/stress-level/discomforting-tolerable-level.svg';
import DistressingVeryDistressing from '../../../assets/icons/stress-level/distressing-very-distressing-level.svg';
import IntenseVeryIntense from '../../../assets/icons/stress-level/intense-very-intense-level.svg';
import UtterlyHorribleExcruciatingUnbearable from '../../../assets/icons/stress-level/utterly-horrible-excruciating-unbearable-level.svg';
import UnimaginableUnspeakable from '../../../assets/icons/stress-level/unimaginable-unspeakable-level.svg';
import PlusIcon from '../../../assets/icons/insights/plus-insights-icon.svg';
import {selectUserSuperCoach} from '../../../store/selectors/users.selectors';

import useStyles from './insights-table.styles';
import BloodGlucoseForm from '../logging-widgets/blood-glucose-widget/BloodGlucoseForm';

interface IInsightsProps {
  headers: string[];
  rows: IInsightRow[];
  isMobile: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const InsightsTable: React.FC<IInsightsProps> = (props) => {
  const {headers, rows, isMobile} = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [contentForm, setContentForm] = React.useState<ReactNode>(null);
  const selectedMember: IUser = useSelector(selectMember);
  const selectedUser = useSelector(selectUserSuperCoach);
  const initialMoodTagList = useSelector(selectMoodTags);
  const initialTagList = useSelector(selectStressTags);
  const classes = useStyles();

  const handleClickDismiss = () => {
    setOpen(false);
  };

  const handleClickDismissAfterSubmit = () => {
    setOpen(false);
    dispatch(homepageActions.loadUsersInsights());
  };

  const calculateBeforeDays = (date: string): number => {
    if (date === BEFORE1) {
      return -1;
    }

    if (date === BEFORE2) {
      return -2;
    }

    if (date === BEFORE3) {
      return -3;
    }

    return 0;
  };

  const solveWidgetForm = (nameKey: string, addDateDays: number): ReactNode => {
    if (nameKey === WEIGHT) {
      return (
        <BodyWeightForm
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }

    if (nameKey === FOOD) {
      return (
        <FoodForm
          isMobile={isMobile}
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
        />
      );
    }

    if (nameKey === WATER) {
      return (
        <DrinkForm
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }

    if (nameKey === SLEEP) {
      return (
        <SleepForm
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }

    if (nameKey === ACTIVITY) {
      return (
        <ActivityForm
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }

    if (nameKey === STEPS) {
      return (
        <StepsForm
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }

    if (nameKey === BOWEL_MOVEMENTS) {
      return (
        <BowelForm
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }

    if (nameKey === STRESS) {
      return (
        <StressForm
          initialMoodTagList={initialMoodTagList}
          initialTagList={initialTagList}
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }
    if (nameKey === GLUCOSE) {
      return (
        <BloodGlucoseForm
          formType={FormType.ADDING_MODE}
          data={null}
          changeState={() => {
            handleClickDismissAfterSubmit();
          }}
          openedFromDialog={true}
          date={addTime(moment(), addDateDays, 'days').toString()}
          isMobile={isMobile}
        />
      );
    }

    return null;
  };

  const clickedOnPlusCell = (name: string, date: string) => {
    const addDateDays = calculateBeforeDays(date);
    const widgetForm = solveWidgetForm(name, addDateDays);

    setContentForm(widgetForm);
    setOpen(true);
  };

  const renderPainLevel = (painLevel: number) => {
    switch (painLevel) {
      case 0: {
        return (
          <MoodEmoticon
            src={UnimaginableUnspeakable}
            name={t('LogsPage.stressWidget.marks.unimaginableUnspeakableName')}
            text={t('LogsPage.stressWidget.marks.unimaginableUnspeakableText')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 1: {
        return (
          <MoodEmoticon
            src={UtterlyHorribleExcruciatingUnbearable}
            name={t('LogsPage.stressWidget.marks.excruciatingUnbearableName')}
            text={t('LogsPage.stressWidget.marks.excruciatingUnbearableText')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 2: {
        return (
          <MoodEmoticon
            src={UtterlyHorribleExcruciatingUnbearable}
            name={t('LogsPage.stressWidget.marks.utterlyHorribleName')}
            text={t('LogsPage.stressWidget.marks.utterlyHorribleText')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 3: {
        return (
          <MoodEmoticon
            src={IntenseVeryIntense}
            name={t('LogsPage.stressWidget.marks.veryIntenseName')}
            text={t('LogsPage.stressWidget.marks.veryIntenseText')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 4: {
        return (
          <MoodEmoticon
            src={IntenseVeryIntense}
            name={t('LogsPage.stressWidget.marks.intenseName')}
            text={t('LogsPage.stressWidget.marks.intenseName')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 5: {
        return (
          <MoodEmoticon
            src={DistressingVeryDistressing}
            name={t('LogsPage.stressWidget.marks.veryDistressingName')}
            text={t('LogsPage.stressWidget.marks.veryDistressingText')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 6: {
        return (
          <MoodEmoticon
            src={DistressingVeryDistressing}
            name={t('LogsPage.stressWidget.marks.distressingName')}
            text={t('LogsPage.stressWidget.marks.distressingName')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 7: {
        return (
          <MoodEmoticon
            src={DiscomfortingTolerable}
            name={t('LogsPage.stressWidget.marks.tolerableName')}
            text={t('LogsPage.stressWidget.marks.tolerableName')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 8: {
        return (
          <MoodEmoticon
            src={DiscomfortingTolerable}
            name={t('LogsPage.stressWidget.marks.discomfortingName')}
            text={t('LogsPage.stressWidget.marks.discomfortingName')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 9: {
        return (
          <MoodEmoticon
            src={NoPainVeryMild}
            name={t('LogsPage.stressWidget.marks.veryMildName')}
            text={t('LogsPage.stressWidget.marks.veryMildText')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      case 10: {
        return (
          <MoodEmoticon
            src={NoPainVeryMild}
            name={t('LogsPage.stressWidget.marks.noPainText')}
            text={t('LogsPage.stressWidget.marks.noPainText')}
            isScaleEmoticon={false}
            notShowText={true}
          />
        );
      }
      default:
        return <div />;
    }
  };

  const prepareCell = (value: string, name: string, date: string): React.ReactNode => {
    if (value === '' || value === null) {
      if (selectedMember || selectedUser) {
        return (
          <TableCell align="center">
            <Typography variant={'overline'} className={classes.noDataText}>
              {t('Homepage.insights.table.noDataLabel')}
            </Typography>
          </TableCell>
        );
      } else {
        return (
          <TableCell align="center">
            <div className={classes.centerContentCell}>
              <div
                role={'button'}
                onKeyDown={() => {}}
                tabIndex={0}
                id="add-another-button"
                className={classNames(classes.plusButton, classes.ripple)}
                onClick={() => clickedOnPlusCell(name, date)}
              >
                <img src={PlusIcon} alt="plus-insights-icon" />
              </div>
            </div>
          </TableCell>
        );
      }
    }

    if (name === STRESS) {
      return (
        <TableCell align="center">
          <div
            className={
              selectedMember
                ? classes.centerContentCell
                : classNames(classes.plusValue, classes.centerContentCell)
            }
          >
            {renderPainLevel(parseInt(value))}
            {!selectedMember && (
              <div
                role={'button'}
                onKeyDown={() => {}}
                tabIndex={0}
                id="add-another-button"
                className={classNames(classes.plusButton, classes.ripple)}
                onClick={() => clickedOnPlusCell(name, date)}
              >
                <img src={PlusIcon} alt="plus-insights-icon" />
              </div>
            )}
          </div>
        </TableCell>
      );
    }

    return (
      <TableCell align="left">
        <div
          className={
            selectedMember
              ? classes.centerContentCell
              : classNames(classes.plusValue, classes.centerContentCell)
          }
        >
          <div>{value}</div>
          {!selectedMember && (
            <div
              role={'button'}
              onKeyDown={() => {}}
              tabIndex={0}
              id="add-another-button"
              className={classNames(classes.plusButton, classes.ripple)}
              onClick={() => clickedOnPlusCell(name, date)}
            >
              <img src={PlusIcon} alt="plus-insights-icon" />
            </div>
          )}
        </div>
      </TableCell>
    );
  };

  const responsive = {
    mobile: {
      breakpoint: {max: MOBILE_SIZE, min: 0},
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <div className={classes.tableWithCarousel}>
          {/* box view for 3Days-Trends */}
          {/* <TableContainer className={classes.root}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {headers?.map((headerName) => (
                  <TableCell key={headerName} align="left">
                    {headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <React.Fragment>
                    {prepareCell(row.before3, row.nameKey, BEFORE3)}
                    {prepareCell(row.before2, row.nameKey, BEFORE2)}
                    {prepareCell(row.before1, row.nameKey, BEFORE1)}
                    {prepareCell(row.today, row.nameKey, TODAY)}
                  </React.Fragment>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
          <div className={classes.firstColumn}>

            {/* Uncomment for tableView */}
            <div className={classes.headerColumn}>{headers[0]}</div>
            {rows?.map((row) => (
              <TableCell component="th" scope="row" key={row.name}>
                {row.name}
              </TableCell>
            ))}
          </div>
          <div className={classes.secondColumn}>
            <Carousel
              swipeable={true}
              draggable={true}
              arrows={!isMobile}
              showDots={isMobile}
              // ssr={true} // means to render carousel on server-side.
              responsive={responsive}
              infinite={false}
              autoPlay={false}
              keyBoardControl={true}
              containerClass="carousel-container"
              renderDotsOutside={true}
              renderButtonGroupOutside={true}
              dotListClass={classes.customDotList}
            >
              
              <div className={classes.othersColumn}>
                <div className={classes.headerColumn}>{headers[1]}</div>
                {rows?.map((row) => (
                  <TableRow key={row.nameKey}>
                    {prepareCell(row.before3, row.nameKey, BEFORE3)}
                  </TableRow>
                ))}
              </div>
              <div className={classes.othersColumn}>
                <div className={classes.headerColumn}>{headers[2]}</div>
                {rows?.map((row) => (
                  <TableRow key={row.nameKey}>
                    {prepareCell(row.before2, row.nameKey, BEFORE2)}
                  </TableRow>
                ))}
              </div>
              <div className={classes.othersColumn}>
                <div className={classes.headerColumn}>{headers[3]}</div>
                {rows?.map((row) => (
                  <TableRow key={row.nameKey}>
                    {prepareCell(row.before1, row.nameKey, BEFORE1)}
                  </TableRow>
                ))}
              </div>
              <div className={classes.othersColumn}>
                <div className={classes.headerColumn}>{headers[4]}</div>
                {rows?.map((row) => (
                  <TableRow key={row.nameKey}>
                    {prepareCell(row.today, row.nameKey, TODAY)}
                  </TableRow>
                ))}
              </div>
            </Carousel>
          </div>
        </div>
      ) : (
        <TableContainer className={classes.root}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {headers?.map((headerName) => (
                  <TableCell key={headerName} align="left">
                    {headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <React.Fragment>
                    {prepareCell(row.before3, row.nameKey, BEFORE3)}
                    {prepareCell(row.before2, row.nameKey, BEFORE2)}
                    {prepareCell(row.before1, row.nameKey, BEFORE1)}
                    {prepareCell(row.today, row.nameKey, TODAY)}
                  </React.Fragment>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ContentDialog
        handleDismiss={handleClickDismiss}
        dialogState={open}
        content={contentForm}
        applyMaxWidthFalse={true}
      />
    </React.Fragment>
  );
};

export default InsightsTable;
