export const LOGIN = '[users] LOGIN';
export const LOGOUT = '[users] LOGOUT';
export const SAVE_SKIN_TONE = '[users] SAVE_SKIN_TONE';

export const STORE_USERS = 'STORE_USERS';
export const STORE_SECONDARY_USERS = 'STORE_SECONDARY_USERS';
export const STORE_SUPERCOACH_FILTERED_USERS = 'STORE_SUPERCOACH_FILTERED_USERS';
export const STORE_COACH_MEMBERS = 'STORE_COACH_MEMBERS';
export const STORE_PAGINATION_USERS = 'STORE_PAGINATION_USERS';
export const CLEAR_PAGINATION_USERS = 'CLEAR_PAGINATION_USERS';
export const SELECT_USER = 'SELECT_USER';
export const UPDATE_UNREAD_STATE = 'UPDATE_UNREAD_STATE';
export const STATUS_ERROR = 'STATUS_ERROR';