import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  weightContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginRight: 24,
  },
  textContent: {
    color: theme.palette.secondary.dark,
    maxWidth: 250,
    display: 'flex',
    wordBreak: 'break-word',
  },
}));

export default useStyles;
