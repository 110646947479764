import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
 
    StepOneFrom:{

    '& .MuiSlider-thumb':{
        width:'16px',
        height:"16px",
        boxShadow: "0px 2px 1px rgba(100, 101, 102, 0.2), 0px 1px 1px rgba(100, 101, 102, 0.14), 0px 1px 3px rgba(100, 101, 102, 0.12);box-shadow: 0px 2px 1px rgba(100, 101, 102, 0.2), 0px 1px 1px rgba(100, 101, 102, 0.14), 0px 1px 3px rgba(100, 101, 102, 0.12)",
    },
    '& .MuiSlider-markActive':{
        width:'8px',
        height:"8px",
        borderRadius:"50%", 
        background:"#D2D3D6",
        opacity:"1",
        boxShadow: "0px 2px 1px rgba(100, 101, 102, 0.2), 0px 1px 1px rgba(100, 101, 102, 0.14), 0px 1px 3px rgba(100, 101, 102, 0.12);box-shadow: 0px 2px 1px rgba(100, 101, 102, 0.2), 0px 1px 1px rgba(100, 101, 102, 0.14), 0px 1px 3px rgba(100, 101, 102, 0.12)",
    },
    '& .MuiSlider-root.Mui-disabled': {
        color: '#000000'
    },
    '& .MuiSlider-rail':{

        background:"#D2D3D6", top:"16px",
    },
    
    '& .MuiSlider-track':{
        
        background:"#D2D3D6",
        top:"16px",

    },

    '& .MuiSlider-mark':{
        width:'8px',
        height:"8px",
        borderRadius:"50%",
        background:"#D2D3D6",
        opacity:"1"
    },
    '& .ptag' :{
        margin:"16px 0 0 0",
        fontSize:"16px",
        lineHeight:"32px",
     },
    
    '& .poppupForm' :{   width:"100%",        
        background:"#fff",
        margin:"56px 0 0 0",
        padding:"40px 40px 41px 40px",
        borderRadius: "12px",
        boxShadow: "0px 11px 15px #D4DEEA, 0px 24px 38px #EDF6FF, 0px 9px 46px rgba(234, 236, 249, 0.12)",
    '& h4':{
            fontWeight:"700",
            fontSize:"28px",
            lineHeight:"32px",
            margin:"0px"
        },

        
    },
    
'& .stepper2':{ 
    margin:"0px auto 50px ",
    maxWidth:"460px",
},

'& .emojisec':{ 
    margin:"40px 0 0 0",
    '& p':{   margin:"8px 0 6px 0", fontSize:"16px" },


},

'& .popupContentArea':{

    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    maxWidth:"540px",
    margin:"0 auto 40px",

 },

 '& .popupContentDiv':{

    position:"relative",
    width:"33.3%",
    borderRight:"1px solid #D7DBE0",
    '&:nth-child(3)':{
        border:"0px"
    },

    '& img':{
        lineHeight:"1px",
    },

    '& h6':{
    margin:"12px 0 0 0",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#5D6B7A",       
    letterSpacing: "0.4px",
    minHeight:"32px"
    },
 },

 ['@media (max-width:767px)']: {
    '& .ptag' :{
        fontSize: "14px",
        marginTop:"4px",
        lineHeight:"20px",
    },
    '& .emojisec':{
    margin:"36px 0 0 0",
    '& p':{
        margin:"0 0 0 0 !important",

    },
    },

    '& .popupContentDiv':{
     padding:"0 10px",
     '& h6' :{
       minHeight:"48px !important" ,
        },
     '& h6 br' :{
     display:"none",
     },
    
    },

 },

},

     
  

  });

  export default useStyles;