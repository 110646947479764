import * as React from 'react';
import {BarChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar} from 'recharts';
import {IChartData, IChartOptions} from '../../../models/IChart';
import {ProgressType} from '../../../utils/enums';

import useStyles from './chart.styles';

interface Props {
  data: IChartData[];
  options: IChartOptions;
  type: string;
  isMobile: boolean;
}

export const SimpleBarChart: React.FC<Props> = ({data, options, type, isMobile}) => {
  const classes = useStyles();

  const formatYAxis = (value: number | string): string => {
    if (type !== ProgressType.SLEEP) return value.toString();
    if (!value) return '';
    let stringValue = value.toString();
    if (stringValue?.length === 3) {
      stringValue = '0' + stringValue;
    }
    const leftValue = stringValue.slice(0, 2);
    const rightValue = stringValue.slice(2, 4);
    return `${leftValue}:${rightValue}`;
  };

  return (
    <ResponsiveContainer
      width={options.width}
      height={isMobile ? options.heightMobile : options.height}
      className={classes.simpleBarChart}
    >
      <BarChart data={data} margin={isMobile ? options.marginMobile : options.margin}>
        <CartesianGrid strokeDasharray="0 0" />
        <XAxis name="date" dataKey="date" allowDuplicatedCategory={false} />
        <YAxis
          name="value"
          orientation="left"
          domain={options.rangeY}
          tickFormatter={formatYAxis}
        />
        <Tooltip
          labelFormatter={(value) =>
            type === ProgressType.SLEEP
              ? `value: ${formatYAxis(data.find((item) => item.date === value)?.value ?? 0)}`
              : `value: ${data.find((item) => item.date === value)?.value ?? 0}`
          }
        />
        <Bar dataKey="value" fill={options.color} />
      </BarChart>
    </ResponsiveContainer>
  );
};
