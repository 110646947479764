// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Helmet} from 'react-helmet';
import * as React from 'react';

interface Props {
  microFormat: any;
}

export const WhiskAddToShoppingList: React.FC<Props> = ({microFormat}) => {
  const loadButton = () => {
    return (
      <div id={'OQSP-JWXO-BFQB-BUDN'}>
        <Helmet>
          <script id="show-banner">
            {`
               var whisk = whisk || {};
               whisk.queue = whisk.queue || [];
               whisk.queue.push(function () {
               whisk.shoppingList.defineWidget("OQSP-JWXO-BFQB-BUDN", {
               styles: {
                  size: "large",
                  linkColor: "#000000",
                  button: {
                      color: "#000000"
                  }
                  }});
               });
               whisk.queue.push(function () {
                 whisk.display("OQSP-JWXO-BFQB-BUDN");
               })
          `}
          </script>
        </Helmet>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <script
          id="whisk-js"
          src="https://cdn.whisk.com/sdk/shopping-list.js"
          type="text/javascript"
          async={true}
        />
        <script type="application/ld+json">{`
            [${microFormat}]
        `}</script>
      </Helmet>
      {loadButton()}
    </>
  );
};
