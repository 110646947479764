import {Dispatch} from 'redux';
import {
  LOAD_INGREDIENTS_CATEGORIES,
  LOAD_CATEGORIES_WITH_INGREDIENTS,
  LOAD_COURSES,
  LOAD_CUISINES,
  LOAD_LEVEL_CATEGORIES,
  SAVE_RECIPES_COUNT,
  SAVE_RECIPES,
  SAVE_RECIPE,
  LOAD_CATEGORIES,
  SAVE_MEAL_PLAN,
  SAVE_MEAL_PLANS,
  SAVE_RECIPE_FILTERS,
  SAVE_MEAL_PLANS_FILTERS,
  SAVE_RECIPES_TAGS,
  SAVE_SELECETED_MENU_ITEM,
  SAVE_SELECETED_RECIPES_FILTERS,
  SAVE_SELECTABLE_INGREDIENTS,
  SAVE_SELECTED_MEAL_PLANS_FILTERS,
  SAVE_MEAL_PLANS_TAGS,
  LOAD_CARDS,
  LOAD_DAILY_CONTENT_CATEGORIES,
  LOAD_DAILY_CONTENTS,
  SAVE_BETR_PROGRAM_FILTERS,
  LOAD_DAILY_CONTENT,
  SAVE_DAILY_CONTENTS_COUNT,
  REHAB_ENABLED,
  LOAD_MINDFULNESS,
  LOAD_RIGHT_CARDS,
  LOAD_PODCAST,
  MOVEMENT_ENABLED,
  LOAD_YOGA,
  APPOINTMENT_ENABLED,
  SAVE_YOGA,
} from './nourish-types';
import {IRecipeDto} from '../../../dtos/IRecipeDto';
import {
  getIngredientsCategories,
  getIngredientsCategoriesWithIngredients,
  getCourses,
  getCuisines,
  getLevelCategories,
  getRecipes,
  getRecipe,
  getCategories,
  getMealPlans,
  getMealPlan,
  getCards,
  getDailyContents,
  getDailyContent,
  getDailyContentCategoriesWithChildren,
  getMindfulness,
  getRightCards,
  getPodCast,
  getYoga,
  getYogas
} from '../../../api/nourish.api';
import {ICuisineDto} from '../../../dtos/ICuisineDto';
import {ICourseDto} from '../../../dtos/ICourseDto';
import {ILevelCategoryDto} from '../../../dtos/ILevelCategoryDto';
import {ICategoryIngredientsDto, IIngredientDto} from '../../../dtos/ICategoryIngredientsDto';
import {
  IRecipeFiltersDto,
  ISelectedMealPlansFiltersDto,
  ISelectedReipesFiltersDto,
} from '../../../dtos/IRecipeFiltersDto';
import {ICategoryDto} from '../../../dtos/ICategoryDto';
import {IMealPlanCardDto} from '../../../dtos/IMealPlanCardDto';
import {IMealPlansFiltersDto} from '../../../dtos/IMealPlansFiltersDto';
import {IMealPlanDto} from '../../../dtos/IMealPlanDto';
import {SideMenuSelectedType} from '../../../utils/enums';
import {IRecipeTagsDto} from '../../../dtos/IRecipeTagsDto';
import {
  initialMealPlansTagsScheme,
  initialRecipesTagsScheme,
} from '../../models/nourish/nourish-models';
import {RecipeTagKeys} from '../../../utils/types';
import {ICardDto, IRightCardDto} from '../../../dtos/ICardDto';
import {IDailyContentCategoryDto} from '../../../dtos/IDailyContentCategoryDto';
import {IBetrProgramFilterDto} from '../../../dtos/IBetrProgramFilterDto';
import {IDailyContentDto} from '../../../dtos/IDailyContentDto';
import { IMindfulnessDto } from '../../../dtos/IMindfulnessDto';
import { IPodcastDto } from '../../../dtos/IPodCastDto';
import { IYogaDto } from '../../../dtos/IYogaDto';

export interface ISaveRecipesCount {
  type: typeof SAVE_RECIPES_COUNT;
  payload: number;
}

export interface ISaveRecipes {
  type: typeof SAVE_RECIPES;
  payload: IRecipeDto[];
}

export interface ISaveRecipe {
  type: typeof SAVE_RECIPE;
  payload: IRecipeDto;
}
export interface ISaveYOGA {
  type: typeof SAVE_YOGA;
  payload: IRecipeDto;
}

export interface ILoadCuisines {
  type: typeof LOAD_CUISINES;
  payload: ICuisineDto[];
}

export interface ILoadCourses {
  type: typeof LOAD_COURSES;
  payload: ICourseDto[];
}
export interface ILoadMindfulness {
  type: typeof LOAD_MINDFULNESS;
  payload: IMindfulnessDto[];
}
export interface ILoadPodCast {
  type: typeof LOAD_PODCAST;
  payload: IPodcastDto[];
}
export interface ILoadYoga {
  type: typeof LOAD_YOGA;
  payload: IYogaDto[];
}

export interface ILoadLevelCategories {
  type: typeof LOAD_LEVEL_CATEGORIES;
  payload: ILevelCategoryDto[];
}

export interface ILoadIngredientsCategories {
  type: typeof LOAD_INGREDIENTS_CATEGORIES;
  payload: ICategoryIngredientsDto[];
}

export interface ILoadCategoriesWIthIngredients {
  type: typeof LOAD_CATEGORIES_WITH_INGREDIENTS;
  payload: ICategoryIngredientsDto[];
}

export interface ISaveMealPlans {
  type: typeof SAVE_MEAL_PLANS;
  payload: IMealPlanCardDto[];
}

export interface ISaveMealPlan {
  type: typeof SAVE_MEAL_PLAN;
  payload: IMealPlanDto[];
}

export interface ILoadCategories {
  type: typeof LOAD_CATEGORIES;
  payload: ICategoryDto[];
}

export interface ILoadCards {
  type: typeof LOAD_CARDS;
  payload: ICardDto[];
}
export interface IRightLoadCards {
  type: typeof LOAD_RIGHT_CARDS;
  payload: IRightCardDto[];
}

export interface ILoadDailyContentCategories {
  type: typeof LOAD_DAILY_CONTENT_CATEGORIES;
  payload: IDailyContentCategoryDto[];
}

export interface ILoadDailyContents {
  type: typeof LOAD_DAILY_CONTENTS;
  payload: IDailyContentDto[];
}

export interface ILoadDailyContent {
  type: typeof LOAD_DAILY_CONTENT;
  payload: IDailyContentDto[];
}

export interface ISaveBetrProgramFilters {
  type: typeof SAVE_BETR_PROGRAM_FILTERS;
  payload: IBetrProgramFilterDto;
}

export interface ISaveDailyContentsCount {
  type: typeof SAVE_DAILY_CONTENTS_COUNT;
  payload: number;
}

export interface ISaveRecipeFilters {
  type: typeof SAVE_RECIPE_FILTERS;
  payload: IRecipeFiltersDto;
}

export interface ISaveSelectedMenuItem {
  type: typeof SAVE_SELECETED_MENU_ITEM;
  payload: SideMenuSelectedType;
}

export interface ISaveSelectedRecipesFilters {
  type: typeof SAVE_SELECETED_RECIPES_FILTERS;
  payload: ISelectedReipesFiltersDto;
}

export interface ISaveSelectableIngredients {
  type: typeof SAVE_SELECTABLE_INGREDIENTS;
  payload: IIngredientDto[];
}

export interface ISaveRecipesTags {
  type: typeof SAVE_RECIPES_TAGS;
  payload: IRecipeTagsDto;
}

export interface ISaveMealPlansTags {
  type: typeof SAVE_MEAL_PLANS_TAGS;
  payload: IMealPlanCardDto;
}

export interface IMealPlansFilters {
  type: typeof SAVE_MEAL_PLANS_FILTERS;
  payload: IMealPlansFiltersDto;
}

export interface ISaveSelectedMealPlansFilters {
  type: typeof SAVE_SELECTED_MEAL_PLANS_FILTERS;
  payload: ISelectedMealPlansFiltersDto;
}

export const saveSelectedMenuItem = (data: SideMenuSelectedType): ISaveSelectedMenuItem => {
  return {type: SAVE_SELECETED_MENU_ITEM, payload: data};
};

export const saveSelectedMealPlansFilters = (
  data: ISelectedMealPlansFiltersDto,
): ISaveSelectedMealPlansFilters => {
  return {type: SAVE_SELECTED_MEAL_PLANS_FILTERS, payload: data};
};

export const saveSelectedRecipesFilters = (
  data: ISelectedReipesFiltersDto,
): ISaveSelectedRecipesFilters => {
  return {type: SAVE_SELECETED_RECIPES_FILTERS, payload: data};
};

export const saveSelectableIngredients = (data: IIngredientDto[]): ISaveSelectableIngredients => {
  return {type: SAVE_SELECTABLE_INGREDIENTS, payload: data};
};

export const saveRecipes = (data: IRecipeDto[]): ISaveRecipes => {
  return {type: SAVE_RECIPES, payload: data};
};

export const saveRecipesFilters = (data: IRecipeFiltersDto): ISaveRecipeFilters => {
  return {type: SAVE_RECIPE_FILTERS, payload: data};
};

export const saveRecipe = (data: IRecipeDto): ISaveRecipe => {
  return {type: SAVE_RECIPE, payload: data};
};
export const saveYoga = (data: IRecipeDto): ISaveYOGA => {

  return {type: SAVE_YOGA, payload: data};
};


export const saveRecipesCount = (count: number): ISaveRecipesCount => {
  return {type: SAVE_RECIPES_COUNT, payload: count};
};

export interface IRehabEnabled {
  type: typeof REHAB_ENABLED;
  payload: boolean;
}
export interface IMovementEnabled {
  type: typeof MOVEMENT_ENABLED;
  payload: any;
}
export interface IAppointmentEnabled {
  type: typeof APPOINTMENT_ENABLED;
  payload: any;
}

export const loadRecipes = (
  filters?: IRecipeFiltersDto,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const {data, headers} = await getRecipes(filters);
    dispatch(saveRecipes(data));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dispatch(saveRecipesFilters(filters!));
    //TODO: Count of recipes should be extracted out from response headers instead
    dispatch(saveRecipesCount(Number(headers.count)));
  };
};

export const loadRecipe = (slug?: string): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const recipes: IRecipeDto[] = await getRecipe(slug);
    const recipe = recipes[0];
    dispatch(saveRecipe(recipe));
  };
};
export const loadYogas = (slug?: string): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const recipes: IRecipeDto[] = await getYogas(slug);
    const recipe = recipes[0];
    dispatch(saveYoga(recipe));
  };
};

export const loadCuisines = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const cuisines: ICuisineDto[] = await getCuisines();
    dispatch({type: LOAD_CUISINES, payload: cuisines});
  };
};

export const loadCourses = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const courses: ICourseDto[] = await getCourses();
    dispatch({type: LOAD_COURSES, payload: courses});
  };
};
export const loadMindfulness = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const mindfulnessData: IMindfulnessDto[] = await getMindfulness();
    
    dispatch({type: LOAD_MINDFULNESS, payload: mindfulnessData});
  };
};
export const loadPodcast = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const podCastData: IPodcastDto[] = await getPodCast();
    podCastData?.sort((a, b) => {
      const episodeA = parseInt(a?.episode?.match(/\d+/)[0]);
      const episodeB = parseInt(b?.episode?.match(/\d+/)[0]);
      return episodeA - episodeB;
    });
    // console.log("podCastData", podCastData);
    dispatch({type: LOAD_PODCAST, payload: podCastData});
  };
};

export const loadYoga = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const yogaData: IYogaDto[] = await getYoga();
    dispatch({type: LOAD_YOGA, payload: yogaData});
  };
};



export const loadLevelCategories = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const levelCategories: ILevelCategoryDto[] = await getLevelCategories();
    dispatch({type: LOAD_LEVEL_CATEGORIES, payload: levelCategories});
  };
};

export const loadIngredientsCategories = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const categories: ICategoryIngredientsDto[] = await getIngredientsCategories();
    dispatch({type: LOAD_INGREDIENTS_CATEGORIES, payload: categories});
  };
};

export const loadCategoriesWithIngredients = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const categories: ICategoryIngredientsDto[] = await getIngredientsCategoriesWithIngredients();
    dispatch({type: LOAD_CATEGORIES_WITH_INGREDIENTS, payload: categories});
  };
};

export const saveMealPlansTags = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tag: any,
  tagsKey: string,
): ((dispatch: Dispatch, getState: any) => void) => {
  return (dispatch: Dispatch, getState: any) => {
    const {nourish} = getState();
    const mealPlansTags = nourish.mealPlansTags;
    const newMealPlansTags = {
      ...mealPlansTags,
      [tagsKey]: [...mealPlansTags[tagsKey], tag],
    };
    dispatch({type: SAVE_MEAL_PLANS_TAGS, payload: newMealPlansTags});
  };
};

export const removeMealPlansTags = (
  tagId: number,
  tagsKey: string,
): ((dispatch: Dispatch, getState: any) => void) => {
  return (dispatch: Dispatch, getState: any) => {
    const {nourish} = getState();
    const mealPlansTags = nourish.mealPlansTags;
    // eslint-disable-next-line
    const filteredTags = [...mealPlansTags[tagsKey]?.filter((oneTag: any) => oneTag.id !== tagId)];
    const newMealPlansTags = {
      ...mealPlansTags,
      [tagsKey]: filteredTags,
    };
    dispatch({type: SAVE_MEAL_PLANS_TAGS, payload: newMealPlansTags});
  };
};

export const saveRecipesTags = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tag: any,
  tagsKey: RecipeTagKeys,
): ((dispatch: Dispatch, getState: any) => void) => {
  return (dispatch: Dispatch, getState: any) => {
    const {nourish} = getState();
    const recipesTags = nourish.recipesTags;
    const newRecipesTags = {
      ...recipesTags,
      [tagsKey]: [...recipesTags[tagsKey], tag],
    };
    dispatch({type: SAVE_RECIPES_TAGS, payload: newRecipesTags});
  };
};

export const removeRecipesTags = (
  tagId: number,
  tagsKey: RecipeTagKeys,
): ((dispatch: Dispatch, getState: any) => void) => {
  return (dispatch: Dispatch, getState: any) => {
    const {nourish} = getState();
    // eslint-disable-next-line
    const recipesTags = nourish.recipesTags;
    // eslint-disable-next-line
    const filteredTags = [...recipesTags[tagsKey]?.filter((oneTag: any) => oneTag.id !== tagId)];
    const newRecipesTags = {
      ...recipesTags,
      [tagsKey]: filteredTags,
    };
    dispatch({type: SAVE_RECIPES_TAGS, payload: newRecipesTags});
  };
};

export const clearRecipesTags = (): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch({type: SAVE_RECIPES_TAGS, payload: initialRecipesTagsScheme});
  };
};

export const clearMealPlansTags = (): ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    dispatch({type: SAVE_MEAL_PLANS_TAGS, payload: initialMealPlansTagsScheme});
  };
};

export const saveMealPlans = (data: IMealPlanCardDto[]): ISaveMealPlans => {
  return {type: SAVE_MEAL_PLANS, payload: data};
};

export const saveMealPlan = (data: IMealPlanDto[]): ISaveMealPlan => {
  return {type: SAVE_MEAL_PLAN, payload: data};
};

export const saveMealPlansFilters = (data: IMealPlansFiltersDto): IMealPlansFilters => {
  return {type: SAVE_MEAL_PLANS_FILTERS, payload: data};
};

export const loadMealPlans = (
  filters?: IMealPlansFiltersDto,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const mealPlans = await getMealPlans(filters);
    dispatch(saveMealPlans(mealPlans));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    dispatch(saveMealPlansFilters(filters!));
  };
};

export const loadMealPlan = (mealPlanSlug: string): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const mealPlan = await getMealPlan(mealPlanSlug);
    dispatch(saveMealPlan(mealPlan));
  };
};

export const loadCategories = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const categories: ICategoryDto[] = await getCategories();
    dispatch({type: LOAD_CATEGORIES, payload: categories});
  };
};

export const loadCards = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const cards: ICardDto[] = await getCards();
    dispatch({type: LOAD_CARDS, payload: cards});
  };
};
export const loadRightCards = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const rightCards: IRightCardDto[] = await getRightCards();
    dispatch({type: LOAD_RIGHT_CARDS, payload: rightCards});
  };
};

export const loadDailyContentCategories = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    // const dailyContentCategories: IDailyContentCategoryDto[] = await getDailyContentCategories();
    const dailyContentCategories: IDailyContentCategoryDto[] =
      await getDailyContentCategoriesWithChildren();
    dispatch({type: LOAD_DAILY_CONTENT_CATEGORIES, payload: dailyContentCategories});
  };
};

export const loadDailyContents = (
  filters?: IBetrProgramFilterDto,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const {data, headers} = await getDailyContents(filters);

    dispatch({type: LOAD_DAILY_CONTENTS, payload: data});
    dispatch({type: SAVE_BETR_PROGRAM_FILTERS, payload: filters});
    dispatch({type: SAVE_DAILY_CONTENTS_COUNT, payload: Number(headers.count)});
  };
};

export const loadDailyContent = (
  dailyContentSlug: string,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const dailyContent = await getDailyContent(dailyContentSlug);
    dispatch({type: LOAD_DAILY_CONTENT, payload: dailyContent});
  };
};
