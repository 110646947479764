import * as React from 'react';
import {useState} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {isMobile} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import Loader from '../loader/Loader';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
// import * as usersActions from '../../../store/actions/users/users-action';
import {selectMember} from '../../../store/selectors/homepage.selectors';
import {IUser} from '../../../models/IUser';
import {
  checkIfUserIsTrainer,
   checkUserRole} from '../../../utils/users-managment';
import {TABLET_BIG_SIZE} from '../../../utils/constants';
import {IConversationDto} from '../../../dtos/IConversationDto';
// import TwilioService from '../../../services/TwilioService';

import useStyles from './connections-item.styles';
// import {selectCurrentUser} from '../../../store/selectors/users.selectors';

interface Props {
  icon?: string;
  inactiveIcon?: string;
  memberId: string;
  conversation?: IConversationDto;
  memberSearch?: boolean;
  users: IUser[];
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const ConnectionsItem: React.FC<Props> = ({
  memberId,
  inactiveIcon,
  users,
  conversation,
  // memberSearch,
}) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    return isMobile || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    color: conversation?.conversationColor,
    isMobileOrTablet: isMobileOrTablet,
  });
  const selectedMemberFromRedux = useSelector(selectMember);
  const dispatch = useDispatch();
  const selectedUser = users.filter((user) => user.id === memberId);
  const imageAvatar = selectedUser?.length > 0 && selectedUser[0].imageUrl;
  const [isLoading, setLoading] = useState(false);
  // const currentUser = useSelector(selectCurrentUser);

  // const setItemConversation = (
  //   memberConversation: IConversationDto[] | null,
  // ): IConversationDto | undefined => {
  //   setLoading(true);
  //   let itemConversation;
  //   if (memberConversation?.length === 1) {
  //     itemConversation = memberConversation[0];
  //   }
  //   if (memberConversation && memberConversation?.length > 1) {
  //     const coachConversation = memberConversation.find(
  //       (conv) => conv.conversationName === memberId,
  //     );
  //     itemConversation = coachConversation;
  //   }
  //   setLoading(false);

  //   return itemConversation;
  // };

  const openConnectionChat = async () => {
    setLoading(true);
    await dispatch(homepageActions.selectMember(memberId));
    if (selectedMemberFromRedux?.id === memberId) {
   if (checkIfUserIsTrainer(selectedMemberFromRedux))
        dispatch(homepageActions.saveSelectedCoach(selectedMemberFromRedux));
        // await dispatch(usersActions.deleteUnreadCount(memberId));
      return;
     }


    dispatch(homepageActions.setLoading(true));
    // let itemConversation = conversation;
    // if (memberSearch && !conversation) {
    //   // const memberConversation = await homepageActions.getConversartionByUserId(memberId);
    //   // itemConversation = setItemConversation(memberConversation);
    // }
    
    // if (!itemConversation) {
    //   itemConversation = {
    //     "conversationId": selectedUser[0]?.conversationId || '',
    //     "conversationName": selectedUser[0]?.id,
    //     "conversationSortId": undefined,
    //     "conversationColor": undefined,
    //     "phaseLevelID": selectedUser[0]?.phaseId,
    //     "phaseLevel": undefined,
    //     "imageUrl": selectedUser[0]?.imageUrl || null,
    //     "fullName": `${selectedUser[0]?.firstName} ${selectedUser[0]?.lastName}`,
    //     "nextPageUrl": null,
    //     "isUserActive": selectedUser[0]?.isActive? true : false,
    //     "participants": []
    // }
    //   await dispatch(homepageActions.saveSelectedConversationId(itemConversation.conversationId));
    // } else if (itemConversation) {
    //   await dispatch(homepageActions.saveSelectedConversationId(itemConversation.conversationId));
    // }

    // TwilioService.removeListeners();
    // await TwilioService.addNewConversation(
    //   itemConversation?.conversationId || '',
    //   async (itemConversation) => {
    //     // await dispatch(homepageActions.selectConversation(itemConversation));
    //     await dispatch(homepageActions.selectMember(memberId));
    //     await dispatch(usersActions.deleteUnreadCount(memberId));
    //   },
    //   currentUser,
    // );
    await dispatch(homepageActions.resetHistoryLogs());
     setLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader size={15} />
      ) : (
        selectedUser?.length > 0 && (
          <div
            id={`selected-user-${selectedUser[0]?.id}`}
            className={
              selectedMemberFromRedux && selectedMemberFromRedux.id === memberId
                ? classNames(classes.itemContainer, classes.selectedConn)
                : classNames(classes.itemContainer, classes.itemContainerHover)
            }
            onClick={() => openConnectionChat()}
            onKeyDown={() => {}}
            role="menuitem"
            tabIndex={0}
          >
            <img
              className={classes.connectionItemIcon}
              src={imageAvatar || inactiveIcon}
              alt="connections-icon"
            />
            <div className={classes.nameTitle}>
              <Typography
                variant={'body1'}
                className={
                  selectedMemberFromRedux && selectedMemberFromRedux?.id === memberId
                    ? classes.selectedTitle
                    : classes.connectionItemText
                }
              >
                {selectedUser?.length > 0
                  ? (
                    <>
                      {selectedUser[0]?.firstName + ' ' + selectedUser[0]?.lastName}
                      {(selectedUser[0].unReadCount && selectedUser[0].unReadCount !== '0') && <span className={classes.unreadBlock}></span>}
                    </>
                  )
                  : ''}
              </Typography>
              <Typography
                variant={'caption'}
                className={
                  selectedMemberFromRedux && selectedMemberFromRedux.id === memberId
                    ? classes.selectedTitle
                    : classes.connectionItemText
                }
              >
                {checkUserRole(selectedUser[0])}
              </Typography>
            </div>
          </div>
        )
      )}
    </React.Fragment>
  );
};
