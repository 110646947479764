import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
    },
  },
  back: {
    color: theme.palette.blacksAndGrays.jet,
    '& img': {
      marginRight: 8,
    },
    '& .MuiTypography-root': {
      letterSpacing: '1.25px',
      fontWeight: 500,
    },
  },
  title: {
    width: 'calc(100% - 75px)',
  },
  contentContainer: {
    margin: '25px 16px',
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px',
  },
  button: {
    marginRight: '16px',
  },
}));

export default useStyles;
