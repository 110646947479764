import * as React from 'react';
import classNames from 'classnames';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import {SideMenuLoggingSelectedType, SideMenuSelectedType} from '../../../utils/enums';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';

import useStyles from './side-menu-bar-item.styles';

interface Props {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text?: string;
  onClick: (value: SideMenuSelectedType | SideMenuLoggingSelectedType) => void;
  elementType: SideMenuSelectedType | SideMenuLoggingSelectedType;
  selectedSideMenuItem: SideMenuSelectedType | SideMenuLoggingSelectedType;
}

export const SideMenuBarItem: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  const {text, Icon, onClick, elementType, selectedSideMenuItem} = props;

  return (
    <div
      id={`side-menu-item-id-${text}`}
      className={
        text === 'Connections'
          ? classNames(classes.sideMenuItemFirst, classes.sideMenuItemHover)
          : classNames(
              classes.sideMenuItem,
              classes.sideMenuItemHover,
              selectedSideMenuItem === elementType ? classes.sideMenuItemActive : '',
            )
      }
      onClick={() => onClick(elementType)}
      onKeyDown={() => onClick(elementType)}
      role="menuitem"
      tabIndex={0}
    >
      <Icon
        className={`${
          selectedSideMenuItem === elementType ? classes.sidebarIconsActive : classes.sidebarIcons
        }`}
      />
      <div
        className={
          selectedSideMenuItem === elementType
            ? 'side-menu-item-text'
            : 'side-menu-item-text-inactive'
        }
      >
        <Typography variant={selectedSideMenuItem === elementType ? 'subtitle2' : 'body1'}>
          {text}
        </Typography>
      </div>
    </div>
  );
};
