import {IConversationDto} from '../../dtos/IConversationDto';
import {ISnackbarState} from '../../common/components/snackbar/Snackbar';
import {IPhasesDto} from '../../dtos/IPhasesDto';
import {IUser} from '../../models/IUser';
import {IUserInsightsDto} from '../../dtos/IInsightsDto';
import {IAchievementsDto} from '../../dtos/IAchievementsDto';
import {IAppointmentDto} from '../../dtos/IAppointmentDto';
import {AppointmentType} from '../../utils/enums';
import {IMeasurementUnit} from '../../models/IMeasurementUnit';
import {INotificationDto} from '../../dtos/INotificationDto';
import {IConversations} from '../../models/IConversations';
import {INotesPaginationDto} from '../../dtos/INotesDto';
import {IHistoryLogsDto} from '../../dtos/IHistoryLogsDto';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const selectConversations = (state: any): IConversationDto[] => state.home.conversations;
export const selectSelectedConversation = (state: any): any => state.home.selectedConversation;

export const selectSnackbarState = (state: any): ISnackbarState => state.home.snackbarState;

export const selectPhases = (state: any): IPhasesDto[] => state.home.loadedPhases;
export const selectMember = (state: any): IUser => state.home.selectedMember;
export const selectCoach = (state: any): IUser => state.home.selectedCoach;
export const selectMeasurementUnit = (state: any): IMeasurementUnit => state.home.measurementUnit;

export const selectInsights = (state: any): IUserInsightsDto => state.home.userInsights;
export const selectAchievements = (state: any): IAchievementsDto[] => state.home.achievements;

export const selectAppointments = (state: any): IAppointmentDto[]  => state.home.appointments;
export const selectLunchAppointments = (state: any): IAppointmentDto[] =>
  state.home.appointments.filter(
    (oneAppointment: IAppointmentDto) => oneAppointment.appointmentType === AppointmentType.LUNCH,
  );
export const selectScheduledAppointments = (state: any): AppointmentType[] =>
  state.home.scheduledAppointments;
export const selectMemberAppointments = (state: any): IAppointmentDto[] =>
  state.home.memberAppointments;

export const selectNotifications = (state: any): INotificationDto[] => state.home.notifications;
export const selectPaginationNotes = (state: any): INotesPaginationDto =>
  state.home.notesPagination;

export const selectBackendConversations = (state: any): IConversations[] =>
  state.home.backendConversations;
export const selectCoachConversations = (state: any): IConversations[] =>
  state.home.coachConversations;
export const selectAllSupercoachConversations = (state: any): IConversationDto[] =>
  state.home.allSuperCoachConversations;

export const selectLoading = (state: any): boolean => state.home.isLoading;
export const selectParticipantIndex = (state: any): boolean => state.home.participantIndex;

export const selectSelectedConversationId = (state: any): string =>
  state.home.selectedConversationId;

export const selectHistoryLogs = (state: any): IHistoryLogsDto[] => state.home.historyLogs;
export const getIsMobileDownload = (state: any): boolean => state.home.isMobileDownload;
