import {Box, Paper, Typography} from '@material-ui/core';
import React from 'react';
import {IMealPlanComponentDetails} from '../../../dtos/IMealPlanComponentDetails';
import useStyles from './meal-plan-shared-component.styles';
import ReactMarkdown from 'react-markdown';

interface Props {
  detail: IMealPlanComponentDetails;
}
export const MealPlanSharedComponent: React.FC<Props> = ({detail}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <Box mb={1}>
        <Typography variant={'subtitle2'} align="left">
          {detail?.name}
        </Typography>
      </Box>
      <ReactMarkdown>{detail?.body || ''}</ReactMarkdown>
    </Paper>
  );
};
