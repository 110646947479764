import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((/*theme*/) => ({
  scheduleRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'linear-gradient(180.12deg, #EBEFFF 0.11%, #EBF4FF 28.4%, #9DC6F5 99.38%)',
    height: '100vh',
  },
}));

export default useStyles;
