import {createTheme} from '@material-ui/core/styles';
import {ThemeType} from '../utils/enums';
import {Theme} from '@material-ui/core';

export const getCurrentThemeType = (): string => {
  return localStorage.getItem('theme') || 'light';
};

export const setCurrentThemeType = (themeType: ThemeType): void => {
  localStorage.setItem('theme', themeType);
};

const fontWeight = {
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
};

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#000000', // main black  // ocean blue 34AFAF
      light: '#000000', // Primary/Ocean Blue //Primary/Yale Blue
      dark: '#042D5C', // Primary/ Deep Ocean Blue //Prussian Blue
    },
    secondary: {
      main: '#FFFFFF',
      light: '#72C492', // Secondary/Sea foam Green
      dark: '#8C8D8F', // Secondary/Stone Gray
    },
    background: {
      default: '#F5F6FA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#FFFFFF',
      hint: '',
      disabled: '',
    },
    colours: {
      red: '#F41047', // red on badge bell
    },
    uicolors: {
      blueCrayola: '#3772FF',
      greenRYB: '#58A22A',
      fireOpal: '#ED5945',
      honeyYellow: '#F9B339',
    },
    tintsAndShades: {
      oxfordBlueDark: '#001D3D',
      deepJungleGreen: '#045C5C',
      skobeloff: '#097070',
      darkCyan: '#1C8A8A',
      oceanBlue: '#34AFAF',
      mediumTurquoise: '#7CCBCB',
      lapisLazuli: '#3166A3',
      beauBlue: '#CEE0F5',
      babyBlueEyes: '#9BBBE0',
      blueGrey: '#6590C2',
      yaleBlue: '#E6EFFA',
      yaleBlueDark: '#042D5C',
      lightestBlue: '#EBF4FF',
    },
    blacksAndWhites: {
      smokyBlack: '#140802',
      jet: '#3D3733',
      davysGray: '#5C5754',
      sonicSilver: '#7A7673',
      silver: '#CCC9C8',
      gainsboro: '#E6E7EB',
      cultured: '#F5F3F2',
      pureWhite: '#FFFFFF',
    },
    blacksAndGrays: {
      jet: '#2D2D2E',
      onyx: '#414142',
      dimGray: '#646566',
      stoneGray: '#8C8D8F',
      grayX11: '#B4B5B8',
      silverSand: '#D2D3D6',
      gainsboro: '#E6E7EB',
      cultured: '#F5F6FA',
      davysGray: '#5C5754',
    },
    blues: {
      deepOceanBlue: '#003C4C',
      midnightEagleGreen: '#2F5A66',
      deepSpaceSparkle: '#2F5A66',
      tealBlue: '#406E7A',
      steelTeal: '#6F98A3',
      lightBlue: '#A3C3CC',
      columbiaBlue: '#CAE0E5',
      azureX11Web: '#E4F1F5',
    },
    tagColors: {
      frenchSkyBlue: '#85A9FF',
      babyBlueEyes: '#ADC6FF',
      lavenderWeb: '#D6E2FF',
      middleBlueGreen: '#89D6D6',
      powderBlue: '#BCEBEB',
      lightCyan: '#D7F5F5',
      pistachio: '#97D96D',
      grannySmithApple: '#B9E69E',
      nyanza: '#DCF2CE',
      turquoiseGreen: '#A9DBBC',
      aeroblue: '#C5E7D3',
      honeydew: '#E2F3E9',
      congoPink: '#F28B7D',
      melon: '#F6ACA2',
      babyPink: '#F9CDC7',
      darkSalmon: '#E99A72',
      apricot: '#F1C0A7',
      champagnePink: '#FAE6DC',
      maximumYellowRed: '#FAC261',
      deepChampagne: '#FCD99C',
      blanchedAlmond: '#FDE8C3',
      levelOne: '#9DC6F5',
      levelTwo: '#D2DAFA',
    },
    cta: {
      sunsetOrange: '#DF6A2E',
    },
    newTagColours: {
      teaGreen: '#D3EBBC',
      grannySmithApple: '#C0E0A2',
      magicMint: '#BCEBCE',
      turquoiseGreen: '#A2E0BA',
    },
    additionalColors: {
      richBlackFOGRA29: '#00141A',
      bdazzledBlue: '#405B7A',
      shadowBlue: '#6F87A3',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "Gantari, sans-serif",
    fontSize: 14,
    fontWeightLight: fontWeight.LIGHT,
    fontWeightRegular: fontWeight.REGULAR,
    fontWeightMedium: fontWeight.MEDIUM,
    fontWeightBold: fontWeight.BOLD,

    h1: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.BOLD,
      fontSize: 48,
      letterSpacing: '-0.75px',
      lineHeight: '52px',
    },
    h2: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.REGULAR,
      fontSize: 40,
      letterSpacing: '-0.5px',
      lineHeight: '44px',
    },
    h3: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.BOLD,
      fontSize: 36,
      letterSpacing: '-0.25px',
      lineHeight: '40px',
    },
    h4: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.MEDIUM,
      fontSize: 28,
      letterSpacing: '0px',
      lineHeight: '32px',
    },
    h5: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.BOLD,
      fontSize: 24,
      letterSpacing: '0px',
      lineHeight: '28px',
    },
    h6: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.BOLD,
      fontSize: 20,
      letterSpacing: '0.15px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.MEDIUM,
      fontSize: 16,
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },
    subtitle2: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.BOLD,
      fontSize: 16,
      letterSpacing: '0.1px',
      lineHeight: '20px',
    },
    body1: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.REGULAR,
      fontSize: 16,
      letterSpacing: '0.25px',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.REGULAR,
      fontSize: 14,
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },
    button: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.MEDIUM,
      fontSize: 14,
      letterSpacing: '1.25px',
      lineHeight: 'auto',
      textTransform: 'none',
    },
    caption: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.REGULAR,
      fontSize: 12,
      letterSpacing: '0.4px',
      lineHeight: '16px',
    },
    overline: {
      fontFamily: "Gantari, sans-serif",
      fontWeight: fontWeight.REGULAR,
      fontSize: 10,
      letterSpacing: '1.5px',
      lineHeight: '12px',
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        '& .MuiPickersToolbarText-toolbarBtnSelected': {
          color: 'white !important',
        },
        '& .MuiPickersToolbarText-toolbarTxt': {
          color: 'rgba(255, 255, 255, 0.54)',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontFamily: "Gantari, sans-serif",
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        // lineHeight: 24, not compatible with safari
        letterSpacing: '0.5px',
        /* Typography/Blacks & Grays/Smoky Black */
        color: '#140802',
      },
      input: {
        fontFamily: "Gantari, sans-serif",
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        // lineHeight: 24, not compatible with safari
        letterSpacing: '0.5px',
        padding: '11px 16px',

        '&::placeholder': {
          color: '#8C8D8F',
        },
      },
    },
    MuiPopover: {
      root: {
        // marginTop: '-8px',
      },
    },
    MuiPaper: {
      root: {
        '& .MuiPickersDay-daySelected, &:hover .MuiPickersDay-daySelected': {
          backgroundColor: '#000000',
          color: 'white',
        },
        '& .MuiPickersDay-dayDisabled': {
          color: 'grey',
        },
        '& .MuiFormControlLabel-label.Mui-disabled': {
          color: '#D2D3D6',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          color: '#D2D3D6 !important',
        },
        '& .MuiPickersClockNumber-clockNumberSelected ': {
          color: 'white',
        },
        '& .MuiPickersToolbar-toolbar': {
          backgroundColor: '#000000',
        },
        '& .MuiPickersClock-pin': {
          backgroundColor: '#000000',
          color: 'white',
        },
        '& .MuiPickersClockPointer-thumb': {
          borderColor: '#000000',
          color: 'white',
          backgroundColor: 'white',
        },
        '& .MuiPickersClockPointer-pointer ': {
          backgroundColor: '#000000',
          color: 'white',
        },
        '& .MuiPickersClockPointer-noPoint ': {
          backgroundColor: '#000000',
          color: 'white',
        },

        '& .MuiInputAdornment-positionEnd': {
          marginLeft: 0,
        },
        '& .MuiFormHelperText-contained': {
          color: 'red',
          marginRight: 0,
          marginLeft: 0,
        },
        '& .MuiFormGroup-root': {
          flexWrap: 'nowrap',
          minWidth: '98px',
        },
        '& .MuiDialogActions-root': {
          '& .MuiButton-textPrimary': {
            backgroundColor: 'white',
            '&:hover .MuiButton-label ': {
              opacity: 0.6,
            },
          },
        },
        '& .MuiSelect-outlined': {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          height: '18px',
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
          // backgroundColor:  'transparent',
          backgroundColor:  'transparent',
        },

        // fix to select dialog which is not picker
        '&.MuiDialog-paper.MuiDialog-paperWidthFalse': {
          maxWidth: 'unset',
          width: 700,
          borderRadius: '12px',
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#999',
    },
    secondary: {
      main: '#363537',
    },
    colours: {},
    uicolors: {},
    tagColors: {},
    tintsAndShades: {},
    blacksAndGrays: {},
    blacksAndWhites: {},
    blues: {},
    cta: {},
    newTagColours: {},
    additionalColors: {},
  },
});

export const getTheme = (): Theme => {
  return lightTheme;
};
