import {get, put, post, del} from './api-calls';
import {INoteRequestDataDto} from '../dtos/INoteRequestDataDto';
import {INotesPaginationDto, INotesDto} from '../dtos/INotesDto';

export const addUserNoteDatabase = (data: INoteRequestDataDto): Promise<any> => {
  return post(`/UserNotes`, data);
};

export const getNotes = (
  id: string,
  pageNumber?: number,
  pageSize?: number,
): Promise<INotesPaginationDto> => {
  return get(`/UserNotes?UserId=${id}&PageNumber=${pageNumber}&PageSize=${pageSize}`);
};

export const deleteNoteDatabase = (id: number): Promise<void> => {
  return del(`/UserNotes/${id}`);
};

export const editNoteDatabase = (note: INotesDto): Promise<any> => {
  return put(`/UserNotes/${note.id}`, note);
};
