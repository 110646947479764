import * as kemtaiActionTypes from '../actions/kemtai/kemtai-types';
import {createReducer} from '../../utils/helpers';

export interface IKemtaiState {
  challengesWorkoutGroups: any;
  routinesWorkoutGroups: any,
}

const initialState: IKemtaiState = {
  challengesWorkoutGroups: null,
  routinesWorkoutGroups: null,
};

const usersReducer = createReducer(initialState, {
  [kemtaiActionTypes.LOAD_CHALLENGES_WORKOUTGROUPS](state: IKemtaiState, action: any) {
    return {
      ...state,
      challengesWorkoutGroups: action.payload,
    };
  },
    [kemtaiActionTypes.LOAD_YOGA_GROUPS](state: IKemtaiState, action: any) {
      return {
        ...state,
        YogaGroups: action.payload,
      };
    },
  
  [kemtaiActionTypes.LOAD_ROUTINES_WORKOUTGROUPS](state: IKemtaiState, action: any) {
    return {
      ...state,
      routinesWorkoutGroups: action.payload,
    };
  },
});

export default usersReducer;
