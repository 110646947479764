import * as React from 'react';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import CollapseIcon from '../../../assets/icons/side-menu-bar/chevron-double-icon.svg';
import CollapseIconInactive from '../../../assets/icons/side-menu-bar/chevron-double-inactive-icon.svg';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';

import useStyles from './connections.styles';

interface Props {
  clicked?: boolean;
}

export const ExpandIcon: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  return (
    <img
      className={classes.collapseIcon}
      src={props.clicked ? CollapseIcon : CollapseIconInactive}
      alt="expand-icon"
    />
  );
};
