import {createReducer} from '../../utils/helpers';

import * as filterActionTypes from '../actions/filters/filter-types';

import {
  ISetSelectedFilters
} from '../actions/filters/filter-actions';

export interface IFilterState {
  selectedFilters: null;
}

const initialState: IFilterState = {
  selectedFilters: null
};
const filterReducers = createReducer(initialState, {
  [filterActionTypes.SET_SELECTED_CONNECTION_FILTERS](state: IFilterState, action: ISetSelectedFilters) {
    return {
      ...state,
      selectedFilters: action.payload,
    };
  },

});

export default filterReducers;