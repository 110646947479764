import {IColumnModifiers, ISchema} from '../../../utils/table-meta-helper';
import React from 'react';
import {EMPTY_FIELD_PLACEHOLDER} from '../../../utils/constants';
import useStyles from './table.styles';
import {Typography} from '@material-ui/core';

interface Props {
  row: any;
  onRowClick?: (row: any) => void;
  columnModifiers: IColumnModifiers;
  schema: ISchema;
}

export const TableRowDetails: React.FC<Props> = ({row, schema, columnModifiers}) => {
  const classes = useStyles();
  const data = schema.columns.reduce((acc: any, column: any) => {
    const withModifier = columnModifiers && columnModifiers[column.key];
    const value = withModifier ? withModifier(row[column.key]) : row[column.key];
    acc[column?.key] = value;
    return acc;
  }, {});
  return (
    <div className={classes.tableRowDetailsContainer}>
      <div className={classes.topContent}>
        {data.imageUrl}
        <div className={classes.topContentName}>
          <Typography variant={'subtitle1'}>
            <mark>{data?.name.split(' ')[0]}</mark> {data?.name.split(' ')[1]}
          </Typography>
          <Typography variant={'caption'}>L1 member</Typography>
        </div>
      </div>
      <div className={classes.centerContent}>
        <div className={classes.centerContentPart}>
          <Typography variant={'caption'}>External Id:</Typography>
          <Typography variant={'body2'}>{data.externalId || EMPTY_FIELD_PLACEHOLDER}</Typography>
        </div>
        <div className={classes.centerContentPart}>
          <Typography variant={'caption'}>Email:</Typography>
          <Typography variant={'body2'}>{data.email || EMPTY_FIELD_PLACEHOLDER} </Typography>
        </div>
        <div className={classes.centerContentPart}>
          <Typography variant={'caption'}>Phone Number:</Typography>
          <Typography variant={'body2'}>{data.email || EMPTY_FIELD_PLACEHOLDER}</Typography>
        </div>
      </div>
      <div className={classes.bottomContent}>
        <Typography variant={'subtitle1'}>Coach: Someone</Typography>
      </div>
    </div>
  );
};
