import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';
import {Button} from '../button/Button';
import {SearchBox} from '../search-box/SearchBox';
import {DatePicker} from '../pickers/DatePicker';
import {IUser} from '../../../models/IUser';
import ConnectionsInactiveItemIcon from '../../../assets/icons/side-menu-bar/connections-inactive-icon.svg';
import CloseIcon from '../../../assets/icons/rest/close-icon-white.svg';
import * as usersActions from '../../../store/actions/users/users-action';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import {ValidationType} from '../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../validation/Validation';
import {addDaysToDate, getDateFromDate} from '../../../utils/date-time-utils';
import {
  checkIfUserIsSuperCoach,
  checkIfUserHasHigherRoles,
  checkUserRole,
  checkIfUserIsMember,
} from '../../../utils/users-managment';
import {DATE_PICKER_DATE_FORMAT} from '../../../utils/constants';
import { hasAdminRoles } from '../../../utils/role-helper';
import {IUserDto} from '../../../dtos/IUserDto';
import { getCoaches } from '../../../api/users.api';

import useStyles from './coaches-item-styles';

interface IProps {
  user: any;
  setOpen: any;
  initialData?: any;
  primaryCoach?: IUserDto;
  myProfile: boolean;
  reloadData: () => void;
  secondaryCoach: boolean;
  isMobile: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const ContentDialogProfileContent: React.FC<IProps> = ({
  setOpen,
  user,
  initialData,
  myProfile,
  reloadData,
  secondaryCoach,
  primaryCoach,
  isMobile,
}) => {
  const classes = useStyles({
    isMobile
  });
  const [t] = useTranslation();
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coaches, setCoaches] = useState<IUser[]>();
  const [searchValue, setSearchValue] = useState('');
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>({});
  const [selectedCoach, setSelectedCoach] = useState({
    key: '',
    value: '',
    img: '',
    role: '',
  });
  const [data, setData] = useState({
    startDate: initialData?.startDate,
    endDate: initialData?.endDate,
  });

  useEffect(() => {
    const loadCoachInfo = async () => {
      if (selectedCoach.key != '') {
        const user = await usersActions.getUserById(selectedCoach.key);
        const role = checkUserRole(user);
        setSelectedCoach({...selectedCoach, img: user?.imageUrl || '', role: role || ''});
      }
    };
    loadCoachInfo();
  }, []);

  useEffect(() => {
    const initialObject = {
      key: secondaryCoach
        ? initialData?.secondaryCoachId || ''
        : primaryCoach
        ? primaryCoach?.id
        : '',
      value: secondaryCoach
        ? initialData.fullName
        : primaryCoach
        ? `${primaryCoach.firstName} ${primaryCoach.lastName}`
        : '',
      img: '',
      role: '',
    };
    setSelectedCoach(initialObject);
  }, []);

  const validationRules = {
    endDate: [
      {
        type: ValidationType.COMPARE_DATES,
        value: 'startDate',
      },
    ],
  };

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };
  const validateToSubmit = () => {
    if (!secondaryCoach) {
      return selectedCoach.key != '';
    }
    if (!hasChanges) return false;
    if (!selectedCoach || selectedCoach.key === '') return false;

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const handleFilter = async (searchValue: any) => {
    const filterOptions = {
      searchFilter: searchValue,
      startDate: getDateFromDate(new Date(data.startDate)),
      endDate: getDateFromDate(new Date(data.endDate)),
    };
    const result: IUserDto[] = await getCoaches(filterOptions);
    const users: any = [];
    result.forEach((item) => {
      users.push(usersActions.saveUserObject(item));
    });
    setCoaches(users);
  };

  const handleChangeSearchValue = async (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value?.length >= 3) {
      await handleFilter(value);
    }
    if (value === '') {
      setCoaches([]);
    }
  };

  const handleSaveModal = async () => {
    setIsLoading(true);
    if (secondaryCoach) {
      if (
        (checkIfUserHasHigherRoles(currentUser,true)) &&
        !myProfile &&
        checkIfUserIsMember(user)
      ) {
        const oneUserCoachEdit = {
          id: initialData?.id || null,
          userId: user.id,
          secondaryCoachId: selectedCoach.key,
          primaryCoachId: user.trainer?.id,
          isActiveSecondaryCoach: initialData.isActiveSecondaryCoach,
          deleted: initialData.deleted,
          startDate: getDateFromDate(new Date(data.startDate)),
          endDate: getDateFromDate(new Date(data.endDate)),
          endNowSecondaryCoach: false,
        };
        await dispatch(homepageActions.editOneSecondaryCoach(oneUserCoachEdit));
      } else {
        const dataToSend = {
          primaryCoachId: myProfile ? currentUser.id : user.id,
          secondaryCoachId: selectedCoach.key,
          startDate: getDateFromDate(new Date(data.startDate)),
          endDate: getDateFromDate(new Date(data.endDate)),
          listOfUserIds:
            !secondaryCoach && (checkIfUserIsSuperCoach(currentUser) || hasAdminRoles(currentUser)) && !myProfile
              ? user.id
              : initialData?.listOfUserIds || null,
        };
        await dispatch(homepageActions.assignSecondaryCoach(dataToSend));
      }
      setSelectedCoach({
        key: initialData.secondaryCoachId || '',
        value: initialData.fullName,
        img: '',
        role: '',
      });
    } else {
      const data = {
        traineeId: user.id,
        trainerId: selectedCoach.key,
      };
      await dispatch(homepageActions.editUsersPersonalCoach(data));
    }
    setIsLoading(false);
    setOpen(false);
    reloadData();
  };

  const handleClickDismiss = () => {
    setOpen(false);
    setHasChanges(false);
    setSearchValue('');
    setCoaches([]);
  };

  const changeSpecificDataType = (value: any, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
    setHasChanges(true);
    handleFilter(searchValue);
  };

  return (
    <React.Fragment>
      <div>
        {secondaryCoach && (
          <React.Fragment>
            <Typography variant={'subtitle1'} className={classes.rowContainer}>
              {t('Homepage.quickProfileUser.secondaryModalTitle')}
            </Typography>

            <div className={classes.datePickersRow}>
              <DatePicker
                name={'startDate'}
                value={data.startDate}
                className={classes.coachesDatePickerModal}
                label={t('Homepage.quickProfileUser.startDate')}
                placeholder={t('Homepage.quickProfileUser.datePlaceholder')}
                format={DATE_PICKER_DATE_FORMAT}
                onChange={(value: any) => changeSpecificDataType(value, 'startDate')}
                minDate={addDaysToDate(new Date().toString(), 1)}
                error={hasError(errors, 'startDate')}
                helperText={getError(errors, 'startDate')}
                onBlur={() => validateFormField('startDate')}
              />
              <DatePicker
                name={'endDate'}
                value={data.endDate}
                format={DATE_PICKER_DATE_FORMAT}
                className={classes.coachesDatePickerModal}
                label={t('Homepage.quickProfileUser.endDate')}
                placeholder={t('Homepage.quickProfileUser.datePlaceholder')}
                onChange={(value: any) => changeSpecificDataType(value, 'endDate')}
                minDate={addDaysToDate(data.startDate?.toString(), 1)}
                error={hasError(errors, 'endDate')}
                helperText={getError(errors, 'endDate')}
                onBlur={() => validateFormField('endDate')}
              />
            </div>
          </React.Fragment>
        )}
        <Typography variant={'body2'} className={classes.rowContainer}>
          {secondaryCoach
            ? t('Homepage.quickProfileUser.secondaryModalSubtitle')
            : t('Homepage.quickProfileUser.personalModalSubtitle')}
        </Typography>
        <SearchBox
          name="search-coaches"
          placeholder={t('Homepage.quickProfileUser.secondaryModalSearchPlaceholder')}
          onChange={handleChangeSearchValue}
          value={searchValue}
          containerClass={classes.searchBox}
        />
        {selectedCoach.key != '' ? (
          <div>
            <Typography variant={'overline'} className={classes.rowContainer}>
              {secondaryCoach
                ? t('Homepage.quickProfileUser.chosenSecondaryCoach')
                : t('Homepage.quickProfileUser.chosenPersonalCoach')}
            </Typography>
            <div className={classes.selectedConn}>
              <div className={classes.profileItem}>
                <img
                  className={classes.connectionItemIcon}
                  src={selectedCoach.img ? selectedCoach.img : ConnectionsInactiveItemIcon}
                  alt={'selected-coach-img'}
                />
                <div className={classes.columns}>
                  <Typography
                    variant={'body1'}
                    className={classNames(classes.coachName, classes.selectedTitle)}
                  >
                    {selectedCoach?.value}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    className={classNames(classes.selectedTitle, classes.coachRole)}
                  >
                    {selectedCoach.role}
                  </Typography>
                </div>
              </div>
              <div
                onClick={() =>
                  setSelectedCoach({
                    key: '',
                    value: '',
                    img: '',
                    role: '',
                  })
                }
                tabIndex={-1}
                onKeyDown={() => {}}
                role={'button'}
              >
                <img src={CloseIcon} alt={'delete-selected-coach'} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.coachesList}>
            {coaches &&
              coaches?.length > 0 &&
              coaches?.map((coach) => (
                <div
                  key={coach.id}
                  className={classNames(classes.profileItem, classes.marginBottom)}
                  onClick={() => {
                    setSelectedCoach({
                      key: coach.id,
                      value: `${coach.firstName} ${coach.lastName}`,
                      img: coach.imageUrl || ConnectionsInactiveItemIcon,
                      role: checkUserRole(coach) || '',
                    });
                    setHasChanges(true);
                  }}
                  tabIndex={-1}
                  onKeyDown={() => {}}
                  role={'button'}
                >
                  <img
                    className={classes.connectionItemIcon}
                    src={coach.imageUrl ? coach.imageUrl : ConnectionsInactiveItemIcon}
                    alt={'coach-img'}
                  />
                  <div className={classes.columns}>
                    <Typography variant={'body1'} className={classes.coachName}>
                      {`${coach.firstName} ${coach.lastName}`}
                    </Typography>
                    <Typography variant={'body1'} className={classes.coachRole}>
                      {checkUserRole(coach)}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
        )}
        <div className={classes.editButtons}>
          <Button
            id={'cancel-button-profile'}
            variant={'outlined'}
            className={classes.outlinedBtn}
            onClick={handleClickDismiss}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
          <Button
            id={'save-button-profile'}
            loading={isLoading}
            disabled={!validateToSubmit()}
            onClick={handleSaveModal}
          >
            {t('Homepage.quickProfileUser.saveButton')}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContentDialogProfileContent;
