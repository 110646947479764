import useStyles from './user-search-page.styles';
import {Typography} from '@material-ui/core';
import {SearchBox} from '../../../common/components/search-box/SearchBox';
import {TableList} from '../../../common/components/tables/TableList';
import React, {useState, useEffect} from 'react';
import {metaData} from '../../../utils/table-meta-helper';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearPaginationUsers,
  searchUsersWithPagination,
} from '../../../store/actions/users/users-action';
import {selectCount} from '../../../store/selectors/users.selectors';
import Loader from '../../../common/components/loader/Loader';
import {useDebounce} from '../../../common/hooks/use-debounce';
import {IUserFilter} from '../../../models/IUserFilter';
import {AdminPaths, EMPTY_IMAGE} from '../../../utils/constants';
import {generatePath} from 'react-router';
import history from '../../../history';
import {useTranslation} from 'react-i18next';
import {TableRowDetails} from '../../../common/components/tables/TableRowDetails';

interface IPageParams {
  page: number;
  rowsPerPage: number;
}

const UserSearchPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const filteredUsers: IUserFilter[] = useSelector(selectAllFilteredUsers);
  const filteredUsers: IUserFilter[] = [
    {
      id: '1',
      name: 'Pera Peric',
      email: 'pera.peric@gmail.com',
      imageUrl: EMPTY_IMAGE,
      phone: '123-123-123',
    },
    {
      id: '2',
      name: 'Mika Mikic',
      email: 'mika.mikic@gmail.com',
      imageUrl: EMPTY_IMAGE,
      phone: '123-123-123',
    },
    {
      id: '3',
      name: 'Zika Zikic',
      email: 'zika.zikic@gmail.com',
      imageUrl: EMPTY_IMAGE,
      phone: '123-123-123',
    },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const count = useSelector(selectCount);
  const [t] = useTranslation();
  const INITIAL_TITLE = t('UserSearchPage.initialTitle');
  const [pageParams, setPageParams] = useState<IPageParams>({
    page: 0,
    rowsPerPage: 10,
  });
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [titleName, setTitleName] = useState<string>(INITIAL_TITLE);
  const debouncedFilter = useDebounce<string>(searchFilter, 500);

  const fetchData = async () => {
    setLoading(true);
    try {
      await dispatch(
        searchUsersWithPagination({
          searchFilter,
          pageNumber: 0,
          pageSize: 10,
        }),
      );
      setTitleName(`${t('UserSearchPage.title')} ${searchFilter}`);
    } catch (err) {
      //console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPageData = async () => {
    setLoading(true);
    try {
      await dispatch(
        searchUsersWithPagination({
          searchFilter,
          pageNumber: pageParams.page,
          pageSize: 10,
        }),
      );
    } catch (err) {
      //console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPageParams({
      ...pageParams,
      page: 0,
    });
    if (!searchFilter) {
      dispatch(clearPaginationUsers());
      setTitleName(INITIAL_TITLE);
      return;
    }
    fetchData();
  }, [debouncedFilter]);

  useEffect(() => {
    if (!searchFilter) {
      dispatch(clearPaginationUsers());
      setTitleName(INITIAL_TITLE);
      return;
    }
    fetchPageData();
  }, [pageParams.page]);

  const handleOnSearchChange = (e: {target: HTMLInputElement}) => {
    const searchFilter = e.target.value;
    setSearchFilter(searchFilter);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPageParams({
      ...pageParams,
      page: newPage,
    });
  };

  const handleOnRowClick = (row: any) => {
    history.push(generatePath(AdminPaths.UserProfilePage, {userId: row.id}));
  };

  return (
    <div className={classes.container}>
      <Typography variant={'h3'}>{titleName}</Typography>
      <SearchBox
        label={t('UserSearchPage.search')}
        containerClass={classes.searchBoxContainer}
        name={'searchBox'}
        placeholder={'Search'}
        onChange={handleOnSearchChange}
        value={searchFilter}
      />
      {loading && <Loader />}
      {filteredUsers?.length > 0 && (
        <TableList
          renderRowContent={(props: any) => <TableRowDetails {...props} />}
          rows={filteredUsers}
          handleChangePage={handleChangePage}
          meta={metaData.userSearch}
          onRowClick={handleOnRowClick}
          count={count}
          {...pageParams}
        />
      )}
    </div>
  );
};

export default UserSearchPage;
