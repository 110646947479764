/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useStyles from './ReEnrollment.styles';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import {TextField} from '../../../common/components/text-field/TextField';
import { getError, hasError, isFormValidToSubmit, IValidationRules, validate, validateField } from '../../../validation/Validation';
import { ValidationType } from '../../../validation/ValidationType';
import { MaskedTextField } from '../../../common/components/masked-text-field/MaskedTextField';
import classNames from 'classnames';
import { Button } from '../../../common/components/button/Button';
import * as usersActions from '../../../store/actions/users/users-action';

import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { GeneralPaths } from '../../../utils/constants';
import Loader from '../../../common/components/loader/Loader';

interface IProfileInfoOnBoardingFormData {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    email: string;
    phoneNumber: string;
    AddressLine1: string,
    AddressLine2: string,
    city: string,
    state: string,
    zip: string,
    enrollmentStatusId?: number
  }

const validationRules: IValidationRules = {
    firstName: [{type: ValidationType.REQUIRED}],
    lastName: [{type: ValidationType.REQUIRED}],
    dateOfBirth: [{type: ValidationType.REQUIRED}, {type: ValidationType.BIRTH_DATE}],
    email: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_EMAIL}],
    phoneNumber: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_PHONE_NUMBER}],
    AddressLine1: [{type: ValidationType.REQUIRED}],
    city: [{type: ValidationType.REQUIRED}],
    state: [{type: ValidationType.REQUIRED}],
    zip: [{type: ValidationType.REQUIRED}],
  };

const ReEnrollmentPage: React.FC = () => {
    const classes = useStyles();
    const [t] = useTranslation();

    const [data, setData] = useState<IProfileInfoOnBoardingFormData>({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        phoneNumber: '',
        AddressLine1: '',
        AddressLine2: '',
        city: '',
        state: '',
        zip: '',
      });
    const [errors, setErrors] = useState<any>({});
    const [isChanged, setIsChanged] = useState(false);
    // const [isLoading, setLoading] = useState(false);
    const [apiPhoneError, setApiPhoneError] = useState(null)
    const [apiEmailError, setApiEmailError] = useState(null)
    const [globalError, setGlobalError] = useState('')
    const [apiCall, setApiCall] = useState(false)

    const dispatch = useDispatch();
    const params: any = useParams();

    useEffect(() => {
      if(params?.userId){
        const loadUserInfo = async () => {
          // console.log('userId-', params?.userId)
          const userData = await usersActions.reEnrollGet(params?.userId);
          const user = JSON.parse(userData?.sponsorJson)
          // console.log('userData-', JSON.parse(userData?.sponsorJson))
          if(user){
            let date = new Date(user?.Birthdate);
            date = ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2) + '-' + date.getFullYear();
            const phoneNumberFormat = user?.Phone_number.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
            const phoneNumber = phoneNumberFormat[1] + '-' + phoneNumberFormat[2] + '-' + phoneNumberFormat[3];
            setData({
              firstName: user?.Given_name,
              lastName: user?.Family_name,
              dateOfBirth: date.toString(),
              email: user?.Email,
              phoneNumber: phoneNumber,
              enrollmentStatusId: user?.EnrollmentStatusId,
              AddressLine1: user?.address?.Street1,
              AddressLine2: user?.address?.Street2,
              city: user?.address?.City,
              state: user?.address?.State,
              zip: user?.address?.ZipCode?.substring(0, 5),
            })
          }
        }
        loadUserInfo()
      }
    }, []);

    const validateFormField = (field: string) => {
        const err = validateField(data, validationRules, field);
        setErrors({
          ...errors,
          [field]: err,
        });
      };

      const handleChange = (e: any) => {
        if(!isChanged) setIsChanged(true);
        const value = e.target.value;
        const name = e.target.name;
        setApiPhoneError(null)
        setApiEmailError(null)
        setGlobalError('')
        setData({
          ...data,
          [name]: value,
        });
      };

      const validateToSubmit = () => {
        const err = validate(data, validationRules);
        return isFormValidToSubmit(err);
      };

      const history = useHistory()
      const redirectWeb = () => {
        history.replace({
          pathname: `${GeneralPaths.LandingPage}`,
          search: '',
        });
      }

      const handleSubmit = () => {
        const payload = {
          "firstName": data?.firstName,
          "lastName": data?.lastName,
          "email": data?.email,
          "phoneNumber": data?.phoneNumber,
          "dateOfBirth": data?.dateOfBirth,
          "enrollmentStatusId": data?.enrollmentStatusId,
          "phoneStatus": 1,
          "address": {
            "addressLine1": data?.AddressLine1,
            "addressLine2": data?.AddressLine2,
            "city": data?.city,
            "state": data?.state,
            "zip": data?.zip
          }
        }
        payload.code = params?.userId
        setApiCall(true);
        dispatch(usersActions.updateReEnrollUser(payload)).then((res: any) => {
          if(res){
            setApiCall(false);
            // console.log('res', res)
            if(res?.statusId === 1) {
              redirectWeb()
            }
            if(res?.statusId === 0) {
              setApiPhoneError({
                isError : false,
                errorMsg : 'Phone number is used by another account. Enter the unique phone number.',
              })
              setApiEmailError({
                isError : false,
                errorMsg : 'Email is used by another account. Enter the unique email address.',
              })
            }
            if(res?.statusId === 4) {
              setApiPhoneError({
                isError : false,
                errorMsg : 'Phone number is not valid. Enter the valid and unique phone number.',
              })
            }
            if(res?.statusId === 3) {
              setApiPhoneError({
                isError : false,
                errorMsg : 'Phone number is used by another account. Enter the unique phone number.',
              })
            }

            if(res?.statusId === 2) {
              setApiEmailError({
                isError : false,
                errorMsg : 'Email is used by another account. Enter the unique email address.',
              })
            }

            if(res?.statusId === 7 || res?.statusId === 8 || res?.statusId === 9) {
              setApiPhoneError({
                isError : false,
                errorMsg : 'Phone number is used by another account. Enter the unique phone number.',
              })
            }
            if(res?.statusId === 0 || res?.statusId === 5 || res?.statusId === 6) {
              setApiEmailError({
                isError : false,
                errorMsg : 'Email is used by another account. Enter the unique email address.',
              })
            }
          } else {
            // console.log('')
          }
        })
      }

    return (
        <div className={classes.posterContainer}>
            <div>
                <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
            </div>
            <div className={classes.paperContent}>
            {
                apiCall &&
                <div className={classes.loaderLayer}>
                  <Loader />
                </div>
              }
            <h1>Welcome, {data?.firstName}!</h1>
            <p>Please review or edit your basic information. If something is missing please fill in to continue.
              {/* {
                userData?.phoneStatus === "0" ?
                'The phone number is invalid and they should change.' :
                userData?.phoneStatus === "2" ?
                  'The given phone is a landline and they should change it to mobile to receive text.' :
                  'If something is missing please fill in to continue.'
              } */}
            </p>
            {globalError && <p class="errorP">{globalError}</p>}
            {/* <div className='fieldBox'>
                <label>Sponsor Code*</label>
                <div className='code-fieldBox'>
                    <TextField name="code1" />
                    <TextField name="code2" />
                    <TextField name="code3" />
                    <TextField name="code4" />
                </div>
            </div> */}
            <div className='fieldBox'>
                <TextField
                name={'firstName'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.firstName')}
                value={data.firstName}
                placeholder={t('OnBoardingPage.profileInfo.firstName')}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'firstName')}
                helperText={getError(errors, 'firstName')}
                onBlur={() => validateFormField('firstName')}
                />
            </div>
            <div className='fieldBox'>
                <TextField
                name={'lastName'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.lastName')}
                value={data.lastName}
                placeholder={t('OnBoardingPage.profileInfo.lastName')}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'lastName')}
                helperText={getError(errors, 'lastName')}
                onBlur={() => validateFormField('lastName')}
                />
            </div>
            <div className='fieldBox'>
                <MaskedTextField
                name={'dateOfBirth'}
                className={classNames(classes.maskedPickerWidth, classes.dateOfBirthWidth)}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.dateOfBirth')}
                value={data.dateOfBirth}
                placeholder={t('OnBoardingPage.profileInfo.dateOfBirthPlaceholder')}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'dateOfBirth')}
                helperText={getError(errors, 'dateOfBirth')}
                onBlur={() => validateFormField('dateOfBirth')}
                mask="Md-Dd-dddd"
                formatChars={{
                    M: '[0-1]',
                    D: '[0-3]',
                    d: '[0-9]',
                }}
                />
            </div>
            <div className='fieldBox'>
              <TextField
                name={'email'}
                type={'email'}
                className={classNames(classes.normalTextField, classes.emailTextField)}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.email')}
                value={data.email}
                placeholder={t('OnBoardingPage.profileInfo.email')}
                onChange={(event) => handleChange(event)}
                error={
                  apiEmailError && !apiEmailError?.isError ? !apiEmailError?.isError :
                  hasError(errors, 'email')
                }
                helperText={
                  apiEmailError && !apiEmailError?.isError ? apiEmailError?.errorMsg :
                  getError(errors, 'email')
                }
                onBlur={() => validateFormField('email')}
              />
            </div>
            <div className='fieldBox'>
              <MaskedTextField
                name={'phoneNumber'}
                className={classes.maskedPickerWidth}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.phoneNumber')}
                value={data.phoneNumber}
                placeholder={t('OnBoardingPage.profileInfo.phoneNumberPlaceholder')}
                onChange={(event) => handleChange(event)}
                error={
                  apiPhoneError && !apiPhoneError?.isError ? !apiPhoneError?.isError :
                  hasError(errors, 'phoneNumber')
                }
                helperText={
                  apiPhoneError && !apiPhoneError?.isError ? apiPhoneError?.errorMsg :
                  getError(errors, 'phoneNumber')
                }
                onBlur={() => validateFormField('phone')}
                mask="999-999-9999"
              />
            </div>
            <div className='fieldBox'>
                <TextField
                name={'AddressLine1'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'Address 1'}
                value={data.AddressLine1}
                placeholder={'Address 1'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'AddressLine1')}
                helperText={getError(errors, 'AddressLine1')}
                onBlur={() => validateFormField('AddressLine1')}
                />
            </div>
            <div className='fieldBox'>
                <TextField
                name={'AddressLine2'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={false}
                label={'Address 2'}
                value={data.AddressLine2}
                placeholder={'Address 2'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'AddressLine2')}
                helperText={getError(errors, 'AddressLine2')}
                onBlur={() => validateFormField('AddressLine2')}
                />
            </div>
            <div className='fieldBox'>
                <TextField
                name={'city'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'City'}
                value={data.city}
                placeholder={'City'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'city')}
                helperText={getError(errors, 'city')}
                onBlur={() => validateFormField('city')}
                />
            </div>
            <div className='fieldBox'>
                <TextField
                name={'state'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'state'}
                value={data.state}
                placeholder={'State'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'state')}
                helperText={getError(errors, 'state')}
                onBlur={() => validateFormField('state')}
                />
            </div>
            <div className='fieldBox'>
                <TextField
                name={'zip'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'Zip Code'}
                value={data.zip}
                placeholder={'Zip Code'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'zip')}
                helperText={getError(errors, 'zip')}
                onBlur={() => validateFormField('zip')}
                inputProps={{ maxLength: 5 }}
                />
            </div>
            <Button
                id="continue"
                onClick={handleSubmit}
                // loading={isLoading}
                disabled={
                  apiPhoneError ? true :
                  apiEmailError ? true :
                  !validateToSubmit()
                }
                className={classes.bottomButton}
            >Confirm</Button>
        </div>
        </div>
    )
}

ReEnrollmentPage.displayName = 'ReEnrollment Page';
export default ReEnrollmentPage;
