import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {isMobile} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import classNames from 'classnames';
import {Tag} from './Tag';
import {ICasualTag} from '../../../models/ITag';
import {TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './tags-with-box.styles';

interface Props {
  disabled?: boolean;
  className?: string;
  id: string;
  name: string;
  originalTagsList: ICasualTag[] | [];
  ref: any;
  validateParent?: any;
  color?: React.CSSProperties['color'];
  borderColor?: string;
}

const TagsWithBox: React.FC<Props> = forwardRef((props, ref) => {
  const {id, originalTagsList, className} = props;
  const [selectedTagsList, setSelectedTagsList] = useState<ICasualTag[]>([]);
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, { noSsr: true })
  const isMobileOrTablet = React.useMemo(() => {
    return  isMobile || isMobileSize
  }, [window.innerWidth])
  const classes = useStyles({
    isMobileOrTablet: isMobileOrTablet
  });

  const handleClick = (e: any) => {
    const selectedTag: ICasualTag | undefined = originalTagsList?.find((oneTag) => oneTag.id == e);

    if (selectedTag !== undefined) {
      selectedTag.isVisible = false;

      const tag: ICasualTag = {
        id: selectedTag.id,
        name: selectedTag.name,
      };

      const newList = [...selectedTagsList, tag];
      setSelectedTagsList(newList);
      props.validateParent && props.validateParent(newList);
    }
  };

  useEffect(() => {
    const filteredTagsList = originalTagsList.filter((item) => item.isVisible === false);
    setSelectedTagsList(filteredTagsList);
  }, [originalTagsList]);

  const handleDelete = (e: any) => {
    const selectedTag: ICasualTag | undefined = originalTagsList?.find((oneTag) => oneTag.id == e);

    if (selectedTag !== undefined) {
      selectedTag.isVisible = true;

      const newList = [...selectedTagsList.filter((oneTag) => oneTag.id !== e)];
      setSelectedTagsList(newList);
      props.validateParent && props.validateParent(newList);
    }
  };

  useImperativeHandle(ref, () => ({
    getSelectedTags(): ICasualTag[] {
      return selectedTagsList;
    },

    clearSelectedTags(): void {
      setSelectedTagsList([]);
    },
  }));

  return (
    <div className={classNames(className, classes.root)} key={props.name + ' ' + id}>
      <div className={classes.tagsContainer}>
        {originalTagsList?.map(
          (oneTag) =>
            oneTag.isVisible && (
              <Tag
                key={`tag-${oneTag.id}`}
                name="chip"
                id={oneTag.id || 0}
                label={oneTag.name}
                onClick={handleClick}
              />
            ),
        )}
      </div>

      <div className={classes.boxTags}>
        {selectedTagsList &&
          originalTagsList.map(
            (oneSelectedTag) =>
              !oneSelectedTag.isVisible && (
                <Tag
                  key={`tag-selected-${oneSelectedTag.id}`}
                  name="chip"
                  id={oneSelectedTag.id || 0}
                  label={oneSelectedTag.name}
                  isSelected={true}
                  onDelete={handleDelete}
                />
              ),
          )}
      </div>
    </div>
  );
});

TagsWithBox.displayName = 'TagsWithBox';
export default TagsWithBox;
