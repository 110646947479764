import React from 'react';
import {useTranslation} from 'react-i18next';
import LogoutIcon from '../../../assets/icons/app-nav-bar/logout-icon.svg';

import useStyles from './app-nav-bar-settings.styles';

interface IAppNavBarSettings {
  handleClose: () => void;
  selectedMember: any;
  callLogOut: () => void;
}

export const AppNavBarSettings: React.FC<IAppNavBarSettings> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.settingsContainer}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={classes.settingsPanelBackground} onClick={() => props.handleClose()} />
      <div className={classes.settingsPanelContent}>
        <div className={classes.settingsPanelLine} />
        <a
          href="https://betrhealth.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('baseLayout.termsAndPolicy.termsAndConditions')}
        </a>
        <a href="https://betrhealth.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
          {t('baseLayout.termsAndPolicy.privacyPolicy')}
        </a>
        <button onClick={() => props.callLogOut()}>
          <img src={LogoutIcon} alt="close notifications" />
          <div>{t('Homepage.logOutButton')}</div>
        </button>
      </div>
    </div>
  );
};
