import {createReducer} from '../../utils/helpers';
import {IConversationDto} from '../../dtos/IConversationDto';
import {IConversations} from '../../models/IConversations';
import {IHistoryLogsDto} from '../../dtos/IHistoryLogsDto';
import * as homepageActionTypes from '../actions/homepage/homepage-types';
import {
  IChangeStateSnackbar,
  ILoadConversations,
  ILoadUserInsights,
  ISelectConversation,
  ILoadPhases,
  ISelectMember,
  ILoadUserAppointments,
  ILoadMemberAppointments,
  ILoadAchievements,
  ISelectMeasurementUnit,
  ILoadNotifications,
  ILoadBackendConversations,
  ILoading,
  IParticipantIndex,
  ILoadNotesPagination,
  ISelectCoach,
  ILoadCoachConversations,
  ILoadAllSuperCoachConversations,
  ISaveSelectedConversationId,
  ILoadHistoryLogs,
  IIsMobileDownload,
} from '../actions/homepage/homepage-action';
import {ISnackbarState} from '../../common/components/snackbar/Snackbar';
import {IUserInsightsDto} from '../../dtos/IInsightsDto';
import {IPhasesDto} from '../../dtos/IPhasesDto';
import {IUser} from '../../models/IUser';
import {IAppointmentDto} from '../../dtos/IAppointmentDto';
import {IAchievementsDto} from '../../dtos/IAchievementsDto';
import {AppointmentType} from '../../utils/enums';
import {IMeasurementUnit} from '../../models/IMeasurementUnit';
import {INotificationDto} from '../../dtos/INotificationDto';
import {INotesPaginationDto} from '../../dtos/INotesDto';

export interface IConversationState {
  conversations: IConversationDto[] | [];
  backendConversations: IConversations[] | [];
  coachConversations: IConversations[] | [];
  allSuperCoachConversations: IConversationDto[];
  selectedConversation: IConversationDto | null;
  snackbarState: ISnackbarState;
  userInsights: IUserInsightsDto;
  loadedPhases: IPhasesDto[] | [];
  selectedMember: IUser | null;
  selectedCoach: IUser | null;
  appointments: IAppointmentDto[] | [];
  scheduledAppointments: AppointmentType[] | [];
  achievements: IAchievementsDto[] | [];
  measurementUnit: IMeasurementUnit | null;
  notifications: INotificationDto[] | [];
  notesPagination: INotesPaginationDto | null;
  memberAppointments: IAppointmentDto[] | [];
  isLoading: boolean;
  participantIndex: number;
  selectedConversationId: string | null;
  historyLogs: IHistoryLogsDto[] | null;
  isMobileDownload: boolean;
}

const initialState: IConversationState = {
  conversations: [],
  backendConversations: [],
  coachConversations: [],
  allSuperCoachConversations: [],
  selectedConversation: null,
  snackbarState: {
    open: false,
    vertical: 'top',
    horizontal: 'center',
  },
  loadedPhases: [],
  selectedMember: null,
  selectedCoach: null,
  userInsights: {
    memberSince: '',
    phaseDate: '',
    level: '',
    daysOnLevel: 0,
    initialWeight: -1,
    initialWeightDate: '',
    currentWeight: 0,
    bmIcurrent: '',
    bmIinitial: '',
    bmiChange: '',
    weightLossChange: 0,
    weight: [],
    food: [],
    water: [],
    sleep: [],
    activity: [],
    steps: [],
    bowelMovement: [],
    stress: [],
    bloodGlucose: [],

    threeDayWordCloudFoodTags: [],
    yesterDayWordCloudFoodTags: [],
    yesterFoodImagePath: [],

    insights: undefined,
  },
  achievements: [],
  measurementUnit: null,
  appointments: [],
  scheduledAppointments: [],
  memberAppointments: [],
  notifications: [],
  notesPagination: {
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    userNotesPaginatedList: [],
  },
  isLoading: false,
  participantIndex: 0,
  selectedConversationId: null,
  historyLogs: [],
  isMobileDownload: true,
};
const homepageReducer = createReducer(initialState, {
  //   [homepageActionTypes.LOAD_CONVERSATIONS](state: IConversationState, action: ILoadConversations) {
  //     return {
  //       ...state,
  //       conversations: [...state.conversations, action.payload],
  //     };
  //   },
  [homepageActionTypes.SELECT_CONVERSATION](
    state: IConversationState,
    action: ISelectConversation,
  ) {
    return {
      ...state,
      selectedConversation: action.payload,
    };
  },
  [homepageActionTypes.REMOVE_CONVERSATION](state: IConversationState, action: ILoadConversations) {
    return {
      ...state,
      conversations: [
        ...state.conversations.filter(
          (oneConversation) => oneConversation.conversationId !== action.payload?.conversationId,
        ),
      ],
    };
  },
  [homepageActionTypes.CHANGE_STATE_SNACKBAR](
    state: IConversationState,
    action: IChangeStateSnackbar,
  ) {
    return {
      ...state,
      snackbarState: {
        ...action.payload,
      },
    };
  },

  [homepageActionTypes.LOAD_USER_PHASES](state: IConversationState, action: ILoadPhases) {
    return {
      ...state,
      loadedPhases: action.payload,
    };
  },
  [homepageActionTypes.SELECT_MEMBER](state: IConversationState, action: ISelectMember) {
    return {
      ...state,
      selectedMember: action.payload,
    };
  },
  [homepageActionTypes.SELECT_COACH](state: IConversationState, action: ISelectCoach) {
    return {
      ...state,
      selectedCoach: action.payload,
    };
  },
  [homepageActionTypes.SELECT_MEASUREMENT_UNIT](
    state: IConversationState,
    action: ISelectMeasurementUnit,
  ) {
    return {
      ...state,
      measurementUnit: action.payload,
    };
  },

  [homepageActionTypes.LOAD_USER_INSIGHTS](state: IConversationState, action: ILoadUserInsights) {
    return {
      ...state,
      userInsights: action.payload,
    };
  },

  [homepageActionTypes.LOAD_ACHIEVEMENTS](state: IConversationState, action: ILoadAchievements) {
    return {
      ...state,
      achievements: action.payload,
    };
  },

  [homepageActionTypes.LOAD_USER_APPOINTMENTS](
    state: IConversationState,
    action: ILoadUserAppointments,
  ) {
    return {
      ...state,
      appointments: action.payload.loadedAppointments,
      scheduledAppointments: action.payload.scheduledAppointments,
    };
  },
  [homepageActionTypes.LOAD_MEMBER_APPOINTMENTS](
    state: IConversationState,
    action: ILoadMemberAppointments,
  ) {
    return {
      ...state,
      memberAppointments: action.payload,
    };
  },
  [homepageActionTypes.LOAD_NOTIFICATIONS](state: IConversationState, action: ILoadNotifications) {
    return {
      ...state,
      notifications: action.payload,
    };
  },

  [homepageActionTypes.LOAD_NOTES_PAGINATION](
    state: IConversationState,
    action: ILoadNotesPagination,
  ) {
    return {
      ...state,
      notesPagination: action.payload,
    };
  },

  [homepageActionTypes.LOAD_BACKEND_CONVERSATION](
    state: IConversationState,
    action: ILoadBackendConversations,
  ) {
    return {
      ...state,
      backendConversations: action.payload,
    };
  },

  [homepageActionTypes.LOAD_ALL_SUPERCOACH_CONVERSATIONS](
    state: IConversationState,
    action: ILoadAllSuperCoachConversations,
  ) {
    return {
      ...state,
      allSuperCoachConversations: action.payload,
    };
  },

  [homepageActionTypes.LOAD_COACH_CONVERSATIONS](
    state: IConversationState,
    action: ILoadCoachConversations,
  ) {
    return {
      ...state,
      coachConversations: action.payload,
    };
  },

  [homepageActionTypes.LOADING](state: IConversationState, action: ILoading) {
    return {
      ...state,
      isLoading: action.payload,
    };
  },

  [homepageActionTypes.PARTICIPANT_INDEX](state: IConversationState, action: IParticipantIndex) {
    return {
      ...state,
      participantIndex: action.payload,
    };
  },

  [homepageActionTypes.SAVE_SELECTED_CONVERSATION_ID](
    state: IConversationState,
    action: ISaveSelectedConversationId,
  ) {
    return {
      ...state,
      selectedConversationId: action.payload,
    };
  },
  [homepageActionTypes.LOAD_HISTORY_LOGS](state: IConversationState, action: ILoadHistoryLogs) {
    return {
      ...state,
      historyLogs: action.payload,
    };
  },
  [homepageActionTypes.UPDATE_IS_MOBILE_DOWNLOAD](state: IConversationState, action: IIsMobileDownload) {
    return {
      ...state,
      isMobileDownload: action.payload,
    };
  },
});

export default homepageReducer;
