import {Client as ConversationsClient} from '@twilio/conversations';
import {
  getConversationsServer,
  getTokenServer,
  getParticipantForConversation,
} from '../api/chat.api';
import {ITokenDto} from '../dtos/ITokenDto';
import {IConversationDto} from '../dtos/IConversationDto';
import {IUser} from '../models/IUser';
// import store from '../store/store';
// import * as usersActionTypes from '../store/actions/users/users-types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default class TwilioService {
  static conversationsClient: any;
  static token: string;
  static selectedConversation: any;
  static backendConversations: IConversationDto[] = [];

  constructor(token: string) {
    TwilioService.token = token;
  }
 
  static async getInstance() {
    if (!TwilioService.conversationsClient) {
      TwilioService.conversationsClient = await new ConversationsClient(TwilioService.token);
    }

    return TwilioService.conversationsClient;
  }

  static async initTwilioService(): Promise<void> {
    try {
      const conversationsClient = await TwilioService.getInstance();
      conversationsClient.on('tokenAboutToExpire', async () => {
        const response: ITokenDto = await getTokenServer();
        conversationsClient.updateToken(response.token);
      });

      conversationsClient.on('tokenExpired', async () => {
        const response: ITokenDto = await getTokenServer();
        conversationsClient.updateToken(response.token);
      });
      conversationsClient.on('connectionStateChanged', (state: any) => {
        if (state !== 'connecting' || state !== 'connected') {
          TwilioService.getInstance();
        } else if (state === 'denied') {
          TwilioService.conversationsClient?.shutdown();
          TwilioService.conversationsClient = null;
          TwilioService.getInstance();
        }
      });
    } catch {
      const response: ITokenDto = await getTokenServer('apiCall');
      TwilioService.token = response.token;
      await TwilioService.getInstance();
    }
  }

  static async addNewConversation(
    conversationId: string,
    callback: (conversation: any) => void,
    currentUser: any,
  ): Promise<void> {
    if (!conversationId) {
      return;
    }
    try {
      const conversationsClient = await TwilioService.getInstance();

      if (TwilioService.backendConversations?.length === 0) {
        TwilioService.backendConversations = await getConversationsServer();
      }

      const allParticipants = await getParticipantForConversation(conversationId);
      const isInConversation = allParticipants.find(
        (participant) => participant.id === currentUser.id,
      );
      if (!isInConversation) {
        TwilioService.selectedConversation = null;
        callback(null);
      } else {
        const conversation = await conversationsClient.getConversationBySid(conversationId);
        TwilioService.selectedConversation = conversation;
        callback(conversation);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.error(error);
      // store.dispatch({
      //   type: usersActionTypes.STATUS_ERROR,
      //   payload: {isError: true, data: 'statusCaughtError'},
      // });
      // throw error;
    }
  }

  static async selectConversationForUserTrainer(
    user: IUser,
    callback: (conversation: any) => void,
  ): Promise<void> {
    if (TwilioService.backendConversations?.length === 0) {
      const newConversations = await getConversationsServer(false, user.id);
      if (newConversations?.length > 0) {
        TwilioService.backendConversations = await getConversationsServer(false, user.id);
      } else {
        const conversationsClient = await TwilioService.getInstance();
        const conversation = await conversationsClient.getConversationBySid(
          user?.conversationId || '',
        );
        TwilioService.selectedConversation = conversation;
        callback(conversation);
      }
    }
    const backendConversations: IConversationDto[] = TwilioService.backendConversations;
    const foundConversation = backendConversations.find(
      (oneConversation) => oneConversation.conversationName === user.id,
    );
    if (foundConversation?.conversationId) {
      const conversationsClient = await TwilioService.getInstance();
      const conversation = await conversationsClient.getConversationBySid(
        foundConversation?.conversationId || '',
      );
      TwilioService.selectedConversation = conversation;
      callback(conversation);
    } else {
      if (user?.trainer) {
        const conversationsClient = await TwilioService.getInstance();
        const conversation = await conversationsClient.getConversationBySid(
          user?.conversationId || '',
        );
        TwilioService.selectedConversation = conversation;
        callback(conversation);
      } else if (user.trainer == null) {
        const conversationsClient = await TwilioService.getInstance();
        const conversation = await conversationsClient.getConversationBySid(user?.conversationId);
        TwilioService.selectedConversation = conversation;
        callback(conversation);
      } else {
        TwilioService.selectedConversation = null;
        callback(null);
      }
    }
  }

  static async removeConversation(callback: any): Promise<void> {
    const conversationsClient = await TwilioService.getInstance();
    conversationsClient.on('conversationLeft', (thisConversation: any) => {
      //dispatch(homepageActions.removeConversation(thisConversation));
      callback(thisConversation);
    });
  }

  static removeListeners(): void {
    TwilioService.selectedConversation?.removeAllListeners();
  }

  static shutDown(): void {
    TwilioService.conversationsClient?.shutdown();
    TwilioService.conversationsClient = null;
  }
}
