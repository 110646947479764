import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import {TextField} from '../../text-field/TextField';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import BloodPressureIcon from '../../../../assets/icons/side-menu-bar-logging/blood-pressure-icon.svg';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
//import {selectBloodPressureLogs} from '../../../store/selectors/loggingPageSelector';
import {
  addBloodPressureLog,
  editBloodPressureLog,
} from '../../../../store/actions/logging-page/logging-page-action';
import {ValidationType} from '../../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../../validation/Validation';
import {isDecimalNumber} from '../../../../validation/DataValidation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {FormType, WidgetType} from '../../../../utils/enums';
import {DATE_PICKER_DATE_FORMAT, MAX_INT_VALUE} from '../../../../utils/constants';

import useStyles from './blood-pressure-form.styles';
import useCommonStyles from '../common-for-widgets.styles';

interface IBloodPressureFormData {
  date: string;
  time: string;
  systolicValue: string;
  diastolicValue: string;
}
interface IBloodPressureFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  isMobile: boolean;
}

const validationRules = {
  diastolicValue: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_INT_NUMBER,
      minValue: 0,
      maxValue: 200,
      errorMessage: `${i18n.t('ErrorMessages.notPositiveIntValue')} 199`,
    },
  ],
  systolicValue: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_INT_NUMBER,
      minValue: 0,
      maxValue: MAX_INT_VALUE + 1,
      errorMessage: `${i18n.t('ErrorMessages.notPositiveIntValue')} ${MAX_INT_VALUE}`,
    },
    {
      type: ValidationType.IS_GREATER,
      value: 'diastolicValue',
      errorMessage: i18n.t('ErrorMessages.notGreaterThan'),
    },
  ],
  date: [{type: ValidationType.REQUIRED}],
  time: [{type: ValidationType.REQUIRED_DURATION}],
};

const BloodPressureForm: React.FC<IBloodPressureFormProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  //const bloodPressureLogs = useSelector(selectBloodPressureLogs);
  const [data, setData] = useState<IBloodPressureFormData>({
    date: moment().toString(),
    time: moment().toString(),
    systolicValue: '',
    diastolicValue: '',
  });

  const formType = props.formType || FormType.ADDING_MODE;

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const handleDiastolicBlur = () => {
    if (data.diastolicValue === '' || !isDecimalNumber(data.diastolicValue, 0, 200, '1')) {
      validateFormField('diastolicValue');
    } else {
      if (parseFloat(data.systolicValue) <= parseFloat(data.diastolicValue)) {
        setErrors({
          ...errors,
          systolicValue: [{message: 'Systolic must be greater than diastolic'}],
          diastolicValue: [],
        });
      } else {
        setErrors({
          ...errors,
          systolicValue: [],
          diastolicValue: [],
        });
      }
    }
  };
  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    let initialObject: IBloodPressureFormData = {
      date: moment().toString(),
      time: moment().toString(),
      systolicValue: '',
      diastolicValue: '',
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        systolicValue: props.data?.systolicValue.toString() || '',
        diastolicValue: props.data?.diastolicValue.toString() || '',
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
      };
    }

    setData(initialObject);
  }, [props.data]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    setData({
      date: moment().toString(),
      time: moment().toString(),
      systolicValue: '',
      diastolicValue: '',
    });
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const bloodPressureLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        systolicValue: parseInt(data.systolicValue),
        diastolicValue: parseInt(data.diastolicValue),
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addBloodPressureLog(bloodPressureLog));
      } else {
        res = await dispatch(editBloodPressureLog(bloodPressureLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img
            className={commonClasses.imageMargin}
            src={BloodPressureIcon}
            alt={'blood-pressure-icon'}
          />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.bloodPressureWidget.formTitleEdit')
              : t('LogsPage.bloodPressureWidget.formTitle')}
          </Typography>
        </div>
        {/* 
        <Button
          id="close-button"
          variant="text"
          onClick={() => {
            props.changeState(WidgetType.NONE_MODE, FormType.NONE_MODE);
          }}
          disabled={bloodPressureLogs?.length === 0}
        >
          {t('LogsPage.bloodPressureWidget.closeButton')}
        </Button> */}
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.bloodPressureWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.bloodPressureWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <div className={commonClasses.centerContainer}>
        <TextField
          name={'systolicValue'}
          containerClass={commonClasses.normalTextField}
          required={true}
          onBlur={() => validateFormField('systolicValue')}
          value={data.systolicValue}
          label={t('LogsPage.bloodPressureWidget.systolicLabel')}
          placeholder={t('LogsPage.bloodPressureWidget.systolicPlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(errors, 'systolicValue')}
          helperText={getError(errors, 'systolicValue')}
        />
        <TextField
          name={'diastolicValue'}
          containerClass={classes.wideTextField}
          onBlur={handleDiastolicBlur}
          value={data.diastolicValue}
          required={true}
          placeholder={t('LogsPage.bloodPressureWidget.diastolicPlaceholder')}
          label={t('LogsPage.bloodPressureWidget.diastolicLabel')}
          onChange={(event) => handleChange(event)}
          error={hasError(errors, 'diastolicValue')}
          helperText={getError(errors, 'diastolicValue')}
        />
      </div>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-blood-pressure-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-steps-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.bloodPressureWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default BloodPressureForm;
