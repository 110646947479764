import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    height: '100%',
  },
  descriptiveText: {
    marginTop: 24,
    marginBottom: 16,
  },
  normalTextField: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 170,
      marginRight: 24,
    },

    '& .MuiFormControl-root': {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 170,
      },
    },
  },
  emailTextField: {
    [theme.breakpoints.up('md')]: {
      width: 364,
      marginRight: 24,
    },
  },
  passwordTextField: {
    [theme.breakpoints.up('md')]: {
      width: 240,
    },
  },
  textFieldContainerMargin: {
    marginBottom: 16,
    marginRight: 0,
  },
  dateOfBirthWidth: {
    width: '100%',
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      width: '170px !important',
      marginRight: 24,
    },
  },
  maskedPickerWidth: {
    width: '100%',
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      width: 160,
      marginRight: 24,
    },
  },
  oneRowDesktopContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
    },
  },
  bottomButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: 'auto',
    },
    marginTop: 'auto',
  },
}));

export default useStyles;
