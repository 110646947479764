import React from 'react';


/*eslint-disable @typescript-eslint/no-empty-function*/
const IntercomPage: React.FC = () => {
  
  return <></>;
};

IntercomPage.displayName = 'Intercom Page';
export default IntercomPage;
