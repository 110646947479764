import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  levelFlag: {
    position: 'absolute',
    padding: '4px 8px',
    borderRadius: (props) => (props.smallScreens ? '4px' : '0px 12px 0px 12px'),
    textAlign: 'center',
    color: theme.palette.blacksAndWhites.smokyBlack,
    border: `1px solid ${theme.palette.blacksAndWhites.smokyBlack}`,
  },
  levelOneStyle: {
    backgroundColor: '#9DC6F5',
  },
  levelTwoStyle: {
    backgroundColor: '#D2DAFA',
  },
  levelThreeStyle: {
    backgroundColor: '#F9CDC7',
  },
  levelDefaultStyle: {
    backgroundColor: '#BCEBEB',
  },
}));

export default useStyles;
