import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiTableContainer-root': {
      overflowX: 'hidden',
    },

    '& table': {
      position: 'relative',
    },

    '& thead tr th': {},
    '& tbody tr th': {
      fontSize: 14,
      fontWeight: 'bold',
      width: 168,
    },
    '& tbody tr td': {
      width: 106,
    },
    '& tbody tr': {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.blacksAndGrays.cultured,
      },
    },

    '& .MuiTableCell-root ': {
      borderBottom: 'none',
    },

    '& tbody .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableWithCarousel: {
    position: 'relative',
    background: theme.palette.background.paper,
    border: '2px solid transparent',
    borderRadius: '12px',
    boxShadow: '0px 2px 2px rgb(0 0 0 / 12%)',
    marginTop: '20px',
    marginBottom: '32px',
  },
  headerColumn: {
    padding: '8px 16px',
  },
  firstColumn: {
    display: 'grid',
    height: '100%',
    '& th': {
      width: '78px',
      height: '52px',
      border: 'none',
      fontSize: '12px',
      lineHeight: '16px',
      padding: '18px 16px',
    },
    '& th:nth-child(even)': {
      background: theme.palette.blacksAndWhites.cultured,
    },
  },
  secondColumn: {
    width: 'calc(100% - 80px)',
    position: 'absolute',
    top: 0,
    right: 0,
    '& .react-multi-carousel-dot-list': {
      top: 'calc(100% + 4px)',
      left: '-75px',
    },
  },
  othersColumn: {
    display: 'grid',
    height: '100%',
    '& tr': {
      width: '100%',
      height: '52px',
      borderLeft: '1px solid  rgba(224, 224, 224, 1)',
    },
    '& tr:nth-child(even)': {
      background: theme.palette.blacksAndWhites.cultured,
    },
    '& td': {
      width: '100%',
      height: '52px',
      borderBottom: 'none',
    },
    '& .MuiTableCell-root': {
      padding: '13px',
    },
  },
  buttonPlusFixation: {
    '& .MuiButton-label': {
      marginBottom: 3,
    },
  },
  plusButton: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '50%',
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  ripple: {
    backgroundPosition: 'center',
    transition: 'background 0.8s',

    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      backgroundColor: 'rgba(52, 175, 175, 0.2)',
      backgroundSize: '100%',
      transition: 'background 0s',
    },
  },
  noDataText: {
    color: theme.palette.cta.sunsetOrange,
  },
  centerContentCell: {
    display: 'flex',
    justifyContent: 'center',

    '& img': {
      marginRight: 0,
    },
  },
  plusValue: {
    cursor: 'pointer',
    '& > :first-child': {
      display: 'flex',
    },
    '& > :last-child': {
      display: 'none ',
    },
    '&:hover': {
      '& > :first-child': {
        display: 'none',
      },
      '& > :last-child': {
        display: 'flex',
      },
    },
  },
  customDotList: {
    top: '100%',
    '& .react-multi-carousel-dot button': {
      border: 'none',
      background: theme.palette.blacksAndGrays.silverSand,
      width: 8,
      height: 8,
      margin: 6,
    },
    '& .react-multi-carousel-dot--active button': {
      border: 'none',
      background: theme.palette.blacksAndGrays.dimGray,
    },
  },
}));

export default useStyles;
