import {makeStyles,Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: {
    isMobile: boolean,
    isTablet: boolean
  },
  smallSize: boolean
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  searchContainer: {
    padding: '0 !important'
  },
  phaseSelectContContainer: {
    display: (props) => props.isMobileOrTablet.isMobile ? 'block' : props.isMobileOrTablet.isTablet ? 'flex' : 'block',
    '& > div': {
      width: '100%'
    },
    '& > div:nth-child(1)': {
      marginRight: (props) => props.isMobileOrTablet.isMobile ? 0 : props.isMobileOrTablet.isTablet ? 12 : 0,
    },
    '& > div:nth-child(2)': {
      marginLeft: (props) => props.isMobileOrTablet.isMobile ? 0 : props.isMobileOrTablet.isTablet ? 12 : 0,
    },
  },
  phaseSelectCont: {
    marginBottom: 16,
    '& .MuiTypography-root.MuiTypography-caption': {
      // color: (props) => props.smallSize ? theme.palette.background.paper : theme.palette.blacksAndGrays.stoneGray,
      color: (props) => props.smallSize ? "#000000": theme.palette.blacksAndGrays.stoneGray,
    }
  },
  phaseSelect: {
    // backgroundColor: (props) => props.smallSize ? theme.palette.tintsAndShades.oxfordBlueDark : theme.palette.background.paper,
    backgroundColor: (props) => props.smallSize ? "#d6d6d6": theme.palette.background.paper,
    width: (props) => props.smallSize ? '100%' : 282,
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiTypography-root': {
      color: (props) => props.smallSize ? theme.palette.tintsAndShades.blueGrey : theme.palette.blacksAndGrays.stoneGray,
    },
    '& .MuiOutlinedInput-root': {
      color: (props) => props.smallSize ? theme.palette.background.paper : theme.palette.blacksAndWhites.smokyBlack,
    },
    '& > div': {
      backgroundColor: (props) => props.smallSize ? theme.palette.tintsAndShades.oxfordBlueDark : theme.palette.background.paper,
    }
  },
  dateContainer: {
    '& .MuiOutlinedInput-adornedEnd': {
      // backgroundColor: (props) => props.smallSize ? theme.palette.tintsAndShades.oxfordBlueDark : `${theme.palette.background.paper} !important`,
      backgroundColor: (props) => props.smallSize ? "#d6d6d6" : `${theme.palette.background.paper} !important`,
      border: (props) => props.smallSize ? '1px solid black !important' : '',
      '& ::placeholder': {
        // color: (props) => props.smallSize ? theme.palette.tintsAndShades.blueGrey : theme.palette.blacksAndGrays.stoneGray,
        color: (props) => props.smallSize ? "#000000" : theme.palette.blacksAndGrays.stoneGray,
      },
      '& .MuiButtonBase-root.MuiIconButton-root': {
        marginTop: (props) => props.smallSize ? '4px' : '10px',
        marginRight: (props) => props.smallSize ? 0 : '4px',
        
        '& svg': {
          // fill: (props: { smallSize: any; }) => props.smallSize ? theme.palette.tintsAndShades.blueGrey : 'currentColor'
          fill: (props: { smallSize: any; }) => props.smallSize ? "#000000" : 'currentColor'
        }
      }
    },
    width: (props) => props.smallSize ? '100% !important' : '282px !important',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) => props.isMobileOrTablet.isTablet ? 'end' :'space-evenly',
    marginBottom: 24,
    marginTop: 16,
    width: (props) => props.smallSize ? '100%' : 'auto',
   
  },
  clearBtn: {
    border: `1.5px solid ${theme.palette.blacksAndGrays.stoneGray} !important`,
    height: (props) => props.smallSize ? '48px !important' : '36px !important',
    width: (props) => props.isMobileOrTablet.isMobile ? '100%' : props.isMobileOrTablet.isTablet ? 152 : 133,
    color: `${theme.palette.blacksAndGrays.stoneGray} !important`,
    padding: '0px 16px',
    marginRight: (props) => props.smallSize ? 5 : 0,
    '&:hover': {
      color: `${theme.palette.blacksAndGrays.stoneGray} !important`,
      borderColor: `${theme.palette.blacksAndGrays.stoneGray} !important`,
    },
  },
  filterBtn: {
    width: (props) => props.isMobileOrTablet.isMobile ? '100%' : props.isMobileOrTablet.isTablet ? 152 :'auto',
    marginLeft: (props) => props.smallSize ? 5 : 0,
    boxShadow: (props) => props.smallSize  
    ? '0px 3px 1px rgba(100, 101, 102, 0.2), 0px 2px 2px rgba(100, 101, 102, 0.14), 0px 1px 5px rgba(100, 101, 102, 0.12)'
    : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    // backgroundColor: (props) => props.smallSize ? `${theme.palette.additionalColors.bdazzledBlue} !important` : theme.palette.primary.main,
    backgroundColor: (props) => props.smallSize ? `#000000 !important` : theme.palette.primary.main,
    // color: (props) => props.smallSize ? `${theme.palette.additionalColors.shadowBlue}` : theme.palette.background.paper,
    color: (props) => props.smallSize ? `#ffffff` : theme.palette.background.paper,
    '&:hover': {
      color: (props) => props.smallSize ? theme.palette.tintsAndShades.blueGrey : '',
      backgroundColor: (props) => props.smallSize ? theme.palette.tintsAndShades.babyBlueEyes : '',
    }
  },
  maxWidthTags: {
    maxWidth: (props) => props.smallSize ? '100%' : 280,
  },
}));
export default useStyles;
