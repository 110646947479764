import * as usersActionTypes from '../actions/users/users-types';
import {createReducer} from '../../utils/helpers';
import {
  IClearPaginationUsers,
  ILogin,
  ILogout,
  ISaveSkinTone,
  IStorePaginationUsers,
  IStoreUsers,
  ISelectUser,
  IStoreSupercoachFilteredUsers,
  IStoreCoachMembers,
  IStoreSecondaryUsers,
  IUpdateUnread,
  IStatusError,
} from '../actions/users/users-action';
import {IUser} from '../../models/IUser';
import {IUserPaginationResult} from '../../models/IUserPaginationResult';
import {EmojiSkin} from 'emoji-mart';

export interface IUsersState {
  currentUser: IUser | null;
  skinTone: EmojiSkin;
  users: any[];
  secondaryUsers: any[];
  supercoachFilteredUsers: any[];
  coachUsers: any[];
  paginatedUsers: IUserPaginationResult;
  selectedUserSuperCoach: IUser | null;
  statusErrorData: any[] | null;
}

const initialState: IUsersState = {
  currentUser: null,
  skinTone: 1,
  users: [],
  secondaryUsers: [],
  supercoachFilteredUsers: [],
  coachUsers: [],
  paginatedUsers: {
    usersPaginatedList: [],
    pageNumber: 0,
    totalPages: 0,
    pagesize: 0,
    totalCount: 0,
  },
  selectedUserSuperCoach: null,
  statusErrorData: null,
};

const usersReducer = createReducer(initialState, {
  [usersActionTypes.LOGIN](state: IUsersState, action: ILogin) {
    return {
      ...state,
      currentUser: action.payload,
      skinTone: action.payload?.skinTone,
    };
  },

  [usersActionTypes.LOGOUT](state: IUsersState, action: ILogout) {
    return {
      ...state,
      currentUser: action.payload,
      skinTone: 1,
    };
  },
  [usersActionTypes.SAVE_SKIN_TONE](state: IUsersState, action: ISaveSkinTone) {
    return {
      ...state,
      skinTone: action.payload,
    };
  },
  [usersActionTypes.STATUS_ERROR](state: IUsersState, action: IStatusError ) {
    return {
      ...state,
      statusErrorData: action.payload,
    };
  },
  [usersActionTypes.STORE_USERS](state: IUsersState, action: IStoreUsers) {
    return {
      ...state,
      users: action.payload,
    };
  },
  [usersActionTypes.STORE_SECONDARY_USERS](state: IUsersState, action: IStoreSecondaryUsers) {
    return {
      ...state,
      secondaryUsers: action.payload,
    };
  },
  [usersActionTypes.STORE_COACH_MEMBERS](state: IUsersState, action: IStoreCoachMembers) {
    return {
      ...state,
      coachUsers: action.payload,
    };
  },
  [usersActionTypes.STORE_SUPERCOACH_FILTERED_USERS](
    state: IUsersState,
    action: IStoreSupercoachFilteredUsers,
  ) {
    return {
      ...state,
      supercoachFilteredUsers: action.payload,
    };
  },
  [usersActionTypes.STORE_PAGINATION_USERS](state: IUsersState, action: IStorePaginationUsers) {
    return {
      ...state,
      paginatedUsers: action.payload,
    };
  },
  [usersActionTypes.CLEAR_PAGINATION_USERS](state: IUsersState, action: IClearPaginationUsers) {
    return {
      ...state,
      paginatedUsers: action.payload,
    };
  },
  [usersActionTypes.SELECT_USER](state: IUsersState, action: ISelectUser) {
    return {
      ...state,
      selectedUserSuperCoach: action.payload,
    };
  },
  [usersActionTypes.UPDATE_UNREAD_STATE](state: IUsersState, action: IUpdateUnread) {
    const getIndex = state.users.findIndex((user) => user.id === action.payload)
    const cloneUsers = [...state.users]
    if(getIndex !== -1) cloneUsers[getIndex].unReadCount = "0";
    return {
      ...state,
      users: cloneUsers
    };
  },
});

export default usersReducer;
