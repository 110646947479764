import {IChartOptions} from '../../../models/IChart';

interface IProgressOptions {
  bodyWeight: IChartOptions;
  food: IChartOptions;
  water: IChartOptions;
  sleep: IChartOptions;
  activity: IChartOptions;
  steps: IChartOptions;
  mentalHealth: IChartOptions;
  bowelMovement: IChartOptions;
  bloodGlucose: IChartOptions;
}

const width = '100%';
const height = 350;
const heightMobile = 250;
const margin = {
  top: 10,
  left: -20,
  right: 20,
};
const marginMobile = {
  top: 10,
  left: -20,
  right: 20,
};
const salmon = '#F07A6A';
const lightViolet = '#D2DAFA';
const darkViolet = '#5165B8';
const turquoise = '#80DBDB';
const midBlue = '#4986CC';
const darkTurquoise = '#34AFAF';
const orange = '#FAC261';

export const progressOptions: IProgressOptions = {
  bodyWeight: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: {
      top: 10,
      left: 0,
      right: 20,
    },
    marginMobile: marginMobile,
    color: salmon,
    unit: '',
    rangeY: [
      (dataMin: number): number => Math.round((dataMin ? dataMin * 0.8 : 0) * 100) / 100,
      (dataMax: number): number => Math.round((dataMax ? dataMax * 1.2 : 0) * 100) / 100,
    ],
  },
  food: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: {
      top: 10,
      left: 0,
      right: 20,
    },
    marginMobile: marginMobile,
    color: salmon,
    unit: '',
    rangeY: [0, 2400],
  },
  water: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: margin,
    marginMobile: marginMobile,
    color: lightViolet,
    unit: 'Oz',
    rangeY: [0, (dataMax: number): number => (dataMax ? Math.round(dataMax * 1.2) : 0)],
  },
  sleep: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: {
      top: 10,
      left: 0,
      right: 20,
    },
    marginMobile: marginMobile,
    color: darkViolet,
    unit: '',
    rangeY: [0, 2400],
  },
  activity: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: margin,
    marginMobile: marginMobile,
    color: turquoise,
    unit: 'min',
    rangeY: [0, 'dataMax + 10'],
  },
  steps: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: {
      top: 10,
      left: 0,
      right: 20,
    },
    marginMobile: marginMobile,
    color: orange,
    unit: '',
    rangeY: [0, 'dataMax + 100'],
  },
  mentalHealth: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: {
      top: 10,
      left: 0,
      right: 20,
    },
    marginMobile: marginMobile,
    color: midBlue,
    unit: '',
    rangeY: [0, 4],
  },
  bowelMovement: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: {
      top: 10,
      left: 0,
      right: 10,
    },
    marginMobile: marginMobile,
    color: darkTurquoise,
    unit: '',
    rangeY: [0, 2400],
  },
  bloodGlucose: {
    width: width,
    height: height,
    heightMobile: heightMobile,
    margin: margin,
    marginMobile: marginMobile,
    color: salmon,
    unit: '',
    rangeY: [0, 'dataMax + 10'],
  },
};
