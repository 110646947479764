import * as React from 'react';
import useStyles from './meal-plan-daily-meal-styles';
import {Typography} from '@material-ui/core';
import {
  IMealPlanDailyMealDto,
  IMealPlanComponentDetails,
} from '../../../dtos/IMealPlanComponentDetails';
import ReactMarkdown from 'react-markdown';

interface Props {
  dailyMeal: IMealPlanComponentDetails;
}

export const MealPlanDailyMeal: React.FC<Props> = ({dailyMeal}) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.cardHeader}>
        <Typography variant="subtitle1">{dailyMeal?.dayOfTheWeek}</Typography>
      </div>
      <div className={classes.cardContent}>
        {dailyMeal?.meals?.map((oneMeal: IMealPlanDailyMealDto) => (
          <div key={oneMeal.id} className={classes.oneRowContainer}>
            <Typography
              className={classes.labelDetails}
              variant="body2"
            >{`${oneMeal.name}:`}</Typography>
            <ReactMarkdown
              components={{
                p: ({children}) => <Typography variant="body2">{children}</Typography>,
              }}
            >
              {oneMeal.details}
            </ReactMarkdown>
          </div>
        ))}
      </div>
    </div>
  );
};
