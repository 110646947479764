import React from 'react';
import {Typography} from '@material-ui/core';
import {Button} from '../../../common/components/button/Button';
import {handleMiniOrangeLogOut} from '../../../api/users.api';
import {useTranslation} from 'react-i18next';
import useStyles from './unsuccessful-login-page-styles';

const UnsuccessfulLoginPage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button
        id={'unsuccessful-login-btn'}
        onClick={() => {
          handleMiniOrangeLogOut();
        }}
        className={classes.tryAgainBtn}
      >
        {t('UnsuccessfulLoginPage.buttonText')}
      </Button>
      <Typography>{t('UnsuccessfulLoginPage.contactSupport')}</Typography>
    </div>
  );
};

UnsuccessfulLoginPage.displayName = 'UnsuccessfulLoginPage';
export default UnsuccessfulLoginPage;
