import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  valuesContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingTop: (props) => (props.isMobile ? 12 : 0),
  },
  valuesText: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginBottom: 24,
    marginRight: 25,
  },
  marginBottom: {
    marginBottom: 8,
  },
}));

export default useStyles;
