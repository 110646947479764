import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wideTextField: {
    width: 190,
  },
  stepsLabel: {
    marginBottom: 8,
  },
}));

export default useStyles;
