import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}
const useStyles = makeStyles<Theme, Props>((theme) => ({
  centralPartContainer: {
    display: 'flex',
    marginTop: '24px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '18px',
  },
  firstRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
  },
  secondRowContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    '& > div:nth-child(odd)': {
      paddingRight: (props) => (props.isMobile ? 8 : 0),
    },
    '& > div:nth-child(even)': {
      paddingLeft: (props) => (props.isMobile ? 8 : 0),
    },
  },
  centralContainerRight: {
    flexDirection: 'column',
    marginLeft: '24px',
  },
  tagsPart: {
    marginTop: (props) => (props.isMobile ? '20px' : '32px'),
  },
  categoryTagContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerCategory: {
    marginTop: 8,
  },
  boxTags: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.blacksAndGrays.gainsboro}`,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: 8,
    paddingTop: 0,
    minHeight: 40,
  },
  ingredientsDescription: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    marginBottom: 12,
  },
  levelDiv: {
    borderRadius: 4,
    width: 16,
    height: 16,
    marginRight: 8,
  },
  levelOne: {
    background: theme.palette.tagColors.levelOne,
  },
  levelTwo: {
    background: theme.palette.tagColors.levelTwo,
    marginLeft: 8,
  },
  levelThree: {
    background: theme.palette.tagColors.babyPink,
    marginLeft: 8,
  },
  levelTxt: {
    color: theme.palette.text.primary,
  },
  greyCaption: {
    color: theme.palette.blacksAndGrays.davysGray,
  },
  sourceSelect: {
    width: (props) => (props.isMobile ? '100%' : 215),
    '& > div': {
      width: '100%',
    },
  },
  typeSelect: {
    width: (props) => (props.isMobile ? '100%' : 177),
    marginRight: (props) => (props.isMobile ? 0 : 24),
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      marginRight: (props) => (props.isMobile ? 0 : 24),
    },
  },
  checkboxFixTop: {
    marginTop: (props) => (props.isMobile ? 12 : 8),
    marginBottom: (props) => (props.isMobile ? 12 : 0),
    '& .MuiFormControlLabel-root': {
      color: theme.palette.blacksAndGrays.stoneGray,
    },
  },
  imageUpload: {
    marginRight: (props) => (props.isMobile ? '16px' : '0'),
    borderRadius: '12px',
    '& > img': {
      width: (props) => (props.isMobile ? '90%' : '164px'),
      height: (props) => (props.isMobile ? 'auto' : '164px'),
    },
  },
  searchContainer: {
    marginRight: (props) => (props.isMobile ? 0 : 16),
    '& .MuiInputBase-input': {
      padding: '11px 44px',
    },
  },
  searchInput: {
    minWidth: (props) => (props.isMobile ? '100%' : 375),
    marginTop: 0,
  },
}));

export default useStyles;
