import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  mainContainer: {
    margin: '20px 0',
  },
  contentList: {
    margin: (props) => (props.isMobileOrTablet.isTabletBig ? '16px -12px' : '16px 0px'),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    cursor: 'pointer',
    overflow: 'auto',
    alignContent: 'flex-start',
    paddingRight: (props) => (props.smallScreens ? 0 : '24px'),
    '& > div:nth-child(odd)': {
      marginRight: '12px',
    },
    '& > div:nth-child(even)': {
      marginLeft: '12px',
    },
  },
  card: {
    width: 'calc(50% - 12px)',
    maxWidth: '100%',
    height: (props) => (props.isMobileOrTablet.isMobile ? 158 : 216),
    '& > img': {
      width: (props) => (props.isMobileOrTablet.isMobile ? 93 : 184),
      height: (props) => (props.isMobileOrTablet.isMobile ? 75 : 148),
    },
  },
}));

export default useStyles;
