import React from 'react';
import classNames from 'classnames';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import {
  EMPTY_FIELD_PLACEHOLDER,
  MOBILE_SIZE,
  TABLET_SMALL_SIZE,
  TABLET_BIG_SIZE,
} from '../../../utils/constants';

import useStyles from './quick-user-profile-overview.styles';

interface IDataItem {
  label?: any;
  itemClassName?: string;
  value: any;
  className?: string;
}

const DataItem: React.FC<IDataItem> = (props) => {
  const {label, value} = props;
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });

  return (
    <React.Fragment>
      <div className={classNames(classes.infoItem, props.itemClassName)}>
        <Typography className={classes.dataItem} variant={'caption'}>
          {label}
        </Typography>
        <Typography variant={'body2'}>{value || EMPTY_FIELD_PLACEHOLDER}</Typography>
      </div>
    </React.Fragment>
  );
};

export default DataItem;
