import React, {ReactNode, useRef, useState} from 'react';
import {FormType, LoggingWidgetType, WidgetType} from '../../../utils/enums';
import {useSelector} from 'react-redux';
import {
  selectActivityLogs,
  selectBloodPressureLogs,
  selectBodyMeasurementLogs,
  selectBowelLogs,
  selectDrinkLogs,
  selectFoodLogs,
  selectMoodTags,
  selectSleepLogs,
  selectStepsLogs,
  selectStressLogs,
  selectStressTags,
  selectWeightLogs,
  selectBloodGlucoseLogs,
  selectCholesterolLogs,
  selectA1CLogs,
  selectBodyPainLogs,
} from '../../../store/selectors/loggingPageSelector';
import FoodForm from './food/FoodForm';
import ActivityForm from './activity/ActivityForm';
import StressForm from './stress/StressForm';
import DrinkForm from './water/DrinkForm';
import SleepForm from './sleep/SleepForm';
import BowelForm from './bowel/BowelForm';
import StepsForm from './steps/StepsForm';
import BloodPressureForm from './blood-pressure-widget/BloodPressureForm';
import BodyMeasurementForm from './body-measurement/BodyMeasurementForm';
import BloodGlucoseForm from './blood-glucose-widget/BloodGlucoseForm';
import BodyPainForm from './body-pain/BodyPainForm';
import {ICasualTag} from '../../../models/ITag';
import {SCROLL_MARGIN} from '../../../utils/constants';
import RecentlyAddedLogs from './RecentlyAddedLogs';
import {IWeightDto} from '../../../dtos/IWeightDto';
import {IFoodDto} from '../../../dtos/IFoodDto';
import {IActivityDto} from '../../../dtos/IActivityDto';
import {IStressDto} from '../../../dtos/IStressDto';
import {IDrinkDto} from '../../../dtos/IDrinkDto';
import {ISleepDto} from '../../../dtos/ISleepDto';
import {IBowelDto} from '../../../dtos/IBowelDto';
import {IBloodPressureDto} from '../../../dtos/IBloodPressureDto';
import {IBloodGlucoseDto} from '../../../dtos/IBloodGlucoseDto';
import {IBodyMeasurementDto} from '../../../dtos/IBodyMeasurementDto';
import {IStepsDto} from '../../../dtos/IStepDto';
import {ICholesterolDto} from '../../../dtos/ICholesterolDto';
import {IA1CDto} from '../../../dtos/IA1CDto';
import {LoggingWidgetDataType} from '../../../types/LoggingWidgetDataType';
import {IBodyPainDto} from '../../../dtos/IBodyPainDto';
import BloodLabsForm from './blood-labs-widget/BloodLabsForm';
import BodyWeightForm from './body-weight/BodyWeightForm';

interface ILoggingWidgetProps {
  loggingWidgetType: LoggingWidgetType;
  isMobile: boolean;
}

const LoggingWidget: React.FC<ILoggingWidgetProps> = ({loggingWidgetType, isMobile}) => {
  const [widgetMode, setWidgetMode] = useState(WidgetType.FORM_MODE);
  const [formType, setFormType] = useState(FormType.NONE_MODE);
  const formRef: any = useRef(null);
  const tableRef: any = useRef(null);
  const [selectedLog, setSelectedLog] = useState<LoggingWidgetDataType | null>(null);

  let logsList: any[];
  let initialMoodTagList: ICasualTag[];
  let initialTagList: ICasualTag[];

  const getBloodLabsLogs = () => {
    const cholesterol = useSelector(selectCholesterolLogs);
    const A1C = useSelector(selectA1CLogs);
    const bloodLabs: any = [];
    cholesterol.forEach((item) => bloodLabs.push(item));
    A1C.forEach((item) => bloodLabs.push(item));
    bloodLabs.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
    return bloodLabs;
  };

  switch (loggingWidgetType) {
    case LoggingWidgetType.BODY_WEIGHT_WIDGET:
      logsList = useSelector(selectWeightLogs);
      break;

    case LoggingWidgetType.FOOD_WIDGET:
      logsList = useSelector(selectFoodLogs);
      break;

    case LoggingWidgetType.ACTIVITY_WIDGET:
      logsList = useSelector(selectActivityLogs);
      break;

    case LoggingWidgetType.MENTAL_HEALTH_WIDGET:
      logsList = useSelector(selectStressLogs);
      initialMoodTagList = useSelector(selectMoodTags);
      initialTagList = useSelector(selectStressTags);
      break;

    case LoggingWidgetType.WATER_WIDGET:
      logsList = useSelector(selectDrinkLogs);
      break;

    case LoggingWidgetType.SLEEP_WIDGET:
      logsList = useSelector(selectSleepLogs);
      break;

    case LoggingWidgetType.BOWEL_WIDGET:
      logsList = useSelector(selectBowelLogs);
      break;

    case LoggingWidgetType.STEPS_WIDGET:
      logsList = useSelector(selectStepsLogs);
      break;

    case LoggingWidgetType.BLOOD_PRESSURE_WIDGET:
      logsList = useSelector(selectBloodPressureLogs);
      break;

    case LoggingWidgetType.BODY_MEASUREMENT_WIDGET:
      logsList = useSelector(selectBodyMeasurementLogs);
      break;

    case LoggingWidgetType.BLOOD_GLUCOSE_WIDGET:
      logsList = useSelector(selectBloodGlucoseLogs);
      break;

    case LoggingWidgetType.BODY_PAIN_WIDGET:
      logsList = useSelector(selectBodyPainLogs);
      break;

    case LoggingWidgetType.BLOOD_LABS_WIDGET:
      logsList = getBloodLabsLogs();
      break;

    default:
      logsList = [];
  }

  const changeSelectedLog = (log: any) => {
    setSelectedLog(log);
  };

  const executeScroll = () => {
    formRef?.current?.scrollIntoView();
  };

  const executeTableScroll = () => {
    tableRef?.current?.scrollIntoView();
  };

  const changeState = (stateType: WidgetType, formTypeValue: FormType) => {
    setFormType(formTypeValue);
    setWidgetMode(stateType);
  };

  const changeStateRecentlyAdded = (stateType: WidgetType, formTypeValue: FormType) => {
    changeState(stateType, formTypeValue);
    executeScroll();
  };

  const renderAppropriateForm = (): ReactNode => {
    switch (loggingWidgetType) {
      case LoggingWidgetType.BODY_WEIGHT_WIDGET:
        return (
          <BodyWeightForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IWeightDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
          // <BodyWeightRHF2
          //   formType={formType}
          //   data={formType === FormType.EDITING_MODE ? (selectedLog as IWeightDto) : null}
          //   changeState={(widgetType, formType) => {
          //     changeState(widgetType, formType);
          //   }}
          // isMobile={true}
          // />
        );

      case LoggingWidgetType.FOOD_WIDGET:
        return (
          <FoodForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IFoodDto) : null}
            changeState={(widgetType, formMode) => {
              changeState(widgetType, formMode);
            }}
            executeScroll={executeScroll}
            executeTableScroll={executeTableScroll}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.ACTIVITY_WIDGET:
        return (
          <ActivityForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IActivityDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.MENTAL_HEALTH_WIDGET:
        return (
          <StressForm
            initialMoodTagList={initialMoodTagList}
            initialTagList={initialTagList}
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IStressDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.WATER_WIDGET:
        return (
          <DrinkForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IDrinkDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.SLEEP_WIDGET:
        return (
          <SleepForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as ISleepDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.BOWEL_WIDGET:
        return (
          <BowelForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IBowelDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.STEPS_WIDGET:
        return (
          <StepsForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IStepsDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.BLOOD_PRESSURE_WIDGET:
        return (
          <BloodPressureForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IBloodPressureDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.BODY_MEASUREMENT_WIDGET:
        return (
          <BodyMeasurementForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IBodyMeasurementDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.BLOOD_GLUCOSE_WIDGET:
        return (
          <BloodGlucoseForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IBloodGlucoseDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.BLOOD_LABS_WIDGET:
        return (
          <BloodLabsForm
            formType={formType}
            data={
              formType === FormType.EDITING_MODE ? (selectedLog as ICholesterolDto | IA1CDto) : null
            }
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      case LoggingWidgetType.BODY_PAIN_WIDGET:
        return (
          <BodyPainForm
            formType={formType}
            data={formType === FormType.EDITING_MODE ? (selectedLog as IBodyPainDto) : null}
            changeState={(widgetType, formType) => {
              changeState(widgetType, formType);
            }}
            isMobile={isMobile}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <React.Fragment>
      <div ref={formRef} style={{scrollMargin: `${SCROLL_MARGIN}px`}}>
        {(widgetMode !== WidgetType.NONE_MODE || logsList?.length === 0) && renderAppropriateForm()}
      </div>

      <div ref={tableRef} style={{scrollMargin: `${SCROLL_MARGIN}px`}}>
        {logsList?.length > 0 && (
          <RecentlyAddedLogs
            loggedItems={logsList}
            changeState={(formTypeValue: FormType) => {
              changeStateRecentlyAdded(WidgetType.FORM_MODE, formTypeValue);
            }}
            selectLog={changeSelectedLog}
            loggingWidgetType={loggingWidgetType}
            isMobile={isMobile}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default LoggingWidget;
