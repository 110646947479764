import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';
import useStyles from './food-lists-view.styles';
import {ICardDto} from '../../../dtos/ICardDto';
import {NourishCard} from '../nourish-card/NourishCard';

interface Props {
  cardLists: ICardDto[];
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const FoodListsView: React.FC<Props> = ({cardLists}) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE - 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);

  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;

  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const [t] = useTranslation();

  const renderCardContent = (array: any[]) =>
    array.map((card: ICardDto) => <NourishCard card={card} cssClass={classes.card}  />);

  return (
    <div id={'scrollableDiv'} className={classes.mainContainer}>
      <>
        {cardLists?.length ? (
          <>
            <Typography variant={'h6'} align="left">
              {t('NourishPage.foodLists.title')}
            </Typography>
            <div className={classes.contentList}>{renderCardContent(cardLists)}</div>
          </>
        ) : (
          <Typography variant="subtitle1">{t('NourishPage.foodListsFilter.noResult')}</Typography>
        )}
      </>
    </div>
  );
};
