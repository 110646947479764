import {FormHelperText, OutlinedInput, Typography} from '@material-ui/core';
import * as React from 'react';
import useStyles from './masked-text-field.styles';
import classNames from 'classnames';
import {MAX_CHARACTERS} from '../../../utils/constants';
import MaskedInput from 'react-input-mask';

interface Props {
  containerClass?: string;
  helperTextClassName?: string;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  className?: string;
  focusClass?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  onFocus?: any;
  isFocused?: boolean;
  label?: string;
  required?: boolean;
  name: string;
  type?: 'number' | 'text' | 'email';
  inputProps?: any;
  formatChars?: any;
  mask: string;
}

export const MaskedTextField: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {containerClass, value, label, helperText, required, helperTextClassName, mask, ...rest} =
    props;

  return (
    <div
      className={classNames(
        containerClass,
        classes.textFieldContainer,
        value && value?.length >= MAX_CHARACTERS && classes.errorBorder,
      )}
      onFocus={rest.onFocus && rest.onFocus}
    >
      <div className={classes.labelContainer}>
        {label && (
          <Typography
            variant="caption"
            component="div"
            className={props.disabled ? classes.labelDisabled : classes.labelText}
          >
            {label}
          </Typography>
        )}

        {required && (
          <Typography variant="caption" component="div" className={classes.requiredText}>
            *
          </Typography>
        )}
      </div>

      <MaskedInput
        required={true}
        {...rest}
        // ref={(ref) => {
        //   inputRef(ref ? ref.inputElement : null);
        // }}
        value={value || ''}
        className={classNames(`${classes.input} ${rest.className || ''}`)}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        maskChar={null}
        mask={mask}
      >
        {() => (
          <OutlinedInput
            placeholder={props.placeholder}
            inputProps={{className: classes.input}}
            {...rest}
            disabled={props.disabled}
            error={rest.error}
          />
        )}
      </MaskedInput>

      <FormHelperText
        error={rest.error}
        className={
          value && value?.length >= MAX_CHARACTERS ? classes.helperText : helperTextClassName
        }
      >
        {helperText}
      </FormHelperText>
    </div>
  );
};
