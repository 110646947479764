import * as React from 'react';
import useStyles from './range-slider.styles';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import {useEffect, useState} from 'react';
import {TextField} from '../text-field/TextField';
import {Typography} from '@material-ui/core';

export interface IMark {
  value: number;
  label?: any;
}

interface Props {
  id: string;
  name: string;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  step: number;
  marks: number[];
  onChange: any;
  className?: string;
  unit?: string;
}

export const RangeValueSlider: React.FC<Props> = (props) => {
  const {id, name, defaultValue, minValue, maxValue, marks, step, unit} = props;
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const convertIntoMarkValues = (): IMark[] => {
    const marksValues: IMark[] = [];
    marks.forEach((oneMark) => {
      marksValues.push({value: oneMark});
    });

    return marksValues;
  };

  const handleChange = (e: any, value: any) => {
    props.onChange(e, value);
    setSelectedValue(value);
  };

  return (
    <div className={classNames(classes.root, props.className)} key={name + ' ' + id}>
      <Box className={classes.rangeBox}>
        <Slider
          aria-label="Always visible"
          value={selectedValue}
          step={step}
          min={minValue}
          max={maxValue}
          marks={convertIntoMarkValues()}
          onChange={handleChange}
        />
        <div className={classes.valueContainer}>
          <TextField
            name={name}
            className={classes.inputValueFied}
            disabled
            value={selectedValue + ''}
            containerClass={classes.textContainerClass}
          />
          <Typography variant="body1" className={classes.unit}>
            {unit || ''}
          </Typography>
        </div>
      </Box>
    </div>
  );
};
