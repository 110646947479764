import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {},
  textFieldContainer: {
    marginTop: 0,
    marginRight: 24,
  },
  errorBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.uicolors.fireOpal,
    },
  },
  helperText: {
    marginTop: 0,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    color: theme.palette.uicolors.fireOpal,
  },
  labelText: {
    color: theme.palette.blacksAndGrays.davysGray,
    marginBottom: 4,
  },
  labelDisabled: {
    color: theme.palette.blacksAndGrays.silverSand,
    marginBottom: 4,
  },
  requiredText: {
    color: theme.palette.primary.light,
    marginBottom: 4,
  },
  labelContainer: {
    display: 'flex',
  },
  unitContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
