/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, put, post} from './api-calls';
import axios from 'axios';
import {IUserDto} from '../dtos/IUserDto';
import {IUserCategoryTagDto} from '../dtos/ICategoryTagDto';
import {IAchievementsDto} from '../dtos/IAchievementsDto';
import {IStatusDto} from '../dtos/IStatusDto';
import {IPhase} from '../models/IPhase';
import {IBasicInfoDto} from '../dtos/IBasicInfoDto';
import {IUserPaginationResult} from '../models/IUserPaginationResult';
import {IMeasurementUnit} from '../models/IMeasurementUnit';
import {IInitialWeightDto} from '../dtos/IInitialWeightDto';
import {EmojiSkin} from 'emoji-mart';
import {IOnBoardingUser} from '../models/IOnBoardingUser';
import moment from 'moment';
import {dateToUtc} from '../utils/date-time-utils';
import {fetchAndActivate, getRemoteConfig, getValue} from '@firebase/remote-config';
import {initializeApp} from '@firebase/app';
import store from '../store/store';
import {
  APPOINTMENT_ENABLED,
  MOVEMENT_ENABLED,
  REHAB_ENABLED,
} from '../store/actions/nourish/nourish-types';
/* ----------------------- mini orange part -----------------------------------  */

let appClientId = '';
let appClientSecret = '';
const appRedirectUri = process.env.REACT_APP_MINIORANGE_REDIRECT_URL || '';
const appMiniOrangeBaseUrl = process.env.REACT_APP_MINIORANGE_BASE_URL || '';
const appMiniOrangeRedirectLogoutUrl = process.env.REACT_APP_LOGOUT_REDIRECT_URL || '';
const appMiniOrangeConfig = process.env.REACT_APP_MINIORANGE_CONFIG || '';
const appMovementConfig = process.env.REACT_APP_DISCOVER_CONFIG || '';
const appointmentConfig = process.env.REACT_APP_APPOINTMENT_CONFIG || '';
let appMiniOrangeLoginUrl = '';

const firebaseConfig: any = {
  apiKey: 'AIzaSyC-imo2pouytteRkcG0_HmVQYeIqxIZkLg',
  authDomain: 'betrhealth-dd41d.firebaseapp.com',
  projectId: 'betrhealth-dd41d',
  storageBucket: 'betrhealth-dd41d.appspot.com',
  messagingSenderId: '449991773425',
  appId: '1:449991773425:web:25fa90fbd231c5f0e26d50',
  measurementId: 'G-357MM1JT5Y',
};
export const setFirebase = () => {
  const app = initializeApp(firebaseConfig);
  return fetchAndActivate(getRemoteConfig(app))
    .then(async () => {
      const configObj: any = await getValue(getRemoteConfig(), appMiniOrangeConfig);
      const configMovObj: any = await getValue(getRemoteConfig(), appMovementConfig);
      const appointmentObj: any = await getValue(
        getRemoteConfig(),
        appointmentConfig ? appointmentConfig : 'prod_appointment_users',
      );

      const config = JSON.parse(configObj?._value);
      const configMov = JSON.parse(configMovObj?._value);
      const appointmentConfigs = JSON.parse(appointmentObj?._value);
      //  console.log("appointmentObj", appointmentObj?._value, appointmentObj);
      appMiniOrangeLoginUrl = config?.loginUrl;
      appClientId = config?.clientId;
      appClientSecret = config?.clientSecret;
      store.dispatch({type: REHAB_ENABLED, payload: config?.rehab_enable});
      store.dispatch({type: MOVEMENT_ENABLED, payload: configMov?.movement});
      store.dispatch({type: APPOINTMENT_ENABLED, payload: appointmentConfigs?.Userid});
    })
    .catch((err: any) => {
      console.log('err-', err);
      // ...
    });
};

setFirebase();

export const buildUrlLinkMiniOrange = () => {
  appMiniOrangeLoginUrl && window.location.replace(appMiniOrangeLoginUrl);
};

export const handleMiniOrangeLogOut = () => {
  const urlLink = `${appMiniOrangeBaseUrl}/idp/oidc/logout?post_logout_redirect_uri=${appMiniOrangeRedirectLogoutUrl}`;
  window.location.replace(urlLink);
};

export const getTokenInfoMiniOrange = async (code: string): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: ' ',
    },
  };

  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('code', code);
  params.append('client_id', appClientId);
  params.append('client_secret', appClientSecret);
  params.append('redirect_uri', appRedirectUri);

  return await axios.post(`${appMiniOrangeBaseUrl}/rest/oauth/token`, params, config);
};

export const getTokenInfoMiniOrangeLocal = async (code: string): Promise<any> => {
  return get(
    `/AutoLogin?hashCode=${code}`,
    {},
    {
      'X-Version': '2',
    },
  );
};

export const getUserInfoMiniOrange = async (accessToken: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return await axios.get(`${appMiniOrangeBaseUrl}/rest/oauth/getuserinfo`, config);
};

/* ----------------------- mini orange part -----------------------------------  */

export const getUserInfo = (): Promise<IUserDto> => {
  return get(`/userinfo`);
};

export const getUserCategoryTags = (): Promise<IUserCategoryTagDto[]> => {
  return get(`/users/tags`);
};

export const getAllUsers = (data: any): Promise<IUserDto[]> => {
  let urlLink = !data.includeSecondaryTrainees ? `/users?` : `/users/Getsecondarytrainees?`;
  if (data.searchFilter) urlLink += `&searchFilter=${data.searchFilter}`;
  if (data.membershipFilter) urlLink += `&predefinedSearchFilter=${data.membershipFilter}`;
  if (data.trainerId) urlLink += `&trainerId=${data.trainerId}`;
  if (data.phaseId) urlLink += `&phaseId=${data.phaseId}`;
  if (data.phaseStartDate) urlLink += `&phaseStartDate=${data.phaseStartDate}`;
  // if (data.includeSecondaryTrainees)
  //   urlLink += `&includeSecondaryTrainees=${data.includeSecondaryTrainees}`;
  if (data.tags && data.tags?.length > 0) {
    data.tags.forEach((tag: number) => {
      urlLink += `&tags=${tag}`;
    });
  }
  if (data.onlyTrainer) urlLink += `&OnlyTrainer=true`;

  return get(urlLink);
};

export const logoutUserApi = (): Promise<IUserDto> => {
  return get(`/Admin/Logout`);
};

export const getSearchPaginationUsers = (data: any): Promise<IUserPaginationResult> => {
  return get(
    `/Admin?searchFilter=${data.searchFilter}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
  );
};
export const getSignedCookie = async (): Promise<string> => {
  // const configNew = {
  //   withCredentials: true,
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     Authorization: ' ',
  //   },
  // };
  // const test =  await axios.get(`${SERVER_URL}/rest/oauth/token`,  configNew);
  // eslint-disable-next-line no-console
  // console.log("tezt", test);
  return get(`/Admin/GetSignedCookie`, {withCredentials: true});
};
export const getUser = (id: string): Promise<IUserDto> => {
  return get(`/users/${id}`);
};
export const getUserLinks = (id: string): Promise<IUserDto> => {
  return get(`/Users/User/Links?UserId=${id}&FrontEnd=true`);
};

export const getMeasurementUnit = (id: number): Promise<IMeasurementUnit> => {
  return get(`/measurementunit/${id}`);
};

export const getAllMeasurementUnits = (): Promise<IMeasurementUnit[]> => {
  return get(`/MeasurementUnit/`);
};

export const getAchievements = (id: string): Promise<IAchievementsDto[]> => {
  return get(`/achievement/${id}`);
};

export const editUserDatabase = (data: any): Promise<any> => {
  return put(`/users`, data);
};

export const manageUserSubscribe = (data: any): Promise<any> => {
  return put(`/Users/ManageSubscription`, data);
};

export const editUserTagsDatabase = (data: any): Promise<any> => {
  return put(`/users/tags`, data);
};

export const editUserPhaseDatabase = (data: IPhase): Promise<IPhase> => {
  return put(`/users/phase`, data);
};

export const editUserInitialWeightDatabase = (data: IInitialWeightDto): Promise<any> => {
  return put(`/users/initialWeight`, data);
};

export const editUserCoachingDatabase = (data: any): Promise<any> => {
  return put(`/users/coaching`, data);
};

export const editUserStatusDatabase = (data: IStatusDto): Promise<IStatusDto> => {
  return put(`/users/status`, data);
};

export const postUserImageDatabase = (data: FormData): Promise<void> => {
  return post(`/users/image`, data, {
    'content-type': 'multipart/form-data',
  });
};

export const editUserBasicInfoDatabase = (data: IBasicInfoDto): Promise<void> => {
  return put(`/userInfo`, data);
};

export const editUserSkinTone = (data: EmojiSkin): Promise<void> => {
  return put(`/users/SkinTone`, {skinTone: data});
};

export const getCoaches = (data: any): Promise<IUserDto[]> => {
  let urlLink = `/users/coaches?`;
  if (data.searchFilter) urlLink += `&searchFilter=${data.searchFilter}`;
  if (data.startDate) urlLink += `&startDate=${data.startDate}`;
  if (data.endDate) urlLink += `&endDate=${data.endDate}`;
  return get(urlLink);
};

export const editPersonalCoach = (data: any): Promise<void> => {
  return put(`/users/trainer`, data);
};

export const validateUserProfileInfo = (data: IOnBoardingUser): Promise<any> => {
  const backUser: any = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    dateOfBirth: data.dateOfBirth,
    gender: data.gender,
  };

  return post(`/users/userValidation`, backUser);
};

export const addUserOnBoarding = (data: IOnBoardingUser): Promise<any> => {
  const backUser: any = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    dateOfBirth: data.dateOfBirth,
    gender: data.gender,
    measurementUnitId: data.unitId,
    height: data.height,
    initialWeight: data.weight,
    initialWeightDate: dateToUtc(moment().toString()), // additional
    password: data.password,
    userSponsors: [
      {
        payorId: data.encodedData.H3PayorId,
        memberId: data.encodedData.H3UserId,
        programId: data.encodedData.H3ProgramId,
        enrollmentId: data.encodedData.H3SupplierId,
        programSowId: data.type, // 1 = walgreens
        dateStart: dateToUtc(moment().toString()),
        statusId: 1, // currentStatus
      },
    ],
  };
  return post(`/users`, backUser);
};

export const deleteUnreadCountDatabase = (id: string): Promise<any> => {
  return del(`/Unread/UnreadCount/${id}`);
};

export const updateOnboardUserApi = async (data: any): Promise<any> => {
  return put(`/Users/BasicInfo`, JSON.stringify(data));
};
export const updateDirectOnboardUserApi = async (data: any): Promise<any> => {
  return post(`/Users/UpdateContractingMember`, JSON.stringify(data));
};
export const DirectOnboardUserApi = async (data: any): Promise<any> => {
  return post(`/Users/DirectOnBoarding`, JSON.stringify(data));
};
export const getDirectOnboardUserApi = async (data: any): Promise<any> => {
  return post(`/Users/GetContractingMember?`, JSON.stringify(data)); 
};
export const updateOnboardStatusUserApi = async (data: any): Promise<any> => {
  return put(`/Users/OnBoardingStatus`, JSON.stringify(data));
};

export const reEnrollGetApi = async (userId: any): Promise<any> => {
  return get(`/Admin/${userId}`);
};

export const updateReEnrollUserApi = async (data: any): Promise<any> => {
  return post(`/Admin/ReenrollmentByCode`, JSON.stringify(data));
};
