import React from 'react';
import {useTranslation} from 'react-i18next';
import Reminder from './Reminder';
import {FriendlyReminderType, SideMenuLoggingSelectedType} from '../../../utils/enums';
import NotificationReminder from '../../../assets/icons/reminder/notification-reminder-icon.svg';
import {useTheme} from '@material-ui/styles';
import {Theme} from '@material-ui/core';
import {INotificationDto} from '../../../dtos/INotificationDto';
import history from '../../../history';
import {GeneralPaths} from '../../../utils/constants';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';

interface IReminderResolverProps {
  notification: INotificationDto;
}
const FriendlyReminderResolver: React.FC<IReminderResolverProps> = ({notification}) => {
  const [t] = useTranslation();
  const theme = useTheme<Theme>();
  const currentUser = useSelector(selectCurrentUser);

  const handleClick = (path: GeneralPaths, elementType: SideMenuLoggingSelectedType) => {
    history.push(`${path}?widget=${elementType}`);
  };
  switch (notification.value) {
    case FriendlyReminderType.BODY_WEIGHT:
      return (
        <Reminder
          title={t('Homepage.notificationPanel.logWeight.title')}
          titleIcon={NotificationReminder}
          content={`${t('Homepage.notificationPanel.greeting')} 
            ${currentUser.firstName}, ${t('Homepage.notificationPanel.logWeight.content')}`}
          buttonText={t('Homepage.notificationPanel.logWeight.buttonText')}
          handleClick={() =>
            handleClick(GeneralPaths.LogsPage, SideMenuLoggingSelectedType.SIDE_MENU_BODY_WEIGHT)
          }
          color={theme.palette.background.paper}
          reminderType={FriendlyReminderType.BODY_WEIGHT}
        />
      );
    case FriendlyReminderType.FOOD:
      return (
        <Reminder
          title={t('Homepage.notificationPanel.logFood.title')}
          titleIcon={NotificationReminder}
          content={`${t('Homepage.notificationPanel.greeting')} 
            ${currentUser.firstName}, ${t('Homepage.notificationPanel.logFood.content')}`}
          buttonText={t('Homepage.notificationPanel.logFood.buttonText')}
          handleClick={() =>
            handleClick(GeneralPaths.LogsPage, SideMenuLoggingSelectedType.SIDE_MENU_FOOD)
          }
          color={theme.palette.background.paper}
          reminderType={FriendlyReminderType.FOOD}
        />
      );
    case FriendlyReminderType.GLOBAL:
      return (
        <Reminder
          title={t('Homepage.notificationPanel.logFood.title')}
          titleIcon={NotificationReminder}
          content={`${t('Homepage.notificationPanel.greeting')} 
            ${currentUser.firstName}, ${t('Homepage.notificationPanel.logGlobal.content')}`}
          buttonText={t('Homepage.notificationPanel.logGlobal.buttonText')}
          handleClick={() => history.push(GeneralPaths.LogsPage)}
          color={theme.palette.background.paper}
          reminderType={FriendlyReminderType.GLOBAL}
        />
      );
    default:
      return <></>;
  }
};

export default FriendlyReminderResolver;
