import React, {useEffect, useRef, useState} from 'react';
import useStyles from './tags-item.styles';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Theme, Typography} from '@material-ui/core';
import {CATEGORY_CHIP_TAG_ID} from '../../../utils/constants';
import {Button} from '../button/Button';
import {IUser} from '../../../models/IUser';
import {selectUserTags} from '../../../store/selectors/loggingPageSelector';
import {ChipTag} from '../chip-tag/ChipTag';
import CategoryChipTagsWithBox from '../category-chip-tags-box/CategoryChipTagsWithBox';
import {IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';
import {useTheme} from '@material-ui/styles';
import {callSaveUserObject, editUserTags} from '../../../store/actions/homepage/homepage-action';

interface Props {
  user: IUser;
  myProfile: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const TagsItem: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {user, myProfile} = props;
  const initialUserTagsList = useSelector(selectUserTags);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [editMood, setEditMood] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme<Theme>();
  const [tagsList, setTagsList] = useState<IUserCategoryTagDto[]>(initialUserTagsList);
  const tagsBoxRef1: any = useRef();
  const tagsBoxRef2: any = useRef();
  const tagsBoxRef3: any = useRef();
  const tagsBoxRef4: any = useRef();
  const tagsBoxRef5: any = useRef();
  const tagsBoxRef6: any = useRef();
  const tagsBoxRef7: any = useRef();

  const handleTagsChanges = () => {
    setHasChanges(true);
  };

  const handleEdit = () => {
    setEditMood(!editMood);
  };

  useEffect(() => {
    const tagsArr: IUserCategoryTagDto[] = [];
    initialUserTagsList.forEach((tags) => {
      tags.tags.forEach((tag) => {
        tag.isVisible = true;
      });
    });

    initialUserTagsList.forEach((category) => {
      const tagsCategory: IUserCategoryTagDto = {
        color: category.color,
        fontColor: category.fontColor,
        hoverColor: category.hoverColor,
        id: category.id,
        name: category.name,
        subCategories: category.subCategories,
        tags: [],
      };
      category.tags.forEach((tag) => {
        const tagToAdd = {
          categoryId: tag.categoryId,
          id: tag.id,
          isSystemOnly: tag.isSystemOnly,
          isVisible: tag.isVisible,
          name: tag.name,
          color: tag.color,
          hoverColor: tag.hoverColor,
          show: true,
        };
        user?.tags?.forEach((tagId: number) => {
          if (tag.id === tagId) {
            tagToAdd.isVisible = false;
          }
        });
        tagsCategory.tags.push(tagToAdd);
      });
      tagsArr.push(tagsCategory);
    });
    setTagsList(tagsArr);
  }, [initialUserTagsList]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const tagsArray: any = [];
    tagsBoxRef1?.current?.getSelectedTags().forEach((tag: any) => tagsArray.push(tag.id));
    tagsBoxRef2?.current?.getSelectedTags().forEach((tag: any) => tagsArray.push(tag.id));
    tagsBoxRef3?.current?.getSelectedTags().forEach((tag: any) => tagsArray.push(tag.id));
    tagsBoxRef4?.current?.getSelectedTags().forEach((tag: any) => tagsArray.push(tag.id));
    tagsBoxRef5?.current?.getSelectedTags().forEach((tag: any) => tagsArray.push(tag.id));
    tagsBoxRef6?.current?.getSelectedTags().forEach((tag: any) => tagsArray.push(tag.id));
    tagsBoxRef7?.current?.getSelectedTags().forEach((tag: any) => tagsArray.push(tag.id));
    setIsLoading(false);
    const dataToSend: any = {
      userId: user.id,
      tags: tagsArray,
    };
    const res1 = await dispatch(editUserTags(dataToSend));
    setIsLoading(false);
    setEditMood(false);
    if (!res1) {
      return;
    }
    await dispatch(callSaveUserObject(user.id));
  };

  return (
    <React.Fragment>
      <div className={classes.titleContainer}>
        <Typography variant={'subtitle1'} className={classes.rowContainer}>
          {t('Homepage.quickProfileUser.tags')}
        </Typography>
        <Button
          id={'edit-btn'}
          variant={'text'}
          onClick={handleEdit}
          className={editMood ? classes.editBtn : ''}
        >
          {t('Homepage.quickProfileUser.editButton')}
        </Button>
      </div>
      {!myProfile && !editMood && (
        <div>
          <div className={classes.tagsContainer}>
            {initialUserTagsList.map((tagCategory) =>
              tagCategory.tags.map(
                (tag) =>
                  user &&
                  user.tags &&
                  user.tags?.length > 0 &&
                  user.tags.map(
                    (tagId: number) =>
                      tag.id === tagId && (
                        <ChipTag
                          key={`tag-selected-${tag.id}`}
                          color={tagCategory.color}
                          borderColor={tagCategory.hoverColor}
                          hoverColor={tagCategory.color}
                          name="chip"
                          id={tag.id || 0}
                          label={tag.name}
                        />
                      ),
                  ),
              ),
            )}
          </div>
        </div>
      )}
      {!myProfile && editMood && (
        <div>
          <div>
            <Typography variant={'overline'}>{initialUserTagsList[0].name}</Typography>
            <CategoryChipTagsWithBox
              id={CATEGORY_CHIP_TAG_ID}
              name={CATEGORY_CHIP_TAG_ID}
              categoryTagsList={[tagsList[0]]}
              ref={tagsBoxRef1}
              className={classes.tagsCont}
              handleTagsChanges={() => handleTagsChanges()}
            />
            <Typography variant={'overline'}>{initialUserTagsList[1].name}</Typography>
            <CategoryChipTagsWithBox
              id={CATEGORY_CHIP_TAG_ID}
              name={CATEGORY_CHIP_TAG_ID}
              categoryTagsList={[tagsList[1]]}
              ref={tagsBoxRef2}
              notSelectedColor={theme.palette.background.paper}
              className={classes.tagsCont}
              handleTagsChanges={() => handleTagsChanges()}
            />
            <Typography variant={'overline'}>{initialUserTagsList[2].name}</Typography>
            <CategoryChipTagsWithBox
              id={CATEGORY_CHIP_TAG_ID}
              name={CATEGORY_CHIP_TAG_ID}
              className={classes.tagsCont}
              categoryTagsList={[tagsList[2]]}
              ref={tagsBoxRef3}
              notSelectedColor={theme.palette.background.paper}
              handleTagsChanges={() => handleTagsChanges()}
            />
            <Typography variant={'overline'}>{initialUserTagsList[3].name}</Typography>
            <CategoryChipTagsWithBox
              id={CATEGORY_CHIP_TAG_ID}
              name={CATEGORY_CHIP_TAG_ID}
              className={classes.tagsCont}
              categoryTagsList={[tagsList[3]]}
              ref={tagsBoxRef4}
              notSelectedColor={theme.palette.background.paper}
              handleTagsChanges={() => handleTagsChanges()}
            />
            <Typography variant={'overline'}>{initialUserTagsList[4].name}</Typography>
            <CategoryChipTagsWithBox
              id={CATEGORY_CHIP_TAG_ID}
              name={CATEGORY_CHIP_TAG_ID}
              className={classes.tagsCont}
              categoryTagsList={[tagsList[4]]}
              ref={tagsBoxRef5}
              notSelectedColor={theme.palette.background.paper}
              handleTagsChanges={() => handleTagsChanges()}
            />
            <Typography variant={'overline'}>{initialUserTagsList[5].name}</Typography>
            <CategoryChipTagsWithBox
              id={CATEGORY_CHIP_TAG_ID}
              name={CATEGORY_CHIP_TAG_ID}
              className={classes.tagsCont}
              notSelectedColor={theme.palette.background.paper}
              categoryTagsList={[tagsList[5]]}
              ref={tagsBoxRef6}
              handleTagsChanges={() => handleTagsChanges()}
            />
            <Typography variant={'overline'}>{initialUserTagsList[6].name}</Typography>
            <CategoryChipTagsWithBox
              id={CATEGORY_CHIP_TAG_ID}
              name={CATEGORY_CHIP_TAG_ID}
              className={classes.tagsCont}
              notSelectedColor={theme.palette.background.paper}
              categoryTagsList={[tagsList[6]]}
              ref={tagsBoxRef7}
              handleTagsChanges={() => handleTagsChanges()}
            />
          </div>
          <div className={classes.editButtons}>
            <Button
              id={'cancel-button-profile'}
              variant={'outlined'}
              className={classes.outlinedBtn}
              onClick={handleEdit}
            >
              {t('Homepage.quickProfileUser.cancelButton')}
            </Button>
            <Button
              id={'save-button-profile'}
              disabled={!hasChanges || isLoading}
              onClick={handleSubmit}
              loading={isLoading}
            >
              {t('Homepage.quickProfileUser.saveButton')}
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TagsItem;
