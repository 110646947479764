import {del, get, post, put} from './api-calls';
import {IDrinkDto} from '../dtos/IDrinkDto';

export const getDrinkLogs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IDrinkDto[]> => {
  return get(`/drink?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addDrinkDatabase = (data: IDrinkDto): Promise<any> => {
  return post('/drink', data);
};

export const editDrinkDatabase = (data: IDrinkDto): Promise<any> => {
  return put(`/drink/${data.id}`, data);
};

export const deleteDrinkDatabase = (id: number): Promise<any> => {
  return del(`/drink/${id}`);
};

export const getDrinkLogById = (id: number): Promise<IDrinkDto> => {
  return get(`/drink/${id}`);
};
