/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {get, put, post} from './api-calls';
import {ISecondaryCoachAssignDto, IEndNowDto} from '../dtos/ISecondaryCoachAssignDto';
import {ICoachingHistoryDto} from '../dtos/ICoachingHistoryDto';

//gets all secondary coaches i assigned to my users
export const getSecondaryCoachAssigment = (
  trainerId: string,
): Promise<ISecondaryCoachAssignDto[]> => {
  return get(`/secondaryCoachAssignment/primaryCoach/${trainerId}`);
};

//gets coaches who assigned me as secondary coach
export const getMySecondaryAssignments = (id: string): Promise<ISecondaryCoachAssignDto[]> => {
  return get(`/secondaryCoachAssignment/isAssigned/${id}`);
};

//gets secondary coach for specific member
export const getSecondaryCoachForMember = (userId: string): Promise<ISecondaryCoachAssignDto> => {
  return get(`/secondaryCoachAssignment/user/${userId}`);
};

//posts secondary coach to all my users
export const postSecondaryCoachAll = (data: any): Promise<any> => {
  return post(`/secondaryCoachAssignment/add-update`, data);
};

//edits secondary coach for one specific user
export const putSecondaryCoachOne = (data: any): Promise<any> => {
  return put(`/secondaryCoachAssignment/update-add`, data);
};

export const endSecondaryCoaching = (data: IEndNowDto): Promise<any> => {
  return put(`/secondaryCoachAssignment/endnow`, data);
};

export const getCoachingHistory = (id: string): Promise<ICoachingHistoryDto[]> => {
  return get(`/users/logCoachings/?UserId=${id}`);
};
