import {Chip as ChipMaterial} from '@material-ui/core';
import * as React from 'react';
import useStyles from './chip-tag.styles';
import classNames from 'classnames';

interface Props {
  containerClass?: string;
  disabled?: boolean;
  className?: string;
  label: string;
  id: number;
  name: string;
  color: React.CSSProperties['color'];
  borderColor: React.CSSProperties['color'];
  hoverColor: React.CSSProperties['color'];
  onClick?: any;
  onDelete?: any;
  textColor?: any;
}

export const ChipTag: React.FC<Props> = (props) => {
  const {id, containerClass, label, color, borderColor, hoverColor, onClick, onDelete, disabled} =
    props;
  const classes = useStyles({
    color: color,
    borderColor: borderColor,
    hoverColor: hoverColor,
    textColor: props.textColor,
  });

  return (
    <div className={classNames(containerClass, classes.chipContainer)} key={props.name + ' ' + id}>
      <ChipMaterial
        label={label}
        variant="default"
        className={disabled ? classes.disabled : classes.chip}
        disabled={disabled}
        // style={{backgroundColor: `${color}`, border: `1px solid ${borderColor}`}}
        // deleteIcon={<img src={CloseIcon} alt="closeIcon" id="close-icon" />}
        onDelete={!onDelete ? undefined : () => onDelete(id)}
        onClick={!onClick ? undefined : () => onClick(id)}
      />
    </div>
  );
};
