import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {IMark, RangeSlider} from '../../range-slider/RangeSlider';
import {MoodEmoticon} from '../../range-slider/MoodEmoticon';
import BodyPainIcon from '../../../../assets/icons/side-menu-bar-logging/body-pain-icon.svg';
import NoPain from '../../../../assets/icons/body-pain-level/no-pain-level-icon.svg';
import VeryDistracting from '../../../../assets/icons/body-pain-level/very-distracting-level-icon.svg';
import Unbearable from '../../../../assets/icons/body-pain-level/unbearable-level-icon.svg';
import Invasive from '../../../../assets/icons/body-pain-level/invasive-level-icon.svg';
import BarelyNoticeable from '../../../../assets/icons/body-pain-level/barely-noticeable-level-icon.svg';
import BarelyDistracting from '../../../../assets/icons/body-pain-level/barely-distracting-level-icon.svg';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {
  addBodyPainLog,
  editBodyPainLog,
} from '../../../../store/actions/logging-page/logging-page-action';
import {ValidationType} from '../../../../validation/ValidationType';
import {getError, hasError, isFormValidToSubmit, validate} from '../../../../validation/Validation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {FormType, WidgetType} from '../../../../utils/enums';
import {DATE_PICKER_DATE_FORMAT} from '../../../../utils/constants';

import useStyles from './body-pain-form.styles';
import useCommonStyles from '../common-for-widgets.styles';

interface IBodyPainFormData {
  date: string;
  time: string;
  neckPainLevelValue: number;
  shoulderPainLevelValue: number;
  lowerBackPainLevelValue: number;
  hipPainLevelValue: number;
  kneePainLevelValue: number;
}

interface IBodyPainProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  openedFromDialog?: boolean;
  date?: string;
  isMobile: boolean;
}

const validationRules = {
  date: [{type: ValidationType.REQUIRED}],
  time: [{type: ValidationType.REQUIRED_DURATION}],
};

const defaultPainLevelValue = 0;

const BodyPainForm: React.FC<IBodyPainProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const formType = props.formType || FormType.ADDING_MODE;

  const [data, setData] = useState<IBodyPainFormData>({
    date: moment().toString(),
    time: moment().toString(),
    neckPainLevelValue: defaultPainLevelValue,
    shoulderPainLevelValue: defaultPainLevelValue,
    lowerBackPainLevelValue: defaultPainLevelValue,
    hipPainLevelValue: defaultPainLevelValue,
    kneePainLevelValue: defaultPainLevelValue,
  });

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }
    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    let initialObject: IBodyPainFormData = {
      neckPainLevelValue: defaultPainLevelValue,
      shoulderPainLevelValue: defaultPainLevelValue,
      lowerBackPainLevelValue: defaultPainLevelValue,
      hipPainLevelValue: defaultPainLevelValue,
      kneePainLevelValue: defaultPainLevelValue,
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        neckPainLevelValue: props?.data.neckPainLevelValue || defaultPainLevelValue,
        shoulderPainLevelValue: props?.data.shoulderPainLevelValue || defaultPainLevelValue,
        lowerBackPainLevelValue: props?.data.lowerBackPainLevelValue || defaultPainLevelValue,
        hipPainLevelValue: props?.data.hipPainLevelValue || defaultPainLevelValue,
        kneePainLevelValue: props?.data.kneePainLevelValue || defaultPainLevelValue,
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
      };
    }

    setData(initialObject);
  }, [props.data]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    setData({
      neckPainLevelValue: defaultPainLevelValue,
      shoulderPainLevelValue: defaultPainLevelValue,
      lowerBackPainLevelValue: defaultPainLevelValue,
      hipPainLevelValue: defaultPainLevelValue,
      kneePainLevelValue: defaultPainLevelValue,
      date: moment().toString(),
      time: moment().toString(),
    });
  };

  const handleChange = (_e: any, value: number, name: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);

      const bodyPainLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        neckPainLevelValue: data.neckPainLevelValue,
        shoulderPainLevelValue: data.shoulderPainLevelValue,
        lowerBackPainLevelValue: data.lowerBackPainLevelValue,
        hipPainLevelValue: data.hipPainLevelValue,
        kneePainLevelValue: data.kneePainLevelValue,
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addBodyPainLog(bodyPainLog));
      } else {
        res = await dispatch(editBodyPainLog(bodyPainLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  const marks: IMark[] = [
    {
      value: 0,
      label: (
        <MoodEmoticon
          src={NoPain}
          name={t('LogsPage.bodyPainWidget.marks.noPain')}
          text={t('LogsPage.bodyPainWidget.marks.noPain')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 1,
      label: (
        <MoodEmoticon
          src={BarelyNoticeable}
          name={t('LogsPage.bodyPainWidget.marks.barelyNoticeable')}
          text={t('LogsPage.bodyPainWidget.marks.barelyNoticeable')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 2,
      label: (
        <MoodEmoticon
          src={BarelyNoticeable}
          name={t('LogsPage.bodyPainWidget.marks.noticeable')}
          text={t('LogsPage.bodyPainWidget.marks.noticeable')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 3,
      label: (
        <MoodEmoticon
          src={BarelyDistracting}
          name={t('LogsPage.bodyPainWidget.marks.barelyDistracting')}
          text={t('LogsPage.bodyPainWidget.marks.barelyDistracting')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 4,
      label: (
        <MoodEmoticon
          src={BarelyDistracting}
          name={t('LogsPage.bodyPainWidget.marks.distracting')}
          text={t('LogsPage.bodyPainWidget.marks.distracting')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 5,
      label: (
        <MoodEmoticon
          src={VeryDistracting}
          name={t('LogsPage.bodyPainWidget.marks.veryDistracting')}
          text={t('LogsPage.bodyPainWidget.marks.veryDistracting')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 6,
      label: (
        <MoodEmoticon
          src={VeryDistracting}
          name={t('LogsPage.bodyPainWidget.marks.mildlyInvasive')}
          text={t('LogsPage.bodyPainWidget.marks.mildlyInvasive')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 7,
      label: (
        <MoodEmoticon
          src={Invasive}
          name={t('LogsPage.bodyPainWidget.marks.veryInvasive')}
          text={t('LogsPage.bodyPainWidget.marks.veryInvasive')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 8,
      label: (
        <MoodEmoticon
          src={Invasive}
          name={t('LogsPage.bodyPainWidget.marks.quiteInvasive')}
          text={t('LogsPage.bodyPainWidget.marks.quiteInvasive')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 9,
      label: (
        <MoodEmoticon
          src={Unbearable}
          name={t('LogsPage.bodyPainWidget.marks.unbearable')}
          text={t('LogsPage.bodyPainWidget.marks.unbearable')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 10,
      label: (
        <MoodEmoticon
          src={Unbearable}
          name={t('LogsPage.bodyPainWidget.marks.worstEver')}
          text={t('LogsPage.bodyPainWidget.marks.worstEver')}
          isScaleEmoticon={true}
        />
      ),
    },
  ];

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img className={commonClasses.imageMargin} src={BodyPainIcon} alt={'body-pain-icon'} />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.bodyPainWidget.formTitleEdit')
              : t('LogsPage.bodyPainWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.bodyPainWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.bodyPainWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>

      <div className={classNames(classes.centerContainer, classes.marginStress)}>
        <Typography variant={'body1'}>{t('LogsPage.bodyPainWidget.rangeSliderNeck')}</Typography>
        <RangeSlider
          id={'neckPainLevelValue'}
          name={'neckPainLevelValue'}
          step={1}
          defaultValue={data.neckPainLevelValue}
          minValue={0}
          maxValue={10}
          marks={marks}
          onChange={(e: any, value: any) => handleChange(e, value, 'neckPainLevelValue')}
          className={classes.rangeSlider}
          showLabel={true}
        />
      </div>

      <div className={classNames(classes.centerContainer, classes.marginStress)}>
        <Typography variant={'body1'}>
          {t('LogsPage.bodyPainWidget.rangeSliderShoulder')}
        </Typography>
        <RangeSlider
          id={'shoulderPainLevelValue'}
          name={'shoulderPainLevelValue'}
          step={1}
          defaultValue={data.shoulderPainLevelValue}
          minValue={0}
          maxValue={10}
          marks={marks}
          onChange={(e: any, value: any) => handleChange(e, value, 'shoulderPainLevelValue')}
          className={classes.rangeSlider}
          showLabel={true}
        />
      </div>

      <div className={classNames(classes.centerContainer, classes.marginStress)}>
        <Typography variant={'body1'}>{t('LogsPage.bodyPainWidget.rangeSliderLowBack')}</Typography>
        <RangeSlider
          id={'lowerBackPainLevelValue'}
          name={'lowerBackPainLevelValue'}
          step={1}
          defaultValue={data.lowerBackPainLevelValue}
          minValue={0}
          maxValue={10}
          marks={marks}
          onChange={(e: any, value: any) => handleChange(e, value, 'lowerBackPainLevelValue')}
          className={classes.rangeSlider}
          showLabel={true}
        />
      </div>

      <div className={classNames(classes.centerContainer, classes.marginStress)}>
        <Typography variant={'body1'}>{t('LogsPage.bodyPainWidget.rangeSliderHip')}</Typography>
        <RangeSlider
          id={'hipPainLevelValue'}
          name={'hipPainLevelValue'}
          step={1}
          defaultValue={data.hipPainLevelValue}
          minValue={0}
          maxValue={10}
          marks={marks}
          onChange={(e: any, value: any) => handleChange(e, value, 'hipPainLevelValue')}
          className={classes.rangeSlider}
          showLabel={true}
        />
      </div>

      <div className={classNames(classes.centerContainer, classes.marginStress)}>
        <Typography variant={'body1'}>{t('LogsPage.bodyPainWidget.rangeSliderKnee')}</Typography>
        <RangeSlider
          id={'kneePainLevelValue'}
          name={'kneePainLevelValue'}
          step={1}
          defaultValue={data.kneePainLevelValue}
          minValue={0}
          maxValue={10}
          marks={marks}
          onChange={(e: any, value: any) => handleChange(e, value, 'kneePainLevelValue')}
          className={classes.rangeSlider}
          showLabel={true}
        />
      </div>

      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-body-pain-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-body-pain-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.bodyPainWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default BodyPainForm;
