import {useState} from 'react';
import {IUser} from '../../models/IUser';
import history from '../../history';
import {checkIfPathNeedAuth, checkIfRoleIsFulfilled} from '../../routes';
import {GeneralPaths} from '../../utils/constants';

export const usePermission = (): any => {
  const [allowed, setAllowed] = useState(false);
  const applyTo = (currentUser: IUser) => {
    const {pathname} = history.location;
    const needAuth = checkIfPathNeedAuth(pathname);
    const needPermission = checkIfRoleIsFulfilled(pathname, currentUser);
    const _handleNext = () => {
      history.push(GeneralPaths.LandingPage);
      setAllowed(false);
    };
    if (needPermission) {
      _handleNext();
    }
    if (needAuth && !currentUser) {
      _handleNext();
    }
    // if(currentUser?.onBoardingStatus === 1){
    //   return   window.location.href = `https://betrhealth.com/solera?userid=${currentUser?.id}`
    // }
    setAllowed(true);
  };

  return {
    allowed,
    applyTo,
  };
};
