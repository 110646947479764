import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  accordionItem: {
    '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root, .MuiAccordion-root': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiAccordion-root': {
      color: theme.palette.blacksAndWhites.smokyBlack,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '12px !important',
      height: '56px',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  widgetDataContainer: {
    padding: (props) => (props.isMobile ? 0 : '16px 16px 0 16px'),
  },
  line: {
    backgroundColor: theme.palette.blacksAndGrays.gainsboro,
    height: 1,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: (props) => (props.isMobile ? 16 : 0),
    paddingTop: (props) => (props.isMobile ? 12 : 0),
    paddingBottom: 16,
  },
  imageTextContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageMargin: {
    marginRight: 12,
  },
}));

export default useStyles;
