import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: {
    isMobile: boolean;
    isTablet: boolean;
  };
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    '&.sidebarSwitch': {
      width: (props) =>
        props.isMobileOrTablet.isMobile
          ? '100%'
          : props.isMobileOrTablet.isTablet
          ? 'calc(50% - 12px)'
          : '100%',
          backgroundColor: (props) =>
          props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
            ?  "#d6d6d6"
            : "#ffffff !important",
    },
  },
  switchContainer: {
    border: (props) =>   props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
    ? `1px solid #000000`
    : `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    // border: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    borderRadius: 18,
    backgroundColor: (props) =>
    props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
      ? "#d6d6d6"
      : theme.palette.background.paper,
    // backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    '&.sidebarSwitch': {
      border: (props) =>   props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
    ? `1px solid #000000`
    : `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
      // border: (props) =>
      //   props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
      //     ? `1px solid ${theme.palette.tintsAndShades.oxfordBlueDark}`
      //     : `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
      backgroundColor: (props) =>
    props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
      ?  "#d6d6d6"
      : "#ffffff !important",
      active: {
        backgroundColor: (props) =>
          props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
            ? theme.palette.tintsAndShades.babyBlueEyes
            : theme.palette.primary.light,
        color: (props) =>
          props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
            ? theme.palette.blacksAndWhites.smokyBlack
            : theme.palette.background.paper,
      },
      inactive: {
        color: (props) =>
          props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
            ? "#000000 !important"
            : theme.palette.secondary.dark,
            // props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
            // ? theme.palette.tintsAndShades.babyBlueEyes
            // : theme.palette.secondary.dark,
      },
    },
  },
  active: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.background.paper,
    borderRadius: '30px',
    padding: 8,
    margin: 4,
    cursor: 'pointer',
  },
  inactive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    color: (props) =>
    props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
      ? "#000000 !important"
      : theme.palette.secondary.dark,
    // color: theme.palette.secondary.dark,
    cursor: 'pointer',
    width: '50%',
    backgroundColor: (props) =>
    props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
      ?  "#d6d6d6"
      : "#ffffff !important",
  },
  title: {
    marginBottom: 8,
    marginTop: 8,
    color: (props) =>
    props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTablet
      ? "#000000 !important"
      : theme.palette.secondary.dark,
  },
  disabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.blacksAndWhites.sonicSilver,
    width: '50%',
    borderRadius: 18,
  },
}));
export default useStyles;
