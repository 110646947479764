import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  card: {
    boxSizing: 'border-box',
    borderRadius: '12px',
    margin: '16px 0',
    padding: '8px 12px',
  },
  header: {
    display: 'flex',
  },
  icon: {
    marginRight: '8px',
  },
  body: {
    marginBottom: '8px',
  },
}));

export default useStyles;
