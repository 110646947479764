import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {SideMenuBarBase} from '../../../common/components/side-menu-bar/SideMenuBarBase';
import generateMenuBarOptions from '../../../common/components/side-menu-bar/side-menu-bar-options';
import {NourishType, Pages, SideMenuSelectedType} from '../../../utils/enums';
import {
  loadCards,
  loadCategories,
  loadCategoriesWithIngredients,
  loadCourses,
  loadCuisines,
  loadDailyContentCategories,
  loadDailyContents,
  loadIngredientsCategories,
  loadLevelCategories,
  loadMealPlans,
  loadMindfulness,
  loadPodcast,
  loadRecipes,
  loadRightCards,
  loadYoga,
} from '../../../store/actions/nourish/nourish-actions';
import {
  selectAllCards,
  selectAllDailyContents,
  selectAllMealPlans,
  selectAllMindfulness,
  selectAllRecipes,
  selectAllRightCards,
  selectBetrProgramFilters,
  selectMealPlansFilters,
  selectPodCasts,
  selectRecipeFilters,
  selectSelectedMenuItem,
  tagsData,
} from '../../../store/selectors/nourish.selectors';
import {RecipesView} from '../../../common/components/recipes/RecipesView';
import {IRecipeFiltersDto} from '../../../dtos/IRecipeFiltersDto';
import {IMealPlansFiltersDto} from '../../../dtos/IMealPlansFiltersDto';
import Loader from '../../../common/components/loader/Loader';
import {MealPlansView} from '../../../common/components/meal-plans/MealPlansView';
import {containsParamInHistory, getHistoryParams} from '../../../utils/qs-helper';
import {Footer} from '../../../common/components/footer/Footer';
import {
  DEFAULT_PER_PAGE_SIZE,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';
import {SideMenuBarMob} from '../../../common/components/side-menu-bar/SideMenuBarMob';

import useStyles from './nourish-page.styles';
import {CardType, ICardDto, IRightCardDto} from '../../../dtos/ICardDto';
import {NourishCard} from '../../../common/components/nourish-card/NourishCard';
import {IBetrProgramFilterDto} from '../../../dtos/IBetrProgramFilterDto';
import {DailyContentsView} from '../../../common/components/daily-content/DailyContentView';
import {FoodListsView} from '../../../common/components/food-lists/FoodListsView';
// import {MasterCLassesView} from '../../../common/components/daily-content/MasterClassesView';
import {FitnessContent} from '../../../common/components/movement/FitnessContent';
import TotalChallengeScore from '../../../common/components/movement/total-challenge-score/TotalChallengeScore';
import {MindfulnessView} from '../../../common/components/mindfullness/MindfulnessView';
import {PodCastsCards} from '../../../common/components/podCasts/PodCastsCards';

/* eslint-disable no-console */
const NourishPage: React.FC = () => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet,
  });
  const dispatch = useDispatch();
  const userData: any = useSelector((state: any) => state.users.currentUser);
  const TagData = useSelector(tagsData);
  const isTag11 = TagData ? TagData?.some((tag: any) => userData?.tags?.includes(tag)) : false;
  const menuItems = generateMenuBarOptions(useTranslation, isTag11)[Pages.NOURISH_PAGE];
  const recipes = useSelector(selectAllRecipes);
  const recipeFilters = useSelector(selectRecipeFilters);
  const mealPlans = useSelector(selectAllMealPlans);
  const mealPlansFilters = useSelector(selectMealPlansFilters);
  const dailyContents = useSelector(selectAllDailyContents);
  const stateInMenu = useSelector(selectSelectedMenuItem);
  const mindfullnessData = useSelector(selectAllMindfulness);
  const podCastsData = useSelector(selectPodCasts);
  const cards: ICardDto[] = useSelector(selectAllCards);
  const rightCards: IRightCardDto[] = useSelector(selectAllRightCards);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pageType, setPageType] = useState(NourishType.BETR_PROGRAM_MODE);
  const history = useHistory();
  const [openSideMenuBar, setOpenSideMenuBar] = useState(false);
  const betrProgramFilters: IBetrProgramFilterDto = useSelector(selectBetrProgramFilters);
  const [betrItemTitle, setBetrItemTitle] = useState<string>('');
  const params: any = useParams();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          dispatch(loadRecipes(recipeFilters)),
          dispatch(loadMealPlans(mealPlansFilters)),
          dispatch(loadCuisines()),
          dispatch(loadCourses()),
          dispatch(loadLevelCategories()),
          dispatch(loadIngredientsCategories()),
          dispatch(loadCategoriesWithIngredients()),
          dispatch(loadCategories()),
          dispatch(loadCards()),
          dispatch(loadDailyContentCategories()),
          dispatch(loadDailyContents(betrProgramFilters)),
          dispatch(loadMindfulness()),
          dispatch(loadRightCards()),
          dispatch(loadPodcast()),
          dispatch(loadYoga()),
        ]);
      } finally {
        setLoading(false);
      }
    };

    if (containsParamInHistory(history, '_slug')) {
      const historyParams = getHistoryParams(history);
      const typeSlug = historyParams['type-slug'];
      const enumType = typeSlug?.toString() as NourishType;
      if (!typeSlug) {
        setPageType(NourishType.RECIPES_MODE);
        return;
      }
      setPageType(enumType);
      return;
    } else if (containsParamInHistory(history, '_workoutgroupid')) {
      const historyParams = getHistoryParams(history);
      const typeSlug = historyParams['type-slug'];
      const enumType = typeSlug?.toString() as NourishType;
      if (!typeSlug) {
        setPageType(NourishType.RECIPES_MODE);
        return;
      }
      setPageType(enumType);
      return;
    } else if (params?.workoutGroupSlug) {
      setPageType(NourishType.MOVEMENT_MODE);
    } else if (history?.location?.pathname?.includes('movement')) {
      setPageType(NourishType.MOVEMENT_MODE);
    }
    // if(history?.location?.pathname === '/nourish'){
    //   setPageType(NourishType.BETR_PROGRAM_MODE);
    //     return;
    // }

    loadData();
  }, []);

  // useEffect(() => {
  //   // if (betrProgramFilters?.filter?.['daily_content_category.name']) {
  //   //   setBetrItemTitle(betrProgramFilters.filter['daily_content_category.name']);
  //   // }
  // }, []);

  const handleChangePageRecipes = async (_e: any, page: number) => {
    const newFilters: IRecipeFiltersDto = {
      ...recipeFilters,
      pagination: {...recipeFilters.pagination, _start: page},
    };
    await dispatch(loadRecipes(newFilters));
  };

  const handleChangePageDailyContents = async (_e: any, page: number) => {
    const newFilters: IBetrProgramFilterDto = {
      ...betrProgramFilters,
      pagination: {...betrProgramFilters.pagination, _start: page},
    };
    await dispatch(loadDailyContents(newFilters));
  };

  const mapToName = (items: any) => items.map((item: any) => item.name);
  const handleFilterRecipes = async (searchFilter: any) => {
    setPageType(NourishType.RECIPES_MODE);
    const newFilters: IRecipeFiltersDto = {
      ...recipeFilters,
      filter: {
        ['title_contains']: searchFilter.searchCriteria,
        ['level_category.name_in']: searchFilter.levelCategory
          ? searchFilter.levelCategory
          : undefined,
        ['cuisine_category.name_in']: mapToName(searchFilter.cuisines),
        ['course_category.name_in']: mapToName(searchFilter.courses),
        ['ingredient_categories.name_in']: mapToName(searchFilter.ingredients),
      },
    };

    try {
      setLoading(true);
      await dispatch(loadRecipes(newFilters));
    } finally {
      setLoading(false);
    }
    if (openSideMenuBar) {
      setOpenSideMenuBar(false);
    }
  };

  const handleFilterMealPlans = async (searchFilter: any) => {
    setPageType(NourishType.MEAL_PLANS_MODE);
    const newFilters: IMealPlansFiltersDto = {
      ...mealPlansFilters,
      filter: {
        ['name_contains']: searchFilter.searchCriteria,
        ['level_category.name_in']: searchFilter.levelCategory
          ? searchFilter.levelCategory
          : undefined,
        ['categories.name_in']: mapToName(searchFilter.categories),
      },
    };

    try {
      setLoading(true);
      await dispatch(loadMealPlans(newFilters));
      // eslint-disable-next-line no-useless-catch
    } catch (err) {
      // console.log(err);
      throw err;
    } finally {
      setLoading(false);
    }
    if (openSideMenuBar) {
      setOpenSideMenuBar(false);
    }
  };

  const handleFilterBetrProgram = async (filter: any) => {
    setPageType(NourishType.BETR_PROGRAM_MODE);
    const newFilters: IBetrProgramFilterDto = {
      ...betrProgramFilters,
      pagination: {
        _limit: DEFAULT_PER_PAGE_SIZE,
        _start: 0,
      },
      filter: {
        ['daily_content_category.name']: filter.dailyCategory.name,
        ['_sort']: 'tag',
      },
    };

    try {
      setLoading(true);
      setBetrItemTitle(filter.dailyCategory.name);
      await dispatch(loadDailyContents(newFilters));
      // eslint-disable-next-line no-useless-catch
    } catch (err) {
      // console.log(err);
      throw err;
    } finally {
      setLoading(false);
    }
    if (openSideMenuBar) {
      setOpenSideMenuBar(false);
    }
  };

  const handleFilterMovement = async (filter: any) => {
    setPageType(NourishType.MOVEMENT_MODE);
    // const newFilters: IBetrProgramFilterDto = {
    //   ...betrProgramFilters,
    //   pagination: {
    //     _limit: DEFAULT_PER_PAGE_SIZE,
    //     _start: 0,
    //   },
    //   filter: {
    //     ['daily_content_category.name']: filter.dailyCategory.name,
    //     ['_sort']: 'tag',
    //   },
    // };

    try {
      setLoading(true);
      setBetrItemTitle(filter.dailyCategory.name);
      // await dispatch(loadDailyContents(newFilters));
      // eslint-disable-next-line no-useless-catch
    } catch (err) {
      // console.log(err);
      throw err;
    } finally {
      setLoading(false);
    }
    if (openSideMenuBar) {
      setOpenSideMenuBar(false);
    }
  };

  const resolvePageType = (selectedType: SideMenuSelectedType) => {
    let nourishType: NourishType;

    switch (selectedType) {
      case SideMenuSelectedType.SIDEMENU_BETR_PROGRAM:
        nourishType = NourishType.BETR_PROGRAM_MODE;
        break;
      case SideMenuSelectedType.SIDEMENU_RECIPES:
        nourishType = NourishType.RECIPES_MODE;
        break;

      case SideMenuSelectedType.SIDEMENU_MEALPLANS:
        nourishType = NourishType.MEAL_PLANS_MODE;
        break;
      case SideMenuSelectedType.SIDEMENU_NOURISHMENT:
        nourishType = NourishType.NOURISHMENT_MODE;
        break;

      case SideMenuSelectedType.SIDEMENU_FOOD_LISTS:
        nourishType = NourishType.FOOD_LISTS_MODE;
        break;

      case SideMenuSelectedType.SIDEMENU_MASTER_CLASSES:
        nourishType = NourishType.MASTER_CLASSES_MODE;
        break;

      case SideMenuSelectedType.SIDEMENU_MOVEMENT:
        nourishType = NourishType.MOVEMENT_MODE;
        break;
      case SideMenuSelectedType.SIDEMENU_MINDFULLNESS:
        nourishType = NourishType.Mindfulness_Mode;
        break;
      case SideMenuSelectedType.SIDEMENU_PODCAST:
        nourishType = NourishType.PODCAST_MODE;
        break;
      default:
        nourishType = NourishType.BETR_PROGRAM_MODE;
    }

    setPageType(nourishType);
  };

  const handleCloseSelectedCategory = (name: any) => {
    if (name === 'NodataFound') {
      window.location.reload();
      history.push('/nourish');
      // resolvePageType(SideMenuSelectedType.SIDEMENU_BETR_PROGRAM);
    }
    setBetrItemTitle('');
  };

  const handleFilterMasterClasses = async (/*filter: any*/) => {
    setPageType(NourishType.MASTER_CLASSES_MODE);

    if (openSideMenuBar) {
      setOpenSideMenuBar(false);
    }
  };

  useEffect(() => {
    if (openSideMenuBar) {
      setOpenSideMenuBar(false);
    }
  }, [pageType]);

  const renderCenterView = () => {
    const shouldShowMealPlans =
      pageType === NourishType.MEAL_PLANS_MODE || pageType === NourishType.NOURISHMENT_MODE;

    const shouldShowRecipes =
      pageType === NourishType.RECIPES_MODE || pageType === NourishType.NOURISHMENT_MODE;

    const shouldShowBetrProgram = pageType === NourishType.BETR_PROGRAM_MODE;
    const shouldShowFoodList = pageType === NourishType.FOOD_LISTS_MODE;
    const shouldShowMasterClasses = pageType === NourishType.Mindfulness_Mode;
    const shouldShowMovement = pageType === NourishType.MOVEMENT_MODE;
    const shouldShowPodcast = pageType === NourishType.PODCAST_MODE;
    // const shouldShowMasterClasses =pageType === NourishType.MASTER_CLASSES_MODE;
    return isLoading ? (
      <Loader />
    ) : (
      <>
        {shouldShowFoodList && (
          <FoodListsView cardLists={cards.filter((card) => card.type === CardType.FOOD_LIST)} />
        )}
        {shouldShowMealPlans && (
          <MealPlansView
            smallScreens={smallScreens}
            isMobileOrTablet={isMobileOrTablet}
            mealPlans={mealPlans}
            mealPlansMode={pageType === NourishType.MEAL_PLANS_MODE}
          />
        )}
        {shouldShowRecipes && (
          <RecipesView
            recipes={recipes?.content}
            onChangePage={handleChangePageRecipes}
            count={recipes?.count}
            page={recipeFilters?.pagination._start}
            onRecipesFilterChange={handleFilterRecipes}
          />
        )}

        {shouldShowBetrProgram && (
          <DailyContentsView
            dailyContents={dailyContents?.content}
            title={betrItemTitle}
            onChangeFilter={handleFilterBetrProgram}
            onChangePage={handleChangePageDailyContents}
            count={dailyContents?.count}
            page={betrProgramFilters?.pagination._start}
            handleCloseSelectedCategory={handleCloseSelectedCategory}
          />
        )}
        {shouldShowPodcast && (
          <PodCastsCards
            // smallScreens={smallScreens}
            isMobileOrTablet={isMobileOrTablet}
            // title={betrItemTitle}
            cardLists={podCastsData?.content}
            page={betrProgramFilters?.pagination._start}
          />
        )}
        {/* {shouldShowMasterClasses && (
          <MasterCLassesView
            dailyContents={dailyContents?.content}
            title={betrItemTitle}
            onChangeFilter={handleFilterMasterClasses}
            onChangePage={handleChangePageDailyContents}
            count={dailyContents?.count}
            page={betrProgramFilters?.pagination._start}
          />
        )} */}
        {shouldShowMasterClasses && (
          <MindfulnessView
            dailyContents={mindfullnessData?.content}
            title={betrItemTitle}
            // onChangeFilter={handleFilterMasterClasses}
            // onChangePage={handleChangePageDailyContents}
            // count={dailyContents?.count}
            page={betrProgramFilters?.pagination._start}
          />
        )}
        {shouldShowMovement && <FitnessContent />}
      </>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        {smallScreens ? (
          <SideMenuBarMob openSideMenuBar={openSideMenuBar} setOpenSideMenuBar={setOpenSideMenuBar}>
            <SideMenuBarBase
              items={menuItems}
              onRecipesFilterChange={handleFilterRecipes}
              onMealPlansFilterChange={handleFilterMealPlans}
              onBetrProgramFilterChange={handleFilterBetrProgram}
              onMasterClassesFilterChange={handleFilterMasterClasses}
              onMovementFilterChange={handleFilterMovement}
              pageCallbackFunction={(selectedType: SideMenuSelectedType) => {
                resolvePageType(selectedType);
              }}
              initialStateInMenu={
                stateInMenu ? SideMenuSelectedType?.SIDEMENU_BETR_PROGRAM : stateInMenu
              }
            />
          </SideMenuBarMob>
        ) : (
          <SideMenuBarBase
            items={menuItems}
            onRecipesFilterChange={handleFilterRecipes}
            onMealPlansFilterChange={handleFilterMealPlans}
            onBetrProgramFilterChange={handleFilterBetrProgram}
            onMasterClassesFilterChange={handleFilterMasterClasses}
            onMovementFilterChange={handleFilterMovement}
            pageCallbackFunction={(selectedType: SideMenuSelectedType) =>
              resolvePageType(selectedType)
            }
            initialStateInMenu={
              stateInMenu ? SideMenuSelectedType?.SIDEMENU_BETR_PROGRAM : stateInMenu
            }
          />
        )}

        <div
          style={{marginTop: smallScreens ? 70 : 0, marginBottom: smallScreens ? 15 : 0}}
          className={classes.mainCenterContent}
        >
          {renderCenterView()}
        </div>
        {!smallScreens && (
          <div className={classes.notificationPanel}>
            {pageType !== NourishType.MOVEMENT_MODE &&
            pageType !== NourishType.MEAL_PLANS_MODE &&
            pageType !== NourishType.NOURISHMENT_MODE &&
            pageType !== NourishType.FOOD_LISTS_MODE &&
            pageType !== NourishType.RECIPES_MODE
              ? cards
                  .filter((_, index) => index < 2)
                  .map((card) => <NourishCard card={card} key={'nourish-card' + card.id} />)
              : null}
            {pageType === NourishType.MEAL_PLANS_MODE ||
            pageType === NourishType.NOURISHMENT_MODE ||
            pageType === NourishType.RECIPES_MODE ||
            pageType === NourishType.FOOD_LISTS_MODE
              ? rightCards?.map((card) => (
                  <NourishCard card={card} key={'nourish-card' + card.id} />
                ))
              : null}
            {pageType === NourishType.MOVEMENT_MODE &&
            !history?.location?.pathname?.includes('yoga') ? (
              <TotalChallengeScore />
            ) : null}
          </div>
        )}
      </div>
      {!smallScreens && <Footer />}
    </React.Fragment>
  );
};

export default NourishPage;
