import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {Typography} from '@material-ui/core';
import Loader from '../loader/Loader';
import {SummaryContent} from './SummaryContent';
import {Button} from '../button/Button';
import PlaceholderImage from '../../../assets/icons/placeholders/placeholder-cooking.svg';
import BackIcon from '../../../assets/icons/rest/left-arrow.svg';
import {loadDailyContent} from '../../../store/actions/nourish/nourish-actions';
import {selectDailyContent} from '../../../store/selectors/nourish.selectors';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {IDailyContentDto, ISectionDto} from '../../../dtos/IDailyContentDto';
import {EMPTY_FIELD_PLACEHOLDER} from '../../../utils/constants';

import useStyles from './daily-content-details-view.styles';

interface Props {
  slug: string;
  title: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  handleDismiss: () => void;
}

export const DailyContentDetailsView: React.FC<Props> = ({
  slug,
  title,
  isMobileOrTablet,
  smallScreens,
  handleDismiss,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const dispatch = useDispatch();
  const dailyContent: IDailyContentDto = useSelector(selectDailyContent);
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  //const componentRef = React.useRef(null);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        await dispatch(loadDailyContent(slug));
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const renderSection = (section: ISectionDto): React.ReactNode => {
    switch (section.headingType) {
      case 'Heading':
      case 'SubHeading':
        return (
          <div className={classes.wholeSection}>
            <Typography variant={'h6'}>{section.Title}</Typography>
            <ReactMarkdown
              components={{
                p: ({children}) => (
                  <p>
                    <Typography variant={'body1'}>{children}</Typography>
                  </p>
                ),
              }}
            >
              {section.content || EMPTY_FIELD_PLACEHOLDER}
            </ReactMarkdown>
          </div>
        );

      case 'Summary':
        return <SummaryContent section={section} smallScreens={smallScreens} />;

      default:
        return null;
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={classes.container}>
      {smallScreens && (
        <div className={classes.header}>
          <Button className={classes.back}  id="back" variant="text" onClick={() => handleDismiss()}>
            <img src={BackIcon} alt={`Back to ${title}`} />
            <Typography variant={'subtitle1'}>{title}</Typography>
          </Button>
        </div>
      )}
      {dailyContent ? (
        <div>
          <div
            className={classes.posterImage}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%), url(${
                dailyContent.mainImage.image.url || PlaceholderImage
              })`,
              width: '100%',
              height: 360,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Typography variant={'h3'} className={classes.titleColor}>
              {dailyContent.Title}
            </Typography>
          </div>

          <div className={classes.mainContainer} /*ref={componentRef}*/>
            <section className={classes.notesSection}>
              {dailyContent?.sections.map((section) => {
                return renderSection(section);
              })}
            </section>
          </div>
        </div>
      ) : (
        <Typography className={classes.noData}>{t('NourishPage.recipesDetails.noData')}</Typography>
      )}
    </div>
  );
};
