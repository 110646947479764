import * as React from 'react';
import {Fragment, useEffect, useRef, useState} from 'react';
import useStyles from './search-members.styles';
import {MemberGroupsType, SideMenuSelectedType} from '../../../utils/enums';
import {useTranslation} from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {ExpandIcon} from './ExpandIcon';
import {SideMenuBarItem} from './SideMenuBarItem';
import {ReactComponent as SearchMemberIcon} from '../../../assets/icons/side-menu-bar/customers-icon1.svg';
import {ReactComponent as SearchMemberInactiveIcon} from '../../../assets/icons/side-menu-bar/Search Memebers Inactive.svg';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {ExpandableContainer} from '../expandable-container/ExpandableContainer';
import {useDispatch, useSelector} from 'react-redux';
import {SearchBox} from '../search-box/SearchBox';
import {DEBOUNCE_TIME, MembershipType} from '../../../utils/constants';
import {Select} from '../select/Select';
import {Typography} from '@material-ui/core';
import {
  searchSupercoachUsers,
  storeSuperCoachFilteredUsers,
} from '../../../store/actions/users/users-action';
import {ConnectionsGroup} from './ConnectionsGroup';
import {selectSupercoachFilteredUsers} from '../../../store/selectors/users.selectors';
import {IMemberGroups} from '../../../models/IMemberGroups';
import {checkIfUserIsTrainer} from '../../../utils/users-managment';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import Loader from '../loader/Loader';
import {
  selectCoach,
  selectLoading,
  selectMember,
  selectPhases,
} from '../../../store/selectors/homepage.selectors';
import {SearchCoachmembers} from './SearchCoachMembers';
import {useDebounce} from '../../hooks/use-debounce';
import {FilterOptions} from './FilterOptions';
import {selectUserTags} from '../../../store/selectors/loggingPageSelector';
import {IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';
import {ICasualTag} from '../../../models/ITag';
import {dateTimeCombined} from '../../../utils/date-time-utils';

interface Props {
  onClick: (value: SideMenuSelectedType) => void;
  selectedSideMenuItem: SideMenuSelectedType;
}

export const SearchMembers: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [connectionsClicked, setConnectionsClicked] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const membershipConstants = Object.values(MembershipType);
  const filteredUsers = useSelector(selectSupercoachFilteredUsers);
  const selectedCoach = useSelector(selectCoach);
  const selectedMember = useSelector(selectMember);
  const [memberGroups, setMemberGroups] = useState<IMemberGroups[]>([]);
  const [isLoading, setLoading] = useState(false);
  const isLoadingRedux = useSelector(selectLoading);
  const [tagsList, setTagsList] = useState<IUserCategoryTagDto[]>([]);
  const loadedPhases = useSelector(selectPhases);
  const initialUserTagsList = useSelector(selectUserTags);
  const [phases, setPhases] = useState<any>([{key: 0, value: ''}]);
  const tagsBoxRef: any = useRef();
  const [filters, setFilters] = useState({
    searchCriteria: '',
    phaseObject: {
      key: 0,
      value: '',
    },
    date: null,
    filterCriteria: {
      key: 0,
      value: MembershipType.ALL_MEMBERS.toString(),
    },
  });
  const debouncedFilter = useDebounce<string>(filters.searchCriteria, DEBOUNCE_TIME);

  const getGroupedMembers = () => {
    const memberGroups = [];
    const coachesArr: IMemberGroups = {members: [], title: ''};
    coachesArr.members = filteredUsers.filter((user) => checkIfUserIsTrainer(user));
    coachesArr.title = MemberGroupsType.COACHES.toString().toUpperCase();
    const membersArr: IMemberGroups = {members: [], title: ''};
    membersArr.members = filteredUsers.filter(
      (user) => !checkIfUserIsTrainer(user) && user.hasCoaching,
    );
    membersArr.title = MemberGroupsType.MEMBERS.toString().toUpperCase();
    const noCoachingArr: IMemberGroups = {members: [], title: ''};
    noCoachingArr.members = filteredUsers.filter(
      (user) => !checkIfUserIsTrainer(user) && !user.hasCoaching,
    );
    noCoachingArr.title = MemberGroupsType.NO_COACHING.toString().toUpperCase();
    memberGroups.push(coachesArr, membersArr, noCoachingArr);
    setMemberGroups(memberGroups);
  };

  React.useEffect(() => {
    setLoading(true);
    getGroupedMembers();
    setLoading(false);
  }, [filteredUsers]);

  React.useEffect(() => {
    if (!selectedCoach && checkIfUserIsTrainer(selectedMember))
      dispatch(homepageActions.saveSelectedCoach(selectedMember));
  }, [selectedMember]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      initialUserTagsList.forEach((tags) => {
        tags.tags.forEach((tag) => {
          tag.isVisible = true;
        });
      });

      setPhases(loadedPhases);
      setTagsList(initialUserTagsList);
      setLoading(false);
    };
    loadData();
  }, [selectedMember, initialUserTagsList, loadedPhases]);

  const getMembershipFilter = (filterCriteria: string): string => {
    switch (filterCriteria) {
      case MembershipType.ALL_MEMBERS:
        return '';
      case MembershipType.COACHES:
        return 'Coaches';
      case MembershipType.MEMBERS_WITHOUT_COACHING:
        return 'MembersWithoutCoach';
      default:
        return '';
    }
  };
  const handleFilter = async (searchCriteria: string, filterCriteria: string) => {
    const selectedTags = tagsBoxRef?.current?.getSelectedTags().map((tag: ICasualTag) => tag.id);
    const formattedDate = filters.date ? dateTimeCombined(filters.date, filters.date) : null;

    const filterOptions = {
      searchFilter: searchCriteria,
      phaseId: filters.phaseObject.key === 0 ? undefined : filters.phaseObject.key,
      membershipFilter: getMembershipFilter(filterCriteria),
      phaseStartDate: formattedDate || undefined,
      tags: selectedTags,
    };
    if (searchCriteria?.length >= 3) {
      dispatch(searchSupercoachUsers(filterOptions));
    }
  };

  React.useEffect(() => {
    if (!filters.searchCriteria) {
      dispatch(storeSuperCoachFilteredUsers([]));
      return;
    }
    handleFilter(filters.searchCriteria, filters.filterCriteria.value);
  }, [debouncedFilter]);

  const handleChangeSearchValue = async (e: any) => {
    const searchValue = e.target.value;
    setFilters((prevState) => {
      return {
        ...prevState,
        searchCriteria: searchValue,
      };
    });
  };

  const changeSpecificDataType = (value: any, specificType: string) => {
    setFilters({
      ...filters,
      [specificType]: value,
    });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'phaseSelect') {
      setFilters({
        ...filters,
        ['phaseObject']: {key: value.key, value: value.value},
        [name]: value.key,
      });
    } else if (name === 'filterCriteria') {
      setFilters({
        ...filters,
        [name]: {key: value.key, value: value.value},
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const handleClear = () => {
    initialUserTagsList.forEach((oneCategoryTag) => {
      oneCategoryTag.tags.forEach((oneTag) => {
        oneTag.isVisible = true;
      });
    });
    setTagsList(initialUserTagsList);
    tagsBoxRef?.current?.clearSelectedTags();
    setFilters((prevState) => {
      return {
        ...prevState,
        phaseObject: {
          key: 0,
          value: '',
        },
        date: null,
        filterCriteria: {
          key: 0,
          value: '',
        },
      };
    });
    handleFilter('', '');
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <ExpandableContainer
          className={
            props.selectedSideMenuItem === SideMenuSelectedType.SIDEMENU_SEARCH_MEMBERS
              ? 'active'
              : ''
          }
        >
          <Accordion>
            <div
              onClick={() => {
                setConnectionsClicked(!connectionsClicked);
                props.onClick(
                  connectionsClicked
                    ? SideMenuSelectedType.SIDEMENU_NONE
                    : SideMenuSelectedType.SIDEMENU_SEARCH_MEMBERS,
                );
              }}
              onKeyDown={() => {
                setConnectionsClicked(!connectionsClicked);
                props.onClick(SideMenuSelectedType.SIDEMENU_SEARCH_MEMBERS);
              }}
              role="menuitem"
              tabIndex={-1}
            >
              <AccordionSummary expandIcon={<ExpandIcon clicked={connectionsClicked} />}>
                <SideMenuBarItem
                  Icon={connectionsClicked ? SearchMemberIcon : SearchMemberInactiveIcon}
                  elementType={SideMenuSelectedType.SIDEMENU_SEARCH_MEMBERS}
                  onClick={(elementType: any) => props.onClick(elementType)}
                  text={t('Homepage.sideMenuBar.searchMembers')}
                  selectedSideMenuItem={props.selectedSideMenuItem}
                />
              </AccordionSummary>
            </div>
            {selectedCoach ? (
              <SearchCoachmembers />
            ) : (
              <Fragment>
                <div className={classes.connectionsContent}>
                  <SearchBox
                    className="side-menu-bar-input"
                    name="searchCriteria"
                    placeholder={t('Homepage.sideMenuBar.connectionsDropDown.searchPlaceholder')}
                    containerClass={classes.connectionsSearch}
                    value={filters.searchCriteria}
                    onChange={handleChangeSearchValue}
                  />
                  <FilterOptions
                    filters={filters}
                    handleChange={handleChange}
                    handleFilter={() =>
                      handleFilter(filters.searchCriteria, filters.filterCriteria.value)
                    }
                    handleClear={handleClear}
                    tagsBoxRef={tagsBoxRef}
                    tagsList={tagsList}
                    changeSpecificDataType={changeSpecificDataType}
                    phases={phases}
                    additionalFilter={
                      <div>
                        <Typography variant={'caption'} className={classes.selectCaption}>
                          {t('Homepage.sideMenuBar.searchMembersDropDown.filterPlaceholder')}
                        </Typography>
                        <Select
                          name={'filterCriteria'}
                          value={filters.filterCriteria}
                          onChange={handleChange}
                          className={classes.filterSelect}
                          options={membershipConstants.map((item) => ({
                            key: item,
                            value: item.toString(),
                          }))}
                        />
                      </div>
                    }
                  />
                </div>
                <div className={classes.line} />
                {isLoadingRedux ? (
                  <Loader />
                ) : (
                  <AccordionDetails>
                    {memberGroups?.length > 0 &&
                      memberGroups.map((oneGroup) => {
                        return (
                          <ConnectionsGroup
                            key={oneGroup.title}
                            members={oneGroup.members}
                            users={filteredUsers}
                            title={oneGroup.title}
                            length={oneGroup?.members?.length}
                          />
                        );
                      })}
                  </AccordionDetails>
                )}
              </Fragment>
            )}
          </Accordion>
        </ExpandableContainer>
      )}
    </Fragment>
  );
};
