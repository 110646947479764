import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    boxShadow:
      '0px 2px 1px rgba(100, 101, 102, 0.2), 0px 1px 1px rgba(100, 101, 102, 0.14), 0px 1px 3px rgba(100, 101, 102, 0.12)',
    borderRadius: 12,
    background: theme.palette.background.paper,
    width: '100%',
    maxWidth: 302,
    height: 216,
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  },
  headline: {
    paddingTop: 16,
    textAlign: 'center',
  },
}));

export default useStyles;
