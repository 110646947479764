import React from 'react';
import "./error.css";
import { connect } from 'react-redux';
import { logOut } from '../../../store/actions/users/users-action';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  } 
 
  handleClick = () => {
    // Dispatch the action using this.props.dispatch
    this.props.dispatch(logOut());
    setTimeout(()=>{
      window.location.reload();
    },100);
  };
 
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.log(error);
    return { hasError: true };
  }
  
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can log the error or perform other actions here

    console.log('Error caught in ErrorBoundary:', error, errorInfo);
  }

  render() {
    const { hasError } = this.props;
    if (this.state.hasError || hasError) {
      // Render fallback UI
      return  ( 
         <div className="error-page">
         <Dialog
      id={'modal-widget'}
      open={this.state.hasError || hasError}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
     
    >
      <DialogContent style={{textAlign:"center", padding:10}}>
      <h1>Something went wrong.</h1>
      <p>
      Please refresh the page. If the issue persists, try to log out and log back into the app. 
      You can also contact us through the Ask Us button to let us know this is happening. 
      </p>
      <div style={{padding:20}}>
      <button style={{marginLeft: 10, backgroundColor:'#fc3737', color:"#ffffff"}}
        className="refresh-button"
         onClick={() => window.location.reload()}
      >
        Refresh
      </button>
      <button style={{marginLeft: 10, backgroundColor:'#000000', color:"#ffffff"}}
        className="refresh-button"
         onClick={this.handleClick}
      >
        Logout
      </button>
      </div>
        </DialogContent>
     
        {/* <div
          // className={classNames(classes.closeIconClass, closeIconClass)}
          // onClick={handleDismiss}
          onKeyDown={() => {}}
          role={'button'}
          tabIndex={0}
        >
          <CloseIcon title={'close-img-icon'} width={24} height={24} />
        </div> */}
     
    </Dialog>
       
     
     
    </div>);
    }
 

    return this.props.children;

  }


}
const mapStateToProps = (state: any) => {
  return {
    hasError: state?.users?.statusErrorData?.isError, // Replace 'state.myState' with the actual path to your desired state in Redux
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch, // Map the dispatch function to props
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary);
