/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import {Backdrop, Box, Modal, Fade, Button} from '@material-ui/core';
import useStyles from './sponsoredUserOnboarding.styles';
// import { Button as ButtonC } from '../../../common/components/button/Button';
import closeWhiteIcon from '../../../assets/images/close-white.svg';

const SponsoredAppointment: React.FC = ({activeScreen, handleScreens, data, userId}) => {
  const classes = useStyles();
  const [param, setParam] = useState('');

  useEffect(() => {
    if (activeScreen === 'duringSchedule') {
      setParam(
        `owner=11914050&appointmentType=${process.env.REACT_APP_ACUITY_SCHEDULING_APPOINTMENT_TYPE}&firstName=${data.firstName}&lastName=${data?.lastName}&email=${data?.email}&phone=${data?.phoneNumber}&field:6226265=${userId}&s=1`,
      );
    }
  }, [data]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={activeScreen}
      onClose={() => handleScreens('afterSchedule')}
      closeAfterTransition
      className={classes.modalAppointment}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Box className={classes.modalContainr}>
          <div className={classes.posterContainer}>
            {/* <div style={{
                padding: '47px',
                width: '850px',
                maxWidth: '100%',
                boxShadow: '0px 11px 16px rgba(137, 149, 163, 0.2), 0px 24px 40px rgba(137, 149, 163, 0.14), 0px 9px 48px rgba(137, 149, 163, 0.12)',
                borderRadius: '12px',
                position: 'relative'
            }}> */}
            <Button
              id="close-popup"
              onClick={() => handleScreens('afterSchedule')}
              className="closePopup"
            >
              <img src={closeWhiteIcon} alt="" />
            </Button>
            <iframe
              title="Appointment"
              style={{
                // width: 900,
                height: '100vh',
                border: 'none',
              }}
              className={classes.iframeContainer}
              data-hj-allow-iframe=""
              src={`https://app.acuityscheduling.com/schedule.php?${param}`}
            ></iframe>
            <script
              src="https://embed.acuityscheduling.com/js/embed.js"
              type="text/javascript"
            ></script>
            {/* <ButtonC
                    style={{
                        maxWidth: '350px',
                        margin: '0 auto',
                        display: 'block'
                    }}
                    id="continue"
                    onClick={() => handleScreens('afterSchedule')}
                    // loading={isLoading}
                    disabled={true}
                    className={classes.bottomButton}
                >Continue</ButtonC> */}
            {/* </div> */}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

SponsoredAppointment.displayName = 'SponsoredUserOnboarding Page';
export default SponsoredAppointment;
