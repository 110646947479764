import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallSize: boolean;
  isMobileDownload?: boolean;
}
// const useStyles = makeStyles<Theme, Props>((theme) => ({
const useStyles = makeStyles<Theme, Props>(() => ({
  sidebarIcon: {
    position: 'fixed',
    top: 10,
    // top: (props) => (props.isMobileDownload ? '288px' : '12px'),
    left: (props) => (props.isMobileOrTablet.isMobile ? '16px' : '32px'),
    zIndex: 1200,
    '& button': {
      background: 'transparent',
      border: 'none',
      padding: 0,
    },
  },
  sideMenuBar: {
    zIndex: 12200000001,
    position: 'fixed',
    top: 0,
    width: 0,
    bottom: 0,
    left: 0,
    // background: theme.palette.primary.dark,
    background: '#DDD6D6 !important',
    color: '#000000 !important',
    transition: 'width .5s',
    msTransitionTimingFunction: 'ease-in',
    '&.sideMenuBarOpen': {
      display: 'block',
    },
    '& button': {
      background: 'transparent',
      border: 'none',
      float: 'right',
      marginTop: 10,
      marginRight: 4,
      '& img': {
        filter: 'invert(1)',
      },
    },
  },
  sideMenuBarOpen: {
    width: '100vw',
    '& > button': {
      marginTop: (props) => (props.isMobileOrTablet.isMobile ? 10 : 25),
      color:(props) => (props.isMobileOrTablet.isMobile ? "#000000" : 'transparent'),
      marginRight: (props) =>
        props.isMobileOrTablet.isMobile ? 4 : props.isMobileOrTablet.isTabletSmall ? 27 : 87,
    },
  },
}));

export default useStyles;
