import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  movementChallengeArea:{

 
    '& .subtitle': {
     color:"#184C88",
     fontSize:16,
     fontWeight:600,
     margin:"0 0 24px 0",
     letterSpacing:0.1 ,
     lineHeight:"20px",
     '& span': {
      cursor: 'pointer'
     }
    },
    '& .headingh3':{
        color:"#140802",
        fontSize:36,
        fontWeight:600,
        margin:"0 0 24px 0",
        letterSpacing:0.25 ,
        lineHeight:"40px",
       
    },

  '& .totalScoreArae':{
    margin:"0 0 16px 0",
    display:"none",
  },


  ['@media (max-width:1280px)']: {

    '& .subtitle': {
      display:"none",      
     },

     '& .headingh3':{
      display:"none",
     },

     '& .totalScoreArae':{

      display:"block",
     },

  },

  ['@media (max-width:767px)']: {
  padding:"0 16px",
  '& .totalScoreArae':{
    margin:"0 0 24px 0",
  },
  },

},
  });

  export default useStyles;