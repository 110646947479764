import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router';
import {isMobile, isMobileOnly, isTablet, isSafari} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {Footer} from '../../../common/components/footer/Footer';
import {GeneralPaths, MOBILE_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';
import {getUrlForCommunity} from '../../../utils/community-helpers';
import {getTokenData} from '../../../utils/locale-storage-service';

const CommunityPage: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const tokenData = getTokenData();
  const token = tokenData.idToken;
  const relay = process.env.REACT_APP_RELAY_URI;
  const environment = process.env.REACT_APP_ENVIORMENT;
  // const relay =
  //   'https://betrhealth.xecurify.com/moas/idp/oidc/logout?post_logout_redirect_uri=https://betrhealth.xecurify.com/moas/broker/login/oauth/239901%3Fclient_id=J66n862-TUfLz_Y%26redirect_uri=https://member.betrhealth.com/oauth2/callback%26response_type=code%26scope=openid%26userName=circle.com';
  // 'https://betr-stg.xecurify.com/moas/idp/oidc/logout%3Fpost_logout_redirect_uri=https://betr-stg.xecurify.com/moas/broker/login/oauth/269239%253Fclient_id%3DXjFhcKcq6cq6Tik%2526redirect_uri%3Dhttps%3A%2F%2Fbetr-stage.circle.so%2Foauth2%2Fcallback%2526response_type%3Dcode%2526scope%3Dopenid%2526userName%3Dcircle.com'
  // 'https://betrhealth.xecurify.com/moas/idp/oidc/logout?post_logout_redirect_uri=https://betrhealth.xecurify.com/moas/broker/login/oauth/239901%3Fclient_id=J66n862-TUfLz_Y%26redirect_uri=https://member.betrhealth.com/oauth2/callback%26response_type=code%26scope=openid%26userName=circle.com';
  // const url = getUrlForCommunity();
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE - 1}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTablet: (isTablet && isTabletSize) || isTabletSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens = React.useMemo(() => {
    return isMobile || isTabletSize;
  }, [window.innerWidth]);

  useEffect(() => {
    const element = document.getElementById('getCommunity');
    if (element) {
      element.click();
    }
    if (isSafari) {
      const url = getUrlForCommunity();
      window.open(url, '_blank');
      history.push(GeneralPaths.HomePage);
    }
  }, []);

  const handleHeight = () => {
    if (isMobileOrTablet.isMobile) {
      return 'calc(100vh - 60px)';
    }

    if (isMobileOrTablet.isTablet) {
      return 'calc(100vh - 80px)';
    }

    return '150vh';
  };

  return (
    <React.Fragment>
      {isLoading ? <div>LOADING</div> : null}
      {/* <>
        <form
          id="contactForm1"
          action="https://store.xecurify.com/moas/broker/login/jwt/callback/20049/jwtsso"
          method="POST"
        >
          <input name="token" style={{display: 'none'}} type="text" value={token} />
          <input type="text" style={{display: 'none'}} name="relay" value={relay} />
          <input type="submit" style={{display: 'none'}} id='getCommunity' name="" value="" />
        </form>
      </> */}
      {/* {console.log("55", browserName,  isSafari)} */}
      <iframe
        title={'title'}
        onLoad={() => setLoading(false)}
        style={{
          border: 0,
          boxShadow: 'none',
          width: '100%',
          marginTop: smallScreens ? '0' : '-16px',
          height: handleHeight(),
          overflow: 'hidden',
          marginBottom: isSafari ? 0 : 55,
        }}
        name="myIframe"
      >
        <>
          <form
            id="contactForm1"
            action={`https://store.xecurify.com/moas/broker/login/jwt/callback/${
              environment === 'production' ? 20049 : 21389
            }/jwtsso`}
            method="POST"
            target="myIframe"
          >
            <input name="token" style={{display: 'none'}} type="text" value={token} />
            <input type="text" style={{display: 'none'}} name="relay" value={relay} />
            <input type="submit" style={{display: 'none'}} id="getCommunity" name="" value="" />
          </form>
        </>
      </iframe>
      {!smallScreens && <Footer />}
    </React.Fragment>
  );
};

CommunityPage.displayName = 'Community Page';
export default CommunityPage;
