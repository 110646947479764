import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// disable-next-line import/no-unresolved
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import './translation/i18n';
import {CookiesProvider} from 'react-cookie';

Sentry.init({
  dsn: 'https://a8d92fceb22a4a5ca10d5769a733288f@o1064003.ingest.sentry.io/4504917301592064',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: process.env.REACT_APP_ENVIORMENT ? process.env.REACT_APP_ENVIORMENT : 'localhost',
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
