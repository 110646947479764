import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  datePickerCont: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.blacksAndGrays.stoneGray,
      fontSize: 16,
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
      width: '100%',
    },
    '& .MuiOutlinedInput-adornedEnd ': {
      paddingRight: 0,
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-disabled.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd':
      {
        color: 'grey !important',
      },
  },
  wrapper: {
    marginRight: (props) => props.isMobileOrTablet ? 0 :'24px',
    width: (props) => props.isMobileOrTablet ? '100%' : '190px',
  },
  wrappers: {
    marginRight: (props) => props.isMobileOrTablet ? 0 :'10px',
    width: (props) => props.isMobileOrTablet ? '100%' : '190px',
  },
  helperText: {
    marginTop: 0,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    color: 'red',
  },
  labelText: {
    color: theme.palette.blacksAndGrays.davysGray,
    marginBottom: 4,
  },
  labelDisabled: {
    color: theme.palette.blacksAndGrays.silverSand,
    marginBottom: 4,
  },
  requiredText: {
    color: theme.palette.primary.light,
    marginBottom: 4,
  },
  labelContainer: {
    display: 'flex',
  },
}));
export default useStyles;
