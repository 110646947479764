import {makeStyles, Theme} from '@material-ui/core';
import {handlePadding} from '../../../utils/css-helpers';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    flexDirection: 'row',
    cursor: 'pointer',
    padding: (props) => handlePadding(props.isMobileOrTablet),
    '&:hover': {
      opacity: 0.6,
    },
  },
  itemText: {
    marginLeft: 12,
    alignItems: 'center',
    color: (props) =>
      props.smallSize ? theme.palette.secondary.main : theme.palette.secondary.dark,
  },
  itemIcon: {
    width: 38,
    height: 38,
    borderRadius: '50%',
  },
}));
export default useStyles;
