import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  boxTags: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.blacksAndGrays.gainsboro}`,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: 8,
    paddingTop: 0,
    minHeight: 40,
  },
  tagsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
