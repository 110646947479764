import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));
export default useStyles;
