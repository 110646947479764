import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  emojiTagContainer: {
    display: (props) => (props.isMobile ? 'block' : 'flex'),
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  info: {
    display: (props) => (props.isMobile ? 'block' : 'flex'),
    '& > span': {
      marginTop: (props) => (props.isMobile ? 0 : 5),
    },
  },
  dateContent: {
    color: theme.palette.blacksAndWhites.sonicSilver,
    width: 85,
    marginTop: (props) => (props.isMobile ? 0 : 5),
  },
  intensityContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    width: 150,
    marginRight: 16,
    marginTop: 8,
  },
  textContent: {
    color: theme.palette.secondary.dark,
    width: '60px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '200px',
    justifyContent: 'flex-start',
  },
  marginTop: {
    marginTop: 16,
  },
}));

export default useStyles;
