import {combineReducers} from 'redux';
import usersReducer from './usersReducer';
import homepageReducer from './homepageReducer';
import loggingPageReducer from './loggingPageReducer';
import nourishReducer from './nourishReducer';
import filterReducers from './filterReducers';
import kemtaiReducers from './kemtaiReducers';

const rootReducer = combineReducers({
  users: usersReducer,
  home: homepageReducer,
  logging: loggingPageReducer,
  nourish: nourishReducer,
  filters: filterReducers,
  kemtai: kemtaiReducers,
});

export default rootReducer;
