/* eslint-disable */
// @ts-ignore
import {stringify, parse} from 'qs';

export const getQuery = (params: any) => stringify(params, {allowDots: true, addQueryPrefix: true});
export const getHistoryParams = (history: any) =>
  parse(history.location.search, {ignoreQueryPrefix: true});

export const containsParamInHistory = (history: any, param: string) =>
  param in getHistoryParams(history);
