import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import moment from 'moment';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {LoggingWidgetType, SideMenuLoggingSelectedType} from '../../../utils/enums';
import * as loggingActions from '../../../store/actions/logging-page/logging-page-action';
import Loader from '../../../common/components/loader/Loader';
import {addTime} from '../../../utils/date-time-utils';
import {SideMenuLoggingBar} from '../../../common/components/side-menu-bar/SideMenuBarLogging';
import {MOBILE_SIZE, TABLET_SMALL_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';
import {Footer} from '../../../common/components/footer/Footer';
import LoggingWidget from '../../../common/components/logging-widgets/LoggingWidget';
import {SideMenuBarMob} from '../../../common/components/side-menu-bar/SideMenuBarMob';

import useStyles from './logs.styles';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';


const LogsPage: React.FC = () => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});

  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet,
  });
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation().search;
  const currentUser = useSelector(selectCurrentUser);
  const widgetParam = new URLSearchParams(location).get('widget');
  //refs for meni bar
  const bodyWeightRef: any = useRef(null);
  const foodRef: any = useRef(null);
  const activityRef: any = useRef(null);
  const mentalHealthRef: any = useRef(null);
  const waterRef: any = useRef(null);
  const sleepRef: any = useRef(null);
  const bowelMovementRef: any = useRef(null);
  const stepsRef: any = useRef(null);
  const bloodPressureRef: any = useRef(null);
  const bodyMeasurementsRef: any = useRef(null);
  const bloodGlucoseRef: any = useRef(null);
  const bodyPainRef: any = useRef(null);
  const bloodLabsRef: any = useRef(null);
  const [openSideMenuBar, setOpenSideMenuBar] = useState(false);

  const executeScroll = (sideMenuItem: SideMenuLoggingSelectedType | string) => {
    switch (sideMenuItem) {
      case SideMenuLoggingSelectedType.SIDE_MENU_BODY_WEIGHT:
        bodyWeightRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_FOOD:
        foodRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_ACTIVITY:
        activityRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_MENTAL_HEALTH:
        mentalHealthRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_WATER:
        waterRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_SLEEP:
        sleepRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_BOWEL_MOVEMENT:
        bowelMovementRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_STEPS:
        stepsRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_BLOOD_PRESSURE:
        bloodPressureRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_BODY_MEASUREMENTS:
        bodyMeasurementsRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_BLOOD_GLUCOSE:
        bloodGlucoseRef?.current?.scrollIntoView();
        break;
      case SideMenuLoggingSelectedType.SIDE_MENU_BODY_PAIN:
        bodyPainRef?.current?.scrollIntoView();
        break;

      case SideMenuLoggingSelectedType.SIDE_MENU_BLOOD_LABS:
        bloodLabsRef?.current?.scrollIntoView();
        break;

      default:
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const fromTime = addTime(moment(), -2, 'days').utc().format();

        await Promise.all([
          dispatch(loggingActions.loadAllTagsWithCategories()),
          dispatch(loggingActions.loadActivityTags()),
          dispatch(loggingActions.loadStressTags()),
          dispatch(loggingActions.loadMoodTags()),
          dispatch(loggingActions.loadSleepTags()),
          dispatch(loggingActions.loadBowelTags()),
        ]);

        dispatch(loggingActions.loadWeightLogs(currentUser?.id, fromTime));
        dispatch(loggingActions.loadFoodLogs(fromTime));
        dispatch(loggingActions.loadActivityLogs(fromTime));
        dispatch(loggingActions.loadStressLogs(fromTime));
        dispatch(loggingActions.loadDrinkLogs(fromTime));
        dispatch(loggingActions.loadSleepLogs(fromTime));
        dispatch(loggingActions.loadBowelLogs(fromTime));
        dispatch(loggingActions.loadStepsLogs(fromTime));
        dispatch(loggingActions.loadBodyMeasurementLogs());
        dispatch(loggingActions.loadBloodPressureLogs());
        dispatch(loggingActions.loadBloodGlucoseLogs(fromTime));
        dispatch(loggingActions.loadBodyPainLogs());
        dispatch(loggingActions.loadCholesterolLogs());
        dispatch(loggingActions.loadA1CLogs());
      } finally {
        setIsLoading(false);
      }
    };

    loadData();

    if (widgetParam) {
      executeScroll(SideMenuLoggingSelectedType.SIDE_MENU_BODY_WEIGHT);
    }
    // executeScroll(widgetParam);
    // } else executeScroll(SideMenuLoggingSelectedType.SIDE_MENU_BODY_WEIGHT);
  }, [currentUser]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.mainContainer}>
          {smallScreens ? (
            <SideMenuBarMob
              openSideMenuBar={openSideMenuBar}
              setOpenSideMenuBar={setOpenSideMenuBar}
            >
              <SideMenuLoggingBar
                executeScrollOnElement={(sideMenuItem: SideMenuLoggingSelectedType) => {
                  setOpenSideMenuBar(false);
                  executeScroll(sideMenuItem);
                }}
              />
            </SideMenuBarMob>
          ) : (
            <SideMenuLoggingBar
              executeScrollOnElement={(sideMenuItem: SideMenuLoggingSelectedType) =>
                executeScroll(sideMenuItem)
              }
            />
          )}

          <div style={{marginTop: smallScreens ? 45 : 0, marginBottom:smallScreens ? 15 : 0 }} className={classes.mainCenterContent}>
            <div ref={bodyWeightRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.BODY_WEIGHT_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={foodRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.FOOD_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={activityRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.ACTIVITY_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={mentalHealthRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.MENTAL_HEALTH_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={waterRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.WATER_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={sleepRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.SLEEP_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={bowelMovementRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.BOWEL_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={stepsRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.STEPS_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={bloodPressureRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.BLOOD_PRESSURE_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={bodyMeasurementsRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.BODY_MEASUREMENT_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={bloodGlucoseRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.BLOOD_GLUCOSE_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={bodyPainRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.BODY_PAIN_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>

            <div ref={bloodLabsRef} className={classes.widgetContainer}>
              <LoggingWidget
                loggingWidgetType={LoggingWidgetType.BLOOD_LABS_WIDGET}
                isMobile={isMobileOrTablet.isMobile}
              />
            </div>
          </div>
          {!smallScreens && <div className={classes.notificationPanel} />}
        </div>
      )}
      <Footer smallScreens={smallScreens} />
    </React.Fragment>
  );
};

export default LogsPage;
