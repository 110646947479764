import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogTitle-root': {
      padding: 24,
      paddingBottom: 16,
    },
    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.blacksAndWhites.sonicSilver,
    },
    '& .MuiDialogActions-root': {
      paddingBottom: 24,
      paddingRight: 24,
    },
  },
  cancelButton: {
    color: theme.palette.secondary.dark,
  },
  deleteButton: {
    color: theme.palette.uicolors.fireOpal,
  },
}));

export default useStyles;
