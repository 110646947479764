import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.blacksAndWhites.smokyBlack,
  },
  text: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginTop: 24,
    marginBottom: 40,
  },
  select: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        width: 262,
      },
    },
  },
  continue: {
    marginTop: 100,
    height: 48,
  },
}));

export default useStyles;
