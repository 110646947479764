import * as React from 'react';
import {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isTablet, isMobileOnly} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {SideMenuSelectedType} from '../../../utils/enums';
import {
  selectCoach,
  selectCoachConversations,
  selectConversations,
  selectLoading,
  selectPhases,
} from '../../../store/selectors/homepage.selectors';
import {SearchBox} from '../search-box/SearchBox';
import {selectUserTags} from '../../../store/selectors/loggingPageSelector';
import {ICasualTag} from '../../../models/ITag';
import {IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';
import {dateTimeCombined} from '../../../utils/date-time-utils';
import Loader from '../loader/Loader';
import {FilterOptions} from './FilterOptions';
import {ConnectionsGroup} from './ConnectionsGroup';
import ClientsSwitch from './ClientsSwitch';
import {
  loadCoachConversations,
  saveSelectedCoach,
} from '../../../store/actions/homepage/homepage-action';
import {selectCoachUsers} from '../../../store/selectors/users.selectors';
import {searchCoachUsers, storeCoachMembers} from '../../../store/actions/users/users-action';
import {SelectedCoachItem} from './SelectedCoachItem';
import {IUserSearch} from '../../../models/IUserSearch';
import {useDebounce} from '../../hooks/use-debounce';
import {
  DEBOUNCE_TIME,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';

import useStyles from './connections.styles';

interface Props {
  onClick?: (value: SideMenuSelectedType) => void;
  selectedSideMenuItem?: SideMenuSelectedType;
}

export const SearchCoachmembers: React.FC<Props> = () => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  const [t] = useTranslation();
  const selectedCoach = useSelector(selectCoach);
  const loadedPhases = useSelector(selectPhases);
  const [phases, setPhases] = useState<any>([{key: 0, value: ''}]);
  const conversations = useSelector((state) => selectConversations(state));
  const coachConversations = useSelector(selectCoachConversations);
  const coachMembers = useSelector(selectCoachUsers);
  const [tagsList, setTagsList] = useState<IUserCategoryTagDto[]>([]);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isLoadingRedux = useSelector(selectLoading);
  const [secondaryTrainees, setSecondaryTrainees] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(true);
  const [filters, setFilters] = useState({
    phaseObject: {
      key: 0,
      value: '',
    },
    date: null,
    searchCriteria: '',
  });
  const debouncedFilter = useDebounce<string>(filters.searchCriteria, DEBOUNCE_TIME);

  const initialUserTagsList = useSelector(selectUserTags);
  const tagsBoxRef: any = useRef();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setSwitchLoading(true);
      await dispatch(loadCoachConversations(selectedCoach.id));
      await dispatch(searchCoachUsers({trainerId: selectedCoach.id}));

      initialUserTagsList.forEach((tags) => {
        tags.tags.forEach((tag) => {
          tag.isVisible = true;
        });
      });

      setPhases(loadedPhases);
      setTagsList(initialUserTagsList);
      setLoading(false);
      setSwitchLoading(false);
    };
    loadData();
  }, [conversations, initialUserTagsList, loadedPhases]);

  const handleFilter = async (searchValue: any, includeSecondary?: boolean, obj?: any) => {
    const selectedTags = tagsBoxRef?.current?.getSelectedTags().map((tag: ICasualTag) => tag.id);
    const formattedDate = filters.date ? dateTimeCombined(filters.date, filters.date) : undefined;
    const filterOptions: IUserSearch = {
      searchFilter: searchValue,
      phaseId: filters.phaseObject.key === 0 ? undefined : filters.phaseObject.key,
      phaseStartDate: formattedDate,
      tags: selectedTags,
      trainerId: selectedCoach.id,
      includeSecondaryTrainees:
        includeSecondary != undefined ? includeSecondary : secondaryTrainees,
    };
    if (searchValue?.length >= 3) {
      setSwitchLoading(true);
      if (obj) {
        obj.searchFilter = searchValue;
        dispatch(searchCoachUsers(obj));
      } else {
        dispatch(searchCoachUsers(filterOptions));
      }
      setSwitchLoading(false);
    }
  };

  useEffect(() => {
    if (!filters.searchCriteria) {
      dispatch(storeCoachMembers([]));
      return;
    }
    handleFilter(filters.searchCriteria);
  }, [debouncedFilter]);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'phaseSelect') {
      setFilters({
        ...filters,
        ['phaseObject']: {key: value.key, value: value.value},
        [name]: value.key,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };
  const changeSpecificDataType = (value: any, specificType: string) => {
    setFilters({
      ...filters,
      [specificType]: value,
    });
  };

  const handleChangeSearchValue = async (e: any) => {
    const searchValue = e.target.value;
    setFilters((prevState) => {
      return {
        ...prevState,
        searchCriteria: searchValue,
      };
    });
  };

  const handleBack = () => {
    dispatch(saveSelectedCoach(null));
    dispatch(storeCoachMembers([]));
  };

  const handleClear = () => {
    initialUserTagsList.forEach((oneCategoryTag) => {
      oneCategoryTag.tags.forEach((oneTag) => {
        oneTag.isVisible = true;
      });
    });
    setTagsList(initialUserTagsList);
    tagsBoxRef?.current?.clearSelectedTags();
    setFilters((prevState) => {
      return {
        ...prevState,
        phaseObject: {
          key: 0,
          value: '',
        },
        date: null,
      };
    });
    handleFilter(filters.searchCriteria, secondaryTrainees, {
      phaseObject: {
        key: 0,
        value: '',
      },
      date: null,
      tags: [],
      trainerId: selectedCoach.id,
    });
  };

  const handleSwitch = (value: string) => {
    setSecondaryTrainees(value === 'Secondary');
    handleFilter('', value === 'Secondary');
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Typography component="div" className={classes.backToSearch} onClick={() => handleBack()}>
            {t('Homepage.sideMenuBar.searchMembersDropDown.backToSearch')}
          </Typography>
          <Typography variant="caption" className={classes.selectedUser}>
            {t('Homepage.sideMenuBar.searchMembersDropDown.selectedUser')}
          </Typography>
          <SelectedCoachItem coach={selectedCoach} />
          <div className={classes.connectionsContent}>
            <SearchBox
              name="searchCriteria"
              placeholder={t('Homepage.sideMenuBar.connectionsDropDown.searchPlaceholder')}
              containerClass={classes.connectionsSearch}
              value={filters.searchCriteria}
              onChange={handleChangeSearchValue}
            />
            <ClientsSwitch
              title={t('Homepage.sideMenuBar.connectionsDropDown.clientsSwitchTitle')}
              label1={t('Homepage.sideMenuBar.connectionsDropDown.label1')}
              label2={t('Homepage.sideMenuBar.connectionsDropDown.label2')}
              setPrimary={(value: string) => handleSwitch(value)}
              disabled={switchLoading || isLoadingRedux}
            />
            <FilterOptions
              filters={filters}
              handleChange={handleChange}
              handleFilter={handleFilter}
              handleClear={handleClear}
              tagsBoxRef={tagsBoxRef}
              tagsList={tagsList}
              changeSpecificDataType={changeSpecificDataType}
              phases={phases}
            />
          </div>
          <div className={classes.line} />
          {isLoadingRedux ? (
            <Loader />
          ) : (
            <AccordionDetails>
              {coachMembers?.length === 0 ? (
                <div className={classes.noData}>
                  <Typography>
                    {t('Homepage.sideMenuBar.connectionsDropDown.noDataSuperCoach')}
                  </Typography>
                </div>
              ) : (
                coachConversations?.length > 0 &&
                coachConversations.map((oneConversationGroup) => {
                  return (
                    <ConnectionsGroup
                      key={oneConversationGroup.title}
                      coachConnections={oneConversationGroup}
                      users={coachMembers}
                      title={oneConversationGroup.title}
                    />
                  );
                })
              )}
            </AccordionDetails>
          )}
        </div>
      )}
    </Fragment>
  );
};
