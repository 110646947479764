import {Button} from '../../../common/components/button/Button';
import useStyles from './Eligibility.styles';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import AskUsIcon from '../../../assets/icons/AskUs.svg';
import React from 'react';
// import { useHistory } from 'react-router';


interface props {
    smallScreens?: boolean;
    setIsErrorPage:any;
  }
const EligibilityError: React.FC<props> = ({smallScreens, setIsErrorPage}) => {
  const classes = useStyles();
//   const history = useHistory();
  return (
    <>
      <div className={classes.posterContainer}>
        <div>
          <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
        </div>
        <h2
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: 22,
            textAlign: 'center',
            width: smallScreens ? 
             "auto" : '25%',
            lineHeight: 1.3,
          }}
        >
          Something seems to be wrong with your eligibility...
        </h2>
        <div  style={{width: '85%', overflow:'auto', maxWidth: 410, minWidth:180}}>
          <p style={{fontSize: 16, textAlign: 'center', lineHeight: 1.5}}>
            Click the Ask Us button on the bottom right of your screen, and our team will be happy
            to troubleshoot this issue.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',

              textAlign: 'center',
            }}
          >
            <img src={AskUsIcon} alt="better health icon" width={110} height={110} />
          </div>

          <Button
            id="tryAgain"
           onClick={() => setIsErrorPage(false)}
            //   loading={isLoading}
            //   disabled={validateToSubmit()}
            className={classes.bottomButton}
            //  style={{marginTop: 10}}
          >
            Try Again
          </Button>
        </div>
      </div>
    </>
  );
};

export default EligibilityError;
