import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography} from '@material-ui/core';
import {ContentDialog} from '../dialog/ContentDialog';

import useStyles from './terms-and-policy.styles';
import {Button} from '../button/Button';

interface ITermsAndPolicyProps {
  handleDismiss: () => void;
  dialogState: boolean;
}

export const TermsAndPolicy: React.FC<ITermsAndPolicyProps> = (props) => {
  const {handleDismiss, dialogState} = props;
  const classes = useStyles();
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    handleDismiss();
  };

  const handleTermsAndPolicy = () => {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Box mb={3}>
            <Typography variant="h6">{t('baseLayout.termsAndPolicy.header')}</Typography>
          </Box>
        </div>
        <Typography variant={'body1'} className={classes.content} component="div">
          {t('baseLayout.termsAndPolicy.firstPart')}{' '}
          <a
            href="https://betrhealth.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('baseLayout.termsAndPolicy.termsAndConditions')}{' '}
          </a>{' '}
          {t('baseLayout.termsAndPolicy.and')}{' '}
          <a
            href="https://betrhealth.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('baseLayout.termsAndPolicy.privacyPolicy')}
          </a>
          . {t('baseLayout.termsAndPolicy.secondPart')}
        </Typography>
        <Button
          id="submit-food-terms-and-policy-id"
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading}
          className={classes.button}
        >
          {t('baseLayout.termsAndPolicy.button')}
        </Button>
      </div>
    );
  };

  return (
    <ContentDialog
      className={classes.root}
      content={handleTermsAndPolicy()}
      handleDismiss={() => handleDismiss()}
      dialogState={dialogState}
      isCloseButtonHidden={true}
    />
  );
};
