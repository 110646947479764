export const SAVE_RECIPES = 'SAVE_RECIPES';
export const SAVE_YOGA = 'SAVE_YOGA';
export const SAVE_RECIPES_COUNT = 'SAVE_RECIPES_COUNT';
export const SAVE_RECIPE = 'SAVE_RECIPE';
export const SAVE_RECIPE_FILTERS = 'SAVE_RECIPE_FILTERS';
export const SAVE_RECIPES_TAGS = 'SAVE_RECIPES_TAGS';
export const SAVE_MEAL_PLANS_TAGS = 'SAVE_MEAL_PLANS_TAGS';
export const LOAD_CUISINES = 'LOAD_CUISINES';

export const LOAD_COURSES = 'LOAD_COURSES';
export const LOAD_MINDFULNESS ='LOAD_MINDFULNESS';
export const LOAD_PODCAST ='LOAD_PODCAST';
export const LOAD_YOGA ='LOAD_YOGA';
export const LOAD_LEVEL_CATEGORIES = 'LOAD_LEVEL_CATEGORIES';

export const LOAD_INGREDIENTS_CATEGORIES = 'LOAD_INGREDIENTS_CATEGORIES';
export const LOAD_CATEGORIES_WITH_INGREDIENTS = 'LOAD_CATEGORIES_WITH_INGREDIENTS';

export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';

export const SAVE_MEAL_PLANS = 'SAVE_MEAL_PLANS';
export const SAVE_MEAL_PLAN = 'SAVE_MEAL_PLAN';
export const SAVE_MEAL_PLANS_FILTERS = 'SAVE_MEAL_PLANS_FILTERS';

export const SAVE_SELECETED_MENU_ITEM = 'SAVE_SELECETED_MENU_ITEM';
export const SAVE_SELECETED_RECIPES_FILTERS = 'SAVE_SELECETED_RECIPES_FILTERS';
export const SAVE_SELECTABLE_INGREDIENTS = 'SAVE_SELECTABLE_INGREDIENTS';
export const SAVE_SELECTED_MEAL_PLANS_FILTERS = 'SAVE_SELECTED_MEAL_PLANS_FILTERS';

export const LOAD_CARDS = 'LOAD_CARDS';
export const LOAD_RIGHT_CARDS = 'LOAD_RIGHT_CARDS';

export const LOAD_DAILY_CONTENT_CATEGORIES = 'LOAD_DAILY_CONTENT_CATEGORIES';
export const LOAD_DAILY_CONTENTS = 'LOAD_DAILY_CONTENTS';
export const LOAD_DAILY_CONTENT = 'LOAD_DAILY_CONTENT';
export const SAVE_BETR_PROGRAM_FILTERS = 'SAVE_BETR_PROGRAM_FILTERS';
export const SAVE_DAILY_CONTENTS_COUNT = 'SAVE_DAILY_CONTENTS_COUNT';
export const REHAB_ENABLED = 'REHAB_ENABLED';
export const MOVEMENT_ENABLED = 'MOVEMENT_ENABLED';
export const APPOINTMENT_ENABLED = 'APPOINTMENT_ENABLED';
