import {Box, Paper, Typography} from '@material-ui/core';
import React from 'react';
import {IMealPlanComponentDetails} from '../../../dtos/IMealPlanComponentDetails';
import useStyles from './meal-plan-advisory-component.styles';
import WaterIcon from '../../../assets/icons/nourishment/water.svg';
import ActivityIcon from '../../../assets/icons/nourishment/activity.svg';
import {MealPlanAvisoryComponentType} from '../../../utils/enums';
import ReactMarkdown from 'react-markdown';
interface Props {
  detail: IMealPlanComponentDetails;
}
export const MealPlanAdvisoryComponent: React.FC<Props> = ({detail}) => {
  const classes = useStyles();

  const icon = detail.name === MealPlanAvisoryComponentType.ACTIVITY ? ActivityIcon : WaterIcon;

  const contentPartOne = detail?.body?.split('\n')[0];
  const contentPartTwo = detail?.body?.split('\n')[2];

  return (
    <Paper className={classes.card}>
      <Box mb={1} component={'div'} className={classes.header}>
        <img src={icon} alt={'water-icon'} className={classes.icon} />
        <Typography variant={'subtitle1'} align="left">
          {detail?.name}
        </Typography>
      </Box>
      <ReactMarkdown
        components={{
          p: ({children}) => <Typography variant="body2">{children}</Typography>,
        }}
      >
        {contentPartOne || ''}
      </ReactMarkdown>
      <ReactMarkdown
        components={{
          p: ({children}) => <Typography variant="caption">{children}</Typography>,
        }}
      >
        {contentPartTwo || ''}
      </ReactMarkdown>
    </Paper>
  );
};
