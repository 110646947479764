import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  intensityContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginRight: 24,
    width: '65px',
    marginTop: (props) => (props.isMobile ? 3 : 0),
  },
  textContent: {
    color: theme.palette.secondary.dark,
    width: '61px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: (props) => (props.isMobile ? 0 : 12),
    maxWidth: '185px',
    justifyContent: 'flex-start',
    '& > div': {
      marginTop: (props) => (props.isMobile ? 8 : 0),
      marginBottom: (props) => (props.isMobile ? 0 : 8),
    },
  },
}));

export default useStyles;
