import React, {useEffect, useState} from 'react';
// import {Select} from '../../select/Select';
// import {ValidationType} from '../../../../validation/ValidationType';
// import {
//   // getError,
//   // hasError,
//   // isFormValidToSubmit,
//   // validate,
//   validateField,
// } from '../../../../validation/Validation';
// import classNames from 'classnames';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
// import {ChipTag} from '../../chip-tag/ChipTag';
import useStyles from './food-form.styles';
import {useDispatch, useSelector} from 'react-redux';
import {ITagDto} from '../../../../dtos/ITagDto';
import {ICategoryTagDto} from '../../../../dtos/ICategoryTagDto';
import {ITag} from '../../../../models/ITag';
import {Typography} from '@material-ui/core';
import {FormType, WidgetType} from '../../../../utils/enums';
import {IFoodDto} from '../../../../dtos/IFoodDto';
import ImageUpload from '../../image-upload/ImageUpload';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
// import {MealType} from '../../../../utils/constants';
// import {MealType, levelOne, levelTwo, levelThree} from '../../../../utils/constants';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {Button} from '../../button/Button';
// import {Checkbox} from '../../checkbox/Checkbox';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {getSourceOfFood} from '../../../../api/food.api';
import {
  addFoodLogg,
  checkAchievement,
  editFoodLogg,
  loadAllTagsWithCategoriesFiltered,
} from '../../../../store/actions/logging-page/logging-page-action';
import FoodIcon from '../../../../assets/icons/side-menu-bar-logging/food-icon.svg';
// import {SearchBox} from '../../search-box/SearchBox';
// import {splitCamelCase} from '../../../../utils/StringHelpers';
import {IKeyValuePair} from '../../../../models/IKeyValuePair';
import ImageUploadIcon from '../../../../assets/icons/widgets/upload-image-icon.svg';
import ImageUploadIconMobile from '../../../../assets/icons/widgets/upload-image-icon-mobile.svg';
import * as homepageActions from '../../../../store/actions/homepage/homepage-action';
import CategoryTagListModal from './CategoryTagListModal';
import useCommonStyles from '../common-for-widgets.styles';
// import DateTimePicker from './DateTimePicker';

interface IFoodFormData {
  typeOfMealObject: IKeyValuePair;
  typeOfMeal?: string;
  sourceOfFoodObject: IKeyValuePair;
  sourceOfFood?: number;
  date: string;
  time: string;
  img: any;
  tags: ITag[];
  skippedMeal: boolean;
}
interface IFoodFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: IFoodDto | null;
  executeScroll?: any;
  executeTableScroll?: any;
  openedFromDialog?: boolean;
  date?: string;
  isMobile: boolean;
}

// const validationRules = {
//   typeOfMeal: [{type: ValidationType.REQUIRED}],
//   date: [{type: ValidationType.REQUIRED}],
//   time: [{type: ValidationType.REQUIRED_DURATION}],
// };

const FoodForm: React.FC<IFoodFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const currentUser = useSelector(selectCurrentUser);
  const [t] = useTranslation();
  const [errors, setErrors] = useState<any>({});
  const [categoryTagsList, setCategoryList] = useState<ICategoryTagDto[]>([]);
  const [selectedTagsList, setSelectedTagsList] = useState<ITag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [loadedSource, setLoadedSource] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [modal, setModal] = useState(false);

  // const mealTypes = Object.values(MealType);

  const formType = props.formType || FormType.ADDING_MODE;
  const [data, setData] = useState<IFoodFormData>({
    typeOfMealObject: {
      key: '',
      value: '',
    },
    sourceOfFoodObject: {
      key: 0,
      value: '',
    },
    img: null,
    date: moment().toString(),
    time: moment().toString(),
    skippedMeal: false,
    tags: [],
  });

  const filterCategoryTagList = (response: ICategoryTagDto[], selectedTagsList: ITag[]) => {
    selectedTagsList.forEach((tag: ITag) => {
      const selectedCategory: ICategoryTagDto | undefined = response.find((oneCategory) =>
        oneCategory.tags.some((oneTag) => oneTag.id === tag.id),
      );

      const selectedTag: ITagDto | undefined = selectedCategory?.tags.find(
        (oneTag) => oneTag.id == tag.id,
      );

      if (selectedTag) {
        selectedTag.isVisible = false;
      }
    });

    setCategoryList(response);
  };

  useEffect(() => {
    let selectedSource: string;
    const initData = async (selectedList: ITag[]) => {
      const response = await loadAllTagsWithCategoriesFiltered('');
      filterCategoryTagList(response, selectedList);
    };
    let initialObject: IFoodFormData = {
      typeOfMealObject: {
        key: '',
        value: '',
      },
      sourceOfFoodObject: {
        key: 0,
        value: '',
      },
      img: null,
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
      skippedMeal: false,
      tags: [],
    };

    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        typeOfMealObject: {
          key: props.data?.mealType || '',
          value: props.data?.mealType || '',
        },
        typeOfMeal: props.data?.mealType || '',
        sourceOfFoodObject: {
          key: props.data?.sourceId || 0,
          value: '',
        },
        sourceOfFood: props.data?.sourceId || 0,
        img: props.data?.imageUrl || null,
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
        skippedMeal: props.data?.mealSkipped || false,
        tags: props.data?.tagsObjects || [],
      };
    }
    const getSource = async () => {
      const sourcesOfFood = await getSourceOfFood();
      // setLoadedSource(sourcesOfFood);
      for (const key in sourcesOfFood) {
        if (sourcesOfFood[key].id === initialObject?.sourceOfFoodObject.key)
          selectedSource = sourcesOfFood[key].name;
      }
      initialObject.sourceOfFoodObject.value = selectedSource ? selectedSource : '';
    };
    initData(initialObject.tags);
    getSource().then(() => {
      setData(initialObject);
    });

    setSelectedTagsList(initialObject.tags);
    filterCategoryTagList(categoryTagsList, initialObject.tags);
  }, [props.data]);

  const handleChangeSearchValue = async (e: any) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);

    if (searchValue?.length >= 3 || searchValue === '') {
      const response = await loadAllTagsWithCategoriesFiltered(searchValue);
      filterCategoryTagList(response, selectedTagsList);
    }
  };

  const dispatch = useDispatch();
  // const validateFormField = (field: string) => {
  //   const err = validateField(data, validationRules, field);
  //   setErrors({
  //     ...errors,
  //     [field]: err,
  //   });
  // };
  const validateToSubmit =() =>{
    if (!(data.img?.size > 0) && !(data.img?.length > 0)) {
      return false;
    }
    return true;
  }

  // const validateToSubmit = () => {
  //   const selectedDate = dateTimeCombined(data.date, data.time);
  //   if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
  //     return false;
  //   }
  //   const err = validate(data, validationRules);
  //   // if is skipped meal populate date time and type of meal
  //   if (data.skippedMeal && data.typeOfMealObject.key != '' && isFormValidToSubmit(err)) {
  //     return true;
  //   }

  //   // you should populate tags or image
  //   if (selectedTagsList?.length === 0) {
  //     if (!(data.img?.size > 0) && !(data.img?.length > 0)) {
  //       return false;
  //     }
  //   }

  //   // if is not skipped meal you should populate source
  //   if (!data.skippedMeal && data.sourceOfFoodObject.key == 0) {
  //     return false;
  //   }

  //   return isFormValidToSubmit(err);
  // };

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  // const handleChange = (e: any) => {
  //   const value = e.target.value;
  //   const name = e.target.name;
  //   if (name === 'sourceOfFood') {
  //     setData({
  //       ...data,
  //       ['sourceOfFoodObject']: {key: value.key, value: value.value},
  //       [name]: value.key,
  //     });
  //   } else if (name === 'typeOfMeal') {
  //     setData({
  //       ...data,
  //       ['typeOfMealObject']: {key: value.key, value: value.value},
  //       [name]: value.key,
  //     });
  //   } else {
  //     setData({
  //       ...data,
  //       [name]: value,
  //     });
  //   }
  // };

  const clearForm = () => {
    setSelectedTagsList([]);
    setData({
      typeOfMealObject: {key: '', value: ''},
      sourceOfFoodObject: {key: 0, value: ''},
      typeOfMeal: '',
      sourceOfFood: 0,
      img: null,
      date: moment().toString(),
      time: moment().toString(),
      skippedMeal: false,
      tags: [],
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // const selectedDate = dateTimeCombined(data.date, data.time);
      const dataToSend = new FormData();
      // dataToSend.append('date', selectedDate);
      // dataToSend.append('mealType', data.typeOfMealObject.key);
      // dataToSend.append('mealSkipped', data.skippedMeal.toString());
      dataToSend.append('userId', currentUser.id);
      // dataToSend.append('image', data.img);
      // console.log("dataToSend",  data.sourceOfFoodObject.key.toString())
      if (!data.skippedMeal) {
        dataToSend.append('image', data.img);
        dataToSend.append('sourceId', data.sourceOfFoodObject.key.toString() === "0" ? "Other" : data.sourceOfFoodObject.key.toString);
        selectedTagsList.forEach((tag) => {
          dataToSend.append('tags', tag?.id ? tag?.id.toString() : '');
        });
      }
      let res: any;
      if (!props.data) {
        res = await dispatch(addFoodLogg(dataToSend));
      } else {
        if (typeof data.img === 'string') {
          dataToSend.append('imageEdit', false.toString());
        } else {
          dataToSend.append('imageEdit', true.toString());
        }
        dataToSend.append('id', props.data?.id ? props.data?.id.toString() : '');
        res = await dispatch(editFoodLogg(dataToSend));
      }
      if (res) {
        await dispatch(checkAchievement('logfood'));
        await dispatch(homepageActions.loadNotifications());
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (e: any) => {
    const selectedCategory: ICategoryTagDto | undefined = categoryTagsList?.find((oneCategory) =>
      oneCategory.tags.some((oneTag) => oneTag.id === e),
    );

    const selectedTag: ITagDto | undefined = selectedCategory?.tags.find(
      (oneTag) => oneTag.id == e,
    );

    if (selectedTag !== undefined && selectedCategory != undefined) {
      selectedTag.isVisible = false;

      const tag: ITag = {
        id: selectedTag.id,
        name: selectedTag.name,
        isLevel2: selectedTag.isLevel2,
        categoryId: selectedTag.categoryId,
        color: selectedTag.color,
        borderColor: selectedTag.color,
        hoverColor: selectedTag.hoverColor,
        textColor: selectedTag.textColor,
      };

      setSelectedTagsList([...selectedTagsList, tag]);
    }
  };

  const changeSpecificDataType = (value: any, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
    if (specificType === 'skippedMeal') {
      props.executeScroll && props.executeScroll();
    }
  };
  const handleDelete = (e: any) => {
    const selectedCategory: ICategoryTagDto | undefined = categoryTagsList?.find((oneCategory) =>
      oneCategory.tags.some((oneTag) => oneTag.id === e),
    );

    const selectedTag: ITagDto | undefined = selectedCategory?.tags.find(
      (oneTag) => oneTag.id == e,
    );

    if (selectedTag !== undefined && selectedCategory != undefined) {
      selectedTag.isVisible = true;

      setSelectedTagsList([...selectedTagsList.filter((oneTag) => oneTag.id !== e)]);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  return (
    <React.Fragment>
      <PaperWrapper>
        <div>
          <div className={classes.headerContainer}>
            <div className={commonClasses.headerTextImageContainer}>
              <img className={commonClasses.imageMargin} src={FoodIcon} alt={'food-log-icon'} />
              <Typography variant={'subtitle2'}>
                {formType === FormType.EDITING_MODE
                  ? t('LogsPage.foodWidget.formTitleEdit')
                  : t('LogsPage.foodWidget.formTitle')}
              </Typography>
            </div>
          </div>
          {props.isMobile ? (
            <React.Fragment>
              <div className={classes.firstRowContainer}>
                {!data.skippedMeal && (
                  <div className={classes.picturePart}>
                    <ImageUpload
                      uploadEnabled={true}
                      name={'img'}
                      changeData={(value: any) => changeSpecificDataType(value, 'img')}
                      imageUrl={data.img ? data.img : ''}
                      uploadIcon={props.isMobile ? ImageUploadIconMobile : ImageUploadIcon}
                      canEdit={false}
                      className={classes.imageUpload}
                      isMobile={props.isMobile}
                    />
                  </div>
                )}
                {/* <Select
                  name={'typeOfMeal'}
                  label={t('LogsPage.foodWidget.typeSelectLabel')}
                  required={true}
                  value={data.typeOfMealObject}
                  onChange={handleChange}
                  error={hasError(errors, 'typeOfMeal')}
                  helperText={getError(errors, 'typeOfMeal')}
                  className={classes.typeSelect}
                  onBlur={() => {
                    validateFormField('typeOfMeal');
                  }}
                  options={mealTypes.map((item) => ({key: item, value: splitCamelCase(item)}))}
                /> */}
              </div>
              {/* <div className={classes.secondRowContainer}>
                <DateTimePicker
                  dateClassName={classes.datePickerFix}
                  date={data.date}
                  time={data.time}
                  errors={errors}
                  changeSpecificDataType={changeSpecificDataType}
                />
              </div> */}

              {/* <div className={classes.checkboxFixTop}>
                <Checkbox
                  name={'skippedMeal'}
                  label={t('LogsPage.foodWidget.checkbox')}
                  checkedProp={data.skippedMeal}
                  changeData={(value: boolean) => changeSpecificDataType(value, 'skippedMeal')}
                />
              </div> */}

              {/* {!data.skippedMeal && (
                <div className={classes.firstRowContainer}>
                  <Select
                    name={'sourceOfFood'}
                    label={t('LogsPage.foodWidget.sourceSelectLabel')}
                    required={true}
                    value={data.sourceOfFoodObject}
                    onChange={handleChange}
                    className={classes.sourceSelect}
                    options={loadedSource.map((item: any) => ({key: item.id, value: item.name}))}
                  />
                </div>
              )} */}

              {/* <div className={classes.tagsPart}>
                <div>
                  <Typography variant={'caption'} className={classes.greyCaption}>
                    {t('LogsPage.foodWidget.searchLabel')}
                  </Typography>
                </div>
                <div className={classes.ingredientsDescription}>
                  <div className={classNames(classes.levelDiv, classes.levelOne)} />
                  <Typography variant={'caption'} className={classes.levelTxt}>
                    {levelOne}
                  </Typography>
                  <div className={classNames(classes.levelDiv, classes.levelTwo)} />
                  <Typography variant={'caption'} className={classes.levelTxt}>
                    {levelTwo}
                  </Typography>
                  <div className={classNames(classes.levelDiv, classes.levelThree)} />
                  <Typography variant={'caption'} className={classes.levelTxt}>
                    {levelThree}
                  </Typography>
                </div>
                <div>
                  <SearchBox
                    className={classes.searchInput}
                    containerClass={classes.searchContainer}
                    name={'searchBox'}
                    placeholder={'Search'}
                    value={searchValue}
                    onClick={toggleModal}
                  />
                </div>
                {selectedTagsList && selectedTagsList?.length > 0 && (
                  <React.Fragment>
                    <div className={classes.headerCategory}>
                      <Typography variant={'caption'}>
                        {t('LogsPage.foodWidget.ingredientsLabel')}
                      </Typography>
                    </div>
                    <div className={classes.boxTags}>
                      {selectedTagsList &&
                        selectedTagsList.map((oneSelectedTag) => (
                          <ChipTag
                            key={`tag-selected-${oneSelectedTag.id}`}
                            color={oneSelectedTag.color}
                            borderColor={oneSelectedTag.color}
                            hoverColor={oneSelectedTag.hoverColor}
                            textColor={oneSelectedTag.textColor}
                            name="chip"
                            id={oneSelectedTag.id || 0}
                            label={oneSelectedTag.name}
                            onDelete={handleDelete}
                          />
                        ))}
                    </div>
                  </React.Fragment>
                )}
              </div> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <div className={classes.firstRowContainer}>
                <Select
                  name={'typeOfMeal'}
                  label={t('LogsPage.foodWidget.typeSelectLabel')}
                  required={true}
                  value={data.typeOfMealObject}
                  onChange={handleChange}
                  error={hasError(errors, 'typeOfMeal')}
                  helperText={getError(errors, 'typeOfMeal')}
                  className={classes.typeSelect}
                  onBlur={() => {
                    validateFormField('typeOfMeal');
                  }}
                  options={mealTypes.map((item) => ({key: item, value: splitCamelCase(item)}))}
                />
                <DateTimePicker
                  dateClassName={commonClasses.datePicker}
                  date={data.date}
                  time={data.time}
                  errors={errors}
                  changeSpecificDataType={changeSpecificDataType}
                />
              </div>

              <div className={classes.checkboxFixTop}>
                <Checkbox
                  name={'skippedMeal'}
                  label={t('LogsPage.foodWidget.checkbox')}
                  checkedProp={data.skippedMeal}
                  changeData={(value: boolean) => changeSpecificDataType(value, 'skippedMeal')}
                />
              </div> */}

              {!data.skippedMeal && (
                <div className={classes.centralPartContainer}>
                  <div className={classes.picturePart}>
                    <ImageUpload
                      uploadEnabled={true}
                      name={'img'}
                      changeData={(value: any) => changeSpecificDataType(value, 'img')}
                      imageUrl={data.img ? data.img : ''}
                      uploadIcon={ImageUploadIcon}
                      canEdit={false}
                      className={classes.imageUpload}
                      isMobile={props.isMobile}
                    />
                  </div>
                  {/* <div className={classes.centralContainerRight}>
                    <Select
                      name={'sourceOfFood'}
                      label={t('LogsPage.foodWidget.sourceSelectLabel')}
                      required={true}
                      value={data.sourceOfFoodObject}
                      onChange={handleChange}
                      className={classes.sourceSelect}
                      options={loadedSource.map((item: any) => ({key: item.id, value: item.name}))}
                    />

                    <div className={classes.tagsPart}>
                      <div>
                        <Typography variant={'caption'} className={classes.greyCaption}>
                          {t('LogsPage.foodWidget.searchLabel')}
                        </Typography>
                      </div>
                      <div className={classes.ingredientsDescription}>
                        <div className={classNames(classes.levelDiv, classes.levelOne)} />
                        <Typography variant={'caption'} className={classes.levelTxt}>
                          {levelOne}
                        </Typography>
                        <div className={classNames(classes.levelDiv, classes.levelTwo)} />
                        <Typography variant={'caption'} className={classes.levelTxt}>
                          {levelTwo}
                        </Typography>
                        <div className={classNames(classes.levelDiv, classes.levelThree)} />
                        <Typography variant={'caption'} className={classes.levelTxt}>
                          {levelThree}
                        </Typography>
                      </div>
                      <div>
                        <SearchBox
                          className={classes.searchInput}
                          name={'searchBox'}
                          placeholder={'Search'}
                          value={searchValue}
                          onChange={handleChangeSearchValue}
                        />
                      </div>

                      {categoryTagsList.map((oneTagCategory) => (
                        <div
                          className={classes.headerCategory}
                          key={`categoryTag-${oneTagCategory.id}`}
                        >
                          <Typography variant={'overline'}>{oneTagCategory.name}</Typography>
                          <div className={classes.categoryTagContainer}>
                            {oneTagCategory.tags.map(
                              (oneTag) =>
                                oneTag.isVisible && (
                                  <ChipTag
                                    key={`tag-${oneTagCategory.id}-${oneTag.id}`}
                                    color={oneTag.color}
                                    borderColor={oneTag.color}
                                    hoverColor={oneTag.hoverColor}
                                    textColor={oneTag.textColor}
                                    name="chip"
                                    id={oneTag.id || 0}
                                    label={oneTag.name}
                                    onClick={handleClick}
                                  />
                                ),
                            )}
                          </div>
                        </div>
                      ))}

                      <div className={classes.headerCategory}>
                        <Typography variant={'caption'}>
                          {t('LogsPage.foodWidget.ingredientsLabel')}
                        </Typography>
                      </div>
                      <div className={classes.boxTags}>
                        {selectedTagsList &&
                          selectedTagsList.map((oneSelectedTag) => (
                            <ChipTag
                              key={`tag-selected-${oneSelectedTag.id}`}
                              color={oneSelectedTag.color}
                              borderColor={oneSelectedTag.color}
                              hoverColor={oneSelectedTag.hoverColor}
                              textColor={oneSelectedTag.textColor}
                              name="chip"
                              id={oneSelectedTag.id || 0}
                              label={oneSelectedTag.name}
                              onDelete={handleDelete}
                            />
                          ))}
                      </div>
                    </div>
                  </div> */}
                </div>
              )}
            </React.Fragment>
          )}

          <div className={commonClasses.buttonRowContainer}>
            {formType == FormType.EDITING_MODE && (
              <Button
                id={'cancel-button-food-form'}
                variant={'outlined'}
                className={commonClasses.outlinedBtn}
                onClick={handleCancel}
              >
                {t('Homepage.quickProfileUser.cancelButton')}
              </Button>
            )}
            <div />
            <Button
              id="submit-food-weight-form-id"
              variant="contained"
              onClick={handleSubmit}
              loading={isLoading}
              disabled={!validateToSubmit() || isLoading}
            >
              {t('LogsPage.bodyWidget.submitButton')}
            </Button>
          </div>
        </div>
      </PaperWrapper>
      {modal && (
        <CategoryTagListModal
          modal={modal}
          handleChangeSearchValue={handleChangeSearchValue}
          setSelectedTagsList={setSelectedTagsList}
          searchValue={searchValue}
          selectedTagsList={selectedTagsList}
          categoryTagsList={categoryTagsList}
          handleClick={handleClick}
          handleDelete={handleDelete}
          toggleModal={toggleModal}
        />
      )}
    </React.Fragment>
  );
};

export default FoodForm;
