import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.blacksAndWhites.smokyBlack,
  },
  text: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    margin: '24px 0px',
  },
  weightLabel: {
    marginTop: 24,
    marginBottom: 10,
    color: theme.palette.blacksAndWhites.davysGray,
  },
  heightLabel: {
    color: theme.palette.blacksAndWhites.davysGray,
  },
  slider: {
    width: '100%',
  },
  inputValueFied: {
    width: 95,
    color: theme.palette.blacksAndGrays.stoneGray,
    '& .MuiOutlinedInput-root': {
      color: theme.palette.blacksAndGrays.stoneGray,
    },
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainerClass: {
    marginRight: '8px !important',
  },
  unit: {
    color: theme.palette.blacksAndGrays.stoneGray,
    marginLeft: 8,
  },
  continue: {
    marginTop: 100,
    height: 48,
  },
}));

export default useStyles;
