import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  posterImage: {
    width: (props) => (props.isMobileOrTablet.isMobile ? 'calc(100% + 32px) !important' : '100%'),
    height: (props) => (props.isMobileOrTablet.isMobile ? `144px !important` : `360px !important`),
    marginLeft: (props) => (props.isMobileOrTablet.isMobile ? '-16px' : 0),
    marginRight: (props) => (props.isMobileOrTablet.isMobile ? '-16px' : 0),
    marginBottom: 40,
    display: (props) => (props.isMobileOrTablet.isMobile ? 'block' : 'flex'),
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: (props) => (props.smallScreens ? 'absolute' : 'relative'),
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
  },
  container: {
    position: 'relative',
    padding: (props) =>
      props.isMobileOrTablet.isMobile
        ? '6px 16px'
        : props.isMobileOrTablet.isTabletSmall
        ? '20px 32px'
        : props.isMobileOrTablet.isTabletBig
        ? '20px 92px'
        : 0,
  },
  mainContainer: {
    display: (props) => (props.smallScreens ? 'block' : 'flex'),
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '26px',
    marginTop: (props) =>
      props.isMobileOrTablet.isMobile
        ? '135px'
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? '340px'
        : 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    position: 'relative',
    '& > div': {
      display: 'flex',
    },
  },
  back: {
    cursor: 'pointer',
    padding: 0,
    marginTop: '5px',
    // paddingLeft:(props) => `${(props.smallScreens ? 30 : 0)} !important`,
    color: (props) =>
      props.smallScreens ? theme.palette.background.paper : theme.palette.blacksAndWhites.jet,
    '& img': {
      marginRight: 8,
      filter: (props) => (props.smallScreens ? 'invert(1)' : 'invert(0)'),
    },
    '& .MuiTypography-root': {
      letterSpacing: '1.25px',
      fontWeight: 500,
    },
  },
  notesSection: {
    width: (props) => (props.smallScreens ? '100%' : 628),
    '& img': {
      width: (props) => (props.smallScreens ? '100%' : 628),
      // height: (props) => (props.isMobileOrTablet.isMobile ? 179 : 303),
    },
    '& li ': {
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& li p p': {
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& li p': {
      margin: 0,
    },
  },
  noData: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  titleColor: {
    position: 'relative',
    zIndex: 1,
    width: (props) => (props.smallScreens ? '100%' : 628),
    fontSize: (props) => (props.isMobileOrTablet.isMobile ? '20px' : '36px'),
    lineHeight: (props) => (props.isMobileOrTablet.isMobile ? '24px' : '40px'),
    color: theme.palette.background.paper,
    marginTop: (props) => (props.smallScreens ? '100px' : '0'),
    marginLeft: (props) => (props.smallScreens ? '32px' : '0'),
    paddingBottom: '24px',
  },
  wholeSection: {
    marginBottom: 40,
  },
}));

export default useStyles;
