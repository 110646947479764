import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}
const useStyles = makeStyles<Theme, Props>(() => ({
  card: {
    margin: (props) => (props.smallScreens ? '0px 0px 16px 0px' : '0px 24px 16px 0px'),
    width: (props) =>
      props.isMobileOrTablet.isMobile
        ? 'calc(50% - 7px)'
        : props.isMobileOrTablet.isTabletSmall
        ? 'calc(50% - 12px)'
        : props.isMobileOrTablet.isTabletBig
        ? 'calc(33% - 24px)'
        : '302px',
    borderRadius: '16px',
    position: 'relative',
    height: 'fit-content',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important',
    '& .MuiCardContent-root': {
      padding: (props) => (props.isMobileOrTablet.isMobile ? '4px 8px 0 8px' : '8px 16px 0 16px'),
      width: (props) => (props.isMobileOrTablet.isMobile ? '100%' : '90%'),
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .MuiTypography-root': {
        fontSize: (props) => (props.smallScreens ? '14px' : '16px'),
      },
    },
    '&:nth-child(odd)': {
      marginRight: (props) => (props.isMobileOrTablet.isMobile ? '7px' : '12px'),
      marginLeft: (props) => (props.isMobileOrTablet.isTabletBig ? '12px' : '0'),
    },
    '&:nth-child(even)': {
      marginLeft: (props) => (props.isMobileOrTablet.isMobile ? '7px' : '12px'),
      marginRight: (props) => (props.isMobileOrTablet.isTabletBig ? '12px' : '0px'),
    },
  },
  infoContainer: {
    display: (props) => (props.isMobileOrTablet.isMobile ? ' block' : 'flex'),
    height: (props) => (props.isMobileOrTablet.isMobile ? '74px' : '56px'),
  },
  ratingContainer: {
    display: (props) => (props.isMobileOrTablet.isMobile ? ' flex' : 'block'),
    flexDirection: (props) => (props.isMobileOrTablet.isMobile ? 'row-reverse' : 'column'),
    justifyContent: 'space-between',
    alignItems: 'center',
    position: (props) => (props.isMobileOrTablet.isMobile ? 'absolute' : 'relative'),
    bottom: 0,
    right: 0,
    left: 0,
    width: (props) => (props.isMobileOrTablet.isMobile ? '100%' : '70px'),
  },
  rating: {
    position: (props) => (props.isMobileOrTablet.isMobile ? 'relative' : 'absolute'),
    right: 0,
    display: 'flex',
    margin: (props) => (props.isMobileOrTablet.isMobile ? '0px 10px 5px 10px' : '10px'),
  },
  ratingValue: {
    fontSize: (props) => (props.smallScreens ? '12px' : '14px'),
    fontWeight: 400,
    marginLeft: '4px',
  },
  showTime: {
    position: (props) => (props.isMobileOrTablet.isMobile ? 'relative' : 'absolute'),
    right: (props) => (props.isMobileOrTablet.isMobile ? 0 : 8),
    left: (props) => (props.isMobileOrTablet.isMobile ? 5 : 'auto'),
    bottom: (props) => (props.isMobileOrTablet.isMobile ? 3 : 8),
  },
}));

export default useStyles;
