import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {selectMember} from '../../../store/selectors/homepage.selectors';
import UserHeaderIcon from '../../../assets/icons/side-menu-bar/connections-inactive-icon.svg';
import TwilioService from '../../../services/TwilioService';
import * as usersActions from '../../../store/actions/users/users-action';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';

import useStyles from './user-header-item.styles';

/*eslint-disable @typescript-eslint/no-empty-function*/
export const UserHeaderItem: React.FC = () => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  const currentUser = useSelector(selectCurrentUser);
  const selectedMember = useSelector(selectMember);

  const dispatch = useDispatch();

  const openMyData = async () => {
    if (!selectedMember) return;
    dispatch(homepageActions.setLoading(true));
    dispatch(homepageActions.selectMember(null));
    dispatch(usersActions.storeCoachMembers([]));
    TwilioService.removeListeners();
    // await TwilioService.selectConversationForUserTrainer(currentUser, async (conversation) => {
    //   await dispatch(homepageActions.selectConversation(conversation));
    // });
    await dispatch(homepageActions.resetHistoryLogs());
    await dispatch(homepageActions.loadHistoryLogs(currentUser.id, 3, 0));
  };

  return (
    <div
      id={'user-item-item-id'}
      className={classes.itemContainer}
      onClick={openMyData}
      onKeyDown={() => {}}
      role="menuitem"
      tabIndex={0}
    >
      <img
        className={classes.itemIcon}
        src={currentUser?.imageUrl || UserHeaderIcon}
        alt="user-icon"
      />
      <div className={classes.itemText}>
        <Typography variant={'body1'}>
          {currentUser.firstName + ' ' + currentUser.lastName}
        </Typography>
      </div>
    </div>
  );
};
