import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import ReminderResolver from './ReminderResolver';
import {
  getDateFromDate,
  dateTimeToString,
  calculateTimeLeft,
  createDateWithExactTime,
} from '../../../utils/date-time-utils';
import {FULL_TIME_FORMAT} from '../../../utils/constants';
import TotalChallengeScore from '../movement/total-challenge-score/TotalChallengeScore';
const ReminderWrapper: React.FC = () => {
  const currentDate = getDateFromDate(new Date());
  const timeLeft = calculateTimeLeft(
    createDateWithExactTime(currentDate, '12:00:00'),
    new Date(dateTimeToString(new Date(), FULL_TIME_FORMAT)),
  );
  const dispatch = useDispatch();

  const getNotifications = async () => {
    await dispatch(homepageActions.loadNotifications());
  };

  useEffect(() => {
    getNotifications();
  }, []);

  if (timeLeft > 0) {
    setTimeout(function () {
      getNotifications();
    }, timeLeft);
  }

  return <>
  <TotalChallengeScore isHome={true} />
  <ReminderResolver />;
  </>
};

export default ReminderWrapper;
