import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  input: {},
  selectContainer: {
    marginTop: 0,
    marginRight: 16,
    width: '177px',
    height: '40px',
  },
  customMenuProp: {
    // backgroundColor: (props) =>
    //   props.isMobileOrTablet
    //     ? theme.palette.tintsAndShades.oxfordBlueDark
    //     : theme.palette.background.paper,
        backgroundColor: (props) =>
        props.isMobileOrTablet
          ? "#d6d6d6"
          : theme.palette.background.paper,
    color: (props) => (props.isMobileOrTablet ? "#000000" : ''),
    padding: (props) => (props.isMobileOrTablet ? 0 : '8px 0'),
    '& li': {
      borderBottom: (props) =>
        props.isMobileOrTablet
          ? `1px solid ${theme.palette.additionalColors.richBlackFOGRA29}`
          : 'none',
    },
    '& li:last-child': {
      borderBottom: 'none',
    },
  },
  customMenuPropDefault: {
    backgroundColor: theme.palette.background.paper,
    padding: '8px 0',
    '& li': {
      borderBottom: 'none',
    },
  },
  formControl: {},
  helperText: {
    marginTop: 0,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    color: 'red',
  },
  labelText: {
    color: theme.palette.blacksAndGrays.davysGray,
    marginBottom: 4,
  },
  labelDisabled: {
    color: theme.palette.blacksAndGrays.silverSand,
    marginBottom: 4,
  },
  requiredText: {
    color: theme.palette.primary.light,
    marginBottom: 4,
  },
  labelContainer: {
    display: 'flex',
  },
  selectedItem: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.tintsAndShades.lapisLazuli,
    },
  },
  notSelected: {
    backgroundColor: 'transparent',
  },
  placeholderClass: {
    color: theme.palette.blacksAndGrays.stoneGray,
  },
  placeholderDisabled: {
    color: theme.palette.blacksAndGrays.silverSand,
  },
}));

export default useStyles;
