import {Container, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const Terms = () => {
  const useStyles = makeStyles({
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 64,
      backgroundColor: '#fff',
      color: '#184c88',
      paddingLeft: 10,
      paddingRight: 10,
      justifyContent: 'center',
      textAlign: 'center',
      boxShadow: '0px 1px 4px 0px rgba(0,0,0,.25)',
      position: 'fixed',
      width: '100vw',
    },
    title: {
      flexGrow: 1,
      fontWeight: 500,
      fontSize: 20,
    },
    sectionContent: {
      marginBottom: '1rem',
      fontFamily: "Gantari, sans-serif",
      fontSize: 12,
      color: '#7a7a7a',
      fontWeight: 'bold',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '34px 0px',
      alignItems: 'center',
    },
    footerImage: {
      width: 110,
      height: 46,
    },
    footerNav: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80vh',
    },
  });
  const classes = useStyles();
  return (
    <>
      <div className={classes.appBar}>
        <img
          className={classes.footerImage}
          decoding="async"
          src="https://sp-ao.shortpixel.ai/client/to_webp,q_lossless,ret_img/https://betrhealth.com/wp-content/uploads/2022/08/footer-logo.png"
          alt="Logo"
        />
        <h1 className={classes.title}>Terms and Conditions</h1>
      </div>
      <Container maxWidth="md" style={{marginTop: 65}}>
        <Box className={classes.sectionContent}>
          <p>
            Thank you for visiting Pure Proactive Health, Inc. DBA Betr. (“<strong>We</strong>,” “
            <strong>Us</strong>”, “<strong>Company</strong>”).&nbsp; These Terms of Use, together
            with the Privacy Policy, and any other documents they expressly incorporate, set forth
            the terms and conditions upon which you may access and use the health coaching services,
            mobile application and Service provided or operated by Pure Proactive Health, Inc. (“
            <strong>Service</strong>”) including any content and functionality offered through the
            Service.&nbsp; (“<strong>Terms of Use</strong>” or “<strong>Agreement</strong>”)
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            PLEASE READ THE TERMS OF USE CAREFULLY BEFORE YOU START TO USE THE SERVICE.&nbsp; BY
            USING THE SERVICE, YOU REPRESENT YOU ARE AT LEAST 13 YEARS OF AGE, LOCATED IN THE UNITED
            STATES.&nbsp; FURTHER, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF
            USE.&nbsp; IF YOU ARE NOT 13 YEARS OLD, A UNITED STATES USER OR DO NOT WANT TO AGREE TO
            THESE TERMS OF USE, YOU MUST NOT ACCESS OR USE THE SERVICE.&nbsp; WE RESERVE THE RIGHT
            TO CHANGE, ADD OR REMOVE PORTIONS OF THESE TERMS OF USE AND ANY DOCUMENTS INCORPORATED
            HEREIN AT ANY TIME AND AT OUR SOLE DISCRETION.&nbsp; YOUR CONTINUED USE OF THE SERVICE
            FOLLOWING THE POSTING OF ANY CHANGES MEANS THAT YOU ACCEPT AND AGREE TO SUCH
            CHANGES.&nbsp; IT IS YOUR RESPONSIBILITY TO CHECK THESE TERMS OF USE PERIODICALLY FOR
            CHANGES.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Use of the Service.</strong>
          </p>
        </Box>

        <Box className={classes.sectionContent}>
          <p>
            <strong>General</strong>.&nbsp; Subject to the terms and conditions of this Agreement,
            and during the term of this Agreement, Pure Proactive Health, Inc. grants you a
            non-exclusive and non-transferable right to access and use the Service in accordance
            with the terms of this Agreement, and any documentation made available to you by Pure
            Proactive Health, Inc. solely for purposes of your personal use of the Services.&nbsp;
            This Service is limited to users in the United States.&nbsp; The Service is enabled by
            technology, software and certain content delivered electronically.&nbsp; You agree that
            you will not reverse-engineer, decompile, disassemble, translate, or otherwise attempt
            to obtain access to the source code of, any aspect of the Services, including any and
            all technology, software and content.&nbsp; You will not copy, reproduce, alter, create
            derivative works from, or otherwise modify the Services, including any and all
            technology, software, and content.&nbsp; You will not lease, loan, sublicense,
            distribute, or otherwise provide others access to or with any aspect of the
            Services.&nbsp; Other than as expressly set forth in this Agreement, no license, or
            other rights in or to the Services are granted to you, and all such licenses and rights
            are hereby expressly reserved.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            The Service has been designed by Pure Proactive Health, Inc. with the purpose of
            assisting people achieve their health goals though healthy life style choices and
            behavior.&nbsp; EVEN IF YOU CARRY OUT THE FOREGOING REQUIREMENTS, YOU UNDERSTAND AND
            AGREE THAT INDIVIDUAL RESULTS VARY, AND THAT THE COMPANY DOES NOT GUARANTEE WEIGHT
            LOSS.&nbsp; We encourage you to consult with your healthcare provider before commencing
            the Services.&nbsp; You represent that you are in good enough health to begin these
            Services, which may include changes to your diet or level of physical activity, among
            other things.&nbsp; If you have been diagnosed with a chronic disease or other health
            condition, you represent that you have obtained the express approval from a health care
            professional to receive the Services before beginning.&nbsp; The Service requires your
            active engagement and participation.&nbsp; You understand that, despite your efforts,
            individual users results will vary for a variety of reasons and Pure Proactive Health,
            Inc. cannot guarantee that you will achieve your health goals.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Health Coaches.</strong>&nbsp; Pure Proactive Health, Inc. will match you with a
            health coach who will consult with you via telephone, email or other online tools.&nbsp;
            We strive to maintain the client-health coach relationship throughout your use of the
            Service but reserve the right to change your health coach at any time.&nbsp; You agree
            to work with your health coach in good faith solely with respect to the Service, and you
            will not seek consultation from your health coach for any other purpose.&nbsp; You agree
            to conduct your sessions with health coach alone, with no observers, and you will not
            record any such session in any way. &nbsp;Our health coaches provide you with
            peer-to-peer support, encouragement, and practical tips to help overcome barriers to
            meeting your health goals<strong>.</strong>
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>
              IT IS IMPORTANT TO UNDERSTAND THAT THE SERVICES DO NOT INCLUDE THE PROVISION OF
              MEDICAL CARE BY THE COMPANY.&nbsp; PURE PROACTIVE HEALTH, INC. IS NOT A LICENSED
              MEDICAL CARE PROVIDER, AND OUR HEALTH COACHES ARE NOT ACTING IN A HEALTH CARE
              PROFESSIONAL CAPACITY.&nbsp; OUR HEALTH COACHES DO NOT PROVIDE ANY MEDICAL, CLINICAL
              SERVICES, AND DO NOT DIAGNOSIS, TREAT OR MANAGE ANY ILLNESS, DISEASE, OR
              CONDITION.&nbsp; PURE PROACTIVE HEALTH, INC. AND OUR HEALTH COACHES DO NOT HAVE
              EXPERTISE IN DIAGNOSING, EXAMINING, OR TREATING MEDICAL OR PSYCHOLOGICAL CONDITIONS OF
              ANY KIND, OR IN DETERMINING THE EFFECT OF ANY SPECIFIC EXERCISE ON A MEDICAL
              CONDITION.&nbsp; YOU SHOULD NOT CHANGE YOUR TREATMENT OR CARE PLAN, MEDICATION OR
              THERAPY BASED ON INFORMATION YOU RECEIVED THROUGH THE SERVICES OR FROM ONE OF OUR
              HEALTH COACHES.&nbsp; CONSULTATION WITH OUR HEALTH COACHES IS SUPPLEMENTAL TO ANY
              TREATMENT OR CARE PROVIDED BY YOUR HEALTH CARE PROVIDER AND SHOULD NOT REPLACE REGULAR
              MEDICAL VISITS WITH YOUR HEALTH CARE PROVIDER.&nbsp; ALWAYS SEEK THE ADVICE OF YOUR
              PHYSICIAN OR OTHER QUALIFIED HEALTH CARE PRACTITIONER REGARDING YOUR MEDICAL CONDITION
              OR THE USE (OR FREQUENCY) OF ANY MEDICATION OR MEDICAL DEVICE.&nbsp; INFORMATION
              PROVIDED BY OUR HEALTH COACHES IS OFFERED FOR INFORMATIONAL PURPOSES ONLY AND IS NOT A
              RECOMMENDATION OR ENDORSEMENT OF ANY DRUG, DEVICE OR TREATMENT OR REPRESENTATION THAT
              A PARTICULAR DRUG, DEVICE, OR TREATMENT IS SAFE, APPROPRIATE, OR EFFECTIVE FOR
              YOU.&nbsp; QUESTIONS REGARDING ANY DRUG, DEVICE, OR TREATMENT SHOULD BE DIRECTED TO
              YOU HEALTHCARE PROVIDER.&nbsp; NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN
              SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ IN CONNECTION WITH OUR SERICES. ALWAYS
              CONSULT WITH YOUR HEALTHCARE PROFESSIONAL IF YOU HAVE ANY QUESTIONS OR CONCERNS ABOUT
              YOUR HEALTH OR CONDITION OR EXPERIENCE ANY CHANGES IN YOUR CONDITION OR HEALTH
              STATUS.&nbsp;&nbsp;
            </strong>
            <strong>
              NOT ALL EXERCISES OR ACTIVITIES RECOMMENDED BY PURE PROACTIVE HEALTH, INC.
            </strong>
            <strong>&nbsp;OR ANY OF THE HEALTH COACHES</strong>
            <strong>
              , OR OTHERWISE MADE AVAILABLE ON THE SITE OR IN THE DOCUMENTATION, ARE SUITABLE FOR
              EVERYONE. &nbsp;YOU UNDERSTAND AND AGREE THAT WHEN PARTICIPATING IN ANY EXERCISE OR
              EXERCISE PROGRAM, THERE IS THE POSSIBILITY OF INJURY OR DEATH. &nbsp;AS SUCH, YOU
              AGREE TO USE YOUR OWN JUDGMENT AT ALL TIMES DURING YOUR USE OF THE SERVICE IN ORDER TO
              DETERMINE IF ANY RECOMMENDED EXERCISE OR ACTIVITY IS SUITABLE FOR YOU. &nbsp;IF YOU
              FEEL ANY DISCOMFORT OR PAIN DURING YOUR USE OF THE SERVICE, YOU MUST IMMEDIATELY STOP
              THE EXERCISE OR ACTIVITY CAUSING SUCH DISCOMFORT OR PAIN, AND DIAL 911 USING YOUR
              TELEPHONE IF YOU NEED EMERGENCY HELP.
            </strong>
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>PURE PROACTIVE HEALTH, INC.</strong>
            <strong>
              &nbsp;WILL NOT BE RESPONSIBLE FOR ANY HEALTH PROBLEMS OR INJURIES THAT YOU MAY
              EXPERIENCE AS A RESULT OF RECEIVING THE SERVICES, INCLUDING FROM TRAINING PROGRAMS,
              PRODUCTS, OR EVENTS YOU LEARN ABOUT THROUGH THE SERVICE.&nbsp; YOU HEREBY AGREE THAT
              YOUR USE OF THE SERVICE, INCLUDING YOUR USE OF ANY EXERCISE PROGRAM RECOMMENDED BY
              PURE PROACTIVE HEALTH, INC.
            </strong>
            <strong>
              &nbsp;OR ANY WELLNESS PROFESSIONAL, IS AT YOUR OWN RISK AND THAT YOU ARE VOLUNTARILY
              PARTICIPATING IN THESE ACTIVITIES, EVEN IF ANY ASPECT OF THE SITE OR DOCUMENTATION IS
              INACCURATE, INCOMPLETE, OR INAPPROPRIATE IN THE MANNER IT WAS PRESENTED.
              &nbsp;ALTHOUGH PURE PROACTIVE HEALTH, INC. COACHES ARE AVAILABLE, THEY DO NOT PROVIDE
              EMERGENCY SERVICES.&nbsp; THE SERVICES DO NOT INCLUDE EMERGENCY, TIME SENSITIVE OR
              URGENT, REMOTE OR CRITICAL CARE SERVICES.&nbsp; IF YOU THINK, YOU HAVE A MEDICAL
              EMERGENCY, CALL 911 OR GO TO THE NEAREST OPEN EMERGENCY ROOM IMMEDIATELY.
            </strong>
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Registration.</strong>&nbsp; You are required to register for the Service and
            provide certain information about yourself.&nbsp; All information we collect about you
            in connection with the Services is subject to our Privacy Policy.&nbsp; By using the
            Service, you consent to all actions taken by us with respect to your information in
            compliance with the Privacy Policy, including being contacted via email.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            You will receive a user account for our personal use that requires a username and
            password.&nbsp; You are responsible for all activities that occur under your user
            account.&nbsp; You shall: (i) have sole responsibility for the accuracy, quality,
            integrity, legality, reliability, and appropriateness of all data you provide; (ii)
            maintain the confidentiality of your password and user account information; (iii) use
            commercially reasonable efforts to prevent unauthorized access to, or use of, the
            Service, and notify the Company promptly of any such unauthorized use; and (iv) comply
            with all applicable local, state, and federal laws in using the Service.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Term and Termination</strong>.&nbsp; The term of this Agreement will begin upon
            your successful registration for the Service and will continue indefinitely unless
            terminated by either party as permitted herein.&nbsp; The Company may suspend or
            terminate your access to the Service if you (a) breach any term of this Agreement, or
            (b) engage in any conduct that the Company determines in its discretion may have an
            adverse effect on the Company or its reputation.&nbsp; Upon termination, you will no
            longer have access to the Service or the health coaches, mobile application, or content
            provided through the Services.&nbsp; In addition to termination, Company reserves the
            right to pursue any and all remedies available to it.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Fees.</strong>&nbsp; Services fees for the Term will be paid in accordance with
            your registration confirmation.&nbsp; You acknowledge that access to the Service many be
            suspended until payment is received in full.&nbsp; All Service Fees are exclusive of any
            tax, levy, or similar governmental charge that may be assessed.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Taxes</strong>.&nbsp; You will be responsible for all taxes of whatever kind or
            nature imposed by any governmental authority upon any Services provided to you
            hereunder, excluding taxes based upon Retrofit’s income or property.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Financial Responsibility/Payment Policy/Assignment of Benefits</strong>
            &nbsp;Payment is due at time of registration.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Promotions</strong>. &nbsp;Any promotion offered has no independent cash value
            and may not be combined with any other offers.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Rely on the Betr Health 100% satisfaction guarantee. &nbsp;</strong>
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            Unless otherwise specified in the Plan and/or a Confirmation Email, if within (30) days
            after starting the Service or Plan you notify us in an email to concierge@betrhealth.com
            that you are not completely satisfied with the Services, the Company will issue a
            refund.&nbsp; The Refund will be issued via your original form of payment.&nbsp;&nbsp;
            Refunds will be applied within one business day after your request is received but may
            not appear on your statement for a few days depending on your bank.&nbsp; You can reach
            our Customer Service Team at 1-877-677-8767 for any further questions.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>________________________________________________________</p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Registration.&nbsp;&nbsp;</strong>You will provide Company with accurate,
            complete, and updated registration information.&nbsp; Failure to do so shall constitute
            a breach of these Terms, which may result in immediate termination of your Company
            account.&nbsp; You shall not use a false nature, e-mail address, or phone number or
            otherwise enter information with the intent to impersonate another person.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Intellectual Property Ownership.</strong>&nbsp; Except for the limited license
            and use rights expressly granted to you under this Terms of Use during the Term, all
            title to and the rights in the Services, including any and all technology, software and
            content, including ownership rights to patents (registrations, renewals, and pending
            applications), copyrights, trademarks, trade secrets, the Company’s or third party
            hardware, other technology, any derivatives of and all goodwill associated with the
            foregoing is the exclusive property of the Company and/or third parties.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Confidentiality.</strong>&nbsp; We will use and protect your personal
            information pursuant to the Privacy Policy.&nbsp; You agree not to reveal or disclose
            any confidential information of the Company, which includes information, materials and
            content provided in connection with the Service) for any purpose with the Company’s
            consent.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Non-Confidential Information.</strong>&nbsp; Subject to any applicable terms and
            conditions set forth in our Privacy Policy, any other communication or material that you
            send to us through the Service, such as any questions, comments, suggestions or the
            like, is and will be deemed to be non-confidential and we will have no obligation of any
            kind with respect to such information.&nbsp; We will be free to use any ideas, concepts,
            know-how, or techniques contained in such communication for any purpose whatsoever,
            including, but not limited to, developing, manufacturing, and marketing products.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Content</strong>.&nbsp; The information presented on or through the Service is
            made available solely for general information purposes.&nbsp; We do not warrant the
            accuracy, completeness, or usefulness of this information.&nbsp; Any reliance you place
            on such information is strictly at your own risk.&nbsp; This Service may provide
            information about health and wellness and certain health conditions.&nbsp; However,
            nothing on this Service should be construed as providing any kind of medical advice or
            recommendation, and should not be relied on as the basis for any clinical decision or
            action.&nbsp; Patients should not use the information contained on the Service for
            diagnosing a health problem or disease.&nbsp; Medical advice should always be sought
            from a qualified medical practitioner.&nbsp; We disclaim all liability and
            responsibility arising from any reliance placed on such materials by you.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Service Access and Changes</strong>.&nbsp; We reserve the right to withdraw or
            amend this Service or materials we provide in connection with the Service, in our sole
            discretion.&nbsp; We will not be liable if for any reason all or any part of the Service
            is unavailable at any time or for any period.&nbsp; From time to time, we may restrict
            access to some parts of the Service, or the entire Service to users.&nbsp; We also may
            update the content on this Service from time to time, and we make reasonable efforts to
            maintain current information.&nbsp; However, we cannot guarantee that the content is
            complete or up-to-date.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Trademarks</strong>.&nbsp; Trademarks, service marks, graphics, and logos used
            in connection with the Service are the trademarks of their respective owners.&nbsp; You
            are granted no right or license with respect to any of the trademarks mentioned above
            and any use of such trademarks.&nbsp; You acknowledge and agree that all text, graphics,
            photographs, trademarks, logos, visual interfaces, artwork, computer code, and all other
            related content contained on the Service is owned by the Company or third parties and is
            protected by trade dress, copyright, patent and trademark laws, and various other
            intellectual property rights and unfair competition laws.&nbsp; Any reproduction,
            publication, further distribution, or public exhibition of materials provided through
            the Service, in whole or in part, is strictly prohibited.&nbsp; Except as expressly
            provided in these Terms of Use, no part of the Service and no content may be copied,
            reproduced, republished, uploaded, posted, publicly displayed, encoded, translated,
            distributed or transmitted in any way (including “mirroring”) to any other computer,
            server, Service or other medium for publication or distribution or for any commercial
            enterprise, without the express prior written consent of the Company.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Third Party Links and Technology</strong>.&nbsp; Any and all third party
            technology or Services provided, made available, linked to, or otherwise accessible
            through the Service (“&nbsp;<strong>Third Party Technology</strong>”) is provided solely
            as a convenience to you and not under our control.&nbsp; The Company does not endorse,
            recommend, or otherwise make any representations or warranties with respect to any Third
            Party Technology.&nbsp; The Company does not have any responsibility or liability to you
            for any Third Party Technology, which you access, and you use it at your own risk.&nbsp;
            Further, you agree to comply with any and all terms and conditions applicable to the use
            of Third Party Technology and otherwise ensure that you have obtained all rights,
            licenses, and clearances that may be necessary to use such Third Party Technology.&nbsp;
            We encourage you to review the terms of use and privacy policies applicable to such
            Third Party Technology.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Intended for Users Over 13</strong>.&nbsp; The Service is not for use by
            individual under the age of 13.&nbsp; We will not knowingly collect information or
            provide Services to anyone under the age of 13.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Indemnity</strong>.&nbsp; You agree to defend, indemnify, and hold the Company,
            its officers, directors, partners, employees, contractors, agents, licensors, and
            suppliers, harmless from and against any claims, actions or demands, liabilities and
            settlements including without limitation, reasonable legal and accounting fees,
            resulting from, or alleged to result from, your use of the Service.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>DISCLAIMER</strong>.&nbsp; PURE PROACTIVE HEALTH, INC. IS NOT A MEDICAL
            ORGANIZATION.&nbsp; YOU SHOULD NOT CONSIDER THE SERVICE OR THE DOCUMENTATION AS MEDICAL
            ADVICE OF ANY KIND AND THE SERVICE IS NOT INTENDED TO TREAT ANY ILLNESS, DISEASE, OR
            ADVERSE MEDICAL CONDITION OR MANAGE A CHRONIC DISEASE OR CONDITION.&nbsp; By using the
            Service, you expressly agree that use of the Service is at your sole risk.&nbsp; The
            Service is provided on an “AS IS” and “as available” basis.&nbsp; NEITHER The Company
            nor its officers, directors, managers, members, employees, agents, third-party content
            providers, designers, contractors, distributors, merchants, sponsors, licensors or the
            like (collectively, “<strong>Affiliates</strong>”) warrant that use of the Service will
            be uninterrupted or error-free.&nbsp; Neither The Company nor its Affiliates warrant the
            accuracy, integrity or completeness of the content provided on the Service or the
            products or services offered for sale on the Service.&nbsp; Further, The Company makes
            no representation that content provided on the Service is applicable to, or appropriate
            for use in, locations outside of the United States.&nbsp; The Company and its Affiliates
            specifically disclaim all warranties, whether expressed or implied, including but not
            limited to warranties of title, merchantability, or fitness for a particular
            purpose.&nbsp; No oral advice or written information given by The Company or its
            Affiliates will create a warranty.&nbsp; Some states do not allow the exclusion or
            limitation of certain warranties, so the above limitation or exclusion may not apply to
            you.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Limitation of Liability</strong>.&nbsp; Except where prohibited by law, in no
            event will THE COMPANYOR ITS AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM YOUR USE OF OR INABILITY TO USE THE
            SERVICE, INCLUDING BUT NOT LIMITED TO RELIANCE BY YOU ON ANY INFORMATION OBTAINED FROM
            THE SERVICE.&nbsp; THE FOREGOING LIMITATION OF LIABILITY WILL APPLY IN ANY ACTION,
            WHETHER IN CONTRACT, TORT OR ANY OTHER CLAIM, EVEN IF AN AUTHORIZED REPRESENTATIVE OF
            THE COMPANY HAS BEEN ADVISED OF OR SHOULD HAVE KNOWLEDGE OF THE POSSIBILITY OF SUCH
            DAMAGES.&nbsp; If, notwithstanding the other provisions of these Terms of Use, The
            Company is found to be liable to you for any damage or loss which arises out of or is in
            any way connected with your use of the Service, THE COMPANY’S AND ITS AFFILIATE’S
            liability will in no event exceed the amount of fees paid during the preceding 12 month
            period.&nbsp; Some jurisdictions do not allow limitations of liability, so the foregoing
            limitation may not apply to you.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Void Where Prohibited</strong>.&nbsp; The Service is intended for users in the
            United States.&nbsp; The Service is not available to all persons or in all geographic
            locations.&nbsp; Any offer for any feature, product, or service made on the Service is
            void where prohibited.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Governing Law</strong>.&nbsp; You agree that all matters relating to your access
            to or use of the Service, including all disputes, will be governed by the laws of the
            United States and by the laws of the State of North Carolina &nbsp;without regard to its
            conflicts of law’s provisions.&nbsp; You agree to the personal jurisdiction by and venue
            in the state and federal courts in Raleigh, North Carolina, and waive any objection to
            such jurisdiction or venue.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>WAIVER OF TRIAL BY JURY</strong>.&nbsp; EXCEPT WHERE PROHIBITED BY LAW, YOU
            EXPRESSLY WAIVE TRIAL BY JURY IN ANY JUDICIAL PROCEEDING INVOLVING ANY DISPUTE,
            CONTROVERSY, OR CLAIM ARISING OUT OF OR RELATING TO THESE TERMS AND CONDITIONS OR THE
            SERVICE.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>No Waiver</strong>.&nbsp; The failure of the Company to insist on or enforce
            strict performance of these Terms of Use will not be construed as a waiver by The
            Company of any provision or any right it has to enforce these Terms of Use, nor will any
            course of conduct between the Company and you or any other party be deemed to modify any
            provision of these Terms of Use.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Severability</strong>.&nbsp; The provisions of these Terms of Use are
            severable.&nbsp; The invalidity, in whole or in part, of any provision of these Terms of
            Use will not affect the validity or enforceability of any other of its provisions.&nbsp;
            If any of the provisions of these Terms of Use are held by a court or other tribunal of
            competent jurisdiction to be void or unenforceable, such provisions will be limited or
            eliminated to the minimum extent necessary and replaced with a valid provision that best
            embodies the intent of these Terms of Use, so that these Terms of Use will remain in
            full force and effect
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            <strong>Safety Warnings</strong>
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            THE BETR HEALTH MOVEMENT SERVICE OFFERS HEALTH AND FITNESS INFORMATION AND IS DESIGNED
            FOR EDUCATIONAL AND ENTERTAINMENT PURPOSES ONLY. YOU SHOULD CONSULT YOUR PHYSICIAN OR
            GENERAL PRACTITIONER BEFORE BEGINNING A NEW FITNESS PROGRAM. YOU SHOULD NOT RELY ON THIS
            INFORMATION AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL MEDICAL ADVICE,
            DIAGNOSIS, OR TREATMENT. IF YOU HAVE ANY CONCERNS OR QUESTIONS ABOUT YOUR HEALTH, YOU
            SHOULD ALWAYS CONSULT WITH A PHYSICIAN, GENERAL PRACTITIONER OR OTHER HEALTH-CARE
            PROFESSIONAL. DO NOT DISREGARD, AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED
            ADVICE FROM YOUR HEALTHCARE PROFESSIONAL BECAUSE OF SOMETHING YOU MAY HAVE READ ON THE
            BETR HEALTH MOVEMENT SITE OR HEARD ON THE BETR HEALTH MOVEMENT SERVICE. THE USE OF
            INFORMATION PROVIDED THROUGH THE BETR HEALTH MOVEMENT SERVICE IS SOLELY AT YOUR OWN RISK
            AND IS NOT MEDICAL OR HEALTHCARE ADVICE.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            NOTHING STATED OR POSTED ON THE BETR HEALTH MOVEMENT SITE OR AVAILABLE THROUGH ANY BETR
            HEALTH MOVEMENT SERVICE IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF
            MEDICAL OR COUNSELING CARE. FOR PURPOSES OF THESE TERMS, THE PRACTICE OF MEDICINE AND
            COUNSELING INCLUDES, WITHOUT LIMITATION, PSYCHIATRY, PSYCHOLOGY, PSYCHOTHERAPY, OR
            PROVIDING HEALTH CARE TREATMENT, INSTRUCTIONS, DIAGNOSIS, PROGNOSIS OR ADVICE. THE BETR
            HEALTH MOVEMENT SERVICE IS CONTINUALLY UNDER DEVELOPMENT AND, TO THE FULL EXTENT
            PERMITTED BY LAW, BETR HEALTH MOVEMENT MAKES NO WARRANTY OF ANY KIND, IMPLIED OR
            EXPRESS, AS TO ITS ACCURACY, COMPLETENESS OR APPROPRIATENESS FOR ANY PURPOSE. IN THAT
            REGARD, DEVELOPMENTS IN MEDICAL RESEARCH MAY IMPACT THE HEALTH, FITNESS AND NUTRITIONAL
            ADVICE THAT APPEARS HERE. NO ASSURANCE CAN BE GIVEN THAT THE ADVICE CONTAINED IN THE
            BETR HEALTH MOVEMENT SERVICE WILL ALWAYS INCLUDE THE MOST RECENT FINDINGS OR
            DEVELOPMENTS WITH RESPECT TO THE PARTICULAR MATERIAL.
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            (A) In becoming a user of Betr Health Movement with the intent of using the Betr Health
            Movement Service, you affirm that either all of the following statements are true:
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <ul>
            <li>
              no physician or general practitioner has ever informed you that you have a heart
              condition or that you should only do physical activities recommended by a physician or
              general practitioner;
            </li>
            <li>you have never felt chest pain when engaging in physical activity;</li>
            <li>
              you have not experienced chest pain when not engaged in physical activity at any time
              within the past month;
            </li>
            <li>
              you have never lost your balance because of dizziness and you have never lost
              consciousness;
            </li>
            <li>
              you do not have a bone or joint problem that could be made worse by a change in your
              physical activity;
            </li>
            <li>
              your physician or general practitioner is not currently prescribing drugs for your
              blood pressure or heart condition;
            </li>
            <li>
              you do not have a history of high blood pressure, and no one in your immediate family
              has a history of high blood pressure or heart problems; and
            </li>
            <li>you do not know of any other reason you should not exercise; or</li>
          </ul>
        </Box>
        <Box className={classes.sectionContent}>
          <p>
            (B) your physician or general practitioner has been specifically consulted by you and
            approved of your use of the Betr Health Movement Service. If applicable, you further
            affirm that
          </p>
        </Box>
        <Box className={classes.sectionContent}>
          <ul>
            <li>
              you are not pregnant, breastfeeding or lactating; unless your physician or general
              practitioner has been specifically consulted and approved your use of the Betr Health
              Movement Service.
            </li>
            <li>
              Betr Health Movement reserves the right to refuse or cancel your membership if we
              determine that you have certain medical conditions or that the representations set
              forth above are untrue in any respect.
            </li>
          </ul>
        </Box>
        <div className={classes.footer}>
          <div className="logo">
            <img
              className={classes.footerImage}
              decoding="async"
              src="https://sp-ao.shortpixel.ai/client/to_webp,q_lossless,ret_img/https://betrhealth.com/wp-content/uploads/2022/08/footer-logo.png"
              alt="Logo"
            />
          </div>
          <div className={classes.footerNav}>
            {/* <div className={classes.footerNav}> */}
            <p>Terms of Service</p>
            <p>Privacy Policy</p>
            <p>Betr for Business</p> {/* </div> */}
            <p className="copyright">© 2023 Betr Health</p>
          </div>
        </div>
      </Container>
    </>
  );
};

Terms.propTypes = {};

export default Terms;
