/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useStyles from './sponsoredUserOnboarding.styles';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import {TextField} from '../../../common/components/text-field/TextField';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  IValidationRules,
  validate,
  validateField,
} from '../../../validation/Validation';
import {ValidationType} from '../../../validation/ValidationType';
import {MaskedTextField} from '../../../common/components/masked-text-field/MaskedTextField';
import classNames from 'classnames';
import {Button} from '../../../common/components/button/Button';
import SponsoredAppointment from './SponsoredAppointment';
import AppStore from '../../../assets/images/AppStore.svg';
import GooglePlay from '../../../assets/images/GooglePlay.svg';
import * as usersActions from '../../../store/actions/users/users-action';
import {getUserData} from '../../../utils/locale-storage-service';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {GeneralPaths} from '../../../utils/constants';
import * as usersActionTypes from '../../../store/actions/users/users-types';
import {isAndroid, isIOS} from 'react-device-detect';
import Loader from '../../../common/components/loader/Loader';
import OnboardingLastStepImage from '../../../assets/images/Onboarding-Last-Step-Image.png';
import {useIntercom} from 'react-use-intercom';
import {Link} from 'react-router-dom';

interface IProfileInfoOnBoardingFormData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  AddressLine1: string;
  AddressLine2: string;
  city: string;
  state: string;
  zip: string;
}

const validationRules: IValidationRules = {
  firstName: [{type: ValidationType.REQUIRED}],
  lastName: [{type: ValidationType.REQUIRED}],
  dateOfBirth: [{type: ValidationType.REQUIRED}, {type: ValidationType.BIRTH_DATE}],
  email: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_EMAIL}],
  phoneNumber: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_PHONE_NUMBER}],
  AddressLine1: [{type: ValidationType.REQUIRED}],
  city: [{type: ValidationType.REQUIRED}],
  state: [{type: ValidationType.REQUIRED}],
  zip: [{type: ValidationType.REQUIRED}],
};

const SponsoredUserOnboardingPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [data, setData] = useState<IProfileInfoOnBoardingFormData>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    AddressLine1: '',
    AddressLine2: '',
    city: '',
    state: '',
    zip: '',
  });
  const [errors, setErrors] = useState<any>({});
  const [userData, setUserData] = useState(null);
  const [activeScreen, setActiveScreen] = useState("beforeSchedule");
  const [isChanged, setIsChanged] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const [apiPhoneError, setApiPhoneError] = useState(null);
  const [apiEmailError, setApiEmailError] = useState(null);
  const [globalError, setGlobalError] = useState('');
  const [apiCall, setApiCall] = useState(false);
  const [enrollmentId, setEnrollmentId] = useState(1);
  const dispatch = useDispatch();
  const {trackEvent} = useIntercom();

  useEffect(() => {
    const userInfo = getUserData();
    if (userInfo) {
      const loadCoachInfo = async () => {
        const user = await usersActions.getUserById(userInfo.id);
        setUserData(user);
        let date = new Date(user?.dateOfBirth);
        date =
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + date.getDate()).slice(-2) +
          '-' +
          date.getFullYear();
        const phoneNumberFormat = user?.phoneNumber
          .replace(/\D/g, '')
          .match(/(\d{3})(\d{3})(\d{4})/);
        const phoneNumber =
          phoneNumberFormat[1] + '-' + phoneNumberFormat[2] + '-' + phoneNumberFormat[3];
        setEnrollmentId(user?.enrollmentStatusId ? user?.enrollmentStatusId : 0);
        setData({
          firstName: user?.firstName,
          lastName: user?.lastName,
          dateOfBirth: date.toString(),
          email: user?.email,
          phoneNumber: phoneNumber,
          AddressLine1: user?.address?.addressLine1,
          AddressLine2: user?.address?.addressLine2,
          city: user?.address?.city,
          state: user?.address?.state,
          zip: user?.address?.zip,
        });
        // console.log('user-', user);
        if (user?.phoneStatus === '0') {
          setApiPhoneError({
            isError: false,
            errorMsg:
              'The number you entered is not a valid mobile phone number. Please ensure your number does not have any spaces. If you don’t have a mobile number please contact support in the button on the bottom right.',
          });
        } else if (user?.phoneStatus === '2') {
          setApiPhoneError({
            isError: false,
            errorMsg:
              'The given phone number is a landline please change it to mobile to receive text messages.',
          });
        }
      };
      loadCoachInfo();
    }

    // trackEvent('Onboarding Page');

    // eslint-disable-next-line prefer-rest-params
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = {hjid: 3335921, hjsv: 6};
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);
  useEffect(() => {
    // Load the script only once the component has mounted
    const script = document.createElement('script');
    script.id = 'EmbedSocialReviewsScript';
    script.src = 'https://embedsocial.com/embedscript/ri.js';
    document.getElementsByTagName('head')[0].appendChild(script);

    // Clean up the script on component unmount (optional)
    return () => {
      const scriptElement = document.getElementById('EmbedSocialReviewsScript');
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, []);
  const trackEventFunction = () => {
    trackEvent('Onboarding Page');
  };
  useEffect(() => {
    // Define the function that tracks the event
   

    // Attach the event listener on component mount
    document.addEventListener('customEvent', trackEventFunction);

    // Create and dispatch the custom event
    const customEvent = new Event('customEvent');
    document.dispatchEvent(customEvent);

    // Remove the event listener on component unmount
    return () => {
      document.removeEventListener('customEvent', trackEventFunction);
    };
  }, []);

  useEffect(() => {
    const userId = userData?.id || null; // Replace your_user_id with your own if available.
    window.hj('identify', userId, {
      // Add your own custom attributes here. Some EXAMPLES:
      // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
      // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
      // 'Total purchases': 15, // Send numbers without quotes.
      // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
      // 'Last refund date': null, // Send null when no value exists for a user.
    });
  }, [userData]);

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const handleChange = (e: any) => {
    if (!isChanged) setIsChanged(true);
    const value = e.target.value;
    const name = e.target.name;
    setApiPhoneError(null);
    setApiEmailError(null);
    setGlobalError('');
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateToSubmit = () => {
    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const history = useHistory();
  const redirectWeb = async () => {
    const loadInfo = async () => {
      const user = await usersActions.getUserById(userData?.id);
      dispatch({
        type: usersActionTypes.LOGIN,
        payload: user,
      });
    };
    await loadInfo();
    await history.replace({
      pathname: `${GeneralPaths.NourishPage}`,
      search: '',
    });
  };

  const handleScreens = (screen) => {
    if (isAndroid || isIOS) {
      setActiveScreen(screen);
    } else {
      if (screen === 'afterSchedule') {
        redirectWeb();
      } else {
        setActiveScreen(screen);
      }
    }
    document.getElementsByClassName('rooContainer')[0].scrollTop = 0;
  };
  const handelUserStatusUpdate = () =>{
 
    const payload = {
      "userId": userData?.id,
      "email": data?.email,
      "onBoardingStatus": 2
    }
    dispatch(usersActions.updateOnboardUserStatus(payload)).then((res: any) => {
      if (res) {
        setApiCall(false);
        // console.log("API response", res)
      }
    })
    handleScreens('duringSchedule');
  }
  const handleSubmit = () => {
    const phone = data?.phoneNumber.replaceAll
      ? data?.phoneNumber.replaceAll('-', '')
      : data?.phoneNumber;
    const payload = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phoneNumber: phone,
      dateOfBirth: data?.dateOfBirth,
      onBoardingStatus: 2,
      phoneStatus: 1,
      enrollmentStatusId: enrollmentId,
      address: {
        addressLine1: data?.AddressLine1,
        addressLine2: data?.AddressLine2,
        city: data?.city,
        state: data?.state,
        zip: data?.zip,
      },
    };
    payload.id = userData?.id;
    setApiCall(true);
    dispatch(usersActions.updateOnboardUser(payload)).then((res: any) => {
      if (res) {
        setApiCall(false);
        if (res?.statusId === 1) {
          if (res?.additionalData) {
            localStorage.setItem('id-token', res?.additionalData);
          }
          handleScreens('duringSchedule');
        }
        if (res?.statusId === 12) {
          setApiPhoneError({
            isError: false,
            errorMsg:
              'Phone number you entered is not a valid mobile phone number. Please ensure your number does not have any spaces. If you don’t have a mobile number please contact support in the button on the bottom right.',
          });
        }
        if (res?.statusId === 3) {
          setApiPhoneError({
            isError: false,
            errorMsg: 'Phone number is used by another account. Enter the unique phone number.',
          });
        }
        if (res?.statusId === 2) {
          setApiEmailError({
            isError: false,
            errorMsg: 'Email is used by another account. Enter the unique email address.',
          });
        }
        if (res?.statusId === 0) {
          setApiPhoneError({
            isError: false,
            errorMsg: 'Phone number is used by another account. Enter the unique phone number.',
          });
          setApiEmailError({
            isError: false,
            errorMsg: 'Email is used by another account. Enter the unique email address.',
          });
        }
        if (res?.statusId === 11) {
          setGlobalError('Something went wrong. Please try again.');
        }
        if (res?.statusId === 4) {
          setGlobalError(
            'Two diffrent User exists one with email address and another with phone number',
          );
        }
        if (res?.statusId === 13) {
          setGlobalError(res?.responseMessage);
        }
      } else {
        // console.log('');
      }
    });
  };

  const renderComponent = () => {
    switch (activeScreen) {
      case 'beforeSchedule':
        return (
          <div className={classes.paperContent} >
             <div style={{textAlign:'center'}}>
            <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
            </div>
            {/* <h1>Schedule a call</h1>
              <p>Your account is verified. Now, you need to schedule a call with your coach to learn more about Betr program.</p> */}
            {/* <h1 className="bH1">Please Don&lsquo;t Skip This Step</h1> */}
            {/* <h1 className="bH1">Welcome {data?.firstName}!</h1> */}
            <h2 style={{display:'flex', justifyContent:'center'}}>
              Welcome, <span className="camelcase">{data?.firstName}</span>!
            </h2>
            <span style={{lineHeight:1.6}}>
              {/* <span className="camelcase">Hi {data?.firstName}</span>,<br /> */}
              With Betr you can say goodbye to diets, counting calories, and knee-buckling workouts.
              {/* {`After you schedule your coach call we give you instant access to the community, app, and program${
                userData?.tags?.includes(12) ? ' to earn your FREE FITBIT' : ''
              }.`} */}
            </span><br></br>
            <span style={{lineHeight:1.6}}>Your scale is on its way and now we want to make sure Betr is truly right for you.</span>
            {/* <p>We will also ship out your FREE Bathroom scale.</p> */}
            {/* <p>counting calories and knee-buckling workouts</p>
            <p>Your scale is on its way and now we want</p>
            <p>to make sure Betr is truly right for you</p> */}
            <p>
              <img src={OnboardingLastStepImage} alt="" style={{width: '100%'}} />
            </p>
            {/* <p>
              This one-on-one phone call with your coach is designed to make this program fit your
              tastes, time, and lifestyle.
            </p> */}
            <span style={{lineHeight:1.6}}>
            The program, scale, and coach are covered at no cost to you. Schedule a quick 15 min. consultation to see if Betr is right for you.
            </span>
            {/* <p>
              You Deserve Betr,
              <br />
              Dr. William Ferro
            </p>
            <p style={{padding: '0 20px', fontWeight: 'bold'}}>
              P.S. On your call with your coach you can decide if this is right for you or not :)
            </p> */}
            <Button
              id="continue"
              onClick={() => handelUserStatusUpdate()}
              // loading={isLoading}
              // disabled={!validateToSubmit()}
              className={classes.bottomButton}
              style={{marginTop:10}}
            >
              Schedule
            </Button>
            <div style={{marginTop: 10}} className="embedsocial-reviews" data-ref="b76e8a2ae66b72a167b5244f4bc4ce830ff3045c"></div>
          </div>
        );
      case 'duringSchedule':
        return (
          <SponsoredAppointment
            activeScreen={activeScreen}
            handleScreens={handleScreens}
            data={data}
            userId={userData?.id}
          />
        );

      case 'afterSchedule':
        return (
          <div className={classes.paperContent}>
            <h1>
              Congratulations, <span className="camelcase">{data?.firstName}</span>!
            </h1>
            <p>You have successfully scheduled a call with your coach.</p>
            <p>
              Your coach will introduce you to basics and guide you step by step through your
              journey.
            </p>
            <p>For the best experience please download our mobile app.</p>
            {/* <Button
                      id="continue"
                      // onClick={handleSubmit}
                      // loading={isLoading}
                      // disabled={!validateToSubmit()}
                      className={classes.bottomButton}
                  >Schedule</Button> */}
            <div className={classes.buttonsContainer}>
              <a href="https://apps.apple.com/us/app/betr/id1598906964" style={{marginRight: 15}}>
                <img src={AppStore} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.betrhealth.app">
                <img src={GooglePlay} alt="" />
              </a>
            </div>
            <br />
            <div style={{textAlign: 'center'}}>
              <a href="javascript:void(0)" onClick={() => redirectWeb()}>
                Continue to web app
              </a>
            </div>
          </div>
        );
      default:
        return (
          <div className={classes.paperContent}>
            {apiCall && (
              <div className={classes.loaderLayer}>
                <Loader />
              </div>
            )}
            <h1 style={{display:'flex', justifyContent:'center'}}>
              Welcome, <span className="camelcase">{data?.firstName}</span>!
            </h1>
            <p style={{display:'flex', textAlign:'center'}}>
              We are going to make this easy! Please take a moment to verify your details and
              schedule a call with your dedicated coach. Expect your FREE bathroom scale to arrive
              in the next few days
              {/* {
                userData?.phoneStatus === "0" ?
                'The phone number is invalid and they should change.' :
                userData?.phoneStatus === "2" ?
                  'The given phone is a landline and they should change it to mobile to receive text.' :
                  'If something is missing please fill in to continue.'
              } */}
            </p>
            {globalError && <p class="errorP">{globalError}</p>}
            {/* <div className='fieldBox'>
                <label>Sponsor Code*</label>
                <div className='code-fieldBox'>
                    <TextField name="code1" />
                    <TextField name="code2" />
                    <TextField name="code3" />
                    <TextField name="code4" />
                </div>
            </div> */}
            <div className="fieldBox">
              <TextField
                name={'firstName'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.firstName')}
                value={data.firstName}
                placeholder={t('OnBoardingPage.profileInfo.firstName')}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'firstName')}
                helperText={getError(errors, 'firstName')}
                onBlur={() => validateFormField('firstName')}
              />
            </div>
            <div className="fieldBox">
              <TextField
                name={'lastName'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.lastName')}
                value={data.lastName}
                placeholder={t('OnBoardingPage.profileInfo.lastName')}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'lastName')}
                helperText={getError(errors, 'lastName')}
                onBlur={() => validateFormField('lastName')}
              />
            </div>
            <div className="fieldBox">
              <MaskedTextField
                name={'dateOfBirth'}
                className={classNames(classes.maskedPickerWidth, classes.dateOfBirthWidth)}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.dateOfBirth')}
                value={data.dateOfBirth}
                placeholder={t('OnBoardingPage.profileInfo.dateOfBirthPlaceholder')}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'dateOfBirth')}
                helperText={getError(errors, 'dateOfBirth')}
                onBlur={() => validateFormField('dateOfBirth')}
                mask="Md-Dd-dddd"
                formatChars={{
                  M: '[0-1]',
                  D: '[0-3]',
                  d: '[0-9]',
                }}
              />
            </div>
            <div className="fieldBox">
              <TextField
                name={'email'}
                type={'email'}
                className={classNames(classes.normalTextField, classes.emailTextField)}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.email')}
                value={data.email}
                placeholder={t('OnBoardingPage.profileInfo.email')}
                onChange={(event) => handleChange(event)}
                error={
                  apiEmailError && !apiEmailError?.isError
                    ? !apiEmailError?.isError
                    : hasError(errors, 'email')
                }
                helperText={
                  apiEmailError && !apiEmailError?.isError
                    ? apiEmailError?.errorMsg
                    : getError(errors, 'email')
                }
                onBlur={() => validateFormField('email')}
              />
            </div>
            <div className="fieldBox">
              <MaskedTextField
                name={'phoneNumber'}
                className={classes.maskedPickerWidth}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={t('OnBoardingPage.profileInfo.phoneNumber')}
                value={data.phoneNumber}
                placeholder={t('OnBoardingPage.profileInfo.phoneNumberPlaceholder')}
                onChange={(event) => handleChange(event)}
                error={
                  apiPhoneError && !apiPhoneError?.isError
                    ? !apiPhoneError?.isError
                    : hasError(errors, 'phoneNumber')
                }
                helperText={
                  apiPhoneError && !apiPhoneError?.isError
                    ? apiPhoneError?.errorMsg
                    : getError(errors, 'phoneNumber')
                }
                onBlur={() => validateFormField('phone')}
                mask="999-999-9999"
              />
            </div>
            <div className="fieldBox">
              <TextField
                name={'AddressLine1'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'Address 1'}
                value={data.AddressLine1}
                placeholder={'Address 1'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'AddressLine1')}
                helperText={getError(errors, 'AddressLine1')}
                onBlur={() => validateFormField('AddressLine1')}
              />
            </div>
            <div className="fieldBox">
              <TextField
                name={'AddressLine2'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={false}
                label={'Address 2'}
                value={data.AddressLine2}
                placeholder={'Address 2'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'AddressLine2')}
                helperText={getError(errors, 'AddressLine2')}
                onBlur={() => validateFormField('AddressLine2')}
              />
            </div>
            <div className="fieldBox">
              <TextField
                name={'city'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'City'}
                value={data.city}
                placeholder={'City'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'city')}
                helperText={getError(errors, 'city')}
                onBlur={() => validateFormField('city')}
              />
            </div>
            <div className="fieldBox">
              <TextField
                name={'state'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'state'}
                value={data.state}
                placeholder={'State'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'state')}
                helperText={getError(errors, 'state')}
                onBlur={() => validateFormField('state')}
              />
            </div>
            <div className="fieldBox">
              <TextField
                name={'zip'}
                className={classes.normalTextField}
                containerClass={classes.textFieldContainerMargin}
                required={true}
                label={'Zip Code'}
                value={data.zip}
                placeholder={'Zip Code'}
                onChange={(event) => handleChange(event)}
                error={hasError(errors, 'zip')}
                helperText={getError(errors, 'zip')}
                onBlur={() => validateFormField('zip')}
                inputProps={{maxLength: 5}}
              />
            </div>
            <div>
              By creating the account, I agree to let Betr Health contact me via email, phone, or
              sms and I also agree to the <Link to="/terms">terms of service</Link>
              and the Betr Health <Link to="/privacy">privacy policy</Link>. I also understand
              standard rates may apply for sms and I can opt-out at any time by replying STOP.
            </div>
            <Button
              id="continue"
              onClick={handleSubmit}
              // loading={isLoading}
              disabled={apiPhoneError ? true : apiEmailError ? true : !validateToSubmit()}
              className={classes.bottomButton}
            >
              Confirm & Schedule
            </Button>
          </div>
        );
    }
  };

  return (
    <div className={classes.posterContainer}>
      {activeScreen != 'beforeSchedule' && (
        <div>
          <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
        </div>
      )}
      {renderComponent()}
      {/* <SponsoredAppointment handleScreens={handleScreens} /> */}
    </div>
  );
};

SponsoredUserOnboardingPage.displayName = 'SponsoredUserOnboarding Page';
export default SponsoredUserOnboardingPage;
