import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  sideMenuBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: (props) => (props.smallScreens ? '0 0 100px 0' : 0),
    marginTop: 0,
    marginRight: (props) => (props.smallScreens ? 0 : '24px'),
    marginLeft: (props) => (props.smallScreens ? 0 : '24px'),
    marginBottom: 0,
    width: (props) => (props.smallScreens ? `100%` : 310),
    minWidth: 310,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: (props) => (props.smallScreens ? 'calc(100vh - 30px)' : '80vh'),
    // position: '-webkit-sticky' /* for Safari */,
    position: 'sticky',
    top: (props) => (props.smallScreens ? 56 : 104),
    alignSelf: 'flex-start' /* <-- this is the fix */,
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& div.#menu-phaseSelect': {
      zIndex: 9999999999,
    },
    '&  p.MuiTypography-root': {
      color: (props) => (props.smallScreens ? '#000000' : ''),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: (props) => (props.smallScreens ? 'none' : `1px solid ${theme.palette.primary.main}`),
    borderBottom: (props) =>
      props.smallScreens ? 'none' : `1px solid ${theme.palette.primary.main}`,
    margin: (props) => (props.smallScreens ? 0 : '20px 0'),
    padding: (props) => (props.smallScreens ? '5px 0' : '15px 0'),
    width: (props) => (props.smallScreens ? '100%' : 'auto'),
    '& > div': {
      width: '100%',
    },
  },
}));

export default useStyles;
