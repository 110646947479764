import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    modalContainr: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 630,
        width: "100%",
        overflowY: "auto", 
        background: '#FFF', 
        borderRadius: 12,
        padding: 40,
        fontSize: 16,
        lineHeight: '24px',
        '& a': {
            color: '#000000'
        },
        '& h3': {
            fontSize: 24,
            lineHeight: '28px',
            margin: '0 0 20px 0',
        },
        '& .footer-buttons': {
            marginTop: 40,
            textAlign: 'right'
        },
        '& button': {
            padding: 15,
            marginLeft: 5
        }
    },
  });

  export default useStyles;