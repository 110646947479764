import {EMPTY_FIELD_PLACEHOLDER} from './constants';

export const splitCamelCase = (value: string): string => {
  return value.replace(/([a-z](?=[A-Z]))/g, '$1 ');
};

export const toUpperCaseLetter = (value: string): string => {
  return value?.charAt(0).toUpperCase() + value?.slice(1);
};

export const toLowerCaseLetter = (value: string): string => {
  return value?.charAt(0)?.toLowerCase() + value?.slice(1);
};

export const addLeadingZeros = (value: number): string => {
  return value.toString().padStart(4, '0');
};

export const formatDuration = (value: number): string => {
  const padded = addLeadingZeros(value);
  const hours = padded.substr(0, 2);
  const minutes = padded.substr(2, 4);

  return hours + 'h ' + minutes + 'm';
};

export const transformPhoneNumber = (value: string): string => {
  if (!value) return EMPTY_FIELD_PLACEHOLDER;
  else return value.replace(/[0-9]/g, 'X');
};

export const mergeStrings = (value1: string, value2: string): string => {
  if (value1 && value2) {
    return value1 + ' ' + value2;
  } else return EMPTY_FIELD_PLACEHOLDER;
};

export const noteStringSplitter = (s: string): [s1: string, s2: string] => {
  let middle = Math.floor(s?.length / 2);
  const before = s.lastIndexOf(' ', middle);
  const after = s.indexOf(' ', middle + 1);
  if (before == -1 || (after != -1 && middle - before >= after - middle)) {
    middle = after;
  } else {
    middle = before;
  }
  const s1 = s.substring(0, middle);
  const s2 = s.substring(middle + 1);
  return [s1, s2];
};

export const convertToCapitalRemoveUnderscore = (str: string): string => {
  let i = 0;
  const frags = str.split('_');
  for (i = 0; i < frags?.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join('');
};

export const removeFirstWord = (str: string): string => {
  const indexOfSpace = str.indexOf(' ');

  if (indexOfSpace === -1) {
    return '';
  }

  return str.substring(indexOfSpace + 1);
};
