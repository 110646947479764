export const LOAD_WEIGHT_LOG_DATA = 'LOAD_WEIGHT_LOG_DATA';
export const DELETE_WEIGHT_LOGG = 'DELETE_WEIGHT_LOGG';

export const LOAD_CATEGORY_TAGS = 'LOAD_CATEGORY_TAGS';

export const LOAD_FOOD_LOG_DATA = 'LOAD_FOOD_LOG_DATA';
export const DELETE_FOOD_LOGG = 'DELETE_FOOD_LOGG';

export const LOAD_ACTIVITY_LOG = 'LOAD_ACTIVITY_LOG';
export const DELETE_ACTIVITY_LOG = 'DELETE_ACTIVITY_LOG';
export const LOAD_ACTIVITY_TAGS = 'LOAD_ACTIVITY_TAGS';

export const LOAD_STRESS_TAGS = 'LOAD_STRESS_TAGS';
export const LOAD_MOOD_TAGS = 'LOAD_MOOD_TAGS';
export const DELETE_STRESS_LOG = 'DELETE_STRESS_LOG';
export const LOAD_STRESS_LOG = 'LOAD_STRESS_LOG';

export const DELETE_DRINK_LOG = 'DELETE_DRINK_LOG';
export const LOAD_DRINK_LOG = 'LOAD_DRINK_LOG';

export const LOAD_SLEEP_TAGS = 'LOAD_SLEEP_TAGS';
export const DELETE_SLEEP_LOG = 'DELETE_SLEEP_LOG';
export const LOAD_SLEEP_LOG = 'LOAD_SLEEP_LOG';

export const LOAD_BOWEL_TAGS = 'LOAD_BOWEL_TAGS';
export const LOAD_BOWEL_LOG = 'LOAD_BOWEL_LOG';
export const DELETE_BOWEL_LOG = 'DELETE_BOWEL_LOG';

export const DELETE_STEPS_LOG = 'DELETE_STEPS_LOG';
export const LOAD_STEPS_LOG = 'LOAD_STEPS_LOG';

export const LOAD_BODY_MEASUREMENT_LOG = 'LOAD_BODY_MEASUREMENT_LOG';
export const DELETE_BODY_MEASUREMENT_LOG = 'DELETE_BODY_MEASUREMENT_LOG';

export const DELETE_BLOOD_PRESSURE_LOG = 'DELETE_BLOOD_PRESSURE_LOG';
export const LOAD_BLOOD_PRESSURE_LOG = 'LOAD_BLOOD_PRESSURE_LOG';

export const DELETE_BLOOD_GLUCOSE_LOG = 'DELETE_BLOOD_GLUCOSE_LOG';
export const LOAD_BLOOD_GLUCOSE_LOG = 'LOAD_BLOOD_GLUCOSE_LOG';

export const DELETE_BODY_PAIN_LOG = 'DELETE_BODY_PAIN_LOG';
export const LOAD_BODY_PAIN_LOG = 'LOAD_BODY_PAIN_LOG';

export const LOAD_USERS_TAGS = 'LOAD_USERS_TAGS';
export const CHECK_ACHIEVEMENT = 'CHECK_ACHIEVEMENT';

export const DELETE_CHOLESTEROL_LOG = 'DELETE_CHOLESTEROL_LOG';
export const LOAD_CHOLESTEROL_LOG = 'LOAD_CHOLESTEROL_LOG';

export const DELETE_A1C_LOG = 'DELETE_A1C_LOG';
export const LOAD_A1C_LOG = 'LOAD_A1C_LOG';
export const LOAD_HISTORY_FILTER = 'LOAD_HISTORY_FILTER';
