import {ReactComponent as NourishmentIcon} from '../../../assets/icons/side-menu-bar-nourish/nourishment.svg';
import {ReactComponent as MealPlansIcon} from '../../../assets/icons/side-menu-bar-nourish/meal-plans.svg';
import {ReactComponent as RecipesIcon} from '../../../assets/icons/side-menu-bar-nourish/recipes.svg';
import {ReactComponent as BetrProgramIcon} from '../../../assets/icons/side-menu-bar-nourish/betr-program.svg';
import {ReactComponent as ResourceIcon} from '../../../assets/icons/side-menu-bar-nourish/food-lists.svg';
import {ReactComponent as MovementIcon} from '../../../assets/icons/side-menu-bar-nourish/movement.svg';
import {ReactComponent as PodCastIcon} from '../../../assets/icons/side-menu-bar-nourish/podcast.svg';
// import {ReactComponent as MindfulnessIcon} from '../../../assets/icons/side-menu-bar-nourish/Mindfulness.svg';
import {Pages, SideMenuSelectedType} from '../../../utils/enums';

/* eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types */
const generateMenuBarOptions = (useTranslation: any, isTag11: any): any => {
  const [t] = useTranslation();
  const menus = {
    [Pages.NOURISH_PAGE]: [
      {
        Icon: NourishmentIcon,
        text: t('NourishPage.sideMenuBar.myNourishment'),
        elementType: SideMenuSelectedType.SIDEMENU_NOURISHMENT,
      },
      {
        Icon: BetrProgramIcon,
        text: t('NourishPage.sideMenuBar.betrProgram'),
        elementType: SideMenuSelectedType.SIDEMENU_BETR_PROGRAM,
        isCollapsible: true,
        hasFilter: true,
      },
      {
        Icon: ResourceIcon,
        text: t('NourishPage.sideMenuBar.foodLists'),
        elementType: SideMenuSelectedType.SIDEMENU_FOOD_LISTS,
        isCollapsible: false,
        hasFilter: false,
      },
      {
        Icon: MealPlansIcon,
        text: t('NourishPage.sideMenuBar.mealPlans'),
        elementType: SideMenuSelectedType.SIDEMENU_MEALPLANS,
        isCollapsible: true,
        hasFilter: true,
      },
      {
        Icon: RecipesIcon,
        text: t('NourishPage.sideMenuBar.recipes'),
        elementType: SideMenuSelectedType.SIDEMENU_RECIPES,
        isCollapsible: true,
        hasFilter: true,
      },
       {
        Icon: PodCastIcon,
        text: t('NourishPage.sideMenuBar.podCast'),
        elementType: SideMenuSelectedType.SIDEMENU_PODCAST,
        isCollapsible: false,
        hasFilter: false,
      },
      // {
      //   Icon: MindfulnessIcon,
      //   text: t('NourishPage.sideMenuBar.mindfulness'),
      //   elementType: SideMenuSelectedType.SIDEMENU_MINDFULLNESS,
      //   isCollapsible: false,
      //   hasFilter: false,
      // },
      // {
      //   Icon: MasterClassesIcon,
      //   text: t('NourishPage.sideMenuBar.masterClasses'),
      //   elementType: SideMenuSelectedType.SIDEMENU_MASTER_CLASSES,
      //   isCollapsible: true,
      //   hasFilter: true,
      // },
    ],
  };
  if (!isTag11) {
    menus[Pages.NOURISH_PAGE].push({
      Icon: MovementIcon,
      text: t('NourishPage.sideMenuBar.movement'),
      elementType: SideMenuSelectedType.SIDEMENU_MOVEMENT,
      isCollapsible: true,
      hasFilter: true,
    });
  }
  return menus;
};

export default generateMenuBarOptions;
