import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  rowTextField: {
    display: 'flex',
  },
  stepsLabel: {
    marginBottom: 8,
  },
  sourceSelect: {
    width: (props) => (props.isMobile ? '100%' : '177px'),
    '& >div': {
      width: '100%',
    },
  },
  subForms: {
    marginTop: '24px',
  },
}));

export default useStyles;
