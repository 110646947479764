import * as React from 'react';
import {useState} from 'react';
import useStyles from './ellipsis-options.styles';
import EllipsisOptionIcon from '../../../assets/icons/elipsis-options/elipsis-options-icon.svg';
import CloseOptionsIcon from '../../../assets/icons/elipsis-options/close-menu-icon.svg';
import {EllipsisType} from '../../../utils/enums';
import {AlertDialog} from '../dialog/AlertDialog';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

interface EllipsisOptionsCustomProps {
  className?: string;
  messageBox?: any;
  optionsClass?: string;
  imageClass?: string;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const EllipsisOptionsCustom: React.FC<EllipsisOptionsCustomProps> = ({
  messageBox,
  imageClass,
  optionsClass,
}) => {
  const classes = useStyles();
  const [ellipsisMode, setEllipsisMode] = useState(EllipsisType.SHOW_ELLIPSIS);
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickConfirm = () => {
    setOpen(false);
  };

  const handleClickDismiss = () => {
    setOpen(false);
  };

  const handleChangeMode = (ellipsisType: EllipsisType) => {
    setEllipsisMode(ellipsisType);
  };

  return (
    <React.Fragment>
      {ellipsisMode === EllipsisType.SHOW_ELLIPSIS && (
        <div
          onClick={() => handleChangeMode(EllipsisType.SHOW_OPTIONS)}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <img
            className={classNames(imageClass, classes.optionsItemImage)}
            src={EllipsisOptionIcon}
            alt={'ellipsis-img'}
          />
        </div>
      )}

      {ellipsisMode === EllipsisType.SHOW_OPTIONS && (
        <div className={classNames(optionsClass, classes.optionsContent)}>
          <div
            onClick={() => handleChangeMode(EllipsisType.SHOW_ELLIPSIS)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <img
              className={classNames(imageClass, classes.optionsItemImage)}
              src={CloseOptionsIcon}
              alt={'close-img'}
            />
          </div>
          {messageBox}
        </div>
      )}
      <AlertDialog
        confirmButtonText={t('LogsPage.ellipsis.confirmButtonText')}
        dismissButtonText={t('LogsPage.ellipsis.dismissButtonText')}
        headerText={t('LogsPage.ellipsis.headerText')}
        contentText={t('LogsPage.ellipsis.contentText')}
        handleConfirm={handleClickConfirm}
        handleDismiss={handleClickDismiss}
        dialogState={open}
      />
    </React.Fragment>
  );
};

EllipsisOptionsCustom.displayName = 'EllipsisOptions';
