import {get, customRequest} from './api-calls-strapi';
import {ICuisineDto} from '../dtos/ICuisineDto';
import {ICourseDto} from '../dtos/ICourseDto';
import {ILevelCategoryDto} from '../dtos/ILevelCategoryDto';
import {ICategoryIngredientsDto} from '../dtos/ICategoryIngredientsDto';
import {IRecipeFiltersDto} from '../dtos/IRecipeFiltersDto';
import {ICategoryDto} from '../dtos/ICategoryDto';
import {IMealPlansFiltersDto} from '../dtos/IMealPlansFiltersDto';
import {IRecipeDto} from '../dtos/IRecipeDto';
import {IMealPlanDto} from '../dtos/IMealPlanDto';
import {getQuery} from '../utils/qs-helper';
import {normalizeStrapiPagination} from '../utils/modifiers';
import {ICardDto, IRightCardDto} from '../dtos/ICardDto';
import {IDailyContentCategoryDto} from '../dtos/IDailyContentCategoryDto';
import {IBetrProgramFilterDto} from '../dtos/IBetrProgramFilterDto';
import {IDailyContentDto} from '../dtos/IDailyContentDto';
import { IMindfulnessDto } from '../dtos/IMindfulnessDto';
import { IPodcastDto } from '../dtos/IPodCastDto';
import { IYogaDto } from '../dtos/IYogaDto';

export const getRecipes = (
  queryParams?: IRecipeFiltersDto,
): Promise<{data: IRecipeDto[]; headers: any}> => {
  const requestParams = {
    ...normalizeStrapiPagination(queryParams?.pagination),
    ...queryParams?.filter,
    minimal: queryParams?.minimal,
  };
  const query = getQuery(requestParams);
  return customRequest(`/recipes${query}`, {method: 'get', exposeResponseHeaders: true});
};

export const getRecipe = (slug?: string): Promise<IRecipeDto[]> => {
  //TODO: Sending slug_eq instead of _slug is changed with reason of incosentent data retrieval for the same thing
  // Here was the problem with level_category which comes in different format depending on param

  const query = getQuery({slug_eq: slug});
  return get(`/recipes${query}`);
};
export const getYogas = (slug?: string): Promise<IRecipeDto[]> => {
  //TODO: Sending slug_eq instead of _slug is changed with reason of incosentent data retrieval for the same thing
  // Here was the problem with level_category which comes in different format depending on param
  // eslint-disable-next-line no-debugger
  const query = getQuery({slug: slug});
  return get(`/Yogas${query}`);
};
export const getCuisines = (): Promise<ICuisineDto[]> => {
  return get('/cuisine-categories');
};

export const getCourses = (): Promise<ICourseDto[]> => {
  return get('/course-categories');
};
export const getMindfulness = (): Promise<IMindfulnessDto[]> => {
  return get('/Mindfulnesses');
};
export const getPodCast = (): Promise<IPodcastDto[]> => {
  return get('/Podcasts');
};
export const getYoga = (): Promise<IYogaDto[]> => {
  return get('/Yogas');
};
export const getCoursesWithIngredients = (): Promise<any[]> => {
  return get('/ingredient-categories');
};

export const getLevelCategories = (): Promise<ILevelCategoryDto[]> => {
  return get('/level-categories');
};

export const getIngredientsCategories = (): Promise<ICategoryIngredientsDto[]> => {
  return get('/ingredient-categories/list/parents');
};

export const getIngredientsCategoriesWithIngredients = (): Promise<ICategoryIngredientsDto[]> => {
  return get('/ingredient-categories/list/parent/child');
};

export const getCategories = (): Promise<ICategoryDto[]> => {
  return get('/categories');
};

export const getMealPlans = (queryParams?: IMealPlansFiltersDto): Promise<any[]> => {
  const requestParams = {
    ...queryParams?.pagination,
    ...queryParams?.filter,
  };
  const query = getQuery(requestParams);
  return get(`/meal-plan-cards${query}`);
};

export const getMealPlan = (mealPlanSlug: string): Promise<IMealPlanDto[]> => {
  return get(`/meal-plan?_slug=${mealPlanSlug}`);
};

export const getCards = (): Promise<ICardDto[]> => {
  return get('/cards?_sort=mainSectionOrder:ASC');
};
export const getRightCards = (): Promise<IRightCardDto[]> => {
  return get('/cards?showInRightNav_eq=true&_sort=rightNavOrder:ASC');
};

export const getDailyContentCategoriesWithChildren = (): Promise<IDailyContentCategoryDto[]> => {
  return get('/daily-content-categories/list/parent/child');
};

export const getDailyContentCategories = (): Promise<IDailyContentCategoryDto[]> => {
  return get('/daily-content-categories');
};

export const getDailyContents = (
  queryParams?: IBetrProgramFilterDto,
): Promise<{data: IDailyContentDto[]; headers: any}> => {
  const requestParams = {
    ...normalizeStrapiPagination(queryParams?.pagination),
    ...queryParams?.filter,
    // minimal: queryParams?.minimal,
  };
  const query = getQuery(requestParams);
  return customRequest(`/daily-contents${query}`, {method: 'get', exposeResponseHeaders: true});
};

export const getDailyContent = (dailyContentSlug: string): Promise<IDailyContentCategoryDto[]> => {
  return get(`/daily-contents?_slug=${dailyContentSlug}`);
};
