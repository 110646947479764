import {Box, Grid, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectMeasurementUnit, selectPhases} from '../../../store/selectors/homepage.selectors';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {
  BodyMeasurementType,
  EMPTY_FIELD_PLACEHOLDER,
  ONLY_DATE_FORMAT,
} from '../../../utils/constants';
import {dateToString} from '../../../utils/date-time-utils';
import {transformPhoneNumber} from '../../../utils/StringHelpers';
import {PaperWrapper} from '../paper-wrapper/PaperWrapper';
import useStyles from './profile-info.styles';
import {calculateHeightToDisplay} from '../../../utils/helpers';

const ProfileInfo: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const user = useSelector(selectCurrentUser);
  const selectedMeasurementUnit = useSelector(selectMeasurementUnit);
  const loadedPhases = useSelector(selectPhases);
  const [phase, setSelectedPhase] = useState<string | undefined>('');
  const height = calculateHeightToDisplay(user?.height || 0, selectedMeasurementUnit?.id);

  useEffect(() => {
    if (loadedPhases?.length > 0) {
      const phaseName = loadedPhases.find((phase) => phase.id === user.phaseId)?.name;
      setSelectedPhase(phaseName);
    }
  }, []);

  return (
    <PaperWrapper>
      <Box mb={2}>
        <Typography variant={'subtitle2'} align="left">
          {t(`ProfilePage.basicInfo.title`)}
        </Typography>
      </Box>
      <Grid container spacing={7}>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.firstName')}
          </Typography>
          <Typography variant={'body1'}>{user?.firstName || EMPTY_FIELD_PLACEHOLDER}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.lastName')}
          </Typography>
          <Typography variant={'body1'}>{user?.lastName || EMPTY_FIELD_PLACEHOLDER}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.dateOfBirth')}
          </Typography>
          <Typography variant={'body1'}>
            {user?.dateOfBirth
              ? dateToString(user?.dateOfBirth, ONLY_DATE_FORMAT)
              : EMPTY_FIELD_PLACEHOLDER}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.gender')}
          </Typography>
          <Typography variant={'body1'}>{user?.gender || EMPTY_FIELD_PLACEHOLDER}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.height')}
          </Typography>
          <Typography variant={'body1'}>
            {height?.length > 0 ? (
              <div className={classes.profileInfo}>
                <Typography className={classes.heightMargin}>{height && height[0]} </Typography>
                <Typography className={classes.heightMargin}>
                  {selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
                    ? t('Homepage.quickProfileUser.heightM')
                    : t('Homepage.quickProfileUser.heightFeet')}
                </Typography>

                <Typography className={classes.heightMargin}>
                  {height && height[1] ? height[1] : 0}
                </Typography>

                <Typography>{selectedMeasurementUnit?.lengthUnit}</Typography>
              </div>
            ) : (
              EMPTY_FIELD_PLACEHOLDER
            )}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={3} mb={1}>
        <Typography variant={'overline'}>{t('ProfilePage.basicInfo.betrProgram')}</Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.phase')}
          </Typography>
          <Typography variant={'body1'}>{phase || EMPTY_FIELD_PLACEHOLDER}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.trainer')}
          </Typography>
          <Typography variant={'body1'}>
            {user?.trainer ? user.trainer?.firstName : EMPTY_FIELD_PLACEHOLDER}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.phaseStartDate')}
          </Typography>
          <Typography variant={'body1'}>
            {user?.phaseStartDate
              ? dateToString(user?.phaseStartDate, ONLY_DATE_FORMAT)
              : EMPTY_FIELD_PLACEHOLDER}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={3} mb={1}>
        <Typography variant={'overline'}>{t('ProfilePage.basicInfo.contact')}</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.email')}
          </Typography>
          <Typography variant={'body1'}>{user?.email || EMPTY_FIELD_PLACEHOLDER}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} className={classes.caption}>
            {t('ProfilePage.basicInfo.phoneNumber')}
          </Typography>
          <Typography variant={'body1'}>{transformPhoneNumber(user?.phoneNumber)}</Typography>
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};
export default ProfileInfo;
