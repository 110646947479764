import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '70%',
  },
  tryAgainBtn: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

export default useStyles;
