import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import {useTranslation} from 'react-i18next';
import Fraction from 'fraction.js';
import {useReactToPrint} from 'react-to-print';
import {Typography} from '@material-ui/core';
import {TimeContainer} from './TimeContainer';
// import {RelatedRecipesView} from './RelatedRecipesView';
// import {TextField} from '../text-field/TextField';
import {LevelFlag} from '../level-flag/LevelFlag';
import {ChipTag} from '../chip-tag/ChipTag';
import Loader from '../loader/Loader';
// import {Incrementer} from '../incrementer/Incrementer';
import {Button} from '../button/Button';
import {WhiskAddToShoppingList} from '../whisk/WhiskAddToShoppingList';
// import {RatingCard} from '../rating/RatingCard';
import {getTheme} from '../../../theme/theme';
import PlaceholderImage from '../../../assets/icons/placeholders/placeholder-cooking.svg';
// import {ReactComponent as ShareIcon} from '../../../assets/icons/buttonIcons/share.svg';
import {ReactComponent as PrinterIcon} from '../../../assets/icons/buttonIcons/printer.svg';
import {ReactComponent as DownIcon} from '../../../assets/icons/rest/down-arrows.svg';
import BackIcon from '../../../assets/icons/rest/left-arrow.svg';
import {loadRecipe} from '../../../store/actions/nourish/nourish-actions';
import {selectRecipe} from '../../../store/selectors/nourish.selectors';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {EMPTY_FIELD_PLACEHOLDER} from '../../../utils/constants';
import {getColorForLevel, getHoverColorForLevel} from '../../../utils/tag-level-helpers';
// import {RatingType} from '../../../utils/enums';

import useStyles from './recipe-details-view.styles';
import {useHistory} from 'react-router';

interface Props {
  slug: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  handleDismiss: () => void;
  // onRecipesFilterChange:any;
}

export const RecipeDetailsView: React.FC<Props> = ({
  slug,
  isMobileOrTablet,
  smallScreens,
  handleDismiss,
  // onRecipesFilterChange,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const dispatch = useDispatch();
  const recipe = useSelector(selectRecipe);
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const componentRef = React.useRef(null);
  const [numberOfServing, setNumberOfServing] = useState<number>(1);
  const history = useHistory();

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        await dispatch(loadRecipe(slug));
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    setNumberOfServing(recipe?.servingsDefaultValue || numberOfServing);
  }, [recipe]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        handleDismiss();
      }
    };
  }, [history, history.location.pathname]);

  // const handleIncrementServe = () => {
  //   setNumberOfServing(numberOfServing + 1);
  // };
  // const handleDecrementServe = () => {
  //   if (numberOfServing === 1) return;
  //   setNumberOfServing(numberOfServing - 1);
  // };

  const showAmountPerServing = (amount: string, serving: number): string => {
    try {
      const decimal: any = new Fraction(amount.trim());
      return new Fraction(decimal * serving).toFraction(true);
    } catch {
      return '0';
    }
  };

  const displayIngredientPreparation = (ingredient: any): string => {
    if (
      (isNaN(ingredient?.amount) && !ingredient?.amount?.includes('/')) ||
      !ingredient?.amount ||
      ingredient?.amount.trim()?.length === 0
    ) {
      return `/ ${ingredient?.name || ''} ${ingredient?.notes || ''}`;
    }

    return `${showAmountPerServing(ingredient?.amount, numberOfServing)} ${
      ingredient?.unit || ''
    } ${ingredient?.name || ''} ${ingredient.notes || ''}`;
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const buttonContainer = () => {
    return (
      <div className={classes.buttonContainer}>
        {/* <Button
          id="share"
          variant="outlined"
          startIcon={<ShareIcon fill={getTheme().palette.primary.main} title="share" />}
          className={classes.button}
          disabled
        >
          {t('NourishPage.recipesDetails.share')}
        </Button> */}
        <Button
          id="print"
          variant="outlined"
          startIcon={<PrinterIcon fill={getTheme().palette.primary.main} title="print" />}
          className={classes.button}
          onClick={handlePrint}
        >
          {t('NourishPage.recipesDetails.print')}
        </Button>
      </div>
    );
  };

  const ratingContainer = () => {
    return (
      <div className={classes.ratingContainer}>
        {/*<RatingCard*/}
        {/*  title={t('NourishPage.recipesDetails.betrRating')}*/}
        {/*  type={RatingType.BETR}*/}
        {/*  ratingValue={recipe?.ratings}*/}
        {/*/>*/}
        {/*<RatingCard title={t('NourishPage.recipesDetails.userRating')} type={RatingType.USER} />*/}
      </div>
    );
  };

  const handleInfoEquipmentShoppingList = () => {
    return (
      <>
        <div className={classes.infoBox}>
          <div className={classes.infoBoxRow}>
            <Typography variant={'body1'}>{t('NourishPage.recipesDetails.course')}</Typography>
            <Typography variant={'subtitle1'}>
              {recipe?.course_category?.name || EMPTY_FIELD_PLACEHOLDER}
            </Typography>
          </div>
          <div className={classes.infoBoxRow}>
            <Typography variant={'body1'}>{t('NourishPage.recipesDetails.level')}</Typography>
            <Typography variant={'subtitle1'}>
              {recipe?.level_category?.name || EMPTY_FIELD_PLACEHOLDER}
            </Typography>
          </div>
          <div className={classes.infoBoxRow}>
            <Typography variant={'body1'}>{t('NourishPage.recipesDetails.cuisine')}</Typography>
            <Typography variant={'subtitle1'}>
              {recipe?.cuisine_category?.name || EMPTY_FIELD_PLACEHOLDER}
            </Typography>
          </div>
        </div>
        {!smallScreens && buttonContainer()}
        {recipe?.equipment?.length > 0 && (
          <div className={classes.equipment}>
            <Typography variant={'subtitle1'}>
              {t('NourishPage.recipesDetails.equipment')}
            </Typography>
            {recipe?.equipment?.map((item: any, index: number) => (
              <Typography variant={'caption'} key={`${item.name}-${index}`}>
                {item.name}
              </Typography>
            ))}
          </div>
        )}
        <div className={classes.shoppingList}>
          {recipe?.microFormat && <WhiskAddToShoppingList microFormat={recipe?.microFormat} />}
        </div>
      </>
    );
  };

  const handleFirstSection = () => {
    if (isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig) {
      return (
        <section>
          <LevelFlag
            className={classes.levelFlag}
            title={recipe?.level_category?.name}
            levelCategory={recipe?.level_category}
            top={isMobileOrTablet.isMobile ? 68 : 17}
            right={0}
          />
          <div
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%), url(${
                recipe?.featuredImage?.image?.url || PlaceholderImage
              })`,
            }}
            className={classes.mealImage}
          />
          <Typography variant={'h3'} align="left">
            {recipe?.title}
          </Typography>
          {/* <div className={classes.author}>
            <Typography className={classes.authorName} variant={'body2'}>
              by {recipe?.author?.username || t('NourishPage.recipesDetails.autor')}
            </Typography>
          </div> */}
          <div className={classes.firstSectionTablet}>
            <div>
              <TimeContainer
                cookTime={recipe.cookTime}
                totalTime={recipe.totalTime}
                prepTime={recipe.prepTime}
                smallScreens={smallScreens}
                isMobileOrTablet={isMobileOrTablet}
              />
              <Typography align="left" variant={'caption'} className={classes.descText}>
                {recipe?.description}
              </Typography>
              <div className={classes.ingredientTags}>
                <Typography variant={'overline'} align="left">
                  {t('NourishPage.recipesDetails.ingredientsCaps')}
                </Typography>
                <div className={classes.ingredientTagList}>
                  {recipe?.ingredient_categories?.map((ingredient: any) => {
                    return (
                      <ChipTag
                        key={`ingredient-${ingredient.id}`}
                        color={getColorForLevel(ingredient.level_category)}
                        borderColor={getColorForLevel(ingredient.level_category)}
                        hoverColor={getHoverColorForLevel(ingredient.level_category)}
                        name="chip"
                        id={ingredient.id || 0}
                        label={ingredient.name}
                        textColor={getTheme().palette.blacksAndWhites.smokyBlack}
                      />
                    );
                  })}
                </div>
              </div>
              {buttonContainer()}
            </div>
            <div>
              {ratingContainer()}
              {handleInfoEquipmentShoppingList()}
            </div>
          </div>
        </section>
      );
    }

    return (
      <section>
        <Typography variant={'h3'} align="left">
          {recipe?.title}
        </Typography>
        {/* <div className={classes.author}>
          <Typography className={classes.authorName} variant={'body2'}>
            by {recipe?.author?.username || t('NourishPage.recipesDetails.autor')}
          </Typography>
        </div> */}
        <Typography align="left" variant={'caption'} className={classes.descText}>
          {recipe?.description}
        </Typography>
        {isMobileOrTablet.isMobile && ratingContainer()}
        <TimeContainer
          cookTime={recipe.cookTime}
          totalTime={recipe.totalTime}
          prepTime={recipe.prepTime}
          smallScreens={smallScreens}
          isMobileOrTablet={isMobileOrTablet}
        />
        <div
          style={{
            backgroundImage: `url(${recipe?.featuredImage?.image?.url || PlaceholderImage})`,
          }}
          className={classes.mealImage}
        />
        <div className={classes.ingredientTags}>
          <Typography variant={'overline'} align="left">
            {t('NourishPage.recipesDetails.ingredientsCaps')}
          </Typography>
          <div className={classes.ingredientTagList}>
            {recipe?.ingredient_categories?.map((ingredient: any) => {
              return (
                <ChipTag
                  key={`ingredient-${ingredient.id}`}
                  color={getColorForLevel(ingredient.level_category)}
                  borderColor={getColorForLevel(ingredient.level_category)}
                  hoverColor={getHoverColorForLevel(ingredient.level_category)}
                  name="chip"
                  id={ingredient.id || 0}
                  label={ingredient.name}
                  textColor={getTheme().palette.blacksAndWhites.smokyBlack}
                />
              );
            })}
          </div>
        </div>
      </section>
    );
  };

  const scrollTo = (title: string) => {
    const elementToView = document.getElementById(title);
    elementToView?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={classes.recipesContainer}>
      {smallScreens && (
        <div className={classes.header}>
          <Button className={classes.back} id="back" variant="text" onClick={() => handleDismiss()}>
            <img src={BackIcon} alt="Back to recipes" />
            <Typography variant={'subtitle1'}>{t('NourishPage.recipes.title')}</Typography>
          </Button>
          {isMobileOrTablet.isMobile && (
            <div className={classes.shareAndPrint}>
              {/* <Button
                id="share"
                variant="text"
                startIcon={<ShareIcon fill={getTheme().palette.blacksAndGrays.jet} title="share" />}
                className={classes.button}
                disabled
              /> */}
              <Button
                id="print"
                variant="text"
                startIcon={
                  <PrinterIcon fill={getTheme().palette.blacksAndGrays.jet} title="print" />
                }
                className={classes.button}
                onClick={handlePrint}
              />
            </div>
          )}
        </div>
      )}
      {recipe ? (
        <div className={classes.mainContainer} ref={componentRef}>
          <div className={classes.sideContent1} />
          <div className={classes.centerContent}>
            {handleFirstSection()}
            {isMobileOrTablet.isMobile && (
              <>
                <div className={classes.linksToSections}>
                  <Button
                    id="share"
                    variant="outlined"
                    startIcon={<DownIcon fill={getTheme().palette.primary.main} title="look" />}
                    className={classes.button}
                    onClick={() => scrollTo('ingredientsSection')}
                  >
                    {t('NourishPage.recipesDetails.ingredients')}
                  </Button>
                  <Button
                    id="share"
                    variant="outlined"
                    startIcon={<DownIcon fill={getTheme().palette.primary.main} title="look" />}
                    className={classes.button}
                    onClick={() => scrollTo('instructionsSection')}
                  >
                    {t('NourishPage.recipesDetails.instructions')}
                  </Button>
                </div>
                {handleInfoEquipmentShoppingList()}
              </>
            )}
            <section id="ingredientsSection" className={classes.ingredientsSection}>
              <div  style={{display:"flex",justifyContent:'space-between'}}>
              <Typography variant={'h5'}>{t('NourishPage.recipesDetails.ingredients')}</Typography>
              
              <Typography style={{fontWeight:400}}>
              {numberOfServing} {numberOfServing > 1 ? "servings"  :  t('NourishPage.recipesDetails.serving')}
              </Typography>
              </div>
              {/* <Incrementer
                min={recipe?.servingsMinValue}
                max={recipe?.servingsMaxValue}
                value={numberOfServing}
                onIncrement={handleIncrementServe}
                onDecrement={handleDecrementServe}
                label={t('NourishPage.recipesDetails.serving')}
              /> */}
              <ul>
                {recipe?.ingredients?.map((ingredient: any, index: number) => {
                  return (
                    ingredient?.amount && (
                      <li key={`${ingredient.name}-${index}`}>
                        <ReactMarkdown
                          components={{
                            p: ({children}) => (
                              <Typography variant={'body1'}>{children}</Typography>
                            ),
                          }}
                        >
                          {displayIngredientPreparation(ingredient)}
                        </ReactMarkdown>
                      </li>
                    )
                  );
                })}
              </ul>
            </section>
            <section id="instructionsSection" className={classes.instructionsSection}>
              <Typography variant={'h5'}>
                {' '}
                {t('NourishPage.recipesDetails.instructions')}
              </Typography>
              <ul>
                {recipe?.instructions?.length > 0
                  ? recipe?.instructions?.map((instruction: any, index: number) => {
                      return (
                        <li key={`${instruction.name}-${index}`}>
                          <ReactMarkdown
                            components={{
                              p: ({children}) => (
                                <Typography variant={'body1'}>{children}</Typography>
                              ),
                            }}
                          >
                            {instruction.instruction}
                          </ReactMarkdown>
                        </li>
                      );
                    })
                  : EMPTY_FIELD_PLACEHOLDER}
              </ul>
            </section>
            <section className={classes.notesSection}>
              <Typography variant={'h5'}>{t('NourishPage.recipesDetails.notes')}</Typography>
              <ReactMarkdown
                components={{
                  li: ({children}) => (
                    <li>
                      <Typography variant={'body1'}>{children}</Typography>
                    </li>
                  ),
                }}
              >
                {recipe?.notes || EMPTY_FIELD_PLACEHOLDER}
              </ReactMarkdown>
            </section>
            {/*TODO: Fetch and display related recipies*/}
            {/*<section className={classes.carouselSection}>*/}
            {/*  <RelatedRecipesView recipes={RelatedRecipesMock}  isMobileOrTablet={isMobileOrTablet}
  smallScreens={smallScreens/>*/}
            {/*</section>*/}
            {/*<div className={classes.comments}>*/}
            {/*  <Typography variant={'h5'}>{t('NourishPage.recipesDetails.comments')}</Typography>*/}
            {/*  <TextField*/}
            {/*    name={'comment'}*/}
            {/*    multiline*/}
            {/*    minRows={4}*/}
            {/*    className={classes.wideTextField}*/}
            {/*    label={t('')}*/}
            {/*    placeholder={t('NourishPage.recipesDetails.commentsPlaceholder')}*/}
            {/*    onChange={() => console.log()}*/}
            {/*  />*/}
            {/*  <Button*/}
            {/*    id="submit-comment-id"*/}
            {/*    variant="contained"*/}
            {/*    onClick={() => console.log()}*/}
            {/*    loading={isLoading}*/}
            {/*    disabled={isLoading}*/}
            {/*  >*/}
            {/*    {t('NourishPage.recipesDetails.postComment')}*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
          <div className={classes.sideContent2}>
            {smallScreens && ratingContainer()}
            {!smallScreens && handleInfoEquipmentShoppingList()}
          </div>
        </div>
      ) : (
        <Typography className={classes.noData}>{t('NourishPage.recipesDetails.noData')}</Typography>
      )}
    </div>
  );
};
