import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  bodyPartContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: (props) => (props.isMobile ? 12 : 0),
  },
  bodyPartText: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
  },
  textLabel: {
    color: theme.palette.blacksAndWhites.sonicSilver,
  },
}));

export default useStyles;
