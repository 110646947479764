/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import {Backdrop,Box,Modal,Fade,Button,Typography} from '@material-ui/core';





import useStyles from './feedback-popup.styles';
import {useState} from 'react';
import emoji1 from '../../../../assets/images/emoji1.png';
import emoji2 from '../../../../assets/images/emoji2.png';
import emoji3 from '../../../../assets/images/emoji3.png';
import emoji4 from '../../../../assets/images/emoji4.png';
import emoji5 from '../../../../assets/images/emoji5.png';
import TransitionsModal2 from './FeedbackPopop2';
import { useDispatch, useSelector } from 'react-redux';
import { postKemTaiUserWorkoutFeedback } from '../../../../store/actions/kemtai/kemtai-action';
import ThankFeedback from './ThankFeedback';

export default function TransitionsModal({isFeedbackOne, setIsFeedbackOne, workoutID, workoutCompDetail}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = useState(true);
  const [shownextcontent, setShownextcontent] = useState(false);
  const [stephide, setStephide] = useState(true);
  const [selectedStep, setSelectedStep] = useState();
  const [sliderDefaultValue, setSliderDefaultValue] = useState(1);
  const [isNotNow, setIsNotNow] = useState(false);
  const [isThanksPopup, setIsThanksPopup] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsFeedbackOne(false)
    setStephide(true)
    setShownextcontent(false)
    setDisable(true)
  }

  const dispatch = useDispatch();
  const userData: any = useSelector((state: any) => state.users.currentUser)

  React.useEffect(() => {
    if(isFeedbackOne) {
      handleOpen()
    }
  }, [isFeedbackOne])

  function handleClick(step: any) {
    setDisable(!disable);
    setShownextcontent(!shownextcontent);
    setStephide(!stephide);
    setSelectedStep(step);
    setSliderDefaultValue(step);
  }

  const handleFeedback = () => {
    const payload = {
      userID: userData?.id,
      workoutID: workoutID,
      workoutFeedbackDetailsID: selectedStep
    }
    dispatch(postKemTaiUserWorkoutFeedback(payload)).then(() => {
      setIsNotNow(false);
      setIsThanksPopup(true);
      handleClose();
      setIsFeedbackOne(false)
      // setStephide(false);
    })
  }

  const handleNotNow = () => {
    setIsNotNow(true);
    setIsThanksPopup(true);
    handleClose();
    setIsFeedbackOne(false)
    // setStephide(false);
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Great Gob Popuop</Button> */}
      <ThankFeedback isThanksPopup={isThanksPopup} setIsThanksPopup={setIsThanksPopup} isNotNow={isNotNow} workoutCompDetail={workoutCompDetail} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.StepOneFrom}>
            <div className="StepOneFrom1">
              <Typography variant="h1">Great job!</Typography>
              {/* <p className="ptag">You’ve finished {workoutDetail?.name} of {workoutDetail?.category}.</p> */}
              <p className="ptag">You’ve finished your workout.</p>
              <div className="poppupForm">
                <h4> How was your workout?</h4>
                <p className="ptag">This will help us improve the experience for you</p>

                {stephide ? (
                  <div className="stepper">
                    <span onClick={() => handleClick(1)}>
                      <img src={emoji1} alt="" />
                    </span>
                    <span onClick={() => handleClick(2)}>
                      <img src={emoji2} alt="" />
                    </span>
                    <span onClick={() => handleClick(3)}>
                      <img src={emoji3} alt="" />
                    </span>
                    <span onClick={() => handleClick(4)}>
                      <img src={emoji4} alt="" />
                    </span>
                    <span onClick={() => handleClick(5)}>
                      <img src={emoji5} alt="" />
                    </span>
                  </div>
                ) : null}

                {shownextcontent ? <TransitionsModal2 selectedStep={selectedStep} setSelectedStep={setSelectedStep} sliderDefaultValue={sliderDefaultValue} /> : null}

                <div className="btnArea">
                  <Button className="btnSubmit" disabled={disable} onClick={() => handleFeedback()}>
                    Submit
                  </Button>
                  <Button className="btnnotnow" onClick={() => handleNotNow()}>Not Now</Button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
