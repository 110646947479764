import {showOnlyDayAndMonth, showOnlyTime, stringToDateTimeFormatString} from './date-time-utils';
import {AM_PM_FORMAT, DEFAULT_PER_PAGE_SIZE, ONLY_DATE_FORMAT} from './constants';
import {IChartOptions} from '../models/IChart';
import {IPaginationStrapiDto} from '../dtos/IPaginationStrapiDto';
import {ProgressType} from './enums';

/* General Modifiers */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const groupBy = (items: any, key: string) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const normalizeStrapiPagination = (
  pagination: IPaginationStrapiDto | any,
): IPaginationStrapiDto => {
  return {
    ...pagination,
    _start:
      pagination?._start && pagination?._start * DEFAULT_PER_PAGE_SIZE - DEFAULT_PER_PAGE_SIZE,
  };
};

/* Specific Modifiers */
export class ProgressModifier {
  public static getLatestValues(values: any[], type: ProgressType): any[] {
    if (type === ProgressType.BLOOD_GLUCOSE) {
      const filterValues = values.map((item) => {
        return {
          date: showOnlyDayAndMonth(item.date),
          value: item.value,
        };
      });
      const reversed = filterValues.reverse();
      return reversed;
    }
    if (type === ProgressType.FOOD || type === ProgressType.BOWEL_MOVEMENT) {
      const filterValues = values.map((item) => {
        return {
          date: showOnlyDayAndMonth(item.date),
          time: showOnlyTime(item.date),
          value: 1,
        };
      });
      const reversed = filterValues.reverse();
      return reversed;
    } else {
      const GROUP_BY_KEY = 'date';
      const grouped = groupBy(values, GROUP_BY_KEY);
      let latestValues: any = [];
      Object.keys(grouped).forEach((item) => {
        const value = grouped[item];
        const sumValues = value.reduce(function (a: any, b: any) {
          let sum = 0;
          if (type === ProgressType.ACTIVITY || type === ProgressType.SLEEP) {
            sum = a.duration ? a.duration + b.duration : b.duration;
          } else if (type === ProgressType.MENTAL_HEALTH) {
            sum = a.moodLevelValue ? a.moodLevelValue + b.moodLevelValue : b.moodLevelValue;
          } else if (type === ProgressType.BODY_WEIGHT) {
            sum = a.value ? a.value + b.value : b.value;
          } else {
            sum = a.value ? a.value + b.value : b.value;
          }
          return {
            date: showOnlyDayAndMonth(value[0].date),
            value: sum,
          };
        }, '');
        if (type === ProgressType.MENTAL_HEALTH || type === ProgressType.BODY_WEIGHT) {
          latestValues = [
            {date: sumValues.date, value: Math.round(sumValues.value / value?.length)},
            ...latestValues,
          ];
        } else {
          latestValues = [sumValues, ...latestValues];
        }
      });
      return latestValues;
    }
  }
  public static modifyData(data: any[], type: ProgressType, time: boolean): any {
    const DATE_FORMAT = time ? AM_PM_FORMAT : ONLY_DATE_FORMAT;
    const filteredValues: any[] = data.map((item) => {
      return {
        ...item,
        date: stringToDateTimeFormatString(item.date, DATE_FORMAT),
      };
    });
    return this.getLatestValues(filteredValues, type);
  }

  public static modifyOptions(options: IChartOptions, initialLog: number): IChartOptions {
    const bottomRange = initialLog * 0.8;
    const topRange = initialLog * 1.2;
    const range = [bottomRange, topRange];
    return {
      ...options,
      rangeY: range,
    };
  }
}
