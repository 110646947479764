import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  average: {
    marginBottom: '16px',
  },
  caption: {
    color: `${theme.palette.blacksAndWhites.sonicSilver} !important`,
    display: 'block',
  },
  averageLoader: {
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginTop: 8,
    marginLeft: 4,
  },
  chart: {
    '& .recharts-text': {
      fill: theme.palette.blacksAndWhites.silver,
    },
    '& .recharts-layer > line': {
      display: 'none',
    },
  },
}));

export default useStyles;
