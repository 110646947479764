/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((_) => ({
  durationLabel: {
    marginBottom: 8,
    marginTop: (props) => (props.isMobile ? '24px' : 0),
  },
  select: {
    width: '177px',
    marginRight: '24px',
  },
  timeFieldPicker: {
    maxWidth: 177,

    '& .MuiButtonBase-root': {
      display: 'none',
    },
  },
  tagsContainer: {
    marginTop: 22,
  },
}));

export default useStyles;
