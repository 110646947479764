import {get, post} from './api-calls';
export const getKemTaiWorkoutGroupsApi = (userID: string | unknown | number, groupId: string | unknown | number): Promise<void> => {
    return get(`/KemTai/User/WorkoutGroups?UserID=${userID}&WorkGroupTypeID=${groupId}`);
};

export const getKemTaiUserWorkoutHistoryApi = (userID: string | unknown | number, groupId: string | unknown | number,): Promise<void> => {
    return get(`/KemTai/User/History/?UserID=${userID}&WorkOutGroupId=${groupId}`);
};

export const postKemTaiUserWorkoutHistoryApi = (groupData: unknown,): Promise<void> => {
    return post(`/KemTai/User/History/`, groupData);
};
export const postYogaIntercomApi = (groupData: unknown,): Promise<void> => {
    return post(`/Intercom/SendIntercomEvent`, groupData);
};

export const getKemTaiUserWorkoutsListApi = (userID: string | unknown | number, workoutGroupID: string | unknown | number, workoutID?: string | unknown | number, WorkoutGroupSlug?: unknown | string, WorkoutSlug?: unknown | string): Promise<void> => {
    if(WorkoutGroupSlug && WorkoutSlug){
        return get(`/KemTai/User/Workouts?UserID=${userID}&WorkoutGroupSlug=${WorkoutGroupSlug}&WorkoutSlug=${WorkoutSlug}`);
    } else if(WorkoutGroupSlug){
        return get(`/KemTai/User/Workouts?UserID=${userID}&WorkoutGroupSlug=${WorkoutGroupSlug}`);
    } else if(workoutID){
        return get(`/KemTai/User/Workouts?UserID=${userID}&WorkoutGroupID=${workoutGroupID}&WorkoutId=${workoutID}`);
    }
    return get(`/KemTai/User/Workouts?UserID=${userID}&WorkoutGroupID=${workoutGroupID}`);
};

export const getKemTaiUserWorkoutsApi = (workoutGroupID: string | unknown | number,   ): Promise<void> => {
    // console.log(workout)
    return get(`/KemTai/Workouts/${workoutGroupID}`);
};

export const getKemTaiVideoURLApi = (kemtaiId: string | unknown | number): Promise<void> => {
    return get(`/KemTai/Channel/Workout/${kemtaiId}`);
};

export const getKemTaiWorkoutDetailApi = (kemtaiId: string | unknown | number,): Promise<void> => {
    return get(`/KemTai/Channel/Workout/${kemtaiId}`);
};

export const getKemTaiUserWorkoutExercisesApi = (userID: string | unknown | number,): Promise<void> => {
    return get(`/KemTai/User/Workout/Exercises?UserID=${userID}`);
};

export const postKemTaiUserWorkoutFeedbackApi = (feedbackDetail: string | unknown | number): Promise<void> => {
    return post(`/KemTai/Workout/Feedback`, feedbackDetail);
};

export const postKemTaiUserWorkoutResultApi = (completedDetail: string | unknown | number): Promise<void> => {
    return post(`/KemTai/User/Workout/Result`, completedDetail);
};

export const getKemTaiUserChallengeScoreApi = (userID: string | unknown | number, workoutGroupID: string | unknown | number): Promise<void> => {
        return get(`/KemTai/User/ChallengeScore?UserID=${userID}&WorkoutGroupID=${workoutGroupID}`);
};

export const getKemtaiUserAchievementApi = (userID: string | unknown | number, workoutGroupID: string | unknown | number): Promise<void> => {
    return get(`/KemTai/User/Achievement?UserID=${userID}&WorkoutGroupID=${workoutGroupID}`);
};

export const postAchievementApi = (achivementPayload: string | unknown | number,): Promise<void> => {
    return post(`/Achievement`, achivementPayload);
};

export const postUserExcerciseParticipationApi = (payload: string | unknown | number,): Promise<void> => {
    return post(`/Users/UserExcerciseParticipation`, payload);
};