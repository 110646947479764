import {makeStyles, Theme} from '@material-ui/core';

interface IProps {
  uploadEnabled: boolean;
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  selectedImgContainerMobile: {
    height: (props) => (props.isMobile ? '400px' : 'auto'),
  },
  selectedImgContainer: {
    position: (props) => (props.isMobile ? 'absolute' : 'relative'),
    marginTop: (props) => (props.isMobile ? '24px' : '0'),
    width: (props) => (props.isMobile ? 'calc(100% - 36px)' : '164px'),
    height: (props) => (props.isMobile ? '311px' : '164px'),
  },
  selectedImg: {
    borderRadius: '12px',
  },
  selectImg: {
    borderWidth: (props) => (props.isMobile ? 0 : '2px'),
    borderColor: theme.palette.primary.light,
    borderStyle: 'dashed',
    borderRadius: '12px',
    width: (props) => (props.isMobile ? '60px' : '164px'),
    height: (props) => (props.isMobile ? '60px' : '164px'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: (props) => (props.uploadEnabled ? 'pointer' : ''),
  },
  defaultImage: {
    width: (props) => (props.isMobile ? '60px !important' : '40px !important'),
    height: (props) => (props.isMobile ? '60px !important' : '40px !important'),
  },
  closeIcon: {
    position: 'absolute',
    backgroundColor: theme.palette.blacksAndGrays.stoneGray,
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: (props) => (props.isMobile ? '-10px' : '-4px'),
    right:  (props) => (props.isMobile ? '30px' : '-4px'),
    cursor: 'pointer',
  },
  hideInputCLass: {
    display: 'none',
  },
  editTxt: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
    marginLeft: '15px',
  },
}));

export default useStyles;
