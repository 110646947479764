import {makeStyles, Theme} from '@material-ui/core';
import {handlePadding} from '../../../utils/css-helpers';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  connectionsContent: {
    background: (props) => (props.smallSize ? '#DDD6D6' : theme.palette.background.default),
    marginTop: (props) => (props.smallSize ? '24px' : '8px'),
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
    },
    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
    },
    '& div.MuiButtonBase-root.MuiAccordionSummary-root': {
      background: (props) => (props.smallSize ? "#d6d6d6" : 'transparent'),
      // background: (props) => (props.smallSize ? theme.palette.primary.dark : 'transparent'),
      border: (props) => (props.smallSize ? "1px solid black" : ''),
      color: (props) => (props.smallSize ? "#000000" : ''),
      padding: (props) => (props.smallSize ? '10' : '0'),
      marginBottom: (props) => (props.smallSize ? '0px' : '0'),
      '& .MuiAccordionSummary-content': {
        width: (props) => (props.smallSize ? '100%' : 'auto'),
      },
      '& .MuiTypography-caption': {
        fontSize: (props) => (props.smallSize ? '16px' : '12px'),
        
      },
      '& .MuiIconButton-label': {
        filter: (props) => (props.smallSize ? 'invert(1)' : 'initial'),
      },
    },
    padding: (props) => handlePadding(props.isMobileOrTablet),
    color: (props) => (props.smallSize ? theme.palette.secondary.main : ''),
  },
  line: {
    border: 1,
    borderColor: theme.palette.blacksAndGrays.gainsboro,
    boxSizing: 'border-box',
    borderStyle: 'solid',
    marginBottom: 16,
    display: (props) => (props.smallSize ? 'none' : 'block'),
  },
  collapseIcon: {
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  connectionsSearch: {
    backgroundColor: (props) =>
      props.smallSize ? '#DDD6D6' : `${theme.palette.background.paper} !important`,
    width: (props) => (props.smallSize ? 'none' : '282px !important'),
    borderRadius: 8,
    marginBottom: (props) => (props.smallSize ? 0 : 6),
    marginRight: '0px !important',
  },
  groupTitle: {
    marginBottom: 8,
    color: (props) => (props.smallSize ? '#000000' : ''),
  },
  backToSearch: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 500,
    color: theme.palette.blacksAndGrays.stoneGray,
    marginBottom: 16,
    marginTop: 10,
    cursor: 'pointer',
  },
  selectedUser: {
    color: theme.palette.blacksAndGrays.davysGray,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export default useStyles;
