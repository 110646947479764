/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {Typography} from '@material-ui/core';
import useStyles from './fitness-content.styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {YogaCard} from './yogaCard';
import YogaIntro from './yoga/yogaIntro';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {getKemTaiUserWorkoutsList} from '../../../store/actions/kemtai/kemtai-action';

interface Props {
  YogaGroups: any;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}
const YogaLists: React.FC<Props> = ({isMobileOrTablet, smallScreens, YogaGroups}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const dispatch = useDispatch();
  const userData: any = useSelector((state: any) => state.users.currentUser);
  const location = useLocation();

  const [isYogaList, setIsYogaList] = React.useState(false);
  const [workoutData, setWorkoutData] = React.useState<any>();
  const [isYogaDeepLink, setIsYogaDeepLink] = React.useState(false);
  const [deepLinkSlug, setDeepLinkSlug] = React.useState<any>('');

  const handleNavigation = (name: string | undefined) => {
    name === 'Yoga' ? setIsYogaList(false) : '';
  };
  const getDeepLinkData = async (userId: any, id: string, slug: any) => {
    try {
      const res: any = await dispatch(getKemTaiUserWorkoutsList(userId, id));
      if (res) {
        setWorkoutData(res?.workouts[0]);
        setDeepLinkSlug(slug);
        setIsYogaList(true);
        setIsYogaDeepLink(true);
      }
    } catch (error) {
      // Handle any errors here
    }
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const slug = searchParams.get('slug');
    const id = searchParams.get('id');
    if (id !== null) {
      getDeepLinkData(userData?.id, id, slug);
    }
  }, [location]);
  const someFunction = () => {
    // Your code here
  };
  const renderYogas = (group: any) =>
    group &&
    group.map((workout: any, index: number) => (
      <YogaCard
        recipe={workout}
        key={workout.id || index}
        setWorkoutData={setWorkoutData}
        userData={userData}
        // onClick={(slug) => handleWorkoutAction(workout, slug)}
        isMobileOrTablet={isMobileOrTablet}
        setIsYogaList={setIsYogaList}
        smallScreens={smallScreens}
      />
    ));
  return (
    <div className={classes.movementLanding}>
      {!isYogaList && <h4 className="headingh3">YOGA</h4>}
      <h4 className={classes.subtitle}>
        {isYogaList && (
          <>
            <span
              onKeyDown={() => someFunction()}
              tabIndex={-1}
              role={'button'}
              onClick={() => handleNavigation('Yoga')}
            >
              Yoga/
            </span>{' '}
            <span style={{cursor: 'auto'}}> {workoutData?.name}</span>
          </>
        )}
      </h4>
      {/* {isYogaList && <h4>{workoutData?.name}</h4>} */}
      {isYogaList ? (
        <>
          <div className={classes.fitnessList}>
            <div className={classes.fitnessList}>
              <YogaIntro
                isMobileOrTablet={isMobileOrTablet}
                smallScreens={smallScreens}
                recipe={workoutData}
                // key={workoutData?.id}
                deepLinkSlug={deepLinkSlug}
                isYogaDeepLink={isYogaDeepLink}
                userData={userData}
                onClick={() => console.log('')}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <Typography variant={'h6'} align="left">
            Welcome
          </Typography>
          {/* <div className={classes.fitnessList}> */}
          <div className={classes.fitnessList}>
            {renderYogas(YogaGroups?.filter((item: {id: number}) => [9].includes(item.id)))}
          </div>
          {/* </div> */}
          <Typography variant={'h6'} align="left">
            Intro to Yoga Course Part One: The Basics (Start Here)
          </Typography>
          {/* <div className={classes.fitnessList}> */}
          <div className={classes.fitnessList}>
            {renderYogas(YogaGroups?.filter((item: {id: number}) => [10, 11].includes(item.id)))}
          </div>
          {/* </div> */}
          <Typography variant={'h6'} align="left">
            Intro to Yoga Course Part Two: The Foundation
          </Typography>
          <div className={classes.fitnessList}>
            {renderYogas(YogaGroups?.filter((item: {id: number}) => [12, 13].includes(item.id)))}
          </div>
          <Typography variant={'h6'} align="left">
            Yoga Classes
          </Typography>
          <div className={classes.fitnessList}>
            {renderYogas(YogaGroups?.filter((item: {id: number}) => [14].includes(item.id)))}
          </div>
        </>
      )}
    </div>
  );
};

export default YogaLists;
