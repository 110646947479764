import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  posterContainer: {
    background:
      'linear-gradient(180.55deg, rgba(173, 188, 238, 0.1736) 0.54%, rgba(141, 188, 241, 0.4032) 21.02%, rgba(125, 245, 245, 0.0952) 68.91%, rgba(151, 251, 190, 0) 99.59%)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  paperContent: {
    boxShadow:
      '0px 8px 10px rgba(100, 101, 102, 0.2), 0px 16px 24px rgba(100, 101, 102, 0.14), 0px 6px 30px rgba(100, 101, 102, 0.12)',
    borderRadius: '0px 150px 0px 0px',
    width: '100%',
    height: '100%',
    maxWidth: 628,
    padding: '32px 40px 32px 40px',

    [theme.breakpoints.up('md')]: {
      maxWidth: 628,
      minHeight: 484,
      height: 'unset',
    },
  },
  contentLoaderWrapper: {
    height: '100%',
  },
  progressBackHeader: {
    width: '100%',
  },
  statusBackHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 8,
    width: 62,
    // cursor: 'pointer',
    //
    // '&:hover': {
    //   opacity: 0.6,
    // },
  },
  status: {
    marginRight: 15,
    color: theme.palette.secondary.dark,
  },
  back: {
    color: theme.palette.primary.light,
  },

  // progress bar
  progressBar: {
    '&.MuiLinearProgress-root': {
      height: 10,
      borderRadius: 5,
      width: '100%',
    },
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.grey[200],
    },
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
      // backgroundColor: '#1a90ff',
    },
  },
}));

export default useStyles;
