import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {handleMargin} from '../../../utils/css-helpers';

interface Props {
  isMobileOrTablet: IResponsiveSize;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 80,
  },
  mainCenterContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: (props) =>
      props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTabletSmall
        ? '100%'
        : props.isMobileOrTablet.isTabletBig
        ? 840
        : 628,
    padding: (props) => handleMargin(props.isMobileOrTablet, '0 '),
  },
  content: {
    minHeight: 200,
    background: theme.palette.background.paper,
    width: '100%',
    margin: '0px 24px',
    marginBottom: 15,
  },
  notificationPanel: {
    marginLeft: 24,
    minWidth: 310,
    marginRight: (props) =>
      props.isMobileOrTablet.isMobile ||
      props.isMobileOrTablet.isTabletSmall ||
      props.isMobileOrTablet.isTabletBig
        ? '0'
        : '',
  },
  sidebarIcon: {
    position: 'absolute',
    top: '12px',
    left: '10px',
    zIndex: 12200000000,
    '& button': {
      background: 'transparent',
      border: 'none',
    },
  },
  sideMenuBar: {
    zIndex: 12200000001,
    position: 'fixed',
    top: 0,
    width: 0,
    bottom: 0,
    left: 0,
    background: theme.palette.primary.dark,
    transition: 'width 1s',
    msTransitionTimingFunction: 'ease-in',
    '& .sideMenuBarOpen': {
      display: 'block',
    },
    '& button': {
      background: 'transparent',
      border: 'none',
      float: 'right',
      marginTop: 10,
      marginRight: 4,
      '& img': {
        filter: 'invert(1)',
      },
    },
  },
  sideMenuBarOpen: {
    width: '100vw',
  },
  scrollMargin: {
    scrollMargin: '80px',
    width: '100%',
  },
}));

export default useStyles;
