import {IImageDto} from './IImageDto';

export enum CardType {
  FOOD_LIST = 'foodList',
  MEAL_DELIVERY = 'mealDelivery',
}

export interface IImageWrapperDto {
  image: IImageDto;
}

export interface IPdfDto {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  url: string;
  ext: string;
}

export interface ICardDto {
  id: number;
  internalName: string;
  name: string;
  body: string;
  Headline: string;
  link: string;
  pdf: IPdfDto;
  Image: IImageWrapperDto;
  type: CardType;
}
export interface IRightCardDto {
  id: number;
  internalName: string;
  name: string;
  body: string;
  Headline: string;
  link: string;
  pdf: IPdfDto;
  Image: IImageWrapperDto;
  type: CardType;
}
