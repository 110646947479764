import {OptionsObject, useSnackbar, WithSnackbarProps} from 'notistack';
import * as React from 'react';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtils = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

export default {
  success(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, {
      ...options,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      variant: 'success',
    });
  },
  warning(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, {...options, variant: 'warning'});
  },
  info(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, {...options, variant: 'info'});
  },
  error(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, {...options, variant: 'error'});
  },
  toast(msg: string, options: OptionsObject = {}): void {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};
