import {makeStyles, Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  sideMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: (props) =>
      props.isMobileOrTablet.isMobile
        ? '12px 16px'
        : props.isMobileOrTablet.isTabletSmall
        ? '12px 32px'
        : props.isMobileOrTablet.isTabletBig
        ? '12px 92px'
        : 0,
    position: 'static',
    marginLeft: 0,
    marginTop: (props) => (props.smallSize ? 0 : 12),
    marginBottom: (props) => (props.smallSize ? 0 : 12),
    cursor: 'pointer',
    width: (props) => (props.smallSize ? '100%' : 'auto'),
    '& .side-menu-item-text-inactive': {
      color: (props) =>
        props.smallSize ? theme.palette.background.paper : theme.palette.secondary.dark,
    },
    '& .side-menu-item-text': {
      color: (props) =>
        props.smallSize ? "#000000" : theme.palette.primary.light,
    },
    '& > .side-menu-item-icon': {
      marginRight: 12,
      width: 32,
      height: 32,
    },
  },
  sideMenuItemFirst: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .side-menu-item-text-inactive': {
      color: (props) =>
        props.smallSize ? theme.palette.background.paper : theme.palette.secondary.dark,
    },
    '& .side-menu-item-text': {
      color: (props) =>
        props.smallSize ? theme.palette.primary.dark : theme.palette.primary.light,
    },

    '& > .side-menu-item-icon': {
      marginRight: 12,
      width: 32,
      height: 32,
    },
  },
  sideMenuItemHover: {
    '&:hover': {
      opacity: 0.6,
    },
  },
  sideMenuItemActive: {
    background: (props) => (props.smallSize ? theme.palette.background.paper : 'transparent'),
  },
  sidebarIcons: {
    marginRight: 12,
    width: '32px',
    height: '32px',
    '& .strokePrimary': {
      stroke: (props) =>
        props.smallSize ? theme.palette.background.paper : theme.palette.primary.main,
    },
    '& .fillPrimary': {
      fill: (props: any) =>
        props.smallSize ? theme.palette.background.paper : theme.palette.primary.main,
    },
    '&  .strokeFillPrimary': {
      stroke: (props) =>
        props.smallSize ? theme.palette.background.paper : theme.palette.primary.main,
    },
    '&  .fillStrokePrimary': {
      fill: (props: any) => (props.smallSize ? 'transparent' : theme.palette.background.paper),
      stroke: (props) => (props.smallSize ? theme.palette.background.paper : ''),
    },
    '& .strokeFillBothPrimary': {
      stroke: (props) =>
        props.smallSize ? theme.palette.background.paper : theme.palette.primary.main,
      fill: (props: any) =>
        props.smallSize ? theme.palette.background.paper : theme.palette.primary.main,
    },
    '& > path': {
      // fill: theme.palette.blacksAndGrays.stoneGray,
      // stroke: theme.palette.blacksAndGrays.stoneGray,
    },
    '&  .fillTransparent': {
      fill: 'transparent',
    },
  },
  sidebarIconsActive: {
    marginRight: 12,
    width: '32px',
    height: '32px',
    '& .primary': {
      fill: theme.palette.primary.main,
    },
    '& .strokePrimary': {
      stroke: theme.palette.background.paper,
    },
    '& .fillPrimary': {
      fill: theme.palette.background.paper,
    },
    '& .strokeFillPrimary': {
      fill: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
    },
    '& .fillStrokePrimary': {
      fill: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
    },
    '& .strokeFillBothPrimary': {
      fill: theme.palette.background.paper,
      stroke: theme.palette.background.paper,
    },
    '&  .fillTransparent': {
      fill: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
