/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import useStyles from './Eligibility.styles';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import {TextField, TextFieldProps} from '@material-ui/core';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {ChangeEvent, SetStateAction, useEffect, useMemo, useState} from 'react';
import {Button} from '../../../common/components/button/Button';
import EligibilityStep2 from './EligibiltyStep2';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {
  DATE_PICKER_DATE_FORMAT,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';
import {getError, hasError} from '../../../validation/Validation';
import useCommonStyles from '../../../common/components/logging-widgets/common-for-widgets.styles';
import moment from 'moment';
import {isMobile} from 'react-device-detect';
import crypto from 'crypto-js';
import {getDirectOnboardUserApi} from '../../../api/users.api';
import {useLocation} from 'react-router';
import EligibilityError from './EligibilityError';

const Eligibility: React.FC = () => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const classes = useStyles();
  const [digit1, setDigit1] = useState('');
  const [digit2, setDigit2] = useState('');
  const [digit3, setDigit3] = useState('');
  const [digit4, setDigit4] = useState('');
  const [isStep2, setIsStep2] = useState(false);
  const [isErrorPage, setIsErrorPage] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [step2Data, setStep2Data] = useState<any>({});
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    date: new Date(),
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const sponsorIdFromURL = searchParams.get('sponsorID');
    if (sponsorIdFromURL) {
      const digitsArray = String(sponsorIdFromURL)?.split('')?.map(Number);

      setDigit1(digitsArray[0]?.toString());
      setDigit2(digitsArray[1]?.toString());
      setDigit3(digitsArray[2]?.toString());
      setDigit4(digitsArray[3]?.toString());
    }
  }, [searchParams]);
  const generateHMACSHA256 = (currentEpochTimeInSeconds: number) => {
    const secretKey = process.env.REACT_APP_HMAC_SECRET_KEY || '';
    const dataToHash = `${currentEpochTimeInSeconds}&${process.env.REACT_APP_HMAC_SECRET_KEY}`;
    const hmac = crypto.HmacSHA256(dataToHash, secretKey);
    const hmacHex = hmac.toString(crypto.enc.Base64);
    return hmacHex;
  };
  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };
  const commonClasses = useCommonStyles({
    isMobile: isMobile,
  });
  const handelChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {name, value} = e.target;
    setData({...data, [name]: value});
  };
  const handelUserStepsUpdate = async () => {
    setIsLoading(true);
    const currentEpochTimeInSeconds = moment().unix();
    const hmacSha256Hash = await generateHMACSHA256(currentEpochTimeInSeconds);

    const formatedDate = moment(data.date).format('YYYY-MM-DD');
    const payload = {
      sponsorid: `${digit1}${digit2}${digit3}${digit4}`,
      dateofbirth: formatedDate,
      firstname: data.firstName,
      lastname: data.lastName,
      secret: hmacSha256Hash,
      requesttime: currentEpochTimeInSeconds,
    };
    try {
      const apiData = await getDirectOnboardUserApi(payload);
      setIsLoading(false);
      if (apiData.data.length > 0) {
        setStep2Data(apiData.data[0]);
        setIsStep2(true);
      }
    } catch (error) {
      // console.log("error", error);
      // Handle the error here
      setErrors(error);
      setIsErrorPage(true);
      setIsLoading(false);
      // console.error('An error occurred:', error);
      // You can also set an error state or display an error message to the user if needed
    }
  };
  const validateToSubmit = () => {
    if (digit1 === '' || undefined || null) {
      return true;
    } else if (digit2 === '' || undefined || null) {
      return true;
    } else if (digit3 === '' || undefined || null) {
      return true;
    } else if (digit4 === '' || undefined || null) {
      return true;
    } else if (data.firstName === '' || undefined || null) {
      return true;
    } else if (data.lastName === '' || undefined || null) {
      return true;
    } else {
      return false;
    }

    // if (!errors) return false;
  };

  const handleDigitChange =
    (setter: {
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (arg0: string): void;
    }) =>
    (event: {target: {value: string}}) => {
      const inputDigit = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
      if (inputDigit.length <= 1) {
        setter(inputDigit);
      }
    };

  return (
    <>
      {isErrorPage ? (
        <EligibilityError setIsErrorPage={setIsErrorPage} smallScreens={smallScreens} />
      ) : isStep2 ? (
        <EligibilityStep2
          setIsErrorPage={setIsErrorPage}
          smallScreens={smallScreens}
          step2Data={step2Data}
        />
      ) : (
        <div
          className={classes.posterContainer}
          style={{
            overflow: smallScreens ? 'none' : 'auto',
            maxWidth: smallScreens ? '100vw' : '100vw',
            overflowX: smallScreens ? 'hidden' : 'auto',
          }}
        >
          <div>
            <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
          </div>
          <h2 style={{display: 'flex', justifyContent: 'center'}}>Welcome!</h2>
          <div
            style={{
              width: '85%',
              overflow: smallScreens ? '' : 'auto',
              maxWidth: smallScreens ? 270 : 410,
              minWidth: 180,
            }}
          >
            Let's see if you are eligible to enroll at no cost to you. Please fill the following
            information
            <h6>Sponsor Code*</h6>
            <div style={{width: 410}}>
              <TextField
                style={{width: 50}}
                variant="outlined"
                value={digit1}
                onChange={handleDigitChange(setDigit1)}
                inputProps={{
                  maxLength: 1,
                }}
              />
              <TextField
                style={{width: 50, marginLeft: 20}}
                variant="outlined"
                value={digit2}
                onChange={handleDigitChange(setDigit2)}
                inputProps={{
                  maxLength: 1,
                }}
              />
              <TextField
                style={{width: 50, marginLeft: 20}}
                variant="outlined"
                value={digit3}
                onChange={handleDigitChange(setDigit3)}
                inputProps={{
                  maxLength: 1,
                }}
              />
              <TextField
                style={{width: 50, marginLeft: 20}}
                variant="outlined"
                value={digit4}
                onChange={handleDigitChange(setDigit4)}
                inputProps={{
                  maxLength: 1,
                }}
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>First Name*</h6>
              <TextField
                name="firstName"
                onChange={(e) => handelChange(e)}
                value={data.firstName}
                style={{width: '96%'}}
                variant="outlined"
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>Last Name*</h6>
              <TextField
                name="lastName"
                onChange={(e) => handelChange(e)}
                value={data.lastName}
                style={{width: '96%'}}
                variant="outlined"
              />
            </div>
            <div style={{width: smallScreens ? '96%' : 400, marginTop: 20}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Date of Birth'}
                  value={data?.date}
                  error={hasError(errors, 'date')}
                  helperText={getError(errors, 'date')}
                  sx={{width: smallScreens ? '100%' : 395}}
                  className={commonClasses.datePicker}
                  onChange={(value: string | null) => changeSpecificDataType(value, 'date')}
                  format={DATE_PICKER_DATE_FORMAT}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                    <TextField
                      sx={{width: smallScreens ? '100%' : 395}}
                      fullWidth
                      {...params}
                      helperText={null}
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <h6 style={{marginBottom: 5}}> Date of Birth*</h6> */}
            </div>
            <Button
              id="continue"
              onClick={() => handelUserStepsUpdate()}
              loading={isLoading}
              disabled={validateToSubmit()}
              className={classes.bottomButton}
              // style={{marginTop: 10}}
            >
              Get Started
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

Eligibility.displayName = 'Eligibility';
export default Eligibility;
