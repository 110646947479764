import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Typography, useMediaQuery} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {DailyContentCard} from './DailyContentCard';
import {ContentCategoryCard} from './ContentCategoryCard';
import {ContentDialog} from '../dialog/ContentDialog';
import {DailyContentDetailsView} from './DailyContentDetailsView';
import Loader from '../loader/Loader';
import {Button} from '../button/Button';
import {ReactComponent as BackIcon} from '../../../assets/icons/rest/left-arrow.svg';
import {
  DEFAULT_PAGE_START,
  DEFAULT_PER_PAGE_SIZE,
  GeneralPaths,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';
import {EngagementPointType, NourishType} from '../../../utils/enums';
import {containsParamInHistory, getHistoryParams} from '../../../utils/qs-helper';
import {selectAllDailyContentCategoriesForBetrHealth} from '../../../store/selectors/nourish.selectors';
import {IDailyContentCategoryDto} from '../../../dtos/IDailyContentCategoryDto';
import {addEngagementPointDatabase} from '../../../api/engagement.api';

import useStyles from './daily-content-view.styles';

interface Props {
  dailyContents: any[];
  title: string;
  page: number;
  count: number;
  onChangePage: (event: any, page: number) => void;
  onChangeFilter: (filter: any) => void;
  handleCloseSelectedCategory: (name: any) => void;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/*eslint-disable @typescript-eslint/no-empty-function*/
export const DailyContentsView: React.FC<Props> = ({
  dailyContents,
  title,
  onChangeFilter,
  onChangePage,
  handleCloseSelectedCategory,
  count,
  page,
}) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE - 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);

  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;

  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const history = useHistory();
  const [slug, setSlug] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [pageScroll, setPageScroll] = useState<number>(0);
  const [allDailyContents, setAllDailyContents] = useState<any>([]);
  const [t] = useTranslation();
  const dailyContentCategories = useSelector(selectAllDailyContentCategoriesForBetrHealth);
  const totalPagesCount = Math.floor((count + DEFAULT_PER_PAGE_SIZE - 1) / DEFAULT_PER_PAGE_SIZE);

  useEffect(() => {
    if (containsParamInHistory(history, '_slug')) {
      const params = getHistoryParams(history);
      if (params?._slug && params['type-slug'] === 'betr-program-mode') {
        addEngagementPointDatabase({
          eventType: EngagementPointType.ARTICLES_READ,
          description: params?._slug || '',
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOnCardClick(params?._slug, true);
    }
  }, []);

  const handleOnCardClick = (slug: string, deepLinking = false) => {
    setSlug(slug!);
    setOpen(true);
    if (!deepLinking) {
      history.replace({
        pathname: GeneralPaths.NourishPage,
        search: `?type-slug=${NourishType.BETR_PROGRAM_MODE.toString()}&_slug=${slug}`,
      });
    }
  };

  const handleOnCategoryCardClick = (dailyContentCategory: IDailyContentCategoryDto) => {
    const filters = {
      dailyCategory: dailyContentCategory,
    };
    onChangeFilter(filters);
  };

  const handleDismiss = () => {
    setOpen(false);
    setSlug('');
    history.replace({
      pathname: GeneralPaths.NourishPage,
      search: ``,
    });
  };

  const renderDailyContent = (array: any[]) =>
    array.map((dailyContent: any) => (
      <DailyContentCard
        dailyContent={dailyContent}
        key={dailyContent.id}
        onClick={handleOnCardClick}
        isMobileOrTablet={isMobileOrTablet}
        smallScreens={smallScreens}
      />
    ));

  const renderDailyContentCategories = () =>
    dailyContentCategories.map((dailyContentCategory: IDailyContentCategoryDto) => (
      <ContentCategoryCard
        smallScreens={smallScreens}
        dailyContentCategory={dailyContentCategory}
        onClick={() => handleOnCategoryCardClick(dailyContentCategory)}
      />
    ));

  useEffect(() => {
    setAllDailyContents([]);
    setPageScroll(0);
  }, []);

  useEffect(() => {
    if (smallScreens) {
      setAllDailyContents(allDailyContents.concat(dailyContents));
    }
  }, [dailyContents]);

  const fetchMoreData = () => {
    onChangePage('', pageScroll);
    setPageScroll(pageScroll + 1);
  };

  return (
    <div id={'scrollableDiv'} className={classes.mainContainer}>
      {dailyContents?.length ? (
        <>
          {smallScreens && title && (
            <Button
              className={classes.back}
              id="back"
              variant="text"
              onClick={() => handleCloseSelectedCategory()}
            >
              <BackIcon title="Back to recipes" />
              <Typography variant={'subtitle1'}>{t('NourishPage.betrProgram.title')}</Typography>
            </Button>
          )}
          {/* <Typography variant={'h6'} align="left">
            {title || t('NourishPage.betrProgram.title')}
          </Typography> */}

          <Typography variant={'h6'} align="left">
            {title || t('NourishPage.betrProgram.title')}
          </Typography>
          {smallScreens ? (
            <InfiniteScroll
              className={classes.infiniteScroll}
              dataLength={title ? allDailyContents?.length : dailyContentCategories?.length}
              height={'90vh'}
              pullDownToRefreshThreshold={10}
              next={fetchMoreData}
              hasMore={
                title
                  ? allDailyContents.length < totalPagesCount
                  : dailyContentCategories.length < totalPagesCount
              }
              loader={<Loader />}
              scrollableTarget="scrollableDiv"
            >
              {title ? renderDailyContent(allDailyContents) : renderDailyContentCategories()}
            </InfiniteScroll>
          ) : (
            <div className={classes.dailyContentList}>
              {title ? renderDailyContent(dailyContents) : renderDailyContentCategories()}
            </div>
          )}

          {title && (
            <div className={classes.paginationContainer}>
              <Pagination
                count={totalPagesCount}
                className={classes.pagination}
                defaultPage={DEFAULT_PAGE_START}
                onChange={onChangePage}
                page={!page ? DEFAULT_PAGE_START : page}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {smallScreens && title && (
            <Button
              className={classes.back}
              id="back"
              variant="text"
              onClick={() => handleCloseSelectedCategory("NodataFound")}
            >
              <BackIcon title="Back to recipes" />
              <Typography variant={'subtitle1'}>{t('NourishPage.betrProgram.title')}</Typography>
            </Button>
          )}
          <Typography variant="subtitle1">{t('NourishPage.betrProgramFilter.noResult')}</Typography>
        </>
      )}
      <ContentDialog
        fullScreen
        slideUp
        handleDismiss={handleDismiss}
        dialogState={open}
        closeIconClass={classes.closeIconRecipesClass}
        isCloseButtonHidden={smallScreens}
        // className={classes.dialogMargin}
        // closeIconClass={classes.dialogClose}
        content={
          <DailyContentDetailsView
            slug={slug!}
            title={title || t('NourishPage.betrProgram.title')}
            isMobileOrTablet={isMobileOrTablet}
            smallScreens={smallScreens}
            handleDismiss={handleDismiss}
          />
        }
      />
    </div>
  );
};
