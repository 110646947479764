import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    background: (props) => (props.smallScreens ? theme.palette.background.paper : 'transparent'),
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: (props) => (props.smallScreens ? '12px 16px' : 0),
    marginRight: 24,
    width: (props) => (props.smallScreens ? '100%' : 310),
    minWidth: (props) => (props.smallScreens ? '100%' : 310),
    overflowY: 'auto',
    overflowX: 'hidden',
    height: (props) => (props.smallScreens ? '100%' : '80vh'),
    // position: '-webkit-sticky' /* for Safari */,
    position: 'sticky',
    alignSelf: 'flex-start' /* <-- this is the fix */,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
    '& > div': {
      marginBottom: 0,
    },
  },
  noteContainer: {
    width: '100%',
  },
  line: {
    height: 1,
    width: '100%',
    background: theme.palette.blacksAndGrays.gainsboro,
    marginTop: 16,
    marginBottom: 16,
  },
  outlinedBtn: {
    height: '36px !important',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  dialogNewNote: {
    '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper': {
      padding: '16px',
    },
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
    },
  },
  loadMore: {
    color: theme.palette.blacksAndWhites.sonicSilver,
    alignSelf: 'center',
  },
}));

export default useStyles;
