import {IBodyPainDto} from '../dtos/IBodyPainDto';
import {del, get, post, put} from './api-calls';

export const getBodyPainLogs = (id: string, dateTime: string): Promise<IBodyPainDto[]> => {
  return get(`/bodypain?UserId=${id}&from=${dateTime}`);
};

export const addBodyPainDatabase = (data: IBodyPainDto): Promise<IBodyPainDto> => {
  return post('/bodypain', data);
};

export const editBodyPainDatabase = (data: IBodyPainDto): Promise<IBodyPainDto> => {
  return put(`/bodypain/${data.id}`, data);
};

export const deleteBodyPainDatabase = (id: number): Promise<any> => {
  return del(`/bodypain/${id}`);
};

export const getBodyPainLogById = (id: number): Promise<IBodyPainDto> => {
  return get(`/bodypain/${id}`);
};

export const getBodyPainLogsActivity = ( id: string,
  fromDate: string,
  toDate: string,
  ): Promise<IBodyPainDto[]> => {
  return get(`/bodypain?UserId=${id}&From=${fromDate}&To=${toDate}`);
};
