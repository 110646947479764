import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './loader-styles';
import React from 'react';
import classNames from 'classnames';

interface LoaderProps {
  className?: string;
  size?: number;
  loaderColor?: boolean;
}

const Loader: React.FC<LoaderProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.loaderContainer, props.className)}>
      <CircularProgress size={props.size} color={props.loaderColor ? 'secondary' : 'primary'} />
    </div>
  );
};

export default Loader;
