import {makeStyles, Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  toolbar: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: (props) => (props.smallScreens ? 'center' : 'space-between'),
    padding: (props) =>
      props.isMobileOrTablet.isMobile
        ? '5px 16px'
        : props.isMobileOrTablet.isTabletSmall
        ? '0 32px'
        : '0 24px',
  },
  navbar: {
    background: theme.palette.background.paper,
    top: '0',
    left: '0',
    height: (props) => (props.isMobileOrTablet.isMobile ? 56 : 80),
    boxShadow: (props) =>
      props.smallScreens
        ? '0px 2px 4px rgba(100, 101, 102, 0.2), 0px 4px 5px rgba(100, 101, 102, 0.14), 0px 1px 10px rgba(100, 101, 102, 0.12)'
        : '0px 2px 6px rgba(0, 0, 0, 0.12)',
    bottom: (props) => (props.smallScreens ? 0 : 'auto'),
    marginBottom: (props) => (props.smallScreens ? 0 : 16),
  },
  smallBar: {
    background: theme.palette.background.paper,
    top: 'auto !important',
    left: '0',
    height: (props) => (props.isMobileOrTablet.isMobile ? 56 : 80),
    boxShadow: (props) =>
      props.smallScreens
        ? '0px 2px 4px rgba(100, 101, 102, 0.2), 0px 4px 5px rgba(100, 101, 102, 0.14), 0px 1px 10px rgba(100, 101, 102, 0.12)'
        : '0px 2px 6px rgba(0, 0, 0, 0.12)',
    bottom: '0 !important' ,
    marginBottom: (props) => (props.smallScreens ? 0 : 16),
  },
  navbarButton: {
    border: 'none',
    background: 'transparent',
  },
  navigationLeftPartContainer: {
    width: '20%',
    '& img': {
      cursor: 'pointer',
    },
  },
  navigationPartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: (props) => (props.smallScreens ? 0 : 9),
    maxWidth: (props) => (props.smallScreens ? '100%' : 580),
    width: '100%',
  },
  notificationCenterPanel: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      height: 25,
      cursor: 'pointer',
    },
  },
  notificationRightPanel: {
    display: (props) => (props.smallScreens ? 'none' : 'flex'),
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex:99999999999999,
    postion:'absolute',
  },
  badgeContainer: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginLeft: 16,
    position: 'relative',
    cursor: 'pointer',
  },
  badgeIcon: {
    '&:hover': {
      opacity: '0.6',
    },
  },
  infoCircle: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    background: theme.palette.colours.red,
    fontWeight: 'bold',
    fontSize: 8,
    lineHeight: 10,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    position: 'absolute',
    top: -2,
    right: -6,
  },
  logOut: {
    color: theme.palette.blacksAndGrays.stoneGray,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default useStyles;
