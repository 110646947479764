import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>(() => ({
  simpleBarChart: {
    '& .recharts-wrapper:hover .recharts-tooltip-wrapper': {
      visibility: 'visible !important',
    },
    '& .recharts-tooltip-cursor': {
      fill: '#EBF4FF',
    },
  },
}));

export default useStyles;
