/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((_) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      marginBottom: '24px',
    },
  },
  rowContainer: {
    display: (props) => (props.isMobile ? 'block' : 'flex'),
    flexDirection: 'row',
    marginBottom: 24,
    '&.mobile': {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        width: '50%',
        marginRight: 0,
      },
      '& > div:nth-child(odd)': {
        paddingRight: (props) => (props.isMobile ? 8 : 0),
      },
      '& > div:nth-child(even)': {
        paddingLeft: (props) => (props.isMobile ? 8 : 0),
      },
    },
  },
  outlinedBtn: {
    height: '36px !important',
    marginRight: 16,
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    '& > button': {
      width: (props) => (props.isMobile ? '100%' : ''),
    },
  },
  normalTextField: {
    width: (props) => (props.isMobile ? '100%' : 170),
    '& > div.MuiFormControl-root': {
      width: (props) => (props.isMobile ? '100%' : ''),
      display: (props) => (props.isMobile ? 'block' : 'inline-flex'),
    },
  },
  heightTextField: {
    width: 95,
    marginRight: '8px !important',
  },
  heightTextFieldRight: {
    width: 95,
    marginRight: '8px !important',
    marginTop: 20,
  },
  editBtn: {
    display: 'none',
  },
  unit: {
    marginTop: 20,
  },
  dateBirthContainer: {
    '& .MuiIconButton-root': {
      display: 'none',
    },
    width: '200px !important',
    marginRight: 24,
  },
  genderSelect: {
    width: 170,
    marginRight: 24,
  },
  measurementUnit: {
    position: 'relative',
  },
  measurementUnitChild: {
    position: 'absolute',
    marginTop: 27,
  },
  measurementUnitChildRight: {
    position: 'absolute',
    marginTop: 27,
    marginLeft: 36,
  },
  heightContainer: {
    display: 'flex',
    marginLeft: (props) => (props.isMobile ? 0 : 24),
    marginTop: (props) => (props.isMobile ? 24 : 0),
    width: (props) => (props.isMobile ? '100%' : ''),
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  mainContainer: {
    display: (props) => (props.isMobile ? 'block' : 'flex'),
  },
  rowJustify: {
    justifyContent: 'space-between',
  },
}));

export default useStyles;
