import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectCurrentUser, selectUserSuperCoach} from '../../../store/selectors/users.selectors';
import {selectMember} from '../../../store/selectors/homepage.selectors';
import {IUser} from '../../../models/IUser';
import QuickUserProfileOverview from './QuickUserProfileOverview';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import Loader from '../loader/Loader';

interface IQuickUserProfileProps {
  example?: string;
  smallScreens?: any;
}

const QuickUserProfileWidget: React.FC<IQuickUserProfileProps> = (props) => {
  const {smallScreens} = props;
  const currentUser = useSelector(selectCurrentUser);
  const selectedMember = useSelector(selectMember);
  const selectedUser = useSelector(selectUserSuperCoach);
  const [user, setUser] = useState<IUser>();
  const dispatch = useDispatch();
  const [myProfile, setMyProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isMyProfile = () => {
    return selectedMember === null;
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await dispatch(
        homepageActions.loadUserAchievements(selectedMember ? selectedMember.id : currentUser.id),
      );
      if (selectedMember && selectedMember.measurementUnit.id) {
        await dispatch(homepageActions.selectMeasurementUnit(selectedMember.measurementUnit.id));
      } else if (currentUser && currentUser.measurementUnit.id) {
        await dispatch(homepageActions.selectMeasurementUnit(currentUser.measurementUnit.id));
      }
      setIsLoading(false);
    };
    setUser(selectedMember || selectedUser || currentUser);
    loadData();
    setMyProfile(isMyProfile());
  }, [currentUser, selectedMember, selectedUser]);

  return (
    <React.Fragment>
      {isLoading ? <Loader /> : <QuickUserProfileOverview user={user} myProfile={myProfile} smallScreens={smallScreens} />}
    </React.Fragment>
  );
};

export default QuickUserProfileWidget;
