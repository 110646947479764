import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  wideTextField: {
    width: (props) => (props.isMobile ? '100%' : 190),
  },
  stepsLabel: {
    marginBottom: 8,
  },
  sourceSelect: {
    width: (props) => (props.isMobile ? '100%' : 208),
    '& >div': {
      width: '100%',
    },
  },
}));

export default useStyles;
