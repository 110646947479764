import React, {Suspense, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {SideMenuBar} from '../../../common/components/side-menu-bar/SideMenuBar';
// import ChatWidget from '../../../common/components/chat/ChatWidget';
import QuickUserProfileWidget from '../../../common/components/quick-user-profile/QuickUserProfileWidget';
import InsightsWidget from '../../../common/components/insights/InsightsWidget';
import ReminderWrapper from '../../../common/components/reminder/ReminderWrapper';
import {Footer} from '../../../common/components/footer/Footer';
import ProgressWidget from '../../../common/components/progress/ProgressWidget';

// import {ContentDialog} from '../../../common/components/dialog/ContentDialog';
import {SideMenuBarMob} from '../../../common/components/side-menu-bar/SideMenuBarMob';
// import ChatAvatarButton from '../../../common/components/chat/ChatAvatarButton';
// import {ReferralCard} from '../../../common/components/referral-card/ReferrealCard';
import * as usersActions from '../../../store/actions/users/users-action';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import * as loggingActions from '../../../store/actions/logging-page/logging-page-action';
import * as usersActionTypes from '../../../store/actions/users/users-types';
import {
  // selectAppointments,
   selectBackendConversations,
  selectMember,
  selectPhases,
  // selectSelectedConversationId,
} from '../../../store/selectors/homepage.selectors';
import {
  selectAllUsers,
  selectCurrentUser,
  selectUserSuperCoach,
} from '../../../store/selectors/users.selectors';
import {
  selectCategoryTags,
  selectActivityTags,
  selectStressTags,
  selectMoodTags,
  selectSleepTags,
  selectBowelTags,
  selectUserTags,
} from '../../../store/selectors/loggingPageSelector';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';
import { SideMenuSelectedType} from '../../../utils/enums';
import {hasGeneralRoles} from '../../../utils/role-helper';
// import TwilioService from '../../../services/TwilioService';
// import GiphyService from '../../../services/GiphyService';
// import {ITokenDto} from '../../../dtos/ITokenDto';
// import {getTokenServer} from '../../../api/chat.api';
// import CoachingHistory from '../../../common/components/coaching-history/CoachingHistory';
// import HistoryLogsWidget from '../../../common/components/history-logs-widget/HistoryLogsWidget';
const HistoryLogsWidget = React.lazy(
  () => import('../../../common/components/history-logs-widget/HistoryLogsWidget'),
);
const CoachingHistory = React.lazy(
  () => import('../../../common/components/coaching-history/CoachingHistory'),
);
const AppointmentWrapper = React.lazy(
  () => import('../../../common/components/appointment/AppointmentWrapper'),
);
// import AppointmentWrapper from '../../../common/components/appointment/AppointmentWrapper';
const NotesWrapper = React.lazy(() => import('../../../common/components/notes/NotesWrapper'));
// import NotesWrapper from '../../../common/components/notes/NotesWrapper';
import useStyles from './home.styles';
import store from '../../../store/store';
import {clearLocaleStorage} from '../../../utils/locale-storage-service';
import {logoutUserApi} from '../../../api/users.api';
import { appointmentEnableUsers } from '../../../store/selectors/nourish.selectors';
// import { useCookies } from 'react-cookie';

interface IHomeProps {
  example?: string;
}

const Home: React.FC<IHomeProps> = () => {


  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet,
  });
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const selectedMember = useSelector(selectMember);
  // const appointments = useSelector(selectAppointments);
  const selectedUser = useSelector(selectUserSuperCoach);
  // const selectedConversationId = useSelector(selectSelectedConversationId);
  const allPhases = useSelector(selectPhases);
  const categoryTags = useSelector(selectCategoryTags);
  const activityTags = useSelector(selectActivityTags);
  const stressTags = useSelector(selectStressTags);
  const moodTags = useSelector(selectMoodTags);
  const sleepTags = useSelector(selectSleepTags);
  const bowelTags = useSelector(selectBowelTags);
  const userTags = useSelector(selectUserTags);
   const conversationsBackend = useSelector(selectBackendConversations);

  const users = useSelector(selectAllUsers);
  const allUsersData = {
    trainerId: selectedUser ? selectedUser.id : currentUser && currentUser.id,
  };
  const appointmentUsersData = useSelector(appointmentEnableUsers);
  const isAppointmentEnabels =  appointmentUsersData?.includes(currentUser?.id);
  const insightsRef: any = useRef(null);
  const progressRef: any = useRef(null);
  const exerciseRef: any = useRef(null);
  const meditationRef: any = useRef(null);
  const coachingHistoryRef: any = useRef(null);
  const appointmentsRef: any = useRef(null);

  // const [chatWidgetChange /*, setChatWidgetChange*/] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [openSideMenuBar, setOpenSideMenuBar] = useState(false);
  // const [modalChat, setModalChat] = useState(false);
  // const [modalChatIcon, setModalChatIcon] = useState(false);
  // const [cookies, setCookie] = useCookies([
  //   "CloudFront-Key-Pair-Id",
  //   "CloudFront-Policy",
  //   "CloudFront-Signature"
  // ]);

  // const initConversations = async (myToken: string) => {
  //   new TwilioService(myToken);
  //   await TwilioService.initTwilioService();
  //   // const conversationsClient = await TwilioService.getInstance();
  //   //
  //   // conversationsClient.on('participantUpdated', function () {
  //   //   //setChatWidgetChange((chatWidgetChange) => !chatWidgetChange);
  //   // });
  // };
  // const fetchCookies = async () => {
  //   try {
  //     const cookieResponse = await getSignedCookie(); // Fetch your cookies from the API
  //     console.log("cookieResponse", cookieResponse);
  //     const domain ="betrhealth.com"
  //     // Set each cookie
  //     setCookie("CloudFront-Key-Pair-Id", cookieResponse["CloudFront-Key-Pair-Id"], {
  //       path: '/',
  //       domain,
  //       maxAge: 7 * 24 * 60 * 60
  //     });
  //     setCookie("CloudFront-Policy", cookieResponse["CloudFront-Policy"], {
  //       path: '/',
  //       domain,
  //       maxAge: 7 * 24 * 60 * 60
  //     });
  //     setCookie("CloudFront-Signature", cookieResponse["CloudFront-Signature"], {
  //       path: '/',
  //       domain,
  //       maxAge: 7 * 24 * 60 * 60
  //     });
  //   } catch (error) {
  //     console.error("Error fetching cookies:", error, cookies);
  //   }
  // };


  const getUserInfo = async () => {
    await dispatch(usersActions.getCurrentUser());
  };

  useEffect(() => {
    getUserInfo();
    // fetchCookies();
    
  }, []);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        !userTags?.length && dispatch(loggingActions.loadUsersCategoryWithTags()),
        !allPhases?.length && dispatch(homepageActions.loadUserPhases()),
        dispatch(homepageActions.loadHistoryLogs(currentUser.id, 3, 0)),
        dispatch(homepageActions.loadUserAppointments()),
        // loading data for widgets on click on insights table
        !categoryTags?.length && dispatch(loggingActions.loadAllTagsWithCategories()),
        !activityTags?.length && dispatch(loggingActions.loadActivityTags()),
        !stressTags?.length && dispatch(loggingActions.loadStressTags()),
        !moodTags?.length && dispatch(loggingActions.loadMoodTags()),
        !sleepTags?.length && dispatch(loggingActions.loadSleepTags()),
        !bowelTags?.length && dispatch(loggingActions.loadBowelTags()),
      ]);

      if (currentUser?.roles && currentUser.roles?.length > 1 && !users?.length) {
        await dispatch(usersActions.searchAllUsers(allUsersData));
        await dispatch(
          usersActions.searchAllUsers({...allUsersData, includeSecondaryTrainees: true}),
        );
      }
    };
    const getInfo = async () => {
      try {
        // const response: ITokenDto = await getTokenServer(); //to do preko reduxa
        // const myToken = response.token;
        dispatch(homepageActions.setLoading(true));

        !conversationsBackend?.length &&
          (await dispatch(homepageActions.loadBackendConversations()));
        // new GiphyService();
        // await initConversations(myToken);

        // !selectedConversationId &&
        //   (await TwilioService.selectConversationForUserTrainer(
        //     currentUser,
        //     async (conversation) => {
        //       await dispatch(homepageActions.selectConversation(conversation));
        //     },
        //   ));
      } catch (error) {
        if (
          error?.message === 'Request failed with status code 401' ||
          error === 'Request failed with status code 401'
        ) {
          logoutUserApi();
          setTimeout(() => {
            clearLocaleStorage();
            store.dispatch({type: usersActionTypes.LOGOUT, payload: null});
          }, 100);
        } else if (error?.message === 'Access forbidden for identity (status: 403, code: 54007)') {
          // console.log('Get Infor error: ', error);
          // throw error;
        } else {
          // dispatch(usersActions.saveErrorStatus({isError: true, data: 'statusCaughtError'}));
          // console.log('Get Infor error: ', error);
          // throw error;
        }

        // throw error;
      }
    };

    const initializePage = async () => {
      // setIsLoading(true);
       await getInfo();
      await loadData();
    //  setIsLoading(false);
    };

    if (currentUser) {
      initializePage();

      // const introCalls = appointments.filter(
      //   (oneAppointment) =>
      //     oneAppointment.appointmentType === AppointmentType.INTRO && !oneAppointment.attended,
      // );

      // if (introCalls?.length > 0) {
      //   appointmentsRef?.current?.scrollIntoView();
      // }
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      await dispatch(homepageActions.loadHistoryLogs(selectedMember?.id, 3, 0));
      // setModalChatIcon(true);
    };
    if (selectedMember) {
      loadData();
    }
  }, [selectedMember]);

  // useEffect(() => {
  //   if (currentUser) {
  //     const introCalls = appointments.filter(
  //       (oneAppointment) =>
  //         oneAppointment.appointmentType === AppointmentType.INTRO && !oneAppointment.attended,
  //     );
  //     if (introCalls?.length > 0 && introCalls[0].attended === false) {
  //       appointmentsRef?.current?.scrollIntoView();
  //     }
  //   }
  // }, [appointments]);

  const executeScroll = (sideMenuItem: SideMenuSelectedType) => {
    switch (sideMenuItem) {
      case SideMenuSelectedType.SIDEMENU_INSIGHTS:
        insightsRef?.current?.scrollIntoView();
        break;

      case SideMenuSelectedType.SIDEMENU_PROGRESS:
        progressRef?.current?.scrollIntoView();
        break;

      case SideMenuSelectedType.SIDEMENU_APPOINTMENTS:
        appointmentsRef?.current?.scrollIntoView();
        break;

      case SideMenuSelectedType.SIDEMENU_EXERCISE:
        exerciseRef?.current?.scrollIntoView();
        break;

      case SideMenuSelectedType.SIDEMENU_MEDITATION:
        meditationRef?.current?.scrollIntoView();
        break;

      case SideMenuSelectedType.SIDEMENU_COACHING_HISTORY:
        coachingHistoryRef?.current?.scrollIntoView();
        break;

      default:
    }
  };

  return (
    <React.Fragment>
      {currentUser && (
        <div className={classes.mainContainer}>
          {currentUser && hasGeneralRoles(currentUser) && smallScreens ? (
            <SideMenuBarMob
              openSideMenuBar={openSideMenuBar}
              setOpenSideMenuBar={setOpenSideMenuBar}
            >
              <SideMenuBar
                executeScrollOnElement={(sideMenuItem: SideMenuSelectedType) => {
                  setOpenSideMenuBar(false);
                  executeScroll(sideMenuItem);
                }}
              />
            </SideMenuBarMob>
          ) : (
            <SideMenuBar
              executeScrollOnElement={(sideMenuItem: SideMenuSelectedType) =>
                executeScroll(sideMenuItem)
              }
            />
          )}

          <div className={classes.mainCenterContent}>
            {/* <ReferralCard /> */}
            <QuickUserProfileWidget   smallScreens={smallScreens}/>
            <div ref={progressRef} className={classes.scrollMargin}>
              <ProgressWidget isMobile={isMobileOrTablet.isMobile} />
            </div>

            <div ref={insightsRef} className={classes.scrollMargin}>
              <InsightsWidget isMobile={isMobileOrTablet.isMobile} />
            </div>
            {/* {!smallScreens && ( */}
              {/* <ChatWidget
                widgetChange={chatWidgetChange}
                isLoading={isLoading}
                smallScreens={smallScreens}
              /> */}
            {/* )} */}
            {isAppointmentEnabels === true && (
                <div ref={appointmentsRef} className={classes.scrollMargin}>
                {selectedMember ? (
                  <Suspense fallback={<div>Loading...</div>}>
                    <AppointmentWrapper selectedMember={selectedMember} selected={true} />
                  </Suspense>
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    <AppointmentWrapper selected={false} />
                  </Suspense>
                )}
              </div> )
            }
           
            <Suspense fallback={<div>Loading...</div>}>
              <HistoryLogsWidget />
            </Suspense>

            {/* {modalChat && (
              <ContentDialog
                content={
                  <ChatWidget
                    widgetChange={chatWidgetChange}
                    isLoading={isLoading}
                    smallScreens={smallScreens}
                  />
                }
                fullScreen={true}
                dialogState={true}
                handleDismiss={() => setModalChat(false)}
              />
            )} */}

            {smallScreens && selectedMember && (
              <div ref={insightsRef} className={classes.scrollMargin}>
                <Suspense fallback={<div>Loading...</div>}>
                  <NotesWrapper />
                </Suspense>
              </div>
            )}
            {(selectedMember ? selectedMember.hasCoaching : currentUser.hasCoaching) && (
              <div ref={coachingHistoryRef} className={classes.scrollMargin}>
                <Suspense fallback={<div>Loading...</div>}>
                  <CoachingHistory />
                </Suspense>
              </div>
            )}

            {/*<div ref={exerciseRef} style={{scrollMargin: `${SCROLL_MARGIN}px`}}>*/}
            {/*  <ExerciseWidget />*/}
            {/*</div>*/}

            {/*<div ref={meditationRef} style={{scrollMargin: `${SCROLL_MARGIN}px`}}>*/}
            {/*  <MeditationWidget />*/}
            {/*</div>*/}
          </div>
          {!smallScreens &&
            (selectedMember ? (
              <div className={classes.notificationPanel}>
                <Suspense fallback={<div>Loading...</div>}>
                  <NotesWrapper />
                </Suspense>
              </div>
            ) : (
              <div className={classes.notificationPanel}>
                <ReminderWrapper />
              </div>
            ))}
        </div>
      )}
      {/* {smallScreens && modalChatIcon && (
        <ChatAvatarButton
          isMobileOrTablet={isMobileOrTablet}
          selectedMember={selectedMember}
          currentUser={currentUser}
          openChat={() => setModalChat(true)}
        />
      )} */}
      {!smallScreens && <Footer />}
    </React.Fragment>
  );
};

Home.displayName = 'Home';
export default Home;
