import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bowelMovementValueTxt: {
    marginBottom: 8,
  },
  tagsContainer: {
    marginTop: 22,
  },
}));

export default useStyles;
