import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.light,

    '&:hover': {
      opacity: 0.6,
    },
  },
  outlined: {
    padding: '0px 16px',
    // border: `1.5px solid ${theme.palette.primary.light}`,
    minHeight: 24, //to do
    color: theme.palette.primary.light,

    '&:hover': {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      // backgroundColor: theme.palette.tintsAndShades.beauBlue,
      backgroundColor: "#DDD6D6",
    },
    '&:disabled': {
      borderColor: theme.palette.blacksAndGrays.gainsboro,
    },
  },
  contained: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.light,
    padding: '9px 16px',

    '&:hover': {
      // backgroundColor: theme.palette.tintsAndShades.lapisLazuli,
      backgroundColor: '#ffffff',
      color:"#000000",
    },

    '&:disabled': {
      color: theme.palette.blacksAndWhites.sonicSilver,
      backgroundColor: theme.palette.blacksAndGrays.gainsboro,
    },
  },
}));

export default useStyles;
