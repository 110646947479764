import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  container: {
    padding: '16px 24px',
    width: (props) => (props.smallScreens ? '100%' : 628),
    marginBottom: 40,
    backgroundColor: '#EBF4FF',
    border: '1px solid #9DC6F5',
    boxSizing: 'border-box',
    borderRadius: 12,
  },
  titleColor: {
    color: '#042D5C',
    marginBottom: 8,
  },
  textColor: {
    color: '#001329',
  },
}));

export default useStyles;
