import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles({

    modal: {
        '& .MuiBackdrop-root': {
            background: 'rgba(45, 45, 46, 0.8)'
        }
    },
    thankFeedback:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 846,
        width:"100%",
        textAlign:"center",        
        overflowY:"auto", 
        background: 'radial-gradient(175.67% 163.13% at -47.08% -37.08%, #BAF5F5 0%, #E9FFFF 39.06%, #EBF4FF 64.58%, #D2DAFA 100%)', 
        borderRadius: "12px",
        boxShadow: "0px 2px 1px rgba(100, 101, 102, 0.2), 0px 1px 1px rgba(100, 101, 102, 0.14), 0px 1px 3px rgba(100, 101, 102, 0.12)",
        padding:"64px 109px 64px 96px",


    '& .h3':{
        fontWeight:"700",
        fontSize:"28px",
        lineHeight:"32px",
        margin:"0",

    },
    '& .closePopup':{
            position: "absolute",
            top: "21px",
            right: "21px",
            zIndex: "1",
            cursor:"pointer",
            '& img':{
                height:"15px",
            },
    
            '&:hover':{
                background:"none"
            },
    },

    '& .headingh1':{
        margin:"0 0 40px 0",
        lineHeight:"40px"
    },
   
    '& .ptag':{
        margin:"0 0 0 0",
        fontSize:"20px",
        lineHeight:"32px",
        fontWeight:"700"

    },
    '& .poppupForm':{
        width:"100%",        
        background:"#fff",
        margin:"40px 0 0 0",
        padding:"40px 109px 64px 109px",
        borderRadius: "12px",
        boxShadow: "0px 11px 15px #D4DEEA, 0px 24px 38px #EDF6FF, 0px 9px 46px rgba(234, 236, 249, 0.12)",
        '& h4':{

            fontWeight:"700",
            fontSize:"28px",
            lineHeight:"32px",
            margin:"0px"
        },

        '& .para2':{
            fontSize: "16px",
            fontWeight: 400,
            margin:"8px 0 0 0",
            lineHeight: "24px",
        },
        '& .feedbackConntentBox':{    
            position:"relative",
        maxWidth:"409px",
        width:"100%",
        margin:"40px auto 0",
        padding:"38px 24px 40px 24px",
        backgroundImage: " radial-gradient(105.09% 77.78% at 46.85% 41.53%, rgba(255, 255, 255, 0.3) 0%, rgba(235, 255, 255, 0.3) 46.69%, rgba(197, 223, 252, 0.3) 74.44%, rgba(210, 218, 250, 0.3) 79.17%)",
        borderRadius: "12px",
        border: "1px solid #D2DAFA",
        '& p':{
            margin:"0px 0 16px 0",
            fontWeight:"700",
        },
        '& span':{
            fontSize:"10px",
            letterSpacing: "1.5px",
             textTransform: "uppercase",
             color:"#5D6B7A",
             lineHeight:"12px",
        },
        '& h5':{
            fontSize:"14px",
            letterSpacing: ".25px",        
             color:"#001329",
             fontWeight:"600",
             lineHeight:"20px",
             margin:"16px 0 0px 0"
             
        },
        '& .bgfeedback':{
            position: "absolute",
            top: "0px",
            width: "100%",
            height: "100%",
            left: "0",
            right: "0",
            zIndex: "1",
            borderRadius: "10px",
        },

        '& .feedbackrelative':{
            position:"relative",
            zIndex:"22"
            },

         '& .line':{
                width:"179px",
                background:"#D7DBE0",
                height:"1px",
                margin:"0 auto"
        },
        '& .line2':{
            width:"211px",
            background:"#D7DBE0",
            height:"1px",
            margin:"0 auto"
        },
        },
        
    },
    ['@media (max-width:1440px)']: {
        overflowY:"auto",
        height:"90vh",
        
    },

    ['@media (max-width:1240px)']: {
        overflowY:"auto",
        height:"100vh",
        maxHeight:"851px",
        
        
    },
    ['@media (max-width:1024px)']: {
        padding:"56px 40px",
        maxWidth: 696,
        overflowY:"auto",       
        height:"100vh",
        

        '& .headingh1':{
        fontSize:"36px",
        },
 
        '& .poppupForm':{
            margin:"40px 0 0 0",
            padding:"40px 103px 56px 103px",
            

        },
        '& .feedbackConntentBox':{
            padding:"32px 24px 40px 24px !important"

        },
        
      },

      ['@media (max-width:767px)']: {
        padding:"56px 16px",
        height:"100vh",
        borderRadius:"0",
        maxWidth: "100%",
        maxHeight:"100%",
        
        '& .headingh1':{
            fontSize:"24px",
            lineHeight:"28px",
            margin:"0 0 24px 0"
            },
            '& .ptag':{
                fontSize:"16px",
                lineHeight:"20px",
                margin:"0 0 0 0"
                },
     
            '& .poppupForm':{
                margin:"24px 0 0 0",
                padding:"24px 16px",
                '& h4':{
                    fontWeight:"700",
                    fontSize:"20px !important",
                    lineHeight:"24px !important",
                    margin:"0px"
                },

               
                '& .para2':{
                   
                    fontSize:"14px !important",
                    lineHeight:"20px !important",
                    
                },
    
            },
            '& .feedbackConntentBox':{
                margin: "24px auto 0 !important",
                padding:" 32px 24px 24px !important"
    
            },
            '& .h3':{
                fontWeight:"700",
                fontSize:"20px !important",
                lineHeight:"24px !important",
                margin:"0px"
            },

            '& .closePopup':{               
                top: "17px",
            },
      },
    
    
    
    
   

}/*end */    

  });

  export default useStyles;