import * as React from 'react';
import useStyles from './selected-coach-item.styles';
import {Typography} from '@material-ui/core';
import classNames from 'classnames';
import {IUser} from '../../../models/IUser';
import ConnectionsInactiveItemIcon from '../../../assets/icons/side-menu-bar/connections-inactive-icon.svg';
import {useTranslation} from 'react-i18next';

interface Props {
  coach: IUser;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const SelectedCoachItem: React.FC<Props> = ({coach}) => {
  const selectedUser = coach;
  const imageAvatar = selectedUser.imageUrl;
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div id={`selected-user-${selectedUser.id}`} className={classNames(classes.itemContainer)}>
      <img
        className={classes.connectionItemIcon}
        src={imageAvatar || ConnectionsInactiveItemIcon}
        alt="icon"
      />
      <div className={classes.nameTitle}>
        <Typography variant={'body1'} className={classes.connectionItemText}>
          {selectedUser.firstName + ' ' + selectedUser.lastName}
        </Typography>
        <Typography variant={'caption'} className={classes.connectionItemText}>
          {t('Homepage.sideMenuBar.searchMembersDropDown.coach')}
        </Typography>
      </div>
    </div>
  );
};
