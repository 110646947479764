import {Chip as ChipMaterial, useMediaQuery} from '@material-ui/core';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { TABLET_BIG_SIZE } from '../../../utils/constants';

import useStyles from './tag.styles';

interface Props {
  className?: string;
  disabled?: boolean;
  isSelected?: boolean;
  label: string;
  id: number;
  name: string;
  onClick?: any;
  onDelete?: any;
}

export const Tag: React.FC<Props> = (props) => {
  const { id, className, label, isSelected, onClick, onDelete } = props;
  const isSmallSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, { noSsr: true })
  const isMobileOrTablet = React.useMemo(() => {
    return  isMobile || isSmallSize
  }, [window.innerWidth])
  const classes = useStyles({
    isSelected: isSelected || false,
    isMobileOrTablet
  });

  return (
    <div className={classNames(className, classes.chipContainer)} key={props.name + ' ' + id}>
      <ChipMaterial
        label={label}
        variant="default"
        className={classes.chip}
        onDelete={!onDelete ? undefined : () => onDelete(id)}
        onClick={!onClick ? undefined : () => onClick(id)}
      />
    </div>
  );
};
