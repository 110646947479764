/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {get} from './api-calls';
import {IHistoryLogsDto} from '../dtos/IHistoryLogsDto';

export const getHistoryLogs = (
  id: string,
  pageSize: number,
  pageNumber: number,
): Promise<IHistoryLogsDto[]> => {
  return get(
    `/logHistoryAudit/logHistoryAudit?UserId=${id}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
  );
};
