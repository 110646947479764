import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  valuesContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: (props) => (props.isMobile ? 10 : 0),
  },
  valuesText: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
  },
  textLabel: {
    color: theme.palette.blacksAndWhites.sonicSilver,
  },
}));

export default useStyles;
