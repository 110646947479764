import * as React from 'react';

import useStyles from './summary-content.styles';
import {ISectionDto} from '../../../dtos/IDailyContentDto';
import {Typography} from '@material-ui/core';

interface Props {
  section: ISectionDto;
  smallScreens: boolean;
}

export const SummaryContent: React.FC<Props> = ({section, smallScreens}) => {
  const classes = useStyles({
    smallScreens,
  });

  return (
    <div className={classes.container}>
      <Typography variant={'subtitle2'} className={classes.titleColor}>
        {section.Title}
      </Typography>
      <Typography variant={'body1'} className={classes.textColor}>
        {section.content}
      </Typography>
    </div>
  );
};
