import * as React from 'react';
// import {useState} from 'react';
import useStyles from './ellipsis-options.styles';
import TrashIcon from '../../../assets/icons/elipsis-options/trash-delete-icon.svg';
import PencilIcon from '../../../assets/icons/elipsis-options/pencil-edit-icon.svg';
import classNames from 'classnames';
// import EllipsisOptionIcon from '../../../assets/icons/elipsis-options/elipsis-options-icon.svg';
// import CloseOptionsIcon from '../../../assets/icons/elipsis-options/close-menu-icon.svg';
// import {EllipsisType} from '../../../utils/enums';
import {AlertDialog} from '../dialog/AlertDialog';
import {useTranslation} from 'react-i18next';

interface EllipsisOptionsProps {
  className?: string;
  fromDevice?: boolean;
  handleEdit: any;
  handleDismiss: any;
  optionsClassName?: string;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const EllipsisOptions: React.FC<EllipsisOptionsProps> = (props) => {
  const classes = useStyles();
  // const [ellipsisMode, setEllipsisMode] = useState(EllipsisType.SHOW_ELLIPSIS);
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickConfirm = () => {
    props.handleDismiss();
    setOpen(false);
  };

  const handleClickDismiss = () => {
    setOpen(false);
  };

  // const handleChangeMode = (ellipsisType: EllipsisType) => {
  //   setEllipsisMode(ellipsisType);
  // };

  return (
    <React.Fragment>
      {/*{ellipsisMode === EllipsisType.SHOW_ELLIPSIS && (*/}
      {/*  <div*/}
      {/*    onClick={() => handleChangeMode(EllipsisType.SHOW_OPTIONS)}*/}
      {/*    onKeyDown={() => {}}*/}
      {/*    role="button"*/}
      {/*    tabIndex={0}*/}
      {/*  >*/}
      {/*    <img className={classes.optionsItemImage} src={EllipsisOptionIcon} alt={'ellipsis-img'} />*/}
      {/*  </div>*/}
      {/*)}*/}

      {/*{ellipsisMode === EllipsisType.SHOW_OPTIONS && (*/}
      <div className={classes.root}>
        <div
          onClick={() => {
            setOpen(true);
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <img
            className={classNames(props.optionsClassName, classes.optionsItemImage)}
            src={TrashIcon}
            alt={'trash-img'}
          />
        </div>
        {!props.fromDevice && (
          <div onClick={props.handleEdit} onKeyDown={() => {}} role="button" tabIndex={0}>
            <img
              className={classNames(props.optionsClassName, classes.optionsItemImage)}
              src={PencilIcon}
              alt={'pencil-img'}
            />
          </div>
        )}
        {/*<div*/}
        {/*  onClick={() => handleChangeMode(EllipsisType.SHOW_ELLIPSIS)}*/}
        {/*  onKeyDown={() => {}}*/}
        {/*  role="button"*/}
        {/*  tabIndex={0}*/}
        {/*>*/}
        {/*  <img className={classes.optionsItemImage} src={CloseOptionsIcon} alt={'close-img'} />*/}
        {/*</div>*/}
      </div>
      {/*)}*/}
      <AlertDialog
        confirmButtonText={t('LogsPage.ellipsis.confirmButtonText')}
        dismissButtonText={t('LogsPage.ellipsis.dismissButtonText')}
        headerText={t('LogsPage.ellipsis.headerText')}
        contentText={t('LogsPage.ellipsis.contentText')}
        handleConfirm={handleClickConfirm}
        handleDismiss={handleClickDismiss}
        dialogState={open}
      />
    </React.Fragment>
  );
};

EllipsisOptions.displayName = 'EllipsisOptions';
