import {Typography} from '@material-ui/core';
import React from 'react';
import useStyles from './welcome-onboarding.styles';
import {Button} from '../button/Button';
import {OnBoardingSteps} from '../../../utils/enums';
import {useTranslation} from 'react-i18next';

interface IWelcomeOnBoardingProps {
  name: string;
  onContinueClick: (step: OnBoardingSteps) => void;
}

const WelcomeOnBoarding: React.FC<IWelcomeOnBoardingProps> = (props) => {
  const classes = useStyles();
  const {name, onContinueClick} = props;
  const [t] = useTranslation();

  return (
    <div className={classes.container}>
      <div>
        <Typography variant={'h5'}>
          {t('OnBoardingPage.welcome.hi')} {name},
        </Typography>
        <Typography variant={'h5'}>{t('OnBoardingPage.welcome.welcome')}</Typography>
      </div>

      <Typography variant={'body1'}>{t('OnBoardingPage.welcome.setUpAccount')}</Typography>

      <Button id="continue" onClick={() => onContinueClick(OnBoardingSteps.PROFILE_INFO)}>
        {t('OnBoardingPage.continue')}
      </Button>
    </div>
  );
};
export default WelcomeOnBoarding;
