/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Card, CardContent, CardMedia, Typography, CardActionArea} from '@material-ui/core';

import workoutimg from '../../../../assets/images/workoutsimage.png';
import lockClosedIcon from '../../../../assets/images/lock-closed.svg';
import ProgressCircular from './ProgressCircular';

import useStyles from './movement-card.styles';
import {useHistory, useParams} from 'react-router';
import {GeneralPaths} from '../../../../utils/constants';
const MovementCard = ({workoutList, workoutGroupSlug, workoutDetail}) => {
  const classes = useStyles();
  const history = useHistory();
  const params: any = useParams();
  const myShopfiyRedirect = (buyLink: any) => {
    // console.log('myShopfiyRedirect', buyLink);
    window.open(
      buyLink,
      '_blank', // <- This is what makes it open in a new window.
    );
  };

  const handleClick = (workoutSlug: any) => {
    // setSelectedWorkoutDay(workoutId)
    if (params?.movementType === 'rehab') {
      history.replace({
        pathname: `${GeneralPaths.MovementPage}/${workoutGroupSlug}/${workoutSlug}`,
      });
    } else {
      history.replace({
        pathname: `${GeneralPaths.MovementPage}/fitness/${workoutGroupSlug}/${workoutSlug}`,
      });
    }
  };
  return (
    <>
      <div className={classes.cardArea}>
        {workoutList &&
          workoutList.map((workout: any, index: any) => {
            return (
              <div className="CardSection" key={index}>
                <Card
                  className={`${workout.completed ? 'cardActive' : 'card'}`}
                  onClick={
                    !workoutDetail?.tag || !workoutDetail?.userID
                      ? () => myShopfiyRedirect(workoutDetail?.buyLink)
                      : () => handleClick(workout?.workoutSlug)
                  }
                >
                  <CardActionArea>
                    {!workoutDetail?.tag ||
                      (!workoutDetail?.userID && (
                        <div className={classes.lockCard} style={{cursor: 'pointer'}}>
                          <img src={lockClosedIcon} alt="" />
                        </div>
                      ))}
                    <div className="hoverCardMedia">
                      <CardMedia
                        component="img"
                        height="148"
                        image={workout.workoutImageUrl || workoutimg}
                        alt="workout image"
                        className="cardMedia"
                      />
                      {workout.completed && (
                        <div className="hoverContent">
                          {workoutDetail?.tag && workoutDetail?.userID && (
                            <div>
                              <a href="javascript:void(0)" className="repeatbtn">
                                Repeat
                              </a>
                              <a href="javascript:void(0)" className="viewtbtn">
                                View Exercises
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <CardContent className="cardContent">
                      <div className="cardContentFlex">
                        <div>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            className="cardheading"
                          >
                            {workout.workOutName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {workout.exerciseCount}{' '}
                            {workout.exerciseCount === 1 || workout.exerciseCount === '1'
                              ? 'Exercise'
                              : 'Exercises'}
                          </Typography>
                        </div>
                        {workout.completed && (
                          <ProgressCircular
                            effectivenessScore={workout?.workoutScore}
                            batchIcon={workout?.batchIcon}
                          />
                        )}
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MovementCard;
