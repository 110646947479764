import React from 'react';
import {useDispatch} from 'react-redux';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import {Tag} from '../../tags-with-box/Tag';
import * as activityLogingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';

import useStyles from './activity-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface ActivityRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
}

const ActivityRecentlyAddedRow: React.FC<ActivityRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const handleDismiss = () => {
    dispatch(activityLogingActions.deleteActivity(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };

  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        <div className={commonClasses.infoContainer}>
          {isMobile ? (
            <div>
              <DateTimeString date={data.date} isMobile={isMobile} />
              <div className={commonClasses.infoContainer}>
                <Typography variant={'subtitle1'} className={classes.intensityContent}>
                  {data.workoutIntensity}
                </Typography>
                <Typography variant={'body1'} className={classes.textContent}>
                  {data.duration + ' min'}
                </Typography>
              </div>
              {data.tagsObject?.length > 0 && (
                <div className={classes.tagsContainer}>
                  {data.tagsObject.map((oneTag: any) => (
                    <Tag
                      key={`tag-selected-${oneTag.id}`}
                      name="chip"
                      isSelected={true}
                      id={oneTag.id || 0}
                      label={oneTag.name}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <DateTimeString date={data.date} isMobile={isMobile} />
              <Typography variant={'subtitle1'} className={classes.intensityContent}>
                {data.workoutIntensity}
              </Typography>
              <Typography variant={'body1'} className={classes.textContent}>
                {data.duration + ' min'}
              </Typography>
              {data.tagsObject?.length > 0 && (
                <div className={classes.tagsContainer}>
                  {data.tagsObject.map((oneTag: any) => (
                    <Tag
                      key={`tag-selected-${oneTag.id}`}
                      name="chip"
                      isSelected={true}
                      id={oneTag.id || 0}
                      label={oneTag.name}
                    />
                  ))}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={commonClasses.buttonsContainer}>
          <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
        </div>
      </div>
    </div>
  );
};
export default ActivityRecentlyAddedRow;
