import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    marginRight: 8,
    '& .MuiSlider-root': {
      marginTop: 12,
      color: theme.palette.blacksAndGrays.silverSand,
    },
    '& .MuiSlider-markActive': {
      backgroundColor: theme.palette.blacksAndGrays.silverSand,
    },
    '& .MuiSlider-thumb': {
      backgroundColor: theme.palette.primary.light, //#addbde',
      width: 16,
      height: 16,
      marginTop: -7,
    },
    '& .MuiSlider-mark': {
      width: 7,
      height: 7,
      borderRadius: 7,
      marginTop: -3,
    },
    '& .MuiSlider-markLabel': {
      color: theme.palette.blacksAndGrays.stoneGray,
    },
    '& .MuiSlider-markLabelActive': {
      color: theme.palette.primary.light,
    },
  },
  rangeBox: {
    width: (props) => (props.isMobile ? '100%' : 460),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useStyles;
