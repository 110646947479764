import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    fitnessLoader: {
        position: 'fixed',
        left: 0,
        top: 0,
        background: 'rgba(255,255,255,0.7)',
        'z-index': '9'
    },
    dayoneBannerArea:{
        marginRight:"16px",
   '& .subtitle':{
    color:"#184C88",
    fontSize:16,
    fontWeight:600,
    margin:"0 0 24px 0",
    letterSpacing:0.1 ,
    lineHeight:"20px",
    '& span': {
        cursor: 'pointer'
    }
   },

   '& .totalScoreArae':{
    margin:"0 0 16px 0",
    display:"none"
},
   '& .headingh3':{
    color:"#140802",
    fontSize:36,
    fontWeight:600,
    margin:"0 0 24px 0",
    letterSpacing:0.25 ,
    lineHeight:"40px",

   },

   '& .dayoneBanner':{
    position: "relative",
   
    margin:"0 0 24px 0",
    borderRadius:20,
    '& .bnrimg':{
        width:"100%",
        borderRadius:20,    
        objectFit:"cover"
       },

    '& .dayoneContent':{
        position: "absolute",
        top:0,
        left:0,
        right:0,
        width:"100%",
        height:"100%",borderRadius:20,
       background: "linear-gradient(180deg, rgba(175, 175, 175, 0.1) 0%, #000000 100%)",

    '& .betr':{
        position: "absolute",
        right:25,
        top:25,   
       },
       },   

    '& .bnrBottomContent':{
        position: "absolute",
        bottom:32,
        color:"#fff",
        width:"90%",
        left:0,
        right:0,
        margin:"0 auto",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",

    '& .headingh4':{
        fontSize:"28px",
        fontWeight:700,
        margin:"0 0 6px 0",
        color:"#fff",
        lineHeight:"32px",
     },

    '& .subtitle2':{
            color:"#fff",
            fontSize:16,
            fontWeight:600,
            margin:"0 0 0 0",
            letterSpacing:0.1 ,
            lineHeight:"20px",
            fontFamily:"Inter",
            fontStyle:"normal"
    },

    '& .StartAgain':{
        background: "radial-gradient(83.32% 398% at 41.13% -206%, #BAF5F5 0%, #9DC6F5 64.23%, #A6B6F5 100%)" ,
        borderRadius: "150px",
        color:"#001329",
        fontSize:16,
        fontWeight:500,       
        letterSpacing:"1.25px" ,
        lineHeight:"56px",
        fontFamily:"Inter",
        width: "302px",
        height: "56px",
        textAlign:"center",
        textDecorationLine:"none"
    },


       },  
       
   },
   '& .StartAgain2':{ 
    background: "radial-gradient(83.32% 398% at 41.13% -206%, #65F7F7 0%, #3986DF 64.23%, #3655D2 100%)" ,
   borderRadius: "150px",
   color:"#fff",
   fontSize:16,
   fontWeight:500,       
   letterSpacing:"1.25px" ,
   lineHeight:"56px",
   fontFamily:"Inter",
   width: "100%",
   height: "56px",
   textAlign:"center",
   textDecorationLine:"none",
   display:"none",
   margin:"16px 0 24px 0",
},


   /* startCard*/
   '& .exerciseMainSec':{  
    background: "#fff",
    border: "1px solid #D7DBE0",
    borderRadius: "12px",
    
    marginTop:"16px",
    '&.completed': {
        background: "radial-gradient(83.32% 398% at 41.13% -206%, #EBFFFF 0%, #EBF4FF 64.23%, #EBEFFF 100%)",
    },

    '& .exerciseBox':{  

        padding:"24px 16px",
        borderBottom:"1px solid #D7DBE0",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        '& h3': {
            margin: '0',
            fontSize: '16px',
            fontWeight: '600',
            color:"#140802"
        },
        '& ul': {
            margin: 0,
            padding: '6px 0 0 0',
            listStyle: 'none',
            display:"table",
            width:"100%",
            
        '& li': {
            position: 'relative',
            paddingRight: '18px',
           
            float:"left",
            fontSize:"14px",
            fontWeight: '300',
            color:"#140802",
            lineHeight: "20px",
        },
        '& li::before': {
            content: '""',
            position: 'absolute',               
            left: "-10px",
            top: "9px",
            borderRadius: '50%',
            width: '2px',
            height: '2px',
            background:"#140802"
            
        },
        '& li:nth-child(1)::before': {
            display:"none"
        },
        
        },

        '& .border': {
            width:"100%",
            height:"1px",
            background:"#D7DBE0",
            margin:"12px 0",
        },'& .exerciseImage': {
            width:"156px",
            height:"108px",
            borderRadius: "12px",
            marginRight:"17px",
            objectFit:"cover"
        },

        '& .exerciseContent': {
            width:"100%",
        },

        '& .workoutContent': {
            display:"flex",  
            '& div':{
                margin:"0 24px 0 0",
            },
            '& h6': {
                margin: '0 0 1px 0',
                fontSize: '10px',
                fontWeight: '400',
                color:"#5D6B7A",
                letterSpacing: "1.5px",
                textTransform: "uppercase",
                lineHeight: "12px",
            },
            '& h5': {
                margin: '0',
                fontSize: '14px',
                fontWeight: '400',
                color:"#140802",
                letterSpacing: "0.25px",
                lineHeight: "20px",
            },

},
},
},
/* EndCard*/
['@media (max-width:1280px)']: {

    '& .exerciseMainSec':{
        padding:"0 16px",
        '& .exerciseBox':{
        padding:"24px 0 !important"
        },
    },
    '& .totalScoreArae':{
        display:"block"
    },
    
    },

    ['@media (max-width:767px)']: {
        margin:"0",
        padding:"0 16px",
        '& h6':{
        display:"none"
        },
    
        '& .headingh3':{
            display:"none"
        },
        '& .StartAgain':{ 
            display:"none"
        },
    
        '& .StartAgain2':{ 
            display:"block"
        },
        '& h5':{
            lineHeight:"24px"
         },
    
        '& .dayoneBanner':{
            margin:"0",
          
         '& .bnrBottomContent':{
          bottom:"12px !important",
          '& .headingh4':{
           fontSize:"16px !important",
           lineHeight:"20px !important",
           fontWeight: "600 !important",
           },
           '& .subtitle2':{
            fontSize:"12px !important",
            lineHeight:"16px !important",
            fontWeight: "400 !important",
    
           },
         }, 
         '& .dayContent':{
    
            width:"100%",
         },  
         '& .StartAgain':{
            display:"none"
         },     
    
        },
    
       
        '& .exerciseMainSec':{
            marginRight:"0",
            padding:"0",
    
            '& .exerciseBox':{
                flexWrap:"wrap",
                padding:"16px !important",        
                },
    
            '& .border':{
               display:"none !important"                   
             },
    
             '& .workoutContent':{
               display:"none !important"          
             },
    
    
            '& .exerciseImage':{
                width:"100% !important",
                height:"172px !important",
                borderRadius: "12px",
                margin:"0 0 16px 0 !important" 
            },
            '& h3': {
                margin: '0',
                fontSize: '16px',
                fontWeight: '600',
                color:"#140802",
                display:"block",
                lineHeight:"20px"
            },
           
            '& .worktime':{
             borderRight:"1px solid #D7DBE0",
             margin:"0",
             padding:"0 24px 0 0",
             '& h6':{
                display:"block"
             },
             '&:nth-child(2)':{
                padding:"0 0 0 24px",
                borderRight:"0 ",
             }
            },
         },
        
        },
},
/*------end--------*/


    
  


  });

  export default useStyles;