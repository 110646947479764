/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {handleMargin} from '../../../utils/css-helpers';

interface Props {
  isMobileOrTablet: IResponsiveSize;
}

const useStyles = makeStyles<Theme, Props>((_) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '80px',
    marginBottom: '80px',
    minHeight: '100%',
  },
  mainCenterContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: (props) =>
      props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTabletSmall
        ? '100%'
        : props.isMobileOrTablet.isTabletBig
        ? 840
        : 588,
    margin: (props) => handleMargin(props.isMobileOrTablet, '0 32px'),
  },
}));

export default useStyles;
