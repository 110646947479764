import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import StressIcon from '../../../../assets/icons/side-menu-bar-logging/stress-icon.svg';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {FormType, WidgetType} from '../../../../utils/enums';
import {ValidationType} from '../../../../validation/ValidationType';
import {getError, hasError, isFormValidToSubmit, validate} from '../../../../validation/Validation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import TagsWithBox from '../../tags-with-box/TagsWithBox';
import {ICasualTag} from '../../../../models/ITag';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {IMark, RangeSlider} from '../../range-slider/RangeSlider';
import {MoodEmoticon} from '../../range-slider/MoodEmoticon';
import NoPainVeryMild from '../../../../assets/icons/stress-level/no-pain-very-mild-level.svg';
import DiscomfortingTolerable from '../../../../assets/icons/stress-level/discomforting-tolerable-level.svg';
import DistressingVeryDistressing from '../../../../assets/icons/stress-level/distressing-very-distressing-level.svg';
import IntenseVeryIntense from '../../../../assets/icons/stress-level/intense-very-intense-level.svg';
import UtterlyHorribleExcruciatingUnbearable from '../../../../assets/icons/stress-level/utterly-horrible-excruciating-unbearable-level.svg';
import UnimaginableUnspeakable from '../../../../assets/icons/stress-level/unimaginable-unspeakable-level.svg';
import {
  addStressLog,
  editStressLogg,
} from '../../../../store/actions/logging-page/logging-page-action';

import useStyles from './stress-form.styles';
import useCommonStyles from '../common-for-widgets.styles';
import {DATE_PICKER_DATE_FORMAT} from '../../../../utils/constants';

interface IStressFormData {
  date: string;
  time: string;
  painLevelValue: number;
  moodLevelValue: number;
  tags: ICasualTag[];
  moodTags: ICasualTag[];
}

interface IStressFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  openedFromDialog?: boolean;
  date?: string;
  initialTagList?: ICasualTag[];
  initialMoodTagList?: ICasualTag[];
  isMobile: boolean;
}

const validationRules = {
  date: [{type: ValidationType.REQUIRED}],
  time: [{type: ValidationType.REQUIRED_DURATION}],
};

const defaultPainLevel = 10;
const defaultMoodLevel = 4;

const StressForm: React.FC<IStressFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const formType = props.formType || FormType.ADDING_MODE;
  const [tagsList, setTagsList] = useState<ICasualTag[]>([]);
  const tagsBoxRef: any = useRef();
  const [moodTagsList, setMoodTagsList] = useState<ICasualTag[]>([]);
  const moodTagsBoxRef: any = useRef();

  const [data, setData] = useState<IStressFormData>({
    date: moment().toString(),
    time: moment().toString(),
    painLevelValue: defaultPainLevel,
    moodLevelValue: defaultMoodLevel,
    tags: [],
    moodTags: [],
  });

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }
    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    const initialTagList = props.initialTagList || [];
    const initialMoodTagList = props.initialMoodTagList || [];

    const filterTags = () => {
      initialTagList.forEach((item) => (item.isVisible = true));
      const filteredTagsArray: ICasualTag[] = [];
      initialTagList.forEach((initialTagObject) => {
        props.data?.tagsObject.forEach((oneTagObject: ICasualTag) => {
          if (initialTagObject.id === oneTagObject.id) {
            initialTagObject.isVisible = false;
          }
        });

        filteredTagsArray.push(initialTagObject);
      });

      setTagsList(filteredTagsArray);
    };

    const filterMoodTagsList = () => {
      initialMoodTagList.forEach((item) => (item.isVisible = true));
      const filteredTagsArray: ICasualTag[] = [];
      initialMoodTagList.forEach((initialTagObject) => {
        props.data?.moodTagsObject.forEach((oneTagObject: ICasualTag) => {
          if (initialTagObject.id === oneTagObject.id) {
            initialTagObject.isVisible = false;
          }
        });

        filteredTagsArray.push(initialTagObject);
      });

      setMoodTagsList(filteredTagsArray);
    };

    let initialObject: IStressFormData = {
      painLevelValue: defaultPainLevel,
      moodLevelValue: defaultMoodLevel,
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
      tags: [],
      moodTags: [],
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        painLevelValue: props.data?.painLevelValue || defaultPainLevel,
        moodLevelValue: props.data?.moodLevelValue || defaultMoodLevel,
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
        tags: props.data?.tagsObject || [],
        moodTags: props.data?.moodTagsObject || [],
      };
    }
    if (props.data && props.data?.tags?.length > 0) {
      filterTags();
    } else {
      initialTagList.forEach((item) => (item.isVisible = true));
      setTagsList(initialTagList);
    }

    if (props.data && props.data?.moodTags?.length > 0) {
      filterMoodTagsList();
    } else {
      initialMoodTagList.forEach((item) => (item.isVisible = true));
      setMoodTagsList(initialMoodTagList);
    }

    setData(initialObject);
  }, [props.data, props.initialTagList, props.initialMoodTagList]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    const initialTagList = props.initialTagList || [];
    initialTagList.forEach((item) => (item.isVisible = true));
    setTagsList(initialTagList);
    tagsBoxRef?.current?.clearSelectedTags();

    const initialMoodTagList = props.initialMoodTagList || [];
    initialMoodTagList?.forEach((item) => (item.isVisible = true));
    setMoodTagsList(initialMoodTagList);
    moodTagsBoxRef?.current?.clearSelectedTags();

    setData({
      painLevelValue: defaultPainLevel,
      moodLevelValue: defaultMoodLevel,
      date: moment().toString(),
      time: moment().toString(),
      tags: [],
      moodTags: [],
    });
  };

  const handleChange = (_e: any, value: number, name: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const selectedTags = tagsBoxRef?.current?.getSelectedTags().map((tag: ICasualTag) => tag.id);
      const selectedMoodTags = moodTagsBoxRef?.current
        ?.getSelectedTags()
        .map((tag: ICasualTag) => tag.id);

      const stressLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        painLevelValue: data.painLevelValue,
        moodLevelValue: data.moodLevelValue,
        tags: selectedTags,
        moodTags: selectedMoodTags,
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addStressLog(stressLog));
      } else {
        res = await dispatch(editStressLogg(stressLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  const marks: IMark[] = [
    {
      value: 0,
      label: (
        <MoodEmoticon
          src={UnimaginableUnspeakable}
          name={t('LogsPage.stressWidget.marks.unimaginableUnspeakableName')}
          text={t('LogsPage.stressWidget.marks.unimaginableUnspeakableText')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 1,
      label: (
        <MoodEmoticon
          src={UtterlyHorribleExcruciatingUnbearable}
          name={t('LogsPage.stressWidget.marks.excruciatingUnbearableName')}
          text={t('LogsPage.stressWidget.marks.excruciatingUnbearableText')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 2,
      label: (
        <MoodEmoticon
          src={UtterlyHorribleExcruciatingUnbearable}
          name={t('LogsPage.stressWidget.marks.utterlyHorribleName')}
          text={t('LogsPage.stressWidget.marks.utterlyHorribleText')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 3,
      label: (
        <MoodEmoticon
          src={IntenseVeryIntense}
          name={t('LogsPage.stressWidget.marks.veryIntenseName')}
          text={t('LogsPage.stressWidget.marks.veryIntenseText')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 4,
      label: (
        <MoodEmoticon
          src={IntenseVeryIntense}
          name={t('LogsPage.stressWidget.marks.intenseName')}
          text={t('LogsPage.stressWidget.marks.intenseName')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 5,
      label: (
        <MoodEmoticon
          src={DistressingVeryDistressing}
          name={t('LogsPage.stressWidget.marks.veryDistressingName')}
          text={t('LogsPage.stressWidget.marks.veryDistressingText')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 6,
      label: (
        <MoodEmoticon
          src={DistressingVeryDistressing}
          name={t('LogsPage.stressWidget.marks.distressingName')}
          text={t('LogsPage.stressWidget.marks.distressingName')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 7,
      label: (
        <MoodEmoticon
          src={DiscomfortingTolerable}
          name={t('LogsPage.stressWidget.marks.tolerableName')}
          text={t('LogsPage.stressWidget.marks.tolerableName')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 8,
      label: (
        <MoodEmoticon
          src={DiscomfortingTolerable}
          name={t('LogsPage.stressWidget.marks.discomfortingName')}
          text={t('LogsPage.stressWidget.marks.discomfortingName')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 9,
      label: (
        <MoodEmoticon
          src={NoPainVeryMild}
          name={t('LogsPage.stressWidget.marks.veryMildName')}
          text={t('LogsPage.stressWidget.marks.veryMildText')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 10,
      label: (
        <MoodEmoticon
          src={NoPainVeryMild}
          name={t('LogsPage.stressWidget.marks.noPainText')}
          text={t('LogsPage.stressWidget.marks.noPainText')}
          isScaleEmoticon={true}
        />
      ),
    },
  ];

  const moodMarks: IMark[] = [
    {
      value: 0,
      label: (
        <MoodEmoticon
          src={UtterlyHorribleExcruciatingUnbearable}
          name={t('LogsPage.stressWidget.moodMarks.iFeelAwful')}
          text={t('LogsPage.stressWidget.moodMarks.iFeelAwful')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 1,
      label: (
        <MoodEmoticon
          src={IntenseVeryIntense}
          name={t('LogsPage.stressWidget.moodMarks.iFeelBad')}
          text={t('LogsPage.stressWidget.moodMarks.iFeelBad')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 2,
      label: (
        <MoodEmoticon
          src={DistressingVeryDistressing}
          name={t('LogsPage.stressWidget.moodMarks.iFeelSoSo')}
          text={t('LogsPage.stressWidget.moodMarks.iFeelSoSo')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 3,
      label: (
        <MoodEmoticon
          src={DiscomfortingTolerable}
          name={t('LogsPage.stressWidget.moodMarks.iFeelGood')}
          text={t('LogsPage.stressWidget.moodMarks.iFeelGood')}
          isScaleEmoticon={true}
        />
      ),
    },
    {
      value: 4,
      label: (
        <MoodEmoticon
          src={NoPainVeryMild}
          name={t('LogsPage.stressWidget.moodMarks.iFeelGreat')}
          text={t('LogsPage.stressWidget.moodMarks.iFeelGreat')}
          isScaleEmoticon={true}
        />
      ),
    },
  ];

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img className={commonClasses.imageMargin} src={StressIcon} alt={'stress-icon'} />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.stressWidget.formTitleEdit')
              : t('LogsPage.stressWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.stressWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.stressWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <div className={classes.centerContainer}>
        <Typography variant={'body1'}>{t('LogsPage.stressWidget.rangeSliderMoodLabel')}</Typography>
        <RangeSlider
          id={'moodLevelValue'}
          name={'moodLevelValue'}
          step={1}
          defaultValue={data.moodLevelValue}
          minValue={0}
          maxValue={4}
          marks={moodMarks}
          onChange={(e: any, value: any) => handleChange(e, value, 'moodLevelValue')}
          className={classes.rangeSlider}
        />
      </div>
      <div className={classes.tagsContainer}>
        <Typography variant="caption">{t('LogsPage.stressWidget.moodTagsLabel')}</Typography>
        <TagsWithBox
          id={'mood-tags'}
          name={'mood-tags-name'}
          originalTagsList={moodTagsList}
          ref={moodTagsBoxRef}
        />
      </div>

      <div className={classNames(classes.centerContainer, classes.marginStress)}>
        <Typography variant={'body1'}>{t('LogsPage.stressWidget.rangeSliderLabel')}</Typography>
        <RangeSlider
          id={'painLevelValue'}
          name={'painLevelValue'}
          step={1}
          defaultValue={data.painLevelValue}
          minValue={0}
          maxValue={10}
          marks={marks}
          onChange={(e: any, value: any) => handleChange(e, value, 'painLevelValue')}
          className={classes.rangeSlider}
        />
      </div>
      <div className={classes.tagsContainer}>
        <Typography variant="caption">{t('LogsPage.stressWidget.tagsLabel')}</Typography>
        <TagsWithBox
          id={'stress-tags'}
          name={'stress-tags-name'}
          originalTagsList={tagsList}
          ref={tagsBoxRef}
        />
      </div>

      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-stress-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-stress-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.stressWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default StressForm;
