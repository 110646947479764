import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  mainContainer: {
    width: '100%',
    margin: (props) => (props.smallScreens ? '0 0 20px 0' : '20px 0'),
  },
  dailyContentList: {
    margin: (props) => (props.isMobileOrTablet.isTabletBig ? '16px -12px' : '16px 0px'),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    cursor: 'pointer',
    overflow: 'auto',
    alignContent: 'flex-start',

    '& .categoryContainer': {
      width: '100%',
      marginTop: 15,
      marginBottom: 15,
      backgroundColor: 'white',
    },
  },
  back: {
    cursor: 'pointer',
    padding: 0,
    // paddingLeft:30,
    marginTop: '5px',
    color: theme.palette.primary.main,
    marginBottom: 20,
    '& svg': {
      marginRight: 8,
      stroke: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      letterSpacing: '1.25px',
      fontWeight: 500,
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '25px',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      minWidth: '10px',
      color: theme.palette.blacksAndGrays.grayX11,
      padding: '0 4px',
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    '& .MuiPaginationItem-page': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'transparent',
      color: 'black',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      fill: 'black',
    },
  },
  infiniteScroll: {
    margin: (props) => (props.isMobileOrTablet.isTabletBig ? '16px -12px' : '16px 0px'),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    cursor: 'pointer',
    overflow: 'auto',
    alignContent: 'flex-start',
  },
  dialogMargin: {
    marginTop: (props) => (props.smallScreens ? 0 : 40),
  },
  dialogClose: {
    position: 'fixed',

    '& svg path': {
      fill: theme.palette.blacksAndWhites.pureWhite,
    },
  },
  closeIconRecipesClass: {
    top: 16,
    right: 35,
    border: '1px solid black',
    cursor: 'pointer',
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 9,
  },
}));

export default useStyles;
