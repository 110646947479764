import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import * as bodyMeasurementLoggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';
import {BodyMeasurementType, STRING_INCH} from '../../../../utils/constants';

import useStyles from './body-measurement-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface BodyMeasurementRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
  isHistoryWidget?: boolean;
}

const BodyMeasurementRecentlyAddedRow: React.FC<BodyMeasurementRecentlyAddedRowProps> = (props) => {
  const {data, isMobile, isHistoryWidget} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleDismiss = () => {
    dispatch(bodyMeasurementLoggingActions.deleteBodyMeasurementLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };

  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };

  const handleTextForMeasurementUnits = (text: string) => {
    if (text === STRING_INCH) {
      return BodyMeasurementType.IN.toString();
    }

    return text;
  };

  return (
    <div>
      {!isHistoryWidget && <div className={commonClasses.line} />}

      <div className={commonClasses.dataContainer}>
        <div className={!isMobile ? commonClasses.infoContainer : ''}>
          {!isHistoryWidget && <DateTimeString date={data.date} isMobile={isMobile} />}
          <div className={classes.bodyPartContent}>
            <div className={classes.bodyPartText}>
              <Typography variant={'subtitle1'} className={classes.textLabel}>
                {t('LogsPage.bodyMeasurementWidget.upperArm')}
              </Typography>

              <Typography variant={'subtitle1'} className={commonClasses.textContent}>
                {data.upperArm + ' ' + handleTextForMeasurementUnits(data.lengthUnit)}
              </Typography>
            </div>

            <div className={classes.bodyPartText}>
              <Typography variant={'subtitle1'} className={classes.textLabel}>
                {t('LogsPage.bodyMeasurementWidget.chest')}
              </Typography>

              <Typography variant={'subtitle1'} className={commonClasses.textContent}>
                {data.chest + ' ' + handleTextForMeasurementUnits(data.lengthUnit)}
              </Typography>
            </div>

            <div className={classes.bodyPartText}>
              <Typography variant={'subtitle1'} className={classes.textLabel}>
                {t('LogsPage.bodyMeasurementWidget.waist')}
              </Typography>

              <Typography variant={'subtitle1'} className={commonClasses.textContent}>
                {data.waist + ' ' + handleTextForMeasurementUnits(data.lengthUnit)}
              </Typography>
            </div>

            <div className={classes.bodyPartText}>
              <Typography variant={'subtitle1'} className={classes.textLabel}>
                {t('LogsPage.bodyMeasurementWidget.hips')}
              </Typography>

              <Typography variant={'subtitle1'} className={commonClasses.textContent}>
                {data.hips + ' ' + handleTextForMeasurementUnits(data.lengthUnit)}
              </Typography>
            </div>

            <div className={classes.bodyPartText}>
              <Typography variant={'subtitle1'} className={classes.textLabel}>
                {t('LogsPage.bodyMeasurementWidget.thighs')}
              </Typography>

              <Typography variant={'subtitle1'} className={commonClasses.textContent}>
                {data.thighs + ' ' + handleTextForMeasurementUnits(data.lengthUnit)}
              </Typography>
            </div>
          </div>
        </div>
        {!isHistoryWidget && (
          <div className={commonClasses.buttonsContainer}>
            <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
          </div>
        )}
      </div>
    </div>
  );
};
export default BodyMeasurementRecentlyAddedRow;
