import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {isMobileOnly, isTablet} from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Typography, useMediaQuery} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {RecipeCard} from './RecipeCard';
import {ContentDialog} from '../dialog/ContentDialog';
import {ReactComponent as BackIcon} from '../../../assets/icons/rest/left-arrow.svg';
import {RecipeDetailsView} from './RecipeDetailsView';
import {
  DEFAULT_PAGE_START,
  DEFAULT_PER_PAGE_SIZE,
  GeneralPaths,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';

import {containsParamInHistory, getHistoryParams} from '../../../utils/qs-helper';

import Loader from '../loader/Loader';

import useStyles from './recipes-view.styles';
import {EngagementPointType, NourishType} from '../../../utils/enums';
import {addEngagementPointDatabase} from '../../../api/engagement.api';
import {RecipesFilter} from '../../../pages/general/nourish-page/RecipesFilter';
import {Button} from '../button/Button';

interface Props {
  recipes: any[];
  page: number;
  count: number;
  onChangePage: (event: any, page: number) => void;
  onRecipesFilterChange: any;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const RecipesView: React.FC<Props> = ({
  recipes,
  onChangePage,
  count,
  page,
  onRecipesFilterChange,
}) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE - 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);

  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;

  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const history = useHistory();
  const [slug, setSlug] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [pageScroll, setPageScroll] = useState<number>(0);
  const [allRecipes, setAllRecipes] = useState<any>([]);
  const [t] = useTranslation();
  const totalPagesCount = Math.floor((count + DEFAULT_PER_PAGE_SIZE - 1) / DEFAULT_PER_PAGE_SIZE);

  useEffect(() => {
    if (containsParamInHistory(history, '_slug')) {
      const params = getHistoryParams(history);
      if (params?._slug && params['type-slug'] === 'recipes-mode') {
        addEngagementPointDatabase({
          eventType: EngagementPointType.RECIPE_READ,
          description: params?._slug || '',
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOnCardClick(params?._slug, true);
    }
  }, []);

  const handleOnCardClick = (slug: string, deepLinking = false) => {
    try {
      setSlug(slug!);
      setOpen(true);
      if (slug.trim() === '') {
        console.error('No recipe found. Slug: ', slug);
      } else if (!deepLinking) {
        history.replace({
          pathname: GeneralPaths.NourishPage,
          search: `?type-slug=${NourishType.RECIPES_MODE.toString()}&_slug=${slug}`,
        });
      }  else {
        console.log('Deep linking to recipe', slug);
        return;
      }
    } catch (error) {
      console.error(`${error}, No recipe found. Slug: ${slug}`);
    }
  };

  const handleDismiss = () => {
    setOpen(false);
    setSlug('');
    history.replace({
      pathname: GeneralPaths.NourishPage,
      search: ``,
    });
  };

  const renderRecipes = (array: any[]) =>
    array.map((recipe: any) => (
      <RecipeCard
        recipe={recipe}
        key={recipe.id}
        onClick={handleOnCardClick}
        isMobileOrTablet={isMobileOrTablet}
        smallScreens={smallScreens}
      />
    ));

  useEffect(() => {
    setAllRecipes([]);
    setPageScroll(0);
  }, []);

  useEffect(() => {
    if (smallScreens) {
      setAllRecipes(allRecipes.concat(recipes));
    }
  }, [recipes]);
  useEffect(() => {
    if (smallScreens && recipes?.length === 0) {
      const handleBackButtonPress = () => {
        window.location.reload();
      };

      // Attach the event listener to the popstate event
      window.addEventListener('popstate', handleBackButtonPress);

      // Cleanup the event listener when the component is unmounted
      return () => {
        window.removeEventListener('popstate', handleBackButtonPress);
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {
        ('');
      };
    }
  }, []);

  const fetchMoreData = () => {
    onChangePage('', pageScroll);
    setPageScroll(pageScroll + 1);
  };

  return (
    <div id={'scrollableDiv'} className={classes.mainContainer}>
      {recipes?.length ? (
        <>
          <Typography variant={'h6'} align="left">
            {t('NourishPage.recipes.title')}
          </Typography>

          {smallScreens ? (
            <>
              <RecipesFilter onFilterChange={(filters: any) => onRecipesFilterChange(filters)} />
              <InfiniteScroll
                className={classes.recipesList}
                dataLength={allRecipes?.length}
                height={'90vh'}
                pullDownToRefreshThreshold={50}
                next={fetchMoreData}
                hasMore={!(totalPagesCount === pageScroll)}
                loader={<Loader />}
                scrollableTarget="scrollableDiv"
              >
                {renderRecipes(allRecipes)}
              </InfiniteScroll>
            </>
          ) : (
            <div className={classes.recipesList}>{renderRecipes(recipes)}</div>
          )}

          {!smallScreens && (
            <div className={classes.paginationContainer}>
              <Pagination
                count={totalPagesCount}
                className={classes.pagination}
                defaultPage={DEFAULT_PAGE_START}
                onChange={onChangePage}
                page={!page ? DEFAULT_PAGE_START : page}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <Button
            className={classes.back}
            id="back"
            variant="text"
            onClick={() => window.location.reload()}
          >
            <BackIcon title="Back to recipes" />
            <Typography style={{marginLeft: 10}} variant={'subtitle1'}>
              {t('NourishPage.recipes.title')}
            </Typography>
          </Button>
          <Typography variant="subtitle1">{t('NourishPage.recipesFilter.noResult')}</Typography>
        </>
      )}
      <ContentDialog
        fullScreen
        slideUp
        handleDismiss={handleDismiss}
        dialogState={open}
        closeIconClass={classes.closeIconRecipesClass}
        isCloseButtonHidden={smallScreens}
        // className={classes.dialogMargin}
        // closeIconClass={classes.dialogClose}
        content={
          <RecipeDetailsView
            slug={slug!}
            isMobileOrTablet={isMobileOrTablet}
            smallScreens={smallScreens}
            handleDismiss={handleDismiss}
          />
        }
      />
    </div>
  );
};
