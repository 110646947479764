export enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum RoleType {
  MEMBER = 'Member',
  TRAINER = 'Trainer',
  ADMIN = 'Admin',
  SUPER_COACH = 'Super Coach',
}

export enum RoleTypeDisplay {
  COACH = 'Coach',
  HEAD_COACH = 'Head Coach',
  MEMBER = 'Member',
}

export enum Pages {
  NOURISH_PAGE = 'Nourish Page',
}

export enum NavBarSelectedType {
  NAVBAR_NONE = 'None',
  NAVBAR_HOME = 'My Insights',
  NAVBAR_COMMUNITY = 'Community',
  NAVBAR_PROFILE = 'Profile',
  NAVBAR_PLUS = '',
  NAVBAR_NOURISH = 'Nourishment',
  NAVBAR_MARKET = 'Market',
  NAVBAR_BETR_PROGRAM = 'Betr Program',
}

export enum MessageDirection {
  MESSAGE_INCOMING,
  MESSAGE_OUTGOING,
}

export enum SideMenuSelectedType {
  SIDEMENU_NONE = 'None',
  SIDEMENU_CONNECTIONS = 'Connections',
  SIDEMENU_APPOINTMENTS = 'Appointments',
  SIDEMENU_MEDITATION = 'Meditation',
  SIDEMENU_EXERCISE = 'Exercise',
  SIDEMENU_PROGRESS = 'Progress',
  SIDEMENU_INSIGHTS = 'Insights',
  SIDEMENU_NOURISHMENT = 'Nourishment',
  SIDEMENU_MEALPLANS = 'Meal Plans',
  SIDEMENU_RECIPES = 'Recipes',
  SIDEMENU_SEARCH_MEMBERS = 'Search Members',
  SIDEMENU_ACTIVE_CONVERSATIONS = 'Active Conversations',
  SIDEMENU_COACHING_HISTORY = 'Coaching History',
  SIDEMENU_BETR_PROGRAM = 'Betr Program',
  SIDEMENU_FOOD_LISTS = 'Resources',
  SIDEMENU_MASTER_CLASSES = 'Master Classes',
  SIDEMENU_MOVEMENT = 'Movement',
  SIDEMENU_MINDFULLNESS ='Mindfullness',
  SIDEMENU_PODCAST = 'PODCAST'
}

export enum SideMenuLoggingSelectedType {
  SIDE_MENU_NONE = 'None',
  SIDE_MENU_BODY_WEIGHT = 'Body Weight',
  SIDE_MENU_FOOD = 'Food',
  SIDE_MENU_ACTIVITY = 'Activity',
  SIDE_MENU_MENTAL_HEALTH = 'Mental Health',
  SIDE_MENU_WATER = 'Water',
  SIDE_MENU_SLEEP = 'Sleep',
  SIDE_MENU_BOWEL_MOVEMENT = 'Bowel Movement',
  SIDE_MENU_STEPS = 'Steps',
  SIDE_MENU_BLOOD_PRESSURE = 'Blood Pressure',
  SIDE_MENU_BLOOD_GLUCOSE = 'Blood Glucose',
  SIDE_MENU_BODY_MEASUREMENTS = 'Body Measurements',
  SIDE_MENU_BLOOD_LABS = 'Blood Labs',
  SIDE_MENU_BODY_PAIN = 'Body Pain',
}

export enum LoggedWidgetType {
  BODY_WEIGHT = 'Log Weight',
  FOOD = 'Log Food',
  ACTIVITY = 'Log Activity',
  STRESS = 'Log Stress',
  WATER = 'Log Drink',
  SLEEP = 'Log Sleep',
  BOWEL_MOVEMENT = 'Log Bowel Movement',
  STEPS = 'Log Step',
  BLOOD_PRESSURE = 'Log Blood Pressure',
  BLOOD_GLUCOSE = 'Log Blood Glucose',
  BODY_MEASUREMENTS = 'Log Body Measurement',
  BLOOD_LABS = 'Blood Labs',
  A1C = 'Log A1C',
  CHOLESTEROL = 'LogCholesterol',
  BODY_PAIN = 'Log Body Pain',
}

export enum ReminderType {
  APPOINTMENT = 'appointment',
  FRIENDLY = 'friendly',
  SUBSCRIPTION = 'subscription',
}
export enum FriendlyReminderType {
  BODY_WEIGHT = 'LogWeight',
  FOOD = 'LogFood',
  GLOBAL = 'Global',
}

export enum WidgetType {
  NONE_MODE,
  FORM_MODE,
}

export enum FormType {
  NONE_MODE,
  ADDING_MODE,
  EDITING_MODE,
}

export enum EllipsisType {
  SHOW_ELLIPSIS,
  SHOW_OPTIONS,
}

export enum BodyPartType {
  NONE,
  UPPER_ARM,
  CHEST,
  WAIST,
  HIPS,
  THIGHS,
}

export enum isTagVisible {
  VISIBLE_DISABLED = 'VISIBLE_DISABLED',
  VISIBLE_ENABLED = 'VISIBLE_ENABLED',
  NOT_VISIBLE = 'NOT_VISIBLE',
}

export enum ProgressType {
  BODY_WEIGHT = 'Weight',
  FOOD = 'Food',
  WATER = 'Water',
  SLEEP = 'Sleep',
  ACTIVITY = 'Activity',
  STEPS = 'Steps',
  BOWEL_MOVEMENT = 'Bowel Movement',
  MENTAL_HEALTH = 'Mental Health',
  BLOOD_GLUCOSE = 'Blood Glucose',
}

export enum IntervalType {
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
  CUSTOM = 'Custom',
}

export enum TagsWidgetType {
  PREFERENCES = 'preferences',
  GOALS = 'goals',
}


export enum AppointmentType {
  INTRO = 'Intro',
  LUNCH = 'Lunch',
  KICK_OFF = 'KickOff',
  LIVE = 'Live',
  Level1 = "Level1",
  Level2 = "Level2",
  Week1 = "Week1",
  LiveAndTNC = "Live and TNC"
}

export enum AchievementsType {
  WEIGHT = 'LogWeight',
  COMMUNITY = 'Community',
  ENGAGEMENT = 'Engagement',
  REFERRAL = 'Referral',
  COACHING = 'Coaching',
  FITNESS = 'Fitness',
}

export enum NourishType {
  NOURISHMENT_MODE = 'nourishment-mode',
  RECIPES_MODE = 'recipes-mode',
  MEAL_PLANS_MODE = 'meal-plans-mode',
  BETR_PROGRAM_MODE = 'betr-program-mode',
  FOOD_LISTS_MODE = 'food-lists-mode',
  MASTER_CLASSES_MODE = 'master-classes-mode',
  MOVEMENT_MODE = 'movement-mode',
  Mindfulness_Mode = 'mindfulness-mode',
  PODCAST_MODE ='Podcast-Mode'
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export enum Unit {
  IMPERIAL = 'Imperial (American)',
  METRIC = 'Metric (Other Countries)',
}

export enum MobileOSType {
  ANDROID = 'android',
  IOS = 'ios',
  UNKNOWN = '',
}

export enum MealPlanComponentType {
  SHARED_COMPONENT = 'shared-components.card',
  MEAL_PLAN_COMPONENT = 'meal-plan-component.daily-meals',
}

export enum MealPlanAvisoryComponentType {
  ACTIVITY = 'High Activity Day',
}

export enum MealPlanSharedComponentType {
  CARD = 'Card',
  ADVISORY = 'Advisory',
}

export enum RatingType {
  BETR = 'Betr',
  USER = 'User',
}

export enum EngagementPointType {
  COMMUNITY = 'Community',
  LOGIN = 'LogIn',
  LOGOUT = 'LogOut',
  NOURISH = 'Nourish',
  RECIPE_READ = 'Recipe Read',
  ARTICLES_READ = 'Articles Read',
  INSIGHTS = 'Insights',
  VIDEO_WATCHED='Video Watched'
}

export enum MemberGroupsType {
  COACHES = 'Coaches',
  MEMBERS = 'Members',
  NO_COACHING = 'Members without coaching',
}

export enum SubscriptionItemType {
  MEMBERSHIP = 'Membership',
  COACHING = 'Coaching',
}

export enum EmoticonType {
  LIKE = 'Like',
  HEART = 'heart',
  HAPPY = 'happy',
  SURPRISED = 'surprised',
  SAD = 'sad',
  ANGRY = 'angry',
}

export enum EmojiGiphyTab {
  EMOJI,
  GIPHY,
}

export enum OnBoardingSteps {
  WELCOME,
  PROFILE_INFO,
  PASSWORD,
  UNIT_SYSTEM,
  HEIGHT_WEIGHT,
  BOOK_INTRO_CALL,
}

export enum OnBoardingScheduleType {
  SCHEDULE_CALL,
  WATCH_VIDEO,
  FINISH,
}

export enum OnBoardingTypes {
  WALGREENS = '1',
  SOLERA = '2',
  WELLVOLUTION = '3',
  CONNECT = '4',
}

export enum LoggingWidgetType {
  BODY_WEIGHT_WIDGET,
  FOOD_WIDGET,
  ACTIVITY_WIDGET,
  MENTAL_HEALTH_WIDGET,
  WATER_WIDGET,
  SLEEP_WIDGET,
  BOWEL_WIDGET,
  STEPS_WIDGET,
  BLOOD_PRESSURE_WIDGET,
  BODY_MEASUREMENT_WIDGET,
  BLOOD_GLUCOSE_WIDGET,
  BLOOD_LABS_WIDGET,
  BODY_PAIN_WIDGET,
}

export enum FormItemType {
  TEXT,
}

export enum MealTime {
  FASTING = 'Fasting',
  BEFORE_MEAL = 'Before Meal',
  AFTER_MEAL_ONE = '1 hour after a meal',
  AFTER_MEAL_TWO = '2 hours after a meal',
}

export enum SelectPanelType {
  CHOLESTEROL = 'Cholesterol',
  A1C = 'A1C',
}

export enum BetrProgramType {
  PREP = 'Prep',
  LEVEL1 = 'Level 1',
  LEVEL2 = 'Level 2',
  FAQ = "FAQ's",
  BLOG= "Blog",
}
