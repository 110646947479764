/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {addLeadingZeros} from '../../../utils/StringHelpers';
import {getHoursAndMinutesFromMinutes} from '../../../utils/helpers';
import {ProgressType} from '../../../utils/enums';
import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Loader from '../loader/Loader';
import useStyles from './progress-chart.styles';
import {MoodLevelText} from './MoodLevelText';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {selectMember} from '../../../store/selectors/homepage.selectors';

interface Props {
  type: ProgressType;
  fromDate: Date;
  toDate: Date;
  logs: any;
  isSelected: boolean;
}

export const AverageValue: React.FC<Props> = ({type, fromDate, toDate, logs, isSelected}) => {
  const duration = Math.abs(differenceInCalendarDays(toDate, fromDate));
  const [isLoading, setIsLoading] = useState(true);
  const [averageValue, setAverageValue] = useState<any>(null);
  const classes = useStyles();
  const [t] = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const selectedMember = useSelector(selectMember);

  const getSumOfValues = (): number => {
    let sum = 0;
    logs?.forEach((item: any) => {
      if (type === ProgressType.MENTAL_HEALTH) {
        sum = sum + item.moodLevelValue;
      } else if (type === ProgressType.ACTIVITY) {
        sum = sum + item.duration;
      } else {
        sum = sum + item.value;
      }
    });
    return sum;
  };

  const returnAverageMood = (): any => {
    const average = Math.round(getSumOfValues() / logs?.length);
    return <MoodLevelText value={average} average={true} />;
  };

  const calculateSleepAverage = (): string => {
    let sum = 0;

    logs?.forEach((item: any) => {
      const padded = addLeadingZeros(item?.duration);
      const hours = parseInt(padded.substr(0, 2)) * 60;
      const minutes = parseInt(padded.substr(2, 4));
      const duration = hours + minutes;
      sum = sum + duration;
    });
    const average = sum / logs?.length;
    return getHoursAndMinutesFromMinutes(average);
  };

  const calculateBodyWeightAverage = () => {
    const unit = selectedMember?.measurementUnit.massUnit || currentUser?.measurementUnit.massUnit;
    if (logs?.length === 1) return `${logs[0]?.value} ${unit}`;

    const value: number = parseFloat((getSumOfValues() / logs?.length).toFixed(2));
    if (value % 1 != 0) return `${value} ${logs[0].massUnit}`;
    return `${Math.floor(value)} ${logs[0].massUnit}`;
  };

  const calculateAverage = (): string | number => {
    let returnValue = t('Homepage.insights.table.noDataLabel');
    if (logs?.length > 0) {
      switch (type) {
        case ProgressType.BODY_WEIGHT:
          returnValue = calculateBodyWeightAverage();
          break;
        case ProgressType.FOOD:
          returnValue = `${(logs?.length / duration).toFixed(2)} meals`;
          break;
        case ProgressType.WATER:
          returnValue = `${(getSumOfValues() / logs?.length).toFixed(2)} Oz`;
          break;
        case ProgressType.SLEEP:
          returnValue = calculateSleepAverage();
          break;
        case ProgressType.ACTIVITY:
          returnValue = `${(getSumOfValues() / logs?.length).toFixed(2)} mins`;
          break;
        case ProgressType.STEPS:
          returnValue = (getSumOfValues() / logs?.length).toFixed(2);
          break;
        case ProgressType.BOWEL_MOVEMENT:
          returnValue = `${(logs?.length / duration).toFixed(2)} entries`;
          break;
        case ProgressType.MENTAL_HEALTH:
          returnValue = returnAverageMood();
          break;
        case ProgressType.BLOOD_GLUCOSE:
          returnValue = `${(getSumOfValues() / logs?.length).toFixed(2)} ${
            logs[0].bloodGlucoseUnit
          }`;
          break;
        default:
          returnValue = t('Homepage.insights.table.noDataLabel');
      }
    }
    setIsLoading(false);
    return returnValue;
  };

  useEffect(() => {
    if (isSelected && logs) {
      const res = calculateAverage();
      setAverageValue(res);
    }
  }, [logs, type, logs?.length]);

  return isLoading ? (
    <Loader className={classes.averageLoader} size={24} />
  ) : (
    <Typography variant={'h6'}>
      {averageValue || t('Homepage.insights.table.noDataLabel')}
    </Typography>
  );
};
