import React from 'react';
import {useDispatch} from 'react-redux';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import * as bloodGlucoseLogingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';

import useStyles from './blood-glucose-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface BloodGlucoseRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
}

const BloodGlucoseRecentlyAddedRow: React.FC<BloodGlucoseRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(bloodGlucoseLogingActions.deleteBloodGlucoseLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };
  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        <div className={!isMobile ? commonClasses.infoContainer : ''}>
          <DateTimeString date={data.date} isMobile={isMobile} />
          <div className={classes.valuesContent}>
            <div className={classes.valuesText}>
              <Typography variant={'subtitle1'}>
                {data.value + ' ' + data.bloodGlucoseUnit}
              </Typography>
            </div>
          </div>
          <div>
            <Typography variant={'body1'}>{data.mealTime}</Typography>
          </div>
        </div>

        <div className={commonClasses.buttonsContainer}>
          <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
        </div>
      </div>
    </div>
  );
};
export default BloodGlucoseRecentlyAddedRow;
