import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  lockCard: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(45, 45, 46, 0.6)',
    cursor: 'default',
    '& img': {
      margin: 15
    }
  },
  cardArea:{
    '& .MuiCardMedia-img': {
      objectPosition: 'top'
    },
      display:"flex",      
    justifyContent: "space-between",
    margin:"24px 16px 0 0 ", 
    flexWrap:"wrap",    
    
     '& .CardSection':{
    width:"50%",  
    padding:"0 24px 24px 0",
    borderRadius:"12px",
    '&:nth-child(even)':{
      paddingRight:"0"
    }
    },
    '& .card':{
      borderRadius:"12px",
    },
    '& .cardheading':{
      fontSize:"16px",
      fontWeight: 600,
      lineHeight: "20px",
      marginBottom:"4px",
    },

    '& .cardContent':{
      padding:"12px 16px 16px 16px",    
    },

    '& .cardContentFlex':{
      display:"flex",      
      justifyContent: "space-between",
    },

    '& .cardContentRight':{
      display:"flex",      
      justifyContent: "space-between",
    },

    '& .adherenceScore':{
      marginRight:16
    },
    '& .cardActive':{
      position:"relative",     
      borderRadius:"12px",
      width:"100%",
      '& .hoverContent':{
        display:"none"
      },
      '&:hover .hoverContent':{
     display:"flex"
      },
      
      '& .cardMedia':{
        opacity:.2,
        },
   },
   '& .hoverCardMedia':{
    zIndex:"111",
    background:" #5fb882c9",
  },
 
  '& .hoverContent':{
    background:"rgba(0, 0, 0, 0.7)",
    position:"absolute",
    width:"100%",
    height:"148px",
    zIndex:"999",
    top:0,
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  '& .repeatbtn':{
    background: "radial-gradient(83.32% 398% at 41.13% -206%, #BAF5F5 0%, #9DC6F5 64.23%, #A6B6F5 100%)",
    width: "109px",
    height: "48px",
    padding: "12px 24px",
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "1.25px",
    textDecoration:"none",
    borderRadius:"50px",
    color:"#001329",
    lineHeight:"30px",
    display:"table",
    margin:"0 auto 12px",
    
  },
  '& .viewtbtn':{
  
    width: "180px",
    height: "48px",
    padding: "12px 24px",
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "1.25px",
    textDecoration:"none",
    borderRadius:"50px",
    color:"#fff",
    lineHeight:"30px",
    border:"1px solid #fff",
    display:"table",
    margin:"0 auto",
    
  },

  ['@media (max-width:1240px)']: {

    margin:"24px 0 0 0 ",    

    '& .hoverContent':{
      height:"188px"
    },   

    '& .cardMedia':{
      height:"188px"
    },
  },
  ['@media (max-width:980px)']: {

    margin:"24px 0 0 0 ",    

    '& .hoverContent':{
      height:"155px"
    },   

    '& .cardMedia':{
      height:"155px"
    },
  },
  ['@media (max-width:767px)']: {

    margin:"16px 0 0 0 ", 

    '& .CardSection':{
      padding:"0 0 16px 0",
      width:"100%",
    },   

    '& .hoverContent':{
      height:"165px"
    },   

    '& .cardMedia':{
      height:"165px"
    },

  
  },
  
  

  },
 
  

    

    
   
      







  });

  export default useStyles;