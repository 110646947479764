import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useCommonStyles = makeStyles<Theme, Props>(() => ({
  normalTextField: {
    width: (props) => (props.isMobile ? '100%' : '177px'),
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTextImageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageMargin: {
    marginRight: 12,
  },
  formRowContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: (props) => (props.isMobile ? '24px' : '16px'),
    marginBottom: '16px',
    flexWrap: (props) => (props.isMobile ? 'initial' : 'wrap'),
    '& > div': {
      width: (props) => (props.isMobile ? '100%' : ''),
      marginRight: (props) => (props.isMobile ? 0 : '24px'),
    },
    '& > div:nth-child(odd)': {
      paddingRight: (props) => (props.isMobile ? 8 : 0),
    },
    '& > div:nth-child(even)': {
      paddingLeft: (props) => (props.isMobile ? 8 : 0),
    },
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      width: (props) => (props.isMobile ? '50%' : ''),
      marginRight: (props) => (props.isMobile ? 0 : 24),
    },
    '& > div:nth-child(odd)': {
      paddingRight: (props) => (props.isMobile ? 8 : 0),
    },
    '& > div:nth-child(even)': {
      paddingLeft: (props) => (props.isMobile ? 8 : 0),
    },
  },
  datePicker: {
    width: (props) => (props.isMobile ? '100%' : 177),
    marginRight: (props) => (props.isMobile ? 0 : 24),
  },
  timePicker: {
    maxWidth: 177,
  },
  buttonRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: (props) => (props.isMobile ? '40px' : '16px'),
    '& button': {
      width: (props) => (props.isMobile ? '100%' : 'auto'),
      height: (props) => (props.isMobile ? '48px' : '35px'),
    },
  },
  outlinedBtn: {
    height: (props) => (props.isMobile ? '48px' : '36px !important'),
    marginRight: 16,
  },
}));

export default useCommonStyles;
