import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {validateUserSession} from '../../../store/actions/users/users-action';
import {IUser} from '../../../models/IUser';
import {usePermission} from '../../hooks/use-permission';

interface IAuthWrapperProps {
  children: React.ReactElement;
}

const AuthWrapper: React.FC<IAuthWrapperProps> = (props) => {
  const dispatch = useDispatch();
  const permissionMiddleware = usePermission();
  const [loading, setLoading] = useState<boolean>(true);
  const [init, setInit] = useState(false);
  const currentUser: IUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!currentUser) {
      dispatch(validateUserSession(setLoading));
      setInit(true);
    }
    if (init) {
      permissionMiddleware.applyTo(currentUser);
    }
  }, [currentUser, dispatch, init]);

  return !loading && permissionMiddleware.allowed ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : null;
};

export default AuthWrapper;
