import * as React from 'react';
import {Card, CardContent, CardMedia, Typography} from '@material-ui/core';
import {LevelFlag} from '../level-flag/LevelFlag';
// import StarIcon from '../../../assets/icons/rest/star.svg';
import PlaceholderImage from '../../../assets/icons/placeholders/placeholder-cooking.svg';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

import useStyles from './recipe-card.styles';

interface Props {
  recipe: any;
  onClick: (slug: string) => void;
  containerStyle?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

export const RecipeCard: React.FC<Props> = ({
  recipe,
  onClick,
  containerStyle,
  isMobileOrTablet,
  smallScreens,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });

  return (
    <Card
      className={`${classes.card} ${containerStyle}`}
      onClick={() => onClick(recipe.slug)}
      id={recipe.id}
    >
      <CardMedia
        component="img"
        height={isMobileOrTablet.isMobile ? '72' : '148'}
        image={recipe.featuredImage?.image.url || PlaceholderImage}
        alt="recipe-image"
      />
      <LevelFlag
        title={recipe?.level_category?.name}
        levelCategory={recipe?.level_category}
        top={8}
        right={8}
      />
      <div className={classes.infoContainer}>
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {recipe.title}
          </Typography>
        </CardContent>
        <div className={classes.ratingContainer}>
          <div className={classes.rating}>
            {/*<img src={StarIcon} alt={'rating-icon'} width={16} />*/}
            {/*<div className={classes.ratingValue}>{recipe.rating || 0}</div>*/}
          </div>
          <div className={classes.showTime}>
            <div className={classes.ratingValue}>
              {isMobileOrTablet.isMobile
                ? recipe.totalTime
                : recipe.totalTime?.substring(0, recipe.totalTime?.length - 4)}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
