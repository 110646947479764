import React, {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';
import Loader from '../loader/Loader';
import CloseIcon from '../../../assets/icons/rest/close-icon-white.svg';

import useStyles from './image-upload-styles';

interface IImageProps {
  uploadEnabled: boolean;
  name?: string;
  changeData?: (args: any) => void;
  imageUrl?: any;
  className?: string;
  uploadIcon: string;
  canEdit: boolean;
  isMobile: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const ImageUpload: React.FC<IImageProps> = (props) => {
  const classes = useStyles({
    uploadEnabled: props.uploadEnabled,
    isMobile: props.isMobile,
  });
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [t] = useTranslation();

  const changeImageHandler = (event: any) => {
    setIsLoading(true);
    if (event.target?.files[0]) {
      props.changeData && props.changeData(event.target.files[0]);
      setIsLoading(false);
    } else {
      props.changeData && props.changeData(props.imageUrl);
      setIsLoading(false);
    }
  };

  const clearImage = () => {
    props.changeData && props.changeData(null);
  };

  const handleSelectClick = () => {
    if (props.uploadEnabled) {
      inputRef.current?.click();
    } else return;
  };

  const handleCanEdit = () => {
    if (!props.canEdit) {
      return (
        <div
          className={classes.closeIcon}
          onClick={clearImage}
          role={'button'}
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <img src={CloseIcon} width={16} height={props.isMobile ? "auto" : 16} alt={'discard-img-icon'} />
        </div>
      );
    }

    return (
      <div onClick={handleSelectClick} role={'button'} onKeyDown={() => {}} tabIndex={0}>
        <input
          type="file"
          className={classes.hideInputCLass}
          ref={inputRef}
          onChange={changeImageHandler}
        />
        <Typography variant={'button'} className={classes.editTxt}>
          {t('Homepage.quickProfileUser.editButton')}
        </Typography>
      </div>
    );
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {props.imageUrl != '' && props.imageUrl ? (
            <div className={classes.selectedImgContainerMobile}>
              {props.isMobile && (
                <div
                  onClick={handleSelectClick}
                  className={classNames(classes.selectImg, props.className)}
                  role={'button'}
                  onKeyDown={() => {}}
                  tabIndex={0}
                >
                  <input
                    type="file"
                    className={classes.hideInputCLass}
                    ref={inputRef}
                    onChange={changeImageHandler}
                  />
                  <img
                    className={classes.defaultImage}
                    src={props.uploadIcon}
                    alt="file-upload-icon"
                    width={props.isMobile ? 60 : 40}
                    height={props.isMobile ? "auto" : 40}
                  />
                </div>
              )}
              <div className={classNames(classes.selectedImgContainer, props.className)}>
                <img
                  src={
                    typeof props.imageUrl === 'string'
                      ? props.imageUrl
                      : URL.createObjectURL(props.imageUrl)
                  }
                  className={props.className}
                  alt="uploaded-img"
                />
                {props.uploadEnabled && handleCanEdit()}
              </div>
            </div>
          ) : (
            <div
              onClick={handleSelectClick}
              className={classNames(classes.selectImg, props.className)}
              role={'button'}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <input
                type="file"
                className={classes.hideInputCLass}
                ref={inputRef}
                onChange={changeImageHandler}
              />
              <img
                className={classes.defaultImage}
                src={props.uploadIcon}
                alt="file-upload-icon"
                width={props.isMobile ? 60 : 40}
                height={props.isMobile ? "auto" : 40}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ImageUpload;
