import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  optionsItemImage: {
    userSelect: 'none',
    cursor: 'pointer',
    width: 24,
    height: 24,
    marginLeft: 12,
    marginRight: 12,

    '&:hover': {
      opacity: 0.6,
    },
  },
  optionsContent: {
    position: 'relative',
  },
}));

export default useStyles;
