import {ValidationType} from './ValidationType';
import {
  compareDates,
  isDate,
  isDecimalNumber,
  isEmail,
  isGreater,
  isIntNumber,
  isPhoneNumber,
  isRequired,
  isRequiredDuration,
  isStrongPassword,
} from './DataValidation';
import i18n from 'i18next';

export interface IValidationRule {
  type: ValidationType;
  value?: string;
  minValue?: number;
  maxValue?: number;
  errorMessage?: string;
  skip?: boolean;
  allowedEmptyString?: boolean;
}

export interface IValidationRules {
  [key: string]: IValidationRule[];
}

export interface IError {
  message: string;
}

export interface IErrors {
  [key: string]: IError[];
}

export const hasError = (errors: IErrors, type: string): boolean => {
  return errors && errors[type] && errors[type]?.length > 0;
};

export const getError = (errors: IErrors, type: string): string => {
  return hasError(errors, type) ? errors[type][0].message : '';
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const validateField = (
  formData: any,
  validationRulesList: IValidationRules,
  field: string,
): IError[] => {
  const fieldErrors: IError[] = [];

  if (!validationRulesList[field]) {
    return [];
  }

  validationRulesList[field].forEach((validationItem: IValidationRule) => {
    if (validationItem.type === ValidationType.REQUIRED) {
      if (!isRequired(formData[field])) {
        fieldErrors.push({
          message: validationItem.errorMessage || i18n.t('ErrorMessages.required'),
        });
      }
    } else if (validationItem.type === ValidationType.REQUIRED_DURATION) {
      if (!isRequiredDuration(formData[field])) {
        fieldErrors.push({
          message: validationItem.errorMessage || i18n.t('ErrorMessages.required'),
        });
      }
    } else if (validationItem.type === ValidationType.IS_INT_NUMBER) {
      if (!isIntNumber(formData[field], validationItem.minValue, validationItem.maxValue)) {
        fieldErrors.push({
          message:
            validationItem.errorMessage ||
            (validationItem.maxValue
              ? `${i18n.t('ErrorMessages.notPositiveIntValue')} ${validationItem.maxValue}`
              : i18n.t('ErrorMessages.intGreaterThanZero')),
        });
      }
    } else if (validationItem.type === ValidationType.IS_GREATER) {
      if (!isGreater(formData[field], formData[validationItem.value || ''])) {
        fieldErrors.push({
          message: validationItem.errorMessage || i18n.t('ErrorMessages.notGreaterThan'),
        });
      }
    } else if (validationItem.type === ValidationType.IS_DECIMAL_NUMBER) {
      if (
        !isDecimalNumber(
          formData[field],
          validationItem.minValue,
          validationItem.maxValue,
          validationItem.value,
          validationItem.allowedEmptyString,
        )
      ) {
        fieldErrors.push({
          message:
            validationItem.errorMessage ||
            `${i18n.t('ErrorMessages.notInRangeValue')} ${validationItem.minValue}-${
              validationItem.maxValue
            }`,
        });
      }
    } else if (validationItem.type === ValidationType.BIRTH_DATE) {
      if (!isDate(formData[field])) {
        fieldErrors.push({
          message: validationItem.errorMessage || i18n.t('ErrorMessages.notValidDate'),
        });
      }
    } else if (validationItem.type === ValidationType.COMPARE_DATES) {
      if (
        !compareDates(formData[field], formData[validationItem.value || ''], validationItem.skip)
      ) {
        fieldErrors.push({
          message: 'End date is before start date.',
        });
      }
    } else if (validationItem.type === ValidationType.IS_EMAIL) {
      if (!isEmail(formData[field])) {
        fieldErrors.push({
          message: validationItem.errorMessage || i18n.t('ErrorMessages.notValidEmail'),
        });
      }
    } else if (validationItem.type === ValidationType.IS_PHONE_NUMBER) {
      if (!isPhoneNumber(formData[field])) {
        fieldErrors.push({
          message: validationItem.errorMessage || i18n.t('ErrorMessages.notValidPhoneNumber'),
        });
      }
    } else if (validationItem.type === ValidationType.IS_STRONG_PASSWORD) {
      if (!isStrongPassword(formData[field])) {
        fieldErrors.push({
          message: validationItem.errorMessage || i18n.t('ErrorMessages.notStrongPassword'),
        });
      }
    }
  });

  return fieldErrors;
};

export const validate = (formData: any, validationRulesList: IValidationRules): IErrors => {
  const errors: IErrors = {};

  for (const propertyKey of Object.keys(validationRulesList)) {
    errors[propertyKey] = validateField(formData, validationRulesList, propertyKey);
  }

  return errors;
};

export const isFormValidToSubmit = (errors: IErrors): boolean => {
  for (const key in errors) {
    const error = errors[key];
    if (error?.length !== 0) {
      return false;
    }
  }

  return true;
};

// errors = {}

// validationRules = {
//   name: [{type: ValidationType.REQUIRED}],
//   name2: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_NUMBER}]
// }

// <TextField
//   name={"name"}
//   error={hasError(errors, "name")}
//   helperText={getError(errors, "name")}
//   onBlur={() => validateForm('name')}
// >

// const validateForm = (field: string) => {
//   const err = validateField(data, validationRules, field);
//   setErrors({
//     ...errors,
//     [field]: err,
//   });
// };

// const validateToSubmit = () => {
//   const err = validate(data, validationRules);
//   return isFormValidToSubmit(err);
// };

// <Button
//     disabled={!validateToSubmit()}
// >
//   Submit
// </Button>
