import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {SideMenuBarItem} from './SideMenuBarItem';
import {SideMenuLoggingSelectedType} from '../../../utils/enums';
import {ReactComponent as BodyWeightIcon} from '../../../assets/icons/side-menu-bar-logging/body-weight-icon.svg';
import {ReactComponent as FoodIcon} from '../../../assets/icons/side-menu-bar-logging/food-icon.svg';
import {ReactComponent as ActivityIcon} from '../../../assets/icons/side-menu-bar-logging/activity-icon.svg';
import {ReactComponent as StressIcon} from '../../../assets/icons/side-menu-bar-logging/stress-icon.svg';
import {ReactComponent as DrinkIcon} from '../../../assets/icons/side-menu-bar-logging/drink-icon.svg';
// import {ReactComponent as WaterIcon} from '../../../assets/icons/side-menu-bar-logging/Water Inactive.svg';
import {ReactComponent as SleepIcon} from '../../../assets/icons/side-menu-bar-logging/sleep-icon.svg';
import {ReactComponent as BowelIcon} from '../../../assets/icons/side-menu-bar-logging/bowel-icon.svg';
import {ReactComponent as StepsIcon} from '../../../assets/icons/side-menu-bar-logging/steps-icon.svg';
import {ReactComponent as BloodPressureIcon} from '../../../assets/icons/side-menu-bar-logging/blood-pressure-icon.svg';
import {ReactComponent as BodyMeasurementIcon} from '../../../assets/icons/side-menu-bar-logging/body-measurement-icon.svg';
import {ReactComponent as BodyPainIcon} from '../../../assets/icons/side-menu-bar-logging/body-pain-icon.svg';
import {ReactComponent as BloodGlucoseIcon} from '../../../assets/icons/side-menu-bar-logging/blood-glucose-icon.svg';
import {ReactComponent as BloodLabIcon} from '../../../assets/icons/side-menu-bar-logging/blood-labs-icon.svg';
import {TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './side-menu-bar-styles';

interface Props {
  executeScrollOnElement?: any;
}

export const SideMenuLoggingBar: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const smallScreens = React.useMemo(() => {
    return isMobile || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    smallScreens,
  });
  const [selectedSideMenuItem, setSideMenuItem] = useState(
    SideMenuLoggingSelectedType.SIDE_MENU_NONE,
  );
  const [t] = useTranslation();

  const selectSideMenuItem = (selectedType: SideMenuLoggingSelectedType) => {
    if (selectedType === selectedSideMenuItem) {
      return;
    }
    setSideMenuItem(selectedType);
  };

  const handleClickOnMenuItem = (elementType: SideMenuLoggingSelectedType) => {
    selectSideMenuItem(elementType);
    props.executeScrollOnElement && props.executeScrollOnElement(elementType);
  };

  return (
    <div className={classes.sideMenuBarContainer} role="menubar">
      <SideMenuBarItem
        Icon={BodyWeightIcon}
        text={t('LogsPage.sideMenuBar.bodyWeight')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_BODY_WEIGHT}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={FoodIcon}
        text={t('LogsPage.sideMenuBar.food')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_FOOD}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={ActivityIcon}
        text={t('LogsPage.sideMenuBar.activity')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_ACTIVITY}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={StressIcon}
        text={t('LogsPage.sideMenuBar.mentalHealth')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_MENTAL_HEALTH}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={DrinkIcon}
        text={t('LogsPage.sideMenuBar.water')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_WATER}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={SleepIcon}
        text={t('LogsPage.sideMenuBar.sleep')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_SLEEP}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={BowelIcon}
        text={t('LogsPage.sideMenuBar.bowelMovement')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_BOWEL_MOVEMENT}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={StepsIcon}
        text={t('LogsPage.sideMenuBar.steps')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_STEPS}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={BloodPressureIcon}
        text={t('LogsPage.sideMenuBar.bloodPressure')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_BLOOD_PRESSURE}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={BodyMeasurementIcon}
        text={t('LogsPage.sideMenuBar.bodyMeasurements')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_BODY_MEASUREMENTS}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={BloodGlucoseIcon}
        text={t('LogsPage.sideMenuBar.bloodGlucose')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_BLOOD_GLUCOSE}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={BodyPainIcon}
        text={t('LogsPage.sideMenuBar.bodyPain')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_BODY_PAIN}
        selectedSideMenuItem={selectedSideMenuItem}
      />
      <SideMenuBarItem
        Icon={BloodLabIcon}
        text={t('LogsPage.sideMenuBar.bloodLabs')}
        onClick={(elementType: any) => handleClickOnMenuItem(elementType)}
        elementType={SideMenuLoggingSelectedType.SIDE_MENU_BLOOD_LABS}
        selectedSideMenuItem={selectedSideMenuItem}
      />
    </div>
  );
};
