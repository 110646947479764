import {makeStyles,Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: boolean
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    width: '100%',
    minWidth: (props) => props.isMobileOrTablet ? '100%' : 628,
    minHeight: 80,
    background: theme.palette.background.paper,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: 12,
    marginTop: 16,
    padding: 16,
    border: `2px solid transparent`,

    '&:focus': {
      border: `2px solid ${theme.palette.uicolors.blueCrayola}`,
      outlineOffset: '0px !important',
      outline: 'none !important',
    },
  },
}));

export default useStyles;
