import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {format, subMonths, subWeeks, subYears, addMonths, addWeeks, addYears} from 'date-fns';
import {Typography} from '@material-ui/core';
import {Button} from '../button/Button';
import {DatePicker} from '../pickers/DatePicker';
import LeftIcon from '../../../assets/icons/rest/left-arrow-bold.svg';
import RightIcon from '../../../assets/icons/rest/right-arrow-bold.svg';
import {getError, hasError} from '../../../validation/Validation';
import {IntervalType} from '../../../utils/enums';
import {DATE_LONGER_FORMAT, DATE_PICKER_DATE_FORMAT} from '../../../utils/constants';

import useStyles from './progress-filter-date.styles';
import i18n from 'i18next';

interface Props {
  isMobile: boolean;
  fromDate: Date;
  setFromDate: (e: any) => void;
  toDate: Date;
  setToDate: (e: any) => void;
}

const ProgressFilterDate: React.FC<Props> = ({
  isMobile,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const classes = useStyles({
    isMobile,
  });
  const [t] = useTranslation();
  const [selected, setSelected] = useState<string>(IntervalType.WEEK);
  const [errors, setErrors] = useState<any>({});

  const handleChangeDate = (type: string) => {
    setSelected(type);
    switch (type) {
      case IntervalType.WEEK:
        setFromDate(subWeeks(toDate, 1));
        break;
      case IntervalType.MONTH:
        setFromDate(subMonths(toDate, 1));
        break;
      case IntervalType.YEAR:
        setFromDate(subYears(toDate, 1));
        break;
      default:
        setFromDate(fromDate);
    }
  };

  const handlePreviousDate = () => {
    setToDate(fromDate);
    switch (selected) {
      case IntervalType.WEEK:
        setFromDate(subWeeks(fromDate, 1));
        break;
      case IntervalType.MONTH:
        setFromDate(subMonths(fromDate, 1));
        break;
      case IntervalType.YEAR:
        setFromDate(subYears(fromDate, 1));
        break;
      default:
        setToDate(new Date());
    }
  };

  const handleNextDate = () => {
    setFromDate(toDate);
    switch (selected) {
      case IntervalType.WEEK:
        setToDate(addWeeks(toDate, 1));
        break;
      case IntervalType.MONTH:
        setToDate(addMonths(toDate, 1));
        break;
      case IntervalType.YEAR:
        setToDate(addYears(toDate, 1));
        break;
      default:
        setFromDate(new Date());
    }
  };

  const validateFormField = (value: string, field: string, setDate: (e: any) => void) => {
    const err = Date.parse(value);
    if (isNaN(err)) {
      setErrors({
        ...errors,
        [field]: [{message: i18n.t('ErrorMessages.notValidDate')}],
      });
    } else {
      setErrors({
        ...errors,
        [field]: [],
      });
      setDate(new Date(value));
    }
  };

  return (
    <div className={classes.progressFilterDate}>
      <div className={classes.buttons}>
        <Button
          id="week-id"
          variant="text"
          className={`${classes.dateButton} ${
            selected === IntervalType.WEEK ? classes.dateButtonActive : ''
          }`}
          onClick={() => handleChangeDate(IntervalType.WEEK)}
        >
          {IntervalType.WEEK}
        </Button>
        <div className={classes.line} />
        <Button
          id="month-id"
          variant="text"
          className={`${classes.dateButton} ${
            selected === IntervalType.MONTH ? classes.dateButtonActive : ''
          }`}
          onClick={() => handleChangeDate(IntervalType.MONTH)}
        >
          {IntervalType.MONTH}
        </Button>
        <div className={classes.line} />
        <Button
          id="year-id"
          variant="text"
          className={`${classes.dateButton} ${
            selected === IntervalType.YEAR ? classes.dateButtonActive : ''
          }`}
          onClick={() => handleChangeDate(IntervalType.YEAR)}
        >
          {IntervalType.YEAR}
        </Button>
        <div className={classes.line} />
        <Button
          id="custom-id"
          variant="text"
          className={`${classes.dateButton} ${
            selected === IntervalType.CUSTOM ? classes.dateButtonActive : ''
          }`}
          onClick={() => handleChangeDate(IntervalType.CUSTOM)}
        >
          {IntervalType.CUSTOM}
        </Button>
      </div>
      {selected === IntervalType.CUSTOM ? (
        <div className={classes.datePickers}>
          <DatePicker
            name={'fromDate'}
            label={t('Homepage.progress.from')}
            onChange={(value: string) => validateFormField(value, 'fromDate', setFromDate)}
            value={format(fromDate, DATE_PICKER_DATE_FORMAT)}
            required={true}
            error={hasError(errors, 'fromDate')}
            format={DATE_PICKER_DATE_FORMAT}
            helperText={getError(errors, 'fromDate')}
            className={classes.datePicker}
          />
          <DatePicker
            name={'toDate'}
            label={t('Homepage.progress.to')}
            onChange={(value: string) => validateFormField(value, 'toDate', setToDate)}
            value={format(toDate, DATE_PICKER_DATE_FORMAT)}
            required={true}
            error={hasError(errors, 'toDate')}
            format={DATE_PICKER_DATE_FORMAT}
            helperText={getError(errors, 'toDate')}
            className={classes.datePicker}
          />
        </div>
      ) : (
        <div className={classes.dateRange}>
          <Button
            id="previous"
            variant="text"
            className={classes.previousButton}
            onClick={handlePreviousDate}
          >
            <img src={LeftIcon} alt="previous" />
          </Button>
          <div>
            <Typography variant={'subtitle1'}>
              {format(fromDate, DATE_LONGER_FORMAT).slice(0, -4)} -{' '}
              {format(toDate, DATE_LONGER_FORMAT)}
            </Typography>
          </div>
          <Button id="next" variant="text" className={classes.nextButton} onClick={handleNextDate}>
            <img src={RightIcon} alt="next" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProgressFilterDate;
