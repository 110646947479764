import * as React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from 'recharts';
import {IChartData, IChartOptions} from '../../../models/IChart';
import {ProgressType} from '../../../utils/enums';
import {convertTime24to12} from '../../../utils/date-time-utils';

interface Props {
  data: IChartData[];
  options: IChartOptions;
  type: string;
  isMobile: boolean;
}

export const SimpleScatterChart: React.FC<Props> = ({data, options, type, isMobile}) => {
  const formatYAxis = (value: number): string => {
    if (type !== ProgressType.FOOD && type !== ProgressType.BOWEL_MOVEMENT) return value.toString();
    if (!value) return '';

    let stringValue = value.toString();
    if (stringValue?.length === 3) {
      stringValue = '0' + stringValue;
    }
    return convertTime24to12(stringValue);
  };

  const formatter = (value: any, name: any, props: any) => {
    props.payload = Object.keys(props.payload).map((k) => {
      return (props.payload[k] = isNaN(props.payload[k])
        ? props.payload[k]
        : formatYAxis(props.payload[k]));
    });
    let returnValue = value;
    if (name === 'time') {
      returnValue = props.payload[1];
    }
    return [returnValue, name, props];
  };

  return (
    <ResponsiveContainer
      width={options.width}
      height={isMobile ? options.heightMobile : options.height}
    >
      <ScatterChart margin={isMobile ? options.marginMobile : options.margin}>
        <CartesianGrid />
        <XAxis dataKey="date" name="date" allowDuplicatedCategory={false} />
        <YAxis
          domain={options.rangeY}
          orientation="left"
          dataKey="value"
          name={
            type === ProgressType.FOOD || type === ProgressType.BOWEL_MOVEMENT ? 'time' : 'value'
          }
          tickFormatter={formatYAxis}
        />

        <Tooltip cursor={{strokeDasharray: '0 0'}} formatter={formatter} />
        <Scatter name="" data={data} fill={options.color} />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
