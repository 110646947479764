import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import {TextField} from '../../text-field/TextField';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {Select} from '../../select/Select';
import TagsWithBox from '../../tags-with-box/TagsWithBox';
import {ICasualTag} from '../../../../models/ITag';
import ActivityIcon from '../../../../assets/icons/side-menu-bar-logging/activity-icon.svg';
import {ValidationType} from '../../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../../validation/Validation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {FormType, WidgetType} from '../../../../utils/enums';
import {
  WorkoutIntensity,
  MAX_INT_VALUE,
  DATE_PICKER_DATE_FORMAT,
} from '../../../../utils/constants';
import {selectActivityTags} from '../../../../store/selectors/loggingPageSelector';
import {
  addActivityLog,
  editActivityLogg,
} from '../../../../store/actions/logging-page/logging-page-action';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {IKeyValuePair} from '../../../../models/IKeyValuePair';

import useStyles from './activity-form.styles';
import useCommonStyles from '../common-for-widgets.styles';

interface IActivityFormData {
  date: string;
  time: string;
  workoutIntensityObject: IKeyValuePair;
  workoutIntensity?: string;
  activityDuration: string;
  tags: ICasualTag[];
}
interface IActivityFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  openedFromDialog?: boolean;
  date?: string;
  isMobile: boolean;
}

const validationRules = {
  activityDuration: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_INT_NUMBER,
      minValue: 0,
      maxValue: MAX_INT_VALUE,
    },
  ],
  workoutIntensity: [{type: ValidationType.REQUIRED}],
  date: [{type: ValidationType.REQUIRED}],
  time: [{type: ValidationType.REQUIRED_DURATION}],
};

const ActivityForm: React.FC<IActivityFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const workoutIntensityConstants = Object.values(WorkoutIntensity);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  const [data, setData] = useState<IActivityFormData>({
    workoutIntensityObject: {
      key: '',
      value: '',
    },
    date: moment().toString(),
    time: moment().toString(),
    activityDuration: '',
    tags: [],
  });
  const formType = props.formType || FormType.ADDING_MODE;
  const initialTagList = useSelector(selectActivityTags);
  const [tagsList, setTagsList] = useState<ICasualTag[]>([]);
  const tagsBoxRef: any = useRef();

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }
    // if (!data.tags || !data.tags.length || data.tags.length < 1) {
    //   return false;
    // }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };
  //
  // const validateTags = (selectedTags: ICasualTag[]) => {
  //   const selectedTagsTemp = selectedTags.map((tag: any) => tag.id);
  //
  //   setData({
  //     ...data,
  //     tags: selectedTagsTemp,
  //   });
  // };

  useEffect(() => {
    const setTags = () => {
      initialTagList.forEach((item) => (item.isVisible = true));
      const filteredTagsArray: ICasualTag[] = [];
      initialTagList.forEach((initialTagObject) => {
        props.data?.tagsObject.forEach((oneTagObject: ICasualTag) => {
          if (initialTagObject.id === oneTagObject.id) {
            initialTagObject.isVisible = false;
          }
        });

        filteredTagsArray.push(initialTagObject);
      });

      setTagsList(filteredTagsArray);
    };

    let initialObject: IActivityFormData = {
      workoutIntensityObject: {
        key: '',
        value: '',
      },
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
      activityDuration: '',
      tags: [],
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        activityDuration: props.data?.duration.toString() || '',
        workoutIntensityObject: {
          key: props.data?.workoutIntensity || '',
          value: props.data?.workoutIntensity || '',
        },
        workoutIntensity: props.data?.workoutIntensity || '',
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
        tags: props.data?.tagsObject || [],
      };
    }
    if (props.data && props.data?.tags?.length > 0) {
      setTags();
    } else {
      initialTagList.forEach((item) => (item.isVisible = true));
      setTagsList(initialTagList);
    }
    setData(initialObject);
  }, [props.data, initialTagList]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    initialTagList.forEach((item) => (item.isVisible = true));
    setTagsList(initialTagList);
    tagsBoxRef?.current?.clearSelectedTags();
    setData({
      workoutIntensityObject: {
        key: '',
        value: '',
      },
      workoutIntensity: '',
      date: moment().toString(),
      time: moment().toString(),
      activityDuration: '',
      tags: [],
    });
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'workoutIntensity') {
      setData({
        ...data,
        ['workoutIntensityObject']: {key: value.key, value: value.value},
        [name]: value.key,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const selectedTags = tagsBoxRef?.current?.getSelectedTags().map((tag: ICasualTag) => tag.id);
      const activityLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        workoutIntensity: data.workoutIntensityObject.key,
        duration: parseInt(data.activityDuration),
        tags: selectedTags,
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addActivityLog(activityLog));
      } else {
        res = await dispatch(editActivityLogg(activityLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img className={commonClasses.imageMargin} src={ActivityIcon} alt={'activity-icon'} />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.activityWidget.formTitleEdit')
              : t('LogsPage.activityWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.activityWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.activityWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <div className={commonClasses.centerContainer}>
        <Select
          name={'workoutIntensity'}
          label={t('LogsPage.activityWidget.intensityLabel')}
          value={data.workoutIntensityObject}
          required={true}
          onChange={handleChange}
          error={hasError(errors, 'workoutIntensity')}
          helperText={getError(errors, 'workoutIntensity')}
          onBlur={() => {
            validateFormField('workoutIntensity');
          }}
          className={classes.select}
          options={workoutIntensityConstants.map((item) => ({key: item, value: item}))}
        />
        <TextField
          name={'activityDuration'}
          required={true}
          className={commonClasses.normalTextField}
          label={t('LogsPage.activityWidget.durationLabel')}
          onBlur={() => validateFormField('activityDuration')}
          value={data.activityDuration}
          placeholder={t('LogsPage.activityWidget.durationPlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(errors, 'activityDuration')}
          helperText={getError(errors, 'activityDuration')}
        />
      </div>
      <div className={classes.tagsContainer}>
        <Typography variant="caption">{t('LogsPage.activityWidget.tagsLabel')}</Typography>
        <TagsWithBox
          id={'tags-with-box-id'}
          name={'tags-with-box-id'}
          originalTagsList={tagsList}
          ref={tagsBoxRef}
          // validateParent={(tags: ICasualTag[]) => validateTags(tags)}
        />
      </div>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-activity-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-activity-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.activityWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default ActivityForm;
