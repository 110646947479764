import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import {MoodEmoticon} from '../../range-slider/MoodEmoticon';
import NoPain from '../../../../assets/icons/body-pain-level/no-pain-level-icon.svg';
import VeryDistracting from '../../../../assets/icons/body-pain-level/very-distracting-level-icon.svg';
import Unbearable from '../../../../assets/icons/body-pain-level/unbearable-level-icon.svg';
import Invasive from '../../../../assets/icons/body-pain-level/invasive-level-icon.svg';
import BarelyNoticeable from '../../../../assets/icons/body-pain-level/barely-noticeable-level-icon.svg';
import BarelyDistracting from '../../../../assets/icons/body-pain-level/barely-distracting-level-icon.svg';
import * as bodyPainLoggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';

import useStyles from './body-pain-recently-added.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface BodyPainRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
  isHistoryWidget?: boolean;
}

const BodyPainRecentlyAddedRow: React.FC<BodyPainRecentlyAddedRowProps> = (props) => {
  const {data, isMobile, isHistoryWidget} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const renderPainLevel = (painLevel: number) => {
    switch (painLevel) {
      case 0: {
        return (
          <MoodEmoticon
            src={NoPain}
            name={t('LogsPage.bodyPainWidget.marks.noPain')}
            text={t('LogsPage.bodyPainWidget.marks.noPain')}
            isScaleEmoticon={false}
          />
        );
      }
      case 1: {
        return (
          <MoodEmoticon
            src={BarelyNoticeable}
            name={t('LogsPage.bodyPainWidget.marks.barelyNoticeable')}
            text={t('LogsPage.bodyPainWidget.marks.barelyNoticeable')}
            isScaleEmoticon={false}
          />
        );
      }
      case 2: {
        return (
          <MoodEmoticon
            src={BarelyNoticeable}
            name={t('LogsPage.bodyPainWidget.marks.noticeable')}
            text={t('LogsPage.bodyPainWidget.marks.noticeable')}
            isScaleEmoticon={false}
          />
        );
      }
      case 3: {
        return (
          <MoodEmoticon
            src={BarelyDistracting}
            name={t('LogsPage.bodyPainWidget.marks.barelyDistracting')}
            text={t('LogsPage.bodyPainWidget.marks.barelyDistracting')}
            isScaleEmoticon={false}
          />
        );
      }
      case 4: {
        return (
          <MoodEmoticon
            src={BarelyDistracting}
            name={t('LogsPage.bodyPainWidget.marks.distracting')}
            text={t('LogsPage.bodyPainWidget.marks.distracting')}
            isScaleEmoticon={false}
          />
        );
      }
      case 5: {
        return (
          <MoodEmoticon
            src={VeryDistracting}
            name={t('LogsPage.bodyPainWidget.marks.veryDistracting')}
            text={t('LogsPage.bodyPainWidget.marks.veryDistracting')}
            isScaleEmoticon={false}
          />
        );
      }
      case 6: {
        return (
          <MoodEmoticon
            src={VeryDistracting}
            name={t('LogsPage.bodyPainWidget.marks.mildlyInvasive')}
            text={t('LogsPage.bodyPainWidget.marks.mildlyInvasive')}
            isScaleEmoticon={false}
          />
        );
      }
      case 7: {
        return (
          <MoodEmoticon
            src={Invasive}
            name={t('LogsPage.bodyPainWidget.marks.veryInvasive')}
            text={t('LogsPage.bodyPainWidget.marks.veryInvasive')}
            isScaleEmoticon={false}
          />
        );
      }
      case 8: {
        return (
          <MoodEmoticon
            src={Invasive}
            name={t('LogsPage.bodyPainWidget.marks.quiteInvasive')}
            text={t('LogsPage.bodyPainWidget.marks.quiteInvasive')}
            isScaleEmoticon={false}
          />
        );
      }
      case 9: {
        return (
          <MoodEmoticon
            src={Unbearable}
            name={t('LogsPage.bodyPainWidget.marks.unbearable')}
            text={t('LogsPage.bodyPainWidget.marks.unbearable')}
            isScaleEmoticon={false}
          />
        );
      }
      case 10: {
        return (
          <MoodEmoticon
            src={Unbearable}
            name={t('LogsPage.bodyPainWidget.marks.worstEver')}
            text={t('LogsPage.bodyPainWidget.marks.worstEver')}
            isScaleEmoticon={false}
          />
        );
      }
      default:
        return <div />;
    }
  };

  const handleDismiss = () => {
    dispatch(bodyPainLoggingActions.deleteBodyPainLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };
  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        <div className={!isMobile ? commonClasses.infoContainer : ''}>
          {!isHistoryWidget && <DateTimeString date={data.date} isMobile={isMobile} />}
          <div className={classes.valuesContent}>
            <Typography variant={'overline'} className={classes.marginBottom}>
              {t('LogsPage.bodyPainWidget.rangeSliderNeck')}
            </Typography>
            <div className={classes.valuesText}>
              <Typography variant={'subtitle1'}>
                {renderPainLevel(data.neckPainLevelValue)}
              </Typography>
            </div>

            <div className={classes.valuesText}>
              <Typography variant={'overline'} className={classes.marginBottom}>
                {t('LogsPage.bodyPainWidget.rangeSliderShoulder')}
              </Typography>
              <Typography variant={'subtitle1'}>
                {renderPainLevel(data.shoulderPainLevelValue)}
              </Typography>
            </div>

            <div className={classes.valuesText}>
              <Typography variant={'overline'} className={classes.marginBottom}>
                {t('LogsPage.bodyPainWidget.rangeSliderLowBack')}
              </Typography>
              <Typography variant={'subtitle1'}>
                {renderPainLevel(data.lowerBackPainLevelValue)}
              </Typography>
            </div>

            <div className={classes.valuesText}>
              <Typography variant={'overline'} className={classes.marginBottom}>
                {t('LogsPage.bodyPainWidget.rangeSliderHip')}
              </Typography>
              <Typography variant={'subtitle1'}>
                {renderPainLevel(data.hipPainLevelValue)}
              </Typography>
            </div>

            <div className={classes.valuesText}>
              <Typography variant={'overline'} className={classes.marginBottom}>
                {t('LogsPage.bodyPainWidget.rangeSliderKnee')}
              </Typography>
              <Typography variant={'subtitle1'}>
                {renderPainLevel(data.kneePainLevelValue)}
              </Typography>
            </div>
          </div>
        </div>

        {!isHistoryWidget && (
          <div className={commonClasses.buttonsContainer}>
            <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
          </div>
        )}
      </div>
    </div>
  );
};
export default BodyPainRecentlyAddedRow;
