import React, {useEffect, useState} from 'react';
import useStyles from './onboarding-schedule-call-page.styles';
import {getUserDataOnBoarding} from '../../../utils/locale-storage-service';
import {IUserDataOnBoardingLocaleStorage} from '../../../models/IUserDataOnBoardingLocaleStorage';
import {MobileOSType, OnBoardingScheduleType} from '../../../utils/enums';
import ScheduleCall from '../../../common/components/onboarding-schedule-call/ScheduleCall';
import WatchVideo from '../../../common/components/onboarding-schedule-call/WatchVideo';
import FinishOnBoarding from '../../../common/components/onboarding-schedule-call/FinishOnBoarding';

const OnBoardingSchedulePage: React.FC = () => {
  const classes = useStyles();
  const [userData, setUserData] = useState<IUserDataOnBoardingLocaleStorage | null>(null);
  const [onBoardingScheduleType, setOnBoardingScheduleType] = useState(
    OnBoardingScheduleType.SCHEDULE_CALL,
  );

  useEffect(() => {
    setUserData(getUserDataOnBoarding());
  }, []);

  const getMobileOS = (): MobileOSType => {
    const userAgent = navigator?.userAgent?.toLowerCase();
    const android = userAgent.indexOf('android') > -1;
    if (android) return MobileOSType.ANDROID;
    else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return MobileOSType.IOS;
    }
    return MobileOSType.UNKNOWN;
  };

  const os = getMobileOS();

  const changeScheduleType = (type: OnBoardingScheduleType) => {
    setOnBoardingScheduleType(type);
  };

  const renderContentBaseOnType = (): React.ReactNode => {
    switch (onBoardingScheduleType) {
      case OnBoardingScheduleType.SCHEDULE_CALL:
        return <ScheduleCall userData={userData} onContinueClick={changeScheduleType} />;

      case OnBoardingScheduleType.WATCH_VIDEO:
        return <WatchVideo onContinueClick={changeScheduleType} />;

      case OnBoardingScheduleType.FINISH:
        return <FinishOnBoarding mobileOs={os} />;

      default:
        return <></>;
    }
  };

  return <div className={classes.scheduleRoot}>{renderContentBaseOnType()}</div>;
};

OnBoardingSchedulePage.displayName = 'OnBoardingSchedulePage';
export default OnBoardingSchedulePage;
