import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  header: {
    marginBottom: 16,
  },
  insightsContent: {
    marginTop: (props) => (props.isMobile ? '16px' : '12px'),
    color: theme.palette.blacksAndWhites.jet,
  },
  tableBelowTags: {
    display: (props) => (props.isMobile ? 'block' : 'flex'),
    justifyContent: 'flex-start',
    marginTop: 0,
    '& > div': {
      width: '100%',
      paddingRight: (props) => (props.isMobile ? 0 : 16),
    },
    '& > div:nth-child(2)': {
      marginTop: (props) => (props.isMobile ? 24 : 0),
    },
  },
  tableBelowTagsContainer: {
    padding: (props) => (props.isMobile ? '12px 16px' : 16),
    marginTop: (props) => (props.isMobile ? 16 : 0),
    borderRadius: (props) => (props.isMobile ? '12px' : 0),
  },
  tagsContainer: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  foodPicsContainer: {
    padding: (props) => (props.isMobile ? '12px 0 0 0' : 16),
    marginTop: (props) => (props.isMobile ? 16 : 0),
    marginBottom: (props) => (props.isMobile ? 16 : 0),
    borderTop: (props) => (props.isMobile ? '2px solid transparent' : 'none'),
    borderBottom: (props) => (props.isMobile ? 'none' : ''),
    borderRadius: (props) => (props.isMobile ? '12px' : '0 0 12px 12px'),
  },
  foodPics: {
    marginTop: 0,
    '& section': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  foodPicsHeader: {
    marginBottom: (props) => (props.isMobile ? 0 : 8),
    paddingLeft: (props) => (props.isMobile ? 16 : 0),
  },
  imageContainer: {
    width: 180,
    height: 180,
    borderRadius: 24,
    padding: 8,
  },
  minHeight: {
    paddingBottom: (props) => (props.isMobile ? '0' : '16px'),
    background: (props) => (props.isMobile ? 'transparent' : theme.palette.background.paper),
    padding: (props) => (props.isMobile ? '0' : '16px'),
    border: (props) => (props.isMobile ? 'none' : ''),
    boxShadow: (props) => (props.isMobile ? 'none' : ''),
    borderRadius: (props) => (props.isMobile ? '' : '12px 12px 0 0 '),
  },
  carouselSection: {
    width: (props) => (props.isMobile ? '100%' : 596),
  },
}));

export default useStyles;
