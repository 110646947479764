import React, {useState} from 'react';
import { getTokenData } from '../../utils/locale-storage-service';
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import { MOBILE_SIZE, TABLET_BIG_SIZE} from '../../utils/constants';


/*eslint-disable @typescript-eslint/no-empty-function*/
const MealplannerPage: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const tokenData = getTokenData();
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE - 1}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTablet: (isTablet && isTabletSize) || isTabletSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens = React.useMemo(() => {
    return isMobile || isTabletSize;
  }, [window.innerWidth]);
  const handleHeight = () => {
    if (isMobileOrTablet.isMobile) {
      return 'calc(100vh - 60px)';
    }

    if (isMobileOrTablet.isTablet) {
      return 'calc(100vh - 80px)';
    }

    return '100vh';
  };
  
  return <>
  {isLoading ? <div>LOADING</div> : null}
  <iframe
    title="MealplannerPage"
    onLoad={() => setLoading(false)}
    src={`https://mealplanner.betrhealth.com/sessions/betrhealth?token=${tokenData?.idToken}`}
    width="100%"
    height="500"
    style={{
      border: 0,
      boxShadow: 'none',
      width: '100%',
      marginTop: smallScreens ? '65px' : '-21px',
      height: handleHeight(),
      overflow: 'hidden',
    }}
  />
  </>;
};

MealplannerPage.displayName = 'Intercom Page';
export default MealplannerPage;
