import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import * as bloodPressureLogingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';

import useStyles from './blood-pressure-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface BloodPressureRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
}

const BloodPressureRecentlyAddedRow: React.FC<BloodPressureRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleDismiss = () => {
    dispatch(bloodPressureLogingActions.deleteBloodPressureLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };
  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        <div className={!isMobile ? commonClasses.infoContainer : ''}>
          <DateTimeString date={data.date} isMobile={isMobile} />
          <div className={classes.valuesContent}>
            <div className={classes.valuesText}>
              <Typography variant={'subtitle1'} className={classes.textLabel}>
                {t('LogsPage.bloodPressureWidget.systolicLabel')}:
              </Typography>

              <Typography variant={'subtitle1'} className={commonClasses.textContent}>
                {data.systolicValue}
              </Typography>
            </div>

            <div className={classes.valuesText}>
              <Typography variant={'subtitle1'} className={classes.textLabel}>
                {t('LogsPage.bloodPressureWidget.diastolicLabel')}:
              </Typography>

              <Typography variant={'subtitle1'} className={commonClasses.textContent}>
                {data.diastolicValue}
              </Typography>
            </div>
          </div>
        </div>

        <div className={commonClasses.buttonsContainer}>
          <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
        </div>
      </div>
    </div>
  );
};
export default BloodPressureRecentlyAddedRow;
