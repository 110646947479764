import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {ExpandableContainer} from '../../expandable-container/ExpandableContainer';
import {ChipTag} from '../../chip-tag/ChipTag';
import {SearchBox} from '../../search-box/SearchBox';
import Plus from '../../../../assets/icons/insights/plus-insights-icon.svg';
import {ExpandIcon} from '../../side-menu-bar/ExpandIcon';

import useStyles from './category-tag-list.style';
import {ContentDialog} from '../../dialog/ContentDialog';

interface ITagProps {
  searchValue?: string;
  modal: boolean;
  handleChangeSearchValue: (e: any) => void;
  setSelectedTagsList: (e: any) => void;
  selectedTagsList: any;
  categoryTagsList: any;
  handleClick: (e: any) => void;
  handleDelete: (e: any) => void;
  toggleModal: () => void;
}

const CategoryTagListModal: React.FC<ITagProps> = ({
  searchValue,
  modal,
  handleChangeSearchValue,
  setSelectedTagsList,
  categoryTagsList,
  selectedTagsList,
  handleClick,
  handleDelete,
  toggleModal,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const tagsView = () => {
    return (
      <div>
        <div className={classes.headerContainer}>
          <button
            onClick={() => {
              setSelectedTagsList([]);
              toggleModal();
            }}
          >
            {t('Homepage.insights.closeButton')}{' '}
          </button>
          <button onClick={toggleModal}> {t('Homepage.insights.saveButton')} </button>
        </div>
        <div>
          <div className={classes.searchContainer}>
            <SearchBox
              name={'searchBox'}
              placeholder={t('LogsPage.foodWidget.ingredientsInMeal')}
              value={searchValue}
              onChange={(e: any) => handleChangeSearchValue(e)}
            />
          </div>
          <ExpandableContainer className={classes.category}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls="selected-tags"
                id="selected-tags"
              >
                <div className={classes.headerCategory} key={`selected-tags`}>
                  <Typography variant={'overline'}>
                    {t('LogsPage.foodWidget.selectedIngredients')}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.boxTags}>
                  {selectedTagsList &&
                    selectedTagsList.map((oneSelectedTag: any) => (
                      <ChipTag
                        key={`tag-selected-${oneSelectedTag.id}`}
                        color={oneSelectedTag.color}
                        borderColor={oneSelectedTag.color}
                        hoverColor={oneSelectedTag.hoverColor}
                        textColor={oneSelectedTag.textColor}
                        name="chip"
                        id={oneSelectedTag.id || 0}
                        label={oneSelectedTag.name}
                        onDelete={handleDelete}
                      />
                    ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </ExpandableContainer>
          {categoryTagsList.map((oneTagCategory: any) => (
            <ExpandableContainer className={classes.category}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandIcon />}
                  aria-controls="categoryTag-${oneTagCategory.id}"
                  id="categoryTag-${oneTagCategory.id}"
                >
                  <div className={classes.headerCategory} key={`categoryTag-${oneTagCategory.id}`}>
                    <Typography variant={'overline'}>{oneTagCategory.name}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {oneTagCategory.tags.map(
                      (oneTag: any) =>
                        oneTag.isVisible && (
                          <div className={classes.tagMobile}>
                            <div>{oneTag.name}</div>
                            <button onClick={() => handleClick(oneTag.id)}>
                              <img src={Plus} alt="add" />
                            </button>
                          </div>
                        ),
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </ExpandableContainer>
          ))}
        </div>
      </div>
    );
  };

  return (
    <ContentDialog
      slideUp
      fullScreen
      handleDismiss={toggleModal}
      dialogState={modal}
      content={tagsView()}
      className={classes.categoryTagList}
      isCloseButtonHidden={true}
    />
  );
};

export default CategoryTagListModal;
