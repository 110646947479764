/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect} from 'react';
import dayoneBanner from '../../../../assets/images/dayoneBanner.png';
// import betr from '../../../../assets/images/betr.png';
// import fitnessimage from '../../../../assets/images/fitnessimage.png';
import useStyles from './daytwo.styles';
import {Typography} from '@material-ui/core';
import TotalChallengeScore from '../total-challenge-score/TotalChallengeScore';
import {useDispatch, useSelector} from 'react-redux';
import {
  getKemTaiUserWorkoutExercises,
  getKemTaiUserWorkoutHistory,
  getKemTaiUserWorkoutsList,
  getKemTaiWorkoutDetail,
} from '../../../../store/actions/kemtai/kemtai-action';
import {useHistory, useParams} from 'react-router';
import {containsParamInHistory, getHistoryParams} from '../../../../utils/qs-helper';
import IframeKemtai from '../iframe-kemtai/IframeKemtai';
import {GeneralPaths} from '../../../../utils/constants';
import Loader from '../../loader/Loader';
// import DisclosureAgreement from '../disclosure-agreement/DisclosureAgreement';
import RehabDisclosureAgreement from '../disclosure-agreement/RehabDisclosureAgreement';

const DayOne = ({screens, setScreens}) => {
  const [workoutDetail, setWorkoutDetail] = React.useState(null);
  const [exercisesDetail, setExercisesDetail] = React.useState(null);
  const [exercisesScoreDetail, setExercisesScoreDetail] = React.useState(null);
  const [isKemtaiIframe, setIsKemtaiIframe] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedWorkoutID, setSelectedWorkoutID] = React.useState(8);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const userData: any = useSelector((state: any) => state.users.currentUser);
  const isRehabEnabled = useSelector((state) => state?.nourish?.rehabEnabled);

  useEffect(() => {
    if (!isRehabEnabled && params?.movementType === 'rehab') {
      history.replace({
        pathname: `${GeneralPaths.MovementPage}`,
        search: '',
      });
    }
  }, [isRehabEnabled]);

  useEffect(() => {
    setIsLoader(true);
    if (params?.movementType === 'rehab' && params?.workoutGroupSlug) {
      dispatch(
        getKemTaiUserWorkoutsList(
          userData?.id,
          '',
          '',
          params?.movementType,
          params?.workoutGroupSlug,
        ),
      ).then((res: any) => {
        setIsLoader(false);
        if (res) {
          setWorkoutDetail(res);
        } else {
          // console.log('');
        }
      });
    } else if (params?.workoutGroupSlug && params?.workoutSlug) {
      dispatch(
        getKemTaiUserWorkoutsList(
          userData?.id,
          '',
          '',
          params?.workoutGroupSlug,
          params?.workoutSlug,
        ),
      ).then((res: any) => {
        setIsLoader(false);
        if (res) {
          setWorkoutDetail(res);
          dispatch(getKemTaiUserWorkoutHistory(userData?.id, res?.workoutGroupID)).then(
            (res: any) => {
              if (res && res?.length) {
                // handleOnCardClick(slug, false);
              } else {
                history.replace({
                  pathname: GeneralPaths.MovementPage,
                });
              }
            },
          );
        }
      });
    } else if (containsParamInHistory(history, '_workoutid')) {
      const params = getHistoryParams(history);
      if (params?._workoutid && params['type-slug'] === 'movement-mode') {
        dispatch(
          getKemTaiUserWorkoutsList(userData?.id, params?._workoutgroupid, params?._workoutid),
        ).then((res: any) => {
          setIsLoader(false);
          if (res) {
            setWorkoutDetail(res);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    setIsLoader(true);
    if (workoutDetail?.workouts?.length && workoutDetail?.workouts[0]?.kemtaiId) {
      dispatch(getKemTaiWorkoutDetail(workoutDetail?.workouts[0]?.kemtaiId)).then((res: any) => {
        // setIsLoader(false);
        if (res) {
          res.result = JSON.parse(res?.result);
          setExercisesDetail(res);
        }
      });
      // setIsLoader(true);
      dispatch(getKemTaiUserWorkoutExercises(userData?.id)).then((res: any) => {
        setIsLoader(false);
        if (res) {
          setExercisesScoreDetail(res);
        }
      });
    }
  }, [workoutDetail]);

  const handleNavigation = (screen) => {
    setScreens({
      ...screens,
      isLanding: true,
      isWorkoutType: false,
      isWorkoutDayType: false,
    });
    if (params?.movementType === 'rehab') {
      history.replace({
        pathname: `${GeneralPaths.MovementPage}/${workoutDetail?.workoutGroupSlug}`,
      });
    } else {
      history.replace({
        pathname:
          screen === 'landing'
            ? `${GeneralPaths.MovementPage}/fitness`
            : `${GeneralPaths.MovementPage}/fitness/${workoutDetail?.workoutGroupSlug}`,
        // search: screen === 'landing' ? '' : `?type-slug=${NourishType.MOVEMENT_MODE.toString()}&_workoutgroupid=${workoutDetail?.workoutGroupID}`,
      });
    }
  };
  const handelStartWorkout = () => {
    setIsLoader(true);
    if (workoutDetail?.workoutGroupID) {
      setSelectedWorkoutID(workoutDetail?.workoutGroupID);
      dispatch(getKemTaiUserWorkoutHistory(userData?.id, workoutDetail?.workoutGroupID)).then(
        (res: any) => {
          //workoutDetail?.workoutGroupID
          setIsLoader(false);
          if (res?.length > 0) {
            if (res[0]?.disclosureAgreement === false) {
              setOpen(true);
            } else {
              setIsKemtaiIframe(true);
            }
          } else {
            setOpen(true);
          }
        },
      );
    }
  };
  const getScoreHTML = (exeId: any) => {
    const fintExe = exercisesScoreDetail.find((exe) => exe.exerciseId === exeId);
    if (fintExe) {
      return (
        <>
          <div className="border"></div>
          <div className="workoutContent">
            <div className="worktime">
              <h6>Workout Time</h6>
              <h5>{fintExe?.duration} sec</h5>
            </div>
            <div className="worktime">
              <h6>Effectiveness</h6>
              <h5>{fintExe?.score}%</h5>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      <RehabDisclosureAgreement
        setOpen={setOpen}
        open={open}
        // setIsAgreement={setIsAgreement}
        selectedWorkoutID={selectedWorkoutID}
        setIsKemtaiIframe={setIsKemtaiIframe}
      />

      {isLoader && <Loader className={classes.fitnessLoader} />}
      {!workoutDetail && !workoutDetail?.lastWorkout ? null : (
        <>
          {workoutDetail?.workouts?.length ? (
            <IframeKemtai
              isKemtaiIframe={isKemtaiIframe}
              setIsKemtaiIframe={setIsKemtaiIframe}
              kemtaiId={workoutDetail?.workouts[0].kemtaiId}
              workoutGroupID={workoutDetail?.workoutGroupID}
            />
          ) : null}
          {workoutDetail && (
            <div className={classes.dayoneBannerArea}>
              <h6 className="subtitle">
                {params?.movementType != 'rehab' && (
                  <>
                    <span onClick={() => handleNavigation('landing')}>Fitness</span> /{' '}
                  </>
                )}
                <span onClick={() => handleNavigation()}>{workoutDetail?.workoutGroupName}</span> /{' '}
                {exercisesDetail?.result[0]?.name}
              </h6>
              {workoutDetail?.workouts?.length ? (
                <>
                  <h3 className="headingh3">{exercisesDetail?.result[0]?.name}</h3>
                  <div className="totalScoreArae">
                    <TotalChallengeScore />
                  </div>
                  <div className="dayoneBanner">
                    <img
                      src={
                        workoutDetail?.workouts[0]?.workoutImageUrl ||
                        exercisesDetail?.result[0]?.thumbnail_image_url ||
                        dayoneBanner
                      }
                      className="bnrimg"
                      alt=""
                    />
                    <div className="dayoneContent">
                      {/* <img src={betr} className="betr" alt="" /> */}
                      <div className="bnrBottomContent">
                        <div className="dayContent">
                          <h4 className="headingh4">{exercisesDetail?.result[0]?.name}</h4>
                          <p className="subtitle2">{workoutDetail?.workoutGroupName}</p>
                        </div>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handelStartWorkout()}
                          className="StartAgain"
                        >
                          {workoutDetail?.workouts[0].completed ? 'Start Again' : 'Start Workout'}
                        </a>
                      </div>
                    </div>
                  </div>
                  <a
                    href="javascript:void(0)"
                    onClick={() => setIsKemtaiIframe(true)}
                    className="StartAgain2"
                  >
                    Repeat Workout
                  </a>
                  <Typography variant={'h5'} align="left">
                    Exercises
                  </Typography>

                  <div
                    className={`exerciseMainSec ${
                      workoutDetail?.workouts[0].completed ? 'completed' : ''
                    }`}
                  >
                    {exercisesDetail?.result[0]?.exercises.map((exe, index) => {
                      return (
                        <div className="exerciseBox" key={index}>
                          <video src={exe?.thumbnail_video_url} className="exerciseImage"></video>
                          {/* <img src={fitnessimage} className="exerciseImage" alt="" /> */}
                          <div className="exerciseContent">
                            <h3>{exe?.name}</h3>
                            <ul>
                              {params?.movementType != 'rehab' ? <li>Fitness</li> : null}
                              <li>{workoutDetail?.workoutGroupName}</li>
                              <li>{exercisesDetail?.result[0]?.name}</li>
                            </ul>
                            {workoutDetail?.workouts[0].completed ? getScoreHTML(exe?.id) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DayOne;
