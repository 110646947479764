import {Box, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';
import {selectUserTags} from '../../../store/selectors/loggingPageSelector';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {TagsWidgetType} from '../../../utils/enums';
import {ChipTag} from '../chip-tag/ChipTag';
import {PaperWrapper} from '../paper-wrapper/PaperWrapper';
import useStyles from './profile-tags.styles';

interface ITagsProps {
  type: TagsWidgetType;
}

const SimpleTagsWidget: React.FC<ITagsProps> = ({type}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const initialUserTagsList = useSelector(selectUserTags);
  const user = useSelector(selectCurrentUser);
  const [tagCategory, setTagCategory] = useState<IUserCategoryTagDto>();

  const getCategoryName = {
    [TagsWidgetType.PREFERENCES]: 'Preferences',
    [TagsWidgetType.GOALS]: 'Goals',
  };

  useEffect(() => {
    if (initialUserTagsList?.length > 0) {
      const category = initialUserTagsList.find(
        (category) => category.name === getCategoryName[type],
      );
      setTagCategory(category);
    }
  }, []);

  return (
    <PaperWrapper>
      <Box mb={1}>
        <Typography variant={'subtitle2'} align="left">
          {t(`ProfilePage.${type.toString()}.title`)}
        </Typography>
      </Box>
      <div className={classes.tagsContainer}>
        {tagCategory?.tags.map(
          (tag) =>
            user &&
            user.tags &&
            user.tags?.length > 0 &&
            user.tags.map(
              (tagId: number) =>
                tag.id === tagId && (
                  <ChipTag
                    key={`tag-selected-${tag.id}`}
                    color={tagCategory.color}
                    borderColor={tagCategory.hoverColor}
                    hoverColor={tagCategory.hoverColor}
                    name="chip"
                    id={tag.id || 0}
                    label={tag.name}
                  />
                ),
            ),
        )}
      </div>
    </PaperWrapper>
  );
};
export default SimpleTagsWidget;
