import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    modal: {
        '& .MuiBackdrop-root': {
            background: 'rgba(0, 0, 0, 0.9)'
        },
        '& .closePopup': {
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: "1",
            background: 'none',
            padding: 0,
            boxShadow: 'unset'
        },
    },
    modalContainr: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 630,
        width: "100%",
        overflowY: "auto", 
        borderRadius: 12,
        padding: 0,
        fontSize: 16,
        lineHeight: '24px',
        color: '#FFF',
        '& h2': {
            fontSize: 48,
            lineHeight: '48px',
            margin: '0 0 30px 0',
            textAlign: 'center',
        },
        '& .letsStartTime': {
            position: 'absolute',
            left: 20,
            top: 20,
            background: '#FFF',
            borderRadius: 30,
            padding: '5px 10px',
            color: '#000',
            verticalAlign: 'middle',
            '& img': {
                verticalAlign: 'middle',
                marginRight: 4
            }
        },
        '& .letsStartNotification': {
            display: 'flex',
            marginBottom: 30,
            justifyContent: 'space-between',
            '& strong': {
                display: 'block'
            },
            '& img': {
                float: 'left',
                marginRight: 15
            }
        },
        '& .letsStartButtons': {
            textAlign: 'center',
            maxWidth: 300,
            margin: '0 auto',
            '& button': {
                width: '100%',
                height: 55,
                color: '#fff',
                borderRadius: 30,
                borderColor: '#3f93e2',
                background: 'linear-gradient(90deg, rgba(63,150,226,1) 4%, rgba(69,164,230,1) 52%, rgba(56,107,216,1) 97%)',
                margin: '0 0 25px 0',
                '&.MuiButton-text': {
                    background : 'none',
                    border: '1px solid #FFF',
                    width: 'auto',
                    padding: '0 25px'
                }
            }
        }
    },
    letsStartBanner:{
        position: "relative",
        marginRight:"16px",
        margin:"0 0 24px 0",
        borderRadius:20,
        width: '100%',
        '& .bannerImg': {
        width:"100%",
        borderRadius: 10,
        }
    },
    betr:{
        position: "absolute",
        right:25,
        top:25, 
    },
    letsStartTitle:{
        position: "absolute",
        bottom: 20,
        left: 20,
        '& h3': {
            color: '#FFF',
            margin: 0,
            fontSize: 24,
            lineHight: '28px',
            '& span': {
                fontWeight: 'normal'
            }
        }
    }
  });

  export default useStyles;