import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {},
  boxTags: {
    marginTop: (props) => (props.isMobileOrTablet ? 24 : 8),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.blacksAndGrays.gainsboro}`,
    background: theme.palette.background.paper,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: 8,
    paddingTop: 0,
    minHeight: 40,
    width: '100%',
    '&.tagsContainerBlue': {
      border: 'none',
      background: (props) => (props.isMobileOrTablet ? "#d6d6d6" : theme.palette.tintsAndShades.oxfordBlueDark),
    },
  },
  tagsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonLoadMore: {
    background: 'transparent',
    border: 'none',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '1.25px',
    // color: (props) =>
    //   props.isMobileOrTablet
    //     ? theme.palette.background.paper
    //     : theme.palette.blacksAndWhites.sonicSilver,
        color: (props) =>
        props.isMobileOrTablet
          ? "#000000"
          : theme.palette.blacksAndWhites.sonicSilver,
    position: (props) => (props.isMobileOrTablet ? 'inherit' : 'relative'),
    left: (props) => (props.isMobileOrTablet ? 0 : '50%'),
    transform: (props) => (props.isMobileOrTablet ? 'translateX(0)' : 'translateX(-50%)'),
    width: (props) => (props.isMobileOrTablet ? '100%' : 'auto'),
    cursor: 'pointer',
    marginTop: '24px !important',
    marginBottom: 30,
  },
}));

export default useStyles;
