import {Typography, useMediaQuery} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ANDROID_APP, IOS_APP, MOBILE_SIZE} from '../../../utils/constants';
import {MobileOSType} from '../../../utils/enums';
import {Button} from '../button/Button';
import useStyles from './finish-on-boarding.styles';
import {buildUrlLinkMiniOrange} from '../../../api/users.api';
import Snackbar from '../notistack/SnackbarUtils';
import classNames from 'classnames';
import {isMobileOnly} from 'react-device-detect';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';

interface IProps {
  mobileOs: MobileOSType;
}

const FinishOnBoarding: React.FC<IProps> = ({mobileOs}) => {
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
    };
    return size;
  }, [window.innerWidth]);

  const classes = useStyles({
    isMobileOrTablet,
  });

  const finish = async (isDownloadAndFinish: boolean) => {
    try {
      setLoading(true);
      if (isDownloadAndFinish) {
        window.open(`${mobileOs === MobileOSType.ANDROID ? ANDROID_APP : IOS_APP}`, '_self');
      } else {
        buildUrlLinkMiniOrange();
      }
    } catch (err: any) {
      Snackbar.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      {!isMobileOrTablet.isMobile && (
        <div>
          <img src={BetterHealthIcon} alt="better health icon" width={164} height={164} />
        </div>
      )}

      <div className={classes.contentContainerText}>
        <Typography variant="h5" className={classes.header}>
          {t('OnBoardingSchedulePage.finish.title')}
        </Typography>
        <Typography
          variant="body1"
          className={
            isMobileOrTablet.isMobile
              ? classes.textContent
              : classNames(classes.textContent, classes.textDesktop)
          }
        >
          {t('OnBoardingSchedulePage.finish.contentText')}
        </Typography>
        {isMobileOrTablet.isMobile && (
          <Typography variant="body1" className={classes.textContent}>
            {t('OnBoardingSchedulePage.finish.textMobile')}
          </Typography>
        )}
      </div>

      <div className={classes.buttonContainer}>
        {isMobileOrTablet.isMobile && (
          <Button
            id="download"
            className={classes.button}
            onClick={async () => await finish(true)}
            loading={isLoading}
            disabled={isLoading}
          >
            {t('OnBoardingSchedulePage.finish.download')}
          </Button>
        )}
        <Button
          id="finish"
          className={classNames(classes.button, classes.finishButton)}
          variant={isMobileOrTablet.isMobile ? 'text' : 'contained'}
          onClick={async () => await finish(false)}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('OnBoardingSchedulePage.finish.finish')}
        </Button>
      </div>
    </div>
  );
};
export default FinishOnBoarding;
