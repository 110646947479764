import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  valuesContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  valuesText: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingTop: (props) => (props.isMobile ? 12 : 0),
  },
  valueItem: {
    display: 'flex',
    marginTop: 8,
  },
  label: {
    color: theme.palette.blacksAndWhites.sonicSilver,
    marginRight: 4,
  },
}));

export default useStyles;
