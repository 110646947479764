import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import moment from 'moment';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import {TextField} from '../../text-field/TextField';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import DrinkIcon from '../../../../assets/icons/side-menu-bar-logging/drink-icon.svg';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {
  addDrinkLog,
  editDrinkLog,
} from '../../../../store/actions/logging-page/logging-page-action';
import {ValidationType} from '../../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../../validation/Validation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {DATE_PICKER_DATE_FORMAT, MAX_INT_VALUE} from '../../../../utils/constants';
import {FormType, WidgetType} from '../../../../utils/enums';

import useStyles from './drink-form.styles';
import useCommonStyles from '../common-for-widgets.styles';

interface IDrinkFormData {
  date: string;
  time: string;
  ounces: string;
}
interface IDrinkFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  openedFromDialog?: boolean;
  date?: string;
  isMobile: boolean;
}

const validationRules = {
  ounces: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_INT_NUMBER,
      minValue: 0,
      maxValue: MAX_INT_VALUE,
    },
  ],
  date: [{type: ValidationType.REQUIRED}],
  time: [{type: ValidationType.REQUIRED_DURATION}],
};

const DrinkForm: React.FC<IDrinkFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  const [data, setData] = useState<IDrinkFormData>({
    date: moment().toString(),
    time: moment().toString(),
    ounces: '',
  });
  const formType = props.formType || FormType.ADDING_MODE;

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    let initialObject: IDrinkFormData = {
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
      ounces: '',
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        ounces: props.data?.value.toString() || '',
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
      };
    }

    setData(initialObject);
  }, [props.data]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    setData({
      date: moment().toString(),
      time: moment().toString(),
      ounces: '',
    });
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const drinkLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        value: parseInt(data.ounces),
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addDrinkLog(drinkLog));
      } else {
        res = await dispatch(editDrinkLog(drinkLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img className={commonClasses.imageMargin} src={DrinkIcon} alt={'drink-icon'} />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.drinkWidget.formTitleEdit')
              : t('LogsPage.drinkWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.drinkWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.drinkWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <Typography variant="caption" component="div" className={classes.ouncesLabel}>
        {t('LogsPage.drinkWidget.ouncesLabel')}
      </Typography>
      <div className={commonClasses.centerContainer}>
        <TextField
          name={'ounces'}
          className={commonClasses.normalTextField}
          onBlur={() => validateFormField('ounces')}
          value={data.ounces}
          placeholder={t('LogsPage.drinkWidget.ouncesPlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(errors, 'ounces')}
          helperText={getError(errors, 'ounces')}
        />
      </div>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-drink-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-drink-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.drinkWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default DrinkForm;
