import React, { useEffect } from 'react';
import {Router} from 'react-router';
import {Switch} from 'react-router-dom';
import AuthWrapper from './common/components/auth-wrapper/AuthWrapper';
import BaseLayout from './common/components/base-layout/BaseLayout';
import history from './history';
import {getRoutes} from './routes';
import {Provider} from 'react-redux';
import store from './store/store';
import {IntercomProvider} from 'react-use-intercom';
import ErrorBoundary from './common/components/ErrorBoundary/ErrorBoundary';
import useIdleTimeout from './common/hooks/useIdleTimeout';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';

const App: React.FC = () => {
  // const query = new URLSearchParams(useLocation().search);
  // const currentUser: IUser = useSelector(selectCurrentUser);


  const isActive = useIdleTimeout({timeoutInSeconds: 2000});

  useEffect(() => {
    if(!isActive){
      console.log('isActive', isActive)
    }
  }, [isActive]);
  return (
    <Provider store={store}>
      <Router history={history}>
        <AuthWrapper>
          <IntercomProvider
            appId={INTERCOM_APP_ID}
            autoBoot={true}
            autoBootProps={{
              customLauncherSelector: '#intercom-id-name',
              name: '',
            }}
          >
            <ErrorBoundary>
              <BaseLayout renderRoutes={() => <Switch>{getRoutes()}</Switch>} />
            </ErrorBoundary>
          </IntercomProvider>
        </AuthWrapper>
      </Router>
    </Provider>
  );
};

export default App;
