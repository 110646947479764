/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import useStyles from './movementchallenge.styles';
import MovementCard from './movementCard';
import {Typography} from '@material-ui/core';
import TotalChallengeScore from '../total-challenge-score/TotalChallengeScore';
import { useDispatch, useSelector } from 'react-redux';
import { getKemTaiUserWorkoutHistory, getKemTaiUserWorkoutsList } from '../../../../store/actions/kemtai/kemtai-action';
import { GeneralPaths } from '../../../../utils/constants';
import { useHistory, useParams } from 'react-router';
import Loader from '../../loader/Loader';

const MovementChallenge = ({selectedWorkoutID, setScreens, screens}) => {
  const [workoutDetail, setWorkoutDetail] = useState(null)
  const [isLoader, setIsLoader] = React.useState(false);

    const classes = useStyles();

    const history = useHistory();
    const params: any = useParams();
    const dispatch = useDispatch()
    const userData: any = useSelector((state: any) => state.users.currentUser)
    const isRehabEnabled = useSelector(state => state?.nourish?.rehabEnabled);

    useEffect(() => {
      if(!isRehabEnabled && params?.movementType === 'rehab'){
        history.replace({
          pathname: `${GeneralPaths.MovementPage}`,
          search: '',
        });
      }
    }, [isRehabEnabled])

    useEffect(() => {
      setIsLoader(true);
      if(params?.movementType === 'rehab'){
        if (params?.movementType){
          dispatch(getKemTaiUserWorkoutsList(userData?.id, '', '', params?.movementType)).then((res: any) => {
            if(res){
              res?.workouts?.sort(function(a: any, b: any) {
                const keyA = parseInt(a.order),
                  keyB = parseInt(b.order);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
              setWorkoutDetail(res)
            } else {
                  // console.log('')
              }
              setIsLoader(false);
          })
        }
      } else{
      if (params?.workoutGroupSlug){
        dispatch(getKemTaiUserWorkoutsList(userData?.id, '', '', params?.workoutGroupSlug)).then((res: any) => {
          if(res){
            res?.workouts?.sort(function(a: any, b: any) {
              const keyA = parseInt(a.order),
                keyB = parseInt(b.order);
              // Compare the 2 dates
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
            setWorkoutDetail(res)
            dispatch(getKemTaiUserWorkoutHistory(userData?.id, res?.workoutGroupID)).then((res: any) => {
              if(res && res?.length){
                // handleOnCardClick(slug, false);
              } else {
                history.replace({
                  pathname: GeneralPaths.MovementPage
                });
              }
            })
          }
            setIsLoader(false);
        })
      }
    }
    }, [selectedWorkoutID])

    const handleNavigation = () => {
      setScreens({
        ...screens,
        isLanding: true,
        isWorkoutType: false
      })
      history.replace({
        pathname: `${GeneralPaths.MovementPage}/fitness`,
        search: '',
      });
    }

  return (
    <>
    {isLoader && <Loader className={classes.fitnessLoader} /> }
   <div className={classes.movementChallengeArea}>
   <div className='totalScoreArae'  >
          <TotalChallengeScore />
        </div>
     {params?.movementType != 'rehab' && <h6 className='subtitle'><span onClick={() => handleNavigation()}>Fitness</span> / {workoutDetail?.workoutGroupName}</h6>}
     <h3 className='headingh3'>{workoutDetail?.workoutGroupName}</h3>
     <Typography variant={'h5'} align="left">
     Workouts
    </Typography>
    <MovementCard workoutList={workoutDetail?.workouts} workoutGroupID={workoutDetail?.workoutGroupID} workoutGroupSlug={workoutDetail?.workoutGroupSlug} workoutDetail={workoutDetail} />
   </div>
    </>
  )
}

export default MovementChallenge