import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './alert-dialog.styles';
import {Button} from '../button/Button';

interface IAlertDialogProps {
  confirmButtonText: string;
  dismissButtonText: string;
  headerText: string;
  contentText: string;
  handleConfirm: any;
  handleDismiss: any;
  dialogState: boolean;
}

export const AlertDialog: React.FC<IAlertDialogProps> = (props) => {
  const classes = useStyles();
  const {
    confirmButtonText,
    dismissButtonText,
    headerText,
    contentText,
    dialogState,
    handleConfirm,
    handleDismiss,
  } = props;

  return (
    <Dialog
      open={dialogState}
      onClose={handleDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">{headerText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="id-`${dismissButtonText}`"
          variant={'text'}
          onClick={handleDismiss}
          className={classes.cancelButton}
        >
          {dismissButtonText}
        </Button>
        <Button
          id="id-`${confirmButtonText}`"
          variant={'text'}
          onClick={handleConfirm}
          className={classes.deleteButton}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.displayName = 'AlertDialog';
