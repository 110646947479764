import * as React from 'react';
import {IDailyContentCategoryDto} from '../../../dtos/IDailyContentCategoryDto';
import useStyles from './content-category-card.styles';
import {Typography} from '@material-ui/core';
import LevelOneIcon from '../../../assets/backgrounds/betr-program/level-one-new.png';
import LevelTwoIcon from '../../../assets/backgrounds/betr-program/level-two-new.png';
import PrepPhaseIcon from '../../../assets/backgrounds/betr-program/prep-phase-new.png';
import FAQIcon from '../../../assets/backgrounds/betr-program/faq-new.png';
import BlogIcon from '../../../assets/backgrounds/betr-program/blog.png';
import {BetrProgramType} from '../../../utils/enums';
import { faq, prepPhase2, level1, level2, blog} from '../../../utils/constants';

interface Props {
  dailyContentCategory: IDailyContentCategoryDto;
  onClick: (dailyContentCategory: IDailyContentCategoryDto) => void;
  smallScreens: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const ContentCategoryCard: React.FC<Props> = ({
  dailyContentCategory,
  onClick,
  smallScreens,
}) => {
  const classes = useStyles({
    smallScreens,
  });

  const renderContent = () => {
    switch (dailyContentCategory.name) {
      case BetrProgramType.PREP:
        return (
          <React.Fragment>
            <img src={PrepPhaseIcon} alt={'prep-phase'} width={"99%"} className={classes.image} />
            <Typography variant={'h6'}  className={smallScreens ? classes.textColorSmall : classes.textColor}>
              {prepPhase2}
            </Typography>
          </React.Fragment>
        );
      case BetrProgramType.LEVEL1:
        return (
          <React.Fragment>
            <img src={LevelOneIcon} alt={'level-one'} width={"99%"} className={classes.image} />
            <Typography variant={'h6'}  className={smallScreens ? classes.textColorSmall : classes.textColor}>
              {level1}
            </Typography>
          </React.Fragment>
        );
      case BetrProgramType.LEVEL2:
        return (
          <React.Fragment>
            <img src={LevelTwoIcon} alt={'level-two'} width={"99%"} className={classes.image} />
            <Typography variant={'h6'}  className={smallScreens ? classes.textColorSmall : classes.textColor}>
              {level2}
            </Typography>
          </React.Fragment>
        );
      case BetrProgramType.FAQ:
        return (
          <React.Fragment>
            <img src={FAQIcon} alt={'faq'} width={"99%"} className={classes.image} />
            <Typography variant={'h6'}  className={smallScreens ? classes.textColorSmall : classes.textColor}>
              {faq}
            </Typography>
          </React.Fragment>
        );
        case BetrProgramType.BLOG:
          return (
            <React.Fragment>
              <img src={BlogIcon} alt={'faq'} width={"99%"} className={classes.image} />
              <Typography variant={'h6'} className={smallScreens ? classes.textColorSmall : classes.textColor}>
                {blog}
              </Typography>
            </React.Fragment>
          );
      default:
        return <div />;
    }
  };

  return (
    <div
      id={'dailyContentCategory' + dailyContentCategory.id}
      key={'dailyContentCategory' + dailyContentCategory.id}
      className={classes.container}
      onClick={() => onClick(dailyContentCategory)}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      <div className={classes.centeredContent}>{renderContent()}</div>
    </div>
  );
};
