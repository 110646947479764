import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    border: `1px solid ${theme.palette.tintsAndShades.blueGrey}`,
    borderRadius: '12px 12px',
    margin: '16px 0px',
  },
  cardHeader: {
    padding: '8px 16px',
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: theme.palette.tintsAndShades.blueGrey,
    height: 36,
    color: theme.palette.text.secondary,
  },
  cardContent: {
    padding: '8px 16px',
  },
  oneRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  labelDetails: {
    marginRight: 10,
  },
}));

export default useStyles;
