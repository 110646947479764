import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  wideTextField: {
    width: 368,
  },
  formRowContainer: {
    display: (props) => (props.isMobile ? 'block' : 'flex'),
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: (props) => (props.isMobile ? '24px' : '16px'),
    marginBottom: '16px',
    flexWrap: 'wrap',
  },
  formRowSubContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > div': {
      marginTop: (props) => (props.isMobile ? '24px' : 0),
    },
    '& > div:nth-child(odd)': {
      paddingRight: (props) => (props.isMobile ? 8 : 0),
    },
    '& > div:nth-child(even)': {
      paddingLeft: (props) => (props.isMobile ? 8 : 0),
    },
  },
  switcherCenter: {
    marginBottom: 4,
    marginTop: '20px',
    marginRight: '16px',
  },
  bodyWeight: {
    marginRight: (props) => (props.isMobile ? 0 : 24),
  },
}));

export default useStyles;
