import {Box} from '@material-ui/core';
import UserAvatarIcon from '../assets/icons/table-list/user-avatar.svg';

export interface ISimpleTableColumn {
  key: string;
}

export interface IColumnModifiers {
  [key: string]: (value: any) => void;
}

export interface ISchema {
  columns: ISimpleTableColumn[];
}

export interface IMeta {
  schema: ISchema;
  columnModifiers?: IColumnModifiers;
}

export interface IMetaData {
  userSearch: IMeta;
}

export const metaData: IMetaData = {
  userSearch: {
    schema: {
      columns: [{key: 'imageUrl'}, {key: 'name'}, {key: 'email'}, {key: 'phone'}],
    },
    columnModifiers: {
      imageUrl: (_value: string) => (
        <Box mt={'5px'}>
          <img src={UserAvatarIcon} alt={_value} width={32} height={32} />
        </Box>
      ),
    },
  },
};
