import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  valuesContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: (props) => (props.isMobile ? 8 : 0),
  },
  valuesText: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    width: 100,
    marginRight: 25,
  },
  textContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginLeft: 4,
  },
}));

export default useStyles;
