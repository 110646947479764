import React, {ReactNode} from 'react';
import {PaperWrapper} from '../paper-wrapper/PaperWrapper';
import BodyWeightIcon from '../../../assets/icons/side-menu-bar-logging/body-weight-icon.svg';
import {Typography} from '@material-ui/core';
import {FormType, LoggingWidgetType} from '../../../utils/enums';
import {useTranslation} from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CollapseThinIcon from '../../../assets/icons/side-menu-bar/collapse-thin-icon.svg';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {ExpandableContainer} from '../expandable-container/ExpandableContainer';
import useStyles from './recently-added-logs.styles';
import BodyWeightRecentlyAddedRow from './body-weight/BodyWeightRecentlyAddedRow';
import FoodIcon from '../../../assets/icons/side-menu-bar-logging/food-icon.svg';
import ActivityIcon from '../../../assets/icons/side-menu-bar-logging/activity-icon.svg';
import StressIcon from '../../../assets/icons/side-menu-bar-logging/stress-icon.svg';
import DrinkIcon from '../../../assets/icons/side-menu-bar-logging/drink-icon.svg';
import SleepIcon from '../../../assets/icons/side-menu-bar-logging/sleep-icon.svg';
import BowelIcon from '../../../assets/icons/side-menu-bar-logging/bowel-icon.svg';
import StepsIcon from '../../../assets/icons/side-menu-bar-logging/steps-icon.svg';
import BloodPressureIcon from '../../../assets/icons/side-menu-bar-logging/blood-pressure-icon.svg';
import BodyMeasurementIcon from '../../../assets/icons/side-menu-bar-logging/body-measurement-icon.svg';
import BloodGlucoseIcon from '../../../assets/icons/side-menu-bar-logging/blood-glucose-icon.svg';
import BodyMeasurementRecentlyAddedRow from './body-measurement/BodyMeasurementRecentlyAddedRow';
import BloodPressureRecentlyAddedRow from './blood-pressure-widget/BloodPressureRecentlyAddedRow';
import StepsRecentlyAddedRow from './steps/StepsRecentlyAddedRow';
import SleepRecentlyAddedRow from './sleep/SleepRecentlyAddedRow';
import BowelRecentlyAddedRow from './bowel/BowelRecentlyAddedRow';
import DrinkRecentlyAddedRow from './water/DrinkRecentlyAddedRow';
import StressRecentlyAddedRow from './stress/StressRecentlyAddedRow';
import ActivityRecentlyAddedRow from './activity/ActivityRecentlyAddedRow';
import FoodRecentlyAddedRow from './food/FoodRecentlyAddedRow';
import BloodGlucoseRecentlyAddedRow from './blood-glucose-widget/BloodGlucoseRecentlyAddedRow';
import {LoggingWidgetDataType} from '../../../types/LoggingWidgetDataType';
import BloodLabsRecentlyAddedRow from './blood-labs-widget/BloodLabsFormRecentlyAddedRow';
import BodyPainRecentlyAddedRow from './body-pain/BodyPainRecentlyAddedRow';

interface RecentlyAddedLogsProps {
  changeState: (formTypeValue: FormType) => void;
  loggedItems: LoggingWidgetDataType[];
  selectLog: (arg: any) => void;
  loggingWidgetType: LoggingWidgetType;
  isMobile: boolean;
}

const RecentlyAddedLogs: React.FC<RecentlyAddedLogsProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const {loggedItems, loggingWidgetType} = props;
  const [t] = useTranslation();
  const loggedItemsFiltered = LoggingWidgetType.BLOOD_LABS_WIDGET
    ? loggedItems.slice(1)
    : loggedItems.filter((log) => log.id != loggedItems[0].id);

  const renderAppropriateHeader = (): ReactNode => {
    switch (loggingWidgetType) {
      case LoggingWidgetType.BODY_WEIGHT_WIDGET:
        return (
          <React.Fragment>
            <img className={classes.imageMargin} src={BodyWeightIcon} alt={'body-weight-icon'} />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.bodyWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.FOOD_WIDGET:
        return (
          <React.Fragment>
            <img className={classes.imageMargin} src={FoodIcon} alt={'food-icon'} />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.foodWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.ACTIVITY_WIDGET:
        return (
          <React.Fragment>
            <img
              className={classes.imageMargin}
              src={ActivityIcon}
              alt={'activity-icon-recently'}
            />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.activityWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.MENTAL_HEALTH_WIDGET:
        return (
          <React.Fragment>
            <img className={classes.imageMargin} src={StressIcon} alt={'stress-icon-recently'} />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.stressWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.WATER_WIDGET:
        return (
          <React.Fragment>
            <img className={classes.imageMargin} src={DrinkIcon} alt={'drink-icon-recently'} />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.drinkWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.SLEEP_WIDGET:
        return (
          <React.Fragment>
            <img className={classes.imageMargin} src={SleepIcon} alt={'sleep-icon-recently'} />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.sleepWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.BOWEL_WIDGET:
        return (
          <React.Fragment>
            <img className={classes.imageMargin} src={BowelIcon} alt={'bowel-icon-recently'} />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.bowelWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.STEPS_WIDGET:
        return (
          <React.Fragment>
            <img className={classes.imageMargin} src={StepsIcon} alt={'steps-icon-recently'} />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.stepsWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.BLOOD_PRESSURE_WIDGET:
        return (
          <React.Fragment>
            <img
              className={classes.imageMargin}
              src={BloodPressureIcon}
              alt={'bloodPressure-icon-recently'}
            />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.bloodPressureWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.BODY_MEASUREMENT_WIDGET:
        return (
          <React.Fragment>
            <img
              className={classes.imageMargin}
              src={BodyMeasurementIcon}
              alt={'body-measurement-recently'}
            />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.bodyMeasurementWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.BLOOD_GLUCOSE_WIDGET:
        return (
          <React.Fragment>
            <img
              className={classes.imageMargin}
              src={BloodGlucoseIcon}
              alt={'blood-glucose-recently'}
            />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.bloodGlucoseWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.BLOOD_LABS_WIDGET:
        return (
          <React.Fragment>
            <img
              className={classes.imageMargin}
              src={BloodGlucoseIcon}
              alt={'blood-labs-recently'}
            />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.bloodLabsWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );

      case LoggingWidgetType.BODY_PAIN_WIDGET:
        return (
          <React.Fragment>
            <img
              className={classes.imageMargin}
              src={BloodGlucoseIcon}
              alt={'body-pain-recently'}
            />
            <Typography variant={'subtitle2'}>
              {t('LogsPage.bodyPainWidget.recentlyAddedTitle')}
            </Typography>
          </React.Fragment>
        );
      default:
        return <></>;
    }
  };

  const renderAppropriateRow = (item: any): ReactNode => {
    switch (loggingWidgetType) {
      case LoggingWidgetType.BODY_WEIGHT_WIDGET:
        return (
          <BodyWeightRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => {
              props.changeState(formType);
            }}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.FOOD_WIDGET:
        return (
          <FoodRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => {
              props.changeState(formType);
            }}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.ACTIVITY_WIDGET:
        return (
          <ActivityRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.MENTAL_HEALTH_WIDGET:
        return (
          <StressRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => {
              props.changeState(formType);
            }}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.WATER_WIDGET:
        return (
          <DrinkRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => {
              props.changeState(formType);
            }}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.SLEEP_WIDGET:
        return (
          <SleepRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.BOWEL_WIDGET:
        return (
          <BowelRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.STEPS_WIDGET:
        return (
          <StepsRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.BLOOD_PRESSURE_WIDGET:
        return (
          <BloodPressureRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.BODY_MEASUREMENT_WIDGET:
        return (
          <BodyMeasurementRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.BLOOD_GLUCOSE_WIDGET:
        return (
          <BloodGlucoseRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.BLOOD_LABS_WIDGET:
        return (
          <BloodLabsRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );

      case LoggingWidgetType.BODY_PAIN_WIDGET:
        return (
          <BodyPainRecentlyAddedRow
            key={item.id}
            data={item}
            changeState={(formType) => props.changeState(formType)}
            selectLog={() => props.selectLog(item)}
            isMobile={props.isMobile}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <PaperWrapper className={classes.widgetDataContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.imageTextContainer}>{renderAppropriateHeader()}</div>
      </div>
      {loggedItems?.length > 0 && renderAppropriateRow(loggedItems[0])}

      {loggedItems?.length > 1 && (
        <React.Fragment>
          <div className={classes.line} />
          <ExpandableContainer className={classes.accordionItem}>
            <Accordion>
              <AccordionSummary
                expandIcon={<img src={CollapseThinIcon} alt={'thin-collapse-icon'} />}
              >
                <Typography variant={'body1'}>{t('LogsPage.moreResults')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loggedItemsFiltered.map((item) => renderAppropriateRow(item))}
              </AccordionDetails>
            </Accordion>
          </ExpandableContainer>
        </React.Fragment>
      )}
    </PaperWrapper>
  );
};

export default RecentlyAddedLogs;
