import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery, Box} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import Achievements from '../achievement/Achievements';
import {PaperWrapper} from '../paper-wrapper/PaperWrapper';
import BasicInfoItem from './BasicInfoItem';
// import PhaseItem from './PhaseItem';
import QuickInfo from './QuickInfo';
import TagsItem from './TagsItem';
import CoachingItem from './CoachingItem';
// import {selectAchievements} from '../../../store/selectors/homepage.selectors';
// import SubscriptionItemContainer from './SubscriptionItemContainer';
import ImageUpload from '../image-upload/ImageUpload';
import ImageUploadFilledIcon from '../../../assets/icons/quick-user-profile/image-upload-filled-icon.svg';
import UserIcon from '../../../assets/icons/side-menu-bar/connections-inactive-icon.svg';
import CollapseIcon from '../../../assets/icons/side-menu-bar/chevron-double-icon.svg';
import {callSaveUserObject, postUserImage} from '../../../store/actions/homepage/homepage-action';
import {checkUserRole} from '../../../utils/users-managment';
import {MOBILE_SIZE, TABLET_SMALL_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';
import useStyles from './quick-user-profile-overview.styles';
// import DataItem from './DataItem';

interface IQuickUserProfileProps {
  user: any;
  myProfile: boolean;
  smallScreens: boolean;
}
/*eslint-disable @typescript-eslint/no-empty-function*/
const QuickUserProfileOverview: React.FC<IQuickUserProfileProps> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens: isMobile || isMobileSize || isTabletSmallSize || isTabletBigSize,
  });
  const {user, myProfile, smallScreens} = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  // const loadedAchievements = useSelector(selectAchievements);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isProfileExpand, setIsProfileExpand] = useState<boolean>(false);
  const uploadImage = async (value: any) => {
    const imageData = new FormData();
    imageData.append('image', value || '');
    imageData.append('userid', user.id);
    await dispatch(postUserImage(imageData));
    await dispatch(callSaveUserObject(user.id));
  };

  return (
    <PaperWrapper className={ smallScreens ?  classes.smallScreenMargin : classes.fixMargin} >
      <div className={classes.mainCenterContent}>
        <div className={classes.content}>
          <Accordion>
            <div>
              <AccordionSummary
                onClick={() => setIsProfileExpand(!isProfileExpand)}
                expandIcon={<img src={CollapseIcon} alt={'collapse-icon'} />}
              >
                <Box mb={3}>
                  <Typography variant="h6">{t('Homepage.quickProfileUser.title')}</Typography>
                </Box>
              </AccordionSummary>
            </div>
            <AccordionDetails>
            <div className={`${classes.profileHeader} ${!isExpand ? 'isNotExpand' : ''}`}>
              <div className={classes.profileInfo}>
                {!myProfile ? (
                  <img
                    className={classes.userIcon}
                    src={user?.imageUrl || UserIcon}
                    alt="user-icon"
                  />
                ) : (
                  <ImageUpload
                    className={isExpand ? classes.imgUploadEdit : classes.imgUpload}
                    uploadEnabled={isExpand}
                    uploadIcon={ImageUploadFilledIcon}
                    imageUrl={user?.imageUrl || null}
                    changeData={(value: any) => uploadImage(value)}
                    canEdit={true}
                    isMobile={false}
                  />
                )}
                <div className={classes.profileInfoTxt}>
                  <Typography variant={'subtitle2'}>
                    {user?.firstName + ' ' + user?.lastName}
                  </Typography>
                  <Typography variant={'body2'} className={classes.title}>
                    {user && checkUserRole(user)}
                  </Typography>
                </div>
              </div>
            </div>
            {!isExpand && <QuickInfo user={user} />}
            </AccordionDetails>
          </Accordion>
         
          {/* <Achievements achievements={loadedAchievements} isMobile={isMobileOrTablet.isMobile} /> */}
          <Accordion>
            <div>
              <AccordionSummary
                onClick={() => setIsExpand(!isExpand)}
                expandIcon={<img src={CollapseIcon} alt={'collapse-icon'} />}
              >
                <Typography variant={'subtitle1'}>
                  {t('Homepage.quickProfileUser.moreInfo')}
                </Typography>
              </AccordionSummary>
            </div>
            <AccordionDetails>
              <BasicInfoItem user={user} isMobile={isMobileOrTablet.isMobile} />
              <div className={classes.line}>{}</div>
              {/* <PhaseItem user={user} myProfile={myProfile} isMobile={isMobileOrTablet.isMobile} /> */}
              {/* <div className={classes.line}>{}</div> */}
              {/* <SubscriptionItemContainer
                user={user}
                isAdmin={false}
                myProfile={myProfile}
                isMobile={isMobileOrTablet.isMobile}
              /> */}
              {/* <div className={classes.line}>{}</div> */}
              <CoachingItem
                user={user}
                myProfile={myProfile}
                isMobile={isMobileOrTablet.isMobile}
              />
              {/*<DataItem*/}
              {/*  label={t('Homepage.quickProfileUser.personalCoach')}*/}
              {/*  value={*/}
              {/*    user?.trainer ? user?.trainer?.firstName + ' ' + user?.trainer?.lastName : null*/}
              {/*  }*/}
              {/*/>*/}
              {!myProfile && (
                <>
                  <div className={classes.line}>{}</div>
                  <TagsItem user={user} myProfile={myProfile} />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </PaperWrapper>
  );
};

export default QuickUserProfileOverview;
