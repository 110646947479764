import React from 'react';
import {makeStyles, Theme} from '@material-ui/core';


interface Props {
  color: React.CSSProperties['color'];
  isMobileOrTablet: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    flexDirection: 'row',
    cursor: 'pointer',
    padding: 8,
    color: (props) => props.isMobileOrTablet ?'#000000' : theme.palette.secondary.dark,
    // color: (props) => props.isMobileOrTablet ? theme.palette.background.paper : theme.palette.secondary.dark,
  },
  connectionItemText: {
    marginLeft: 12,
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.blacksAndWhites.smokyBlack,
    },
  },
  connectionItemTextInactive: {
    marginLeft: 12,
    alignItems: 'center',
    color: theme.palette.secondary.dark,
  },
  connectionItemIcon: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    borderColor: (props) => props.color,
    border: 'solid',
  },
  itemContainerHover: {
    '&:hover': {
      backgroundColor: theme.palette.tintsAndShades.yaleBlue,
      color: `${theme.palette.blacksAndWhites.smokyBlack} !important`,
      borderRadius: 4,
    },
  },
  selectedConn: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
  },
  selectedTitle: {
    marginLeft: 12,
    alignItems: 'center',
    color: theme.palette.background.paper,
  },
  nameTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  unreadBlock: {
    display: 'inline-block',
    width: '7px',
    height: '7px',
    background: '#c82121',
    borderRadius: '50%',
    verticalAlign: 'top',
    marginLeft: '5px',
  }
}));
export default useStyles;
