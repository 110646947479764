import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import ImageUpload from '../../image-upload/ImageUpload';
import {ChipTag} from '../../chip-tag/ChipTag';
import ImageUploadIcon from '../../../../assets/icons/widgets/upload-image-icon.svg';
import DotsIcon from '../../../../assets/icons/app-nav-bar/three-dots-icon.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/icons/rest/close-icon.svg';
import * as weightLoggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {IFoodDto} from '../../../../dtos/IFoodDto';
import {splitCamelCase} from '../../../../utils/StringHelpers';
import {FormType} from '../../../../utils/enums';
import {stringToDateTimeFormatString} from '../../../../utils/date-time-utils';

import useStyles from './food-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';
import {ONLY_DATE_FORMAT, TIME_FORMAT} from '../../../../utils/constants';

interface FoodRecentlyAddedRowProps {
  data: IFoodDto;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
  isHistoryWidget?: boolean;
}

const FoodRecentlyAddedRow: React.FC<FoodRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isSkippedMeal: data.mealSkipped,
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleDismiss = () => {
    dispatch(weightLoggingActions.deleteFoodLogg(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };

  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };

  const handleShowButtonsMobile = () => {
    if (isMobile) {
      if (open) {
        return (
          <div className={`${commonClasses.buttonsContainer} ${classes.buttonsContainer}`}>
            <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
            <button className={classes.closeButton} onClick={() => setOpen(false)}>
              <CloseIcon title="close" />
            </button>
          </div>
        );
      }
      return (
        <div className={`${commonClasses.buttonsContainer} ${classes.buttonsContainer}`}>
          <button onClick={() => setOpen(true)}>
            <img src={DotsIcon} alt="see more" />
          </button>
        </div>
      );
    }

    return <div />;
  };

  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        <div className={!isMobile ? commonClasses.infoContainer : classes.width}>
          {!data.mealSkipped && !isMobile && (
            <div>
              <ImageUpload
                uploadEnabled={false}
                imageUrl={data.imageUrl}
                className={classes.uploadedImage}
                canEdit={false}
                uploadIcon={ImageUploadIcon}
                isMobile={false}
              />
            </div>
          )}

          <div className={classes.dataWithTags}>
            <div className={isMobile ? commonClasses.infoContainer : ''}>
              <div>
                {data?.mealType !== 'Other' && (
                   <Typography variant={'subtitle1'}>{splitCamelCase(data?.mealType)}</Typography>
                )}
               
                <span className={commonClasses.dateTime}>
                  {stringToDateTimeFormatString(data.date, ONLY_DATE_FORMAT)} <div />
                  {stringToDateTimeFormatString(data.date, TIME_FORMAT)}
                </span>
              </div>
              {handleShowButtonsMobile()}
            </div>

            {!data.mealSkipped && isMobile && (
              <div>
                <ImageUpload
                  uploadEnabled={false}
                  imageUrl={data.imageUrl}
                  className={classes.uploadedImage}
                  canEdit={false}
                  uploadIcon={ImageUploadIcon}
                  isMobile={false}
                />
              </div>
            )}

            <div className={classes.tagsContainer}>
              {data.tagsObjects.map((oneTag) => (
                <ChipTag
                  key={`tag-selected-${oneTag.id}`}
                  color={oneTag.color}
                  borderColor={oneTag.color}
                  hoverColor={oneTag.hoverColor}
                  textColor={oneTag.textColor}
                  name="chip"
                  id={oneTag.id || 0}
                  label={oneTag.name}
                />
              ))}
            </div>
          </div>

          {data.mealSkipped && (
            <div className={classes.skippedMeal}>
              <Typography variant={'caption'}>{t('LogsPage.foodWidget.skippedMeal')}</Typography>
            </div>
          )}
        </div>
        {!isMobile && (
          <div className={commonClasses.buttonsContainer}>
            <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
          </div>
        )}
      </div>
    </div>
  );
};
export default FoodRecentlyAddedRow;
