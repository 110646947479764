import {Typography} from '@material-ui/core';
import React, {Fragment, useEffect, useState} from 'react';
import useStyles from './common-onboarding.styles';
import {Button} from '../button/Button';
import {Gender, OnBoardingSteps} from '../../../utils/enums';
import {useTranslation} from 'react-i18next';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  IValidationRules,
  validate,
  validateField,
} from '../../../validation/Validation';
import {ValidationType} from '../../../validation/ValidationType';
import {TextField} from '../text-field/TextField';
import moment from 'moment';
import {MaskedTextField} from '../masked-text-field/MaskedTextField';
import {IOnBoardingUser} from '../../../models/IOnBoardingUser';
import classNames from 'classnames';
import {IKeyValuePair} from '../../../models/IKeyValuePair';
import {Select} from '../select/Select';
import {buildUrlLinkMiniOrange, validateUserProfileInfo} from '../../../api/users.api';

interface IProfileInfoOnBoardingProps {
  userData: IOnBoardingUser;
  onContinueClick: (step: OnBoardingSteps) => void;
  onUpdateUser: (user: IOnBoardingUser) => void;
}

interface IProfileInfoOnBoardingFormData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  gender: IKeyValuePair;
}

const validationRules: IValidationRules = {
  firstName: [{type: ValidationType.REQUIRED}],
  lastName: [{type: ValidationType.REQUIRED}],
  dateOfBirth: [{type: ValidationType.REQUIRED}, {type: ValidationType.BIRTH_DATE}],
  email: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_EMAIL}],
  phoneNumber: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_PHONE_NUMBER}],
  gender: [{type: ValidationType.REQUIRED}],
};

const ProfileInfoOnBoarding: React.FC<IProfileInfoOnBoardingProps> = (props) => {
  const classes = useStyles();
  const {userData, onContinueClick, onUpdateUser} = props;
  const [t] = useTranslation();
  const gender = Object.values(Gender);
  const [isLoading, setLoading] = useState(false);
  const [isLoginMode, setLoginMode] = useState(false);

  const [errors, setErrors] = useState<any>({});
  const [data, setData] = useState<IProfileInfoOnBoardingFormData>({
    firstName: '',
    lastName: '',
    dateOfBirth: moment().toString(),
    email: '',
    phoneNumber: '',
    gender: {
      key: '',
      value: '',
    },
  });

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const validateToSubmit = () => {
    if (data.gender.key === '') {
      return false;
    }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'gender') {
      setData({
        ...data,
        [name]: {key: value.key, value: value.value},
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    const initialObject: IProfileInfoOnBoardingFormData = {
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      dateOfBirth: userData?.dateOfBirth || moment().toString(),
      email: userData?.email || '',
      phoneNumber: userData?.phoneNumber || '',
      gender: {
        key: userData?.gender || '',
        value: userData?.gender || '',
      },
    };

    setData(initialObject);
  }, [props.userData]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      userData.firstName = data.firstName;
      userData.lastName = data.lastName;
      userData.dateOfBirth = data.dateOfBirth;
      userData.email = data.email;
      userData.phoneNumber = data.phoneNumber;
      userData.gender = data.gender.key;
      onUpdateUser(userData);

      //TODO 1: call backend to verify data if all right proceed to the next page
      const response = await validateUserProfileInfo(userData);
      if (response === 200) {
        onContinueClick(OnBoardingSteps.PASSWORD);
      }
    } catch (err: any) {
      const errorMessage = `User with email address ${userData.email} already exists.`;
      if (err.response?.status === 400 && err.response?.data?.error === errorMessage) {
        setLoginMode(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant={'h5'}>{t('OnBoardingPage.profileInfo.basicProfileInfo')}</Typography>
      {!isLoginMode && (
        <Fragment>
          <Typography variant={'body1'} className={classes.descriptiveText}>
            {t('OnBoardingPage.profileInfo.doubleCheckInfo')}
          </Typography>

          <div className={classes.oneRowDesktopContainer}>
            <TextField
              name={'firstName'}
              className={classes.normalTextField}
              containerClass={classes.textFieldContainerMargin}
              required={true}
              label={t('OnBoardingPage.profileInfo.firstName')}
              value={data.firstName}
              placeholder={t('OnBoardingPage.profileInfo.firstName')}
              onChange={(event) => handleChange(event)}
              error={hasError(errors, 'firstName')}
              helperText={getError(errors, 'firstName')}
              onBlur={() => validateFormField('firstName')}
            />

            <TextField
              name={'lastName'}
              className={classes.normalTextField}
              containerClass={classes.textFieldContainerMargin}
              required={true}
              label={t('OnBoardingPage.profileInfo.lastName')}
              value={data.lastName}
              placeholder={t('OnBoardingPage.profileInfo.lastName')}
              onChange={(event) => handleChange(event)}
              error={hasError(errors, 'lastName')}
              helperText={getError(errors, 'lastName')}
              onBlur={() => validateFormField('lastName')}
            />
          </div>

          <div className={classes.oneRowDesktopContainer}>
            <MaskedTextField
              name={'dateOfBirth'}
              className={classNames(classes.maskedPickerWidth, classes.dateOfBirthWidth)}
              containerClass={classes.textFieldContainerMargin}
              required={true}
              label={t('OnBoardingPage.profileInfo.dateOfBirth')}
              value={data.dateOfBirth}
              placeholder={t('OnBoardingPage.profileInfo.dateOfBirthPlaceholder')}
              onChange={(event) => handleChange(event)}
              error={hasError(errors, 'dateOfBirth')}
              helperText={getError(errors, 'dateOfBirth')}
              onBlur={() => validateFormField('dateOfBirth')}
              mask="Md-Dd-dddd"
              formatChars={{
                M: '[0-1]',
                D: '[0-3]',
                d: '[0-9]',
              }}
            />

            <Select
              name={'gender'}
              className={classes.normalTextField}
              containerClass={classes.textFieldContainerMargin}
              required={true}
              options={gender.map((item) => ({
                key: item,
                value: item,
              }))}
              value={data.gender}
              onChange={handleChange}
              label={t('OnBoardingPage.profileInfo.gender')}
            />
          </div>

          <div className={classes.oneRowDesktopContainer}>
            <TextField
              name={'email'}
              type={'email'}
              className={classNames(classes.normalTextField, classes.emailTextField)}
              containerClass={classes.textFieldContainerMargin}
              required={true}
              label={t('OnBoardingPage.profileInfo.email')}
              value={data.email}
              placeholder={t('OnBoardingPage.profileInfo.email')}
              onChange={(event) => handleChange(event)}
              error={hasError(errors, 'email')}
              helperText={getError(errors, 'email')}
              onBlur={() => validateFormField('email')}
            />

            <MaskedTextField
              name={'phoneNumber'}
              className={classes.maskedPickerWidth}
              containerClass={classes.textFieldContainerMargin}
              required={true}
              label={t('OnBoardingPage.profileInfo.phoneNumber')}
              value={data.phoneNumber}
              placeholder={t('OnBoardingPage.profileInfo.phoneNumberPlaceholder')}
              onChange={(event) => handleChange(event)}
              error={hasError(errors, 'phoneNumber')}
              helperText={getError(errors, 'phoneNumber')}
              onBlur={() => validateFormField('phoneNumber')}
              mask="999-999-9999"
            />
          </div>

          <Button
            id="continue"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={!validateToSubmit() || isLoading}
            className={classes.bottomButton}
          >
            {t('OnBoardingPage.continue')}
          </Button>
        </Fragment>
      )}

      {isLoginMode && (
        <Fragment>
          <Button
            id="login"
            onClick={() => {
              buildUrlLinkMiniOrange();
            }}
            loading={isLoading}
            disabled={isLoading}
            className={classes.bottomButton}
          >
            {t('OnBoardingPage.profileInfo.login')}
          </Button>
        </Fragment>
      )}
    </div>
  );
};
export default ProfileInfoOnBoarding;
