import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    width: 150,
  },
}));

export default useStyles;
