import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  posterContainer: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#FFF',
    padding: '30px',
    '& .closePopup': {
      position: "absolute",
      top: "20px",
      right: "20px",
      zIndex: "1",
      boxShadow: 'unset',
      background: '#807c7c',
      padding: '15px',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      minWidth: 'auto',
    },
  },
  smallScreenContainer:{

  },
  hlqPoster:{
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#FFF',
  },
  modalAppointment: {
    '& .MuiBackdrop-root': {
      background: '#FFF'
    }
  },
  paperContent: {
    width: '100%',
    minHeight: '100%',
    paddingBottom: '40px',
    maxWidth: 411,
    // padding: '32px 40px 32px 40px',
    '& h1': {
        fontSize: '40px',
        fontWeight: 'normal',
        lineHeight: '1.0'
    },
    '& > p': {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.25px',
        marginBottom: '30px'
    },
    '& .errorP': {
      color: '#FF0000'
    },
    '& .fieldBox': {
        '& label': {
            fontSize: '12px',
            color: '#5C5754'
        },
        '& .code-fieldBox': {
            display: 'flex',
            marginBottom: '15px',
            '& input':{
                height: '40px',
                fontSize: '24px',
                fontWeight: 'bold'
            }
        }
    },
  },
  normalTextField: {
    width: '100%',
  },
  dateOfBirthWidth: {
    width: '100%',
    boxSizing: 'content-box'
  },
  maskedPickerWidth: {
    width: '100%',
  },
  textFieldContainerMargin: {
    marginBottom: 16,
    marginRight: 0,
  },
  heightContainer: {
    display: 'flex',
    // marginLeft: (props) => (props?.isMobile ? 0 : 24),
    // marginTop: (props) => (props?.isMobile ? 24 : 0),
    // width: (props) => (props.isMobile ? '100%' : ''),
  },
  
    heightTextField: {
    width: 95,
    marginRight: '8px !important',
  },
  heightTextFieldRight: {
    width: 95,
    marginRight: '8px !important',
    marginTop: 20,
  },
  unit: {
    marginTop: 20,
  },
  bottomButton: {
    marginTop: '20px',
    width: '100%',
    height: '38px'
  }
}));

export default useStyles;
