import * as homepageActions from './homepage-types';
import {IConversationDto} from '../../../dtos/IConversationDto';
import {IConversations} from '../../../models/IConversations';
import {Dispatch} from 'redux';
import {ISnackbarState} from '../../../common/components/snackbar/Snackbar';
import {IUserInsightsDto} from '../../../dtos/IInsightsDto';
import {getUserInsights} from '../../../api/user-insights.api';
import {IValueDate} from '../../../models/IValueDate';
import {IUserDto} from '../../../dtos/IUserDto';
import {ICoachingHistoryDto} from '../../../dtos/ICoachingHistoryDto';
import {IParticipantDto} from '../../../dtos/IParticipantDto';
import {IInsightRow} from '../../../models/IInsights';
import i18n from 'i18next';
import {IPhasesDto} from '../../../dtos/IPhasesDto';
import {IPhase} from '../../../models/IPhase';
import {getPhases} from '../../../api/side-menu-bar.api';
import {getCurrentTimeZone} from '../../../utils/date-time-utils';
import {getNotifications} from '../../../api/notifications.api';
import {setUserInfo} from '../../../utils/locale-storage-service';
import {
  editUserDatabase,
  editUserPhaseDatabase,
  // editUserBasicInfoDatabase,
  getAchievements,
  getMeasurementUnit,
  getUser,
  postUserImageDatabase,
  editUserInitialWeightDatabase,
  editUserTagsDatabase,
  getCoaches,
  editPersonalCoach,
  manageUserSubscribe,
  updateOnboardUserApi,
  // editUserBasicInfoDatabase,
  // getUserLinks,
} from '../../../api/users.api';
import {
  addUserNoteDatabase,
  getNotes,
  deleteNoteDatabase,
  editNoteDatabase,
} from '../../../api/notes.api';
import {
  getSecondaryCoachAssigment,
  postSecondaryCoachAll,
  putSecondaryCoachOne,
  getMySecondaryAssignments,
  getSecondaryCoachForMember,
  endSecondaryCoaching,
  getCoachingHistory,
} from '../../../api/coaches.api';
import Snackbar from '../../../common/components/notistack/SnackbarUtils';
import {IUser} from '../../../models/IUser';
import {IAchievementsDto} from '../../../dtos/IAchievementsDto';
import * as userActions from '../users/users-action';
import {
  // ACTIVITY,
  BOWEL_MOVEMENTS,
  // FOOD,
  SLEEP,
  STEPS,
  STRESS,
  WATER,
  WEIGHT,
  levelOne,
  levelTwo,
  levelThree,
  prepPhase,
  // GLUCOSE,
} from '../../../utils/constants';
import {
  deleteAppointmentDatabase,
  // getAppointmentsLogs,
  editAppointmentDatabase,
  getScheduleAppointmentsLogs,
} from '../../../api/appointment.api';
import {
  ISecondaryCoachAssignDto,
  IPrimaryCoachAssignDto,
  IEndNowDto,
  ISearchCoachesDto,
} from '../../../dtos/ISecondaryCoachAssignDto';
import {IAppointmentDto} from '../../../dtos/IAppointmentDto';
import {AppointmentType} from '../../../utils/enums';
import {IMeasurementUnit} from '../../../models/IMeasurementUnit';
import {INotificationDto} from '../../../dtos/INotificationDto';
import {IBasicInfoDto} from '../../../dtos/IBasicInfoDto';
import {
  getConversationsServer,
  addParticipant,
  deleteParticipant,
  getParticipantById,
  getPaginationConversations,
} from '../../../api/chat.api';
import {getHistoryLogs} from '../../../api/history-logs.api';
import TwilioService from '../../../services/TwilioService';
import {IInitialWeightDto} from '../../../dtos/IInitialWeightDto';
import {IUserTagsDto} from '../../../dtos/IUserTagsDto';
import {INotesPaginationDto, INotesDto} from '../../../dtos/INotesDto';
import {INoteRequestDataDto} from '../../../dtos/INoteRequestDataDto';
import {IHistoryLogsDto} from '../../../dtos/IHistoryLogsDto';

export interface ILoadConversations {
  type: typeof homepageActions.LOAD_CONVERSATIONS;
  payload: IConversationDto | null;
}

export interface ISelectConversation {
  type: typeof homepageActions.SELECT_CONVERSATION;
  payload: IConversationDto | null | undefined;
}

export interface IRemoveConversation {
  type: typeof homepageActions.REMOVE_CONVERSATION;
  payload: IConversationDto | null;
}

export interface IChangeStateSnackbar {
  type: typeof homepageActions.CHANGE_STATE_SNACKBAR;
  payload: ISnackbarState;
}

export interface ILoadUserInsights {
  type: typeof homepageActions.LOAD_USER_INSIGHTS;
  payload: IUserInsightsDto;
}

export interface ILoadPhases {
  type: typeof homepageActions.LOAD_USER_PHASES;
  payload: IPhasesDto[];
}

export interface ISelectMember {
  type: typeof homepageActions.SELECT_MEMBER;
  payload: IUser | IUserDto;
}

export interface ISelectCoach {
  type: typeof homepageActions.SELECT_COACH;
  payload: IUser | IUserDto | null;
}

export interface ISelectMeasurementUnit {
  type: typeof homepageActions.SELECT_MEASUREMENT_UNIT;
  payload: IMeasurementUnit;
}

export interface ILoadAchievements {
  type: typeof homepageActions.LOAD_ACHIEVEMENTS;
  payload: IAchievementsDto[];
}
export interface IEditUser {
  type: typeof homepageActions.EDIT_USER;
  payload: IUser;
}

export interface ILoadUserAppointments {
  type: typeof homepageActions.LOAD_USER_APPOINTMENTS;
  payload: {
    loadedAppointments: IAppointmentDto[];
    scheduledAppointments: AppointmentType[];
  };
}

export interface ILoadMemberAppointments {
  type: typeof homepageActions.LOAD_MEMBER_APPOINTMENTS;
  payload: IAppointmentDto[];
}

export interface ILoadNotifications {
  type: typeof homepageActions.LOAD_NOTIFICATIONS;
  payload: INotificationDto[];
}

export interface ILoadNotesPagination {
  type: typeof homepageActions.LOAD_NOTES_PAGINATION;
  payload: INotesPaginationDto;
}

export interface ILoadBackendConversations {
  type: typeof homepageActions.LOAD_BACKEND_CONVERSATION;
  payload: IConversations[] | null;
}

export interface ILoadAllSuperCoachConversations {
  type: typeof homepageActions.LOAD_ALL_SUPERCOACH_CONVERSATIONS;
  payload: IConversationDto[];
}
export interface ILoadCoachConversations {
  type: typeof homepageActions.LOAD_COACH_CONVERSATIONS;
  payload: IConversations[] | null;
}

export interface IEditUserBasicInfo {
  type: typeof homepageActions.EDIT_USER_BASIC_INFO;
  payload: IBasicInfoDto | null;
}

export interface ISaveSelectedConversationId {
  type: typeof homepageActions.SAVE_SELECTED_CONVERSATION_ID;
  payload: string | null;
}

export interface ILoadHistoryLogs {
  type: typeof homepageActions.LOAD_HISTORY_LOGS;
  payload: IHistoryLogsDto[] | null;
}

// export const loadConversations = (
//   data: IConversationDto,
// ): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
//   return async (dispatch: Dispatch) => {
//     dispatch({type: homepageActions.SELECT_CONVERSATION, payload: data});
//   };
// };

export interface ILoading {
  type: typeof homepageActions.LOADING;
  payload: boolean;
}

export interface IParticipantIndex {
  type: typeof homepageActions.PARTICIPANT_INDEX;
  payload: number;
}
export interface IIsMobileDownload {
  type: typeof homepageActions.UPDATE_IS_MOBILE_DOWNLOAD;
  payload: boolean;
}

export const selectConversation = (
  conversation: IConversationDto | null | undefined,
): ISelectConversation => {
  return {
    type: homepageActions.SELECT_CONVERSATION,
    payload: conversation,
  };
};

export const removeConversation = (conversation: IConversationDto): IRemoveConversation => {
  return {
    type: homepageActions.REMOVE_CONVERSATION,
    payload: conversation,
  };
};

export const changeStateSnackbar = (snackbarState: ISnackbarState): IChangeStateSnackbar => {
  return {
    type: homepageActions.CHANGE_STATE_SNACKBAR,
    payload: snackbarState,
  };
};

export const loadUserPhases = (): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const phases: IPhasesDto[] = getState().home.loadedPhases;
    if (phases?.length > 0) dispatch({type: homepageActions.LOAD_USER_PHASES, payload: phases});
    else {
      const loadedPhases = await getPhases();
      dispatch({type: homepageActions.LOAD_USER_PHASES, payload: loadedPhases});
    }
  };
};

export const saveSelectedMember = (data: IUser): ISelectMember => {
  return {type: homepageActions.SELECT_MEMBER, payload: data};
};

export const saveSelectedCoach = (data: IUser | null): ISelectCoach => {
  return {type: homepageActions.SELECT_COACH, payload: data};
};

export const editUserPhase = (data: IPhase): (() => Promise<boolean>) => {
  return async () => {
    try {
      await editUserPhaseDatabase(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editUserInitialWeight = (data: IInitialWeightDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await editUserInitialWeightDatabase(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const addUserNote = (data: INoteRequestDataDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await addUserNoteDatabase(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const callSaveUserObject = (
  id: string,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const editedUser: IUserDto = await getUser(id);
    const currentUser = getState().users.currentUser;
    const user: IUser = await userActions.saveUserObject(editedUser);
    if (id === currentUser.id) {
      setUserInfo(user);
      await dispatch(userActions.saveUserData(user));
    } else dispatch(saveSelectedMember(user));
  };
};

export const editUser = (data: IUser): (() => Promise<boolean>) => {
  return async () => {
    try {
      await editUserDatabase(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const subscribeUserManage = (data: IUser): (() => Promise<boolean>) => {
  return async () => {
    try {
      await manageUserSubscribe(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editUserTags = (data: IUserTagsDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await editUserTagsDatabase(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editUserBasicInfo = (data: IBasicInfoDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      //  await editUserBasicInfoDatabase(data);
      const res = await updateOnboardUserApi(data);
     
      if (res) {
        if (res?.statusId === 1) {
          Snackbar.success(i18n.t(res?.responseMessage));
          if(res?.additionalData){
            localStorage.setItem('id-token', res?.additionalData);
          }
        }
        if (res?.statusId === 12) {
          Snackbar.error(
            i18n.t(
              'Phone number you entered is not a valid mobile phone number. Please ensure your number does not have any spaces. If you don’t have a mobile number please contact support in the button on the bottom right.',
            ),
          );
        }
        if (res?.statusId === 3) {
          Snackbar.error(
            i18n.t('Phone number is used by another account. Enter the unique phone number.'),
          );
        }
        if (res?.statusId === 2) {
          Snackbar.error(
            i18n.t('Email is used by another account. Enter the unique email address.'),
          );
        }
        if (res?.statusId === 0) {
          Snackbar.error(
            i18n.t('Phone number is used by another account. Enter the unique phone number.'),
          );
          // setApiEmailError({
          //   isError: false,
          //   errorMsg: 'Email is used by another account. Enter the unique email address.',
          // });
        }
        if (res?.statusId === 11) {
          Snackbar.error(i18n.t('Something went wrong. Please try again.'));
        }
        if (res?.statusId === 4) {
          Snackbar.error(
            i18n.t('Two diffrent User exists one with email address and another with phone number'),
          );
        }
        if (res?.statusId === 13) {
          Snackbar.error(i18n.t(res?.responseMessage));
        }
      } else {
        // console.log('');
      }
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const selectMember = (id: string | null): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    if (id === null) {
      dispatch({type: homepageActions.SELECT_MEMBER, payload: null});
      return;
    }
    const selectedMember = await getUser(id);
    const user: IUser = userActions.saveUserObject(selectedMember);
    dispatch(saveSelectedMember(user));
  };
};

export const selectCoach = (id: string | null): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    if (id === null) {
      dispatch({type: homepageActions.SELECT_MEMBER, payload: null});
      return;
    }
    const selectedCoach = await getUser(id);
    const user: IUser = userActions.saveUserObject(selectedCoach);
    dispatch(saveSelectedCoach(user));
  };
};

export const selectMeasurementUnit = (
  id: number | null,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    if (id === null) {
      dispatch({type: homepageActions.SELECT_MEASUREMENT_UNIT, payload: null});
      return;
    }
    const selectedMeasurementUnit = await getMeasurementUnit(id);
    dispatch({type: homepageActions.SELECT_MEASUREMENT_UNIT, payload: selectedMeasurementUnit});
  };
};

const prepareInsightRow = (name: string, data: IValueDate[], isMobile?: boolean) => {
  const insightRow: IInsightRow = {
    name: i18n.t(isMobile ? `insights.${name}Mobile` : `insights.${name}`),
    nameKey: name,
    before1: '',
    before2: '',
    before3: '',
    today: '',
  };

  if (name === STEPS || name === SLEEP || name === BOWEL_MOVEMENTS) {
     insightRow.before3 = data[0]?.value;
    insightRow.before2 = data[1]?.value;
    insightRow.before1 = data[2]?.value;
    insightRow.today = data[3]?.value;
  } else if (name === STRESS) {
     insightRow.before3 = data[0]?.value;
    insightRow.before2 = data[1]?.value;
    insightRow.before1 = data[2]?.value;
    insightRow.today = data[3]?.value;

    // const splitter = ' | ';
    //insightRow.before3 = data[0].value != '' ? i18n.t(`stressMarks.${data[0].value}`) : '';
    // insightRow.before3 += data[0].value != '' && data[0].value2 != '' ? splitter : '';
    // insightRow.before3 += data[0].value2 != '' ? i18n.t(`moodMarks.${data[0].value2}`) : '';

    //insightRow.before2 = data[1].value != '' ? i18n.t(`stressMarks.${data[1].value}`) : '';
    // insightRow.before2 += data[1].value != '' && data[1].value2 != '' ? splitter : '';
    // insightRow.before2 += data[1].value2 != '' ? i18n.t(`moodMarks.${data[1].value2}`) : '';

    //insightRow.before1 = data[2].value != '' ? i18n.t(`stressMarks.${data[2].value}`) : '';
    // insightRow.before1 += data[2].value != '' && data[2].value2 != '' ? splitter : '';
    // insightRow.before1 += data[2].value2 != '' ? i18n.t(`moodMarks.${data[2].value2}`) : '';

    //insightRow.today = data[3].value != '' ? i18n.t(`stressMarks.${data[3].value}`) : '';
    // insightRow.today += data[3].value != '' && data[3].value2 != '' ? splitter : '';
    // insightRow.today += data[3].value2 != '' ? i18n.t(`moodMarks.${data[3].value2}`) : '';
  } else {
    insightRow.before3 = data[0]?.value != '' ? data[0]?.value + ' ' + data[0]?.unit : '';
    insightRow.before2 = data[1]?.value != '' ? data[1]?.value + ' ' + data[1]?.unit : '';
    insightRow.before1 = data[2]?.value != '' ? data[2]?.value + ' ' + data[2]?.unit : '';
    insightRow.today = data[3]?.value != '' ? data[3]?.value + ' ' + data[3]?.unit : '';
  }

  return insightRow;
};

export const saveLoadedAchievement = (data: IAchievementsDto[]): ILoadAchievements => {
  return {type: homepageActions.LOAD_ACHIEVEMENTS, payload: data};
};

export const loadUserAchievements = (id: string): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const achievements = await getAchievements(id);
    dispatch(saveLoadedAchievement(achievements));
  };
};

export const loadUsersInsights = (
  userId?: string,
  // isMobile?: boolean,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;

    const response = await getUserInsights(userId || userData.id);
    const insights: IInsightRow[] = [];
    let insightRow: IInsightRow = prepareInsightRow(WEIGHT, response.weight);
    insights.push(insightRow);

    // insightRow = prepareInsightRow(FOOD, response.food);
    // insights.push(insightRow);

    insightRow = prepareInsightRow(WATER, response.water);
    insights.push(insightRow);

    insightRow = prepareInsightRow(SLEEP, response.sleep);
    insights.push(insightRow);

    // insightRow = prepareInsightRow(ACTIVITY, response.activity);
    // insights.push(insightRow);

    // insightRow = prepareInsightRow(STEPS, response.steps);
    // insights.push(insightRow);

    // insightRow = prepareInsightRow(BOWEL_MOVEMENTS, response.bowelMovement, isMobile);
    // insights.push(insightRow);

    // insightRow = prepareInsightRow(STRESS, response.stress, isMobile);
    // insights.push(insightRow);

    // insightRow = prepareInsightRow(GLUCOSE, response.bloodGlucose, isMobile);
    // insights.push(insightRow);
    //  console.log("response", response);
    const headers: string[] = [];
    headers.push(i18n.t(`insights.day`));
    headers.push(response.food[0].dayOfTheWeek.slice(0, 3));
    headers.push(response.food[1].dayOfTheWeek.slice(0, 3));
    headers.push(response.food[2].dayOfTheWeek.slice(0, 3));
    headers.push(i18n.t(`insights.today`));

    response.insights = {
      headers,
      rows: insights,
    };

    dispatch({type: homepageActions.LOAD_USER_INSIGHTS, payload: response});
  };
};

export const saveUserAppointments = (
  loadedAppointments: IAppointmentDto[],
  scheduledAppointments: AppointmentType[],
): ILoadUserAppointments => {
  return {
    type: homepageActions.LOAD_USER_APPOINTMENTS,
    payload: {
      loadedAppointments: loadedAppointments,
      scheduledAppointments: scheduledAppointments,
    },
  };
};
export const saveMemberAppointments = (
  loadedAppointments: IAppointmentDto[],
): ILoadMemberAppointments => {
  return {
    type: homepageActions.LOAD_MEMBER_APPOINTMENTS,
    payload: loadedAppointments,
  };
};

export const loadUserAppointments = (
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    // const loadedAppointments = await getAppointmentsLogs(id ? id : userData.id);
    // const linksForAppointments = await getUserLinks(id ? id : userData.id);
    const newAppointmentsData = await getScheduleAppointmentsLogs(id ? id : userData.id);
    // console.log("newAppointmentsData", newAppointmentsData);
    // const filteredArray = linksForAppointments?.filter((obj: { linkTypeId: number; }) => [1, 2, 3, 4, 5, 7].includes(obj.linkTypeId));
    // const allAppointments = await
    //   const scheduledAppointments = [
    //     AppointmentType.INTRO,
    //     AppointmentType.KICK_OFF,
    //     AppointmentType.Level1,
    //     AppointmentType.Level2,
    //     AppointmentType.Week1,
    //     AppointmentType.LiveAndTNC,
    //   ];
    // // const scheduledAppointment = loadedAppointments?.filter((obj: any) => scheduledAppointments.includes(obj.appointmentType));
    // loadedAppointments.forEach((oneAppointment) => {
    //   scheduledAppointments = scheduledAppointments.filter(
    //     (oneScheduled) => oneScheduled === oneAppointment.appointmentType,
    //   );
    // })
    dispatch(saveUserAppointments(newAppointmentsData));
  };
};

export const deleteUserAppointment = (id: string): ((dispatch: Dispatch) => Promise<void>) => {
  return async () => {
    await deleteAppointmentDatabase(id);
  };
};

export const editAppointment = (
  appointment: IAppointmentDto,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await editAppointmentDatabase(appointment);
    // eslint-disable-next-line
    //@ts-ignore
    await dispatch(loadUserAppointments(appointment.userId));
  };
};

export const loadNotifications = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const timeZone = getCurrentTimeZone();
    const loadedNotifications = await getNotifications(timeZone);
    dispatch({type: homepageActions.LOAD_NOTIFICATIONS, payload: loadedNotifications});
  };
};

export const getNotesPagination = async (
  id: string,
  pageNumber: number,
  pageSize: number,
): Promise<INotesPaginationDto> => {
  return await getNotes(id, pageNumber, pageSize);
};

export const loadNotesPagination = (
  id: string,
  pageNumber: number,
  pageSize: number,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const notes = await getNotesPagination(id, pageNumber, pageSize);
    dispatch({type: homepageActions.LOAD_NOTES_PAGINATION, payload: notes});
  };
};

export const deleteNote = (
  id: number,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      await deleteNoteDatabase(id);
      const selectedMember = getState().home.selectedMember;
      const notes = await getNotesPagination(selectedMember?.id, 0, 3);
      dispatch({type: homepageActions.LOAD_NOTES_PAGINATION, payload: notes});
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editNote = (note: INotesDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await editNoteDatabase(note);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const postUserImage = (data: FormData): (() => Promise<boolean>) => {
  return async () => {
    try {
      await postUserImageDatabase(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const getConversartionByUserId = async (
  data: string,
): Promise<IConversationDto[] | null> => {
  try {
    const result = await getConversationsServer(false, data);
    return result;
  } catch (err) {
    return null;
  }
};

export const loadAllSuperCoachConversationsBackend = (
  pageSize: number,
  nextPageUrl?: string | null,
  userId?: string,
): Promise<IConversationDto[]> => {
  const newConversations = getPaginationConversations(pageSize, nextPageUrl, userId);
  return newConversations;
};

export const loadAllSuperCoachConversations = (
  pageSize: number,
  nextPageUrl?: string | null,
  userId?: string,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const conversationsFromRedux = getState().home.allSuperCoachConversations;
    const loadedConversations = await loadAllSuperCoachConversationsBackend(
      pageSize,
      nextPageUrl,
      userId,
    );
    const newConversations = conversationsFromRedux;
    if(loadedConversations){
      loadedConversations?.forEach((conv: IConversationDto) => {
        newConversations.push(conv);
      });
    }
   
    dispatch({
      type: homepageActions.LOAD_ALL_SUPERCOACH_CONVERSATIONS,
      payload: newConversations,
    });
  };
};

export const loadBackendConversations = (): ((
  dispatch: Dispatch,
  getState: any,
) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const user = getState().users?.currentUser;
    if(user){
      const loadedConversations = await getConversationsServer(false, user?.id);
      if(loadedConversations){
        const editedConversations = [];
        const prepPhaseArr: IConversations = {conversations: [], title: ''};
        prepPhaseArr.conversations = loadedConversations?.filter((conv) => conv?.phaseLevelID === 1);
        prepPhaseArr.title = prepPhase?.toUpperCase();
        const level1Arr: IConversations = {conversations: [], title: ''};
        level1Arr.conversations = loadedConversations?.filter((conv) => conv?.phaseLevelID === 2);
        level1Arr.title = levelOne?.toUpperCase();
        const level2Arr: IConversations = {conversations: [], title: ''};
        level2Arr.conversations = loadedConversations?.filter((conv) => conv?.phaseLevelID === 3);
        level2Arr.title = levelTwo?.toUpperCase();
        const level3Arr: IConversations = {conversations: [], title: ''};
        level3Arr.conversations = loadedConversations?.filter((conv) => conv?.phaseLevelID === 4);
        level3Arr.title = levelThree?.toUpperCase();
        editedConversations?.push(prepPhaseArr, level1Arr, level2Arr, level3Arr);
        dispatch({type: homepageActions.LOAD_BACKEND_CONVERSATION, payload: editedConversations});
        TwilioService.backendConversations = loadedConversations;
      }
   
    }
  };
};

export const loadCoachConversations = (
  trainerId: string,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    const loadedConversations = await getConversationsServer(false, trainerId);
    const editedConversations = [];
    if(loadedConversations){
      const prepPhaseArr: IConversations = {conversations: [], title: ''};
      prepPhaseArr.conversations = loadedConversations.filter((conv) => conv.phaseLevelID === 1);
      prepPhaseArr.title = prepPhase.toUpperCase();
      const level1Arr: IConversations = {conversations: [], title: ''};
      level1Arr.conversations = loadedConversations.filter((conv) => conv.phaseLevelID === 2);
      level1Arr.title = levelOne.toUpperCase();
      const level2Arr: IConversations = {conversations: [], title: ''};
      level2Arr.conversations = loadedConversations.filter((conv) => conv.phaseLevelID === 3);
      level2Arr.title = levelTwo.toUpperCase();
      const level3Arr: IConversations = {conversations: [], title: ''};
      level3Arr.conversations = loadedConversations.filter((conv) => conv.phaseLevelID === 4);
      level3Arr.title = levelThree.toUpperCase();
      editedConversations.push(prepPhaseArr, level1Arr, level2Arr, level3Arr);
    }
    dispatch({type: homepageActions.LOAD_COACH_CONVERSATIONS, payload: editedConversations});
  };
};

export const addParticipantToConversation = async (
  conversationId: string | undefined,
  userId: string,
): Promise<boolean> => {
  try {
    const result = await addParticipant(conversationId, userId);
    return result;
  } catch (err: any) {
    Snackbar.error(err.message);
    return false;
  }
};

export const deleteParticipantFromConversation = (
  conversationId: string,
  userId: string,
  activeConversations?: boolean,
): ((dispatch: Dispatch) => Promise<any>) => {
  return async (dispatch: Dispatch) => {
    await deleteParticipant(conversationId, userId);
    let loadedConversations: IConversationDto[];
    if (activeConversations) {
      loadedConversations = [];
      const backendConversations = await loadAllSuperCoachConversationsBackend(10, '', userId);
      backendConversations.forEach((conv: IConversationDto) => {
        loadedConversations?.push(conv);
      });
    } else {
      loadedConversations = await getConversationsServer();
    }
    dispatch({
      type: homepageActions.LOAD_ALL_SUPERCOACH_CONVERSATIONS,
      payload: loadedConversations,
    });
  };
};

export const getParticipant = async (userId: string): Promise<IParticipantDto> => {
  const user = await getParticipantById(userId);
  return user;
};

let timer: any = null;
const forceClosingLoader = () => {
  clearInterval(timer);
  return {
    type: homepageActions.LOADING,
    payload: false,
  };
};

export const setLoading = (isLoading: boolean): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    clearInterval(timer);
    if (isLoading) {
      timer = setInterval(() => dispatch(forceClosingLoader()), 10000);
    }

    dispatch({type: homepageActions.LOADING, payload: isLoading});
  };
};

export const setParticipantReadIndex = (index: number): IParticipantIndex => {
  return {
    type: homepageActions.PARTICIPANT_INDEX,
    payload: index,
  };
};

export const assignSecondaryCoach = (data: ISecondaryCoachAssignDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await postSecondaryCoachAll(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editOneSecondaryCoach = (data: ISecondaryCoachAssignDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await putSecondaryCoachOne(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const getSecondaryCoaches = async (data: string): Promise<ISecondaryCoachAssignDto[]> => {
  return await getSecondaryCoachAssigment(data);
};

export const getAllCoaches = async (data: ISearchCoachesDto): Promise<IUserDto[]> => {
  return await getCoaches(data);
};

export const getOneSecondaryCoach = async (id: string): Promise<ISecondaryCoachAssignDto> => {
  return await getSecondaryCoachForMember(id);
};

export const getMySecondaryAssign = async (id: string): Promise<ISecondaryCoachAssignDto[]> => {
  return await getMySecondaryAssignments(id);
};

export const editUsersPersonalCoach = (data: IPrimaryCoachAssignDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await editPersonalCoach(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const endNowSecondaryCoach = (data: IEndNowDto): (() => Promise<boolean>) => {
  return async () => {
    try {
      await endSecondaryCoaching(data);
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const loadCoachingHistory = async (id: string): Promise<ICoachingHistoryDto[]> => {
  return await getCoachingHistory(id);
};

export const saveSelectedConversationId = (
  id: string | null,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    dispatch({type: homepageActions.SAVE_SELECTED_CONVERSATION_ID, payload: id});
  };
};

export const resetHistoryLogs = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    dispatch({type: homepageActions.LOAD_HISTORY_LOGS, payload: []});
  };
};

export const loadHistoryLogs = (
  id: string,
  pageSize: number,
  pageNumber: number,
): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const historyLogs = getState().home.historyLogs;
    const newLogs: IHistoryLogsDto[] = [].concat(historyLogs);
    const logs = await getHistoryLogs(id, pageSize, pageNumber);
    logs.forEach((log: IHistoryLogsDto) => newLogs.push(log));
    dispatch({type: homepageActions.LOAD_HISTORY_LOGS, payload: newLogs});
  };
};

export const updateIsMobileDownload = (
  isMobileDownload: boolean,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    dispatch({type: homepageActions.UPDATE_IS_MOBILE_DOWNLOAD, payload: isMobileDownload});
  };
};
