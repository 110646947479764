import {RoleType} from './enums';
import {IUser} from '../models/IUser';

export const getGeneralRoles = (): RoleType[] => [RoleType.MEMBER, RoleType.TRAINER];
export const getAdminRoles = (): RoleType[] => [RoleType.ADMIN];
export const getTrainerRoles = (): RoleType[] => [RoleType.TRAINER];
export const getSuperCoachRoles = (): RoleType[] => [RoleType.SUPER_COACH];
export const getAllRoles = (): RoleType[] => [RoleType.MEMBER, RoleType.TRAINER, RoleType.ADMIN];
export const hasAdminRoles = (currentUser: IUser): boolean =>
  getAdminRoles().some((role) => currentUser.roles.includes(role));
export const hasSuperCoachRoles = (currentUser: IUser): boolean =>
  getSuperCoachRoles().some((role) => currentUser.roles.includes(role));
export const hasGeneralRoles = (currentUser: IUser): boolean =>
  getGeneralRoles().some((role) => currentUser.roles.includes(role));
export const hasTrainerRole = (currentUser: IUser): boolean =>
  getTrainerRoles().some((role) => currentUser.roles.includes(role));
