/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, post, put} from './api-calls';
import {ICasualTag} from '../models/ITag';
import {IBowelDto} from '../dtos/IBowelDto';

export const getBowelTags = (): Promise<ICasualTag[]> => {
  return get(`/bowelmovement/tags`);
};

export const getBowelLogs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IBowelDto[]> => {
  return get(`/bowelmovement?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addBowelDatabase = (data: IBowelDto): Promise<any> => {
  return post('/bowelmovement', data);
};

export const editBowelDatabase = (data: IBowelDto): Promise<any> => {
  return put(`/bowelmovement/${data.id}`, data);
};

export const deleteBowelDatabase = (id: number): Promise<any> => {
  return del(`/bowelmovement/${id}`);
};

export const getBowelLogById = (id: number): Promise<IBowelDto> => {
  return get(`/bowelmovement/${id}`);
};
