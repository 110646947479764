/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react';
import {IResponsiveSize} from '../../../../models/IResponsiveSize';
import YogaCard from './YogaCard';
import {ContentDialog} from '../../dialog/ContentDialog';
import YogaPlayersView from './yogaPlayersView';
import {
  getKemTaiUserWorkoutsList,
  postYogaIntercomSlug,
} from '../../../../store/actions/kemtai/kemtai-action';
import {useDispatch} from 'react-redux';
import useStyles from '../fitness-content.styles';
import {addEngagementPointDatabase} from '../../../../api/engagement.api';
import {EngagementPointType} from '../../../../utils/enums';
import moment from 'moment';
import {useHistory, useLocation} from 'react-router';
// import { useHistory } from 'react-router';
// import { GeneralPaths } from '../../../../utils/constants';
interface Props {
  recipe: any;
  onClick: (slug: string) => void;
  containerStyle?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  userData: any;
  isYogaDeepLink: any;
  deepLinkSlug: any;
}
const YogaIntro: React.FC<Props> = ({
  isMobileOrTablet,
  smallScreens,
  userData,
  recipe,
  deepLinkSlug,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [workoutDetail, setWorkoutDetail] = React.useState<any>();
  const [slug, setSlug] = React.useState();

  const location = useLocation();
  const history = useHistory();
  // const [backTitle, setBackTitle] = React.useState<any>();
  const handleDismiss = () => {
    setOpen(false);
    const searchParams = new URLSearchParams(location?.search);
    // const slug = searchParams.get('slug');
    const id = searchParams.get('id');
    if (id !== null) {
      history.push('/movement/yoga');
    }
  };
  // const history = useHistory();
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const dispatch = useDispatch();
  const handleCardClick = async (workouts: any) => {
    setOpen(true);

    setSlug(workouts?.workoutSlug);
    addEngagementPointDatabase({
      eventType: EngagementPointType.VIDEO_WATCHED,
      description: workouts.workOutName || '',
    });
    const currentEpochTimeInSeconds = moment().unix();
    const data = {subscription: workouts.workoutSlug};
    const payload = {
      userId: userData?.id,
      eventName: 'Yoga',
      metaData: JSON.stringify(data),
      created_At: currentEpochTimeInSeconds,
      duplicate_Allowed: false,
    };
    await dispatch(postYogaIntercomSlug(payload));
  };

  const getWorkouts = async () => {
    try {
      const res: any = await dispatch(
        getKemTaiUserWorkoutsList(userData?.id, recipe.id, '', '', ''),
      );
      if (res) {
        res?.workouts?.sort(function (a: any, b: any) {
          const keyA = parseInt(a.order),
            keyB = parseInt(b.order);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        setWorkoutDetail(res?.workouts);
      }
    } catch (error) {
      // Handle any errors here
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    // const slug = searchParams.get('slug');
    const id = searchParams.get('id');
    if (id !== null) {
      setSlug(deepLinkSlug);
      setOpen(true);
    } else {
      getWorkouts();
    }
  }, []);

  return (
    // eslint-disable-next-line react/no-unescaped-entities
    <>
      <ContentDialog
        fullScreen
        slideUp
        handleDismiss={handleDismiss}
        dialogState={open}
        closeIconClass={classes.closeIconRecipesClass}
        isCloseButtonHidden={smallScreens}
        // className={classes.dialogMargin}
        //  closeIconClass={classes.dialogClose}
        content={
          <>
            <YogaPlayersView
              slug={slug ? slug : ''}
              isMobileOrTablet={isMobileOrTablet}
              smallScreens={smallScreens}
              Content={undefined}
              handleDismiss={handleDismiss}
              backTitle={'Yoga'}
            />
          </>
        }
      />
      <div>
        <h2>Let's Start</h2>
        <>
          <div className={classes.fitnessList}>
            {workoutDetail &&
              workoutDetail?.length > 0 &&
              workoutDetail?.map((work: any) => {
                return (
                  <>
                    <YogaCard
                      isMobileOrTablet={isMobileOrTablet}
                      smallScreens={smallScreens}
                      name={work?.workOutName}
                      Image={work?.workoutImageUrl}
                      isLocked={false}
                      onClick={() => handleCardClick(work)}
                      recipe={work}
                    />
                  </>
                );
              })}
          </div>
        </>
      </div>
    </>
  );
};

// yogaIntro.propTypes = {}

export default YogaIntro;
