import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {handleMargin} from '../../../utils/css-helpers';

interface Props {
  isMobileOrTablet: IResponsiveSize;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 80,
    minHeight: 'calc(100vh - 200px)',
  },
  mainCenterContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: (props) =>
      props.isMobileOrTablet.isMobile || props.isMobileOrTablet.isTabletSmall
        ? '100%'
        : props.isMobileOrTablet.isTabletBig
        ? 840
        : 652,
    margin: (props) => handleMargin(props.isMobileOrTablet, '0'),
  },
  notificationPanel: {
    minWidth: 310,
    marginRight: '0',
  },
  noContentContainer: {
    display: 'flex',
    marginTop: '100px',
  },
}));

export default useStyles;
