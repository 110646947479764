import {ITokenInfo} from '../models/ITokenInfo';
import {IUser} from '../models/IUser';
import {IUserDataOnBoardingLocaleStorage} from '../models/IUserDataOnBoardingLocaleStorage';

const ACCESS_TOKEN_KEY = 'access-token';
const ID_TOKEN_KEY = 'id-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const USER_INFO_EMAIL_KEY = 'email';
const USER_INFO_WHOLE_USER = 'user';
const USER_DATA_ON_BOARDING = 'user_data_on_boarding';

const LANGUAGE_KEY = 'i18nextLng';

export const setTokenInfo = (tokenInfo: ITokenInfo): void => {
  localStorage.setItem(ACCESS_TOKEN_KEY, tokenInfo.access_token);
  localStorage.setItem(REFRESH_TOKEN_KEY, tokenInfo.refresh_token);
  localStorage.setItem(ID_TOKEN_KEY, tokenInfo.id_token);
};

export const setUserInfo = (userInfo: IUser): void => {
  localStorage.setItem(USER_INFO_EMAIL_KEY, userInfo.email);
  localStorage.setItem(USER_INFO_WHOLE_USER, JSON.stringify(userInfo));
};

export const clearLocaleStorage = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);

  localStorage.removeItem(USER_INFO_EMAIL_KEY);
  localStorage.removeItem(USER_INFO_WHOLE_USER);

  localStorage.removeItem(LANGUAGE_KEY);

  localStorage.removeItem(USER_DATA_ON_BOARDING);
  localStorage.removeItem("chatToken");
};

export const getTokenData = (): any => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const idToken = localStorage.getItem(ID_TOKEN_KEY);

  return {
    accessToken,
    refreshToken,
    idToken,
  };
};

export const getUserData = (): IUser => {
  const email = localStorage.getItem(USER_INFO_EMAIL_KEY);
  const user: IUser = JSON.parse(localStorage.getItem(USER_INFO_WHOLE_USER) || 'null');

  if (user != null) {
    return user;
  } else {
    return <IUser>{
      email,
    };
  }
};

export const setUserDataOnBoarding = (userInfo: IUserDataOnBoardingLocaleStorage): void => {
  localStorage.setItem(USER_DATA_ON_BOARDING, JSON.stringify(userInfo));
};

export const getUserDataOnBoarding = (): IUserDataOnBoardingLocaleStorage => {
  return JSON.parse(localStorage.getItem(USER_DATA_ON_BOARDING) || 'null');
};

export const removeUserDataOnBoarding = (): void => {
  localStorage.removeItem(USER_DATA_ON_BOARDING);
};
