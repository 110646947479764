import {get, del, post} from './api-calls';
import {IConversationDto} from '../dtos/IConversationDto';
import {ITokenDto} from '../dtos/ITokenDto';
import {IParticipantDto} from '../dtos/IParticipantDto';

export const getTokenServer = (type?: string): Promise<ITokenDto> => {
  // return get(`/chat/token`);
  const chatToken = localStorage.getItem('chatToken');
  if (chatToken && type === 'apiCall') {
    return get(`/chat/token`).then((res) => {
      localStorage.setItem('chatToken', res.token);
      return res;
    });
  } else if (chatToken) {
    const token: ITokenDto = {token: chatToken};
    return Promise.resolve(token);
  } else {
    return get(`/chat/token`).then((res) => {
      localStorage.setItem('chatToken', res.token);
      return res;
    });
  }
};

export const getConversationsServer = (
  includeParticipants = false,
  userId?: string,
): Promise<IConversationDto[]> => {
  return get(
    `/chat/conversations?UserId=${userId}&IncludeParticipants=${includeParticipants}&AllPhases=true`,
  );
};

export const getPaginationConversations = (
  pageSize: number,
  nextPageUrl?: string | null,
  userId?: string,
): Promise<IConversationDto[]> => {
  if (nextPageUrl) {
    return get(
      `/chat/conversationsWithPagination?UserId=${userId}&NextPageUrl=${nextPageUrl}&UsersPerPage=${pageSize}`,
    );
  } else {
    return get(`/chat/conversationsWithPagination?UserId=${userId}&UsersPerPage=${pageSize}`);
  }
};

export const getParticipantForConversation = (id: string): Promise<IParticipantDto[]> => {
  return get(`/chat/conversations/${id}/participants`);
};

export const addParticipant = (
  conversationId: string | undefined,
  participantId: string,
): Promise<any> => {
  return post(`/chat/conversations/${conversationId}/participants`, {
    conversationId: conversationId,
    participantId: participantId,
  });
};

export const deleteParticipant = (
  conversationId: string | undefined,
  participantId: string,
): Promise<any> => {
  return del(`/chat/conversations/${conversationId}/participants/${participantId}`);
};

export const getParticipantById = (participantId: string): Promise<IParticipantDto> => {
  return get(`/chat/participants/${participantId}`);
};
