import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: '12px',
    '& .MuiTypography-subtitle1': {
      '& mark': {
        color: theme.palette.blacksAndWhites.smokyBlack,
        background: 'none',
      },
    },
    '& .MuiTypography-caption, .MuiTypography-subtitle1, .MuiTypography-body2': {
      color: theme.palette.blacksAndGrays.stoneGray,
    },
  },
  tableRow: {
    height: 20,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      '& > .MuiTableCell-body': {
        color: theme.palette.blacksAndWhites.pureWhite,
      },
    },
  },
  tableCell: {
    padding: '8px',
    color: theme.palette.blacksAndWhites.davysGray,
  },
  tableRowDetailsContainer: {
    borderBottom: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    padding: '26px 16px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.blacksAndWhites.cultured,
      '& .MuiTypography-caption, .MuiTypography-subtitle1, .MuiTypography-body2': {
         color: theme.palette.primary.main,
      
        '& mark': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  topContent: {
    display: 'flex',
    marginBottom: '24px',
  },
  topContentName: {
    margin: '6px 12px',
    display: 'flex',
    flexDirection: 'column',
  },
  centerContent: {
    display: 'flex',
  },
  bottomContent: {
    marginTop: '24px',
  },
  centerContentPart: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
}));
export default useStyles;
