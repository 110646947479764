import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((themes) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '588px',
    margin: '100px auto',
  },
  searchBoxContainer: {
    width: '588px',
    margin: '60px 0 24px 0px',
    backgroundColor: themes.palette.blacksAndWhites.pureWhite,
    filter: 'none',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  tableContainer: {
    width: '588px',
  },
}));

export default useStyles;
