import {makeStyles, Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  mainCenterContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: (props) => (props.smallScreens ? '100%' : 588),
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      padding: 0,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& .MuiAccordionSummary-root ': {
      padding: 0,
    },
  },
  content: {
    width: '100%',
    // margin: '0px 24px',
    marginBottom: 15,
  },
  profileHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
    '&.isNotExpand': {
      marginBottom: 0,
    },
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  line: {
    border: '1px solid',
    borderColor: theme.palette.blacksAndWhites.gainsboro,
    marginTop: 16,
    marginBottom: 16,
  },
  profileInfoTxt: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {},
  achievements: {marginBottom: 16},
  personalInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  programInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
    marginTop: 10,
  },
  badgeIcon: {
    marginTop: 16,
    marginRight: 20,
  },
  infoItem: {
    marginRight: (props) => (props.isMobileOrTablet.isMobile ? 0 : 32),
    display: 'flex',
    flexDirection: (props) => (props.isMobileOrTablet.isMobile ? 'row' : 'column'),
    '& > span': {
      width: (props) => (props.isMobileOrTablet.isMobile ? 102 : 'auto'),
      marginBottom: (props) => (props.isMobileOrTablet.isMobile ? '4px' : 0),
    },
    '& > p': {
      fontSize: (props) => (props.isMobileOrTablet.isMobile ? '12px' : '14px'),
      lineHeight: (props) => (props.isMobileOrTablet.isMobile ? '16px' : '20px'),
      color: theme.palette.blacksAndWhites.smokyBlack,
    },
  },
  subscription: {
    marginBottom: 8,
  },
  gearIcon: {
    cursor: 'pointer',
  },
  membership: {
    display: 'flex',
    flexDirection: 'row',
  },
  userIcon: {
    marginRight: 10,
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dataItem: {
    color: `${theme.palette.blacksAndWhites.sonicSilver} !important`,
  },
  progressItem: {
    marginBottom: 24,
  },
  fixMargin: {
    marginTop: 0,
  },
  smallScreenMargin: {
    marginTop: 65,
  },
  heightMargin: {
    marginRight: 4,
  },
  imgUpload: {
    borderRadius: '100px !important',
    width: '40px !important',
    height: '40px !important',
    marginRight: 20,
  },
  imgUploadEdit: {
    borderRadius: '100px !important',
    width: '64px !important',
    height: '64px !important',
    marginRight: 20,
  },
}));

export default useStyles;
