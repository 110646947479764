import * as React from 'react';
import {useEffect, useState} from 'react';
import {isMobileOnly} from 'react-device-detect';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import {useMediaQuery} from '@material-ui/core';
import {MOBILE_SIZE} from '../../../utils/constants';

import useStyles from './range-slider.styles';

export interface IMark {
  value: number;
  label?: any;
}

interface Props {
  id: string;
  name: string;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  step: number;
  marks: IMark[];
  onChange: any;
  className?: string;
  showLabel?: boolean;
}

export const RangeSlider: React.FC<Props> = (props) => {
  const {id, name, defaultValue, showLabel, minValue, maxValue, step, marks} = props;
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isMobile = React.useMemo(() => {
    return isMobileOnly || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobile,
  });
  const [showingLabel, setShowingLabel] = useState(null);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const setValidEmoticon = (value: number) => {
    for (let i = 0; i < marks?.length - 1; ++i) {
      if (value >= marks[i].value && value < marks[i + 1].value) {
        setShowingLabel(marks[i].label);
        break;
      }

      if (value === marks[marks?.length - 1].value) {
        setShowingLabel(marks[marks?.length - 1].label);
      }
    }
  };

  useEffect(() => {
    setSelectedValue(defaultValue);
    setValidEmoticon(defaultValue);
  }, [defaultValue]);

  const convertIntoMarkValues = (): IMark[] => {
    const marksValues: IMark[] = [];
    marks.forEach((oneMark) => {
      marksValues.push({value: oneMark.value, label: showLabel ? oneMark.value.toString() : ''});
    });
    return marksValues;
  };

  const handleChange = (e: any, value: any) => {
    props.onChange(e, value);
    setSelectedValue(value);
    setValidEmoticon(value);
  };

  return (
    <div className={classNames(classes.root, props.className)} key={name + ' ' + id}>
      <Box className={classes.rangeBox}>
        {showingLabel}
        <Slider
          aria-label="Always visible"
          value={selectedValue}
          step={step}
          min={minValue}
          max={maxValue}
          marks={convertIntoMarkValues()}
          onChange={handleChange}
          valueLabelDisplay="off"
        />
      </Box>
    </div>
  );
};
