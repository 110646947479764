import * as React from 'react';
import {AreaChart, XAxis, YAxis, Area, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {IChartData, IChartOptions} from '../../../models/IChart';

interface Props {
  data: IChartData[];
  options: IChartOptions;
  isMobile: boolean;
}

export const BaseAreaChart: React.FC<Props> = ({data, options, isMobile}) => {
  return (
    <ResponsiveContainer
      width={options.width}
      height={isMobile ? options.heightMobile : options.height}
    >
      <AreaChart data={data} margin={isMobile ? options.marginMobile : options.margin}>
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={options.color} stopOpacity={0.4} />
            <stop offset="95%" stopColor={options.color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" />
        <YAxis orientation="left" domain={options.rangeY} unit={options.unit} />
        <CartesianGrid strokeDasharray="0 0" />
        <Tooltip />
        <Area
          dataKey="value"
          stroke={options.color}
          fillOpacity={1}
          fill="url(#colorValue)"
          strokeWidth={1.5}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
