import * as React from 'react';
import {useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import {ExpandableContainer} from '../../../common/components/expandable-container/ExpandableContainer';
import {GeneralPaths, MOBILE_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './recipes-filter.styles';
import {IDailyContentCategoryDto} from '../../../dtos/IDailyContentCategoryDto';
// import {
//   // selectAllDailyContentCategoriesForBetrHealth,
//   selectBetrProgramFilters,
// } from '../../../store/selectors/nourish.selectors';
// import {useEffect, useState} from 'react';
// import classNames from 'classnames';
// import {IBetrProgramFilterDto} from '../../../dtos/IBetrProgramFilterDto';
import { useHistory, useParams } from 'react-router';

interface MovementFilterProps {
  className?: string;
  onFilterChange: any;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const MovementFilter: React.FC<MovementFilterProps> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTablet: isTablet || isTabletSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize: isMobileOrTablet.isMobile || isMobileOrTablet.isTablet,
  });
  const isRehabEnabled = useSelector(state => state?.nourish?.rehabEnabled);
  const [dailyContentCategories, setDailyContentCategories] = React.useState([])
React.useEffect(() => {
    const movementLinks: IDailyContentCategoryDto[] = [
      {id: 3, name: "Fitness", parent: "Movement"},
    ]
    if(isRehabEnabled){
      movementLinks.push({id: 5, name: "Rehab", parent: "Movement"})
    }
    movementLinks.push({id: 6, name: "Yoga", parent: "Movement"})
      setDailyContentCategories(movementLinks)
  }, [isRehabEnabled])
  const params: any = useParams();
  const history = useHistory();
  // const [selectedCategoryItem, setSelectedCategoryItem] = useState<
  //   IDailyContentCategoryDto | undefined
  // >(undefined);
  // const selectedBetrProgramFilters: IBetrProgramFilterDto = useSelector(selectBetrProgramFilters);

  // useEffect(() => {
  //   if (selectedBetrProgramFilters?.filter) {
  //     if (dailyContentCategories) {
  //       setSelectedCategoryItem(
  //         dailyContentCategories.find(
  //           (item) =>
  //             item.name === selectedBetrProgramFilters?.filter['daily_content_category.name'],
  //         ),
  //       );
  //     }
  //   }
  // }, [selectedBetrProgramFilters]);

  const handleSelectCategory = (dailyContentCategory: IDailyContentCategoryDto) => {
    if(dailyContentCategory?.name === 'Rehab') {
      history.replace({
        pathname: `${GeneralPaths.MovementPage}/rehab`,
      });
    } if(dailyContentCategory?.name === 'Fitness') {
      history.replace({
        pathname: `${GeneralPaths.MovementPage}/fitness`,
      });
    }  if(dailyContentCategory?.name === 'Yoga') {
      history.replace({
        pathname: `${GeneralPaths.MovementPage}/yoga`,
      });
    } else {
      // setSelectedCategoryItem(dailyContentCategory);
  
      const filters = {
        dailyCategory: dailyContentCategory,
      };
      props.onFilterChange(filters);
    }
  };

  return (
    <ExpandableContainer className={classes.filterContent}>
      <div className={classes.containerWidth}>
        {dailyContentCategories?.map((dailyContentCategory) => (
          <div
            className={classes.subItem}
            key={'daily-content-category-key' + dailyContentCategory?.id}
            id={'daily-content-category-id' + dailyContentCategory?.id}
            onClick={() => handleSelectCategory(dailyContentCategory)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <Typography
              variant={'body1'}
              style={{'cursor': 'pointer'}}
              className={
                // dailyContentCategory.id === selectedCategoryItem?.id
                //   ? classNames(classes.subItemSelected, classes.subItemSelected)
                //   : classes.subItemSelected
                (params?.movementType && dailyContentCategory?.name) ?
                 params?.movementType?.toLowerCase() === dailyContentCategory?.name?.toLowerCase() ? classes.subItemSelected : null  : null
              }
            >
              {dailyContentCategory?.name}
            </Typography>
          </div>
        ))}
      </div>
    </ExpandableContainer>
  );
};

MovementFilter.displayName = 'MovementFilter';
