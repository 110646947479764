import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Box} from '@material-ui/core';
import DataItem from '../quick-user-profile/DataItem';
import {MoodLevelText} from './MoodLevelText';
import {ProgressType} from '../../../utils/enums';

import useStyles from './progress-header.styles';

interface Props {
  type: ProgressType;
  isMobile: boolean;
  data: any;
}

const ProgressCardHeader: React.FC<Props> = ({isMobile, type, data}) => {
  const [t] = useTranslation();
  const classes = useStyles({
    isMobile,
  });

  const returnValueToDisplay = (value: string) => {
    if (value === '' || !value)
      return (
        <Typography variant={'overline'}>{t('Homepage.insights.table.noDataLabel')}</Typography>
      );
    if (type === ProgressType.MENTAL_HEALTH) {
      return <MoodLevelText value={value} />;
    } else return value;
  };

  return (
    <React.Fragment>
      <div className={classes.titleContainer}>
        <Box mb={3}>
          <Typography variant="h6">{type}</Typography>
        </Box>
      </div>
      <div className={classes.rowContainer}>
        <DataItem
          label={t('Homepage.progress.today')}
          value={data?.length > 0 && returnValueToDisplay(data[3]?.value)}
          itemClassName={classes.item}
        />
        <DataItem
          label={t('Homepage.progress.yesterday')}
          value={data?.length > 0 && returnValueToDisplay(data[2]?.value)}
          itemClassName={classes.item}
        />
        <DataItem
          label={t('Homepage.progress.2DaysAgo')}
          value={data?.length > 0 && returnValueToDisplay(data[1]?.value)}
          itemClassName={classes.item}
        />
        <DataItem
          label={t('Homepage.progress.3DaysAgo')}
          value={data?.length > 0 && returnValueToDisplay(data[0]?.value)}
          itemClassName={classes.item}
        />
      </div>
    </React.Fragment>
  );
};

export default ProgressCardHeader;
