import moment from 'moment';
import {EMPTY_FIELD_PLACEHOLDER, AM_PM_FORMAT, ONLY_DATE_FORMAT} from './constants';

export const isValidDate = (dateTime: string | undefined): boolean => {
  const timestamp = Date.parse(dateTime || '');

  return !isNaN(timestamp);
};

export const isValidDateBirthDatePicker = (dateTime: string | undefined): boolean => {
  const matches = /^(\d{2})[-/](\d{2})[-/](\d{4})$/.exec(dateTime || '');
  if (matches == null) return false;
  const day = +matches[2];
  const month = +matches[1] - 1;
  const year = +matches[3];
  const checkDate = new Date(year, month, day);
  const isValidDate =
    checkDate.getDate() == day && checkDate.getMonth() == month && checkDate.getFullYear() == year;
  const isDateBeforeCurrent = checkDate < new Date();

  return isValidDate && isDateBeforeCurrent;
};

// for merging date and time
export const dateTimeCombined = (date: string | undefined, time: string | undefined): string => {
  if (!isValidDate(date) || !isValidDate(time)) {
    return '';
  }

  const dateObject = moment(date);
  const timeObject = moment(time);
  dateObject.hour(timeObject.get('hour'));
  dateObject.minute(timeObject.get('minute'));

  return dateObject.utc().format();
};
export const getDateFromDate = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

//TODO remove hardcoded hour/for sending date to database in utc format
export const dateToUtc = (date: string): string => {
  const date1 = getDateFromDate(new Date(date));
  return date1;
};

// for showing data on pages
export const stringToDateTimeFormatString = (
  date: string | undefined,
  stringFormat = AM_PM_FORMAT,
): string => {
  if (!date) return EMPTY_FIELD_PLACEHOLDER;
  return moment.utc(date).local().format(stringFormat);
};

export const stringToDateTimeString = (date: string): string => {
  return moment.utc(date).local().toString();
};

export const stringToDateTimeStringFormat = (date: string): string => {
  return moment.utc(date).local().format();
};

export const addTime = (
  date: moment.Moment,
  amount: number | string,
  unitOfTime: any = 'minutes',
): moment.Moment => {
  return moment(date).add(amount, unitOfTime);
};

export const isDateSameOrBeforeDate = (date1: string, date2: string): boolean => {
  return moment(date1).isSameOrBefore(moment(date2));
};

export const isDateSameOrAfterDate = (date1: string, date2: string): boolean => {
  return moment(date1).isSameOrAfter(moment(date2));
};

export const isDateSameOrBeforeCurrentDateTime = (date1: string): boolean => {
  return isDateSameOrBeforeDate(date1, moment().toString());
};

export const dateToString = (
  date: Date | undefined | string | null,
  format = ONLY_DATE_FORMAT,
): string => {
  if (!date) return EMPTY_FIELD_PLACEHOLDER;
  return moment(date).format(format);
};

export const dateTimeToString = (
  date: Date | string | undefined,
  format = AM_PM_FORMAT,
): string => {
  return moment(date).format(format);
};

export const daysBetween = (date1: Date, date2: Date): number => {
  const date1Changed = new Date(date1).setHours(0, 0, 0, 0);
  const date2Changed = new Date(date2).setHours(0, 0, 0, 0);
  const timeDifference = date2Changed - date1Changed;
  return Math.round(timeDifference / (1000 * 3600 * 24));
};

//there is another option with resolvedOptions but it does not work on InternetExplorer
export const getCurrentTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   return new Date().toString().substring(35, new Date().toString().indexOf(')'));
};

export const calculateTimeLeft = (date1: Date, date2: Date): number => {
  return date1.getTime() - date2.getTime();
};

export const createDateWithExactTime = (date: string, time: string): Date => {
  return new Date(`${date} ${time}`);
};

export const addDaysToDate = (date: string, days: number): Date => {
  const dateToAdd = new Date(date);
  dateToAdd.setDate(dateToAdd.getDate() + days);
  return dateToAdd;
};

//get age from date of birth
export const getAge = (dateString: string): number | string => {
  if (!dateString) return EMPTY_FIELD_PLACEHOLDER;

  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

//for display on history logs widget
export const getDateWithMonthName = (dateString: string): string => {
  const partsForDateTime = dateString.split(' ');
  const time = partsForDateTime[1] + ' ' + partsForDateTime[2];
  const partsDate = partsForDateTime[0].split('-');
  const date = new Date(+partsDate[2], +partsDate[0] - 1, +partsDate[1]);

  const dateMonth = date.toLocaleString('default', {month: 'long'});
  //const time = date.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
  const day = date.getDate();
  return `${dateMonth} ${day}, ${time}`;
};

export const convertTime12to24 = (time12h: string): number => {
  if (!time12h) return 0;
  const [time, modifier] = time12h.split(' ');
  const arr = time.split(':');
  let hours: any = arr[0];
  const minutes = arr[1];

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return parseInt(`${hours}${minutes}`);
};

export const convertTime24to12 = (timeString: string): string => {
  const H = +timeString.substr(0, 2);
  const h = H % 12 || 12;
  const ampm = H < 12 || H === 24 ? 'AM' : 'PM';
  return h + ':' + timeString.substr(2, 3) + ampm;
};

export const showOnlyDayAndMonth = (date: string | undefined): string => {
  if (!date) return EMPTY_FIELD_PLACEHOLDER;

  return date.substr(0, 5).replace('-', '/');
};

export const showOnlyTime = (date: string | undefined): string => {
  if (!date) return EMPTY_FIELD_PLACEHOLDER;

  return date.substr(date?.length - 8);
};
