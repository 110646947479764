/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, post, put} from './api-calls';
import {IBodyMeasurementDto} from '../dtos/IBodyMeasurementDto';

export const getBodyMeasurementLogs = (
  id: string,
  dateTime: string,
): Promise<IBodyMeasurementDto[]> => {
  return get(`/bodyMeasurement?UserId=${id}&from=${dateTime}`);
};

export const addBodyMeasurementDatabase = (data: IBodyMeasurementDto): Promise<any> => {
  return post('/bodyMeasurement', data);
};

export const editBodyMeasurementDatabase = (data: IBodyMeasurementDto): Promise<any> => {
  return put(`/bodyMeasurement/${data.id}`, data);
};

export const deleteBodyMeasurementDatabase = (id: number): Promise<any> => {
  return del(`/bodyMeasurement/${id}`);
};

export const getBodyMeasurementLogById = (id: number): Promise<IBodyMeasurementDto> => {
  return get(`/bodyMeasurement/${id}`);
};

export const getBodyMeasurementDataLogs = (
  id: string,
  fromDate: string,
  toDate: string,
): Promise<IBodyMeasurementDto[]> => {
  return get(`/bodyMeasurement?UserId=${id}&From=${fromDate}&To=${toDate}`);
};
