/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
/* ----------------------- mini orange part -----------------------------------  */

const appOidcTenant = process.env.REACT_APP_WALGREENS_TENANT || '';
const appOidcPolicy = process.env.REACT_APP_WALGREENS_POLICY || '';
const appOidcClientId = process.env.REACT_APP_WALGREENS_CLIENT_ID || '';
const appOidcClientSecret = process.env.REACT_APP_WALGREENS_CLIENT_SECRET || '';
const appOidcRedirectUri = process.env.REACT_APP_WALGREENS_REDIRECT_URI || '';

export const getTokenInfoWalgreens = async (codeValue: string): Promise<any> => {
  const oidcTenant = appOidcTenant;
  const oidcPolicy = appOidcPolicy;
  const oidcClientId = appOidcClientId;
  const oidcClientSecret = appOidcClientSecret;
  const grantType = 'authorization_code';
  const scope = 'openid';
  const code = codeValue;

  const urlLink =
    `https://${oidcTenant}.b2clogin.com/${oidcTenant}.onmicrosoft.com/${oidcPolicy}/oauth2/v2.0/token?` +
    `client_id=${oidcClientId}&client_secret=${oidcClientSecret}&grant_type=${grantType}&scope=${scope}` +
    `&code=${code}`;

  return await axios.get(urlLink);
};

export const getUserInfoWalgreens = async (accessToken: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const oidcTenant = appOidcTenant;
  const oidcPolicy = appOidcPolicy;
  const urlLink = `https://${oidcTenant}.b2clogin.com/${oidcTenant}.onmicrosoft.com/${oidcPolicy}/openid/v2.0/userinfo`;

  return await axios.get(urlLink, config);
};

export const redirectToWalgreens = (): void => {
  const oidcTenant = appOidcTenant;
  const oidcPolicy = appOidcPolicy;
  const oidcClientId = appOidcClientId;
  // const state =
  //   'ewogICJIM1VzZXJJZCI6ICI1ODQzMDY1OS01MDk1LTRiNWItYTg0YS0yNjEzNWZkYzhiOWEiLAogICJIM1BheW9ySWQiOiAiNTg0MzA2NTktNTA5NS00YjViLWE4NGEtMjYxMzVmZGM4YjliIiwKICAiSDNQcm9ncmFtSWQiOiAiNTg0MzA2NTktNTA5NS00YjViLWE4NGEtMjYxMzVmZGM4YjljIiwKICAiSDNTdXBwbGllcklkIjogIjU4NDMwNjU5LTUwOTUtNGI1Yi1hODRhLTI2MTM1ZmRjOGI5ZCIKfQ==';
  //const redirectUri = `https://jwt.ms/&state=${state}`;
  //const redirectUri = `http://localhost:3000/on-boarding/&state=${state}`;
  const redirectUri = appOidcRedirectUri;
  const scope = 'openid profile';
  const responseType = 'code id_token';
  const prompt = 'login';

  const urlLink =
    `https://${oidcTenant}.b2clogin.com/${oidcTenant}.onmicrosoft.com/${oidcPolicy}/oauth2/v2.0/authorize?` +
    `client_id=${oidcClientId}&redirect_uri=${redirectUri}` +
    `&scope=${scope}&response_type=${responseType}&prompt=${prompt}`;

  window.location.replace(urlLink);
};
