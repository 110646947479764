import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: (props) => (props.smallScreens ? '0' : '9px 16px'),
    boxShadow: (props) => (props.smallScreens ? 'none' : '0px 2px 2px rgba(0, 0, 0, 0.12)'),
    borderRadius: '12px',
    width: (props) => (props.smallScreens ? '100%' : 285),
    maxHeight: 'fit-content',
    backgroundColor: theme.palette.background.paper,
    marginBottom: (props) => (props.smallScreens ? '0' : 16),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    display: 'flex',
  },
  ellipsisImage: {
    marginRight: '0px !important',
    marginLeft: '0px !important',
  },
  ellipsisOptions: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  optionsItemIcon: {
    marginLeft: '0px !important',
  },
  headerIcon: {
    marginRight: 8,
  },
  content: {
    marginTop: 8,
    marginBottom: 16,
    overflowWrap: 'break-word',
    '& a': {
      color: 'rgb(23, 76, 136)'
    }
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
    '& button': {
      width: '100%',
    },
  },
  button: {
    height: '36px !important',
    marginRight: '16px',
  },
  noteInput: {
    width: '100%',
    borderRadius: '12px',
    '&:focus': {
      height: '300px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
    },
  },
  inputOnFocus: {
    height: '300px',
    '& .MuiOutlinedInput-notchedOutline': {
      height: '300px',
    },
  },
  fieldContainer: {
    marginTop: '10px',
    marginRight: 0,
  },
  coach: {
    color: theme.palette.blacksAndGrays.stoneGray,
    marginTop: (props) => (props.smallScreens ? 5 : 16),
  },
  noteDate: {
    color: theme.palette.primary.dark,
  },
  loadMore: {
    marginBottom: 16,
    alignSelf: 'baseline',
  },
}));

export default useStyles;
