/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as loggingActions from './logging-page-types';
import {IWeightDto} from '../../../dtos/IWeightDto';

import {Dispatch} from 'redux';
import {
  getWeightLoggs,
  addWeightLoggDatabase,
  deleteWeightLoggDatabase,
  editWeightLoggDatabase,
  getBodyWeightLogById,
} from '../../../api/weightLoggs.api';
import {checkAchievementDatabase} from '../../../api/achievement.api';
import {ICategoryTagDto, IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';
import {
  addFoodLoggDatabase,
  deleteFoodLoggDatabase,
  editFoodLoggDatabase,
  getCategoryTags,
  getFoodLoggs,
  getFoodLogById,
} from '../../../api/food.api';
import {addTime} from '../../../utils/date-time-utils';
import moment from 'moment';
import Snackbar from '../../../common/components/notistack/SnackbarUtils';
import i18n from 'i18next';
import {IFoodDto} from '../../../dtos/IFoodDto';
import {ITagDto} from '../../../dtos/ITagDto';
import {ICasualTag, ITag, IUserTagDto} from '../../../models/ITag';
import {
  addActivityDatabase,
  deleteActivityDatabase,
  editActivityDatabase,
  getActivityLogs,
  getActivityTags,
  getActivityLogById,
} from '../../../api/activity.api';
import {IActivityDto} from '../../../dtos/IActivityDto';
import {
  addStressDatabase,
  deleteStressDatabase,
  editStressDatabase,
  getMoodTags,
  getStressLogs,
  getStressTags,
  getStressLogById,
} from '../../../api/stress.api';
import {IStressDto} from '../../../dtos/IStressDto';
import {IDrinkDto} from '../../../dtos/IDrinkDto';
import {
  addDrinkDatabase,
  deleteDrinkDatabase,
  editDrinkDatabase,
  getDrinkLogs,
  getDrinkLogById,
} from '../../../api/drink.api';
import {ISleepDto} from '../../../dtos/ISleepDto';
import {
  addSleepDatabase,
  deleteSleepDatabase,
  editSleepDatabase,
  getSleepLogs,
  getSleepTags,
  getSleepLogById,
} from '../../../api/sleep.api';
import {
  addBowelDatabase,
  deleteBowelDatabase,
  editBowelDatabase,
  getBowelLogs,
  getBowelTags,
  getBowelLogById,
} from '../../../api/bowel.api';
import {IBowelDto} from '../../../dtos/IBowelDto';
import {
  addStepsDatabase,
  deleteStepsDatabase,
  editStepsDatabase,
  getStepsLogs,
  getStepsLogById,
} from '../../../api/steps.api';
import {IStepsDto} from '../../../dtos/IStepDto';
import {IBloodPressureDto} from '../../../dtos/IBloodPressureDto';
import {IBloodGlucoseDto} from '../../../dtos/IBloodGlucoseDto';
import {IBodyPainDto} from '../../../dtos/IBodyPainDto';
import {
  addBloodPressureDatabase,
  deleteBloodPressureDatabase,
  editBloodPressureDatabase,
  getBloodPressureLogs,
  getBloodPressureLogById,
  getBloodPressureDataLogs,
} from '../../../api/bloodPressure.api';
import {
  addBloodGlucoseDatabase,
  deleteBloodGlucoseDatabase,
  editBloodGlucoseDatabase,
  getBloodGlucoseLogs,
  getBloodGlucoseLogById,
} from '../../../api/blood-glucose.api';
import {
  addBodyPainDatabase,
  deleteBodyPainDatabase,
  editBodyPainDatabase,
  getBodyPainLogs,
  getBodyPainLogById,
  getBodyPainLogsActivity,
} from '../../../api/body-pain.api';
import {
  getCholesterolLogs,
  addCholesterolDatabase,
  editCholesterolDatabase,
  deleteCholesterolDatabase,
  getCholesterolLogById,
  getCholesterolDataLogs,
} from '../../../api/cholesterol.api';
import {
  getA1CLogs,
  addA1CDatabase,
  editA1CDatabase,
  deleteA1CDatabase,
  getA1CLogById,
  getA1CLogsActivity,
} from '../../../api/a1C.api';
import {getUserCategoryTags} from '../../../api/users.api';
import {IBodyMeasurementDto} from '../../../dtos/IBodyMeasurementDto';
import {
  addBodyMeasurementDatabase,
  deleteBodyMeasurementDatabase,
  editBodyMeasurementDatabase,
  getBodyMeasurementLogs,
  getBodyMeasurementLogById,
  getBodyMeasurementDataLogs,
} from '../../../api/body-measurement.api';
import {IAchievementDto} from '../../../dtos/IAchievementDto';
import {IA1CDto} from '../../../dtos/IA1CDto';
import {ICholesterolDto} from '../../../dtos/ICholesterolDto';
import {GROUP_TAGS} from '../../../utils/constants';
import {LoggedWidgetType} from '../../../utils/enums';

export interface ILoadWeightLoggs {
  type: typeof loggingActions.LOAD_WEIGHT_LOG_DATA;
  payload: IWeightDto[] | null;
}
export interface IDeleteWeightLogg {
  type: typeof loggingActions.DELETE_WEIGHT_LOGG;
  payload: number;
}

export interface ILoadCategoryTags {
  type: typeof loggingActions.LOAD_CATEGORY_TAGS;
  payload: ICategoryTagDto[] | null;
}

export interface ILoadUsersTags {
  type: typeof loggingActions.LOAD_USERS_TAGS;
  payload: IUserTagDto[] | null;
}

export interface ILoadFoodLoggs {
  type: typeof loggingActions.LOAD_FOOD_LOG_DATA;
  payload: IFoodDto[] | null;
}
export interface IDeleteFoodLogg {
  type: typeof loggingActions.DELETE_FOOD_LOGG;
  payload: number;
}
export interface ILoadActivityLoggs {
  type: typeof loggingActions.LOAD_ACTIVITY_LOG;
  payload: IActivityDto[] | null;
}
export interface ILoadActivityTags {
  tags: typeof loggingActions.LOAD_ACTIVITY_TAGS;
  payload: ICasualTag[] | null;
}
export interface IDeleteActivityLog {
  type: typeof loggingActions.DELETE_ACTIVITY_LOG;
  payload: number;
}

export interface ILoadStressLoggs {
  type: typeof loggingActions.LOAD_STRESS_LOG;
  payload: IStressDto[] | null;
}
export interface ILoadStressTags {
  tags: typeof loggingActions.LOAD_STRESS_TAGS;
  payload: ICasualTag[] | null;
}
export interface ILoadMoodTags {
  tags: typeof loggingActions.LOAD_MOOD_TAGS;
  payload: ICasualTag[] | null;
}
export interface IDeleteStressLog {
  type: typeof loggingActions.DELETE_STRESS_LOG;
  payload: number;
}
export interface ILoadSleepLoggs {
  type: typeof loggingActions.LOAD_SLEEP_LOG;
  payload: ISleepDto[] | null;
}
export interface ILoadSleepTags {
  tags: typeof loggingActions.LOAD_SLEEP_TAGS;
  payload: ICasualTag[] | null;
}
export interface IDeleteSleepLog {
  type: typeof loggingActions.DELETE_SLEEP_LOG;
  payload: number;
}
export interface ILoadBowelLoggs {
  type: typeof loggingActions.LOAD_BOWEL_LOG;
  payload: IBowelDto[] | null;
}
export interface ILoadBowelTags {
  tags: typeof loggingActions.LOAD_BOWEL_TAGS;
  payload: ICasualTag[] | null;
}
export interface IDeleteBowelLog {
  type: typeof loggingActions.DELETE_BOWEL_LOG;
  payload: number;
}

export interface ILoadDrinkLogs {
  type: typeof loggingActions.LOAD_DRINK_LOG;
  payload: IDrinkDto[] | null;
}
export interface IDeleteDrinkLog {
  type: typeof loggingActions.DELETE_DRINK_LOG;
  payload: number;
}

export interface ILoadStepsLogs {
  type: typeof loggingActions.LOAD_STEPS_LOG;
  payload: IStepsDto[] | null;
}
export interface IDeleteStepsLog {
  type: typeof loggingActions.DELETE_STEPS_LOG;
  payload: number;
}

export interface ILoadBodyMeasurementLogs {
  type: typeof loggingActions.LOAD_BODY_MEASUREMENT_LOG;
  payload: IBodyMeasurementDto[] | null;
}
export interface IDeleteBodyMeasurementLog {
  type: typeof loggingActions.DELETE_BODY_MEASUREMENT_LOG;
  payload: number;
}

export interface ILoadBloodPressureLogs {
  type: typeof loggingActions.LOAD_BLOOD_PRESSURE_LOG;
  payload: IBloodPressureDto[] | null;
}
export interface IDeleteBloodPressureLog {
  type: typeof loggingActions.DELETE_BLOOD_PRESSURE_LOG;
  payload: number;
}

export interface ILoadBloodGlucoseLogs {
  type: typeof loggingActions.LOAD_BLOOD_GLUCOSE_LOG;
  payload: IBloodGlucoseDto[] | null;
}
export interface IDeleteBloodGlucoseLog {
  type: typeof loggingActions.DELETE_BLOOD_GLUCOSE_LOG;
  payload: number;
}

export interface ILoadA1CLogs {
  type: typeof loggingActions.LOAD_A1C_LOG;
  payload: IA1CDto[] | null;
}
export interface IDeleteA1CLog {
  type: typeof loggingActions.DELETE_A1C_LOG;
  payload: number;
}

export interface ILoadCholesterolLogs {
  type: typeof loggingActions.LOAD_CHOLESTEROL_LOG;
  payload: ICholesterolDto[] | null;
}
export interface IDeleteCholesterolLog {
  type: typeof loggingActions.DELETE_CHOLESTEROL_LOG;
  payload: number;
}

export interface ICheckAchievement {
  type: typeof loggingActions.CHECK_ACHIEVEMENT;
  payload: IAchievementDto | null;
}

export interface ILoadBodyPainLogs {
  type: typeof loggingActions.LOAD_BODY_PAIN_LOG;
  payload: IBodyPainDto[] | null;
}
export interface IDeleteBodyPainLog {
  type: typeof loggingActions.DELETE_BODY_PAIN_LOG;
  payload: number;
}

export const getWeightLoggsBackend = async (
  userId: string,
  fromDate?: string,
  toDate?: string,
): Promise<IWeightDto[]> => {
  const loadedWeightLogs: IWeightDto[] = [];
  const response: IWeightDto[] = await getWeightLoggs(userId, fromDate, toDate);
  for (const key in response) {
    loadedWeightLogs.push(response[key]);
  }

  return loadedWeightLogs;
};

export const loadWeightLogs = (
  id?: string,
  fromDate?: string,
  toDate?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IWeightDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    let loadedWeightLogs;
    // eslint-disable-next-line no-debugger
    if (id) {
      loadedWeightLogs = await getWeightLoggsBackend(id, fromDate, toDate);
    } else {
      const userData = getState().users.currentUser;
      loadedWeightLogs = await getWeightLoggsBackend(userData?.id, fromDate, toDate);
    }

    dispatch({type: loggingActions.LOAD_WEIGHT_LOG_DATA, payload: loadedWeightLogs});
    return loadedWeightLogs;
  };
};

export const addWeightLogg = (
  data: IWeightDto,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      await addWeightLoggDatabase(data);
      const loadedWeightLogs = await getWeightLoggsBackend(userData.id, fromTime);
      dispatch({type: loggingActions.LOAD_WEIGHT_LOG_DATA, payload: loadedWeightLogs});
      Snackbar.success(i18n.t('LogsPage.bodyWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editWeightLogg = (
  data: IWeightDto,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await editWeightLoggDatabase(data);
      const loadedWeightLogs = await getWeightLoggsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_WEIGHT_LOG_DATA, payload: loadedWeightLogs});
      Snackbar.success(i18n.t('LogsPage.bodyWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const deleteWeightLogg = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    
    await deleteWeightLoggDatabase(id);
    dispatch({type: loggingActions.DELETE_WEIGHT_LOGG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getFoodLogsBackend = async (
  id: string,
  categoryTagsList: ICategoryTagDto[],
  fromDate?: string,
  toDate?: string,
): Promise<IFoodDto[]> => {
  const loadedFoodLogs: IFoodDto[] = [];
  //const fromTime = dateTime || addTime(moment(), -2, 'days').utc().format();
  const response: IFoodDto[] = await getFoodLoggs(id, fromDate, toDate);
  let tag: ITag;

  // if (categoryTagsList?.length === 0) {
  //   const response2 = await getCategoryTags(true);
  //   response2.forEach((oneCategoryTagDto) => {
  //     oneCategoryTagDto.tags.forEach((oneTag) => {
  //       oneTag.isVisible = true;
  //     });
  //     categoryTagsList.push(oneCategoryTagDto);
  //   });
  // }

  response.forEach((foodLog) => {
    foodLog.tagsObjects = [];
    foodLog.tags.forEach((tagId: number) => {
      const selectedCategory: ICategoryTagDto | undefined = categoryTagsList?.find((oneCategory) =>
        oneCategory.tags.some((oneTag) => oneTag.id === tagId),
      );

      const selectedTag: ITagDto | undefined = selectedCategory?.tags.find(
        (oneTag) => oneTag.id == tagId,
      );

      if (selectedTag !== undefined && selectedCategory != undefined) {
        tag = {
          id: selectedTag.id,
          name: selectedTag.name,
          isLevel2: selectedTag.isLevel2,
          categoryId: selectedTag.categoryId,
          color: selectedTag.color,
          borderColor: selectedTag.color,
          hoverColor: selectedTag.hoverColor,
          textColor: selectedTag.textColor,
        };

        foodLog.tagsObjects.push(tag);
      }
    });

    loadedFoodLogs.push(foodLog);
  });

  return loadedFoodLogs;
};

export const loadFoodLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IFoodDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const categories = getState().logging.categoryTags;

    let loadedFoodLogs: IFoodDto[];
    if (id) {
      loadedFoodLogs = await getFoodLogsBackend(id, categories, fromDate, toDate);
    } else {
      loadedFoodLogs = await getFoodLogsBackend(userData.id, categories, fromDate, toDate);
    }
    dispatch({type: loggingActions.LOAD_FOOD_LOG_DATA, payload: loadedFoodLogs});
    return loadedFoodLogs;
  };
};

export const addFoodLogg = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const categories = getState().logging.categoryTags;
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      await addFoodLoggDatabase(data);
      const loadedFoodLogs: IFoodDto[] = await getFoodLogsBackend(
        userData.id,
        categories,
        fromTime,
      );
      dispatch({type: loggingActions.LOAD_FOOD_LOG_DATA, payload: loadedFoodLogs});
      Snackbar.success(i18n.t('LogsPage.foodWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editFoodLogg = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const categories = getState().logging.categoryTags;

      await editFoodLoggDatabase(data);
      const loadedFoodLogs: IFoodDto[] = await getFoodLogsBackend(userData.id, categories);
      dispatch({type: loggingActions.LOAD_FOOD_LOG_DATA, payload: loadedFoodLogs});
      Snackbar.success(i18n.t('LogsPage.foodWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const deleteFoodLogg = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteFoodLoggDatabase(id);
    dispatch({type: loggingActions.DELETE_FOOD_LOGG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const loadActivityTags = (): ((dispatch: Dispatch) => Promise<void>) => {
  const newActivityArray: ICasualTag[] = [];
  return async (dispatch: Dispatch) => {
    const response = await getActivityTags();
    response.forEach((oneTag) => {
      oneTag.isVisible = true;
      newActivityArray.push(oneTag);
    });

    dispatch({type: loggingActions.LOAD_ACTIVITY_TAGS, payload: newActivityArray});
  };
};

export const getActivityLogsBackend = async (
  id: string,
  activityTags: ICasualTag[],
  fromDate?: string,
  toDate?: string,
): Promise<IActivityDto[]> => {
  //const fromTime = addTime(moment(), -2, 'days').utc().format();
  const loadedActivityLogs: IActivityDto[] = await getActivityLogs(id, fromDate, toDate);
  let tag: ICasualTag;
  loadedActivityLogs.forEach((activityLog) => {
    activityLog.tagsObject = [];
    activityLog.tags.forEach((tagId: number) => {
      const selectedTag: ICasualTag | undefined = activityTags.find(
        (oneTag: ICasualTag) => oneTag.id == tagId,
      );

      if (selectedTag !== undefined) {
        tag = {
          id: selectedTag.id,
          name: selectedTag.name,
        };
        activityLog.tagsObject.push(tag);
      }
    });
  });
  return loadedActivityLogs;
};
export const loadActivityLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IActivityDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const activityTags = getState().logging.activityTags;
    let loadedActivityLogs: IActivityDto[];
    if (id) {
      loadedActivityLogs = await getActivityLogsBackend(id, activityTags, fromDate, toDate);
    } else {
      loadedActivityLogs = await getActivityLogsBackend(
        userData.id,
        activityTags,
        fromDate,
        toDate,
      );
    }
    dispatch({type: loggingActions.LOAD_ACTIVITY_LOG, payload: loadedActivityLogs});
    return loadedActivityLogs;
  };
};
export const addActivityLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const activityTags = getState().logging.activityTags;
      await addActivityDatabase(data);
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      const loadedActivityLogs: IActivityDto[] = await getActivityLogsBackend(
        userData.id,
        activityTags,
        fromTime,
      );
      dispatch({type: loggingActions.LOAD_ACTIVITY_LOG, payload: loadedActivityLogs});
      Snackbar.success(i18n.t('LogsPage.activityWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const editActivityLogg = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const activityTags = getState().logging.activityTags;
      await editActivityDatabase(data);
      const loadedActivityLogs: IActivityDto[] = await getActivityLogsBackend(
        userData.id,
        activityTags,
      );
      dispatch({type: loggingActions.LOAD_ACTIVITY_LOG, payload: loadedActivityLogs});
      Snackbar.success(i18n.t('LogsPage.activityWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteActivity = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteActivityDatabase(id);
    dispatch({type: loggingActions.DELETE_ACTIVITY_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const loadAllTagsWithCategories = (): ((dispatch: Dispatch) => Promise<void>) => {
  const loadedCategoryTags: ICategoryTagDto[] = [];
  return async (dispatch: Dispatch) => {
    const response = await getCategoryTags('', true);
    response.forEach((oneCategoryTagDto) => {
      oneCategoryTagDto.tags.forEach((oneTag) => {
        oneTag.isVisible = true;
      });

      loadedCategoryTags.push(oneCategoryTagDto);
    });

    dispatch({type: loggingActions.LOAD_CATEGORY_TAGS, payload: loadedCategoryTags});
    // return loadedCategoryTags;
  };
};

export const loadUsersCategoryWithTags = (): ((
  dispatch: Dispatch,
  getState: any,
) => Promise<void>) => {
  const loadedCategoryTags: IUserCategoryTagDto[] = [];
  return async (dispatch: Dispatch, getState: any) => {
    const categoryTags: IUserCategoryTagDto[] = getState().logging.userTags;
    if (categoryTags?.length > 0)
      dispatch({type: loggingActions.LOAD_USERS_TAGS, payload: categoryTags});
    else {
      const response = await getUserCategoryTags();
      let helperTags: IUserCategoryTagDto = {
        id: 0,
        name: '',
        color: '',
        fontColor: '',
        hoverColor: '',
        tags: [],
      };
      response.forEach((oneCategoryTagDto) => {
        oneCategoryTagDto.tags.forEach((oneTag) => {
          oneTag.isVisible = true;
        });
        if (oneCategoryTagDto.name === GROUP_TAGS) {
          helperTags = {
            id: oneCategoryTagDto.id,
            name: oneCategoryTagDto.name,
            fontColor: oneCategoryTagDto.fontColor,
            color: oneCategoryTagDto.color,
            hoverColor: oneCategoryTagDto.hoverColor,
            tags: [],
          };
          oneCategoryTagDto?.subCategories?.forEach((subCategory) => {
            subCategory.tags.forEach((tag) => {
              tag.isVisible = true;
              helperTags.tags.push(tag);
            });
          });
          return;
        }
        loadedCategoryTags.push(oneCategoryTagDto);
      });
      loadedCategoryTags.push(helperTags);

      dispatch({type: loggingActions.LOAD_USERS_TAGS, payload: loadedCategoryTags});
    }
  };
};

export const loadStressTags = (): ((dispatch: Dispatch) => Promise<void>) => {
  const loadedStressTags: ICasualTag[] = [];
  return async (dispatch: Dispatch) => {
    const response = await getStressTags();
    response.forEach((oneTag) => {
      oneTag.isVisible = true;
      loadedStressTags.push(oneTag);
    });
    dispatch({type: loggingActions.LOAD_STRESS_TAGS, payload: loadedStressTags});
  };
};

export const loadMoodTags = (): ((dispatch: Dispatch) => Promise<void>) => {
  const loadedMoodTags: ICasualTag[] = [];
  return async (dispatch: Dispatch) => {
    const response = await getMoodTags();
    response.forEach((oneTag) => {
      oneTag.isVisible = true;
      loadedMoodTags.push(oneTag);
    });
    dispatch({type: loggingActions.LOAD_MOOD_TAGS, payload: loadedMoodTags});
  };
};

export const getStressLogsBackend = async (
  id: string,
  stressTags: ICasualTag[],
  moodTags: ICasualTag[],
  fromDate?: string,
  toDate?: string,
): Promise<IStressDto[]> => {
  //const fromTime = addTime(moment(), -2, 'days').utc().format();
  const loadedStressLogs: IStressDto[] = await getStressLogs(id, fromDate, toDate);
  let tag: ICasualTag;
  loadedStressLogs.forEach((stressLog) => {
    stressLog.tagsObject = [];
    stressLog.moodTagsObject = [];
    stressLog.tags.forEach((tagId: number) => {
      const selectedTag: ICasualTag | undefined = stressTags ?  stressTags?.find(
        (oneTag: ICasualTag) => oneTag.id == tagId,
      ) : ''; 
 
      if (selectedTag !== undefined) {
        tag = {
          id: selectedTag.id,
          name: selectedTag.name,
        };
        stressLog.tagsObject.push(tag);
      }
    });

    stressLog.moodTags.forEach((tagId: number) => {
      const selectedTag: ICasualTag | undefined = moodTags.find(
        (oneTag: ICasualTag) => oneTag.id == tagId,
      );

      if (selectedTag !== undefined) {
        tag = {
          id: selectedTag.id,
          name: selectedTag.name,
        };
        stressLog.moodTagsObject.push(tag);
      }
    });
  });
  return loadedStressLogs;
};

export const loadStressLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IStressDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const stressTags = getState().logging.stressTags;
    const moodTags = getState().logging.moodTags;
    let loadedStressLogs: IStressDto[];
    if (id) {
      loadedStressLogs = await getStressLogsBackend(id, stressTags, moodTags, fromDate, toDate);
    } else {
      loadedStressLogs = await getStressLogsBackend(
        userData.id,
        stressTags,
        moodTags,
        fromDate,
        toDate,
      );
    }
    dispatch({type: loggingActions.LOAD_STRESS_LOG, payload: loadedStressLogs});
    return loadedStressLogs;
  };
};
export const addStressLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const stressTags = getState().logging.stressTags;
      const moodTags = getState().logging.moodTags;
      await addStressDatabase(data);
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      const loadedStressLogs: IStressDto[] = await getStressLogsBackend(
        userData.id,
        stressTags,
        moodTags,
        fromTime,
      );
      dispatch({type: loggingActions.LOAD_STRESS_LOG, payload: loadedStressLogs});
      Snackbar.success(i18n.t('LogsPage.stressWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editStressLogg = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const stressTags = getState().logging.stressTags;
      const moodTags = getState().logging.moodTags;
      await editStressDatabase(data);
      const loadedStressLogs: IStressDto[] = await getStressLogsBackend(
        userData.id,
        stressTags,
        moodTags,
      );
      dispatch({type: loggingActions.LOAD_STRESS_LOG, payload: loadedStressLogs});
      Snackbar.success(i18n.t('LogsPage.stressWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteStressLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteStressDatabase(id);
    dispatch({type: loggingActions.DELETE_STRESS_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const loadSleepTags = (): ((dispatch: Dispatch) => Promise<void>) => {
  const loadedSleepTags: ICasualTag[] = [];
  return async (dispatch: Dispatch) => {
    const response = await getSleepTags();
    response.forEach((oneTag) => {
      oneTag.isVisible = true;
      loadedSleepTags.push(oneTag);
    });
    dispatch({type: loggingActions.LOAD_SLEEP_TAGS, payload: loadedSleepTags});
  };
};
export const getSleepLogsBackend = async (
  id: string,
  sleepTags: ICasualTag[],
  fromDate?: string,
  toDate?: string,
): Promise<ISleepDto[]> => {
  //const fromTime = addTime(moment(), -2, 'days').utc().format();
  const loadedSleepLogs: ISleepDto[] = await getSleepLogs(id, fromDate, toDate);
  let tag: ICasualTag;
  loadedSleepLogs.forEach((sleepLog) => {
    sleepLog.tagsObject = [];
    sleepLog.tags.forEach((tagId: number) => {
      const selectedTag: ICasualTag | undefined = sleepTags.find(
        (oneTag: ICasualTag) => oneTag.id == tagId,
      );

      if (selectedTag !== undefined) {
        tag = {
          id: selectedTag.id,
          name: selectedTag.name,
        };
        sleepLog.tagsObject.push(tag);
      }
    });
  });
  return loadedSleepLogs;
};

export const loadSleepLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<ISleepDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const sleepTags = getState().logging.sleepTags;
    let loadedSleepLogs: ISleepDto[];
    if (id) {
      loadedSleepLogs = await getSleepLogsBackend(id, sleepTags, fromDate, toDate);
    } else {
      loadedSleepLogs = await getSleepLogsBackend(userData.id, sleepTags, fromDate, toDate);
    }
    dispatch({type: loggingActions.LOAD_SLEEP_LOG, payload: loadedSleepLogs});
    return loadedSleepLogs;
  };
};
export const addSleepLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const sleepTags = getState().logging.sleepTags;
      await addSleepDatabase(data);
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      const loadedSleepLogs: ISleepDto[] = await getSleepLogsBackend(
        userData.id,
        sleepTags,
        fromTime,
      );
      dispatch({type: loggingActions.LOAD_SLEEP_LOG, payload: loadedSleepLogs});
      Snackbar.success(i18n.t('LogsPage.sleepWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editSleepLogg = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const sleepTags = getState().logging.sleepTags;
      await editSleepDatabase(data);
      const loadedSleepLogs: ISleepDto[] = await getSleepLogsBackend(userData.id, sleepTags);
      dispatch({type: loggingActions.LOAD_SLEEP_LOG, payload: loadedSleepLogs});
      Snackbar.success(i18n.t('LogsPage.sleepWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteSleepLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteSleepDatabase(id);
    dispatch({type: loggingActions.DELETE_SLEEP_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const loadAllTagsWithCategoriesFiltered = async (
  searchFilter: string,
): Promise<ICategoryTagDto[]> => {
  const loadedCategoryTags: ICategoryTagDto[] = [];
  const response = await getCategoryTags(searchFilter, true);
  response.forEach((oneCategoryTagDto) => {
    oneCategoryTagDto.tags.forEach((oneTag) => {
      oneTag.isVisible = true;
    });

    loadedCategoryTags.push(oneCategoryTagDto);
  });

  return loadedCategoryTags;
};

export const loadBowelTags = (): ((dispatch: Dispatch) => Promise<void>) => {
  const loadedBowelTags: ICasualTag[] = [];
  return async (dispatch: Dispatch) => {
    const response = await getBowelTags();
    response.forEach((oneTag) => {
      oneTag.isVisible = true;
      loadedBowelTags.push(oneTag);
    });
    dispatch({type: loggingActions.LOAD_BOWEL_TAGS, payload: loadedBowelTags});
  };
};
export const getBowelLogsBackend = async (
  id: string,
  bowelTags: ICasualTag[],
  fromDate?: string,
  toDate?: string,
): Promise<IBowelDto[]> => {
  //const fromTime = addTime(moment(), -2, 'days').utc().format();
  const loadedBowelLogs: IBowelDto[] = await getBowelLogs(id, fromDate, toDate);
  let tag: ICasualTag;
  loadedBowelLogs.forEach((bowelLog) => {
    bowelLog.tagsObject = [];
    bowelLog.tags.forEach((tagId: number) => {
      const selectedTag: ICasualTag | undefined = bowelTags.find(
        (oneTag: ICasualTag) => oneTag.id == tagId,
      );

      if (selectedTag !== undefined) {
        tag = {
          id: selectedTag.id,
          name: selectedTag.name,
        };
        bowelLog.tagsObject.push(tag);
      }
    });
  });
  return loadedBowelLogs;
};

export const loadBowelLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IBowelDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const bowelTags = getState().logging.bowelTags;
    let loadedBowelLogs: IBowelDto[];
    if (id) {
      loadedBowelLogs = await getBowelLogsBackend(id, bowelTags, fromDate, toDate);
    } else {
      loadedBowelLogs = await getBowelLogsBackend(userData.id, bowelTags, fromDate, toDate);
    }
    dispatch({type: loggingActions.LOAD_BOWEL_LOG, payload: loadedBowelLogs});
    return loadedBowelLogs;
  };
};
export const addBowelLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const bowelTags = getState().logging.bowelTags;
      await addBowelDatabase(data);
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      const loadedBowelLogs: IBowelDto[] = await getBowelLogsBackend(
        userData.id,
        bowelTags,
        fromTime,
      );
      dispatch({type: loggingActions.LOAD_BOWEL_LOG, payload: loadedBowelLogs});
      Snackbar.success(i18n.t('LogsPage.bowelWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editBowelLogg = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const bowelTags = getState().logging.bowelTags;
      await editBowelDatabase(data);
      const loadedBowelLogs: IBowelDto[] = await getBowelLogsBackend(userData.id, bowelTags);
      dispatch({type: loggingActions.LOAD_BOWEL_LOG, payload: loadedBowelLogs});
      Snackbar.success(i18n.t('LogsPage.bowelWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteBowelLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteBowelDatabase(id);
    dispatch({type: loggingActions.DELETE_BOWEL_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getDrinkLogsBackend = async (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IDrinkDto[]> => {
  // const fromTime = addTime(moment(), -2, 'days').utc().format();
  const loadedDrinkLogs: IDrinkDto[] = await getDrinkLogs(id, fromDate, toDate);
  return loadedDrinkLogs;
};

export const loadDrinkLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IDrinkDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    let loadedDrinkLogs: IDrinkDto[];
    if (id) {
      loadedDrinkLogs = await getDrinkLogsBackend(id, fromDate, toDate);
    } else {
      loadedDrinkLogs = await getDrinkLogsBackend(userData.id, fromDate, toDate);
    }
    dispatch({type: loggingActions.LOAD_DRINK_LOG, payload: loadedDrinkLogs});
    return loadedDrinkLogs;
  };
};
export const addDrinkLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await addDrinkDatabase(data);
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      const loadedDrinkLogs: IDrinkDto[] = await getDrinkLogsBackend(userData.id, fromTime);
      dispatch({type: loggingActions.LOAD_DRINK_LOG, payload: loadedDrinkLogs});
      Snackbar.success(i18n.t('LogsPage.drinkWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editDrinkLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editDrinkDatabase(data);
      const loadedDrinkLogs: IDrinkDto[] = await getDrinkLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_DRINK_LOG, payload: loadedDrinkLogs});
      Snackbar.success(i18n.t('LogsPage.drinkWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteDrinkLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteDrinkDatabase(id);
    dispatch({type: loggingActions.DELETE_DRINK_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getStepsLogsBackend = async (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IStepsDto[]> => {
  // const fromTime = addTime(moment(), -2, 'days').utc().format();
  const loadedStepsLog: IStepsDto[] = await getStepsLogs(id, fromDate, toDate);
  return loadedStepsLog;
};


export const loadStepsLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IStepsDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    let loadedStepsLog: IStepsDto[];
    if (id) {
      loadedStepsLog = await getStepsLogsBackend(id, fromDate, toDate);
    } else {
      loadedStepsLog = await getStepsLogsBackend(userData.id, fromDate, toDate);
    }
    dispatch({type: loggingActions.LOAD_STEPS_LOG, payload: loadedStepsLog});
    return loadedStepsLog;
  };
};
export const addStepsLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await addStepsDatabase(data);
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      const loadedStepsLog: IStepsDto[] = await getStepsLogsBackend(userData.id, fromTime);
      dispatch({type: loggingActions.LOAD_STEPS_LOG, payload: loadedStepsLog});
      Snackbar.success(i18n.t('LogsPage.stepsWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editStepsLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editStepsDatabase(data);
      const loadedStepsLog: IStepsDto[] = await getStepsLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_STEPS_LOG, payload: loadedStepsLog});
      Snackbar.success(i18n.t('LogsPage.stepsWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteStepsLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteStepsDatabase(id);
    dispatch({type: loggingActions.DELETE_STEPS_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getBodyMeasurementLogsBackend = async (id: string): Promise<IBodyMeasurementDto[]> => {
  const fromTime = addTime(moment(), -2, 'days').utc().format();
  return await getBodyMeasurementLogs(id, fromTime);
};
export const getBodyMeasurementActivityLogs = async (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IBloodPressureDto[]> => {
  const loadedBloodPressureLog: any[] = await getBodyMeasurementDataLogs(
    id,
    fromDate,
    toDate,
  );
  return loadedBloodPressureLog;
};

export const loadBodyMeasurementLogs = (): ((
  dispatch: Dispatch,
  getState: any,
) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const loadedBodyMeasurementLogs: IBodyMeasurementDto[] = await getBodyMeasurementLogsBackend(
      userData.id,
    );
    dispatch({type: loggingActions.LOAD_BODY_MEASUREMENT_LOG, payload: loadedBodyMeasurementLogs});
  };
};

export const addBodyMeasurementLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await addBodyMeasurementDatabase(data);
      const loadedBodyMeasurementLog: IBodyMeasurementDto[] = await getBodyMeasurementLogsBackend(
        userData.id,
      );
      dispatch({type: loggingActions.LOAD_BODY_MEASUREMENT_LOG, payload: loadedBodyMeasurementLog});
      Snackbar.success(i18n.t('LogsPage.bodyMeasurementWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editBodyMeasurementLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editBodyMeasurementDatabase(data);
      const loadedBodyMeasurement: IBodyMeasurementDto[] = await getBodyMeasurementLogsBackend(
        userData.id,
      );
      dispatch({type: loggingActions.LOAD_BODY_MEASUREMENT_LOG, payload: loadedBodyMeasurement});
      Snackbar.success(i18n.t('LogsPage.bodyMeasurementWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const deleteBodyMeasurementLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteBodyMeasurementDatabase(id);
    dispatch({type: loggingActions.DELETE_BODY_MEASUREMENT_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getBloodPressureLogsBackend = async (id: string): Promise<IBloodPressureDto[]> => {
  const fromTime = addTime(moment(), -2, 'days').utc().format();
  const loadedBloodPressureLog: IBloodPressureDto[] = await getBloodPressureLogs(id, fromTime);
  return loadedBloodPressureLog;
};
export const getBloodPressureActivityLogs = async (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IBloodPressureDto[]> => {
  const loadedBloodPressureLog: IBloodPressureDto[] = await getBloodPressureDataLogs(
    id,
    fromDate,
    toDate,
  );
  return loadedBloodPressureLog;
};

export const loadBloodPressureLogs = (): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const loadedBloodPressureLog: IBloodPressureDto[] = await getBloodPressureLogsBackend(
      userData.id,
    );
    dispatch({type: loggingActions.LOAD_BLOOD_PRESSURE_LOG, payload: loadedBloodPressureLog});
  };
};
export const addBloodPressureLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await addBloodPressureDatabase(data);
      const loadedBloodPressureLog: IBloodPressureDto[] = await getBloodPressureLogsBackend(
        userData.id,
      );
      dispatch({type: loggingActions.LOAD_BLOOD_PRESSURE_LOG, payload: loadedBloodPressureLog});
      Snackbar.success(i18n.t('LogsPage.bloodPressureWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editBloodPressureLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editBloodPressureDatabase(data);
      const loadedBloodPressure: IBloodPressureDto[] = await getBloodPressureLogsBackend(
        userData.id,
      );
      dispatch({type: loggingActions.LOAD_BLOOD_PRESSURE_LOG, payload: loadedBloodPressure});
      Snackbar.success(i18n.t('LogsPage.bloodPressureWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteBloodPressureLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteBloodPressureDatabase(id);
    dispatch({type: loggingActions.DELETE_BLOOD_PRESSURE_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getBloodGlucoseLogsBackend = async (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IBloodGlucoseDto[]> => {
  //const fromTime = addTime(moment(), -2, 'days').utc().format();
  return await getBloodGlucoseLogs(id, fromDate, toDate);
};

export const loadBloodGlucoseLogs = (
  fromDate?: string,
  toDate?: string,
  id?: string,
): ((dispatch: Dispatch, getState: any) => Promise<IBloodGlucoseDto[]>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    let loadedBloodGlucoseLog: IBloodGlucoseDto[];
    if (id) {
      loadedBloodGlucoseLog = await getBloodGlucoseLogsBackend(id, fromDate, toDate);
    } else {
      loadedBloodGlucoseLog = await getBloodGlucoseLogsBackend(userData.id, fromDate, toDate);
    }
    dispatch({type: loggingActions.LOAD_BLOOD_GLUCOSE_LOG, payload: loadedBloodGlucoseLog});
    return loadedBloodGlucoseLog;
  };
};
export const addBloodGlucoseLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      const fromTime = addTime(moment(), -2, 'days').utc().format();
      await addBloodGlucoseDatabase(data);
      const loadedBloodGlucoseLog: IBloodGlucoseDto[] = await getBloodGlucoseLogsBackend(
        userData.id,
        fromTime,
      );
      dispatch({type: loggingActions.LOAD_BLOOD_GLUCOSE_LOG, payload: loadedBloodGlucoseLog});
      Snackbar.success(i18n.t('LogsPage.bloodGlucoseWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editBloodGlucoseLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editBloodGlucoseDatabase(data);
      const loadedBloodGlucose: IBloodGlucoseDto[] = await getBloodGlucoseLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_BLOOD_GLUCOSE_LOG, payload: loadedBloodGlucose});
      Snackbar.success(i18n.t('LogsPage.bloodGlucoseWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteBloodGlucoseLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteBloodGlucoseDatabase(id);
    dispatch({type: loggingActions.DELETE_BLOOD_GLUCOSE_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const saveLoadedAchievement = (data: IAchievementDto): ICheckAchievement => {
  return {type: loggingActions.CHECK_ACHIEVEMENT, payload: data};
};

export const checkAchievement = (
  type: string,
): ((dispatch: Dispatch, getState: any) => Promise<any>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const user = getState().users.currentUser;
    const achievement = await checkAchievementDatabase(user.id, type);
    dispatch(saveLoadedAchievement(achievement));
  };
};

export const getBodyPainLogsBackend = async (id: string): Promise<IBodyPainDto[]> => {
  const fromTime = addTime(moment(), -2, 'days').utc().format();
  return await getBodyPainLogs(id, fromTime);
};

export const getBodyPainLogsBackendActivity = async ( id: string,
  fromDate: string,
  toDate: string
  ): Promise<IBodyPainDto[]> => {
  return await getBodyPainLogsActivity(id, fromDate, toDate);
};


export const loadBodyPainLogs = (): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const loadedBodyPainLog: IBodyPainDto[] = await getBodyPainLogsBackend(userData.id);
    dispatch({type: loggingActions.LOAD_BODY_PAIN_LOG, payload: loadedBodyPainLog});
  };
};
export const addBodyPainLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await addBodyPainDatabase(data);
      const loadedBodyPainLog: IBodyPainDto[] = await getBodyPainLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_BODY_PAIN_LOG, payload: loadedBodyPainLog});
      Snackbar.success(i18n.t('LogsPage.bodyPainWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editBodyPainLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editBodyPainDatabase(data);
      const loadedBodyPain: IBodyPainDto[] = await getBodyPainLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_BODY_PAIN_LOG, payload: loadedBodyPain});
      Snackbar.success(i18n.t('LogsPage.bodyPainWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteBodyPainLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteBodyPainDatabase(id);
    dispatch({type: loggingActions.DELETE_BODY_PAIN_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getA1CLogsBackend = async (id: string): Promise<IA1CDto[]> => {
  const fromTime = addTime(moment(), -2, 'days').utc().format();
  return await getA1CLogs(id, fromTime);
};
export const getA1CLogsBackendActivity = async (id: string,
  fromDate: string | undefined,
  toDate: string,
  ): Promise<IA1CDto[]> => {
  return await getA1CLogsActivity(id, fromDate, toDate);
};

export const getCholesterolBackendActivity = async (id: string,
  fromDate: string | undefined,
  toDate: string,
  ): Promise<IA1CDto[]> => {
  return await getCholesterolDataLogs(id, fromDate, toDate);
};

export const loadA1CLogs = (): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const loadedA1CLog: IA1CDto[] = await getA1CLogsBackend(userData.id);
    dispatch({type: loggingActions.LOAD_A1C_LOG, payload: loadedA1CLog});
  };
};
export const addA1CLog = (data: any): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await addA1CDatabase(data);
      const loadedA1C: IA1CDto[] = await getA1CLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_A1C_LOG, payload: loadedA1C});
      Snackbar.success(i18n.t('LogsPage.bloodLabsWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editA1CLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editA1CDatabase(data);
      const loadedA1C: IA1CDto[] = await getA1CLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_A1C_LOG, payload: loadedA1C});
      Snackbar.success(i18n.t('LogsPage.bloodLabsWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteA1CLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteA1CDatabase(id);
    dispatch({type: loggingActions.DELETE_A1C_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getCholesterolLogsBackend = async (id: string): Promise<ICholesterolDto[]> => {
  const fromTime = addTime(moment(), -2, 'days').utc().format();
  return await getCholesterolLogs(id, fromTime);
};

export const loadCholesterolLogs = (): ((dispatch: Dispatch, getState: any) => Promise<void>) => {
  return async (dispatch: Dispatch, getState: any) => {
    const userData = getState().users.currentUser;
    const loadedCholesterolLog: ICholesterolDto[] = await getCholesterolLogsBackend(userData.id);
    dispatch({type: loggingActions.LOAD_CHOLESTEROL_LOG, payload: loadedCholesterolLog});
  };
};
export const addCholesterolLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;

      await addCholesterolDatabase(data);
      const loadedCholesterol: ICholesterolDto[] = await getCholesterolLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_CHOLESTEROL_LOG, payload: loadedCholesterol});
      Snackbar.success(i18n.t('LogsPage.bloodLabsWidget.snackbarAdded'));
      return true;
    } catch (err) {
      return false;
    }
  };
};

export const editCholesterolLog = (
  data: any,
): ((dispatch: Dispatch, getState: any) => Promise<boolean>) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const userData = getState().users.currentUser;
      await editCholesterolDatabase(data);
      const loadedCholesterol: ICholesterolDto[] = await getCholesterolLogsBackend(userData.id);
      dispatch({type: loggingActions.LOAD_CHOLESTEROL_LOG, payload: loadedCholesterol});
      Snackbar.success(i18n.t('LogsPage.bloodLabsWidget.snackbarEdited'));
      return true;
    } catch (err) {
      return false;
    }
  };
};
export const deleteCholesterolLog = (id: number): ((dispatch: Dispatch) => Promise<void>) => {
  return async (dispatch: Dispatch) => {
    await deleteCholesterolDatabase(id);
    dispatch({type: loggingActions.DELETE_CHOLESTEROL_LOG, payload: id});
    Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
  };
};

export const getStressLog = async (id: number, stressTags: any, moodTags: any): Promise<any> => {
  const stressLog = await getStressLogById(id);
  let tag: ICasualTag;
  stressLog.tagsObject = [];
  stressLog.moodTagsObject = [];
  stressLog.tags.forEach((tagId: number) => {
    const selectedTag: ICasualTag | undefined = stressTags ?  stressTags?.find(
      (oneTag: ICasualTag) => oneTag.id == tagId,
    ) : '';

    if (selectedTag !== undefined) {
      tag = {
        id: selectedTag.id,
        name: selectedTag.name,
      };
      stressLog.tagsObject.push(tag);
    }
  });
  stressLog.moodTags.forEach((tagId: number) => {
    const selectedTag: ICasualTag | undefined = moodTags.find(
      (oneTag: ICasualTag) => oneTag.id == tagId,
    );

    if (selectedTag !== undefined) {
      tag = {
        id: selectedTag.id,
        name: selectedTag.name,
      };
      stressLog.moodTagsObject.push(tag);
    }
  });
  return stressLog;
};

export const getActivityLog = async (
  id: number,
  activityTags: ICasualTag[],
): Promise<IActivityDto> => {
  const loadedActivityLog = await getActivityLogById(id);
  let tag: ICasualTag;

  loadedActivityLog.tagsObject = [];
  loadedActivityLog.tags.forEach((tagId: number) => {
    const selectedTag: ICasualTag | undefined = activityTags.find(
      (oneTag: ICasualTag) => oneTag.id == tagId,
    );

    if (selectedTag !== undefined) {
      tag = {
        id: selectedTag.id,
        name: selectedTag.name,
      };
      loadedActivityLog.tagsObject.push(tag);
    }
  });

  return loadedActivityLog;
};

export const getFoodLog = async (
  id: number,
  categoryTagsList: ICategoryTagDto[],
): Promise<IFoodDto> => {
  const foodLog: IFoodDto = await getFoodLogById(id);
  let tag: ITag;

  foodLog.tagsObjects = [];
  foodLog.tags.forEach((tagId: number) => {
    const selectedCategory: ICategoryTagDto | undefined = categoryTagsList?.find((oneCategory) =>
      oneCategory.tags.some((oneTag) => oneTag.id === tagId),
    );

    const selectedTag: ITagDto | undefined = selectedCategory?.tags.find(
      (oneTag) => oneTag.id == tagId,
    );

    if (selectedTag !== undefined && selectedCategory != undefined) {
      tag = {
        id: selectedTag.id,
        name: selectedTag.name,
        isLevel2: selectedTag.isLevel2,
        categoryId: selectedTag.categoryId,
        color: selectedTag.color,
        borderColor: selectedTag.color,
        hoverColor: selectedTag.hoverColor,
        textColor: selectedTag.textColor,
      };

      foodLog.tagsObjects.push(tag);
    }
  });
  return foodLog;
};

export const getSleepLog = async (id: number, sleepTags: ICasualTag[]): Promise<ISleepDto> => {
  const sleepLog: ISleepDto = await getSleepLogById(id);
  let tag: ICasualTag;

  sleepLog.tagsObject = [];
  sleepLog.tags.forEach((tagId: number) => {
    const selectedTag: ICasualTag | undefined = sleepTags.find(
      (oneTag: ICasualTag) => oneTag.id == tagId,
    );

    if (selectedTag !== undefined) {
      tag = {
        id: selectedTag.id,
        name: selectedTag.name,
      };
      sleepLog.tagsObject.push(tag);
    }
  });
  return sleepLog;
};

export const getBowelLog = async (id: number, bowelTags: ICasualTag[]): Promise<IBowelDto> => {
  const bowelLog: IBowelDto = await getBowelLogById(id);
  let tag: ICasualTag;

  bowelLog.tagsObject = [];
  bowelLog.tags.forEach((tagId: number) => {
    const selectedTag: ICasualTag | undefined = bowelTags.find(
      (oneTag: ICasualTag) => oneTag.id == tagId,
    );

    if (selectedTag !== undefined) {
      tag = {
        id: selectedTag.id,
        name: selectedTag.name,
      };
      bowelLog.tagsObject.push(tag);
    }
  });

  return bowelLog;
};

export const loadLogById = (
  id: number,
  logType: string,
  isCholesterol?: boolean,
): ((dispatch: Dispatch, getState: any) => Promise<IStressDto>) => {
  // eslint-disable-next-line
  //@ts-ignore
  return async (dispatch: Dispatch, getState: any) => {
    let moodTags;
    let tags;

    switch (logType) {
      case LoggedWidgetType.BODY_WEIGHT:
        return await getBodyWeightLogById(id);

      case LoggedWidgetType.FOOD:
        tags = getState().logging.categoryTags;
        return await getFoodLog(id, tags);

      case LoggedWidgetType.ACTIVITY:
        tags = getState().logging.activityTags;
        return await getActivityLog(id, tags);

      case LoggedWidgetType.STRESS:
        tags = getState().logging.stressTags;
        moodTags = getState().logging.moodTags;
        return await getStressLog(id, tags, moodTags);

      case LoggedWidgetType.WATER:
        return await getDrinkLogById(id);

      case LoggedWidgetType.SLEEP:
        tags = getState().logging.sleepTags;
        return await getSleepLog(id, tags);

      case LoggedWidgetType.BOWEL_MOVEMENT:
        tags = getState().logging.bowelTags;
        return await getBowelLog(id, tags);

      case LoggedWidgetType.STEPS:
        return await getStepsLogById(id);

      case LoggedWidgetType.BLOOD_PRESSURE.toString():
        return await getBloodPressureLogById(id);

      case LoggedWidgetType.BODY_MEASUREMENTS.toString():
        return await getBodyMeasurementLogById(id);

      case LoggedWidgetType.BLOOD_GLUCOSE.toString():
        return await getBloodGlucoseLogById(id);
      case LoggedWidgetType.BLOOD_LABS.toString():
        if (isCholesterol) {
          return await getCholesterolLogById(id);
        } else return await getA1CLogById(id);
      case LoggedWidgetType.BODY_PAIN.toString():
        return await getBodyPainLogById(id);
      default:
        return null;
    }
  };
};


export const getActivityFilterLogs = async (
  id: string,
  fromDate?: string,
  toDate?: string,
  type?: string,
): Promise<IStepsDto[]> => {
  let activityFilterData: any;
  switch (type) {
    case 'Body Weight':
      activityFilterData = await getWeightLoggsBackend(id, fromDate, toDate);
      break;
    case 'Food':
      activityFilterData = await getFoodLoggs(id, fromDate, toDate);
      break;
    case 'Activity':
      activityFilterData = await getActivityLogs(id, fromDate, toDate);
      break;
    case 'Mental Health':
      activityFilterData = await getStressLogs(id, fromDate, toDate);
      break;
    case 'Water':
      activityFilterData = await getDrinkLogsBackend(id, fromDate, toDate);
      break;
    case 'Sleep':
      activityFilterData = await getSleepLogs(id, fromDate, toDate);
      break;
    case 'Bowel Movement':
      activityFilterData = await getBowelLogs(id, fromDate, toDate);
      break;
    case 'Steps':
      activityFilterData = await getStepsLogsBackend(id, fromDate, toDate);
      break;
    case 'Blood Pressure':
      activityFilterData = await getBloodPressureActivityLogs(id, fromDate, toDate);
      break;
    case 'Blood Glucose':
      activityFilterData = await getBloodGlucoseLogsBackend(id, fromDate, toDate);
      break;
    case 'Body Measurements':
      activityFilterData = await getBodyMeasurementActivityLogs(id, fromDate, toDate); 
      break;
    case 'Blood Labs':
      // eslint-disable-next-line no-case-declarations
      const data = await getCholesterolBackendActivity(id, fromDate, toDate);
      // eslint-disable-next-line no-case-declarations
      const newData = await getA1CLogsBackendActivity(id, fromDate, toDate);
      activityFilterData = await data.concat(newData);
      break;
    case 'Body Pain':
      activityFilterData = await getBodyPainLogsBackendActivity(id, fromDate, toDate);
      break;
    default:
      activityFilterData = await getStepsLogs(id, fromDate, toDate);
    // Handle the case when none of the conditions match.
  }

  return activityFilterData;
};

export const deleteFilterLogs = async (
  id: any,
  type?: string,
): Promise<IStepsDto[]> => {
  let activityFilterData: any;
  switch (type) {
    case 'Body Weight':
       await deleteWeightLoggDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Food':
      await deleteFoodLoggDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Activity':
      await deleteActivityDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Mental Health':
      await  await deleteStressDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Water':
      await deleteDrinkDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Sleep':
      await deleteSleepDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Bowel Movement':
      await deleteBowelDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Steps':
      activityFilterData =  await deleteStepsDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Blood Pressure':
      await deleteBloodPressureDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Blood Glucose':
      await deleteBloodGlucoseDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Body Measurements':
     deleteBodyMeasurementDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
      case 'Cholesterol' : 
      await deleteCholesterolDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Blood Labs':
       await deleteA1CDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    case 'Body Pain':
      await deleteBodyPainDatabase(id);
      Snackbar.success(i18n.t('Messages.deletedSuccessfully'));
      activityFilterData = true;
      break;
    default:
      activityFilterData = false;
    // Handle the case when none of the conditions match.
  }

  return activityFilterData;
};
