import {IStepsDto} from '../dtos/IStepDto';
import {del, get, post, put} from './api-calls';

export const getStepsLogs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IStepsDto[]> => {
  return get(`/step?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addStepsDatabase = (data: IStepsDto): Promise<IStepsDto> => {
  return post('/step', data);
};

export const editStepsDatabase = (data: IStepsDto): Promise<IStepsDto> => {
  return put(`/step/${data.id}`, data);
};

export const deleteStepsDatabase = (id: number): Promise<any> => {
  return del(`/step/${id}`);
};

export const getStepsLogById = (id: number): Promise<IStepsDto> => {
  return get(`/steps/${id}`);
};
