import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '100px auto',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90%',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginTop: '70px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
  },
}));

export default useStyles;
