import * as React from 'react';
import useStyles from './mindfullnessDetails-style';
import ReactPlayer from 'react-player';
import {Typography} from '@material-ui/core';

interface Props {
  videoUrl: string;
  isMobileOrTablet: any;
  smallScreens: any;
}

export const MindfulnessDetailsView: React.FC<Props> = ({
  isMobileOrTablet,
  smallScreens,
  videoUrl,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });

  return (
    <div className={classes.recipesContainer}>
      <div className={classes.mainContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 300,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '60%',
            }}
          >
            <Typography align="left" variant={'h4'} style={{fontWeight: 'bold', marginTop: 15}}>
              Demo For Video
            </Typography>
            <Typography variant={'caption'} style={{ marginTop: 25}}>
              This is the video for the demo please check out the video and please confirm it is
              working fine and what other changes required in this section currently no data for
              this option is coming from the strapi so we putted some demo data and putted a video
              in the strip which is played below.
            </Typography>

            <Typography variant={'h6'} style={{ marginTop: 25}}>
             When we will get data will display data here like other Options
            </Typography>
            <Typography variant={'caption'} style={{ marginTop: 25}}>
              This is the video for the demo please check out the video and please confirm it is
              working fine and what other changes required in this section currently no data for
              this option is coming from the strapi so we putted some demo data and putted a video
              in the strip which is played below.
            </Typography>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            // marginTop: '40%',
          }}
        >
          <ReactPlayer
            url={videoUrl}
            width="40%"
            height="40%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
            controls={true}
            
          />
        </div>
      </div>
      <></>
    </div>
  );
};
