import * as React from 'react';
import {Backdrop, Box, Modal, Fade} from '@material-ui/core';
import useStyles from './LetsStart.styles';
import { Button} from '../../button/Button';
// import dayoneBanner from '../../../../assets/images/dayoneBanner.png';
// import betr from '../../../../assets/images/betr.png';
import schedule from '../../../../assets/images/schedule.svg';
import spacebetween from '../../../../assets/images/spacebetween.png';
import videocam_off from '../../../../assets/images/videocam_off.png';
import IframeKemtai from '../iframe-kemtai/IframeKemtai';
import { useDispatch, useSelector } from 'react-redux';
import { getKemTaiUserWorkouts, getKemTaiUserWorkoutsList } from '../../../../store/actions/kemtai/kemtai-action';
import closeWhiteIcon from '../../../../assets/images/close-white.svg';

interface Props {
    isLetsStart: boolean;
    setIsLetsStart: (status: boolean) => void;
    // setIsAgreement: (status: boolean) => void;
    selectedWorkoutID?: any;
  }

const LetsStart: React.FC<Props> = ({isLetsStart, setIsLetsStart, selectedWorkoutID}) => {
    const [isKemtaiIframe, setIsKemtaiIframe] = React.useState(false);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [kemtaiId, setKemtaiId] = React.useState(null);
    const [day1Detail, setDay1Detail] = React.useState(null);
    const [workoutName, setWorkoutName] = React.useState(null);
    const [workoutDuration, setWorkoutDuration] = React.useState(null);

    const dispatch = useDispatch();
    const userData: any = useSelector((state: any) => state.users.currentUser)

    const handleClose = () => {
        setOpen(false);
        setIsLetsStart(false)
    }
    const handleContinue = () => {
        setOpen(false);
        setIsKemtaiIframe(true)
        setIsLetsStart(false)
    }

    React.useEffect(() => {
        dispatch(getKemTaiUserWorkoutsList(userData?.id, selectedWorkoutID)).then((res: any) => {
            if(res){
                const da1 = res?.workouts[0];
                setKemtaiId(da1?.kemtaiId)
                setDay1Detail(da1)
                setWorkoutName(res?.workoutGroupName)
            }
        })
        dispatch(getKemTaiUserWorkouts(selectedWorkoutID)).then((res: any) => {
            if(res){
                setWorkoutDuration(res?.duration)
            }
        })
    }, [selectedWorkoutID, isLetsStart])

    React.useEffect(() => {
        setOpen(isLetsStart)
    }, [isLetsStart])

    return (
    <div>
        <IframeKemtai isKemtaiIframe={isKemtaiIframe} setIsKemtaiIframe={setIsKemtaiIframe} kemtaiId={kemtaiId} workoutGroupID={selectedWorkoutID} />
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
            className={classes.modal}
        >
            <Box>
                <Button id="close-popup" onClick={handleClose} className='closePopup'><img src={closeWhiteIcon} alt= ''/></Button>
            <Fade in={open}>
                <Box className={classes.modalContainr}>
                    <h2>Let’s Start!</h2>
                    <div className={classes.letsStartBanner}>
                        <img src={day1Detail?.workoutImageUrl} className='bannerImg' alt='' />
                        {/* <img src={betr} className={classes.betr} alt='' /> */}
                        <div className='letsStartTime'>
                            <img src={schedule} alt='' />
                            {workoutDuration && Math.round(workoutDuration/60)}min
                        </div>
                        <div className={classes.letsStartTitle}>
                            <h3>{workoutName} <span>{day1Detail?.workOutName}</span></h3>
                        </div>
                    </div>
                    <div className='letsStartNotification'>
                        <div>
                            <img src={spacebetween} alt='' />
                            Make sure you have
                            <strong>a few feet of space</strong>
                        </div>
                        <div>
                            <img src={videocam_off} alt='' />
                            Camera access is required but
                            <strong>we don’t record any videos</strong>
                        </div>
                    </div>
                    <div className='letsStartButtons'>
                        <Button id='start-workout' onClick={() => handleContinue()}>Start Workout</Button>
                        <Button id='do-it-later' onClick={() => handleClose()} variant="text">I’ll do it later!</Button>
                    </div>
                </Box>
            </Fade>
          </Box>
        </Modal>
        </div>
    );
}



export default LetsStart;