import {IUser} from '../../models/IUser';
import {EMPTY_FIELD_PLACEHOLDER, EMPTY_IMAGE} from '../../utils/constants';
import {EmojiSkin} from 'emoji-mart';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const selectCurrentUser = (state: any): IUser => state.users.currentUser;
export const selectUserSkinTone = (state: any): EmojiSkin => state.users.skinTone;
export const selectAllUsers = (state: any): IUser[] => state.users.users;
export const selectAllSecondaryUsers = (state: any): IUser[] => state.users.secondaryUsers;
export const selectSupercoachFilteredUsers = (state: any): IUser[] =>
  state.users.supercoachFilteredUsers;
export const selectCoachUsers = (state: any): IUser[] => state.users.coachUsers;
export const selectAllFilteredUsers = (state: any) => {
  return state.users.paginatedUsers.usersPaginatedList.map((user: IUser) => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      imageUrl: user.imageUrl ? user.imageUrl : EMPTY_IMAGE,
      phone: user.phoneNumber || EMPTY_FIELD_PLACEHOLDER,
    };
  });
};
export const selectPaginationParams = (state: any) => {
  return {
    pageNumber: state.users.paginatedUsers.pageNumber,
    pageSize: state.users.paginatedUsers.pageSize,
    totalCount: state.users.paginatedUsers.totalCount,
    totalPages: state.users.paginatedUsers.totalPages,
  };
};
export const selectCount = (state: any) => state.users.paginatedUsers.totalCount;
export const statusErrorsSelector = (state: any) => state.users.statusErrorData;
export const selectUserSuperCoach = (state: any) => state.users.selectedUserSuperCoach;
