import React, {useEffect} from 'react';
import {useLocation} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {logIn} from '../../store/actions/users/users-action';
import Loader from '../../common/components/loader/Loader';
import {makeStyles} from '@material-ui/core/styles';
import {IUser} from '../../models/IUser';
import {selectCurrentUser} from '../../store/selectors/users.selectors';

const useStyles = makeStyles(() => ({
  loaderFixation: {
    height: 'calc(100% - 160px)',
  },
}));

const AuthenticationPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const currentUser: IUser = useSelector(selectCurrentUser);

  useEffect(() => {
    const code = query.get('code') || '';
    const state = query.get('state') || '';
   
    if (!currentUser) {
      // if (currentUser?.onBoardingStatus === 1) {
      //   window.location.href = `https://betrhealth.com/solera?userid=${currentUser?.id}`;
      // }
      dispatch(logIn(code, state));
    }
  }, []);

  return (
    <React.Fragment>
      <Loader className={classes.loaderFixation} />
    </React.Fragment>
  );
};

AuthenticationPage.displayName = 'Authentication Page';
export default AuthenticationPage;
