import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import {Tag} from '../../tags-with-box/Tag';
import {MoodEmoticon} from '../../range-slider/MoodEmoticon';
import NoPainVeryMild from '../../../../assets/icons/stress-level/no-pain-very-mild-level.svg';
import DiscomfortingTolerable from '../../../../assets/icons/stress-level/discomforting-tolerable-level.svg';
import DistressingVeryDistressing from '../../../../assets/icons/stress-level/distressing-very-distressing-level.svg';
import IntenseVeryIntense from '../../../../assets/icons/stress-level/intense-very-intense-level.svg';
import UtterlyHorribleExcruciatingUnbearable from '../../../../assets/icons/stress-level/utterly-horrible-excruciating-unbearable-level.svg';
import UnimaginableUnspeakable from '../../../../assets/icons/stress-level/unimaginable-unspeakable-level.svg';
import * as activityLogingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';

import useStyles from './stress-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface StressRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
  isHistoryWidget?: boolean;
}

const StressRecentlyAddedRow: React.FC<StressRecentlyAddedRowProps> = (props) => {
  const {data, isMobile, isHistoryWidget} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const handleDismiss = () => {
    dispatch(activityLogingActions.deleteStressLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };

  const renderPainLevel = (painLevel: number) => {
    switch (painLevel) {
      case 0: {
        return (
          <MoodEmoticon
            src={UnimaginableUnspeakable}
            name={t('LogsPage.stressWidget.marks.unimaginableUnspeakableName')}
            text={t('LogsPage.stressWidget.marks.unimaginableUnspeakableText')}
            isScaleEmoticon={false}
          />
        );
      }
      case 1: {
        return (
          <MoodEmoticon
            src={UtterlyHorribleExcruciatingUnbearable}
            name={t('LogsPage.stressWidget.marks.excruciatingUnbearableName')}
            text={t('LogsPage.stressWidget.marks.excruciatingUnbearableText')}
            isScaleEmoticon={false}
          />
        );
      }
      case 2: {
        return (
          <MoodEmoticon
            src={UtterlyHorribleExcruciatingUnbearable}
            name={t('LogsPage.stressWidget.marks.utterlyHorribleName')}
            text={t('LogsPage.stressWidget.marks.utterlyHorribleText')}
            isScaleEmoticon={false}
          />
        );
      }
      case 3: {
        return (
          <MoodEmoticon
            src={IntenseVeryIntense}
            name={t('LogsPage.stressWidget.marks.veryIntenseName')}
            text={t('LogsPage.stressWidget.marks.veryIntenseText')}
            isScaleEmoticon={false}
          />
        );
      }
      case 4: {
        return (
          <MoodEmoticon
            src={IntenseVeryIntense}
            name={t('LogsPage.stressWidget.marks.intenseName')}
            text={t('LogsPage.stressWidget.marks.intenseName')}
            isScaleEmoticon={false}
          />
        );
      }
      case 5: {
        return (
          <MoodEmoticon
            src={DistressingVeryDistressing}
            name={t('LogsPage.stressWidget.marks.veryDistressingName')}
            text={t('LogsPage.stressWidget.marks.veryDistressingText')}
            isScaleEmoticon={false}
          />
        );
      }
      case 6: {
        return (
          <MoodEmoticon
            src={DistressingVeryDistressing}
            name={t('LogsPage.stressWidget.marks.distressingName')}
            text={t('LogsPage.stressWidget.marks.distressingName')}
            isScaleEmoticon={false}
          />
        );
      }
      case 7: {
        return (
          <MoodEmoticon
            src={DiscomfortingTolerable}
            name={t('LogsPage.stressWidget.marks.tolerableName')}
            text={t('LogsPage.stressWidget.marks.tolerableName')}
            isScaleEmoticon={false}
          />
        );
      }
      case 8: {
        return (
          <MoodEmoticon
            src={DiscomfortingTolerable}
            name={t('LogsPage.stressWidget.marks.discomfortingName')}
            text={t('LogsPage.stressWidget.marks.discomfortingName')}
            isScaleEmoticon={false}
          />
        );
      }
      case 9: {
        return (
          <MoodEmoticon
            src={NoPainVeryMild}
            name={t('LogsPage.stressWidget.marks.veryMildName')}
            text={t('LogsPage.stressWidget.marks.veryMildText')}
            isScaleEmoticon={false}
          />
        );
      }
      case 10: {
        return (
          <MoodEmoticon
            src={NoPainVeryMild}
            name={t('LogsPage.stressWidget.marks.noPainText')}
            text={t('LogsPage.stressWidget.marks.noPainText')}
            isScaleEmoticon={false}
          />
        );
      }
      default:
        return <div />;
    }
  };

  const renderMoodLevel = (moodLevel: number) => {
    switch (moodLevel) {
      case 0: {
        return (
          <MoodEmoticon
            src={UtterlyHorribleExcruciatingUnbearable}
            name={t('LogsPage.stressWidget.moodMarks.iFeelAwful')}
            text={t('LogsPage.stressWidget.moodMarks.iFeelAwful')}
            isScaleEmoticon={false}
          />
        );
      }
      case 1: {
        return (
          <MoodEmoticon
            src={IntenseVeryIntense}
            name={t('LogsPage.stressWidget.moodMarks.iFeelBad')}
            text={t('LogsPage.stressWidget.moodMarks.iFeelBad')}
            isScaleEmoticon={false}
          />
        );
      }
      case 2: {
        return (
          <MoodEmoticon
            src={DistressingVeryDistressing}
            name={t('LogsPage.stressWidget.moodMarks.iFeelSoSo')}
            text={t('LogsPage.stressWidget.moodMarks.iFeelSoSo')}
            isScaleEmoticon={false}
          />
        );
      }
      case 3: {
        return (
          <MoodEmoticon
            src={DiscomfortingTolerable}
            name={t('LogsPage.stressWidget.moodMarks.iFeelGood')}
            text={t('LogsPage.stressWidget.moodMarks.iFeelGood')}
            isScaleEmoticon={false}
          />
        );
      }
      case 4: {
        return (
          <MoodEmoticon
            src={NoPainVeryMild}
            name={t('LogsPage.stressWidget.moodMarks.iFeelGreat')}
            text={t('LogsPage.stressWidget.moodMarks.iFeelGreat')}
            isScaleEmoticon={false}
          />
        );
      }
      default:
        return <div />;
    }
  };

  return (
    <div>
      {!isHistoryWidget && <div className={commonClasses.line} />}

      <div className={commonClasses.dataContainer}>
        <div>
          <div className={classes.info}>
            {!isHistoryWidget && <DateTimeString date={data.date} isMobile={isMobile} />}
            <div>
              <Typography variant={'overline'}>
                {t('LogsPage.stressWidget.moodRecentlyAdded')}
              </Typography>
              <div className={classes.emojiTagContainer}>
                <Typography variant={'subtitle1'} className={classes.intensityContent}>
                  {renderMoodLevel(data.moodLevelValue)}
                </Typography>

                {data.moodTagsObject?.length > 0 && (
                  <div className={classes.tagsContainer}>
                    {data.moodTagsObject.map((oneTag: any) => (
                      <Tag
                        key={`tag-selected-mood--${oneTag.id}`}
                        name="chip"
                        isSelected={true}
                        id={oneTag.id || 0}
                        label={oneTag.name}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className={classes.marginTop}>
                <Typography variant={'overline'}>
                  {t('LogsPage.stressWidget.stressRecentlyAdded')}
                </Typography>
                <div className={classes.emojiTagContainer}>
                  <Typography variant={'subtitle1'} className={classes.intensityContent}>
                    {renderPainLevel(data.painLevelValue)}
                  </Typography>

                  {data.tagsObject?.length > 0 && (
                    <div className={classes.tagsContainer}>
                      {data.tagsObject.map((oneTag: any) => (
                        <Tag
                          key={`tag-selected-${oneTag.id}`}
                          name="chip"
                          isSelected={true}
                          id={oneTag.id || 0}
                          label={oneTag.name}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isHistoryWidget && (
          <div className={commonClasses.buttonsContainer}>
            <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
          </div>
        )}
      </div>
    </div>
  );
};
export default StressRecentlyAddedRow;
