export const LOAD_CONVERSATIONS = 'LOAD_CONVERSATIONS';
export const SELECT_CONVERSATION = 'SELECT_CONVERSATION';
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION';

export const CHANGE_STATE_SNACKBAR = 'CHANGE_STATE_SNACKBAR';

export const LOAD_USER_PHASES = 'LOAD_USER_PHASES';
export const SELECT_MEMBER = 'SELECT_MEMBER';
export const SELECT_COACH = 'SELECT_COACH';
export const SELECT_MEASUREMENT_UNIT = 'SELECT_MEASUREMENT_UNIT';

export const LOAD_USER_INSIGHTS = 'LOAD_USER_INSIGHTS';
export const LOAD_ACHIEVEMENTS = 'LOAD_ACHIEVEMENTS';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_BASIC_INFO = 'EDIT_USER_BASIC_INFO';

export const LOAD_USER_APPOINTMENTS = 'LOAD_USER_APPOINTMENTS';
export const LOAD_MEMBER_APPOINTMENTS = 'LOAD_MEMBER_APPOINTMENTS';

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const LOAD_NOTES_PAGINATION = 'LOAD_NOTES_PAGINATION';

export const LOAD_BACKEND_CONVERSATION = 'LOAD_BACKEND_CONVERSATION';
export const LOAD_ALL_SUPERCOACH_CONVERSATIONS = 'LOAD_ALL_SUPERCOACH_CONVERSATIONS';
export const LOAD_COACH_CONVERSATIONS = 'LOAD_COACH_CONVERSATIONS';

export const LOADING = 'LOADING';
export const PARTICIPANT_INDEX = 'PARTICIPANT_INDEX';

export const SAVE_SELECTED_CONVERSATION_ID = 'SAVE_SELECTED_CONVERSATION_ID';

export const LOAD_HISTORY_LOGS = 'LOAD_HISTORY_LOGS';

export const UPDATE_IS_MOBILE_DOWNLOAD = 'UPDATE_IS_MOBILE_DOWNLOAD';