import {makeStyles, Theme} from '@material-ui/core';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  isMobileDownload?: boolean;
}

//don't touch colors
const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    display: 'flex',
  } as CSSProperties,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowX: (props) => (props.smallScreens ? 'hidden' : 'auto'),
    position: (props) => (props.isMobileDownload ? 'relative' : 'static'),
     backgroundColor : '#FDFBF9 !important', 
  } as CSSProperties,
  snackButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    minWidth: (props) => (props.smallScreens ? 30 : 64),
    padding: (props) => (props.smallScreens ? 0 : '9px 16px'),
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  snackSuccess: {
    width: (props) => (props.smallScreens ? '100%' : 628),
    marginLeft: (props) => (props.smallScreens ? 0 : 340),
    fontFamily: 'inter',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    backgroundColor: '#3D3733',
    marginTop: (props) => (props.smallScreens ? '40px' : '55px'),
    marginRight: (props) => (props.smallScreens ? 0 : 340),
    paddingLeft: (props) => (props.smallScreens ? 12 : 20),
  },
  intercomExtension: {
    position: 'fixed',
    width: 352,
    height: 60,
    right: 20,
    bottom: (props) => (props.smallScreens ? 70 : 40),
    background: '#FFFFFF',
    boxShadow: '0px 2px 32px rgba(0, 0, 0, 0.16), 0px 1px 6px rgba(0, 0, 0, 0.06)',
    borderRadius: 50,
    animation: `$loading 8s`,
  },
  header: {
    marginLeft: 21,
    marginTop: 12,
  },
  text: {
    textAlign: 'center',
    marginRight: 60,
  },
  '@keyframes loading': {
    '0%': {
      right: -500,
    },
    '50%': {
      right: -500,
    },
    '100%': {
      right: 20,
    },
  },
  plusIcon: {
    display: 'flex',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: (props) =>
      props.isMobileOrTablet.isMobile
        ? 140
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? 145
        : 115,
    right: (props) => (props.isMobileOrTablet.isMobile ? '16px' : '32px'),
    cursor: 'pointer',
    boxShadow: '0px 2px 32px rgba(0, 0, 0, 0.16), 0px 1px 6px rgba(0, 0, 0, 0.06)',
    borderRadius: '100px',
  },
}));

export default useStyles;
