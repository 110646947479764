import {TableCell, TableRow} from '@material-ui/core';
import useStyles from './table.styles';
import React from 'react';
import {IColumnModifiers, ISchema} from '../../../utils/table-meta-helper';

interface Props {
  row: any;
  onRowClick?: (row: any) => void;
  columnModifiers: IColumnModifiers;
  schema: ISchema;
}

export const TableRowSimple: React.FC<Props> = ({row, onRowClick, columnModifiers, schema}) => {
  const classes = useStyles();
  return (
    <TableRow
      key={row.id}
      className={classes.tableRow}
      onClick={() => onRowClick && onRowClick(row)}
    >
      {schema.columns.map((column: any, i: number) => {
        const withModifier = columnModifiers && columnModifiers[column.key];
        const value = withModifier ? withModifier(row[column.key]) : row[column.key];
        return (
          <TableCell key={i} className={classes.tableCell}>
            {value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
