import React, {useEffect, useState} from 'react';
import {subWeeks} from 'date-fns';
import ProgressFilterDate from './ProgressFilterDate';
import ProgressCardHeader from './ProgressCardHeader';
import WeightProgressCardHeader from './WeightProgressCardHeader';
import ProgressChart from './ProgressChart';
import {WeightType} from '../../../utils/constants';
import {ProgressType} from '../../../utils/enums';
import useStyles from './progress-widget.styles';
import {useDispatch, useSelector} from 'react-redux';
import {selectInsights, selectMember} from '../../../store/selectors/homepage.selectors';
import * as loggingActions from '../../../store/actions/logging-page/logging-page-action';
import {
  dateToUtc,
  isDateSameOrBeforeDate,
  isDateSameOrAfterDate,
} from '../../../utils/date-time-utils';

interface Props {
  isMobile: boolean;
  type: any;
  user: any;
  selectedType: number;
}

const ProgressCard: React.FC<Props> = ({isMobile, type, user, selectedType}) => {
  const classes = useStyles({
    isMobile,
  });
  const [fromDate, setFromDate] = useState<Date>(subWeeks(new Date(), 1));
  const [toDate, setToDate] = useState<Date>(new Date());
  const userInsights = useSelector(selectInsights);
  const dispatch = useDispatch();
  const [selectedLogs, setSelectedLogs] = useState<any>(null);
  const [insightsData, setInsightsData] = useState<any>([]);
  const selectedMember = useSelector(selectMember);
  const [includeStartWeight, setIncludeStartWeight] = useState(false);

  const loadLogs = async (fromDate: string, toDate?: string) => {
    let selectedLogs;
    let insightsData;

    if (userInsights.memberSince != '') {
      switch (selectedType) {
        case 0:
          selectedLogs = await dispatch(
            loggingActions.loadWeightLogs(selectedMember?.id, fromDate, toDate),
          );
          insightsData = userInsights.weight;
          break;
        // case 1:
        //   selectedLogs = await dispatch(
        //     loggingActions.loadFoodLogs(fromDate, toDate, selectedMember?.id),
        //   );
        //   insightsData = userInsights.food;
        //   break;
        case 1:
          selectedLogs = await dispatch(
            loggingActions.loadDrinkLogs(fromDate, toDate, selectedMember?.id),
          );
          insightsData = userInsights.water;
          break;
        case 2:
          selectedLogs = await dispatch(
            loggingActions.loadSleepLogs(fromDate, toDate, selectedMember?.id),
          );

          insightsData = userInsights.sleep;
          break;
        case 3:
          selectedLogs = await dispatch(
            loggingActions.loadActivityLogs(fromDate, toDate, selectedMember?.id),
          );
          insightsData = userInsights.activity;
          break;
        case 4:
          selectedLogs = await dispatch(
            loggingActions.loadStepsLogs(fromDate, toDate, selectedMember?.id),
          );
          insightsData = userInsights.steps;
          break;
        case 5:
          selectedLogs = await dispatch(
            loggingActions.loadBowelLogs(fromDate, toDate, selectedMember?.id),
          );
          insightsData = userInsights.bowelMovement;
          break;
        case 6:
          selectedLogs = await dispatch(
            loggingActions.loadStressLogs(fromDate, toDate, selectedMember?.id),
          );
          insightsData = userInsights.stress;
          break;
        case 7:
          selectedLogs = await dispatch(
            loggingActions.loadBloodGlucoseLogs(fromDate, toDate, selectedMember?.id),
          );
          insightsData = userInsights.bloodGlucose;
          break;
        default:
          return;
      }
    }
    setSelectedLogs(selectedLogs);
    setInsightsData(insightsData);
  };

  useEffect(() => {
    if (selectedType === type.value) {
      loadLogs(dateToUtc(fromDate.toString()), dateToUtc(toDate.toString()));
    }
    if (
      isDateSameOrBeforeDate(userInsights.initialWeightDate, toDate.toString()) &&
      isDateSameOrAfterDate(userInsights.initialWeightDate, fromDate.toString())
    )
      setIncludeStartWeight(true);
  }, [type, userInsights, selectedMember, fromDate, toDate]);

  return (
    <div className={classes.progressCard}>
      {type.type === ProgressType.BODY_WEIGHT ? (
        <WeightProgressCardHeader isMobile={isMobile} type={type.type} />
      ) : (
        <ProgressCardHeader type={type?.type} isMobile={isMobile} data={insightsData} />
      )}
      <div className={classes.line} />
      <ProgressFilterDate
        isMobile={isMobile}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
      <ProgressChart
        isMobile={isMobile}
        fromDate={fromDate}
        includeStartWeight={includeStartWeight}
        toDate={toDate}
        unit={user?.measurementUnitId === 1 ? WeightType.LBS : WeightType.KG}
        user={user}
        type={type.type}
        logs={selectedLogs}
        isSelected={selectedType === type.value}
      />
    </div>
  );
};

export default ProgressCard;
