import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {Typography, useMediaQuery} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {
  selectAllCategories,
  selectAllLevels,
  selectAllMealPlansTags,
  selectSelectedMealPlansFilters,
} from '../../../store/selectors/nourish.selectors';
import CollapseThinIcon from '../../../assets/icons/side-menu-bar/collapse-thin-icon.svg';
import {SearchBox} from '../../../common/components/search-box/SearchBox';
import {ILevelCategoryDto} from '../../../dtos/ILevelCategoryDto';
import {Select} from '../../../common/components/select/Select';
import {Button} from '../../../common/components/button/Button';
import {ExpandableContainer} from '../../../common/components/expandable-container/ExpandableContainer';
import {ICategoryDto} from '../../../dtos/ICategoryDto';
import {Tag} from '../../../common/components/tags-with-box/Tag';
import {ISelectedMealPlansFiltersDto} from '../../../dtos/IRecipeFiltersDto';
import {
  clearMealPlansTags,
  removeMealPlansTags,
  saveMealPlansTags,
  saveSelectedMealPlansFilters,
} from '../../../store/actions/nourish/nourish-actions';
import {IMealPlanTagsDto} from '../../../dtos/IMealPlanTagsDto';
import {MOBILE_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './recipes-filter.styles';

interface RecipesFilterProps {
  className?: string;
  onFilterChange: any;
}

export const MealPlansFilter: React.FC<RecipesFilterProps> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTablet: isTablet || isTabletSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize: isMobileOrTablet.isMobile || isMobileOrTablet.isTablet,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const levels: ILevelCategoryDto[] = useSelector(selectAllLevels);
  const categories: ICategoryDto[] = useSelector(selectAllCategories);
  const selectedMealPlansFilters: ISelectedMealPlansFiltersDto = useSelector(
    selectSelectedMealPlansFilters,
  );
  const tags: IMealPlanTagsDto = useSelector(selectAllMealPlansTags);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'levelSelect') {
      dispatch(
        saveSelectedMealPlansFilters({
          ...selectedMealPlansFilters,
          levelObject: {key: value.key, value: value.value},
          [name]: value.key,
        }),
      );
    } else {
      dispatch(
        saveSelectedMealPlansFilters({
          ...selectedMealPlansFilters,
          [name]: value,
        }),
      );
    }
  };

  const handleFilter = () => {
    const searchFilters = {
      searchCriteria: selectedMealPlansFilters.searchCriteria,
      levelCategory: selectedMealPlansFilters.levelObject.value,
      categories: tags.categories,
    };
    props.onFilterChange(searchFilters);
  };

  const handleClear = () => {
    dispatch(
      saveSelectedMealPlansFilters({
        searchCriteria: '',
        levelObject: {
          key: 0,
          value: '',
        },
      }),
    );
    categories.forEach((oneCategory) => (oneCategory.isHidden = false));
    dispatch(clearMealPlansTags());
  };

  const handleChangeSearchValue = async (e: any) => {
    const searchValue = e.target.value;
    const filters = {
      ...selectedMealPlansFilters,
      searchCriteria: searchValue,
    };
    dispatch(saveSelectedMealPlansFilters(filters));
  };

  const handleClick = (e: any) => {
    const selectedTag: ICategoryDto[] = categories.filter((oneTag) => oneTag.id == e);
    if (selectedTag[0]) {
      selectedTag[0].isHidden = true;
      dispatch(saveMealPlansTags(selectedTag[0], 'categories'));
    }
  };

  const handleDelete = (e: any) => {
    const selectedTag: ICategoryDto[] = categories.filter((oneTag) => oneTag.id === e);
    if (selectedTag[0]) {
      selectedTag[0].isHidden = false;
      dispatch(removeMealPlansTags(e, 'categories'));
    }
  };

  // const filterListBySearchCriteria = (param: any) => {
  //   return param.name.toLowerCase().includes(filters.searchCriteria.toLowerCase());
  // };

  return (
    <ExpandableContainer className={classes.filterContent}>
      <SearchBox
        className="side-menu-bar-input sidebar-searchBox"
        name="searchCriteria"
        placeholder={t('NourishPage.mealPlansFilter.searchPlaceholder')}
        containerClass={classes.searchBox}
        value={selectedMealPlansFilters.searchCriteria}
        onChange={handleChangeSearchValue}
        hasButton
        onSearchClick={() => {
          handleFilter();
        }}
      />

      <Accordion>
        <AccordionSummary expandIcon={<img src={CollapseThinIcon} alt={'thin-collapse-icon'} />}>
          <Typography variant={'caption'} className={classes.textColor}>
            {t('NourishPage.mealPlansFilter.filterOptionsDropDown')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.searchContainer}>
          <div>
            <Typography variant={'caption'} className={classes.textColor}>
              {t('NourishPage.mealPlansFilter.selectLevel')}
            </Typography>
            <Select
              name={'levelSelect'}
              options={
                levels?.length > 1
                  ? levels.map((item: any) => ({
                      key: item.id,
                      value: item.name,
                    }))
                  : [{key: 0, value: ''}]
              }
              value={selectedMealPlansFilters.levelObject}
              onChange={handleChange}
              className={`${classes.selectBox} side-menu-bar-select`}
              isDark={true}
            />
          </div>
          <div className={classes.containerMargin}>
            <Typography variant={'caption'} className={classes.textColor}>
              {t('NourishPage.mealPlansFilter.category')}
            </Typography>
            <div className={classes.containerTags}>
              {categories.map(
                (oneCuisine: ICategoryDto) =>
                  !oneCuisine.isHidden && (
                    <Tag
                      className="sidebarTag"
                      key={`category-${oneCuisine.id}`}
                      name="chip"
                      id={oneCuisine.id || 0}
                      label={oneCuisine.name}
                      onClick={handleClick}
                    />
                  ),
              )}
            </div>
          </div>
          {tags.categories && tags.categories?.length > 0 && (
            <div className={classes.boxTags}>
              {tags.categories.map((oneSelectedTag) => (
                <Tag
                  className="sidebarTag"
                  key={`tag-selected-category-${oneSelectedTag.id}`}
                  name="chip"
                  id={oneSelectedTag.id || 0}
                  label={oneSelectedTag.name}
                  isSelected={true}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          )}

          <div className={classes.buttons}>
            <Button
              id={'clear-filters-btn'}
              variant="outlined"
              onClick={handleClear}
              className={classes.clearBtn}
            >
              {t('NourishPage.mealPlansFilter.clear')}
            </Button>
            <Button id={'filter-btn'} onClick={() => handleFilter()} className={classes.filterBtn}>
              {t('NourishPage.mealPlansFilter.filterResults')}
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </ExpandableContainer>
  );
};

MealPlansFilter.displayName = 'MealPlansFilter';
