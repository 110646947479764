import * as React from 'react';

import useStyles from './nourish-card.styles';
import {ICardDto} from '../../../dtos/ICardDto';
import {Typography} from '@material-ui/core';
import classNames from 'classnames';

interface INourishCardProps {
  card: ICardDto;
  cssClass?: string;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const NourishCard: React.FC<INourishCardProps> = (props) => {
  const {card, cssClass,} = props;
  const classes = useStyles();

  const handleCardClick = async () => {
   
    if (card.link) {
      window.open(card.link, '_blank');
      return;
    }

    if (card.pdf) {
      window.open(card.pdf.url, '_blank');
      return;
    }
  };

  return (
    <>
        <div
          id={'card' + card.id}
          className={cssClass ? classNames(classes.root, cssClass) : classes.root}
          onClick={handleCardClick}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <img src={card.Image.image.url} width={184} height={148} alt={card.Image.image.name} />
          <Typography className={classes.headline} variant={'subtitle2'}>
            {card.Headline}
          </Typography>
        </div>
    </>
  );
};

NourishCard.displayName = 'NourishCard';
