import {IBloodGlucoseDto} from '../dtos/IBloodGlucoseDto';
import {del, get, post, put} from './api-calls';

export const getBloodGlucoseLogs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IBloodGlucoseDto[]> => {
  return get(`/bloodglucose?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addBloodGlucoseDatabase = (data: IBloodGlucoseDto): Promise<IBloodGlucoseDto> => {
  return post('/bloodglucose', data);
};

export const editBloodGlucoseDatabase = (data: IBloodGlucoseDto): Promise<IBloodGlucoseDto> => {
  return put(`/bloodglucose/${data.id}`, data);
};

export const deleteBloodGlucoseDatabase = (id: number): Promise<any> => {
  return del(`/bloodglucose/${id}`);
};

export const getBloodGlucoseLogById = (id: number): Promise<IBloodGlucoseDto> => {
  return get(`/bloodglucose/${id}`);
};
