import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  isMobileOrTablet: {
    isMobile: boolean;
    //isTabletSmall: boolean;
    //isTabletBig: boolean;
  };
  //smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  continueButton: {
    marginTop: 30,
    width: (props) => (props.isMobileOrTablet.isMobile ? 295 : 548),
    marginBottom: 32,
  },
}));

export default useStyles;
