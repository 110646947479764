import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    '& .MuiDialog-paperFullScreen': {
      margin: '0 !important',
    },
    '& .MuiDialog-paperScrollPaper': {
      margin: (props) => (props.isMobile ? 16 : ''),
    },
    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.blacksAndWhites.sonicSilver,
    },
    '& .MuiDialogContent-root': {
      padding: 0,

      '& .MuiPaper-root': {
        boxShadow: 'none',
        marginTop: 0,

        '&:focus': {
          border: `2px solid transparent`,
        },
      },
    },
  },
  closeIconClass: {
    position: 'absolute',
    top: 12,
    right: 17,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

export default useStyles;
