import {Card, Typography} from '@material-ui/core';
// import classNames from 'classnames';
// import {IPodcastDto} from '../../../dtos/IPodCastDto';

import React, {useState, useEffect} from 'react';
import useStyles from '../../recipes/recipe-card.styles';
import Loader from '../../loader/Loader';
import BackIcon from '../../../../assets/icons/rest/left-arrow.svg';
import {loadYogas} from '../../../../store/actions/nourish/nourish-actions';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '../../button/Button';
interface Props {
  Content: any;
  isMobileOrTablet: any;
  smallScreens: any;
  slug: string;
  handleDismiss: any;
  backTitle: string;
}

const YogaPlayersView: React.FC<Props> = (props) => {
  const {slug, isMobileOrTablet, smallScreens, handleDismiss, backTitle} = props;
  const yogaData = useSelector((state) => state?.nourish?.yogaSelectedData);
  const urlRegex = /src=["'](.*?)["']/;
  const [url, setUrl] = React.useState();
  const [description, setDescription] = React.useState();
  React.useEffect(() => {
    const match = yogaData?.description?.match(urlRegex);
    const urls = match ? match[1] : '';
    setUrl(urls);
    const textContent = yogaData?.description.replace(/<iframe.*?<\/iframe>/s, '').trim();
    setDescription(textContent);
  }, [yogaData]);
  const [isLoading, setLoading] = useState<boolean>(false);
  // const [card, setCard] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        await dispatch(loadYogas(slug));
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [slug]);
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {smallScreens && (
            <div className={classes.header}>
              <Button
                className={classes.back}
                id="back"
                variant="text"
                onClick={() => handleDismiss()}
              >
                <img src={BackIcon} alt="Back to yogas" />
                <Typography style={{marginLeft: 10}} variant={'subtitle1'}>
                  {' '}
                  Back to {backTitle ? backTitle : 'Lets Start Yoga'}
                </Typography>
              </Button>
            </div>
          )}
          <Card
            style={{
              height: '100vh',
              width: '100vw',
              textAlign: 'center',
              flexDirection: 'column',
              overflow: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h6'} style={{marginTop: 25}}>
              {yogaData?.title ? yogaData?.title : 'Lets Start Yoga'}
            </Typography>
            {url && (
              <iframe
                style={{height: '70vh', width: '70vw', marginTop: 30}}
                title="Iframe Player"
                width="60vw"
                height="60vh"
                frameBorder="no"
                scrolling="no"
                seamless
                src={url}
              ></iframe>
            )}
            <div style={{marginTop: 10, maxWidth: '59vw',  textAlign: 'center', marginBottom:10}}>
              <Typography style={{paddingBottom: 15,fontSize:14}} variant={'caption'}>
                {description}
              </Typography>
            </div>
           
            {/* <Button  id={''}>
              Learn More
            </Button> */}
          </Card>
        </>
        // <Card
        //   // id={'card' + card.id}
        //   className={`${classes.card}`}
        //   // onClick={handleCardClick}
        //   // eslint-disable-next-line @typescript-eslint/no-empty-function
        //   onKeyDown={() => {}}
        //   role="button"
        //   tabIndex={0}
        //   style={{height: 230, minHeight: 230}}
        // >
        //   <Typography variant={'h6'} style={{marginTop: 25}}>

        //     </Typography>
        //   <img
        //     src={''}
        //     //   width={300}
        //     height={148}
        //     style={{
        //       borderTopLeftRadius: 12,
        //       borderTopRightRadius: 12,
        //       width: '100%',
        //       height: card?.image?.image?.formats?.small?.url ? 'auto' : 120,
        //       aspectRatio: 'auto',
        //     }}
        //     alt={card?.image?.image?.name}
        //   />
        //   <Typography className={classes.headline} style={{padding: 5}} variant={'subtitle2'}>
        //     {/* {card.title} */}
        //   </Typography>
        //   <Typography style={{paddingLeft: 10, paddingBottom: 15}} variant={'caption'}>
        //     {/* {card.episode} */}
        //   </Typography>
        // </Card>
      )}
    </>
  );
};

export default YogaPlayersView;
