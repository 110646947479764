import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {Typography, useMediaQuery} from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CollapseThinIcon from '../../../assets/icons/side-menu-bar/collapse-thin-icon.svg';

import {
  selectAllIngredientsCategories,
  selectAllCategoriesWithIngredients,
  selectAllLevels,
  selectAllRecipesTags,
  selectSelectedRecipesFilters,
  selectNoHiddenCourses,
  selectNoHiddenCuisines,
  selectNoHiddenSelectableIngredients,
} from '../../../store/selectors/nourish.selectors';
import {ICuisineDto} from '../../../dtos/ICuisineDto';
import {ICourseDto} from '../../../dtos/ICourseDto';
import {SearchBox} from '../../../common/components/search-box/SearchBox';
import {ILevelCategoryDto} from '../../../dtos/ILevelCategoryDto';
import {Select} from '../../../common/components/select/Select';
import {Button} from '../../../common/components/button/Button';
import {ICategoryIngredientsDto, IIngredientDto} from '../../../dtos/ICategoryIngredientsDto';
import {ChipTag} from '../../../common/components/chip-tag/ChipTag';
import {ExpandableContainer} from '../../../common/components/expandable-container/ExpandableContainer';
import {Tag} from '../../../common/components/tags-with-box/Tag';
import {getTheme} from '../../../theme/theme';
import {getColorForLevel, getHoverColorForLevel} from '../../../utils/tag-level-helpers';
import {IRecipeTagsDto} from '../../../dtos/IRecipeTagsDto';
import {
  clearRecipesTags,
  removeRecipesTags,
  saveRecipesTags,
  saveSelectedRecipesFilters,
  saveSelectableIngredients,
} from '../../../store/actions/nourish/nourish-actions';
import {MOBILE_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './recipes-filter.styles';

interface RecipesFilterProps {
  className?: string;
  onFilterChange: any;
}

export const RecipesFilter: React.FC<RecipesFilterProps> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTablet: isTablet || isTabletSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize: isMobileOrTablet.isMobile || isMobileOrTablet.isTablet,
  });
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const tags: IRecipeTagsDto = useSelector(selectAllRecipesTags);
  const cuisines: ICuisineDto[] = useSelector(selectNoHiddenCuisines);
  const courses: ICourseDto[] = useSelector(selectNoHiddenCourses);
  const levels: ILevelCategoryDto[] = useSelector(selectAllLevels);
  const categories: ICategoryIngredientsDto[] = useSelector(selectAllIngredientsCategories);
  const categoriesIngredients: ICategoryIngredientsDto[] = useSelector(
    selectAllCategoriesWithIngredients,
  );
  const selectedRecipesFilters = useSelector(selectSelectedRecipesFilters);
  const selectableIngredients = useSelector(selectNoHiddenSelectableIngredients);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'levelSelect') {
      const filters = {
        ...selectedRecipesFilters,
        levelObject: {key: value.key, value: value.value},
        [name]: value.key,
      };
      dispatch(saveSelectedRecipesFilters(filters));
    } else if (name === 'categorySelect') {
      const filters = {
        ...selectedRecipesFilters,
        categoryObject: {key: value.key, value: value.value},
        [name]: value.key,
      };
      dispatch(saveSelectedRecipesFilters(filters));
      const selectedCategory = categoriesIngredients.filter(
        (oneCategory) => oneCategory.name === value.value,
      );
      selectedCategory[0] &&
        dispatch(saveSelectableIngredients(selectedCategory[0].children || []));
    } else {
      const filters = {
        ...selectedRecipesFilters,
        [name]: value.key,
      };
      dispatch(saveSelectedRecipesFilters(filters));
    }
  };

  const handleFilter = () => {
    const searchFilters = {
      searchCriteria: selectedRecipesFilters.searchCriteria,
      levelCategory: selectedRecipesFilters.levelObject.value,
      cuisines: tags.cuisines,
      courses: tags.courses,
      ingredients: tags.ingredients,
    };
    props.onFilterChange(searchFilters);
  };

  const handleClear = () => {
    dispatch(
      saveSelectedRecipesFilters({
        searchCriteria: '',
        levelObject: {
          key: 0,
          value: '',
        },
        categoryObject: {
          key: 0,
          value: '',
        },
      }),
    );
    cuisines.forEach((oneCuisine) => (oneCuisine.isHidden = false));
    courses.forEach((oneCourse) => (oneCourse.isHidden = false));

    categoriesIngredients.forEach((oneCategory) => {
      oneCategory.children &&
        oneCategory.children.forEach((oneIngredient) => (oneIngredient.isHidden = false));
    });

    dispatch(saveSelectableIngredients([]));
    dispatch(clearRecipesTags());
  };

  const handleChangeSearchValue = async (e: any) => {
    const searchValue = e.target.value;
    const filters = {
      ...selectedRecipesFilters,
      searchCriteria: searchValue,
    };
    dispatch(saveSelectedRecipesFilters(filters));
  };

  const handleClickCuisine = (e: any) => {
    const selectedTag: ICuisineDto[] = cuisines.filter((oneTag) => oneTag.id == e);
    if (selectedTag[0]) {
      selectedTag[0].isHidden = true;
      dispatch(saveRecipesTags(selectedTag[0], 'cuisines'));
    }
  };

  const handleDeleteCuisine = (e: any) => {
    const selectedTag: ICuisineDto[] = cuisines.filter((oneTag) => oneTag.id === e);
    if (selectedTag[0]) {
      selectedTag[0].isHidden = false;
      dispatch(removeRecipesTags(e, 'cuisines'));
    }
  };

  const handleClickCourse = (e: any) => {
    const selectedTag: ICourseDto[] = courses.filter((oneTag) => oneTag.id == e);
    if (selectedTag[0]) {
      selectedTag[0].isHidden = true;
      dispatch(saveRecipesTags(selectedTag[0], 'courses'));
    }
  };

  const handleDeleteCourse = (e: any) => {
    const selectedTag: ICourseDto[] = courses.filter((oneTag) => oneTag.id === e);
    if (selectedTag[0]) {
      selectedTag[0].isHidden = false;
      dispatch(removeRecipesTags(e, 'courses'));
    }
  };

  const handleClickIngredient = (e: any) => {
    const selectedTag: IIngredientDto[] = selectableIngredients.filter((oneTag) => oneTag.id == e);
    if (selectedTag[0]) {
      selectedTag[0].isHidden = true;
      dispatch(saveRecipesTags(selectedTag[0], 'ingredients'));
    }
  };

  const handleDeleteIngredient = (e: any) => {
    const tag: IIngredientDto[] = tags.ingredients.filter((oneTag) => oneTag.id === e);
    if (tag[0]) {
      tag[0].isHidden = false;
      dispatch(removeRecipesTags(e, 'ingredients'));
    }
    // const selectedTag: IIngredientDto[] = selectableIngredients.filter((oneTag) => oneTag.id === e);
    // if (selectedTag[0]) {
    //   selectedTag[0].isHidden = false;
    //   dispatch(removeRecipesTags(e, 'ingredients'));
    // }
  };

  return (
    <ExpandableContainer className={classes.filterContent}>
      <SearchBox
        className="side-menu-bar-input sidebar-searchBox"
        name="searchCriteria"
        // style={{color: isMobile ? "#fff" :  ''}}
        placeholder={t('NourishPage.recipesFilter.searchPlaceholder')}
        containerClass={classes.searchBox}
        value={selectedRecipesFilters.searchCriteria}
        onChange={handleChangeSearchValue}
        hasButton
        onSearchClick={() => {
          handleFilter();
        }}
      />
      <Accordion style={{backgroundColor: isMobile ? "#fff" : ''}}>
        <AccordionSummary expandIcon={<img src={CollapseThinIcon} alt={'thin-collapse-icon'} />}>
          <Typography style={{padding: isMobile ? 5 : 0}} variant={'caption'} className={classes.textColor}>
            {t('NourishPage.recipesFilter.filterOptionsDropDown')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.searchContainer}>
          <div>
            <Typography   style={{color: isMobile ? "#fff" :  ''}} variant={'caption'} className={classes.textColor}>
              {t('NourishPage.recipesFilter.selectLevel')}
            </Typography>
            <Select
              name={'levelSelect'}
            
              options={
                levels?.length > 1
                  ? levels.map((item: any) => ({
                      key: item.id,
                      value: item.name,
                    }))
                  : [{key: 0, value: ''}]
              }
              value={selectedRecipesFilters.levelObject}
              onChange={handleChange}
              className={`${classes.selectBox} side-menu-bar-select`}
              isDark={true}
            />
          </div>
          <div className={classes.containerMargin}>
            <Typography variant={'caption'} className={classes.textColor}>
              {t('NourishPage.recipesFilter.cuisine')}
            </Typography>
            <div className={classes.containerTags}>
              {cuisines.map(
                (oneCuisine: ICuisineDto) =>
                  !oneCuisine.isHidden && (
                    <Tag
                      className="sidebarTag"
                      key={`cuisine-${oneCuisine.id}`}
                      name="chip"
                      id={oneCuisine.id || 0}
                      label={oneCuisine.name}
                      onClick={handleClickCuisine}
                    />
                  ),
              )}
            </div>
          </div>

          <div className={classes.containerMargin}>
            <Typography variant={'caption'} className={classes.textColor}>
              {t('NourishPage.recipesFilter.course')}
            </Typography>
            <div className={classes.containerTags}>
              {courses.map(
                (oneCourse: ICourseDto) =>
                  !oneCourse.isHidden && (
                    <Tag
                      className="sidebarTag"
                      key={`course-${oneCourse.id}`}
                      name="chip"
                      id={oneCourse.id || 0}
                      label={oneCourse.name}
                      onClick={handleClickCourse}
                    />
                  ),
              )}
            </div>
          </div>

          <div className={classes.containerMargin}>
            <Typography variant={'caption'} className={classes.textColor}>
              {t('NourishPage.recipesFilter.ingredientsCategory')}
            </Typography>
            <Select
              name={'categorySelect'}
              options={
                categories?.length > 1
                  ? categories.map((item: any) => ({
                      key: item.id,
                      value: item.name,
                    }))
                  : [{key: 0, value: ''}]
              }
              value={selectedRecipesFilters.categoryObject}
              onChange={handleChange}
              className={`${classes.selectBox} side-menu-bar-select`}
              isDark={true}
            />
            <div className={classes.containerTags}>
              {selectableIngredients &&
                selectableIngredients.map(
                  (oneItem: IIngredientDto) =>
                    !oneItem.isHidden && (
                      <ChipTag
                        key={`ingredient-${oneItem.id}`}
                        color={getColorForLevel(oneItem.level_category)}
                        borderColor={getColorForLevel(oneItem.level_category)}
                        hoverColor={getHoverColorForLevel(oneItem.level_category)}
                        name="chip"
                        id={oneItem.id || 0}
                        label={oneItem.name}
                        textColor={getTheme().palette.blacksAndWhites.smokyBlack}
                        onClick={handleClickIngredient}
                      />
                    ),
                )}
            </div>
          </div>
          {tags &&
            (tags?.cuisines?.length > 0 ||
              tags?.courses?.length > 0 ||
              tags?.ingredients?.length > 0) && (
              <div className={classes.boxTags}>
                {tags.cuisines &&
                  tags.cuisines.map((oneSelectedTag) => (
                    <Tag
                      key={`tag-selected-cuisine-${oneSelectedTag.id}`}
                      name="chip"
                      id={oneSelectedTag.id || 0}
                      label={oneSelectedTag.name}
                      isSelected={true}
                      onDelete={handleDeleteCuisine}
                    />
                  ))}
                {tags.courses &&
                  tags.courses.map((oneSelectedTag) => (
                    <Tag
                      key={`tag-selected-course-${oneSelectedTag.id}`}
                      name="chip"
                      id={oneSelectedTag.id || 0}
                      label={oneSelectedTag.name}
                      isSelected={true}
                      onDelete={handleDeleteCourse}
                    />
                  ))}
                {tags.ingredients &&
                  tags.ingredients.map((oneSelectedTag) => (
                    <ChipTag
                      key={`tag-selected-ingredient-${oneSelectedTag.id}`}
                      color={getColorForLevel(oneSelectedTag.level_category)}
                      borderColor={getColorForLevel(oneSelectedTag.level_category)}
                      hoverColor={getHoverColorForLevel(oneSelectedTag.level_category)}
                      name="chip"
                      id={oneSelectedTag.id || 0}
                      label={oneSelectedTag.name}
                      textColor={getTheme().palette.blacksAndWhites.smokyBlack}
                      onDelete={handleDeleteIngredient}
                    />
                  ))}
              </div>
            )}

          <div className={classes.buttons}>
            <Button
              id={'clear-filters-btn'}
              variant="outlined"
              onClick={handleClear}
              className={classes.clearBtn}
            >
              {t('NourishPage.recipesFilter.clear')}
            </Button>
            <Button id={'filter-btn'} onClick={() => handleFilter()} className={classes.filterBtn}>
              {t('NourishPage.recipesFilter.filterResults')}
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </ExpandableContainer>
  );
};

RecipesFilter.displayName = 'RecipesFilter';
