import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  timeWrapper: {
    maxWidth: '177px',
  },
  textField: {
    width: 200,
    '& .MuiOutlinedInput-input': {
      color: theme.palette.blacksAndGrays.stoneGray,
    },
  },
  helperText: {
    marginTop: 0,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    color: 'red',
  },
  labelText: {
    color: theme.palette.blacksAndGrays.davysGray,
    marginBottom: 4,
  },
  requiredText: {
    color: theme.palette.primary.light,
    marginBottom: 4,
  },
  labelContainer: {
    display: 'flex',
  },
  timePickerCont: {
    '& .MuiOutlinedInput-inputAdornedEnd': {
      color: theme.palette.blacksAndGrays.stoneGray,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  timeFieldPickerCont: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      color: theme.palette.blacksAndWhites.smokyBlack,
    },
  },
}));
export default useStyles;
