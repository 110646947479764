import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  rows: number;
}

const row = '1fr ';

const useStyles = makeStyles<Theme, Props>(() => ({
  card: {
    cursor: 'auto',
    width: (props) =>
      props.isMobileOrTablet.isMobile
        ? '100%'
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? 'calc(50% - 12px)'
        : '302px',
    marginBottom: '16px',
    borderRadius: '16px',
    position: 'relative',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important',
    '& .MuiCardContent-root': {
      padding: '8px 16px',
    },
    '&:nth-child(odd)': {
      marginRight: (props) => (props.isMobileOrTablet.isMobile ? '0px' : '12px'),
    },
    '&:nth-child(even)': {
      marginLeft: (props) => (props.isMobileOrTablet.isMobile ? '0px' : '12px'),
    },
  },
  cardHeader: {
    margin: '5px 0',
    '& .MuiTypography-h6': {
      width: 220,
      height: 40,
    },
  },
  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: (props) => `${row.repeat(props.rows)}`,
    gridAutoFlow: 'column',
    margin: '15px 0',
    '& .MuiGrid-item': {
      padding: '8px 8px 0 0',
    },
  },
  button: {
    height: '36px',
    width: '120px',
  },
  contentDialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: (props) => (props.smallScreens ? '100%' : '650px'),
    },
  },
}));

export default useStyles;
