import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  // const useStyles = makeStyles((theme) => ({
  footer: {
    // background: theme.palette.primary.dark,
    background:"#DDD6D6",
    color: "#000000",
    flexShrink: 0,
    bottom: 0,
    width: '100%',
    height: '90px',
    padding: '6px 80px',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'start',
    position: 'relative',
    marginTop: '-60px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    // color: theme.palette.common.white,
    color: "#000000",
    textDecoration: 'none',
    marginRight: '32px',
  },
}));

export default useStyles;
