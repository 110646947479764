import * as React from 'react';
import {useDispatch} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Card, CardContent, Grid, Typography, useMediaQuery} from '@material-ui/core';
import {Button} from '../button/Button';
import {MealPlanDetailsView} from './MealPlanDetailsView';
import {ContentDialog} from '../dialog/ContentDialog';
import {LevelFlag} from '../level-flag/LevelFlag';
import {loadMealPlan} from '../../../store/actions/nourish/nourish-actions';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';
import {IMealPlanButtonDto} from '../../../dtos/IMealPlanButtonDto';

import useStyles from './meal-plan-card-styles';

interface Props {
  mealPlan: any;
}

export const MealPlanCard: React.FC<Props> = ({mealPlan}) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
    rows: mealPlan.card.button?.length / 2 + 1,
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const handleOnOptionClick = async (slug: string) => {
    try {
      await dispatch(loadMealPlan(slug));
      setOpen(true);
    } catch (err) {
      setOpen(false);
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.cardHeader}>
          <Typography gutterBottom variant="h6" component="div">
            {mealPlan.name}
          </Typography>
          <LevelFlag
            title={mealPlan?.level_category?.name}
            levelCategory={mealPlan?.level_category}
            top={15}
            right={15}
          />
        </div>
        <Grid container wrap="wrap" className={classes.buttonContainer} spacing={2}>
          {mealPlan.card.button.map((button: IMealPlanButtonDto) => (
            <Grid item key={button.id}>
              <Button
                key={button.id}
                id={button.id + ''}
                variant="outlined"
                className={classes.button}
                onClick={() => handleOnOptionClick(button.href)}
              >
                {button.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <ContentDialog
        fullScreen={smallScreens}
        slideUp
        handleDismiss={() => setOpen(false)}
        dialogState={open}
        isCloseButtonHidden={smallScreens}
        content={
          <MealPlanDetailsView
            isMobileOrTablet={isMobileOrTablet}
            smallScreens={smallScreens}
            handleDismiss={() => setOpen(false)}
          />
        }
        className={classes.contentDialog}
      />
    </Card>
  );
};
