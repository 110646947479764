import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  stepsLabel: {
    marginBottom: 8,
    marginTop: (props) => (props.isMobile ? '24px' : 0),
  },
}));

export default useStyles;
