/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, post, put} from './api-calls';
import {IAppointmentDto} from '../dtos/IAppointmentDto';

export const getAppointmentsLogs = (id: string, dateTime?: string): Promise<IAppointmentDto[]> => {
  return get(`/appointments?UserId=${id}&from=${dateTime}`);
};
export const getScheduleAppointmentsLogs = (id: string): Promise<IAppointmentDto[]> => {
  return get(`/Appointments/AppointmentSchedules/${id}`);
};

export const getAppointmentById = (id: number): Promise<IAppointmentDto> => {
  return get(`/appointments/${id}`);
};

export const addAppointmentDatabase = (data: IAppointmentDto): Promise<IAppointmentDto> => {
  return post('/appointments', data);
};

export const editAppointmentDatabase = (data: IAppointmentDto): Promise<IAppointmentDto> => {
  return put(`/appointments/${data.id}`, data);
};

export const deleteAppointmentDatabase = (id: string): Promise<any> => {
  return del(`/appointments/${id}`);
};
