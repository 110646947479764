import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  card: {
    border: `2px solid ${theme.palette.primary.light}`,
    boxSizing: 'border-box',
    borderRadius: '12px',
    margin: '16px 0px !important',
    padding: '8px 12px',
    '& ul': {
      margin: 0,
      padding: '0 16px',
    },
  },
}));

export default useStyles;
