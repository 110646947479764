import React from 'react';
import {useTranslation} from 'react-i18next';
import {isTablet, isMobileOnly, isMobile} from 'react-device-detect';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {ExpandableContainer} from '../expandable-container/ExpandableContainer';
import {Select} from '../select/Select';
import {DatePicker} from '../pickers/DatePicker';
import CategoryChipTagsWithBox from '../category-chip-tags-box/CategoryChipTagsWithBox';
import {Button} from '../button/Button';
import CollapseThinIcon from '../../../assets/icons/side-menu-bar/collapse-thin-icon.svg';
import {
  CATEGORY_CHIP_TAG_ID,
  DATE_PICKER_DATE_FORMAT,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
} from '../../../utils/constants';
import {IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';

import useStyles from './filter-options.style';

interface Props {
  filters: any;
  handleFilter: any;
  handleClear: any;
  tagsBoxRef: any;
  tagsList: IUserCategoryTagDto[];
  changeSpecificDataType: any;
  handleChange: any;
  phases: any;
  additionalFilter?: any;
  selectedStateTags?: any;
}
export const FilterOptions: React.FC<Props> = (props) => {
  const {
    phases,
    tagsList,
    handleChange,
    filters,
    handleClear,
    handleFilter,
    tagsBoxRef,
    changeSpecificDataType,
    selectedStateTags=null
  } = props;
  const [t] = useTranslation();
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTablet: isTablet || isTabletSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize: isMobileOrTablet.isMobile || isMobileOrTablet.isTablet,
  });
  return (
    <ExpandableContainer>
      <Accordion defaultExpanded={
        selectedStateTags?.tags?.length ? true : false ||
        selectedStateTags?.phaseId ? true : false ||
        selectedStateTags?.phaseStartDate ? true : false
        }>
        <AccordionSummary expandIcon={<img src={CollapseThinIcon} alt={'thin-collapse-icon'} />}>
          <Typography variant={'caption'}>
            {t('Homepage.sideMenuBar.connectionsDropDown.filterOptionsDropDown')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.searchContainer}>
          {props.additionalFilter}

          <div className={classes.phaseSelectContContainer}>
            <div className={classes.phaseSelectCont}>
              <Typography variant={'caption'}>
                {t('Homepage.sideMenuBar.connectionsDropDown.phaseSelectLabel')}
              </Typography>
              <Select
                name={'phaseSelect'}
                options={
                  phases.length > 1
                    ? phases.map((item: any) => ({
                        key: item.id,
                        value: item.name,
                      }))
                    : [{key: 0, value: ''}]
                }
                value={filters.phaseObject}
                onChange={handleChange}
                className={classes.phaseSelect}
                isDark={true}
              />
            </div>
            <div className={classes.phaseSelectCont}>
              <DatePicker
                name={'date'}
                label={t('Homepage.sideMenuBar.connectionsDropDown.startDateLabel')}
                containerClass={classes.dateContainer}
                value={filters.date}
                format={DATE_PICKER_DATE_FORMAT}
                placeholder={t('Homepage.sideMenuBar.connectionsDropDown.startDatePlaceholder')}
                onChange={(value: string) => changeSpecificDataType(value, 'date')}
              />
            </div>
          </div>
          <Typography variant={'caption'} style={{color: isMobile ? "#000" : ""}} >
            {t('Homepage.sideMenuBar.connectionsDropDown.tagsLabel')}
          </Typography>
          <CategoryChipTagsWithBox
            className={classes.maxWidthTags}
            id={CATEGORY_CHIP_TAG_ID}
            name={'category-chip-tags-with-box-id'}
            categoryTagsList={tagsList}
            ref={tagsBoxRef}
            sidebar={true}
            selectedStateTags={selectedStateTags}
          />

          <div className={classes.buttons}>
            <Button
              id={'clear-filters-connections-btn'}
              variant="outlined"
              onClick={handleClear}
              className={classes.clearBtn}
            >
              {t('Homepage.sideMenuBar.connectionsDropDown.clearButton')}
            </Button>
            <Button
              id={'filter-connections-btn'}
              onClick={() => handleFilter(filters.searchCriteria)}
              className={classes.filterBtn}
            >
              {t('Homepage.sideMenuBar.connectionsDropDown.filterButton')}
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </ExpandableContainer>
  );
};
