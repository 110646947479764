/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {useEffect, useRef} from 'react';
import useStyles from '../general/eligibility-page/Eligibility.styles';
import BetterHealthIcon from '../../assets/icons/betr-health-logo.svg';
import {useSelector} from 'react-redux';

const HlqLandboat = () => {
  const userData: any = useSelector((state: any) => state.users.currentUser);
  const classes = useStyles();
  const containerRef = useRef(null);

  useEffect(() => {
    if (userData) {
      const myLandbotFrame = new LandbotFrameWidget({
        index: `https://landbot.pro/v3/H-1746216-7V8GR8YTSJ4HHSUK/index.html?firstname=${userData?.firstName}&lastname=${userData?.lastName}&email=${userData?.email}&mobile=${userData?.phoneNumber}&userid${userData?.id}`,
        container: containerRef.current,
      });
      return () => myLandbotFrame.destroy();
    }
    return () => null;
  }, [userData]);
  return (
    <div className={classes.hlqPoster}>
      <div>
        <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
      </div>
      <div id="my-landbot-new" style={{width: '100%', height: '100vh'}} ref={containerRef}></div>
    </div>
  );
};

export default HlqLandboat;
