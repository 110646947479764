import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';
import DataItem from './DataItem';
import {TextField} from '../text-field/TextField';
import {Button} from '../button/Button';
import {DatePicker} from '../pickers/DatePicker';
import {Select} from '../select/Select';
import {dateToString, getAge} from '../../../utils/date-time-utils';
import {
  BodyMeasurementType,
  EMPTY_FIELD_PLACEHOLDER,
  MAX_INT_WEIGHT_VALUE_IN,
  MAX_INT_WEIGHT_VALUE_CM,
  MAX_INT_WEIGHT_VALUE_M,
  MAX_INT_WEIGHT_VALUE_FEET,
  ONLY_DATE_FORMAT,
  DATE_PICKER_DATE_FORMAT,
} from '../../../utils/constants';
import {Gender} from '../../../utils/enums';
import {checkIfUserIsMember} from '../../../utils/users-managment';
import {calculateHeightToSend, calculateHeightToDisplay} from '../../../utils/helpers';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../validation/Validation';
import {ValidationType} from '../../../validation/ValidationType';
import {
  editUserBasicInfo,
  callSaveUserObject,
} from '../../../store/actions/homepage/homepage-action';
import {selectMeasurementUnit} from '../../../store/selectors/homepage.selectors';

import useStyles from './basic-info-item.styles';

interface IBasicInfoProps {
  user: any;
  isMobile: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const BasicInfoItem: React.FC<IBasicInfoProps> = (props) => {
  const selectedMeasurementUnit = useSelector(selectMeasurementUnit);
  const validationRules = {
    gender: [
      {
        type: ValidationType.REQUIRED,
      },
    ],

    firstName: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    lastName: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    height1st: [
      {
        type: ValidationType.IS_INT_NUMBER,
        minValue: 0,
        maxValue:
          selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
            ? MAX_INT_WEIGHT_VALUE_M + 1
            : MAX_INT_WEIGHT_VALUE_FEET + 1,
        value: '1',
        errorMessage: `${i18n.t('ErrorMessages.notPositiveIntValue')} ${
          selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
            ? MAX_INT_WEIGHT_VALUE_M
            : MAX_INT_WEIGHT_VALUE_FEET
        }`,
      },
    ],
    height2nd: [
      {
        type: ValidationType.IS_INT_NUMBER,
        minValue: 0,
        maxValue:
          selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
            ? MAX_INT_WEIGHT_VALUE_CM + 1
            : MAX_INT_WEIGHT_VALUE_IN + 1,
        value: '1',
        errorMessage: `${i18n.t('ErrorMessages.notPositiveIntValue')} ${
          selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
            ? MAX_INT_WEIGHT_VALUE_CM
            : MAX_INT_WEIGHT_VALUE_IN
        }`,
      },
    ],
  };
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const {user} = props;
  const dispatch = useDispatch();
  const gender = Object.values(Gender);
  const [t] = useTranslation();
  const height = calculateHeightToDisplay(user?.height, selectedMeasurementUnit?.id);
  const [editMood, setEditMood] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    credentials: '',
    dateOfBirth: null,
    gender: {
      key: '',
      value: '',
    },
    height1st: '0',
    height2nd: '0',
    phoneNumber: '',
    email: '',
  });

  useEffect(() => {
    const initialObject = {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      credentials: user?.credentials || '',
      dateOfBirth: user?.dateOfBirth || null,
      gender: {
        key: user?.gender || '',
        value: user?.gender || '',
      },
      height1st: height ? height[0] : '0',
      height2nd: height ? height[1] : '0',
      phoneNumber: user?.phoneNumber || '',
      email: user?.email || '',
    };
    setData(initialObject);
  }, [user, selectedMeasurementUnit]);
  const handleEdit = () => {
    setEditMood(!editMood);
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'gender') {
      setData({
        ...data,
        [name]: {key: value.key, value: value.value},
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
    setHasChanges(true);
  };
  const changeSpecificDataType = (value: any, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
    setHasChanges(true);
  };
  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };
  const validateToSubmit = () => {
    if (hasChanges === false) return false;
    if (data.dateOfBirth === null || data.dateOfBirth === '' || data.gender.key === '')
      return false;
    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const heightToSend = calculateHeightToSend(
      data.height1st,
      data.height2nd,
      selectedMeasurementUnit.id,
    );
    const dataBasicInfo: any = {
      // userId: user.id,
      id: user.id,
      firstName: data.firstName,
      lastName: data.lastName,
      credentials: data.credentials,
      dateOfBirth: data.dateOfBirth,
      gender: data.gender.key,
      height: heightToSend,
      email: data.email,
      phoneNumber: data.phoneNumber,
    };
    const res1 = await dispatch(editUserBasicInfo(dataBasicInfo));
    // console.log("saved", res1);
    setIsLoading(false);
    setEditMood(false);
    if (!res1) {
      return;
    }
    await dispatch(callSaveUserObject(user.id));
  };

  const handleEditMood = () => {
    if (editMood) {
      return (
        <div>
          <div className={`${classes.rowContainer} ${props.isMobile ? 'mobile' : ''}`}>
            <TextField
              name={'firstName'}
              label={t('Homepage.quickProfileUser.firstName')}
              className={classes.normalTextField}
              value={data.firstName}
              onChange={handleChange}
              error={hasError(errors, 'firstName')}
              helperText={getError(errors, 'firstName')}
              onBlur={() => validateFormField('firstName')}
              containerClass={classes.normalTextField}
            />
            <TextField
              name={'lastName'}
              label={t('Homepage.quickProfileUser.lastName')}
              className={classes.normalTextField}
              value={data.lastName}
              onChange={handleChange}
              error={hasError(errors, 'lastName')}
              helperText={getError(errors, 'lastName')}
              onBlur={() => validateFormField('lastName')}
              containerClass={classes.normalTextField}
            />
            {!checkIfUserIsMember(user) && (
              <TextField
                name={'credentials'}
                label={t('Homepage.quickProfileUser.credentials')}
                className={classes.normalTextField}
                value={data.credentials}
                onChange={handleChange}
                error={hasError(errors, 'credentials')}
                helperText={getError(errors, 'credentials')}
                onBlur={() => validateFormField('credentials')}
                containerClass={classes.normalTextField}
              />
            )}
          </div>
          <div className={classes.rowContainer}>
            {/* <DataItem
              label={t('Homepage.quickProfileUser.phoneNumber')}
              value={user?.phoneNumber}
            /> */}
            <TextField
              name={'phoneNumber'}
              label={t('Homepage.quickProfileUser.phoneNumber')}
              className={classes.normalTextField}
              value={data.phoneNumber}
              onChange={handleChange}
              error={hasError(errors, 'phoneNumber')}
              helperText={getError(errors, 'phoneNumber')}
              onBlur={() => validateFormField('phoneNumber')}
              containerClass={classes.normalTextField}
            />
            <TextField
              name={'email'}
              label={t('Homepage.quickProfileUser.email')}
              className={classes.emailTextField}
              value={data.email}
              onChange={handleChange}
              error={hasError(errors, 'email')}
              helperText={getError(errors, 'email')}
              onBlur={() => validateFormField('email')}
              containerClass={classes.normalTextField}
            />
            {/* <DataItem label={t('Homepage.quickProfileUser.email')} value={user?.email} /> */}
          </div>
          <div className={`${classes.rowContainer} ${props.isMobile ? 'mobile' : ''}`}>
            <DatePicker
              name={'dateOfBirth'}
              required={true}
              label={t('Homepage.quickProfileUser.dateOfBirth')}
              className={classes.normalTextField}
              value={data.dateOfBirth}
              format={DATE_PICKER_DATE_FORMAT}
              placeholder={t('Homepage.quickProfileUser.enterDateOfBirth')}
              onChange={(value: string) => changeSpecificDataType(value, 'dateOfBirth')}
            />
            <Select
              name={'gender'}
              options={gender.map((item) => ({
                key: item,
                value: item,
              }))}
              value={data.gender}
              required={true}
              onChange={handleChange}
              className={classes.normalTextField}
              label={t('Homepage.quickProfileUser.gender')}
            />
            <div className={classes.heightContainer}>
              <TextField
                name={'height1st'}
                className={classes.heightTextField}
                label={t('Homepage.quickProfileUser.height')}
                value={data.height1st}
                required={true}
                onChange={handleChange}
                error={hasError(errors, 'height1st')}
                helperText={getError(errors, 'height1st')}
                onBlur={() => validateFormField('height1st')}
                containerClass={classes.heightTextField}
                unit={
                  selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
                    ? t('Homepage.quickProfileUser.heightM')
                    : t('Homepage.quickProfileUser.heightFeet')
                }
              />
              <TextField
                name={'height2nd'}
                className={classes.heightTextFieldRight}
                label={''}
                value={data.height2nd}
                onChange={handleChange}
                error={hasError(errors, 'height2nd')}
                helperText={getError(errors, 'height2nd')}
                onBlur={() => validateFormField('height2nd')}
                containerClass={classes.heightTextField}
                unit={selectedMeasurementUnit ? selectedMeasurementUnit.lengthUnit : ''}
                unitClassName={classes.unit}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <DataItem
              label={t('Homepage.quickProfileUser.age')}
              value={getAge(user?.dateOfBirth)}
            />
            <DataItem
              label={t('Homepage.quickProfileUser.memberSince')}
              value={dateToString(user?.created, ONLY_DATE_FORMAT)}
            />
            <DataItem label={t('Homepage.quickProfileUser.userId')} value={user?.id} />
          </div>
          <div className={classes.editButtons}>
            <Button
              id={'cancel-button-profile'}
              variant={'outlined'}
              className={classes.outlinedBtn}
              onClick={handleEdit}
            >
              {t('Homepage.quickProfileUser.cancelButton')}
            </Button>
            <Button
              id={'save-button-profile'}
              disabled={!validateToSubmit() || isLoading}
              onClick={handleSubmit}
              loading={isLoading}
            >
              {t('Homepage.quickProfileUser.saveButton')}
            </Button>
          </div>
        </div>
      );
    }

    if (props.isMobile) {
      return (
        <div className={classes.mainContainer}>
          <div className={classes.columnContainer}>
            <div className={classes.rowContainer}>
              <DataItem label={t('Homepage.quickProfileUser.firstName')} value={user?.firstName} />
              <DataItem label={t('Homepage.quickProfileUser.lastName')} value={user?.lastName} />
              {!checkIfUserIsMember(user) && (
                <DataItem
                  label={t('Homepage.quickProfileUser.credentials')}
                  value={user?.credentials}
                />
              )}
              <DataItem
                label={t('Homepage.quickProfileUser.phoneNumber')}
                value={user?.phoneNumber}
              />
              <DataItem label={t('Homepage.quickProfileUser.email')} value={user?.email} />
            </div>
            <div className={classes.rowContainer}>
              <DataItem
                label={t('Homepage.quickProfileUser.dateOfBirth')}
                value={user?.dateOfBirth && dateToString(user?.dateOfBirth, ONLY_DATE_FORMAT)}
              />
              <DataItem
                label={t('Homepage.quickProfileUser.age')}
                value={getAge(user?.dateOfBirth)}
              />
              <DataItem label={t('Homepage.quickProfileUser.gender')} value={user?.gender} />
              {height ? (
                <div>
                  <DataItem
                    label={t('Homepage.quickProfileUser.height')}
                    value={`${height[0]} ${
                      selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
                        ? t('Homepage.quickProfileUser.heightM')
                        : t('Homepage.quickProfileUser.heightFeet')
                    }
                      ${height[1] || 0} ${selectedMeasurementUnit?.lengthUnit}`}
                  />
                </div>
              ) : (
                EMPTY_FIELD_PLACEHOLDER
              )}
            </div>
            <div className={classes.rowContainer}>
              <DataItem
                label={t('Homepage.quickProfileUser.memberSince')}
                value={dateToString(user?.created, ONLY_DATE_FORMAT)}
              />
              <DataItem label={t('Homepage.quickProfileUser.userId')} value={user?.id} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.mainContainer}>
        <div className={classes.columnContainer}>
          <div className={classes.rowContainer}>
            <DataItem label={t('Homepage.quickProfileUser.firstName')} value={user?.firstName} />
          </div>
          <div className={classes.rowContainer}>
            <DataItem
              label={t('Homepage.quickProfileUser.phoneNumber')}
              value={user?.phoneNumber}
            />
          </div>
          <div className={classes.rowContainer}>
            <DataItem
              label={t('Homepage.quickProfileUser.dateOfBirth')}
              value={user?.dateOfBirth && dateToString(user?.dateOfBirth, ONLY_DATE_FORMAT)}
            />
          </div>
          <div>
            <DataItem
              label={t('Homepage.quickProfileUser.memberSince')}
              value={dateToString(user?.created, ONLY_DATE_FORMAT)}
            />
          </div>
        </div>
        <div className={classes.columnContainer}>
          <div className={classNames(classes.rowContainer, classes.rowJustify)}>
            <DataItem label={t('Homepage.quickProfileUser.lastName')} value={user?.lastName} />
            {!checkIfUserIsMember(user) && (
              <DataItem
                label={t('Homepage.quickProfileUser.credentials')}
                value={user?.credentials}
              />
            )}
          </div>
          <div className={classes.rowContainer}>
            <DataItem label={t('Homepage.quickProfileUser.email')} value={user?.email} />
          </div>
          <div className={classes.rowContainer}>
            <DataItem
              label={t('Homepage.quickProfileUser.age')}
              value={getAge(user?.dateOfBirth)}
            />
            <DataItem label={t('Homepage.quickProfileUser.gender')} value={user?.gender} />
            {height ? (
              <div>
                <DataItem
                  label={t('Homepage.quickProfileUser.height')}
                  value={`${height[0]} ${
                    selectedMeasurementUnit?.lengthUnit === BodyMeasurementType.CM
                      ? t('Homepage.quickProfileUser.heightM')
                      : t('Homepage.quickProfileUser.heightFeet')
                  }
                  ${height[1] || 0} ${selectedMeasurementUnit?.lengthUnit}`}
                />
              </div>
            ) : (
              EMPTY_FIELD_PLACEHOLDER
            )}
          </div>
          <DataItem label={t('Homepage.quickProfileUser.userId')} value={user?.id} />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.titleContainer}>
        <Typography variant={'subtitle1'} className={classes.rowContainer}>
          {t('Homepage.quickProfileUser.basicProfileInfo')}
        </Typography>
        <Button
          id={'edit-btn'}
          variant={'text'}
          onClick={handleEdit}
          className={editMood ? classes.editBtn : ''}
        >
          {t('Homepage.quickProfileUser.editButton')}
        </Button>
      </div>
      {handleEditMood()}
    </React.Fragment>
  );
};

export default BasicInfoItem;
