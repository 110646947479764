import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  select: {
    width: (props) => (props.isMobile ? '100%' : '177px'),
    marginRight: (props) => (props.isMobile ? '0' : '24px'),
    '& >div': {
      width: '100%',
    },
  },
  tagsContainer: {
    marginTop: 22,
  },
}));

export default useStyles;
