import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  categoryTagList: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 0,
    width: '100%',
    maxHeight: '100%',
    borderRadius: 0,
    '& .MuiDialog-container': {
      width: '100%',
    },
    '& .MuiAccordion-root': {
      background: theme.palette.background.paper,
      color: theme.palette.blacksAndWhites.smokyBlack,
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '32px',
      height: '32px',
    },
  },
  tagsPart: {},
  searchContainer: {
    padding: '8px',
  },
  category: {
    '& .MuiAccordionSummary-root.Mui-expanded': {
      background: theme.palette.blacksAndGrays.cultured,
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    '& button': {
      border: 'none',
      background: 'transparent',
      color: theme.palette.primary.main,
      fontFamily: "Gantari, sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      padding: '16px',
    },
  },
  boxTags: {
    marginRight: 8,
    marginLeft: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: 8,
    paddingTop: 0,
    minHeight: 40,
  },
  tagMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    borderBottom: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    '& button': {
      background: 'transparent',
      width: '20px',
      height: '20px',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      padding: '2px',
    },
  },
}));

export default useStyles;
