import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  normalTextField: {
    width: (props) => (props.isMobile ? '46px' : '96px'),
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
    },
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  stepsLabel: {
    marginBottom: 8,
  },
  bodyContainer: {
    marginTop: 22,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    width: (props) => (props.isMobile ? 164 : ''),
    '& img': {
      width: (props) => (props.isMobile ? '100%' : ''),
    },
  },
  bodyPartContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    height: (props) => (props.isMobile ? 40 : 60),
  },
  bodyTextLabel: {
    marginLeft: (props) => (props.isMobile ? 8 : 16),
    marginRight: 8,
    color: theme.palette.secondary.dark,
    fontSize: (props) => (props.isMobile ? '12px' : '16px'),
    lineHeight: (props) => (props.isMobile ? '16px' : '20px'),
    whiteSpace: 'pre',
  },
  circleLine: {
    height: 2,
    background: theme.palette.blacksAndGrays.silverSand,
  },
  valueField: {
    '& button': {
      background: 'transparent',
      padding: 0,
      border: 'none',
      color: theme.palette.blacksAndGrays.stoneGray,
      whiteSpace: 'pre',
    },
  },
  upperArmAddition: {
    left: (props) => (props.isMobile ? 137 : 205),
    top: (props) => (props.isMobile ? 97 : 160),

    '& .circleLineWidth': {
      width: (props) => (props.isMobile ? 32 : 87),
    },
  },
  chestAddition: {
    left: (props) => (props.isMobile ? 79 : 115),
    top: (props) => (props.isMobile ? 133 : 220),

    '& .circleLineWidth': {
      width: (props) => (props.isMobile ? 90 : 177),
    },
  },
  waistAddition: {
    left: (props) => (props.isMobile ? 79 : 115),
    top: (props) => (props.isMobile ? 167 : 279),

    '& .circleLineWidth': {
      width: (props) => (props.isMobile ? 90 : 177),
    },
  },
  hipsAddition: {
    left: (props) => (props.isMobile ? 108 : 159),
    top: (props) => (props.isMobile ? 208 : 340),

    '& .circleLineWidth': {
      width: (props) => (props.isMobile ? 61 : 133),
    },
  },
  thighsAddition: {
    left: (props) => (props.isMobile ? 53 : 64),
    top: (props) => (props.isMobile ? 249 : 400),

    '& .circleLineWidth': {
      width: (props) => (props.isMobile ? 116 : 228),
    },
  },
  circleContainer: {
    width: (props) => (props.isMobile ? 12 : 24),
    height: (props) => (props.isMobile ? 12 : 24),
    background: theme.palette.blacksAndGrays.silverSand,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCircle: {
    width: (props) => (props.isMobile ? 7 : 14),
    height: (props) => (props.isMobile ? 7 : 14),
    background: theme.palette.blacksAndWhites.pureWhite,
    borderRadius: '50%',
  },
  selectedPart: {
    '& .circleLine': {
      background: theme.palette.blacksAndGrays.grayX11,
    },

    '& $innerCircle': {
      background: theme.palette.primary.light,
    },

    '& $circleContainer': {
      background: theme.palette.blacksAndGrays.grayX11,
    },

    '& $bodyTextLabel': {
      color: theme.palette.blacksAndGrays.davysGray,

      '& p': {
        fontWeight: 700,
        fontSize: (props) => (props.isMobile ? '12px' : '14px'),
        lineHeight: (props) => (props.isMobile ? '16px' : '20px'),
      },
    },
  },
  switcherCenter: {
    position: 'absolute',
    top: 90,
    left: 330,
  },
  tooltip: {
    padding: '6px 2px',
    fontWeight: 500,
    fontSize: '10px',
  },
}));

export default useStyles;
