import * as React from 'react';
import {Card, CardContent, CardMedia, Typography} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import lockClosedIcon from '../../../assets/images/lock-closed.svg';
import PlaceholderWorkout from '../../../assets/images/workoutsimage.png';
import useStyles from './fitness-card.styles';
import Loader from '../loader/Loader';

interface Props {
  recipe: any;
  //   onClick: (slug: string) => void;
  //   handelYogas: (recipe: any) => void;
  containerStyle?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  setIsYogaList: any;
  setWorkoutData: any;
  userData: any;
  key: any;
}

export const YogaCard: React.FC<Props> = ({
  recipe,
  containerStyle,
  isMobileOrTablet,
  smallScreens,
  setIsYogaList,
  setWorkoutData,
  key,
}) => {
  const [isLoader, setIsLoader] = React.useState(false);
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  //   const [slug, setSlug] = React.useState('');
  //   const history = useHistory();
  //   const dispatch = useDispatch();

  const handelShowYogas = async (workout: any) => {
    setIsYogaList(true);
    setWorkoutData(workout);
    setIsLoader(true);
  };
  const myShopfiyRedirect = () => {
    // window.open(
    //   buyLink,
    //   '_blank', // <- This is what makes it open in a new window.
    // );
  };

  return (
    <div key={key} className={classes.fitnessList}>
      <Card
        style={{minHeight: isMobileOrTablet.isMobile ? 170 : 220, minWidth: 150}}
        className={`${classes.card} ${containerStyle}`}
        //   onClick={(recipe?.tag && !recipe?.userId) ? () => myShopfiyRedirect(recipe?.buyLink) : () => slug ? onClick(slug) : {}}
        onClick={
          recipe?.tag === 'Yoga' && !recipe?.userId
            ? () => myShopfiyRedirect()
            : () => handelShowYogas(recipe)
        }
        id={recipe.id}
      >
        {isLoader && <Loader className={`${classes.fitnessLoader} ${classes.loaderAb}`} />}
        {/* {recipe.id !== 26 && recipe.id !== 27 && recipe.id !== 28 && (
          <div className={classes.lockCard} style={{cursor: 'pointer'}}>
            <img src={lockClosedIcon} alt="" />
          </div>
        )} */}

        {recipe?.tag === 'Yoga' && !recipe?.userId && (
          <div className={classes.lockCard} style={{cursor: 'pointer'}}>
            <img src={lockClosedIcon} alt="" />
          </div>
        )}
        <CardMedia
          component="img"
          height={isMobileOrTablet.isMobile ? '82' : '148'}
          image={recipe?.thumbNailImage || PlaceholderWorkout}
          alt="recipe-image"
        />
        {/* <LevelFlag
        title={recipe?.level_category?.name}
        levelCategory={recipe?.level_category}
        top={8}
        right={8}
      /> */}
        <div className={classes.infoContainer}>
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="div">
              {recipe?.name}
            </Typography>
          </CardContent>
          <div className={classes.ratingContainer}>
            <div className={classes.rating}>
              {/*<img src={StarIcon} alt={'rating-icon'} width={16} />*/}
              {/*<div className={classes.ratingValue}>{recipe.rating || 0}</div>*/}
            </div>
            <div className={classes.showTime}>
              <div className={classes.ratingValue}>
                {recipe?.days} {recipe?.days === 1 || recipe?.days === '1' ? 'video' : 'videos'}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
