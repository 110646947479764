import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  progressFilterDate: {},
  buttons: {
    background: theme.palette.blacksAndWhites.gainsboro,
    borderRadius: '12px',
    padding: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  dateButton: {
    background: 'transparent',
    color: theme.palette.text.primary,
    width: '100%',
  },
  dateButtonActive: {
    background: theme.palette.background.paper,
    borderRadius: '8px',
  },
  line: {
    width: '1px',
    height: '20px',
    borderRight: `1px solid ${theme.palette.background.paper}`,
  },
  dateRange: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    '& button': {
      minWidth: (props) => (props.isMobile ? '20px' : ''),
      width: (props) => (props.isMobile ? '50px' : ''),
    },
    marginBottom: (props) => (props.isMobile ? '10px' : '0'),
  },
  previousButton: {
    marginLeft: 'auto',
  },
  nextButton: {
    marginRight: 'auto',
  },
  datePickers: {
    display: 'flex',
    marginBottom: 16,
    '& > div:nth-child(1)': {
      marginRight: (props) => (props.isMobile ? '8px' : '12px'),
    },
    '& > div:nth-child(2)': {
      marginLeft: (props) => (props.isMobile ? '8px' : '12px'),
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-input': {
      fontSize: (props) => (props.isMobile ? '14px' : '16px'),
      padding: (props) => (props.isMobile ? '11px 0px 11px 12px ' : '11px 16px'),
    },
  },
}));

export default useStyles;
