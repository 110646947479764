import React from 'react'
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import happyicon from '../../../../assets/images/happyicon.png';

interface Props {
  isTotalScore?: boolean;
  effectivenessScore?: number,
  batchIcon?: string
}

const ProgressCircular: React.FC<Props> = ({isTotalScore, effectivenessScore, batchIcon}) => {
    const percentage = effectivenessScore === 0 ? effectivenessScore : effectivenessScore || 50;
   
 
  return (
   <>
      
<div style={{
  display:"flex",
  ...!isTotalScore ? {width: '105px', justifyContent: "space-between", alignItems: 'end'} : {alignItems: 'end', justifyContent: 'center'}
  }} >

<div style={{
  ...!isTotalScore ? {width: 45, height: 45, marginRight:16} : {width: 84, height: 84}}}>
<CircularProgressbar value={percentage} text={`${percentage}%`} 
styles={buildStyles({
    rotation: 0.53,
    strokeLinecap: 'butt',
    // Text size
    textSize: '25px',
    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,
    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',
    // Colors
    pathColor: `#000, ${percentage / 100})`,
    textColor: '#000',
    trailColor: '#d7e6f9',
    fontWeight:"700",
    background: "radial-gradient(83.32% 398% at 41.13% -206%, #65F7F7 0%, #3986DF 64.23%, #3655D2 100%)"
  })}
/>
</div>
{!isTotalScore && <img src={batchIcon || happyicon} alt='' width={50} />}
</div>

   </>
  )
}

export default ProgressCircular