import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  ouncesLabel: {
    color: theme.palette.blacksAndGrays.davysGray,
    marginBottom: 8,
  },
}));

export default useStyles;
