import {Typography, useMediaQuery} from '@material-ui/core';
import * as React from 'react';
import {useHistory, useParams} from 'react-router';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {
  // DEFAULT_PAGE_START,
  // DEFAULT_PER_PAGE_SIZE,
  GeneralPaths,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';
import {FitnessCard} from './FitnessCard';
import useStyles from './fitness-content.styles';
import challengePromoBanner from '../../../assets/images/challenge-promo-banner.png';
// import DayOne from "./dayexercise/DayOne";
import DayTwo from './dayexercise/DayTwo';
import MovementChallenge from './movementchallenge/MovementChallenge';
// import TransitionsModal from "./feedbackpopup/FeedbackPopop";
// import TransitionsModal2 from "./feedbackpopup/FeedbackPopop2";
// import ThankFeedback from "./feedbackpopup/ThankFeedback";
// import ThankFeedback2 from "./feedbackpopup/ThankFeedback2";
import DisclosureAgreement from './disclosure-agreement/DisclosureAgreement';
// import LetsStart from './lets-start/LetsStart';
// import IframeKemtai from './iframe-kemtai/IframeKemtai';
// import workoutimg from '../../../assets/images/workoutsimage.png';
// import day8 from '../../../assets/images/day8.png';
import {useDispatch, useSelector} from 'react-redux';
import {
  getKemTaiUserWorkoutHistory,
  getKemTaiWorkoutGroups,
} from '../../../store/actions/kemtai/kemtai-action';
import Loader from '../loader/Loader';
import {containsParamInHistory, getHistoryParams} from '../../../utils/qs-helper';
import {MovementLanding} from './MovementLanding';
import YogaLists from './yogaLists';
interface Props {
  recipes?: any[];
  page?: number;
  count?: number;
  onChangePage?: (event: any, page: number) => void;
}

type RouteParamProps = {
  workoutGroupSlug?: string;
  workoutSlug?: string;
  movementType?: string;
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const FitnessContent: React.FC<Props> = () => {
  const [isDisclosure, setIsDisclosure] = React.useState(false);
  // const [isAgreement, setIsAgreement] = React.useState(false);
  // const [isAgreementSkip, setIsAgreementSkip] = React.useState(false);
  const [bannerWorkout, setBannerWorkout] = React.useState(null);
  const [selectedWorkoutID, setSelectedWorkoutID] = React.useState(5);
  const [isLoader, setIsLoader] = React.useState(false);
  const [screens, setScreens] = React.useState({
    isLanding: true,
    isWorkoutType: false,
    isWorkoutDayType: false,
    isYogaScreen: false,
    yogaData: '',
  });

  // const [selectedWorkoutDay, setSelectedWorkoutDay] = React.useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const params: RouteParamProps = useParams();
  const {challengesWorkoutGroups, routinesWorkoutGroups, YogaGroups} = useSelector(
    (state: any) => state.kemtai,
  );

  const userData: any = useSelector((state: any) => state.users.currentUser);

  const workout5 =
    challengesWorkoutGroups && challengesWorkoutGroups.find((workout) => workout.id === 5);

  React.useEffect(() => {
    dispatch(getKemTaiUserWorkoutHistory(userData?.id, 5)).then((res: any) => {
      setBannerWorkout(res);
    });
    dispatch(getKemTaiWorkoutGroups(userData?.id, 2));
    dispatch(getKemTaiWorkoutGroups(userData?.id, 1));
    dispatch(getKemTaiWorkoutGroups(userData?.id, 4));
  }, []);

  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE - 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);

  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });

  const handleOnCardClick = (slug: string, deepLinking = false) => {
    // setSlug(slug!);
    // setOpen(true);
    if (!deepLinking) {
      if (params?.movementType === 'rehab') {
        history.replace({
          pathname: `${GeneralPaths.MovementPage}/${slug}`,
          // search: `?type-slug=${NourishType.MOVEMENT_MODE.toString()}&_workoutgroupid=${slug}`,
        });
      } else if (params?.movementType === 'yoga') {
        history.replace({
          pathname: `${GeneralPaths.MovementPage}/yoga/${slug}`,
          // search: `?type-slug=${NourishType.MOVEMENT_MODE.toString()}&_workoutgroupid=${slug}`,
        });
      } else {
        history.replace({
          pathname: `${GeneralPaths.MovementPage}/fitness/${slug}`,
          // search: `?type-slug=${NourishType.MOVEMENT_MODE.toString()}&_workoutgroupid=${slug}`,
        });
      }
    }
  };

  React.useEffect(() => {
    if (params?.movementType === 'rehab' && params?.workoutGroupSlug) {
      setScreens({
        ...screens,
        isLanding: false,
        isWorkoutType: false,
        isWorkoutDayType: true,
      });
    } else if (params?.movementType === 'rehab') {
      setScreens({
        ...screens,
        isLanding: false,
        isWorkoutType: true,
      });
      handleOnCardClick(params?.movementType, true);
    } else if (params?.workoutGroupSlug && params?.workoutSlug) {
      setScreens({
        ...screens,
        isLanding: false,
        isWorkoutType: false,
        isWorkoutDayType: true,
      });
    } else if (params?.workoutGroupSlug) {
      setScreens({
        ...screens,
        isLanding: false,
        isWorkoutType: true,
      });
      handleOnCardClick(params?.workoutGroupSlug, true);
    } else if (containsParamInHistory(history, '_workoutid')) {
      const params = getHistoryParams(history);
      if (
        params?._workoutgroupid &&
        params?._workoutid &&
        params['type-slug'] === 'movement-mode'
      ) {
        setScreens({
          ...screens,
          isLanding: false,
          isWorkoutType: false,
          isWorkoutDayType: true,
        });
      }
    } else if (containsParamInHistory(history, '_workoutgroupid')) {
      const params = getHistoryParams(history);
      if (params?._workoutgroupid && params['type-slug'] === 'movement-mode') {
        setScreens({
          ...screens,
          isLanding: false,
          isWorkoutType: true,
        });
        //   addEngagementPointDatabase({
        //     eventType: EngagementPointType.RECIPE_READ,
        //     description: params?._slug || '',
        //   });
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleOnCardClick(params?._workoutgroupid, true);
      }
    }
  }, []);

  const handleWorkoutAction = (workout: any, slug: any) => {
    setIsLoader(true);
    setSelectedWorkoutID(workout.id);
    dispatch(getKemTaiUserWorkoutHistory(userData?.id, workout?.id)).then((res: any) => {
      if (res && res?.length) {
        handleOnCardClick(slug, false);
      } else {
        setIsDisclosure(true);
      }
      setIsLoader(false);
    });
  };
  // const handleWorkoutYoga = (workout: any, slug: any) => {
  //   setIsLoader(true);

  //   if (workout?.workOutGroupCount > 0) {
  //     console.log('workout', workout);
  //     history.replace({
  //       pathname: `${GeneralPaths.MovementPage}/yoga/${workout.name}`,
  //       // search: `?type-slug=${NourishType.MOVEMENT_MODE.toString()}&_workoutgroupid=${slug}`,
  //     });
  //     setScreens({
  //       ...screens,
  //       isLanding: false,
  //       isWorkoutType: false,
  //         isWorkoutDayType: false,
  //       isYogaScreen: true,
  //       yogaData:workout
  //     });
  //     handleOnCardClick(params?.workoutGroupSlug, true);
  //   }
  //   setSelectedWorkoutID(workout.id);
  //   // dispatch(getKemTaiUserWorkoutHistory(userData?.id, workout?.id)).then((res: any) => {
  //   //   if (res && res?.length) {
  //   //     handleOnCardClick(slug, false);
  //   //   } else {
  //   //     setIsDisclosure(true);
  //   //   }
  //   //   setIsLoader(false);
  //   // });
  // };

  const renderRecipes = (group: any) =>
    group &&
    group.map((workout: any) => (
      <FitnessCard
        recipe={workout}
        key={workout.id}
        onClick={(slug) => handleWorkoutAction(workout, slug)}
        // handelYogas={() => handelDisplayyoga}
        isMobileOrTablet={isMobileOrTablet}
        smallScreens={smallScreens}
      />
    ));

  const myShopfiyRedirect = (buyLink: any) => {
    window.open(
      buyLink,
      '_blank', // <- This is what makes it open in a new window.
    );
  };

  return (
    <div id={'scrollableDiv'} className={classes.fullWidth}>
      {/* <IframeKemtai />
      <TransitionsModal/>
      <TransitionsModal2/>
      <ThankFeedback/>
      <ThankFeedback2/>
      <DayOne/>
      <DayTwo/>
      <MovementChallenge/> */}
      {screens?.isWorkoutType && (
        <MovementChallenge
          selectedWorkoutID={selectedWorkoutID}
          setScreens={setScreens}
          screens={screens}
          // setSelectedWorkoutDay={setSelectedWorkoutDay}
        />
      )}

      {/* <YogaIntro isMobileOrTablet={isMobileOrTablet} smallScreens={smallScreens}  /> */}

      {screens?.isWorkoutDayType && (
        <DayTwo
          // selectedWorkoutID={selectedWorkoutID}
          // setScreens={setScreens}
          // screens={screens}
          // setSelectedWorkoutDay={setSelectedWorkoutDay}
          selectedWorkoutID={selectedWorkoutID}
          setScreens={setScreens}
          screens={screens}
          // setSelectedWorkoutDay={setSelectedWorkoutDay}
        />
      )}

      {/* <LetsStart /> */}
      <DisclosureAgreement
        isDisclosure={isDisclosure}
        setIsDisclosure={setIsDisclosure}
        // setIsAgreement={setIsAgreement}
        selectedWorkoutID={selectedWorkoutID}
        setBannerWorkout={setBannerWorkout}
      />

      {params?.movementType === 'fitness' ? (
        <>
          {/* {(isAgreementSkip ? null : !isAgreement) && <div className={classes.fitnessBanner}> */}
          {isLoader && <Loader className={classes.fitnessLoader} />}
          {screens.isLanding && (
            <>
              {bannerWorkout && !bannerWorkout?.length ? (
                <div className={classes.fitnessBanner}>
                  <h1 className={classes.bannerTitle}>Real-time, personalized coaching.</h1>
                  <sub>Any time, anywhere.</sub>
                  <img src={challengePromoBanner} alt="Real-time, personalized coaching." />
                  <h2>Get moving with Betr’s virtual fitness tool.</h2>
                  <p>
                    The tool superimposes key points over your body and analyzes your movements with
                    sophisticated software.
                  </p>
                  <h3>To start:</h3>
                  <ul>
                    <li>
                      <b>Set up:</b>
                      Grab your mat and get situated so that your full body is in frame.
                    </li>
                    <li>
                      <b>Start:</b>
                      Choose from a variety of classes and get moving. The fitness tool will provide
                      constructive feedback.
                    </li>
                    <li>
                      <b>Rest and repeat:</b>
                      You can work out anywhere, with no need to schedule an appointment or set up
                      special equipment.
                    </li>
                  </ul>
                  <h2>Start a healthy habit today.</h2>
                  <div className={'bannerBottom'}>
                    {/* <h2>Join the 14-Day Betr Movement Challenge! </h2> */}
                    {workout5?.tag && !workout5?.userId ? (
                      <button
                        href="javascript:void(0)"
                        className={`${classes.button} howWorks`}
                        onClick={() => myShopfiyRedirect(workout5?.buyLink)}
                      >
                        Get Started
                      </button>
                    ) : (
                      <button
                        href="javascript:void(0)"
                        className={`${classes.button} howWorks`}
                        onClick={() => setIsDisclosure(true)}
                      >
                        {' '}
                        Start Now
                      </button>
                    )}
                    {/* <a href="javascript:void(0)" className={classes.button} onClick={() => setIsAgreementSkip(true)}>Skip and Subscibe Now</a> */}
                  </div>
                </div>
              ) : null}

              <Typography variant={'h6'} align="left">
                Challenges
              </Typography>
              <div className={classes.fitnessList}>{renderRecipes(challengesWorkoutGroups)}</div>
              <Typography variant={'h6'} align="left">
                Fitness Routines
              </Typography>
              <div className={classes.fitnessList}>{renderRecipes(routinesWorkoutGroups)}</div>
            </>
          )}
        </>
      ) : params?.movementType === 'fitness' ? (
        <>
          {/* {(isAgreementSkip ? null : !isAgreement) && <div className={classes.fitnessBanner}> */}
          {isLoader && <Loader className={classes.fitnessLoader} />}
          {screens.isLanding && (
            <>
              {bannerWorkout && !bannerWorkout?.length ? (
                <div className={classes.fitnessBanner}>
                  <h1 className={classes.bannerTitle}>Real-time, personalized coaching.</h1>
                  <sub>Any time, anywhere.</sub>
                  <img src={challengePromoBanner} alt="Real-time, personalized coaching." />
                  <h2>Get moving with Betr’s virtual fitness tool.</h2>
                  <p>
                    The tool superimposes key points over your body and analyzes your movements with
                    sophisticated software.
                  </p>
                  <h3>To start:</h3>
                  <ul>
                    <li>
                      <b>Set up:</b>
                      Grab your mat and get situated so that your full body is in frame.
                    </li>
                    <li>
                      <b>Start:</b>
                      Choose from a variety of classes and get moving. The fitness tool will provide
                      constructive feedback.
                    </li>
                    <li>
                      <b>Rest and repeat:</b>
                      You can work out anywhere, with no need to schedule an appointment or set up
                      special equipment.
                    </li>
                  </ul>
                  <h2>Start a healthy habit today.</h2>
                  <div className={'bannerBottom'}>
                    {/* <h2>Join the 14-Day Betr Movement Challenge! </h2> */}
                    {workout5?.tag && !workout5?.userId ? (
                      <button
                        href="javascript:void(0)"
                        className={`${classes.button} howWorks`}
                        onClick={() => myShopfiyRedirect(workout5?.buyLink)}
                      >
                        Get Started
                      </button>
                    ) : (
                      <button
                        href="javascript:void(0)"
                        className={`${classes.button} howWorks`}
                        onClick={() => setIsDisclosure(true)}
                      >
                        {' '}
                        Start Now
                      </button>
                    )}
                    {/* <a href="javascript:void(0)" className={classes.button} onClick={() => setIsAgreementSkip(true)}>Skip and Subscibe Now</a> */}
                  </div>
                </div>
              ) : null}

              <Typography variant={'h6'} align="left">
                Challenges
              </Typography>
              <div className={classes.fitnessList}>{renderRecipes(challengesWorkoutGroups)}</div>
              <Typography variant={'h6'} align="left">
                Fitness Routines
              </Typography>
              <div className={classes.fitnessList}>{renderRecipes(routinesWorkoutGroups)}</div>
            </>
          )}
        </>
      ) : params?.movementType === 'yoga' ? (
        <YogaLists
          isMobileOrTablet={isMobileOrTablet}
          smallScreens={smallScreens}
          YogaGroups={YogaGroups}
        />
      ) : // : params?.movementType === 'rehab' ?
      //   <div className={classes.fitnessList}>{renderRecipes(rehabWorkoutGroups)}</div>
      params?.movementType === 'rehab' ? null : (
        <div className={classes.movementLanding}>
          <h3 className="headingh3">Movement</h3>
          <div className={classes.fitnessList}>
            <MovementLanding isMobileOrTablet={isMobileOrTablet} smallScreens={smallScreens} />
          </div>
        </div>
      )}
    </div>
  );
};
