import {get, del, post, put} from './api-calls';
import {IWeightDto} from '../dtos/IWeightDto';

export const getWeightLoggs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IWeightDto[]> => {
  return get(`/weight?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addWeightLoggDatabase = (data: IWeightDto): Promise<any> => {
  return post('/weight', data);
};

export const editWeightLoggDatabase = (data: IWeightDto): Promise<any> => {
  return put(`/weight/${data.id}`, data);
};

export const deleteWeightLoggDatabase = (id: number): Promise<any> => {
  return del(`/weight/${id}`);
};

export const getBodyWeightLogById = (id: number): Promise<IWeightDto> => {
  return get(`/weight/${id}`);
};
