import {IRecipeTagsDto} from '../../../dtos/IRecipeTagsDto';
import {IMealPlanTagsDto} from '../../../dtos/IMealPlanTagsDto';
import {DEFAULT_PER_PAGE_SIZE} from '../../../utils/constants';

export const initialMealPlansTagsScheme: IMealPlanTagsDto = {
  categories: [],
};

export const initialRecipesTagsScheme: IRecipeTagsDto = {
  courses: [],
  cuisines: [],
  ingredients: [],
};
// Configuration for sidebar recipe filters
export const sidebarRecipeFiltersModel = {
  recipeFilters: {
    minimal: true,
    pagination: {
      _limit: DEFAULT_PER_PAGE_SIZE,
      _start: 0,
    },
    filter: {
      ['title_contains']: undefined,
      ['level_category.name_in']: undefined,
      ['cuisine_category.name_in']: [],
      ['course_category.name_in']: [],
      ['ingredient_categories.name_in']: [],
    },
  },
  recipesTags: initialRecipesTagsScheme,
  selectedRecipesFilters: {
    searchCriteria: '',
    levelObject: {
      key: 0,
      value: '',
    },
    categoryObject: {
      key: 0,
      value: '',
    },
  },
  selectableIngredients: [],
};

export const sidebarMealPlansFiltersModel = {
  mealPlansFilters: {
    minimal: true,
    pagination: {
      _limit: DEFAULT_PER_PAGE_SIZE,
      _start: 0,
    },
    filter: {
      ['name_contains']: undefined,
      ['level_category.name_in']: undefined,
      ['categories.name_in']: [],
    },
  },
  mealPlansTags: initialMealPlansTagsScheme,
  selectedMealPlansFilters: {
    searchCriteria: '',
    levelObject: {
      key: 0,
      value: '',
    },
  },
};

export const sidebarBetrProgramFiltersModel = {
  betrProgramFilters: {
    minimal: true,
    pagination: {
      _limit: DEFAULT_PER_PAGE_SIZE,
      _start: 0,
    },
    filter: {
      ['daily_content_category.name']: undefined,
      ['_sort']: 'tag',
    },
  },
};
