import React, {useEffect, useRef, useState} from 'react';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import SleepIcon from '../../../../assets/icons/side-menu-bar-logging/sleep-icon.svg';
import {Button} from '../../button/Button';
import useStyles from './sleep-form.styles';
import {Typography} from '@material-ui/core';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {FormType, WidgetType} from '../../../../utils/enums';
import {ValidationType} from '../../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../../validation/Validation';
import moment from 'moment';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {selectSleepTags} from '../../../../store/selectors/loggingPageSelector';
import TagsWithBox from '../../tags-with-box/TagsWithBox';
import {ICasualTag} from '../../../../models/ITag';
import {
  addSleepLog,
  editSleepLogg,
} from '../../../../store/actions/logging-page/logging-page-action';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {TimeFieldPicker} from '../../pickers/TimeFieldPicker';
import {addLeadingZeros} from '../../../../utils/StringHelpers';
import i18n from 'i18next';
import useCommonStyles from '../common-for-widgets.styles';
import {DATE_PICKER_DATE_FORMAT} from '../../../../utils/constants';

interface ISleepFormData {
  date: string;
  time: string;
  duration: string;
  tags: ICasualTag[];
}
interface ISleepFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  openedFromDialog?: boolean;
  date?: string;
  isMobile: boolean;
}

const momentValue: string = moment
  .utc(moment().set({hour: 7, minute: 0}))
  .local()
  .toString();

const SleepForm: React.FC<ISleepFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  const [data, setData] = useState<ISleepFormData>({
    date: moment().toString(),
    time: moment().toString(),
    duration: momentValue,
    tags: [],
  });
  const formType = props.formType || FormType.ADDING_MODE;
  const initialTagList = useSelector(selectSleepTags);
  const [tagsList, setTagsList] = useState<ICasualTag[]>([]);
  const tagsBoxRef: any = useRef();

  const validationRules = {
    duration: [
      {
        type: ValidationType.REQUIRED_DURATION,
        errorMessage: i18n.t('ErrorMessages.sleepDurationErr'),
      },
    ],
    date: [{type: ValidationType.REQUIRED}],
    time: [{type: ValidationType.REQUIRED_DURATION}],
  };

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    const setTags = () => {
      initialTagList.forEach((item) => (item.isVisible = true));
      const filteredTagsArray: ICasualTag[] = [];
      initialTagList.forEach((initialTagObject) => {
        props.data?.tagsObject.forEach((oneTagObject: ICasualTag) => {
          if (initialTagObject.id === oneTagObject.id) {
            initialTagObject.isVisible = false;
          }
        });

        filteredTagsArray.push(initialTagObject);
      });

      setTagsList(filteredTagsArray);
    };

    let initialObject: ISleepFormData = {
      date: props.date || moment().toString(),
      time: props.date || moment().toString(),
      duration: momentValue,
      tags: [],
    };
    if (formType == FormType.EDITING_MODE) {
      const padded = addLeadingZeros(props.data?.duration || 700);
      const hours: number = parseInt(padded.substr(0, 2));
      const minutes: number = parseInt(padded.substr(2, 4));
      const tempMomentValue: string = moment
        .utc(moment().set({hour: hours, minute: minutes}))
        .local()
        .toString();

      initialObject = {
        duration: tempMomentValue,
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
        tags: props.data?.tagsObject || [],
      };
    }
    if (props.data && props.data?.tags?.length > 0) {
      setTags();
    } else {
      initialTagList.forEach((item) => (item.isVisible = true));
      setTagsList(initialTagList);
    }
    setData(initialObject);
  }, [props.data, initialTagList]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    initialTagList.forEach((item) => (item.isVisible = true));
    setTagsList(initialTagList);
    tagsBoxRef?.current?.clearSelectedTags();
    setData({
      date: moment().toString(),
      time: moment().toString(),
      duration: momentValue,
      tags: [],
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const momentDate = moment(data.duration);
      const momentDateInt = momentDate.hour() * 100 + momentDate.minute();
      const selectedTagsTemp = tagsBoxRef?.current
        ?.getSelectedTags()
        .map((tag: ICasualTag) => tag.id);
      const sleepLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        duration: momentDateInt,
        tags: selectedTagsTemp,
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addSleepLog(sleepLog));
      } else {
        res = await dispatch(editSleepLogg(sleepLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img className={commonClasses.imageMargin} src={SleepIcon} alt={'sleep-icon'} />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.sleepWidget.formTitleEdit')
              : t('LogsPage.sleepWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.sleepWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.sleepWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <Typography variant={'body1'} className={classes.durationLabel}>
        {t('LogsPage.sleepWidget.durationLabel')}
      </Typography>
      <div className={commonClasses.centerContainer}>
        <TimeFieldPicker
          name={'duration'}
          className={classes.timeFieldPicker}
          onBlur={() => validateFormField('duration')}
          value={data.duration || ''}
          onChange={(value: string) => changeSpecificDataType(value, 'duration')}
          error={hasError(errors, 'duration')}
          helperText={getError(errors, 'duration')}
        />
      </div>
      <div className={classes.tagsContainer}>
        <Typography variant="caption">{t('LogsPage.sleepWidget.tagsLabel')}</Typography>
        <TagsWithBox
          id={'tags-with-box-id'}
          name={'tags-with-box-id'}
          originalTagsList={tagsList}
          ref={tagsBoxRef}
        />
      </div>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-sleep-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-sleep-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.sleepWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default SleepForm;
