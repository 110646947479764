import React, {useState} from 'react';
import Carousel from 'react-multi-carousel';
import ProgressCard from './ProgressCard';
import {PaperWrapper} from '../paper-wrapper/PaperWrapper';
import {ReactComponent as LeftArrow} from '../../../assets/icons/rest/left-arrow.svg';
import {ReactComponent as RightArrow} from '../../../assets/icons/rest/right-arrow.svg';
import {ProgressType} from '../../../utils/enums';

import 'react-multi-carousel/lib/styles.css';
import useStyles from './progress-widget.styles';

interface Props {
  isMobile: boolean;
}

interface PropsButtons {
  next?: () => void;
  previous?: () => void;
}

const ProgressWidget: React.FC<Props> = ({isMobile}) => {
  const classes = useStyles({
    isMobile,
  });
  const progress = [
    {value: 0, type: ProgressType.BODY_WEIGHT},
    // {value: 1, type: ProgressType.FOOD},
    {value: 1, type: ProgressType.WATER},
    {value: 2, type: ProgressType.SLEEP},
    {value: 3, type: ProgressType.ACTIVITY},
    {value: 4, type: ProgressType.STEPS},
    {value: 5, type: ProgressType.BOWEL_MOVEMENT},
    {value: 6, type: ProgressType.MENTAL_HEALTH},
    {value: 7, type: ProgressType.BLOOD_GLUCOSE},
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const ButtonGroup: React.FC<PropsButtons> = ({next, previous}) => {
    return (
      <div className={`${classes.buttonsGroup} carousel-button-group`}>
        <div className={currentSlide === 0 ? classes.hiddenArrow : classes.buttonInGroup}>
          <LeftArrow onClick={() => previous && previous()} />
        </div>
        <div className={currentSlide === 7 ? classes.hiddenArrow : classes.buttonInGroup}>
          <RightArrow onClick={() => next && next()} />
        </div>
      </div>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: {max: 5000, min: 300},
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <PaperWrapper className={classes.progressContainer}>
      <Carousel
        swipeable={false}
        draggable={false}
        arrows={false}
        showDots={true}
        responsive={responsive}
        infinite={false}
        autoPlay={false}
        keyBoardControl={true}
        containerClass={`${classes.carouselContainer} carousel-container`}
        renderDotsOutside={true}
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside={!isMobile}
        shouldResetAutoplay={false}
        afterChange={(_previousSlide, {currentSlide}) => {
          setCurrentSlide(currentSlide);
        }}
        dotListClass={classes.customDotList}
      >
        {progress.map((type, i) => (
          <ProgressCard
            key={i}
            isMobile={isMobile}
            selectedType={currentSlide}
            type={type}
            user={type}
          />
        ))}
      </Carousel>
    </PaperWrapper>
  );
};

export default ProgressWidget;
