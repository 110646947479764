import * as React from 'react';
import classNames from 'classnames';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery, Typography} from '@material-ui/core';
import {NavBarSelectedType} from '../../../utils/enums';
import {MOBILE_SIZE, TABLET_SMALL_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './app-nav-bar-item.styles';

interface Props {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconClassName?: string;
  elementType: NavBarSelectedType;
  selectedNavBarItem: NavBarSelectedType;
  onClick: (value: NavBarSelectedType) => void;
  text?: string;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const AppNavBarItem: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(`(max-width:${TABLET_SMALL_SIZE}px)`, {noSsr: true});
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobile: isMobileOrTablet.isMobile,
    smallScreens:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  const {Icon, iconClassName, text, elementType, selectedNavBarItem} = props;

  return (
    <div
      id={`app-bar-item-id-${text}`}
      className={
        text
          ? classNames(classes.toolbarButton, classes.toolbarButtonWithHover)
          : classNames(classes.toolbarButton, classes.toolbarButtonWithHover)
      }
      onClick={() => props.onClick(elementType)}
      onKeyDown={() => {}}
      role="menuitem"
      tabIndex={0}
    >
      <Icon
        className={`${
          selectedNavBarItem === elementType ? classes.navbarIconsActive : classes.navbarIcons
        } ${iconClassName}`}
      />

      <div
        className={
          selectedNavBarItem === elementType ? 'toolbar-button-text' : 'toolbar-button-inactive'
        }
      >
        {text && <Typography>{text}</Typography>}
      </div>
    </div>
  );
};
