import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  textContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    width: 150,
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '200px',
    justifyContent: 'flex-start',
    '& > div': {
      marginTop: (props) => (props.isMobile ? 8 : 0),
      marginBottom: (props) => (props.isMobile ? 0 : 8),
    },
  },
}));

export default useStyles;
