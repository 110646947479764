import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  progressContainer: {
    position: 'relative',
    marginBottom: 32,
  },
  line: {
    height: '2px',
    borderBottom: `2px solid #E0DDDC`,
    marginBottom: 16,
  },
  mainCenterContent: {
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      padding: 0,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none !important',
      marginTop: 24,
    },
    '& .MuiAccordionSummary-root ': {
      padding: 0,
    },
  },
  progressItem: {
    marginBottom: 24,
  },
  carouselContainer: {
    position: 'relative',
  },
  buttonsGroup: {
    display: (props) => (props.isMobile ? 'none' : 'flex'),
    position: 'absolute',
    bottom: '245px',
    left: '16px',
    right: '16px',

    '& svg path ': {
      stroke: theme.palette.primary.light,
    },
  },
  buttonInGroup: {
    width: 32,
    height: 32,
    background: theme.palette.background.paper,
    boxShadow: '-1px 3px 11px rgb(0 0 0 / 14%)',
    borderRadius: '4px',
    padding: '7px 10px',
    '&:nth-child(2)': {
      marginLeft: 'auto',
    },
    border: `1px solid ${theme.palette.primary.light}`,
  },
  customDotList: {
    top: 'calc( 100% + 10px)',
    '& .react-multi-carousel-dot button': {
      border: 'none',
      background: theme.palette.blacksAndGrays.silverSand,
      width: 8,
      height: 8,
      margin: 6,
    },
    '& .react-multi-carousel-dot--active button': {
      border: 'none',
      background: theme.palette.blacksAndGrays.dimGray,
    },
  },
  hiddenArrow: {
    display: 'none',
  },
}));

export default useStyles;
