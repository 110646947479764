import * as React from 'react';
import {FocusEventHandler} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {isMobile} from 'react-device-detect';
import {FormHelperText, Typography, useMediaQuery} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './date-picker-styles';

interface Props {
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  name: string;
  className?: string;
  containerClass?: string;
  onChange: any;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
  format?: string;
  required?: boolean;
  value: string | null;
  placeholder?: string;
  maxDate?: string | moment.Moment;
  minDate?: Date;
  readOnly?: boolean;
  marginRight?: string | number;
  labelFunc?: () => any;
  style?:any;
}

export const DatePicker: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, { noSsr: true })
  const isMobileOrTablet = React.useMemo(() => {
    return  isMobile || isMobileSize
  }, [window.innerWidth])
  const classes = useStyles({
    isMobileOrTablet
  });
  const {label, format, helperText, required, value, className, containerClass, marginRight, maxDate, ...rest} =
    props;

  return (
    <div className={marginRight ? classNames(classes.wrappers, className) :  classNames(classes.wrapper, className)} >
      <div className={classes.labelContainer}>
        {label && (
          <Typography
            variant="caption"
            component="div"
            className={props.disabled ? classes.labelDisabled : classes.labelText}
          >
            {label}
          </Typography>
        )}

        {required && (
          <Typography variant="caption" component="div" className={classes.requiredText}>
            *
          </Typography>
        )}
      </div>

      {/*variant="inline"*/}
      <div className={classNames(containerClass, classes.datePickerCont)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="outlined"
            format={format}
            margin="normal"
            id="date-picker-inline"
            value={value}
            required={true}
            minDateMessage={false}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            maxDate={maxDate && maxDate}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      </div>
      <FormHelperText error={rest.error} className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </div>
  );
};
