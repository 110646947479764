import * as React from 'react';
import {isMobile} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './level-flag.styles';
import classNames from 'classnames';
import {ILevelCategoryDto, StylingLevelCategoryType} from '../../../dtos/ILevelCategoryDto';

interface Props {
  className?: string;
  title: string;
  levelCategory: ILevelCategoryDto;
  top?: number;
  right?: number;
}

export const LevelFlag: React.FC<Props> = ({className, levelCategory, title, top, right}) => {
  const isTabletBigSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const smallScreens = React.useMemo(() => {
    return isMobile || isTabletBigSize;
  }, [window.innerWidth]);
  const classes = useStyles({smallScreens});

  // const getFlagStyle = (title: string) => {
  //   const levelNumber = Number(title?.split(' ')[1]) || 0;
  //   if (levelNumber === 3) return classes.levelThreeStyle;
  //   if (levelNumber === 2) return classes.levelTwoStyle;
  //   if (levelNumber === 1) return classes.levelOneStyle;
  //   if (levelNumber === 0) return classes.levelDefaultStyle;
  //   return classes.levelDefaultStyle;
  // };

  const getFlagStyle = () => {
    if (!levelCategory) {
      return '';
    }

    const idleStyle = levelCategory.styling.find(
      (oneStyle) => oneStyle.property === StylingLevelCategoryType.BACKGROUND_IDLE,
    );
    if (!idleStyle) {
      return '';
    }

    return idleStyle.value;
  };

  return levelCategory?.name ? (
    <div
      className={`${classes.levelFlag} ${classNames(className)}}`}
      style={{
        top,
        right,
        backgroundColor: title === 'Level 1' ? '#DDD6D6' : title === 'Level 2' ? '#ffffff' : `${getFlagStyle()}`,
        color: title === 'Level 1' ? '#000000' : '#140802',
      }}
    >
      {title}
    </div>
  ) : (
    <></>
  );
};
