export const processLinkInText = (details: string): string => {
  const regexConst = new RegExp(
    '\\[{1}[a-zA-Z\\-\\.0-9 ]+\\]{1}\\({1}[a-zA-Z:\\/\\._0-9\\-\\?\\_\\=%]+\\){1}',
    'g',
  );

  let matched: string | string[] | null;
  while ((matched = regexConst.exec(details)) != null) {
    matched?.forEach((item) => {
      const parts = item.split(']');
      const textLink = parts[0].substr(1, parts[0]?.length);
      const hrefLink = parts[1].substr(1, parts[1]?.length - 2);
      details = details.replace(item, `<a href="${hrefLink}" target="_blank">${textLink}</a>`);
    });
  }

  return details;
};

export const processNewLines = (details: string): string => {
  return details.replace('\n', `<br />`);
};

export const findValueForKeyInString = (stringToProcess: string, key: string): string => {
  const regex = new RegExp(`${key}=([0-9a-zA-Z]+)`);
  const match = stringToProcess.match(regex);

  if (match && match?.length >= 2) {
    return match[1];
  }

  return '';
};

export const parseKeyValuePairsUrl = (hashObject: string): any => {
  hashObject = hashObject.replace('#', '');
  const hashArray = hashObject.split('&');

  return hashArray.reduce((result: any, stringPair) => {
    // eslint-disable-next-line prefer-const
    let [key, value] = stringPair.split('=');

    if (key === 'state') {
      // value = value.replaceAll('%3d', '=');
      value = unescape(value);
    }

    result[key] = value;
    return result;
  }, {});
};
