import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {Box, Button, Typography, useMediaQuery} from '@material-ui/core';
import Notes from './Notes';
import Loader from '../loader/Loader';
import {ContentDialog} from '../dialog/ContentDialog';
import {loadNotesPagination} from '../../../store/actions/homepage/homepage-action';
import {selectMember, selectPaginationNotes} from '../../../store/selectors/homepage.selectors';
import {INotesDto} from '../../../dtos/INotesDto';
import {NOTES_PAGE_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './notes-wrapper.styles';

interface IPageParams {
  pageNumber: number;
  pageSize: number;
}

const NotesWrapper: React.FC = () => {
  const isSmallSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const smallScreens = React.useMemo(() => {
    return isMobile || isSmallSize;
  }, [window.innerWidth]);
  const dispatch = useDispatch();
  const selectedMember = useSelector(selectMember);
  const loadedNotesPagination = useSelector(selectPaginationNotes);
  const [notes, setNotes] = useState<INotesDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const classes = useStyles({
    smallScreens,
  });
  const [t] = useTranslation();
  const [pageParams, setPageParams] = useState<IPageParams>({
    pageNumber: 0,
    pageSize: NOTES_PAGE_SIZE,
  });

  const getNotes = async () => {
    setLoading(true);
    try {
      await dispatch(
        loadNotesPagination(selectedMember?.id, pageParams.pageNumber, pageParams.pageSize),
      );
    } finally {
      setLoading(false);
    }
  };

  const getStartNotes = async () => {
    setLoading(true);
    try {
      await dispatch(loadNotesPagination(selectedMember?.id, 0, NOTES_PAGE_SIZE));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageParams.pageNumber !== 0) getNotes();
  }, [pageParams.pageNumber]);

  useEffect(() => {
    setPageParams({pageNumber: 0, pageSize: NOTES_PAGE_SIZE});
    setNotes([]);
    getStartNotes();
  }, [selectedMember]);

  useEffect(() => {
    if (loadedNotesPagination.pageNumber > 0)
      setNotes(notes.concat(loadedNotesPagination?.userNotesPaginatedList));
    else setNotes(loadedNotesPagination.userNotesPaginatedList);
  }, [loadedNotesPagination]);

  const loadMore = () => {
    setPageParams((prevState) => ({
      ...prevState,
      pageNumber: prevState.pageNumber + 1,
    }));
  };

  const handleHeader = () => {
    if (smallScreens) {
      return (
        <div className={classes.titleContainer}>
          <Box mb={3}>
            <Typography variant="h6">{t('Homepage.notes.coach')}</Typography>
          </Box>
          <Button
            id={'cancel-button-activity-form'}
            variant={'outlined'}
            className={classes.outlinedBtn}
            onClick={() => setModal(true)}
          >
            {t('Homepage.notes.addNote')}
          </Button>
        </div>
      );
    }

    return <Notes newNote={true} smallScreens={smallScreens} />;
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {handleHeader()}
          {smallScreens && <div className={classes.line} />}
          {notes?.length > 0 &&
            notes?.map((note: INotesDto) => (
              <div className={classes.noteContainer} key={note.id}>
                <Notes note={note} newNote={false} smallScreens={smallScreens} />
                {smallScreens && <div className={classes.line} />}
              </div>
            ))}
          {notes && notes?.length > 0 && notes?.length < loadedNotesPagination?.totalCount && (
            <Button
              variant="text"
              id="load-more-notes"
              onClick={loadMore}
              className={classes.loadMore}
            >
              {t('Homepage.sideMenuBar.loadMore')}
            </Button>
          )}
          {modal && (
            <ContentDialog
              handleDismiss={() => setModal(false)}
              dialogState={modal}
              content={
                <Notes
                  newNote={true}
                  smallScreens={smallScreens}
                  handleDismiss={() => setModal(false)}
                />
              }
              className={classes.dialogNewNote}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NotesWrapper;
