/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment';
import {daysBetween, isValidDateBirthDatePicker} from '../utils/date-time-utils';

export const isRequired = (value: string): boolean => {
  return !(!value || value === '');
};
export const isRequiredDuration = (value: string): boolean => {
  return !(!value || value === '' || !moment(value).isValid());
};
export const countPlaces = (num: number) => {
  const b = String(num).split('.');
  return b[1] ? b[1]?.length : 0;
};
export const isGreater = (value1: any, value2: any) => {
  const int1: number = parseFloat(value1);
  const int2: number = parseFloat(value2);

  return int1 > int2;
};
export const isIntNumber = (value: any, minValue: any, maxValue: any): boolean => {
  const re = new RegExp('^([0-9]+)$');
  if (!re.test(value)) {
    return false;
  }
  const integerNumber: number = parseInt(value);
  const isIntNumber: boolean = !isNaN(integerNumber) && isFinite(value);
  if (!isIntNumber) {
    return false;
  }
  if (minValue || minValue === 0) {
    if (integerNumber < minValue) {
      return false;
    }
  }

  if (maxValue || maxValue === 0) {
    if (integerNumber >= maxValue) {
      return false;
    }
  }
  return true;
};
export const isDecimalNumber = (
  value: any,
  minValue: any,
  maxValue: any,
  numberOfDecimals: any,
  allowedEmptyString?: boolean,
): boolean => {
  if (allowedEmptyString && value === '') return true;

  if (typeof value === 'string' && value.indexOf(' ') >= 0) return false;

  const regExp = /^0[0-9].*$/;
  if (regExp.test(value)) return false;

  const decimalNumber: number = parseFloat(value);
  const isDecimalNumber: boolean = !isNaN(decimalNumber) && isFinite(value);
  if (!isDecimalNumber) {
    return false;
  }

  if (minValue || minValue === 0) {
    if (decimalNumber < minValue) {
      return false;
    }
  }

  if (maxValue || maxValue === 0) {
    if (decimalNumber > maxValue) {
      return false;
    }
  }

  if (numberOfDecimals) {
    if (countPlaces(decimalNumber) > parseInt(numberOfDecimals)) {
      return false;
    }
  }

  return true;
};

export const isDate = (value: any): boolean => {
  // return !!Date.parse(value) && isDateSameOrBeforeCurrentDateTime(value);
  return isValidDateBirthDatePicker(value);
};

export const compareDates = (date1: Date, date2: Date, skip?: boolean): boolean => {
  if (skip) {
    return true;
  }

  if (date1 === null || date2 === null) return false;
  if (
    daysBetween(new Date('0001-01-01'), new Date(date1)) <= 0 ||
    daysBetween(new Date('0001-01-01'), new Date(date2)) <= 0
  )
    return true;
  if (
    (typeof date1 === 'string'
      ? new Date(date1).setHours(0, 0, 0, 0)
      : date1.setHours(0, 0, 0, 0)) -
      (typeof date2 === 'string'
        ? new Date(date2).setHours(0, 0, 0, 0)
        : date2.setHours(0, 0, 0, 0)) <=
    0
  ) {
    return false;
  }

  return true;
};

export const isEmail = (value: any): boolean => {
  const re = new RegExp(
    "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
  );

  return re.test(value);
};

export const isPhoneNumber = (value: any): boolean => {
  if (value.charAt(0) === '0' || value.charAt(0) === '1') {
    return false;
  }

  const re = new RegExp('[0-9]{3}-[0-9]{3}-[0-9]{4}');

  return re.test(value);
};

export const isStrongPassword = (value: any) => {
  // 8 letter password, with at least a symbol, upper and lower case letters and a number
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  return re.test(value);
};
