import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    flexDirection: 'row',
    padding: 8,
    color: theme.palette.primary.light,
  },
  connectionItemText: {
    marginLeft: 12,
    alignItems: 'center',
    
    
  },
  connectionItemIcon: {
    width: 38,
    height: 38,
    borderRadius: '50%',
  },
}));
export default useStyles;
