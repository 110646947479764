/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useRef} from 'react';
import useStyles from './Eligibility.styles';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';

const EligibilityPage: React.FC = () => {
    const classes = useStyles();

    const containerRef = useRef(null);

  useEffect(() => {
    const myLandbotFrame = new LandbotFrameWidget({
      index: 'https://landbot.pro/v3/H-1442780-7O98V1KHJBXGSF5M/index.html',
      container: containerRef.current,
    });
    return () => myLandbotFrame.destroy();
  }, []);

    return (
        <div className={classes.posterContainer}>
            <div>
                <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
            </div>
            <div id="my-landbot-new" style={{width: '100%', height: '100vh'}} ref={containerRef}></div>
        </div>
    )
}

EligibilityPage.displayName = 'Eligibility Page';
export default EligibilityPage;
