import {IResponsiveSize} from '../models/IResponsiveSize';

export const handlePadding = (isMobileOrTablet: IResponsiveSize): string => {
  if (isMobileOrTablet.isMobile) {
    return '0 16px';
  }

  if (isMobileOrTablet.isTabletSmall) {
    return '0 32px';
  }

  if (isMobileOrTablet.isTabletBig) {
    return '0 92px';
  }

  return '0px';
};

export const handleMargin = (isMobileOrTablet: IResponsiveSize, desktop: string): string => {
  if (isMobileOrTablet.isMobile) {
    return '0 16px';
  }

  if (isMobileOrTablet.isTabletSmall) {
    return '0 32px';
  }

  if (isMobileOrTablet.isTabletBig) {
    return '0 auto';
  }

  return desktop;
};
