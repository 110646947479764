import * as React from 'react';
import {Card, CardContent, CardMedia, Typography} from '@material-ui/core';
// import {LevelFlag} from '../level-flag/LevelFlag';
// import StarIcon from '../../../assets/icons/rest/star.svg';
// import PlaceholderImage from '../../../assets/icons/placeholders/placeholder-cooking.svg';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import lockClosedIcon from '../../../assets/images/lock-closed.svg';
import PlaceholderWorkout from '../../../assets/images/workoutsimage.png';

import useStyles from './fitness-card.styles';
import { useDispatch, useSelector } from 'react-redux';
import { getKemTaiUserWorkoutsList } from '../../../store/actions/kemtai/kemtai-action';
import Loader from '../loader/Loader';

interface Props {
  recipe: any;
  onClick: (slug: string) => void;
  containerStyle?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

export const FitnessCard: React.FC<Props> = ({
  recipe,
  onClick,
  containerStyle,
  isMobileOrTablet,
  smallScreens,
}) => {
  const [isLoader, setIsLoader] = React.useState(false);
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const [slug, setSlug] = React.useState('');

  const dispatch = useDispatch();
  const userData: any = useSelector((state: any) => state.users.currentUser)

  React.useEffect(() => {
    setIsLoader(true);
    if(recipe?.id){
      dispatch(getKemTaiUserWorkoutsList(userData?.id, recipe?.id)).then((res: any) => {
        if(res){
          setSlug(res?.workoutGroupSlug);
          setIsLoader(false);
        }
    })
    }
  }, [recipe?.id])

  const myShopfiyRedirect = (buyLink: any) => {
    window.open(
      buyLink,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  return (
    <Card
      className={`${classes.card} ${containerStyle}`}
      onClick={(recipe?.tag && !recipe?.userId) ? () => myShopfiyRedirect(recipe?.buyLink) : () => slug ? onClick(slug) : {}}
      id={recipe.id}
    >
      {isLoader && <Loader className={`${classes.fitnessLoader} ${classes.loaderAb}`} /> }
      {(recipe?.tag && !recipe?.userId) && <div className={classes.lockCard} style={{cursor: 'pointer'}}><img src={lockClosedIcon} alt='' /></div>}
      <CardMedia
        component="img"
        height={isMobileOrTablet.isMobile ? '72' : '148'}
        image={recipe?.thumbNailImage || PlaceholderWorkout}
        alt="recipe-image"
      />
      {/* <LevelFlag
        title={recipe?.level_category?.name}
        levelCategory={recipe?.level_category}
        top={8}
        right={8}
      /> */}
      <div className={classes.infoContainer}>
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {recipe?.name}
          </Typography>
        </CardContent>
        <div className={classes.ratingContainer}>
          <div className={classes.rating}>
            {/*<img src={StarIcon} alt={'rating-icon'} width={16} />*/}
            {/*<div className={classes.ratingValue}>{recipe.rating || 0}</div>*/}
          </div>
          <div className={classes.showTime}>
            <div className={classes.ratingValue}>
              {recipe?.days} {(recipe?.days === 1 || recipe?.days === '1') ? 'Workout' : 'Workouts'}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
