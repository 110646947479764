/* eslint-disable react/jsx-no-target-blank */
import {Typography} from '@material-ui/core';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {PRIVACY_POLICY, TERMS_AND_CONDITIONS} from '../../../utils/constants';
import useStyles from './footer.styles';
interface IFooterProps {
  smallScreens?: any;
}

export const Footer: React.FC<IFooterProps>  = ({smallScreens}) => {
  const styles = useStyles();
  const [t] = useTranslation();

  return (
    <div style={{bottom: smallScreens ? 40 : 0}} className={styles.footer}>
      <div className={styles.container}>
        <Typography component="div" variant="body1">
          <a href={TERMS_AND_CONDITIONS} className={styles.link} target="_blank">
            {t('ProfilePage.footer.terms')}
          </a>
        </Typography>
        <Typography component="div" variant="body1">
          <a href={PRIVACY_POLICY} className={styles.link} target="_blank">
            {t('ProfilePage.footer.privacy')}
          </a>
        </Typography>
      </div>
    </div>
  );
};
