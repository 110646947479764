import * as React from 'react';
import useStyles from './feedback-popup2.styles';
import easy from '../../../../assets/images/easy.png';
import tooeasy from '../../../../assets/images/tooeasy.png';
import good from '../../../../assets/images/good.png';
import toohard from '../../../../assets/images/toohard.png';
import toohard2 from '../../../../assets/images/toohard2.png';
import air from '../../../../assets/images/air.png';
import chat_bubble from '../../../../assets/images/chat_bubble.png';
import water_drop from '../../../../assets/images/water_drop.png';
import pain from '../../../../assets/images/pain.png';
import {Slider} from '@material-ui/core';

export default function TransitionsModal2({selectedStep, setSelectedStep, sliderDefaultValue}) {
  const classes = useStyles();

  // const [activeTab, setActiveTab] = useState(selectedStep);
  // const [activeSlider, setActiveSlider] = useState(selectedStep);

  const SliderClick = (event: Event, newValue: number | number[]) => {
    setSelectedStep(newValue as number)
  };

  return (
    <>
      <div className={classes.StepOneFrom}>
        {/* <p className="ptag">This will optimize your next set.</p> */}

        <div className="emojisec">
          {selectedStep == 1 ? (
            <div>
              <img src={tooeasy} alt="" />
              <p>Too Easy</p>
            </div>
          ) : null}
          {selectedStep == 2 ? (
            <div>
              <img src={easy} alt="" />
              <p>Easy</p>
            </div>
          ) : null}
          {selectedStep == 3 ? (
            <div>
              <img src={good} alt="" />
              <p>Challenging but good</p>
            </div>
          ) : null}
          {selectedStep == 4 ? (
            <div>
              <img src={toohard} alt="" />
              <p>Too Hard</p>
            </div>
          ) : null}
          {selectedStep == 5 ? (
            <div>
              <img src={toohard2} alt="" />
              <p>Way Too Hard</p>
            </div>
          ) : null}
        </div>
        <div className="stepper2">
          <Slider
            onChange={SliderClick}
            aria-label="Temperature"
            defaultValue={sliderDefaultValue}
            step={1}
            marks
            min={1}
            max={5}
          />
        </div>

        {selectedStep == 1 ? (
          <div className="popupContentArea">
            <div className="popupContentDiv">
              <img src={air} alt="" />
              <h6>
                Breathing<br></br> regularly
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={chat_bubble} alt="" />
              <h6>
                Easy to talk and<br></br> sing
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={water_drop} alt="" />
              <h6>
                Warmed up, but<br></br> not too sweaty
              </h6>
            </div>
          </div>
        ) : null}

        {selectedStep == 2 ? (
          <div className="popupContentArea">
            <div className="popupContentDiv">
              <img src={air} alt="" />
              <h6>Breathing rapidly</h6>
            </div>
            <div className="popupContentDiv">
              <img src={chat_bubble} alt="" />
              <h6>
                Able to talk and <br></br> sing
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={water_drop} alt="" />
              <h6>Sweating lightly</h6>
            </div>
          </div>
        ) : null}

        {selectedStep == 3 ? (
          <div className="popupContentArea">
            <div className="popupContentDiv">
              <img src={air} alt="" />
              <h6>
                Breathing <br></br> rapidly
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={chat_bubble} alt="" />
              <h6>
                Hard to talk,<br></br> can’t sing
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={water_drop} alt="" />
              <h6>Sweating</h6>
            </div>
          </div>
        ) : null}
        {selectedStep == 4 ? (
          <div className="popupContentArea">
            <div className="popupContentDiv">
              <img src={air} alt="" />
              <h6>
                Shortness of <br></br>breath
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={chat_bubble} alt="" />
              <h6>
                Barely able to<br></br> talk
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={pain} alt="" />
              <h6>Side cramp</h6>
            </div>
          </div>
        ) : null}
        {selectedStep == 5 ? (
          <div className="popupContentArea">
            <div className="popupContentDiv">
              <img src={air} alt="" />
              <h6>Out of breath</h6>
            </div>
            <div className="popupContentDiv">
              <img src={chat_bubble} alt="" />
              <h6>
                Talking is <br></br>impossible
              </h6>
            </div>
            <div className="popupContentDiv">
              <img src={pain} alt="" />
              <h6>Chest pain</h6>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
