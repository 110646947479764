/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {makeStyles,Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((_) => ({
  container: {
    display: (props) => props.isMobile ? 'block' : 'flex',
  },
  rowContainer: {
    display: (props) => props.isMobile ? 'block' : 'flex',
    flexDirection: 'row',
    marginBottom: 24,
  },
  columnContainer: {
    display: (props) => props.isMobile ? 'block' : 'flex',
    flexDirection: 'column',
  },
  infoItem: {
    display: 'flex',
  },
  icon: {
    marginRight: '8px',
  },
  contactInfo: {
    marginLeft: (props) => props.isMobile ? 0 : '7px',
    marginBottom: (props) => props.isMobile ? '24px' : 0,
  },
}));

export default useStyles;
