import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import useStyles from './common-onboarding.styles';
import {Button} from '../button/Button';
import {OnBoardingSteps} from '../../../utils/enums';
import {useTranslation} from 'react-i18next';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  IValidationRules,
  validate,
  validateField,
} from '../../../validation/Validation';
import {ValidationType} from '../../../validation/ValidationType';
import {TextField} from '../text-field/TextField';
import {IOnBoardingUser} from '../../../models/IOnBoardingUser';
import classNames from 'classnames';

interface IPasswordOnBoardingProps {
  userData: IOnBoardingUser;
  onContinueClick: (step: OnBoardingSteps) => void;
  onUpdateUser: (user: IOnBoardingUser) => void;
}

interface IPasswordOnBoardingFormData {
  password: string;
  repeatPassword: string;
}

const validationRules: IValidationRules = {
  password: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_STRONG_PASSWORD}],
  repeatPassword: [{type: ValidationType.REQUIRED}, {type: ValidationType.IS_STRONG_PASSWORD}],
};

const PasswordOnBoarding: React.FC<IPasswordOnBoardingProps> = (props) => {
  const classes = useStyles();
  const {userData, onContinueClick, onUpdateUser} = props;
  const [t] = useTranslation();

  const [errors, setErrors] = useState<any>({});
  const [data, setData] = useState<IPasswordOnBoardingFormData>({
    password: '',
    repeatPassword: '',
  });

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);

    if (data.password !== data.repeatPassword && data.repeatPassword) {
      setErrors({
        ...errors,
        [field]: err,
        repeatPassword: [{message: t('ErrorMessages.passwordsDoNotMatch')}],
      });
    } else {
      setErrors({
        ...errors,
        [field]: err,
        repeatPassword: [],
      });
    }
  };

  const validateToSubmit = () => {
    if (data.password !== data.repeatPassword) {
      return false;
    }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    const initialObject: IPasswordOnBoardingFormData = {
      password: userData.password || '',
      repeatPassword: userData.repeatPassword || '',
    };

    setData(initialObject);
  }, [props.userData]);

  const handleSubmit = () => {
    userData.password = data.password;
    userData.repeatPassword = data.repeatPassword;
    onUpdateUser(userData);
    onContinueClick(OnBoardingSteps.UNIT_SYSTEM);
  };

  return (
    <div className={classes.container}>
      <Typography variant={'h5'}>{t('OnBoardingPage.password.setPassword')}</Typography>
      <Typography variant={'body1'} className={classes.descriptiveText}>
        {t('OnBoardingPage.password.setPasswordForAccount')}
      </Typography>

      <TextField
        name={'password'}
        type={'password'}
        className={classNames(classes.normalTextField, classes.passwordTextField)}
        containerClass={classes.textFieldContainerMargin}
        required={true}
        label={t('OnBoardingPage.password.password')}
        value={data.password}
        placeholder={t('OnBoardingPage.password.passwordPlaceholder')}
        onChange={(event) => handleChange(event)}
        error={hasError(errors, 'password')}
        helperText={getError(errors, 'password')}
        onBlur={() => validateFormField('password')}
      />

      <TextField
        name={'repeatPassword'}
        type={'password'}
        className={classNames(classes.normalTextField, classes.passwordTextField)}
        containerClass={classes.textFieldContainerMargin}
        required={true}
        label={t('OnBoardingPage.password.repeatPassword')}
        value={data.repeatPassword}
        placeholder={t('OnBoardingPage.password.passwordPlaceholder')}
        onChange={(event) => handleChange(event)}
        error={hasError(errors, 'repeatPassword')}
        helperText={getError(errors, 'repeatPassword')}
        onBlur={() => validateFormField('repeatPassword')}
      />

      <Button id="continue" onClick={handleSubmit} disabled={!validateToSubmit()}>
        {t('OnBoardingPage.continue')}
      </Button>
    </div>
  );
};

export default PasswordOnBoarding;
