import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 8,
    '& .MuiSlider-root': {
      marginTop: 12,
      color: theme.palette.blacksAndGrays.silverSand,
    },
    '& .MuiSlider-markActive': {
      backgroundColor: theme.palette.blacksAndGrays.silverSand,
    },
    '& .MuiSlider-thumb': {
      backgroundColor: theme.palette.primary.light,
      width: 16,
      height: 16,
      marginTop: -7,
    },
    '& .MuiSlider-mark': {
      width: 7,
      height: 7,
      borderRadius: 7,
      marginTop: -3,
    },
  },
  rangeBox: {
    maxWidth: 540,
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inputValueFied: {
    width: 70,
  },
  textContainerClass: {
    marginRight: '8px !important',
  },
  unit: {
    color: theme.palette.blacksAndGrays.stoneGray,
  },
}));

export default useStyles;
