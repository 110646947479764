import * as React from 'react';
import useStyles from './time-picker-styles';
import {Typography} from '@material-ui/core';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import classNames from 'classnames';

interface Props {
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  className?: string;
  onChange: any;
  onBlur: any;
  label?: string;
  required?: boolean;
  name: string;
  value: string;
}

export const TimeFieldPicker: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {label, value, required, className, ...rest} = props;
  // const [errMessage, setErrMessage] = useState('');

  return (
    <div className={classNames(classes.timeWrapper, className)}>
      <div className={classes.labelContainer}>
        {label && (
          <Typography variant="caption" component="div" className={classes.labelText}>
            {label}
          </Typography>
        )}

        {required && (
          <Typography variant="caption" component="div" className={classes.requiredText}>
            *
          </Typography>
        )}
      </div>

      <div className={classes.timeFieldPickerCont}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            ampm={false}
            inputVariant="outlined"
            value={value || ''}
            required={required}
            // helperText={errMessage}
            // onError={(error, value) => {
            //   if (error != '' && value != '') {
            //     setErrMessage(i18n.t('ErrorMessages.notValidTimePeriod'));
            //   } else if (value === '') {
            //     setErrMessage(helperText ? helperText : '');
            //   }
            //}}
            {...rest}
          />
        </MuiPickersUtilsProvider>
        {/*<FormHelperText error={rest.error} className={classes.helperText}>*/}
        {/*  {helperText}*/}
        {/*</FormHelperText>*/}
      </div>
    </div>
  );
};
