import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Typography, useMediaQuery} from '@material-ui/core';
import DataItem from './DataItem';
import MailIcon from '../../../assets/icons/quick-user-profile/mail-icon.svg';
import PhoneIcon from '../../../assets/icons/quick-user-profile/phone-icon.svg';
import {dateToString, getAge} from '../../../utils/date-time-utils';
import { MOBILE_SIZE, ONLY_DATE_FORMAT } from '../../../utils/constants';

import useStyles from './quick-info.styles';

interface Props {
  user: any;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const QuickInfo: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, { noSsr: true })
  const isMobile = React.useMemo(() => {
    return  isMobileOnly || isMobileSize
  }, [window.innerWidth])
  const classes = useStyles({
    isMobile,
  });
  const {user} = props;
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.columnContainer}>
          <div className={classes.rowContainer}>
            <DataItem
              label={t('Homepage.quickProfileUser.dateOfBirth')}
              value={user?.dateOfBirth && dateToString(user?.dateOfBirth, ONLY_DATE_FORMAT)}
            />
            <DataItem
              label={t('Homepage.quickProfileUser.age')}
              value={getAge(user?.dateOfBirth)}
            />
            <DataItem label={t('Homepage.quickProfileUser.gender')} value={user?.gender} />
          </div>
          <div className={classes.rowContainer}>
            <DataItem
              label={t('Homepage.quickProfileUser.memberSince')}
              value={dateToString(user?.created, ONLY_DATE_FORMAT)}
            />
          </div>
        </div>
        <div className={classNames(classes.columnContainer, classes.contactInfo)}>
          <div className={classes.infoItem}>
            <img src={MailIcon} alt="mail-icon" className={classes.icon} />
            <Typography variant="body2">{user?.email}</Typography>
          </div>
          <div className={classes.infoItem}>
            <img src={PhoneIcon} alt="phone-icon" className={classes.icon} />
            <Typography variant="body2">{user?.phoneNumber}</Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuickInfo;
