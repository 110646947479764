import React, {useState} from 'react';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery, Typography} from '@material-ui/core';
import {MOBILE_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './client-switch-styles';

interface IClientSwitchProps {
  className?: string;
  label1: string;
  label2: string;
  title: string;
  disabled?: boolean;
  setPrimary: (value: string) => void;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const ClientSwitch: React.FC<IClientSwitchProps> = ({
  className,
  label1,
  label2,
  title,
  setPrimary,
  disabled,
}) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTablet: isTablet || isTabletSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
  });
  const [selected, setSelected] = useState(label1);

  const changeSelectedItem = (label: string) => {
    setSelected(label);
    setPrimary(label);
  };

  const handleClick = (label: string) => {
    if (disabled) return;
    changeSelectedItem(label);
  };

  return (
    <div className={`${classes.mainContainer} ${className}`}>
      <Typography variant={'caption'} className={classes.title}>
        {title}
      </Typography>
      <div className={`${classes.switchContainer} ${className}`}>
        <div
          className={
            disabled ? classes.disabled : selected === label1 ? classes.active : classes.inactive
          }
          onClick={() => handleClick(label1)}
          role={'button'}
          onKeyDown={() => {}}
          tabIndex={0}
        >
          <Typography variant={'button'}>{label1}</Typography>
        </div>
        <div
          className={
            disabled ? classes.disabled : selected === label2 ? classes.active : classes.inactive
          }
          onClick={() => handleClick(label2)}
          role={'button'}
          onKeyDown={() => {}}
          tabIndex={0}
        >
          <Typography variant={'button'}>{label2}</Typography>
        </div>
      </div>
    </div>
  );
};
export default ClientSwitch;
