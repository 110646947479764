import request, {SERVER_URL} from '../utils/http-request';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
interface IApiService {
  customRequest: (path: string, options: ICustomRequestOptions) => void;
  get: (path: string, data: any, headers?: {[key: string]: string}) => void;
  post: (path: string, data: any, headers?: {[key: string]: string}) => void;
  del: (path: string, data: any, headers?: {[key: string]: string}) => void;
  put: (path: string, data: any, headers?: {[key: string]: string}) => void;
  exposeMethods: () => void;
}

interface ICustomRequestOptions {
  method: 'get' | 'post' | 'put' | 'delete';
  exposeResponseHeaders?: boolean;
  requestBody?: any;
  headers?: {[key: string]: string};
  cdn?: string;
}

export class ApiService implements IApiService {
  private readonly cdn = SERVER_URL;
  constructor(cdn?: string) {
    this.cdn = cdn;
  }
  customRequest = async (
    path: string,
    {exposeResponseHeaders = false, ...options},
  ): Promise<any> => {
    const response = await request({path, cdn: this.cdn, ...options});
    return exposeResponseHeaders ? response : response.data;
  };
  get = async (path: string, data?: any, headers?: {[key: string]: string}): Promise<any> => {
    const response = await request({
      path,
      method: 'get',
      requestBody: data,
      headers,
      cdn: this.cdn,
    });
    return response.data;
  };

  post = async (path: string, data: any, headers?: {[key: string]: string}): Promise<any> => {
    const response = await request({
      path,
      method: 'post',
      requestBody: data,
      headers,
      cdn: this.cdn,
    });
    return response.data;
  };

  put = async (path: string, data: any, headers?: {[key: string]: string}): Promise<any> => {
    const response = await request({
      path,
      method: 'put',
      requestBody: data,
      headers,
      cdn: this.cdn,
    });
    return response.data;
  };

  del = async (path: string, data?: any, headers?: {[key: string]: string}): Promise<any> => {
    const response = await request({
      path,
      method: 'delete',
      requestBody: data,
      headers,
      cdn: this.cdn,
    });
    return response.data;
  };

  exposeMethods = () => {
    return {
      get: this.get,
      post: this.post,
      put: this.put,
      del: this.del,
      customRequest: this.customRequest,
    };
  };
}
