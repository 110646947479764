import React, {Fragment, useEffect, useState} from 'react';
import useStyles from './schedule-call.styles';
import {Button} from '../button/Button';
import {OnBoardingScheduleType} from '../../../utils/enums';
import {useTranslation} from 'react-i18next';
import {InlineWidget} from 'react-calendly';
import {IUserDataOnBoardingLocaleStorage} from '../../../models/IUserDataOnBoardingLocaleStorage';
import {removeUserDataOnBoarding} from '../../../utils/locale-storage-service';
import {useMediaQuery} from '@material-ui/core';
import {MOBILE_SIZE} from '../../../utils/constants';
import {isMobileOnly} from 'react-device-detect';

interface IScheduleCallProps {
  userData: IUserDataOnBoardingLocaleStorage | null;
  onContinueClick: (type: OnBoardingScheduleType) => void;
}

const ScheduleCall: React.FC<IScheduleCallProps> = (props) => {
  const {userData, onContinueClick} = props;
  const [isEnabledContinueToVideo, setEnabledContinueToVideo] = useState(false);
  const [t] = useTranslation();

  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
    };
    return size;
  }, [window.innerWidth]);

  const classes = useStyles({
    isMobileOrTablet,
  });

  function isCalendlyEvent(e: any) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  function reactOnMessageCalendly(e: any) {
    if (!isCalendlyEvent(e)) {
      return;
    }

    if (e.data.event === 'calendly.event_scheduled') {
      removeUserDataOnBoarding();
      setEnabledContinueToVideo(true);
    }
  }

  useEffect(() => {
    window.removeEventListener('message', reactOnMessageCalendly);
    window.addEventListener('message', reactOnMessageCalendly);
  }, []);

  return (
    <Fragment>
      {/*// hide_landing_page_details=1 - nista */}
      {/*// hide_event_type_details=1 - podaci o dogadjaju*/}
      {/*// hide_gdpr_banner=1 - sakriva dole cookies*/}

      <InlineWidget
        url="https://calendly.com/betrgroupcalls/test-call-clone?hide_landing_page_details=1&hide_gdpr_banner=1"
        styles={{
          marginTop: '60px',
          height: '1000px',
          width: '100vw',
          borderRadius: '32px !important',
        }}
        prefill={{
          email: userData?.email || '',
          firstName: userData?.firstName || '',
          lastName: userData?.lastName || '',
          name:
            userData?.firstName && userData?.lastName
              ? userData?.firstName + ' ' + userData?.lastName
              : '',
          //guests: ['janedoe@example.com', 'johndoe@example.com'],
          customAnswers: {
            a1: userData?.phoneNumber ? '1' + userData?.phoneNumber : '',
            a2: '',
          },
          // date: new Date(Date.now() + 86400000),
        }}
      />

      {isEnabledContinueToVideo && (
        <Button
          id="continue"
          className={classes.continueButton}
          onClick={() => onContinueClick(OnBoardingScheduleType.WATCH_VIDEO)}
        >
          {t('OnBoardingSchedulePage.continue')}
        </Button>
      )}
    </Fragment>
  );
};
export default ScheduleCall;
