import * as React from 'react';
import {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {SideMenuBarItem} from './SideMenuBarItem';
import {SearchMembers} from './SearchMembers';
// import {ActiveConversations} from './ActiveConversations';
import {Connections} from './Connections';
import {UserHeaderItem} from '../user-header-item/UserHeaderItem';
// import {ReferralCard} from '../referral-card/ReferrealCard';
// import AppointmentsIcon from '../../../assets/icons/side-menu-bar/appointments-icon.svg';
import {ReactComponent as InsightsIcon} from '../../../assets/icons/side-menu-bar/insights-icon.svg';
// import AppointmentsInactiveIcon from '../../../assets/icons/side-menu-bar/appointments-inactive-icon.svg';
import {ReactComponent as ProgressIcon} from '../../../assets/icons/side-menu-bar/progress.svg';
import {ReactComponent as CoachHistoryIcon} from '../../../assets/icons/side-menu-bar/coaching-history-icon.svg';
// import ExerciseIcon from '../../../assets/icons/side-menu-bar/exercise-icon.svg';
// import MeditationIcon from '../../../assets/icons/side-menu-bar/meditation-icon.svg';
// import EventsIcon from '../../../assets/icons/side-menu-bar/events-icon.svg';
// import FavoritesIcon from '../../../assets/icons/side-menu-bar/favorites-icon.svg';
// import RecipesIcon from '../../../assets/icons/side-menu-bar/recipes-icon.svg';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {SideMenuSelectedType} from '../../../utils/enums';
import {checkIfUserIsMember} from '../../../utils/users-managment';
import {hasSuperCoachRoles} from '../../../utils/role-helper';
import {TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './side-menu-bar-styles';

interface Props {
  conversations?: any[];
  executeScrollOnElement?: any;
  setOpenSideMenuBar?: (e: boolean) => void;
}

export const SideMenuBar: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const smallScreens = React.useMemo(() => {
    return isMobile || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    smallScreens,
  });
  const [selectedSideMenuItem, setSideMenuItem] = useState(SideMenuSelectedType.SIDEMENU_NONE);
  const [t] = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  // const allConversations = useSelector(selectConversations);
  // const dispatch = useDispatch();

  const selectSideMenuItem = (selectedType: SideMenuSelectedType) => {
    if (selectedType === selectedSideMenuItem) {
      return;
    }
    setSideMenuItem(selectedType);
  };

  // useEffect(() => {
  //   if (
  //     currentUser.roles.length === 1 &&
  //     currentUser.roles.find((role) => role === 'member' || role === 'Member')
  //   ) {
  //
  //     if (allConversations.length > 0) {
  //       dispatch(homepageActions.selectConversation(allConversations[0]));
  //     }
  //   }
  // }, [currentUser]);

  return (
    <Fragment>
      <div className={classes.sideMenuBarContainer} role="menubar">
        {!checkIfUserIsMember(currentUser) && (
          <React.Fragment>
            <UserHeaderItem />
            <div className={classes.header}>
              {hasSuperCoachRoles(currentUser) && !smallScreens && (
                <SearchMembers
                  onClick={(elementType: SideMenuSelectedType) => selectSideMenuItem(elementType)}
                  selectedSideMenuItem={selectedSideMenuItem}
                />
              )}
              {/* {hasSuperCoachRoles(currentUser) && !smallScreens && (
                <ActiveConversations
                  onClick={(elementType: SideMenuSelectedType) => selectSideMenuItem(elementType)}
                  selectedSideMenuItem={selectedSideMenuItem}
                />
              )} */}
              <Connections
                onClick={(elementType: SideMenuSelectedType) => selectSideMenuItem(elementType)}
                selectedSideMenuItem={selectedSideMenuItem}
              />
            </div>
          </React.Fragment>
        )}
        {/*<SideMenuBarItem*/}
        {/*  icon={EventsIcon}*/}
        {/*  inactiveIcon={EventsInactiveIcon}*/}
        {/*  text={t('Homepage.sideMenuBar.events')}*/}
        {/*  onClick={(elementType: SideMenuSelectedType) => selectSideMenuItem(elementType)}*/}
        {/*  elementType={SideMenuSelectedType.SIDEMENU_EVENTS}*/}
        {/*  selectedSideMenuItem={selectedSideMenuItem}*/}
        {/*/>*/}

        <SideMenuBarItem
          Icon={ProgressIcon}
          text={t('Homepage.sideMenuBar.progress')}
          onClick={(elementType: any) => {
            selectSideMenuItem(elementType);
            props.executeScrollOnElement &&
              props.executeScrollOnElement(SideMenuSelectedType.SIDEMENU_PROGRESS);
          }}
          elementType={SideMenuSelectedType.SIDEMENU_PROGRESS}
          selectedSideMenuItem={selectedSideMenuItem}
        />
        <SideMenuBarItem
          Icon={InsightsIcon}
          text={t('Homepage.sideMenuBar.insights')}
          onClick={(elementType: any) => {
            selectSideMenuItem(elementType);
            props.executeScrollOnElement &&
              props.executeScrollOnElement(SideMenuSelectedType.SIDEMENU_INSIGHTS);
          }}
          elementType={SideMenuSelectedType.SIDEMENU_INSIGHTS}
          selectedSideMenuItem={selectedSideMenuItem}
        />
        {currentUser.hasCoaching && (
          <SideMenuBarItem
            Icon={CoachHistoryIcon}
            text={t('Homepage.sideMenuBar.coachingHistory')}
            onClick={(elementType: any) => {
              selectSideMenuItem(elementType);
              props.executeScrollOnElement &&
                props.executeScrollOnElement(SideMenuSelectedType.SIDEMENU_COACHING_HISTORY);
            }}
            elementType={SideMenuSelectedType.SIDEMENU_COACHING_HISTORY}
            selectedSideMenuItem={selectedSideMenuItem}
          />
        )}
        {/*{!isMobileSize && <ReferralCard />}*/}
        {/*<SideMenuBarItem*/}
        {/*  Icon={AppointmentsIcon}*/}
        {/*  text={t('Homepage.sideMenuBar.appointments')}*/}
        {/*  onClick={(elementType: any) => {*/}
        {/*    selectSideMenuItem(elementType);*/}
        {/*    props.executeScrollOnElement &&*/}
        {/*      props.executeScrollOnElement(SideMenuSelectedType.SIDEMENU_APPOINTMENTS);*/}
        {/*  }}*/}
        {/*  elementType={SideMenuSelectedType.SIDEMENU_APPOINTMENTS}*/}
        {/*  selectedSideMenuItem={selectedSideMenuItem}*/}
        {/*/>*/}

        {/*<SideMenuBarItem*/}
        {/*  Icon={FavoritesIcon}*/}
        {/*  text={t('Homepage.sideMenuBar.favorites')}*/}
        {/*  onClick={(elementType: SideMenuSelectedType) => selectSideMenuItem(elementType)}*/}
        {/*  elementType={SideMenuSelectedType.SIDEMENU_FAVORITES}*/}
        {/*  selectedSideMenuItem={selectedSideMenuItem}*/}
        {/*/>*/}
        {/*<SideMenuBarItem*/}
        {/*  Icon={RecipesIcon}*/}
        {/*  text={t('Homepage.sideMenuBar.recipes')}*/}
        {/*  onClick={(elementType: SideMenuSelectedType) => selectSideMenuItem(elementType)}*/}
        {/*  elementType={SideMenuSelectedType.SIDEMENU_RECIPES}*/}
        {/*  selectedSideMenuItem={selectedSideMenuItem}*/}
        {/*/>*/}
        {/*<SideMenuBarItem*/}
        {/*  Icon={ExerciseIcon}*/}
        {/*  text={t('Homepage.sideMenuBar.exercise')}*/}
        {/*  onClick={(elementType: any) => {*/}
        {/*    selectSideMenuItem(elementType);*/}
        {/*    props.executeScrollOnElement &&*/}
        {/*      props.executeScrollOnElement(SideMenuSelectedType.SIDEMENU_EXERCISE);*/}
        {/*  }}*/}
        {/*  elementType={SideMenuSelectedType.SIDEMENU_EXERCISE}*/}
        {/*  selectedSideMenuItem={selectedSideMenuItem}*/}
        {/*/>*/}
        {/*<SideMenuBarItem*/}
        {/*  Icon={MeditationIcon}*/}
        {/*  text={t('Homepage.sideMenuBar.meditation')}*/}
        {/*  onClick={(elementType: any) => {*/}
        {/*    selectSideMenuItem(elementType);*/}
        {/*    props.executeScrollOnElement &&*/}
        {/*      props.executeScrollOnElement(SideMenuSelectedType.SIDEMENU_MEDITATION);*/}
        {/*  }}*/}
        {/*  elementType={SideMenuSelectedType.SIDEMENU_MEDITATION}*/}
        {/*  selectedSideMenuItem={selectedSideMenuItem}*/}
        {/*/>*/}
      </div>
    </Fragment>
  );
};
