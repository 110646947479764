import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Typography, Box} from '@material-ui/core';
import DataItem from '../quick-user-profile/DataItem';
import {Button} from '../button/Button';
import {TextField} from '../text-field/TextField';
import {DatePicker} from '../pickers/DatePicker';
import {TimePicker} from '../pickers/TimePicker';
import i18n from '../../../translation/i18n';
import {DATE_PICKER_DATE_FORMAT} from '../../../utils/constants';
import Warning from '../../../assets/icons/quick-user-profile/warning.svg';
import {selectInsights, selectMember} from '../../../store/selectors/homepage.selectors';
import {
  callSaveUserObject,
  editUserInitialWeight,
} from '../../../store/actions/homepage/homepage-action';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {IUser} from '../../../models/IUser';
import {ValidationType} from '../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../validation/Validation';
import {dateTimeCombined, stringToDateTimeString} from '../../../utils/date-time-utils';
import {ProgressType} from '../../../utils/enums';

import useStyles from './progress-header.styles';

interface Props {
  type: ProgressType;
  isMobile: boolean;
}

const WeightProgressCardHeader: React.FC<Props> = ({type, isMobile}) => {
  const [t] = useTranslation();
  const classes = useStyles({
    isMobile,
  });
  const userInsights = useSelector(selectInsights);
  const [editMood, setEditMood] = useState(false);
  const [data, setData] = useState<any>({
    startWeight: 0,
    date: moment().toString(),
    time: moment().toString(),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedMember = useSelector(selectMember);
  const currentUser = useSelector(selectCurrentUser);
  const [user, setUser] = useState<IUser>();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);

  const validationRules = {
    startWeight: [
      {
        type: ValidationType.IS_DECIMAL_NUMBER,
        minValue: 40,
        maxValue: 1000,
        errorMessage: `${i18n.t('ErrorMessages.notDecimalNumber')}`,
      },
    ],
    date: [{type: ValidationType.REQUIRED}],
    time: [{type: ValidationType.REQUIRED_DURATION}],
  };

  useEffect(() => {
    let initialObject = {
      startWeight: 0,
      date: stringToDateTimeString(userInsights.initialWeightDate) || moment().toString(),
      time: stringToDateTimeString(userInsights.initialWeightDate) || moment().toString(),
    };

    if (editMood) {
      initialObject = {
        startWeight: userInsights.initialWeight,
        date: stringToDateTimeString(userInsights.initialWeightDate || moment().toString()),
        time: stringToDateTimeString(userInsights.initialWeightDate || moment().toString()),
      };
    }
    setData(initialObject);
  }, [userInsights, editMood]);

  useEffect(() => {
    if (selectedMember) {
      setUser(selectedMember);
    } else {
      setUser(currentUser);
    }
    setEditMood(false);
  }, [currentUser, selectedMember]);

  const handleEdit = () => {
    setEditMood(!editMood);
  };

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };
  const validateToSubmit = () => {
    if (!hasChanges) {
      return false;
    }
    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
    setHasChanges(true);
  };

  const handleSubmit = async () => {
    const requestBodyData = {
      userId: user?.id,
      newInitialWeight: data.startWeight,
      initialWeightDate: dateTimeCombined(data.date, data.time),
    };
    setIsLoading(true);
    const res1 = await dispatch(editUserInitialWeight(requestBodyData));
    setIsLoading(false);
    if (!res1) {
      return;
    }
    user?.id && (await dispatch(callSaveUserObject(user?.id)));
    setEditMood(false);
  };
  const changeSpecificDataType = (value: string, specificType: string) => {
    setHasChanges(true);
    setData({
      ...data,
      [specificType]: value,
    });
  };

  return (
    <React.Fragment>
      <div>
        <div className={classes.titleContainer}>
          <Box mb={3}>
            <Typography variant="h6">{type}</Typography>
          </Box>
          {userInsights.initialWeight && (
            <Button
              id={'edit-btn'}
              variant={'text'}
              onClick={handleEdit}
              className={editMood ? classes.editBtn : ''}
            >
              {t('Homepage.quickProfileUser.editInitialWeight')}
            </Button>
          )}
        </div>
        <div className={classes.progressItem}>
          {!userInsights.initialWeight && (
            <div className={classes.warningMessage}>
              <img src={Warning} alt="warning" />
              <Typography variant="body2">
                {t('Homepage.quickProfileUser.initialWeightWarning')}
              </Typography>
            </div>
          )}
          {(editMood || !userInsights.initialWeight) && (
            <div className={`${classes.rowContainer} form`}>
              <TextField
                name={'startWeight'}
                label={t('Homepage.quickProfileUser.startWeight')}
                className={classes.normalTextField}
                value={data.startWeight}
                onChange={handleChange}
                error={hasError(errors, 'startWeight')}
                helperText={getError(errors, 'startWeight')}
                onBlur={() => validateFormField('startWeight')}
                containerClass={classes.normalTextField}
              />
              <div className={`${classes.rowContainer} ${isMobile ? 'mobile' : ''}`}>
                <DatePicker
                  name={'date'}
                  label={t('LogsPage.bodyWidget.dateLabel')}
                  onChange={(value: string) => changeSpecificDataType(value, 'date')}
                  value={data.date}
                  required={true}
                  format={DATE_PICKER_DATE_FORMAT}
                  error={hasError(errors, 'date')}
                  maxDate={moment()}
                  helperText={getError(errors, 'date')}
                />
                <TimePicker
                  name={'time'}
                  value={data.time}
                  error={hasError(errors, 'time')}
                  helperText={getError(errors, 'time')}
                  label={t('LogsPage.bodyWidget.timeLabel')}
                  onChange={(value: string) => changeSpecificDataType(value, 'time')}
                  required={true}
                />
              </div>
            </div>
          )}
          <div className={`${classes.rowContainer} weight`}>
            {!editMood && (
              <DataItem
                label={t('Homepage.quickProfileUser.startWeight')}
                value={user?.initialWeight}
                itemClassName={`${classes.item} weight`}
              />
            )}
            <DataItem
              label={t('Homepage.quickProfileUser.currentWeight')}
              value={userInsights?.currentWeight}
              itemClassName={`${classes.item} weight`}
            />
            <DataItem
              label={t('Homepage.quickProfileUser.weightLossChange')}
              value={
                userInsights.weightLossChange ? userInsights?.weightLossChange + '%' : undefined
              }
              itemClassName={`${classes.item} weight`}
            />
            <DataItem
              label={t('Homepage.quickProfileUser.startBmi')}
              value={userInsights?.bmIinitial}
              itemClassName={`${classes.item} weight`}
            />
            <DataItem
              label={t('Homepage.quickProfileUser.currentBmi')}
              value={userInsights?.bmIcurrent}
              itemClassName={`${classes.item} weight`}
            />
            <DataItem
              label={t('Homepage.quickProfileUser.bmiChange')}
              itemClassName={`${classes.item} weight`}
              value={
                userInsights?.bmiChange != '' &&
                userInsights?.bmiChange != 'NaN' &&
                userInsights.bmiChange
              }
            />
          </div>
        </div>
      </div>
      {(editMood || !userInsights.initialWeight) && (
        <div className={classes.editButtons}>
          {userInsights?.initialWeight && (
            <Button
              id={'cancel-button-profile'}
              variant={'outlined'}
              className={classes.outlinedBtn}
              onClick={handleEdit}
            >
              {t('Homepage.quickProfileUser.cancelButton')}
            </Button>
          )}
          <Button
            id={'save-button-profile'}
            disabled={!validateToSubmit()}
            onClick={handleSubmit}
            loading={isLoading}
          >
            {t('Homepage.quickProfileUser.saveButton')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default WeightProgressCardHeader;
