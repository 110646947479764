import {IA1CDto} from '../dtos/IA1CDto';
import {del, get, post, put} from './api-calls';

export const getA1CLogs = (id: string, dateTime: string): Promise<IA1CDto[]> => {
  return get(`/a1c?UserId=${id}&from=${dateTime}`);
};

export const addA1CDatabase = (data: IA1CDto): Promise<IA1CDto> => {
  return post('/a1c', data);
};

export const editA1CDatabase = (data: IA1CDto): Promise<IA1CDto> => {
  return put(`/a1c/${data.id}`, data);
};

export const deleteA1CDatabase = (id: number): Promise<any> => {
  return del(`/a1c/${id}`);
};

export const getA1CLogById = (id: number): Promise<IA1CDto> => {
  return get(`/a1c/${id}`);
};
export const getA1CLogsActivity = ( id: string,
  fromDate: string,
  toDate: string,
  ): Promise<IA1CDto[]> => {
  return get(`/a1c?UserId=${id}&From=${fromDate}&To=${toDate}`);
};