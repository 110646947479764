export enum StylingLevelCategoryType {
  BACKGROUND_IDLE = 'background-idle',
  BACKGROUND_HOVER = 'background-hover',
  BACKGROUND_SELECTED = 'background-selected',
  TYPOGRAPHY_CLOSE_ICON = 'typography-close-icon',
}

export interface IStylingLevelCategory {
  id: number;
  property: StylingLevelCategoryType;
  value: string;
}

export interface ILevelCategoryDto {
  id: number;
  name: string;
  published_at: string;
  created_at: string;
  updated_at: string;
  styling: IStylingLevelCategory[];
}
