import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      marginBottom: '24px',
    }
  },
  chooseSecondary: {
    width: 237,
    padding: '11.5px 16px',
  },
  rowContainer: {
    display: (props) => props.isMobile ? 'block' : 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  rowContainerTop: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 24,
    marginBottom: 8,
  },
  outlinedBtn: {
    height: '36px !important',
    marginRight: 16,
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  editButton: {
    height: 36,
  },
  contentDialog: {
    '& .MuiDialogContent-root': {
      padding: '14px 16px !important',
    },
  },
  connectionItemIcon: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    border: 'solid',
  },
  coachesList: {
    maxHeight: 160,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  coachesDatePickerModal: {
    marginTop: 24,
  },
  coachesDatePicker: {
    width: 170,
  },
  secondarySelect: {
    width: 160,
  },
  selectCoachRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  endNowBtn: {
    width: 105,
    height: 40,
  },
  profileItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  marginBottom: {
    marginBottom: 16,
  },
  searchBox: {
    marginBottom: 24,
  },
  coachName: {
    textAlign: 'center',
    marginLeft: 14,
  },
  selectedConn: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 12,
  },
  selectedTitle: {
    color: theme.palette.background.paper,
  },
  captionMembers: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },
  membersInfo: {
    marginBottom: 8,
  },
  datePickersRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
  },
  coachRole: {
    marginLeft: 14,
  },
  secondaryCoachName: {
    width: (props) => props.isMobile ? '100%' : 130,
  },
}));

export default useStyles;
