import {makeStyles,Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '9px 16px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px',
    width: (props) => props.isMobileOrTablet ? '100%' : 310,
    height: 159,
    marginRight: 24,
    backgroundColor: theme.palette.background.paper,
    marginBottom: 8,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerIcon: {
    marginRight: 8,
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    height: '36px !important',
  },
}));

export default useStyles;
