import * as React from 'react';

import useStyles from '../recipes/recipe-card.styles';
import {Card, Typography} from '@material-ui/core';
// import classNames from 'classnames';
import {IPodcastDto} from '../../../dtos/IPodCastDto';
import {ContentDialog} from '../dialog/ContentDialog';
import {PodcastPlayerView} from './PodCastPlayerView';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface INourishCardProps {
  card: IPodcastDto;
  cssClass?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const PodCastCardView: React.FC<INourishCardProps> = (props) => {
  const {card, isMobileOrTablet, smallScreens} = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const handleDismiss = () => {
    setOpen(false);
  };
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });

  const handleCardClick = async () => {
    setOpen(true);
  };

  return (
    <>
      <ContentDialog
        fullScreen
        slideUp
        handleDismiss={handleDismiss}
        dialogState={open}
        closeIconClass={
          smallScreens ? classes.smallCloseIconRecipesClass : classes.closeIconRecipesClass
        }
        // isCloseButtonHidden={smallScreens}
        className={classes.dialogMargin}
        // closeIconClass={classes.dialogClose}
        content={
          <PodcastPlayerView
            isMobileOrTablet={isMobileOrTablet}
            smallScreens={smallScreens}
            Content={card}
          />
        }
      />
      <Card
        id={'card' + card.id}
        className={`${classes.card}`}
        onClick={handleCardClick}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        style={{
          height: 230,
          minHeight: 230,
          width: smallScreens ? 300 : 250,
          marginLeft: smallScreens ? 7 : '',
        }}
      >
        <img
          src={card?.image?.image?.formats?.small?.url}
          //   width={300}
          height={148}
          style={{
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            width: '100%',
            height: card?.image?.image?.formats?.small?.url ? 'auto' : 120,
            aspectRatio: 'auto',
          }}
          alt={card?.image?.image?.name}
        />
        <Typography className={classes.headline} style={{padding: 5}} variant={'subtitle2'}>
          {card.title}
        </Typography>
        <Typography style={{paddingLeft: 10, paddingBottom: 15}} variant={'caption'}>
          {card.episode}
        </Typography>
      </Card>
    </>
  );
};

PodCastCardView.displayName = 'PodCastCardView';
