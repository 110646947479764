/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, post, put} from './api-calls';
import {ICasualTag} from '../models/ITag';
import {IStressDto} from '../dtos/IStressDto';

export const getStressTags = (): Promise<ICasualTag[]> => {
  return get(`/stress/tags`);
};

export const getMoodTags = (): Promise<ICasualTag[]> => {
  return get(`/stress/moodtags`);
};

export const getStressLogs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IStressDto[]> => {
  return get(`/stress?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addStressDatabase = (data: IStressDto): Promise<any> => {
  return post('/stress', data);
};

export const editStressDatabase = (data: IStressDto): Promise<any> => {
  return put(`/stress/${data.id}`, data);
};

export const deleteStressDatabase = (id: number): Promise<any> => {
  return del(`/stress/${id}`);
};

export const getStressLogById = (id: number): Promise<IStressDto> => {
  return get(`/stress/${id}`);
};
