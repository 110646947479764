import {useEffect, useState} from 'react';
import {getCurrentThemeType, setCurrentThemeType, lightTheme, darkTheme} from '../../theme/theme';
import {ThemeType} from '../../utils/enums';

export const useTheme = (): any => {
  const [theme, setTheme] = useState<ThemeType>(ThemeType.LIGHT);

  const toggleTheme = () => {
    if (theme === 'light') {
      setCurrentThemeType(ThemeType.DARK);
      setTheme(ThemeType.DARK);
    } else {
      setCurrentThemeType(ThemeType.LIGHT);
      setTheme(ThemeType.LIGHT);
    }
  };

  useEffect(() => {
    const localTheme = getCurrentThemeType() as ThemeType;

    setTheme(localTheme);
  }, []);

  return [{...((theme === 'dark' ? darkTheme : lightTheme) as any)}, theme, toggleTheme];
};
