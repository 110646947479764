import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginStress: {
    marginTop: 32,
  },
  select: {
    width: '164px',
    marginRight: '24px',
  },
  tagsContainer: {
    marginTop: 22,
  },
  rangeSlider: {
    marginLeft: 45,
    marginRight: 45,
  },
}));

export default useStyles;
