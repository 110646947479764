import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  smallScreens: boolean;
  marginBottom: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  mainContainer: {
    position: 'relative',
    margin: (props) => (props.smallScreens ? '0px' : '20px 0'),
    paddingBottom: (props) => (props.marginBottom ? '20px' : '0'),
  },
  carousel: {
    marginTop: '16px',
    width: 'calc(100vw - 33px)',
    '& li > div': {},
  },
  slide: {
    marginRight: '5px',
  },
  plansList: {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    cursor: 'pointer',
  },
  customDotList: {
    top: 'calc(100% - 30px)',
    '& .react-multi-carousel-dot button': {
      border: 'none',
      background: theme.palette.blacksAndGrays.silverSand,
      width: 8,
      height: 8,
      margin: 6,
    },
    '& .react-multi-carousel-dot--active button': {
      border: 'none',
      background: theme.palette.blacksAndGrays.dimGray,
    },
  },
}));

export default useStyles;
