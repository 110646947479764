/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
// import React, {useEffect, useRef} from 'react';
import useStyles from './Eligibility.styles';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import {TextField} from '@material-ui/core';
import {useEffect, useState} from 'react';
import crypto from 'crypto-js';
import {Button} from '../../../common/components/button/Button';
import {getError, hasError, validateField} from '../../../validation/Validation';
import {ValidationType} from '../../../validation/ValidationType';
import i18n from '../../../translation/i18n';
import {TextField as TextFields} from '../../../common/components/text-field/TextField';
import {useTranslation} from 'react-i18next';
import {DirectOnboardUserApi, updateDirectOnboardUserApi} from '../../../api/users.api';
import moment from 'moment';
import {calculateHeightToSend} from '../../../utils/helpers';
import EligibilityError from './EligibilityError';

interface props {
  step2Data?: any;
  smallScreens?: boolean;
  setIsErrorPage:any;
}
const EligibilityStep2: React.FC<props> = ({step2Data, smallScreens}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    credentials: '',
    dateOfBirth: null,
    gender: {
      key: '',
      value: '',
    },
    height1st: '0',
    height2nd: '0',
    email: '',
    mobile: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorPage, setIsErrorPage] = useState(false);
  useEffect(() => {
    setData({
      ...data,
      ['firstName']: step2Data?.firstName,
      ['lastName']: step2Data?.lastName,
      ['email']: step2Data?.email,
      ['mobile']: step2Data.phone,
      ['address1']: step2Data.address1,
      ['address2']: step2Data.address2,
      ['city']: step2Data.city,
      ['state']: step2Data.state,
      ['zip']: step2Data.zip,
    });
  }, [step2Data]);
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'gender') {
      setData({
        ...data,
        [name]: {key: value.key, value: value.value},
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
    // setHasChanges(true);
  };
  const validationRules = {
    email: [
      {
        type: ValidationType.REQUIRED,
      },
    ],

    mobile: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    address1: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    address2: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    city: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    state: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    zip: [
      {
        type: ValidationType.REQUIRED,
      },
    ],
    height1st: [
      {
        type: ValidationType.IS_INT_NUMBER,
        minValue: 0,
        maxValue: 11,
        value: '1',
        errorMessage: `${i18n.t('ErrorMessages.notPositiveIntValue')} ${11}`,
      },
    ],
    height2nd: [
      {
        type: ValidationType.IS_INT_NUMBER,
        minValue: 0,
        maxValue: 11,
        value: '1',
        errorMessage: `${i18n.t('ErrorMessages.notPositiveIntValue')} ${11}`,
      },
    ],
  };

  const validateToSubmit = () => {
    if (data.email === undefined || '' || null) {
      return true;
    } else if (data.mobile === undefined || '' || null) {
      return true;
    } else if (data.address1 === undefined || '' || null) {
      return true;
    } else if (data.city === undefined || '' || null) {
      return true;
    } else if (data.state === undefined || '' || null) {
      return true;
    } else if (data.zip === undefined || '' || null) {
      return true;
    } else if (data.height1st === '' || null) {
      return true;
    } else if (data.height2nd === '' || null) {
      return true;
    } else {
      return false;
    }

    // if (!errors) return false;
  };
  const generateHMACSHA256 = (currentEpochTimeInSeconds: number) => {
    const secretKey = process.env.REACT_APP_HMAC_SECRET_KEY || '';
    const dataToHash = `${currentEpochTimeInSeconds}&${process.env.REACT_APP_HMAC_SECRET_KEY}`;
    const hmac = crypto.HmacSHA256(dataToHash, secretKey);
    const hmacHex = hmac.toString(crypto.enc.Base64);
    return hmacHex;
  };
  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };
  const handelUserEnroll = async () => {
    setIsLoading(true);
    const currentEpochTimeInSeconds = moment().unix();
    const hmacSha256Hash = await generateHMACSHA256(currentEpochTimeInSeconds);
    const heightToSend = calculateHeightToSend(data.height1st, data.height2nd, 1);
    const payload = {
      enrollmentid: step2Data?.enrollmentid,
      phone: data.mobile,
      dateofbirth: moment(step2Data?.dateofbirth).format('YYYY-MM-DD'),
      city: data.city,
      state: data.state,
      address1: data.address1,
      address2: data.address2,
      secret: hmacSha256Hash,
      requesttime: currentEpochTimeInSeconds,
      height: heightToSend,
      email: data?.email,
    };
    try {
      const response = await updateDirectOnboardUserApi(payload);
      if (response) {
        const userPayload = {
          enrollmentid: response?.data?.enrollmentid,
          datasource: response?.data?.datasource,
          secret: hmacSha256Hash,
          requesttime: currentEpochTimeInSeconds,
        };
        const onboardUser = await DirectOnboardUserApi(userPayload);
        if(onboardUser === "User already exists!"){
          window.location.href = `https://app.acuityscheduling.com/schedule.php?owner=11914050&appointmentType=36433511&firstName=${step2Data?.firstName}&lastName=${step2Data?.lastName}&email=${data?.email}&phone=${data?.mobile}`;
        }
        if (onboardUser && onboardUser !== "User already exists!") {
          window.location.href = `https://app.acuityscheduling.com/schedule.php?owner=11914050&appointmentType=36433511&firstName=${step2Data?.firstName}&lastName=${step2Data?.lastName}&email=${data?.email}&phone=${data?.mobile}`;
        }
        setIsLoading(false);
      }
    } catch (error) {
      // Handle the error here
      setIsErrorPage(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isErrorPage ? (
        <EligibilityError setIsErrorPage={setIsErrorPage} smallScreens={smallScreens} />
      ) : (
        <div className={classes.posterContainer}>
          <div>
            <img src={BetterHealthIcon} alt="better health icon" width={110} height={110} />
          </div>
          <div style={{width: '85%', overflow: 'auto', maxWidth: 410, minWidth: 180}}>
            Thanks for confirming your eligibility, now please provide us following additional
            information for enrollment process:
            <h6>Email*</h6>
            <div style={{width: 410}}>
              <TextField
                style={{width: smallScreens ? '80%' : '96%'}}
                variant="outlined"
                value={data?.email}
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>Mobile*</h6>
              <TextField
                style={{width: '96%'}}
                value={data?.mobile}
                name="mobile"
                onChange={(e) => handleChange(e)}
                variant="outlined"
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>Address Line1*</h6>
              <TextField
                style={{width: '96%'}}
                value={data?.address1}
                name="address1"
                onChange={(e) => handleChange(e)}
                variant="outlined"
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>Address Line2</h6>
              <TextField
                style={{width: '96%'}}
                value={data?.address2}
                name="address2"
                onChange={(e) => handleChange(e)}
                variant="outlined"
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>City*</h6>
              <TextField
                style={{width: '96%'}}
                value={data?.city}
                name="city"
                onChange={(e) => handleChange(e)}
                variant="outlined"
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>State*</h6>
              <TextField
                style={{width: '96%'}}
                value={data?.state}
                name="state"
                onChange={(e) => handleChange(e)}
                variant="outlined"
              />
            </div>
            <div>
              <h6 style={{marginBottom: 5}}>Zip Code*</h6>
              <TextField
                style={{width: '96%'}}
                value={data?.zip}
                name="zip"
                onChange={(e) => handleChange(e)}
                variant="outlined"
              />
            </div>
            <div className={classes.heightContainer}>
              <TextFields
                name={'height1st'}
                className={classes.heightTextField}
                label={t('Homepage.quickProfileUser.height')}
                value={data?.height1st}
                required={false}
                onChange={handleChange}
                error={hasError(errors, 'height1st')}
                helperText={getError(errors, 'height1st')}
                onBlur={() => validateFormField('height1st')}
                containerClass={classes.heightTextField}
                unit={'feet'}
              />
              <TextFields
                name={'height2nd'}
                className={classes.heightTextFieldRight}
                label={''}
                value={data?.height2nd}
                onChange={handleChange}
                error={hasError(errors, 'height2nd')}
                helperText={getError(errors, 'height2nd')}
                onBlur={() => validateFormField('height2nd')}
                containerClass={classes.heightTextField}
                unit={'inch'}
                unitClassName={classes.unit}
              />
            </div>
            <Button
              id="continue"
              onClick={() => handelUserEnroll()}
              disabled={validateToSubmit()}
              loading={isLoading}
              className={classes.bottomButton}
              //   style={{marginTop:10}}
            >
              Enroll
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

EligibilityStep2.displayName = 'EligibilityStep2';
export default EligibilityStep2;
