import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from '../../text-field/TextField';
import {getError, hasError} from '../../../../validation/Validation';

import useCommonStyles from '../common-for-widgets.styles';

interface IA1CSubformProps {
  validateFormField: any;
  data?: any;
  errors: any;
  changeData: any;
  validationRulesA1C: any;
  isMobile: boolean;
}

const A1CSubform: React.FC<IA1CSubformProps> = (props) => {
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [t] = useTranslation();
  const [data, setData] = useState({a1CValue: ''});

  useEffect(() => {
    const initialObject = {
      a1CValue: props.data || '',
    };
    setData(initialObject);
  }, []);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
    props.changeData({[name]: value});
  };

  return (
    <div className={commonClasses.centerContainer}>
      <TextField
        name={'a1CValue'}
        containerClass={commonClasses.normalTextField}
        required={true}
        onBlur={() => props.validateFormField(data, 'a1CValue', props.validationRulesA1C)}
        value={data.a1CValue}
        label={t('LogsPage.bloodLabsWidget.a1CLabel')}
        placeholder={t('LogsPage.bloodLabsWidget.a1CTextFieldPlaceholder')}
        onChange={(event) => handleChange(event)}
        error={hasError(props.errors, 'a1CValue')}
        helperText={getError(props.errors, 'a1CValue')}
      />
    </div>
  );
};

export default A1CSubform;
