import * as React from 'react';
import {Card, CardContent, CardMedia, Typography} from '@material-ui/core';
import {LevelFlag} from '../level-flag/LevelFlag';
import PlaceholderImage from '../../../assets/icons/placeholders/placeholder-cooking.svg';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

import useStyles from './daily-content-card.styles';
import {IDailyContentDto} from '../../../dtos/IDailyContentDto';

interface Props {
  dailyContent: IDailyContentDto;
  onClick: (slug: string) => void;
  containerStyle?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

export const DailyContentCard: React.FC<Props> = ({
  dailyContent,
  onClick,
  containerStyle,
  isMobileOrTablet,
  smallScreens,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });

  return (
    <Card
      className={`${classes.card} ${containerStyle}`}
      onClick={() => onClick(dailyContent.slug)}
      id={dailyContent.id.toString()}
    >
      <CardMedia
        component="img"
        height={isMobileOrTablet.isMobile ? '72' : '148'}
        image={dailyContent.mainImage?.image.url || PlaceholderImage}
        alt="recipe-image"
      />
      <LevelFlag
        title={dailyContent?.tag?.name}
        levelCategory={dailyContent?.level_category}
        top={8}
        right={8}
      />
      <div className={classes.infoContainer}>
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {dailyContent.Title}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};
