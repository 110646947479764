import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import classNames from 'classnames';
import moment from 'moment';
import {ClickAwayListener, Typography} from '@material-ui/core';
import {Circle} from './Circle';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {TextField} from '../../text-field/TextField';
import BodyMeasurementIcon from '../../../../assets/icons/side-menu-bar-logging/body-measurement-icon.svg';
import BodyBackground from '../../../../assets/icons/widgets/body-background.svg';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {
  addBodyMeasurementLog,
  editBodyMeasurementLog,
} from '../../../../store/actions/logging-page/logging-page-action';
import {ValidationType} from '../../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../../validation/Validation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {DATE_PICKER_DATE_FORMAT, MAX_INT_VALUE} from '../../../../utils/constants';
import {BodyPartType, FormType, WidgetType} from '../../../../utils/enums';

import useStyles from './body-measurement-form.style';
import useCommonStyles from '../common-for-widgets.styles';
import {IUser} from '../../../../models/IUser';

interface IBodyMeasurementFormData {
  date: string;
  time: string;
  upperArm: string;
  chest: string;
  waist: string;
  hips: string;
  thighs: string;
}
interface IBodyMeasurementFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  isMobile: boolean;
}

const BodyMeasurementForm: React.FC<IBodyMeasurementFormProps> = (props) => {
  const validationRules = {
    upperArm: [
      {
        type: ValidationType.IS_DECIMAL_NUMBER,
        minValue: 0,
        maxValue: MAX_INT_VALUE,
        value: '2',
        allowedEmptyString: true,
        errorMessage: i18n.t('ErrorMessages.notValidValue'),
      },
    ],
    chest: [
      {
        type: ValidationType.IS_DECIMAL_NUMBER,
        minValue: 0,
        maxValue: MAX_INT_VALUE,
        value: '2',
        allowedEmptyString: true,
        errorMessage: i18n.t('ErrorMessages.notValidValue'),
      },
    ],
    waist: [
      {
        type: ValidationType.IS_DECIMAL_NUMBER,
        minValue: 0,
        maxValue: MAX_INT_VALUE,
        value: '2',
        allowedEmptyString: true,
        errorMessage: i18n.t('ErrorMessages.notValidValue'),
      },
    ],
    hips: [
      {
        type: ValidationType.IS_DECIMAL_NUMBER,
        minValue: 0,
        maxValue: MAX_INT_VALUE,
        value: '2',
        allowedEmptyString: true,
        errorMessage: i18n.t('ErrorMessages.notValidValue'),
      },
    ],
    thighs: [
      {
        type: ValidationType.IS_DECIMAL_NUMBER,
        minValue: 0,
        maxValue: MAX_INT_VALUE,
        value: '2',
        allowedEmptyString: true,
        errorMessage: i18n.t('ErrorMessages.notValidValue'),
      },
    ],
    date: [{type: ValidationType.REQUIRED}],
    time: [{type: ValidationType.REQUIRED_DURATION}],
  };

  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentUser: IUser = useSelector(selectCurrentUser);
  const [data, setData] = useState<IBodyMeasurementFormData>({
    date: moment().toString(),
    time: moment().toString(),
    upperArm: '',
    chest: '',
    waist: '',
    hips: '',
    thighs: '',
  });
  const formType = props.formType || FormType.ADDING_MODE;
  const [selectedBodyPart, setSelectedBodyPart] = useState(BodyPartType.NONE);

  const validateFormField = (field: string) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const checkIfValueIsEmpty = (value: string) => {
    return parseFloat(value) === 0 || value === '';
  };

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);

    if (
      checkIfValueIsEmpty(data.hips) &&
      checkIfValueIsEmpty(data.waist) &&
      checkIfValueIsEmpty(data.thighs) &&
      checkIfValueIsEmpty(data.chest) &&
      checkIfValueIsEmpty(data.upperArm)
    )
      return false;
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }

    const err = validate(data, validationRules);
    return isFormValidToSubmit(err);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    let initialObject: IBodyMeasurementFormData = {
      date: moment().toString(),
      time: moment().toString(),
      upperArm: '',
      chest: '',
      waist: '',
      hips: '',
      thighs: '',
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
        upperArm: props.data?.upperArm.toString() || '',
        chest: props.data?.chest.toString() || '',
        waist: props.data?.waist.toString() || '',
        hips: props.data?.hips.toString() || '',
        thighs: props.data?.thighs.toString() || '',
      };
    }

    setData(initialObject);
  }, [props.data]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    setData({
      date: moment().toString(),
      time: moment().toString(),
      upperArm: '',
      chest: '',
      waist: '',
      hips: '',
      thighs: '',
    });

    setSelectedBodyPart(BodyPartType.NONE);
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDate = dateTimeCombined(data.date, data.time);
      const bodyMeasurementLog = {
        id: props.data?.id || undefined,
        userId: currentUser.id,
        date: selectedDate,
        upperArm: data.upperArm === '' ? 0 : parseFloat(data.upperArm),
        chest: data.chest === '' ? 0 : parseFloat(data.chest),
        waist: data.waist === '' ? 0 : parseFloat(data.waist),
        hips: data.hips === '' ? 0 : parseFloat(data.hips),
        thighs: data.thighs === '' ? 0 : parseFloat(data.thighs),
      };
      let res: any;
      if (!props.data) {
        res = await dispatch(addBodyMeasurementLog(bodyMeasurementLog));
      } else {
        res = await dispatch(editBodyMeasurementLog(bodyMeasurementLog));
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  const handleMeasurementUnit = (): string => {
    if (currentUser?.measurementUnit?.id === 2) {
      return t('LogsPage.bodyMeasurementWidget.valuePlaceholderCm');
    }

    return t('LogsPage.bodyMeasurementWidget.valuePlaceholder');
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img
            className={commonClasses.imageMargin}
            src={BodyMeasurementIcon}
            alt={'body-measurement-icon'}
          />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.bodyMeasurementWidget.formTitleEdit')
              : t('LogsPage.bodyMeasurementWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.bodyMeasurementWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.bodyMeasurementWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <Typography variant="body1">{t('LogsPage.bodyMeasurementWidget.formSubtitle')}</Typography>

      <ClickAwayListener onClickAway={() => setSelectedBodyPart(BodyPartType.NONE)}>
        <div className={classes.bodyContainer}>
          <img src={BodyBackground} alt={'body-background'} />

          <div
            onKeyDown={() => setSelectedBodyPart(BodyPartType.UPPER_ARM)}
            onClick={() => setSelectedBodyPart(BodyPartType.UPPER_ARM)}
            role="button"
            tabIndex={0}
            className={
              selectedBodyPart === BodyPartType.UPPER_ARM
                ? classNames(
                    classes.bodyPartContainer,
                    classes.upperArmAddition,
                    classes.selectedPart,
                  )
                : classNames(classes.bodyPartContainer, classes.upperArmAddition)
            }
          >
            <Circle
              tooltip={t('LogsPage.bodyMeasurementWidget.tooltips.upperArm')}
              circleClass={classes.circleContainer}
              innerClass={classes.innerCircle}
              tooltipClass={classes.tooltip}
              selected={selectedBodyPart === BodyPartType.UPPER_ARM}
            />
            <div className={`${classes.circleLine} circleLineWidth`} />
            <div className={classes.bodyTextLabel}>
              <Typography variant={'body2'}>
                {t('LogsPage.bodyMeasurementWidget.upperArm')}
              </Typography>
            </div>
            <div className={classes.valueField}>
              {selectedBodyPart === BodyPartType.UPPER_ARM ? (
                <TextField
                  name={'upperArm'}
                  className={classes.normalTextField}
                  onBlur={() => validateFormField('upperArm')}
                  value={data.upperArm}
                  placeholder={handleMeasurementUnit()}
                  onChange={(event) => handleChange(event)}
                  error={hasError(errors, 'upperArm')}
                  helperText={getError(errors, 'upperArm')}
                />
              ) : (
                <button onClick={() => setSelectedBodyPart(BodyPartType.UPPER_ARM)}>
                  {data.upperArm?.length > 0 ? data.upperArm : 0} {handleMeasurementUnit()}
                </button>
              )}
            </div>
          </div>

          <div
            onKeyDown={() => setSelectedBodyPart(BodyPartType.CHEST)}
            onClick={() => setSelectedBodyPart(BodyPartType.CHEST)}
            role="button"
            tabIndex={0}
            className={
              selectedBodyPart === BodyPartType.CHEST
                ? classNames(classes.bodyPartContainer, classes.chestAddition, classes.selectedPart)
                : classNames(classes.bodyPartContainer, classes.chestAddition)
            }
          >
            <Circle
              tooltip={t('LogsPage.bodyMeasurementWidget.tooltips.chest')}
              circleClass={classes.circleContainer}
              innerClass={classes.innerCircle}
              tooltipClass={classes.tooltip}
              selected={selectedBodyPart === BodyPartType.CHEST}
            />
            <div className={`${classes.circleLine} circleLineWidth`} />
            <div className={classes.bodyTextLabel}>
              <Typography variant={'body2'}>{t('LogsPage.bodyMeasurementWidget.chest')}</Typography>
            </div>
            <div className={classes.valueField}>
              {selectedBodyPart === BodyPartType.CHEST ? (
                <TextField
                  name={'chest'}
                  className={classes.normalTextField}
                  onBlur={() => validateFormField('chest')}
                  value={data.chest}
                  placeholder={handleMeasurementUnit()}
                  onChange={(event) => handleChange(event)}
                  error={hasError(errors, 'chest')}
                  helperText={getError(errors, 'chest')}
                />
              ) : (
                <button onClick={() => setSelectedBodyPart(BodyPartType.CHEST)}>
                  {data.chest?.length > 0 ? data.chest : 0} {handleMeasurementUnit()}
                </button>
              )}
            </div>
          </div>

          <div
            onKeyDown={() => setSelectedBodyPart(BodyPartType.WAIST)}
            onClick={() => setSelectedBodyPart(BodyPartType.WAIST)}
            role="button"
            tabIndex={0}
            className={
              selectedBodyPart === BodyPartType.WAIST
                ? classNames(classes.bodyPartContainer, classes.waistAddition, classes.selectedPart)
                : classNames(classes.bodyPartContainer, classes.waistAddition)
            }
          >
            <Circle
              tooltip={t('LogsPage.bodyMeasurementWidget.tooltips.waist')}
              circleClass={classes.circleContainer}
              innerClass={classes.innerCircle}
              tooltipClass={classes.tooltip}
              selected={selectedBodyPart === BodyPartType.WAIST}
            />
            <div className={`${classes.circleLine} circleLineWidth`} />
            <div className={classes.bodyTextLabel}>
              <Typography variant={'body2'}>{t('LogsPage.bodyMeasurementWidget.waist')}</Typography>
            </div>
            <div className={classes.valueField}>
              {selectedBodyPart === BodyPartType.WAIST ? (
                <TextField
                  name={'waist'}
                  className={classes.normalTextField}
                  onBlur={() => validateFormField('waist')}
                  value={data.waist}
                  placeholder={handleMeasurementUnit()}
                  onChange={(event) => handleChange(event)}
                  error={hasError(errors, 'waist')}
                  helperText={getError(errors, 'waist')}
                />
              ) : (
                <button onClick={() => setSelectedBodyPart(BodyPartType.WAIST)}>
                  {data.waist?.length > 0 ? data.waist : 0} {handleMeasurementUnit()}
                </button>
              )}
            </div>
          </div>

          <div
            onKeyDown={() => setSelectedBodyPart(BodyPartType.HIPS)}
            onClick={() => setSelectedBodyPart(BodyPartType.HIPS)}
            role="button"
            tabIndex={0}
            className={
              selectedBodyPart === BodyPartType.HIPS
                ? classNames(classes.bodyPartContainer, classes.hipsAddition, classes.selectedPart)
                : classNames(classes.bodyPartContainer, classes.hipsAddition)
            }
          >
            <Circle
              tooltip={t('LogsPage.bodyMeasurementWidget.tooltips.hips')}
              circleClass={classes.circleContainer}
              innerClass={classes.innerCircle}
              tooltipClass={classes.tooltip}
              selected={selectedBodyPart === BodyPartType.HIPS}
            />
            <div className={`${classes.circleLine} circleLineWidth`} />
            <div className={classes.bodyTextLabel}>
              <Typography variant={'body2'}>{t('LogsPage.bodyMeasurementWidget.hips')}</Typography>
            </div>
            <div className={classes.valueField}>
              {selectedBodyPart === BodyPartType.HIPS ? (
                <TextField
                  name={'hips'}
                  className={classes.normalTextField}
                  onBlur={() => validateFormField('hips')}
                  value={data.hips}
                  placeholder={handleMeasurementUnit()}
                  onChange={(event) => handleChange(event)}
                  error={hasError(errors, 'hips')}
                  helperText={getError(errors, 'hips')}
                />
              ) : (
                <button onClick={() => setSelectedBodyPart(BodyPartType.HIPS)}>
                  {data.hips?.length > 0 ? data.hips : 0} {handleMeasurementUnit()}
                </button>
              )}
            </div>
          </div>

          <div
            onKeyDown={() => setSelectedBodyPart(BodyPartType.THIGHS)}
            onClick={() => setSelectedBodyPart(BodyPartType.THIGHS)}
            role="button"
            tabIndex={0}
            className={
              selectedBodyPart === BodyPartType.THIGHS
                ? classNames(
                    classes.bodyPartContainer,
                    classes.thighsAddition,
                    classes.selectedPart,
                  )
                : classNames(classes.bodyPartContainer, classes.thighsAddition)
            }
          >
            <Circle
              tooltip={t('LogsPage.bodyMeasurementWidget.tooltips.thighs')}
              circleClass={classes.circleContainer}
              innerClass={classes.innerCircle}
              tooltipClass={classes.tooltip}
              selected={selectedBodyPart === BodyPartType.THIGHS}
            />
            <div className={`${classes.circleLine} circleLineWidth`} />
            <div className={classes.bodyTextLabel}>
              <Typography variant={'body2'}>
                {t('LogsPage.bodyMeasurementWidget.thighs')}
              </Typography>
            </div>
            <div className={classes.valueField}>
              {selectedBodyPart === BodyPartType.THIGHS ? (
                <TextField
                  name={'thighs'}
                  className={classes.normalTextField}
                  onBlur={() => validateFormField('thighs')}
                  value={data.thighs}
                  placeholder={handleMeasurementUnit()}
                  onChange={(event) => handleChange(event)}
                  error={hasError(errors, 'thighs')}
                  helperText={getError(errors, 'thighs')}
                />
              ) : (
                <button onClick={() => setSelectedBodyPart(BodyPartType.THIGHS)}>
                  {data.thighs?.length > 0 ? data.thighs : 0} {handleMeasurementUnit()}
                </button>
              )}
            </div>
          </div>
        </div>
      </ClickAwayListener>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-body-measurement-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-bodyMeasurement-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.bodyMeasurementWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default BodyMeasurementForm;
