import React from 'react';
import {Typography} from '@material-ui/core';
import {stringToDateTimeFormatString} from '../../../utils/date-time-utils';
import {ONLY_DATE_FORMAT, TIME_FORMAT} from '../../../utils/constants';

import useCommonAddedRowStyles from './common-for-added-row.styles';

interface IDateTimeProps {
  date: string;
  isMobile: boolean;
}

const DateTimeString: React.FC<IDateTimeProps> = ({date, isMobile}) => {
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  return (
    <Typography variant={'overline'} className={commonClasses.dateContent}>
      {isMobile ? (
        <span className={commonClasses.dateTime}>
          {stringToDateTimeFormatString(date, ONLY_DATE_FORMAT)} <div />
          {stringToDateTimeFormatString(date, TIME_FORMAT)}
        </span>
      ) : (
        stringToDateTimeFormatString(date)
      )}
    </Typography>
  );
};

export default DateTimeString;
