/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, post, put} from './api-calls';
import {ICategoryTagDto} from '../dtos/ICategoryTagDto';
import {IFoodDto} from '../dtos/IFoodDto';

export const getCategoryTags = (
  searchFilter: string,
  includeLevelTwo: boolean,
): Promise<ICategoryTagDto[]> => {
  return get(`/food/tags?IncludeLevel2=${includeLevelTwo}&SearchString=${searchFilter}`);
};

export const getFoodLoggs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<IFoodDto[]> => {
  return get(`/food?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addFoodLoggDatabase = (data: any): Promise<any> => {
  return post('/food', data, {
    'content-type': 'multipart/form-data',
  });
};

export const editFoodLoggDatabase = (data: any): Promise<any> => {
  return put(`/food/${data.get('id')}`, data, {
    'content-type': 'multipart/form-data',
  });
};

export const deleteFoodLoggDatabase = (id: number): Promise<any> => {
  return del(`/food/${id}`);
};

export const getSourceOfFood = (): Promise<any> => {
  return get('/food/sources');
};

export const getFoodLogById = (id: number): Promise<IFoodDto> => {
  return get(`/food/${id}`);
};
