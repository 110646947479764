import * as React from 'react';
import {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {AppNavBarNotification} from './AppNavBarNotification';
import {AppNavBarSettings} from './AppNavBarSettings';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import BellIcon from '../../../assets/icons/app-nav-bar/blue-bell-icon.svg';
import DotsIcon from '../../../assets/icons/app-nav-bar/three-dots-icon.svg';

import useStyles from './app-nav-bar-mob.styles';

interface Props {
  selectedMember: any;
  currentUser: any;
  isMobileOrTablet: IResponsiveSize;
  callLogOut: () => void;
  smallScreens: any;
}

export const AppNavBarMob: React.FC<Props> = (props) => {
  const smallScreens =
    props.isMobileOrTablet.isMobile ||
    props.isMobileOrTablet.isTabletSmall ||
    props.isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet: props.isMobileOrTablet,
    smallScreens,
  });
  const [openNotification, setOpenNotification] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  return (
    <Fragment>
      <AppBar
        position={smallScreens ? 'fixed' : 'sticky'}
        className={smallScreens ? `${classes.smallTopBar}` : classes.navbar}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.navigationLeftPartContainer} />
          <Link to="/" className={classes.notificationCenterPanel}>
            <img src={BetterHealthIcon} alt="better health icon" />
          </Link>
          {props.currentUser && (
            <div className={classes.notificationRightPanel}>
              <button className={classes.navbarButton} onClick={() => setOpenNotification(true)}>
                <img src={BellIcon} alt="notifications" />
              </button>
              <button className={classes.navbarButton} onClick={() => setOpenSettings(true)}>
                <img src={DotsIcon} alt="options" />
              </button>
            </div>
          )}
        </Toolbar>
      </AppBar>

      {openNotification && (
        <AppNavBarNotification
          handleClose={() => setOpenNotification(false)}
          selectedMember={props.selectedMember}
        />
      )}

      {openSettings && (
        <AppNavBarSettings
          handleClose={() => setOpenSettings(false)}
          selectedMember={props.selectedMember}
          callLogOut={props.callLogOut}
        />
      )}
    </Fragment>
  );
};
