/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const createReducer = (initialState: any, handlers: any) => {
  return function reducer(state = initialState, action: any) {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

/* eslint no-console: ["error", { allow: ["error"] }] */
export const handlePrint = (url: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    if (!url) {
      throw Error('Print failed: Document url is not defined');
    }
    window.open(url, 'PRINT');
  } catch (err) {
    // eslint-disable-next-line no-console
    // console.log(err);
    throw err;
  }
};

export const calculateHeightToSend = (unit1: string, unit2: string, unitId: number) => {
  if (unitId === 1) {
    const feet = parseInt(unit1);
    const inch = parseInt(unit2);
    return 12 * feet + inch;
  } else {
    const m = parseInt(unit1);
    const cm = parseInt(unit2);
    return m * 100 + cm;
  }
};

export const calculateHeightToDisplay = (height: number, unitId: number): string[] => {
  if (!height || height === 0) return ['0', '0'];
  let convertedHeight = [];
  if (unitId === 1) {
    const feet = parseInt((height / 12).toString()).toString();
    convertedHeight.push(feet);
    const inch = (height % 12).toString();
    convertedHeight.push(inch);
    return convertedHeight;
  } else {
    convertedHeight = (height / 100).toFixed(2).toString().split('.');
    return convertedHeight;
  }
};

export const getHoursAndMinutesFromMinutes = (min: number): string => {
  const hours = min / 60;
  const roundHours = Math.floor(hours);
  const minutes = (hours - roundHours) * 60;
  const roundMinutes = Math.round(minutes);
  return `${roundHours}h ${roundMinutes}min`;
};
