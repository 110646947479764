import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: {
    isMobile: boolean;
    isTablet: boolean;
  };
  smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  searchContainer: {
    padding: '0 !important',
  },
  filterContent: {
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
    },
    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
    },
    '& div.MuiButtonBase-root.MuiAccordionSummary-root': {
      background: (props) => (props.smallSize ? theme.palette.primary.dark : 'transparent'),
      color: (props) => (props.smallSize ? theme.palette.background.paper : ''),
      padding: (props) => (props.smallSize ? '0' : '0'),
      marginBottom: (props) => (props.smallSize ? '5px' : '0'),
      '& .MuiAccordionSummary-content': {
        width: (props) => (props.smallSize ? '100%' : 'auto'),
      },
      '& .MuiTypography-caption': {
        fontSize: (props) => (props.smallSize ? '16px' : '12px'),
        
      },
      '& .MuiIconButton-label': {
        filter: (props) => (props.smallSize ? 'invert(1)' : 'initial'),
      },
    },
    padding: 0,
    color: (props) => (props.smallSize ? theme.palette.secondary.main : ''),
  }, 
  searchBox: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: (props) => (props.smallSize ? '100%' : '282px !important'),
    borderRadius: 8,
    marginBottom: 6,
    marginRight: '0px !important',
    color: (props) => (props.smallSize ? '#fff !important' : ''),
    '& .sidebar-searchBox .MuiInputBase-root input': {
      backgroundColor: (props) =>
      props.smallSize
        ? theme.palette.tintsAndShades.oxfordBlueDark
        : theme.palette.background.paper,
      color: (props) =>
      props.smallSize
        ? "#fff !important"
        : theme.palette.blacksAndGrays.stoneGray,
    },
    '& .sidebar-searchBox .MuiInputBase-root input::placeholder': {
      color: (props) =>
        props.smallSize
          ? "#ffffff !important"
          : theme.palette.blacksAndGrays.stoneGray,
      fontSize: (props) => props.smallSize ? 18 : 18,
      backgroundColor: (props) =>
      props.smallSize
        ? theme.palette.tintsAndShades.oxfordBlueDark
        : theme.palette.background.paper,
        
    },
  },
  selectBox: {
    backgroundColor: (props) =>
      props.smallSize
        ? theme.palette.tintsAndShades.oxfordBlueDark
        : theme.palette.background.paper,
    width: (props) =>
      props.isMobileOrTablet.isMobile ? '100%' : props.isMobileOrTablet.isTablet ? '50%' : 282,
    color: (props) => (props.smallSize ? theme.palette.background.paper : ''),
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .side-menu-bar-select': {
      width: '100%',
    },
    '& .MuiTypography-root': {
      color: (props) =>
        props.smallSize
          ? "#fff !important"
          : theme.palette.blacksAndGrays.stoneGray,
    },
    '& svg': {
      filter: (props) => (props.smallSize ? 'invert(1)' : 'initial'),
    },
  },
  containerTags: {
    display: 'flex',
    maxWidth: (props) => (props.smallSize ? '100%' : 280),
    flexWrap: 'wrap',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) => (props.isMobileOrTablet.isTablet ? 'end' : 'space-evenly'),
    marginBottom: 24,
    marginTop: 16,
    width: (props) => (props.smallSize ? '100%' : 'auto'),
  },
  clearBtn: {
    border: `1.5px solid ${theme.palette.blacksAndGrays.stoneGray} !important`,
    height: (props) => (props.smallSize ? '48px !important' : '36px !important'),
    width: (props) =>
      props.isMobileOrTablet.isMobile ? '100%' : props.isMobileOrTablet.isTablet ? 152 : 133,
    color: `${theme.palette.blacksAndGrays.stoneGray} !important`,
    padding: '0px 16px',
    marginRight: (props) => (props.smallSize ? 5 : 0),
    '&:hover': {
      color: `${theme.palette.blacksAndGrays.stoneGray} !important`,
      borderColor: `${theme.palette.blacksAndGrays.stoneGray} !important`,
    },
  },
  filterBtn: {
    width: (props) =>
      props.isMobileOrTablet.isMobile ? '100%' : props.isMobileOrTablet.isTablet ? 152 : 'auto',
    marginLeft: (props) => (props.smallSize ? 5 : 0),
    boxShadow: (props) =>
      props.smallSize
        ? '0px 3px 1px rgba(100, 101, 102, 0.2), 0px 2px 2px rgba(100, 101, 102, 0.14), 0px 1px 5px rgba(100, 101, 102, 0.12)'
        : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    backgroundColor: (props) =>
      props.smallSize
        ? `${theme.palette.tintsAndShades.oxfordBlueDark} !important`
        : theme.palette.primary.main,
    color: (props) =>
      props.smallSize
        ? `#fff`
        : theme.palette.background.paper,
    '&:hover': {
      color: (props) => (props.smallSize ? theme.palette.tintsAndShades.blueGrey : ''),
      backgroundColor: (props) =>
        props.smallSize ? theme.palette.tintsAndShades.babyBlueEyes : '',
    },
  },
  textColor: {
    color: (props) =>
      props.smallSize ? theme.palette.background.paper : theme.palette.blacksAndGrays.davysGray,
  },
  containerMargin: {
    marginTop: 16,
  },
  boxTags: {
    marginTop: 16,
    marginBottom: (props) => (props.smallSize ? 14 : 30),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: (props) =>
      props.smallSize ? 'none' : `1px solid ${theme.palette.blacksAndGrays.gainsboro}`,
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: 8,
    paddingTop: 0,
    minHeight: 40,
    background: (props) =>
      props.smallSize
        ? theme.palette.tintsAndShades.oxfordBlueDark
        : theme.palette.background.paper,
    maxWidth: (props) => (props.smallSize ? '100%' : 282),
  },
  containerWidth: {
    width: (props) => (props.smallSize ? '100%' : 282),
    paddingLeft: 45,
  },
  subItem: {
    marginTop: 12,
    marginBottom: 12,
  },
  subItemText: {
    color: theme.palette.blacksAndGrays.stoneGray,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.6,
    },
  },
  subItemSelected: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
  },
}));

export default useStyles;
