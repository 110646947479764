import {makeStyles, Theme} from '@material-ui/core/styles';
import {handlePadding} from '../../../utils/css-helpers';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallSize: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    width: (props) => (props.smallSize ? '100%' : 'auto'),
    '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root, .MuiAccordion-root': {
      backgroundColor: '#FDFBF9 !important',
      // backgroundColor: (props) =>
      //   props.smallSize ? theme.palette.primary.dark : theme.palette.background.default,
      color: (props) => (props.smallSize ? theme.palette.background.paper : ''),
      width: '100%',
      boxShadow: (props) => (props.smallSize ? 'none' : ''),
    },
    '& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root, .MuiAccordionSummary-root': {
      padding: (props) => handlePadding(props.isMobileOrTablet),
    },
    '&.active > div > .MuiButtonBase-root.MuiAccordionSummary-root, &.active > div > div > .MuiButtonBase-root.MuiAccordionSummary-root':
      {
        backgroundColor: (props) => (props.smallSize ? theme.palette.background.paper : ''),
        color: (props) => (props.smallSize ? theme.palette.primary.dark : ''),
      },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '24px !important',
      padding: (props) => handlePadding(props.isMobileOrTablet),
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded ': {
      paddingBottom: 0,
      marginTop: 12,
    },
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      padding: (props) => handlePadding(props.isMobileOrTablet),
      backgroundColor: (props) =>   props.smallSize ? '#d6d6d6' : '',
    },
    '& .MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none !important',
    },
  },
  content: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '& > div': {
        padding: (props) => (props.smallSize ? '12px 0' : 0),
      },
    },
    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
    },
  },
}));

export default useStyles;
