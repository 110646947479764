import React from 'react';
import {useDispatch} from 'react-redux';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import * as weightLoggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';
import {IWeightDto} from '../../../../dtos/IWeightDto';

import useStyles from './body-weight-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface BodyWeightRecentlyAddedRowProps {
  data: IWeightDto;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
}

const BodyWeightRecentlyAddedRow: React.FC<BodyWeightRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(
      weightLoggingActions.deleteWeightLogg(
        data.id || 0 /*, (errorMessage: string) => {
        //setBackendError(errorMessage);
      }*/,
      ),
    );

    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };
  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        <div className={commonClasses.infoContainer}>
          {isMobile ? (
            <div>
              <Typography variant={'subtitle1'} className={classes.weightContent}>
                {data.value + ' ' + data.massUnit}
              </Typography>
              <DateTimeString date={data.date} isMobile={isMobile} />
            </div>
          ) : (
            <React.Fragment>
              <DateTimeString date={data.date} isMobile={isMobile} />
              <Typography variant={'subtitle1'} className={classes.weightContent}>
                {data.value + ' ' + data.massUnit}
              </Typography>
            </React.Fragment>
          )}

          {/*<Typography variant={'caption'} className={classes.textContent}>*/}
          {/*  {data.comment}*/}
          {/*</Typography>*/}
        </div>

        <div className={commonClasses.buttonsContainer}>
          <EllipsisOptions
            handleEdit={handleEdit}
            handleDismiss={handleDismiss}
            fromDevice={data.fromScale}
          />
        </div>
      </div>
    </div>
  );
};
export default BodyWeightRecentlyAddedRow;
