import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobileOrTablet: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  input: {
    '&.side-menu-bar-input > div': {
      color: (props) =>
        props.isMobileOrTablet
          ? theme.palette.background.paper
          : theme.palette.blacksAndWhites.smokyBlack,
    },
  },
  label: {
    top: -30,
    position: 'absolute',
  },
  textFieldContainer: {
    position: 'relative',
    marginTop: '8px',
    marginRight: 16,
    background: theme.palette.background.default,
    borderRadius: 8,
    width: (props) => (props.isMobileOrTablet ? '100%' : 'fit-content'),
    boxShadow: (props) =>
      props.isMobileOrTablet
        ? 'none'
        : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '& .sidebar-searchBox .MuiOutlinedInput-root': {
      borderRadius: 8,
      background: (props) =>
        props.isMobileOrTablet
          ? "#d6d6d6"
          : "#ffffff",
          // props.isMobileOrTablet
          // ? theme.palette.tintsAndShades.oxfordBlueDark
          // : theme.palette.background.default,
    },
    '& .sidebar-searchBox.MuiFormControl-root': {
      width: '100%',
      background: (props) => (props.isMobileOrTablet ? theme.palette.primary.dark : 'transparent'),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: (props) => (props.isMobileOrTablet ? 'none' : ''),
    },
    '& .MuiOutlinedInput-input': {
      padding: '11px 44px',
      color: (props) =>
        props.isMobileOrTablet
          ? '#000000 !important'
          :  theme.palette.blacksAndGrays.jet,
    },
  },
  search: {
    '& .sidebar-searchBox .MuiInputBase-root input': {
      marginLeft: 30,
   
      color: (props) =>
        props.isMobileOrTablet
          ? '#000000 !important'
          : theme.palette.blacksAndGrays.jet,
          
          // props.isMobileOrTablet
          // ? theme.palette.tintsAndShades.blueGrey
          // : theme.palette.blacksAndGrays.jet,
          
    },
    '& .sidebar-searchBox.MuiInputBase-root input::placeholder': {
      color: (props) =>
        props.isMobileOrTablet
          ? '#000000 !important'
          : theme.palette.blacksAndGrays.stoneGray,
          // props.isMobileOrTablet
          // ? theme.palette.tintsAndShades.blueGrey
          // : theme.palette.blacksAndGrays.stoneGray,
    },
  },
  imgSearch: {
    position: 'absolute',
    top: 11,
    left: 15,
    width: 18,
    height: 18,
  },
  imgSearchWhite: {
    width: 18,
    height: 18,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '40px',
    right: 0,
    top: ' 0px',
    bottom: '0px',
    cursor: 'pointer',
    background: theme.palette.primary.light,
    borderRadius: '0px 8px 8px 0px',
    '&:hover': {
      opacity: 0.9,
    },
  },
}));

export default useStyles;
