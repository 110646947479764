import {Card, CardContent, CardMedia, Typography} from '@material-ui/core';
import useStyles from '../fitness-card.styles';
import React from 'react';
import {IResponsiveSize} from '../../../../models/IResponsiveSize';
import PlaceholderWorkout from '../../../../assets/images/workoutsimage.png';
// import lockClosedIcon from '../../../../assets/images/lock-closed.svg';

interface Props {
  recipe: any;
  onClick: any;
  containerStyle?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  name: string;
  Image: any;
  isLocked: boolean;
}
const YogaCard: React.FC<Props> = ({isMobileOrTablet, smallScreens, name, Image, onClick}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  return (
    // eslint-disable-next-line react/no-unescaped-entities
    <>
      <Card
        className={`${classes.card}`}
        style={{minHeight: 220, minWidth: isMobileOrTablet ? 140 : 300}}
        // onClick={(recipe?.tag && !recipe?.userId) ? () => myShopfiyRedirect(recipe?.buyLink) : () => slug ? onClick(slug) : {}}
        // id={recipe.id}

        onClick={onClick}
      >
        {/* { (isLocked) &&<div className={classes.lockCard} style={{cursor: 'pointer'}}><img src={lockClosedIcon} alt='' /></div>} */}
        {/* {isLoader && <Loader className={`${classes.fitnessLoader} ${classes.loaderAb}`} /> } */}
        {/* {(recipe?.tag && !recipe?.userId) && <div className={classes.lockCard} style={{cursor: 'pointer'}}><img src={lockClosedIcon} alt='' /></div>} */}
        <CardMedia
          component="img"
          height={isMobileOrTablet?.isMobile ? '82' : '148'}
          image={Image ? Image : PlaceholderWorkout}
          alt="recipe-image"
        />
        <div className={classes.infoContainer}>
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="div">
              {name}
            </Typography>
          </CardContent>
          <div className={classes.ratingContainer}>
            <div className={classes.rating}>
              {/*<img src={StarIcon} alt={'rating-icon'} width={16} />*/}
              {/*<div className={classes.ratingValue}>{recipe.rating || 0}</div>*/}
            </div>
            <div className={classes.showTime}>
              <div className={classes.ratingValue}>
                {/* {recipe?.days} {recipe?.days === 1 || recipe?.days === '1' ? 'Workout' : 'Workouts'} */}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

// yogaIntro.propTypes = {}

export default YogaCard;
