import * as React from 'react';
import useStyles from './mood-emoticon.styles';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';

interface Props {
  name: string;
  src: string;
  isScaleEmoticon: boolean;
  text?: string;
  notShowText?: boolean;
}

export const MoodEmoticon: React.FC<Props> = (props) => {
  const {name, src, text, isScaleEmoticon, notShowText} = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      {isScaleEmoticon && (
        <div className={classNames(classes.rootScaleMood)} key={name}>
          <img src={src} alt={name} width={45} height={45} />
          {!notShowText && text && (
            <Typography variant={'body2'} className={classes.emoticonTxt}>
              {text}
            </Typography>
          )}
        </div>
      )}

      {!isScaleEmoticon && (
        <div className={classNames(classes.rootMood)} key={name}>
          <img src={src} alt={name} width={24} height={24} className={classes.emoticonMargin} />
          {!notShowText && text && <Typography variant={'body2'}>{text}</Typography>}
        </div>
      )}
    </React.Fragment>
  );
};
