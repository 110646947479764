import {IWeightDto} from '../../dtos/IWeightDto';
import {ICategoryTagDto, IUserCategoryTagDto} from '../../dtos/ICategoryTagDto';
import {IFoodDto} from '../../dtos/IFoodDto';
import {IActivityDto} from '../../dtos/IActivityDto';
import {ICasualTag} from '../../models/ITag';
import {IStressDto} from '../../dtos/IStressDto';
import {ISleepDto} from '../../dtos/ISleepDto';
import {IBowelDto} from '../../dtos/IBowelDto';
import {IDrinkDto} from '../../dtos/IDrinkDto';
import {IStepsDto} from '../../dtos/IStepDto';
import {IBodyMeasurementDto} from '../../dtos/IBodyMeasurementDto';
import {IBloodPressureDto} from '../../dtos/IBloodPressureDto';
import {IBloodGlucoseDto} from '../../dtos/IBloodGlucoseDto';
import {ICholesterolDto} from '../../dtos/ICholesterolDto';
import {IA1CDto} from '../../dtos/IA1CDto';

import {IBodyPainDto} from '../../dtos/IBodyPainDto';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const selectWeightLogs = (state: any): IWeightDto[] => state.logging.loggedWeights;

export const selectFoodLogs = (state: any): IFoodDto[] => state.logging.loggedFoods;
export const selectCategoryTags = (state: any): ICategoryTagDto[] => state.logging.categoryTags;

export const selectUserTags = (state: any): IUserCategoryTagDto[] => state.logging.userTags;

export const selectActivityLogs = (state: any): IActivityDto[] => state.logging.loggedActivity;
export const selectActivityTags = (state: any): ICasualTag[] => state.logging.activityTags;

export const selectStressTags = (state: any): ICasualTag[] => state.logging.stressTags;
export const selectMoodTags = (state: any): ICasualTag[] => state.logging.moodTags;
export const selectStressLogs = (state: any): IStressDto[] => state.logging.loggedStress;

export const selectSleepTags = (state: any): ICasualTag[] => state.logging.sleepTags;
export const selectSleepLogs = (state: any): ISleepDto[] => state.logging.loggedSleep;

export const selectBowelTags = (state: any): ICasualTag[] => state.logging.bowelTags;
export const selectBowelLogs = (state: any): IBowelDto[] => state.logging.loggedBowel;

export const selectDrinkLogs = (state: any): IDrinkDto[] => state.logging.loggedDrink;

export const selectStepsLogs = (state: any): IStepsDto[] => state.logging.loggedSteps;

export const selectBodyMeasurementLogs = (state: any): IBodyMeasurementDto[] =>
  state.logging.loggedBodyMeasurements;

export const selectBloodPressureLogs = (state: any): IBloodPressureDto[] =>
  state.logging.loggedBloodPressure;

export const selectBloodGlucoseLogs = (state: any): IBloodGlucoseDto[] =>
  state.logging.loggedBloodGlucose;

export const selectCholesterolLogs = (state: any): ICholesterolDto[] =>
  state.logging.loggedCholesterol;

export const selectA1CLogs = (state: any): IA1CDto[] => state.logging.loggedA1C;

export const selectBodyPainLogs = (state: any): IBodyPainDto[] => state.logging.loggedBodyPain;
