import * as React from 'react';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';
import {TextField as MaterialTextField, useMediaQuery} from '@material-ui/core';
import SearchIcon from '../../../assets/icons/widgets/search-icon.svg';
import SearchIconWhite from '../../../assets/icons/widgets/search-icon-white.svg';
import {TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './search-box.styles';

interface Props {
  containerClass?: string;
  className?: string;
  value?: string;
  placeholder?: string;
  onChange?: any;
  name: string;
  label?: string;
  hasButton?: boolean;
  onSearchClick?: any;
  onKeyDown?: any;
  onClick?: () => void;
  autoComplete?: any;
}

export const SearchBox: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    return isMobile || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet: isMobileOrTablet,
  });
  const {onSearchClick, containerClass, value, label, ...rest} = props;

  return (
    <div
      className={classNames(containerClass, classes.textFieldContainer, !value && classes.search)}
    >
      <div className={classes.label}>{label}</div>
      <MaterialTextField
        variant="outlined"
        required={true}
        {...rest}
        value={value || ''}
        className={`${classes.input} ${rest.className || ''}`}
        onKeyUp={(e) => {
          e.key === 'Enter' && onSearchClick && onSearchClick();
        }}
        onClick={props.onClick}
      />
      {!value && <img alt={'search-icon'} src={SearchIcon} className={classes.imgSearch} />}
      {props.hasButton && value && (
        <div className={classes.button} onClick={onSearchClick} aria-hidden>
          <img alt={'search-icon'} src={SearchIconWhite} className={classes.imgSearchWhite} />
        </div>
      )}
    </div>
  );
};
