import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import * as loggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType, SelectPanelType} from '../../../../utils/enums';

import useStyles from './blood-labs-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface BloodLabsRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
  isHistoryWidget?: boolean;
}

const BloodLabsRecentlyAddedRow: React.FC<BloodLabsRecentlyAddedRowProps> = (props) => {
  const {data, isMobile, isHistoryWidget} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const [dataType, setDataType] = useState('');
  const [t] = useTranslation();

  const handleDismiss = () => {
    if (dataType === SelectPanelType.CHOLESTEROL) {
      dispatch(loggingActions.deleteCholesterolLog(data.id));
    } else {
      dispatch(loggingActions.deleteA1CLog(data.id));
    }
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };

  useEffect(() => {
    if (data.totalCholesterol) setDataType(SelectPanelType.CHOLESTEROL);
    else setDataType(SelectPanelType.A1C);
  }, []);

  const renderDataDisplay = () => {
    if (dataType === SelectPanelType.CHOLESTEROL) {
      return (
        <div className={classes.valuesText}>
          {!isHistoryWidget && (
            <Typography variant={'overline'}>
              {t('LogsPage.bloodLabsWidget.cholesterol')}
            </Typography>
          )}
          <div className={classes.valueItem}>
            <Typography variant={'subtitle1'} className={classes.label}>
              {t('LogsPage.bloodLabsWidget.cholesterolLabel')}:
            </Typography>
            <Typography variant={'subtitle1'}>
              {`  ${data.totalCholesterol} ${t('LogsPage.bloodLabsWidget.unit')}`}
            </Typography>
          </div>
          <div className={classes.valueItem}>
            <Typography variant={'subtitle1'} className={classes.label}>
              {t('LogsPage.bloodLabsWidget.nonHDLLabel')}:
            </Typography>
            <Typography variant={'subtitle1'}>
              {`  ${data.nonHdl} ${t('LogsPage.bloodLabsWidget.unit')}`}
            </Typography>
          </div>
          <div className={classes.valueItem}>
            <Typography variant={'subtitle1'} className={classes.label}>
              {t('LogsPage.bloodLabsWidget.ldlLabel')}:
            </Typography>
            <Typography variant={'subtitle1'}>
              {`  ${data.ldlValue} ${t('LogsPage.bloodLabsWidget.unit')}`}
            </Typography>
          </div>
          <div className={classes.valueItem}>
            <Typography variant={'subtitle1'} className={classes.label}>
              {t('LogsPage.bloodLabsWidget.hdlLabel')}:
            </Typography>
            <Typography variant={'subtitle1'}>
              {` ${data.hdlValue} ${t('LogsPage.bloodLabsWidget.unit')}`}
            </Typography>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.valuesText}>
        {!isHistoryWidget && (
          <Typography variant={'overline'}>{t('LogsPage.bloodLabsWidget.A1C')}</Typography>
        )}
        <div className={classes.valueItem}>
          <Typography variant={'subtitle1'} className={classes.label}>
            {t('LogsPage.bloodLabsWidget.testValue')}:
          </Typography>
          <Typography variant={'subtitle1'}>{` ${data.value}%`}</Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!isHistoryWidget && <div className={commonClasses.line} />}
      <div className={commonClasses.dataContainer}>
        <div className={!isMobile ? commonClasses.infoContainer : ''}>
          {!isHistoryWidget && <DateTimeString date={data.date} isMobile={isMobile} />}
          {renderDataDisplay()}
        </div>
        {!isHistoryWidget && (
          <div className={commonClasses.buttonsContainer}>
            <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
          </div>
        )}
      </div>
    </div>
  );
};
export default BloodLabsRecentlyAddedRow;
