import * as React from 'react';
import {useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import {ConnectionsItem} from './ConnectionsItem';
import {IConversations} from '../../../models/IConversations';
import {IUser} from '../../../models/IUser';
import ConnectionsInactiveItemIcon from '../../../assets/icons/side-menu-bar/connections-inactive-icon.svg';
import {
  selectAllUsers,
  selectCurrentUser,
  selectCoachUsers,
} from '../../../store/selectors/users.selectors';
import {selectCoach} from '../../../store/selectors/homepage.selectors';
// import {findConversation} from '../../../utils/conversations-helpers';
import {TABLET_BIG_SIZE, MOBILE_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';
import {IConversationDto} from '../../../dtos/IConversationDto';

import useStyles from './connections.styles';

interface Props {
  connections?: IConversations;
  members?: IUser[];
  coachConnections?: IConversations;
  title: string;
  length?: number;
  users: IUser[];
}

/*eslint-disable @typescript-eslint/no-empty-function*/
export const ConnectionsGroup: React.FC<Props> = ({
  connections,
  members,
  coachConnections,
  users,
  length,
  title,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const selectedCoach = useSelector(selectCoach);
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
  });
  const allUsers = useSelector(selectAllUsers);
  const allCoachMembers = useSelector(selectCoachUsers);

  const calculateLength = () => {
    const filteredConv: IConversationDto[] = [];

    if (connections && connections.conversations?.length > 0) {
      connections.conversations.filter((oneConversation: IConversationDto) => {
        allUsers.forEach((user) => {
          if (
            user.id === oneConversation.conversationName &&
            oneConversation.conversationName != currentUser.id
          )
            filteredConv.push(oneConversation);
        });
      });
    }

    return filteredConv?.length;
  };

  const calculateCoachMembersLength = () => {
    const filteredConv: IConversationDto[] = [];

    if (coachConnections && coachConnections.conversations?.length > 0) {
      coachConnections.conversations.filter((oneConversation: IConversationDto) => {
        allCoachMembers.forEach((user) => {
          if (
            user.id === oneConversation.conversationName &&
            oneConversation.conversationName != currentUser.id
          )
            filteredConv.push(oneConversation);
        });
      });
    }
    return filteredConv?.length;
  };

  return (
    <React.Fragment>
      {(length
        ? length > 0
        : (coachConnections ? calculateCoachMembersLength() : calculateLength()) > 0) && (
        <Typography variant={'overline'} className={classes.groupTitle}>
          {title}(
          {members ? length : coachConnections ? calculateCoachMembersLength() : calculateLength()})
        </Typography>
      )}
      {connections &&
        connections.conversations?.length > 0 &&
        connections?.conversations.map((oneConversation: IConversationDto) => {
          return oneConversation.conversationName !== currentUser.id ? (
            <ConnectionsItem
              inactiveIcon={ConnectionsInactiveItemIcon}
              key={oneConversation.conversationId + Math.random().toString()}
              memberId={oneConversation.conversationName}
              conversation={oneConversation}
              users={users}
            />
          ) : (
            <React.Fragment key={Math.random().toString()} />
          );
        })}
      {coachConnections &&
        coachConnections.conversations?.length > 0 &&
        coachConnections?.conversations?.map((oneConversation: IConversationDto) => {
          return oneConversation.conversationName !== selectedCoach.id ? (
            <ConnectionsItem
              inactiveIcon={ConnectionsInactiveItemIcon}
              key={oneConversation.conversationId + Math.random().toString()}
              memberId={oneConversation.conversationName}
              conversation={oneConversation}
              users={users}
            />
          ) : (
            <React.Fragment key={Math.random().toString()} />
          );
        })}
      {members &&
        members?.length > 0 &&
        members?.map((user: IUser) => {
          return user.id != currentUser.id ? (
            <ConnectionsItem
              inactiveIcon={ConnectionsInactiveItemIcon}
              key={user.id + Math.random().toString()}
              memberId={user.id}
              // conversation={findConversation(user.id)}
              memberSearch
              users={users}
            />
          ) : (
            <React.Fragment key={Math.random().toString()} />
          );
        })}
    </React.Fragment>
  );
};
