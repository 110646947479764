import * as React from 'react';
import useStyles from './podcastPlayer-style';
import {Typography} from '@material-ui/core';

interface Props {
  Content: any;
  isMobileOrTablet: any;
  smallScreens: any;
}

export const PodcastPlayerView: React.FC<Props> = ({isMobileOrTablet, smallScreens, Content}) => {
  const urlRegex = /src=["'](.*?)["']/;
  const [url, setUrl] = React.useState();
  React.useEffect(() => {
    const match = Content?.description?.match(urlRegex);
    const urls = match ? match[1] : '';
    setUrl(urls);
  }, [Content]);

  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });

  return (
    <div className={classes.recipesContainer}>
      <div className={classes.mainContainer}>
        <img
          src={Content?.image?.image?.formats?.large?.url}
          style={{aspectRatio: 'auto', width: '100%', height: 'auto'}}
          alt={Content?.image?.image?.name}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 300,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '60%',
            }}
          >
            <Typography align="left" variant={'h4'} style={{fontWeight: 'bold', marginTop: 15}}>
              {Content?.podcastSeries}
            </Typography>
            <Typography variant={'caption'} style={{marginTop: 25, paddingLeft:4}}>
              {Content?.title}
            </Typography>

            <Typography variant={'h6'} style={{marginTop: 25}}>
              {url && (
                <iframe
                  title="Iframe Player"
                  width="100%"
                  height="180"
                  frameBorder="no"
                  scrolling="no"
                  seamless
                  src={url}
                ></iframe>
              )}
            </Typography>
            <Typography variant={'caption'} style={{marginTop: 25, paddingBottom:30, paddingLeft:4}}>
              {Content?.episode}
            </Typography>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // textAlign: 'center',
            // marginTop: '40%',
          }}
        >
          
        </div>
      </div>
      <></>
    </div>
  );
};
