import * as React from 'react';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Paper, useMediaQuery} from '@material-ui/core';
import classNames from 'classnames';
import {MOBILE_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';

import useStyles from './paper-wrapper.styles';

interface PaperWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const PaperWrapper: React.FC<PaperWrapperProps> = ({children, className}) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    return isMobileOnly || isMobileSize || isTablet || isTabletSmallSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
  });

  return (
    <Paper className={classNames(classes.root, className)} elevation={0} tabIndex={0}>
      {children}
    </Paper>
  );
};

PaperWrapper.displayName = 'PaperWrapper';
