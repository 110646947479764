import {makeStyles} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

const SIDE_WIDTH = '302px';
const CENTER_WIDTH = '628px';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  recipesContainer: {
    position: 'relative',
    padding: (props) =>
      props.isMobileOrTablet.isMobile
        ? '6px 16px'
        : props.isMobileOrTablet.isTabletSmall
        ? '20px 32px'
        : props.isMobileOrTablet.isTabletBig
        ? '20px 92px'
        : 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    position: 'relative',
    '& > div': {
      display: 'flex',
    },
  },
  back: {
    cursor: 'pointer',
    padding: 0,
    color: (props) =>
      props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? theme.palette.background.paper
        : theme.palette.blacksAndWhites.jet,
    '& img': {
      marginRight: 8,
      filter: (props) =>
        props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
          ? 'invert(1)'
          : 'invert(0)',
    },
    '& .MuiTypography-root': {
      letterSpacing: '1.25px',
      fontWeight: 500,
    },
  },
  shareAndPrint: {
    '& > button': {
      padding: 0,
      minWidth: '30px',
      marginRight: 0,
      marginBottom: 0,
    },
    '& > button svg': {
      height: '24px',
      width: 'auto',
    },
    '& > button svg path': {
      fill: (props: any) =>
        props.isMobileOrTablet.isMobile
          ? theme.palette.blacksAndWhites.jet
          : theme.palette.primary.main,
    },
  },
  mainContainer: {
    justifyContent: 'center',
    display: (props) => (props.smallScreens ? 'block' : 'flex'),
    columnGap: '26px',
  },
  noData: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  sideContent1: {
    minWidth: SIDE_WIDTH,
    [theme.breakpoints.down(992)]: {
      minWidth: 0,
    },
  },
  sideContent2: {
    maxWidth: SIDE_WIDTH,
    paddingTop: (props) => (props.smallScreens ? '0' : '236px'),
  },
  firstSectionTablet: {
    display: 'flex',
    marginTop: 50,
    justifyContent: 'space-between',
    '& > div:nth-child(1)': {
      paddingRight: '10%',
    },
    '& > div:nth-child(2)': {
      width: '250px',
    },
  },
  centerContent: {
    maxWidth: (props) => (props.smallScreens ? '100%' : CENTER_WIDTH),
    '& section': {
      margin: (props) => (props.smallScreens ? '20px 0 0 0' : '40px auto'),
      '& h3': {
        position: 'relative',
        zIndex: 1,
        fontSize: (props) => (props.isMobileOrTablet.isMobile ? '24px' : '36px'),
        lineHeight: (props) => (props.isMobileOrTablet.isMobile ? '28px' : '40px'),
        color: (props) =>
          props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
            ? theme.palette.background.paper
            : theme.palette.blacksAndWhites.jet,
        marginTop: (props) =>
          props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
            ? '225px'
            : '0',
      },
    },
    '& .MuiTypography-h5': {
      fontSize: (props) => (props.isMobileOrTablet.isMobile ? '16px' : '24px'),
      lineHeight: (props) => (props.isMobileOrTablet.isMobile ? '20px' : '28px'),
    },
  },
  levelFlag: {
    right: (props) =>
      props.isMobileOrTablet.isMobile
        ? 'calc( 100% - 67px) !important'
        : props.isMobileOrTablet.isTabletSmall
        ? '32px !important'
        : props.isMobileOrTablet.isTabletBig
        ? '92px !important'
        : '',
    zIndex: 1,
  },
  descText: {
    color: theme.palette.blacksAndGrays.stoneGray,
    margin: '16px 0',
    display: 'block',
    minHeight: 40,
  },
  timeLabel: {
    color: theme.palette.blacksAndGrays.stoneGray,
    marginBottom: '3px',
    display: 'block',
  },
  timeContainer: {
    display: 'flex',
    position: 'relative',
  },
  timeValue: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      marginRight: '5px',
    },
    fontSize: '14px',
    color: theme.palette.blacksAndGrays.davysGray,
  },
  timeBox: {
    marginRight: '32px',
    marginBottom: '18px',
  },
  author: {
    marginBottom: '16px',
    marginTop: '8px',
    display: 'flex',
    zIndex: 1,
    position: 'relative',
  },
  authorName: {
    color: (props) =>
      props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? theme.palette.background.paper
        : theme.palette.blacksAndWhites.sonicSilver,
  },
  mealImage: {
    height: (props) =>
      props.isMobileOrTablet.isMobile
        ? 179
        : props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? 354
        : 303,
    minWidth: (props) => (props.smallScreens ? '100%' : 628),
    marginLeft: (props) => (props.isMobileOrTablet.isMobile ? '-16px' : 0),
    marginRight: (props) => (props.isMobileOrTablet.isMobile ? '-16px' : 0),
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: (props) =>
      props.isMobileOrTablet.isTabletSmall || props.isMobileOrTablet.isTabletBig
        ? 'absolute'
        : 'relative',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
  },
  ingredientTags: {
    display: (props) => (props.smallScreens ? ' block' : 'flex'),
    alignItems: 'center',
    marginTop: (props) => (props.smallScreens ? 0 : '16px'),
    flexWrap: 'wrap',
    '& > .MuiTypography-root': {
      marginTop: '8px',
      marginRight: '10px',
    },
  },
  ingredientTagList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ingredientsSection: {
    position: 'relative',
    '& ul': {
      paddingLeft: '25px',
    },
    '& li': {
      padding: '2px 0',
    },
  },
  linksToSections: {
    display: 'flex',
    marginTop: '24px',
    '& button:nth-child(odd)': {
      marginRight: 7,
    },
    '& button:nth-child(even)': {
      marginLeft: 7,
    },
  },
  instructionsSection: {
    '& ul': {
      paddingLeft: '25px',
    },
    '& li': {
      listStyleType: 'decimal',
    },
  },
  notesSection: {
    '& > .MuiTypography-body1': {
      marginTop: '16px',
    },
    '& ol, & ul': {
      paddingLeft: '25px',
    },
    '& li': {
      padding: '2px 0',
    },
    '& img': {
      width: (props) => (props.smallScreens ? '100%' : 628),
      height: (props) => (props.isMobileOrTablet.isMobile ? 179 : 303),
    },
  },
  infoBox: {
    margin: 0,
    border: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    borderRadius: '12px',
    padding: '0px 15px',
  },
  infoBoxRow: {
    paddingBottom: '12px',
    marginTop: '12px',
    borderBottom: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    '& .MuiTypography-body1': {
      color: theme.palette.secondary.dark,
      marginBottom: '2px',
    },
    '& .MuiTypography-subtitle1': {
      color: theme.palette.blacksAndWhites.davysGray,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  equipment: {
    border: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
    borderRadius: '12px',
    padding: '15px',
    margin: '50px 20px 0 20px',
    '& .MuiTypography-subtitle1': {
      color: theme.palette.blacksAndWhites.davysGray,
      borderBottom: '1px solid black',
      paddingBottom: '8px',
      marginBottom: '8px',
    },
    '& .MuiTypography-caption': {
      display: 'block',
      padding: '8px 0',
      borderBottom: `1px solid ${theme.palette.blacksAndWhites.gainsboro}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  shoppingList: {
    margin: '40px 20px',
  },
  ratingContainer: {
    margin: (props) => (props.smallScreens ? '0 0 24px 0' : '250px 0px 50px 0px'),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: (props) => (props.smallScreens ? '24px' : '50px'),
    '& button:nth-child(odd)': {
      marginRight: 10,
    },
    '& button:nth-child(even)': {
      marginLeft: 10,
    },
  },
  button: {
    width: '117.5px',
    height: '36px',
    padding: '0px 16px',
    [theme.breakpoints.down(1200)]: {
      width: '100%',
      marginBottom: '20px',
    },
    '&:disabled': {
      color: theme.palette.blacksAndGrays.gainsboro,
    },
  },
  comments: {
    '& .MuiTextField-root': {
      marginTop: '24px',
      marginBottom: '32px',
      marginRight: 0,
      width: '100%',
    },
    '& textarea.MuiInputBase-input': {
      height: '100px !important',
    },
    '& > div': {
      marginRight: 0,
    },
    '& button': {
      float: 'right',
    },
  },
}));

export default useStyles;
