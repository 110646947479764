import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import A1CSubform from './A1CSubform';
import CholesterolSubform from './CholesterolSubform';
import {PaperWrapper} from '../../paper-wrapper/PaperWrapper';
import {Button} from '../../button/Button';
import {DatePicker} from '../../pickers/DatePicker';
import {TimePicker} from '../../pickers/TimePicker';
import {Select} from '../../select/Select';
import {IKeyValuePair} from '../../../../models/IKeyValuePair';
import BloodGlucoseIcon from '../../../../assets/icons/side-menu-bar-logging/blood-glucose-icon.svg';
import {selectCurrentUser} from '../../../../store/selectors/users.selectors';
import {
  addA1CLog,
  editA1CLog,
  addCholesterolLog,
  editCholesterolLog,
} from '../../../../store/actions/logging-page/logging-page-action';
import {ValidationType} from '../../../../validation/ValidationType';
import {
  getError,
  hasError,
  isFormValidToSubmit,
  validate,
  validateField,
} from '../../../../validation/Validation';
import {
  dateTimeCombined,
  isDateSameOrBeforeCurrentDateTime,
  stringToDateTimeString,
} from '../../../../utils/date-time-utils';
import {FormType, SelectPanelType, WidgetType} from '../../../../utils/enums';
import {DATE_PICKER_DATE_FORMAT} from '../../../../utils/constants';

import useStyles from './blood-labs-form.styles';
import useCommonStyles from '../common-for-widgets.styles';

interface IBloodLabsFormData {
  date: string;
  time: string;
  panelTypeObject: IKeyValuePair;
  panelType?: string;
}
interface IBloodLabsFormProps {
  changeState: (widgetType: WidgetType, formMode: FormType) => void;
  formType?: FormType;
  data?: any;
  isMobile: boolean;
}

const validationRules = {
  panelType: [{type: ValidationType.REQUIRED}],
  date: [{type: ValidationType.REQUIRED}],
  time: [{type: ValidationType.REQUIRED_DURATION}],
};

const validationRulesCholesterol = {
  totalCholesterolValue: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_DECIMAL_NUMBER,
      minValue: 10,
      maxValue: 300,
      value: '2',
      errorMessage: `${i18n.t('ErrorMessages.notInRangeValue')} 10-300`,
    },
  ],
  nonHdl: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_DECIMAL_NUMBER,
      minValue: 40,
      maxValue: 600,
      value: '2',
      errorMessage: `${i18n.t('ErrorMessages.notInRangeValue')} 40-600`,
    },
  ],
  ldl: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_DECIMAL_NUMBER,
      minValue: 10,
      maxValue: 250,
      value: '2',
      errorMessage: `${i18n.t('ErrorMessages.notInRangeValue')} 10-250`,
    },
  ],
  hdl: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_DECIMAL_NUMBER,
      minValue: 10,
      maxValue: 250,
      value: '2',
      errorMessage: `${i18n.t('ErrorMessages.notInRangeValue')} 10-250`,
    },
  ],
};

const validationRulesA1C = {
  a1CValue: [
    {type: ValidationType.REQUIRED},
    {
      type: ValidationType.IS_DECIMAL_NUMBER,
      minValue: 0.1,
      maxValue: 12,
      value: '2',
      errorMessage: `${i18n.t('ErrorMessages.notInRangeValue')} 0.1-12`,
    },
  ],
};

const BloodLabsForm: React.FC<IBloodLabsFormProps> = (props) => {
  const classes = useStyles({
    isMobile: props.isMobile,
  });
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [errors, setErrors] = useState<any>({});
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const panelTypes = Object.values(SelectPanelType);
  const [data, setData] = useState<IBloodLabsFormData>({
    date: moment().toString(),
    time: moment().toString(),
    panelTypeObject: {key: '', value: ''},
    panelType: '',
  });
  const [subformData, setSubformData] = useState({
    totalCholesterolValue: '',
    nonHdl: '',
    hdl: '',
    ldl: '',
    a1CValue: '',
  });
  const formType = props.formType || FormType.ADDING_MODE;

  const validateFormField = (data: any, field: string, validationRules: any) => {
    const err = validateField(data, validationRules, field);
    setErrors({
      ...errors,
      [field]: err,
    });
  };

  const validateToSubmit = () => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      return false;
    }
    const err = validate(data, validationRules);
    const errSubform = validate(
      subformData,
      data.panelType === SelectPanelType.CHOLESTEROL
        ? validationRulesCholesterol
        : validationRulesA1C,
    );
    return isFormValidToSubmit(err) && isFormValidToSubmit(errSubform);
  };

  const changeSpecificDataType = (value: string, specificType: string) => {
    setData({
      ...data,
      [specificType]: value,
    });
  };

  useEffect(() => {
    let initialObject: IBloodLabsFormData = {
      date: moment().toString(),
      time: moment().toString(),
      panelTypeObject: {key: '', value: ''},
      panelType: '',
    };
    if (formType == FormType.EDITING_MODE) {
      initialObject = {
        panelType: props.data.totalCholesterol ? SelectPanelType.CHOLESTEROL : SelectPanelType.A1C,
        panelTypeObject: props.data.totalCholesterol
          ? {
              key: SelectPanelType.CHOLESTEROL,
              value: SelectPanelType.CHOLESTEROL,
            }
          : {
              key: SelectPanelType.A1C,
              value: SelectPanelType.A1C,
            },
        date: stringToDateTimeString(props.data?.date || ''),
        time: stringToDateTimeString(props.data?.date || ''),
      };
    }

    const subformInitialObject = {
      totalCholesterolValue: props.data?.totalCholesterol || '',
      nonHdl: props.data?.nonHdl || '',
      ldl: props.data?.ldlValue || '',
      hdl: props.data?.hdlValue || '',
      a1CValue: props.data?.value || '',
    };

    setData(initialObject);
    setSubformData(subformInitialObject);
  }, [props.data]);

  useEffect(() => {
    const selectedDate = dateTimeCombined(data.date, data.time);
    if (!isDateSameOrBeforeCurrentDateTime(selectedDate)) {
      setErrors({
        ...errors,
        time: [{message: t('ErrorMessages.timeAfterCurrent')}],
      });
    } else {
      if (errors['time']?.length > 0) {
        setErrors({
          ...errors,
          time: [],
        });
      }
    }
  }, [data.date, data.time]);

  const clearForm = () => {
    setData({
      date: moment().toString(),
      time: moment().toString(),
      panelTypeObject: {key: '', value: ''},
      panelType: '',
    });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'panelType') {
      setData({
        ...data,
        ['panelTypeObject']: {key: value.key, value: value.value},
        [name]: value.key,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const getFloatFromString = (data: any) => {
    if (data === '' || !data) return 0;
    return parseFloat(data);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const selectedDate = dateTimeCombined(data.date, data.time);
      let bloodLabsLog;
      let res: any;
      if (data.panelType === SelectPanelType.CHOLESTEROL) {
        bloodLabsLog = {
          id: props.data?.id || undefined,
          userId: currentUser.id,
          date: selectedDate,
          totalCholesterol: getFloatFromString(subformData?.totalCholesterolValue),
          nonHdl: getFloatFromString(subformData?.nonHdl),
          ldlValue: getFloatFromString(subformData?.ldl),
          hdlValue: getFloatFromString(subformData?.hdl),
        };

        if (!props.data) {
          res = await dispatch(addCholesterolLog(bloodLabsLog));
        } else {
          res = await dispatch(editCholesterolLog(bloodLabsLog));
        }
      } else {
        bloodLabsLog = {
          id: props.data?.id || undefined,
          userId: currentUser.id,
          date: selectedDate,
          value: getFloatFromString(subformData?.a1CValue),
        };
        if (!props.data) {
          res = await dispatch(addA1CLog(bloodLabsLog));
        } else {
          res = await dispatch(editA1CLog(bloodLabsLog));
        }
      }
      if (res) {
        clearForm();
        props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    setErrors({});
    props.changeState(WidgetType.FORM_MODE, FormType.ADDING_MODE);
  };

  const changeSubformData = (data: any) => {
    setSubformData(data);
  };

  const renderSubforms = () => {
    switch (data.panelType) {
      case SelectPanelType.A1C:
        return (
          <A1CSubform
            data={props.data?.value || ''}
            changeData={changeSubformData}
            validateFormField={validateFormField}
            errors={errors}
            validationRulesA1C={validationRulesA1C}
            isMobile={props.isMobile}
          />
        );
      case SelectPanelType.CHOLESTEROL:
        return (
          <CholesterolSubform
            data={{
              totalCholesterolValue: props.data?.totalCholesterol || '',
              nonHdl: props.data?.nonHdl || '',
              ldl: props.data?.ldlValue || '',
              hdl: props.data?.hdlValue || '',
            }}
            changeData={changeSubformData}
            validateFormField={validateFormField}
            errors={errors}
            validationRulesCholesterol={validationRulesCholesterol}
            isMobile={props.isMobile}
          />
        );
      default:
        return <React.Fragment />;
    }
  };

  return (
    <PaperWrapper>
      <div className={commonClasses.headerContainer}>
        <div className={commonClasses.headerTextImageContainer}>
          <img
            className={commonClasses.imageMargin}
            src={BloodGlucoseIcon}
            alt={'blood-glucose-icon'}
          />
          <Typography variant={'subtitle2'}>
            {formType === FormType.EDITING_MODE
              ? t('LogsPage.bloodLabsWidget.formTitleEdit')
              : t('LogsPage.bloodLabsWidget.formTitle')}
          </Typography>
        </div>
      </div>

      <div className={commonClasses.formRowContainer}>
        <DatePicker
          name={'date'}
          label={t('LogsPage.bloodLabsWidget.dateLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'date')}
          value={data.date}
          required={true}
          format={DATE_PICKER_DATE_FORMAT}
          error={hasError(errors, 'date')}
          helperText={getError(errors, 'date')}
          className={commonClasses.datePicker}
          maxDate={moment()}
        />
        <TimePicker
          name={'time'}
          value={data.time}
          error={hasError(errors, 'time')}
          helperText={getError(errors, 'time')}
          label={t('LogsPage.bloodLabsWidget.timeLabel')}
          onChange={(value: string) => changeSpecificDataType(value, 'time')}
          required={true}
          className={commonClasses.timePicker}
        />
      </div>
      <div className={commonClasses.centerContainer}>
        <Select
          name={'panelType'}
          label={t('LogsPage.bloodLabsWidget.selectLabel')}
          required={true}
          disabled={formType == FormType.EDITING_MODE}
          value={data.panelTypeObject}
          onChange={handleChange}
          className={classes.sourceSelect}
          error={hasError(errors, 'panelType')}
          helperText={getError(errors, 'panelType')}
          onBlur={() => {
            validateFormField(data, 'panelType', validationRules);
          }}
          options={panelTypes.map((item: any) => ({key: item, value: item}))}
        />
      </div>
      <div className={classes.subForms}>{renderSubforms()}</div>
      <div className={commonClasses.buttonRowContainer}>
        {formType == FormType.EDITING_MODE && (
          <Button
            id={'cancel-button-blood-labs-form'}
            variant={'outlined'}
            className={commonClasses.outlinedBtn}
            onClick={handleCancel}
          >
            {t('Homepage.quickProfileUser.cancelButton')}
          </Button>
        )}
        <Button
          id="submit-blood-labs-form-id"
          variant="contained"
          onClick={handleSubmit}
          disabled={!validateToSubmit() || isLoading}
          loading={isLoading}
        >
          {t('LogsPage.bloodLabsWidget.submitButton')}
        </Button>
      </div>
    </PaperWrapper>
  );
};

export default BloodLabsForm;
