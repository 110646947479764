import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  settingsContainer: {
    transition: '.5s',
  },
  settingsPanelBackground: {
    zIndex: 12200000000,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.24)',
  },
  settingsPanelContent: {
    zIndex: 12200000001,
    position: 'fixed',
    top: 'auto',
    right: 0,
    bottom: 0,
    left: 0,
    background: theme.palette.background.default,
    padding: 16,
    borderRadius: '12px 12px 0px 0px',
    '& button': {
      display: 'flex',
      color: theme.palette.uicolors.fireOpal,
      fontSize: '16px',
      lineHeight: '20px',
      background: 'transparent',
      border: 'none',
      letterSpacing: '0.5px',
      '& img': {
        marginRight: 13,
      },
      '& div': {
        paddingTop: 3,
      },
    },
    '& a': {
      display: 'block',
      textDecoration: 'none',
      color: theme.palette.blacksAndWhites.smokyBlack,
      // fontFamily: "Gantari, sans-serif",
      fontFamily: "Gantari, sans-serif",
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '24px',
      fontWeight: 400,
      letterSpacing: '0.5px',
    },
  },
  settingsPanelLine: {
    height: '4px',
    width: '64px',
    background: theme.palette.blacksAndGrays.jet,
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    marginBottom: 44,
  },
}));

export default useStyles;
