import React, {Fragment, useEffect, useState} from 'react';
import useStyles from './onboarding-page.styles';
import {useLocation, useParams} from 'react-router';
import {IUser} from '../../../models/IUser';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import history from '../../../history';
import {getUserHomePath} from '../../../routes';
import {LinearProgress, Paper, Typography} from '@material-ui/core';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import {Gender, OnBoardingSteps, OnBoardingTypes, Unit} from '../../../utils/enums';
import WelcomeOnBoarding from '../../../common/components/onboarding/WelcomeOnBoarding';
import ProfileInfoOnBoarding from '../../../common/components/onboarding/ProfileInfoOnBoarding';
import {
  IEncodedDataUser,
  IOnBoardingUser,
  IOnBoardingUserSolera,
  IOnBoardingUserWellVolution,
} from '../../../models/IOnBoardingUser';
import PasswordOnBoarding from '../../../common/components/onboarding/PasswordOnBoarding';
import UnitSystemOnBoarding from '../../../common/components/onboarding/UnitSystemOnBoarding';
import {
  getTokenInfoWalgreens,
  getUserInfoWalgreens,
} from '../../../api/on-boarding-walgreens-users.api';
import Loader from '../../../common/components/loader/Loader';
import HeightAndWeightImperialOnBoarding from '../../../common/components/onboarding/HeightAndWeightImperialOnBoarding';
import HeightAndWeightMetricOnBoarding from '../../../common/components/onboarding/HeightAndWeightMetricOnBoarding';
import {addUserOnBoarding} from '../../../api/users.api';
import {dateToString} from '../../../utils/date-time-utils';
import {ONLY_DATE_FORMAT} from '../../../utils/constants';
import {parseKeyValuePairsUrl} from '../../../utils/link-helpers';
import BookIntroCallOnBoarding from '../../../common/components/onboarding/BookIntroCallOnBoarding';
import BackIcon from '../../../assets/icons/on-boarding/back-on-boarding-icon.svg';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {calculateHeightToSend} from '../../../utils/helpers';
import {
  getTokenInfoWellvolution,
  getUserInfoWellvolution,
} from '../../../api/on-boarding-wellvolution-users.api';

type RouteParamProps = {
  typeId: string;
};

/*eslint-disable @typescript-eslint/no-empty-function*/
const OnBoardingPage: React.FC = () => {
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const search = useLocation();
  const currentUser: IUser = useSelector(selectCurrentUser);
  const [onBoardingStep, setOnBoardingStep] = useState(OnBoardingSteps.WELCOME); //WELCOME //BOOK_INTRO_CALL
  const [onBoardingUser, setOnBoardingUser] = useState<IOnBoardingUser | any>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<''>();
  const params: RouteParamProps = useParams();
  const [progressValue, setProgressValue] = useState(0);
  const [t] = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      if (currentUser) {
        history.push(getUserHomePath(currentUser));
      }

      try {
        setLoading(true);
        const type = params.typeId;
        const onBoardingType = +type;

        if (type == OnBoardingTypes.WALGREENS) {
          const parsedObject = parseKeyValuePairsUrl(search.hash);
          const code = parsedObject.code;
          const state = parsedObject.state;

          if (!code || !state) {
            return;
          }

          const responseToken = await getTokenInfoWalgreens(code);
          const responseUserInfo = await getUserInfoWalgreens(responseToken.data.id_token);

          const userInfo = responseUserInfo.data;
          const decodedData: IEncodedDataUser = JSON.parse(atob(state));

          setOnBoardingUser({
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            dateOfBirth: dateToString(new Date(userInfo.dob), ONLY_DATE_FORMAT), //'09-16-1960',
            gender: '',
            email: userInfo.email,
            phoneNumber: userInfo.phone,
            oid: userInfo.oid,
            unitSystem: Unit.IMPERIAL,
            height: 0,
            weight: 0,
            unitId: 1,
            encodedData: decodedData,
            type: onBoardingType,
          });
        }

        if (type == OnBoardingTypes.SOLERA) {
          const encodedValue = query.get('value');
          const decodedDataSolera: IOnBoardingUserSolera = JSON.parse(atob(encodedValue || ''));
          // console.log('SOLERA', decodedDataSolera);

          let gender = '';
          if (decodedDataSolera.Gender.toUpperCase() === 'M') {
            gender = Gender.MALE;
          }
          if (decodedDataSolera.Gender.toUpperCase() === 'F') {
            gender = Gender.FEMALE;
          }

          const dateOfBirthMoment = moment(decodedDataSolera.Birthdate, 'YYYYMMDD');

          setOnBoardingUser({
            firstName: decodedDataSolera.First_Name,
            lastName: decodedDataSolera.Last_Name,
            dateOfBirth: dateToString(dateOfBirthMoment.toString()), //'09-16-1960',
            gender: gender,
            email: decodedDataSolera.Email,
            phoneNumber: decodedDataSolera.Cell_Num,
            // oid: decodedDataSolera.oid,
            unitSystem: Unit.IMPERIAL,
            height: decodedDataSolera.Height,
            weight: decodedDataSolera.Weight,
            unitId: 1,
            encodedData: {
              H3UserId: decodedDataSolera.Solera_ID,
              H3PayorId: decodedDataSolera.Payer_ID,
              H3ProgramId: '',
              H3SupplierId: decodedDataSolera.Partner_ID,
            },
            type: onBoardingType,
          });
        }

        if (type == OnBoardingTypes.WELLVOLUTION) {
          // const encodedValue = query.get('value');
          // const decodedDataWellvolution: IOnBoardingUserWellVolution = JSON.parse(
          //   atob(encodedValue || ''),
          // );
          // console.log('WELLVOLUTION', decodedDataWellvolution);

          const responseTokenInfo = await getTokenInfoWellvolution();
          // eslint-disable-next-line no-console
          // console.log({responseTokenInfo});
          const response = await getUserInfoWellvolution(
            responseTokenInfo.access_token,
            'b6b3ddf51cbe43f2af84f614',
          );
          // eslint-disable-next-line no-console
          // console.log('WELLVOLUTION', response);
          const decodedDataWellvolution: IOnBoardingUserWellVolution = response;

          let gender = '';
          if (decodedDataWellvolution.gender.toUpperCase() === 'MALE') {
            gender = Gender.MALE;
          }
          if (decodedDataWellvolution.gender.toUpperCase() === 'FEMALE') {
            gender = Gender.FEMALE;
          }

          const dateOfBirthMoment = moment(decodedDataWellvolution.birthdate, 'MM/DD/YYYY');

          setOnBoardingUser({
            firstName: decodedDataWellvolution.given_name,
            lastName: decodedDataWellvolution.family_name,
            dateOfBirth: dateToString(dateOfBirthMoment.toString()), //'09-16-1960',
            gender: gender,
            email: decodedDataWellvolution.email,
            phoneNumber: decodedDataWellvolution.phone_number,
            // oid: decodedDataWellvolution.oid,
            unitSystem: Unit.IMPERIAL,
            height: calculateHeightToSend(
              decodedDataWellvolution.heightFeet.toString(),
              decodedDataWellvolution.heightInches.toString(),
              1,
            ),
            weight: decodedDataWellvolution.weight,
            unitId: 1,
            encodedData: {
              H3UserId: decodedDataWellvolution.enrollmentId,
              H3PayorId: '',
              H3ProgramId: decodedDataWellvolution.programId,
              H3SupplierId: '',
            },
            type: onBoardingType,
          });
        }

        if (type == OnBoardingTypes.CONNECT) {
          // const encodedValue = query.get('value');
          // const decodedDataConnect: IOnBoardingUserWellVolution = JSON.parse(
          //   atob(encodedValue || ''),
          // );
          // console.log('CONNECT', decodedDataConnect);
          // let gender = '';
          // if (decodedDataWellvolution.gender.toUpperCase() === 'MALE') {
          //   gender = Gender.MALE;
          // }
          // if (decodedDataWellvolution.gender.toUpperCase() === 'FEMALE') {
          //   gender = Gender.FEMALE;
          // }
          //
          // const dateOfBirthMoment = moment(decodedDataWellvolution.birthdate, 'MM/DD/YYYY');
          //
          // setOnBoardingUser({
          //   firstName: decodedDataWellvolution.given_name,
          //   lastName: decodedDataWellvolution.family_name,
          //   dateOfBirth: dateToString(dateOfBirthMoment.toString()), //'09-16-1960',
          //   gender: gender,
          //   email: decodedDataWellvolution.email,
          //   phoneNumber: decodedDataWellvolution.phone_number,
          //   // oid: decodedDataWellvolution.oid,
          //   unitSystem: Unit.IMPERIAL,
          //   height: calculateHeightToSend(
          //       decodedDataWellvolution.heightFeet.toString(),
          //       decodedDataWellvolution.heightInches.toString(),
          //       1,
          //   ),
          //   weight: decodedDataWellvolution.weight,
          //   unitId: 1,
          //   encodedData: {
          //     H3UserId: decodedDataWellvolution.enrollmentId,
          //     H3PayorId: '',
          //     H3ProgramId: decodedDataWellvolution.programId,
          //     H3SupplierId: '',
          //   },
          //   type: onBoardingType,
          // });
        }

        // else {
        //   //redirectToWalgreens();
        // }
      } catch (err: any) {
        setError(err.message);
        //redirectToWalgreens();
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [currentUser, history]);

  const onClickOnContinue = (step: OnBoardingSteps) => {
    setProgressValue(progressValue + 1);
    setOnBoardingStep(step);
  };

  const onUpdateUser = (user: IOnBoardingUser) => {
    setOnBoardingUser(user);
  };

  const finishOnBoarding = async (): Promise<any> => {
    return await addUserOnBoarding(onBoardingUser);
  };

  const renderContent = () => {
    if (error) {
      return <div>{error}</div>;
    }

    switch (onBoardingStep) {
      case OnBoardingSteps.WELCOME:
        return (
          <WelcomeOnBoarding name={onBoardingUser?.firstName} onContinueClick={onClickOnContinue} />
        );

      case OnBoardingSteps.PROFILE_INFO:
        return (
          <ProfileInfoOnBoarding
            userData={onBoardingUser}
            onContinueClick={onClickOnContinue}
            onUpdateUser={onUpdateUser}
          />
        );

      case OnBoardingSteps.PASSWORD:
        return (
          <PasswordOnBoarding
            userData={onBoardingUser}
            onContinueClick={onClickOnContinue}
            onUpdateUser={onUpdateUser}
          />
        );

      case OnBoardingSteps.UNIT_SYSTEM:
        return (
          <UnitSystemOnBoarding
            userData={onBoardingUser}
            onContinueClick={onClickOnContinue}
            onUpdateUser={onUpdateUser}
          />
        );

      case OnBoardingSteps.HEIGHT_WEIGHT:
        return onBoardingUser.unitSystem === Unit.IMPERIAL ? (
          <HeightAndWeightImperialOnBoarding
            userData={onBoardingUser}
            onContinueClick={onClickOnContinue}
            onUpdateUser={onUpdateUser}
            submitDataForOnBoarding={finishOnBoarding}
          />
        ) : (
          <HeightAndWeightMetricOnBoarding
            userData={onBoardingUser}
            onContinueClick={onClickOnContinue}
            onUpdateUser={onUpdateUser}
            submitDataForOnBoarding={finishOnBoarding}
          />
        );

      case OnBoardingSteps.BOOK_INTRO_CALL:
        return <BookIntroCallOnBoarding userData={onBoardingUser} />;

      default:
        return <></>;
    }
  };

  return (
    <div className={classes.posterContainer}>
      {onBoardingStep !== OnBoardingSteps.WELCOME && (
        <div className={classes.progressBackHeader}>
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            value={(100 / 5) * progressValue}
          />
          <div className={classes.statusBackHeader}>
            {onBoardingStep !== OnBoardingSteps.BOOK_INTRO_CALL && (
              <div
                className={classes.backHeader}
                onClick={() => {
                  const step: any = OnBoardingSteps[progressValue - 1];
                  setOnBoardingStep(OnBoardingSteps[step] as any as OnBoardingSteps);
                  setProgressValue(progressValue - 1);
                }}
                role={'button'}
                tabIndex={0}
                onKeyDown={() => {}}
              >
                <img src={BackIcon} width={24} height={24} alt={'back-img-icon'} />

                <Typography variant={'button'} className={classes.back}>
                  {t('OnBoardingPage.back')}
                </Typography>
              </div>
            )}

            {onBoardingStep === OnBoardingSteps.BOOK_INTRO_CALL && (
              <div className={classes.backHeader} />
            )}

            <Typography
              variant={'button'}
              className={classes.status}
            >{`${progressValue} of 5`}</Typography>
          </div>
        </div>
      )}

      <div>
        <img src={BetterHealthIcon} alt="better health icon" width={164} height={164} />
      </div>
      <Paper className={classes.paperContent}>
        {isLoading ? <Loader /> : <Fragment>{renderContent()}</Fragment>}
      </Paper>
    </div>
  );
};

OnBoardingPage.displayName = 'OnBoardingPage Page';
export default OnBoardingPage;
