import { Box } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getKemTaiUserChallengeScore } from '../../../../store/actions/kemtai/kemtai-action';
import ProgressCircular from '../movementchallenge/ProgressCircular';
import useStyles from './TotalChallengeScore.styles';

const TotalChallengeScore: React.FC = ({isHome}: any) => {
    const [scoreDetail, setScoreDetail] = React.useState(null)

    const classes = useStyles();
    const dispatch = useDispatch()

    const userData: any = useSelector((state: any) => state.users.currentUser)

    React.useEffect(() => {
        dispatch(getKemTaiUserChallengeScore(userData?.id, 5)).then((res) => {
            setScoreDetail(res)
        })
    }, [])

    return (
        scoreDetail?.completedWorkouts ?
    <Box className={classes.totalScore} style={isHome && {width: 310, marginBottom: 20}}>
        <h3>Total Challenge Score</h3>
        <h4>Total Effectiveness</h4>
        <ProgressCircular isTotalScore={true} effectivenessScore={scoreDetail?.effectivenessScore} />
        <div className='grayText'>Last Workout</div>
        <p>{scoreDetail?.lastWorkout}</p>
        <div className='bottomScore'>
            <div>
                <div className='grayText'>Total<br />Workout time</div>
                <strong>{Math.round((scoreDetail?.totalWorkoutTime)/60)} min</strong>
            </div>
            <div>
                <div className='grayText'>Completed<br />workouts</div>
                <strong>{scoreDetail?.completedWorkouts}/{scoreDetail?.totalWorkouts}</strong>
            </div>
        </div>
    </Box>
    : null
    );
}



export default TotalChallengeScore;