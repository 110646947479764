import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  toolbarButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: (props) => (props.smallScreens ? '20%' : 90),
    height: (props) => (props.isMobile ? 60 : 'auto'),
    paddingTop: 5,
    paddingBottom: (props) => (props.isMobile ? 5 : 0),
    marginLeft: (props) => (props.smallScreens ? 'auto' : 13),
    marginRight: (props) => (props.smallScreens ? 'auto' : 13),
    userSelect: 'none',
    cursor: 'pointer',
    '& img': {
      height: '100%',
    },
    '& svg.navBarIcon': {
      height: 23,
    },
    '& .toolbar-button-text': {
      paddingTop: (props) => (props.isMobile ? 2 : 7),
      color: theme.palette.primary.main,
      '& .MuiTypography-body1': {
        fontWeight: '600',
        fontSize: (props) => (props.isMobile ? '12px' : '16px'),
        lineHeight: (props) => (props.isMobile ? '16px' : '24px'),
      },
    },
    '& .toolbar-button-inactive': {
      paddingTop: (props) => (props.isMobile ? 2 : 7),
      color: theme.palette.secondary.dark,
      fontSize: (props) => (props.isMobile ? '12px' : '16px'),
      '& .MuiTypography-body1': {
        fontSize: (props) => (props.isMobile ? '12px' : '16px'),
        lineHeight: (props) => (props.isMobile ? '16px' : '24px'),
      },
    },
    '& > .toolbar-button-icon': {
      width: 32,
      height: 24,
    },
  },
  toolbarButtonWithHover: {
    '&:hover': {
      opacity: 0.6,
    },
  },
  navbarIcons: {
    '&.iconHeight': {
      width: 32,
      height: 24,
    },
    '&.iconPlus': {
      width: 45,
      height: 45,
    },
    '& .white': {
      fill: theme.palette.background.paper,
    },
    '& .primaryStroke': {
      stroke: theme.palette.blacksAndGrays.stoneGray,
    },
    '& path': {
      fill: theme.palette.blacksAndGrays.stoneGray,
    },
    '& circle': {
      fill: theme.palette.blacksAndGrays.stoneGray,
    },
    '& .oceanBlueHide': {
      fill: theme.palette.background.paper,
    },
    '& .primaryWhite': {
      fill: theme.palette.primary.main,
    },
    '& .whitePrimary': {
      fill: theme.palette.background.paper,
    },
  },
  navbarIconsActive: {
    '&.iconHeight': {
      width: 32,
      height: 24,
    },
    '&.iconPlus': {
      width: 45,
      height: 45,
    },
    '&  .white': {
      fill: theme.palette.background.paper,
    },
    '& .grey': {
      fill: theme.palette.background.paper,
    },
    '&  .oceanBlue': {
      fill: theme.palette.tintsAndShades.oceanBlue,
    },
    '&  .oceanBlueHide': {
      fill: theme.palette.tintsAndShades.oceanBlue,
    },
    '& .primary': {
      fill: theme.palette.primary.main,
    },
    '& .primaryStroke': {
      fill: theme.palette.primary.main,
    },
    '& .primaryWhite': {
      fill: theme.palette.background.paper,
    },
    '& .whitePrimary': {
      fill: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
