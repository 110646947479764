import {FormHelperText, TextField as MaterialTextField, Typography} from '@material-ui/core';
import * as React from 'react';
import useStyles from './text-field.styles';
import {FocusEventHandler} from 'react';
import classNames from 'classnames';
import {MAX_CHARACTERS} from '../../../utils/constants';

interface Props {
  containerClass?: string;
  helperTextClassName?: string;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  className?: string;
  focusclass?: string;
  value?: string;
  placeholder?: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>;
  isFocused?: boolean;
  label?: string;
  required?: boolean;
  unit?: string;
  unitClassName?: string;
  name: string;
  type?: 'number' | 'text' | 'email' | 'password';
  inputProps?: any;
}

export const TextField: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    containerClass,
    value,
    label,
    helperText,
    required,
    helperTextClassName,
    minRows,
    isFocused,
    ...rest
  } = props;

  return (
    <div
      className={classNames(
        containerClass,
        classes.textFieldContainer,
        value && value?.length >= MAX_CHARACTERS && classes.errorBorder,
      )}
      onFocus={rest.onFocus && rest.onFocus}
    >
      <div className={classes.labelContainer}>
        {label && (
          <Typography
            variant="caption"
            component="div"
            className={props.disabled ? classes.labelDisabled : classes.labelText}
          >
            {label}
          </Typography>
        )}

        {required && (
          <Typography variant="caption" component="div" className={classes.requiredText}>
            *
          </Typography>
        )}
      </div>
      <div className={classes.unitContainer}>
        <MaterialTextField
          variant="outlined"
          required={true}
          {...rest}
          minRows={isFocused ? minRows : 1}
          error={!rest.disabled && rest.error}
          value={value || ''}
          className={classNames(`${classes.input} ${rest.className || ''}`)}
        />
        <Typography variant="body1" className={rest.unitClassName}>
          {rest.unit}
        </Typography>
      </div>
      <FormHelperText
        error={rest.error}
        className={
          value && value?.length >= MAX_CHARACTERS ? classes.helperText : helperTextClassName
        }
      >
        {helperText}
      </FormHelperText>
    </div>
  );
};
