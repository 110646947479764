import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import {PaperWrapper} from '../paper-wrapper/PaperWrapper';
import InsightsTable from './InsightsTable';
// import {CustomCarousel} from '../carousel/Carousel';
import Loader from '../loader/Loader';
// import {ChipTag} from '../chip-tag/ChipTag';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import {selectInsights, selectMember} from '../../../store/selectors/homepage.selectors';
// import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {IUser} from '../../../models/IUser';
import {IUserInsightsDto} from '../../../dtos/IInsightsDto';

import useStyles from './insights-widget.styles';

interface Props {
  isMobile: boolean;
}

const InsightsWidget: React.FC<Props> = ({isMobile}) => {
  const classes = useStyles({
    isMobile,
  });
  const [t] = useTranslation();
  const dispatch = useDispatch();

  // const currentUser: IUser = useSelector(selectCurrentUser);
  const selectedMember: IUser = useSelector(selectMember);
  const userInsights: IUserInsightsDto = useSelector(selectInsights);
  const [isLoading, setLoading] = useState(false);

  const {insights,
    //  threeDayWordCloudFoodTags, 
    // yesterDayWordCloudFoodTags,
    //  yesterFoodImagePath
    } =
    userInsights;

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await dispatch(homepageActions.loadUsersInsights(selectedMember?.id));
    };

    loadData().then(() => {
      setLoading(false);
    });
  }, [dispatch, selectedMember]);

  // const solveHeaderName = (): string => {
  //   if (selectedMember) {
  //     return selectedMember.firstName + ' ' + selectedMember.lastName;
  //   }
  //
  //   return currentUser.firstName + ' ' + currentUser.lastName;
  // };

  return (
    <React.Fragment>
      <PaperWrapper className={classes.minHeight}>
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Typography variant="h6">{t('Homepage.insights.daysTrend')}</Typography>
            {!selectedMember && (
              <Typography className={classes.insightsContent}>
                {isMobile
                  ? t('Homepage.insights.contentMobile')
                  : t('Homepage.insights.contentDesktop')}
              </Typography>
            )}
            <InsightsTable
              headers={insights?.headers || []}
              rows={insights?.rows || []}
              isMobile={isMobile}
            />
          </React.Fragment>
        )}
      </PaperWrapper>
      {/* <PaperWrapper className={classes.tableBelowTagsContainer}> */}
        {/* <div className={classes.tableBelowTags}> */}
          {/* {threeDayWordCloudFoodTags?.length > 0 && (
            <div>
              <Typography variant={'subtitle1'}>{t('Homepage.insights.daysWordCloud')}</Typography>

              <div className={classes.tagsContainer}>
                {threeDayWordCloudFoodTags.map((oneTagCategory) =>
                  oneTagCategory.tags.map((oneTag) => (
                    <ChipTag
                      key={`daysWordCloud-tag-${oneTagCategory.id}-${oneTag.id}`}
                      color={oneTag.color}
                      borderColor={oneTag.color}
                      hoverColor={oneTag.hoverColor}
                      textColor={oneTag.textColor}
                      name="chip"
                      id={oneTag.id || 0}
                      label={oneTag.name}
                    />
                  )),
                )}
              </div>
            </div>
          )} */}
          {/* <div>
            <Typography variant={'subtitle1'}>
              {t('Homepage.insights.yesterdayWordCloud')}
            </Typography>

            {yesterDayWordCloudFoodTags?.length > 0 && (
              <div className={classes.tagsContainer}>
                {yesterDayWordCloudFoodTags.map((oneTagCategory) =>
                  oneTagCategory.tags.map((oneTag) => (
                    <ChipTag
                      key={`yesterdayWordCloud-tag-${oneTagCategory.id}-${oneTag.id}`}
                      color={oneTag.color}
                      borderColor={oneTag.color}
                      hoverColor={oneTag.hoverColor}
                      textColor={oneTag.textColor}
                      name="chip"
                      id={oneTag.id || 0}
                      label={oneTag.name}
                    />
                  )),
                )}
              </div>
            )}
          </div> */}
        {/* </div> */}
      {/* </PaperWrapper> */}
      {/* <PaperWrapper className={classes.foodPicsContainer}>
        <div className={classes.foodPics}>
          <Typography variant={'subtitle1'} className={classes.foodPicsHeader}>
            {t('Homepage.insights.yesterdayFoodPics')}
          </Typography>
          <section className={classes.carouselSection}>
            {yesterFoodImagePath?.length > 0 && (
              <CustomCarousel
                isMobile={isMobile}
                content={yesterFoodImagePath}
                cardsShown={isMobile ? 1 : 3}
                slideNumber={1}
                infinite={true}
              />
            )}
          </section>
        </div>
      </PaperWrapper> */}
    </React.Fragment>
  );
};

export default InsightsWidget;
