import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  notificationCenterPanel: {
    width: '60%',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      height: 25,
      cursor: 'pointer',
    },
  },
  notificationRightPanel: {
    display: 'flex',
    justifyContent: 'end',
    width: '20%',
    '& img': {
      height: 'fit-content',
      marginLeft: 7,
      marginRight: 7,
    },
  },
  notificationPanel: {
    transition: '.5s',
  },
  notificationPanelBackground: {
    zIndex: 12200000000,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.24)',
  },
  notificationPanelContent: {
    zIndex: 12200000001,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 'calc( 100% - 366px)',
    background: theme.palette.background.default,
    padding: 16,
  },
  notificationPanelHeader: {
    position: 'relative',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 700,
    marginBottom: 30,
    '& button': {
      position: 'absolute',
      top: '-3px',
      left: 0,
      padding: 0,
    },
  },
}));

export default useStyles;
