import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isSkippedMeal: boolean;
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  width: {
    width: '100%',
  },
  dataWithTags: {
    marginLeft: (props) => (props.isMobile ? 0 : !props.isSkippedMeal ? 24 : 0),
    minWidth: (props) => (!props.isSkippedMeal ? 0 : 164),
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  skippedMeal: {
    marginLeft: (props) => (props.isMobile ? 0 : !props.isSkippedMeal ? 0 : 24),
  },
  uploadedImage: {
    width: (props) => (props.isMobile ? '100% ' : 164),
    height: (props) => (props.isMobile ? 'auto' : 164),
    marginLeft: (props) => (props.isMobile ? '-9px' : 0),
    borderRadius: (props) => (props.isMobile ? 0 : '12px'),
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    '& button': {
      border: 'none',
      background: 'transparent',
    },
  },
  closeButton: {
    '& > svg path': {
      fill: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
