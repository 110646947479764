import * as React from 'react';
import {Fragment} from 'react';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
// import FiltersIcon from '../../../assets/icons/app-nav-bar/filters-icon.svg';
// import CloseIcon from '../../../assets/icons/rest/close-icon.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/rest/close-icon.svg';
import {MOBILE_SIZE, TABLET_BIG_SIZE, TABLET_SMALL_SIZE} from '../../../utils/constants';

import useStyles from './side-menu-bar-mob.style';
import {useSelector} from 'react-redux';
import {getIsMobileDownload, selectMember} from '../../../store/selectors/homepage.selectors';

interface Props {
  openSideMenuBar: boolean;
  setOpenSideMenuBar: (e: boolean) => void;
  children: React.ReactChild;
}

export const SideMenuBarMob: React.FC<Props> = (props) => {
  const selectedMember = useSelector(selectMember);
  const isMobileDownload = useSelector(getIsMobileDownload);
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: isTablet && isTabletSmallSize,
      isTabletBig: isTablet && isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
    smallSize:
      isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig,
    isMobileDownload,
  });

  React.useEffect(() => {
    if (props.openSideMenuBar) {
      props.setOpenSideMenuBar(false);
    }
  }, [selectedMember]);

  return (
    <Fragment>
      <div className={classes.sidebarIcon}>
        <button style={{ height:32}} className={classes.navbarButton} onClick={() => props.setOpenSideMenuBar(true)}>
          {/* <img src={FiltersIcon} alt="filters" /> */}
          <span style={{fontWeight: 800, color:"#000000", fontSize:17}}>MENU</span>
        </button>
      </div>
      <div
        className={`${classes.sideMenuBar} ${props.openSideMenuBar ? classes.sideMenuBarOpen : ''}`}
      >
        <button className={classes.navbarButton} onClick={() => props.setOpenSideMenuBar(false)}>
          {/* <img src={CloseIcon}  alt="close filters" /> */}
          <CloseIcon fill={'#000'} />
        </button>
        {props.openSideMenuBar && props.children}
      </div>
    </Fragment>
  );
};
