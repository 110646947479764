import React from 'react';
import useStyles from './welcome-onboarding.styles';
import {Button} from '../button/Button';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import {IOnBoardingUser} from '../../../models/IOnBoardingUser';
import {setUserDataOnBoarding} from '../../../utils/locale-storage-service';
import {useHistory} from 'react-router';
import {GeneralPaths} from '../../../utils/constants';

interface IWelcomeOnBoardingProps {
  userData: IOnBoardingUser;
}

const BookIntroCallOnBoarding: React.FC<IWelcomeOnBoardingProps> = (props) => {
  const classes = useStyles();
  const data = props.userData;
  const [t] = useTranslation();
  const history = useHistory();

  const scheduleCall = () => {
    setUserDataOnBoarding({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth,
    });

    history.push(GeneralPaths.OnBoardingSchedulePage);
  };

  return (
    <div className={classes.container}>
      <div>
        <Typography variant={'h5'}>
          {t('OnBoardingPage.bookIntroCall.scheduleIntroCall')}
        </Typography>
      </div>
      <Typography variant={'body1'}>{t('OnBoardingPage.bookIntroCall.pickGoodDate')}</Typography>

      <Button id="schedule" onClick={scheduleCall}>
        {t('OnBoardingPage.bookIntroCall.scheduleIntroCallButton')}
      </Button>
    </div>
  );
};
export default BookIntroCallOnBoarding;
