import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootScaleMood: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.text.primary,
  },
  rootMood: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: theme.palette.text.primary,
  },
  emoticonMargin: {
    marginRight: 8,
  },
  emoticonTxt: {
    marginTop: 8,
  },
}));

export default useStyles;
