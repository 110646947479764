import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  posterContainer: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#FFF',
    padding: '10px',
    '& .closePopup': {
      position: "absolute",
      top: "10px",
      right: "30px",
      zIndex: "1",
      boxShadow: 'unset',
      background: '#807c7c',
      padding: '15px',
      borderRadius: '50%',
      width: '34px',
      height: '34px',
      minWidth: 'auto',
    },
  },
  modalAppointment: {
    '& .MuiBackdrop-root': {
      background: '#FFF'
    }
  },
  iframeContainer:{
    width:"900px",
  },
  paperContent: {
    width: '100%',
    minHeight: '100%',
    paddingBottom: '40px',
    maxWidth: 411,
    // padding: '32px 40px 32px 40px',
    '& .camelcase': {
      textTransform: 'lowercase',
      display: 'inline-block',
      '&::first-letter': {
        textTransform: 'uppercase',
      }
      
    },
    '& a': {
      color: '#000000'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '& h1': {
        fontSize: '40px',
        fontWeight: 'normal',
        lineHeight: '1.0',
        '&.bH1': {
          fontSize: '30px',
          fontWeight: 'bold',
          marginTop: 0,
        }
    },
    '& > p': {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginBottom: '15px'
    },
    '& .errorP': {
      color: '#FF0000'
    },
    '& .fieldBox': {
        '& label': {
            fontSize: '12px',
            color: '#5C5754'
        },
        '& .code-fieldBox': {
            display: 'flex',
            marginBottom: '15px',
            '& input':{
                height: '40px',
                fontSize: '24px',
                fontWeight: 'bold'
            }
        }
    },
  },
  normalTextField: {
    width: '100%',
  },
  dateOfBirthWidth: {
    width: '100%',
    boxSizing: 'content-box'
  },
  maskedPickerWidth: {
    width: '100%',
  },
  textFieldContainerMargin: {
    marginBottom: 16,
    marginRight: 0,
  },
  bottomButton: {
    marginTop: '0px',
    width: '100%',
    height: '38px'
  },
  loaderLayer: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,0.5)'
  },
  ['@media (max-width:767px)']: {
    buttonsContainer: {
      '& img': {
        width: '145px'
      }
    },
    paperContent: {
      '& h1': {
        fontSize: '35px',
        '&.bH1': {
          fontSize: '25px'
        }
      },
    },
    posterContainer: {
     
      '& .closePopup': {
        right: "3px",
      },
    },
    iframeContainer: {
      width: '100%',
    },
}
}));

export default useStyles;
