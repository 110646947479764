import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      marginBottom: '24px',
    },
  },
  editBtn: {
    display: 'none',
  },
  outlinedBtn: {
    height: '36px !important',
    marginRight: 16,
  },
  editButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    marginBottom: 24,
    '& > button': {
      width: (props) => (props.isMobile ? '100%' : ''),
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
    '&.weight': {
      flexWrap: 'wrap',
    },
    '&.form': {
      display: (props) => (props.isMobile ? 'block' : 'flex'),
    },
    '&.mobile': {
      flexWrap: 'wrap',
      '& > div': {
        width: '50%',
        marginRight: 0,
      },
      '& > div:nth-child(odd)': {
        paddingRight: (props) => (props.isMobile ? 8 : 0),
      },
      '& > div:nth-child(even)': {
        paddingLeft: (props) => (props.isMobile ? 8 : 0),
      },
    },
  },
  item: {
    marginRight: (props) => (props.isMobile ? 'auto' : '16px'),
    justifyContent: (props) => (props.isMobile ? 'space-between' : ''),
    flexDirection: 'column',
    '& span': {
      width: '100%',
    },
    '&.weight': {
      flexDirection: (props) => (props.isMobile ? 'row' : 'column'),
      justifyContent: (props) => (props.isMobile ? 'flex-start' : ''),
      marginBottom: (props) => (props.isMobile ? '4px' : '0px'),
      width: (props) => (props.isMobile ? '50%' : ''),
      paddingRight: (props) => (props.isMobile ? '10px' : '0px'),
      '& span': {
        width: (props) => (props.isMobile ? 'auto' : ''),
        paddingRight: (props) => (props.isMobile ? '10px' : '0px'),
      },
      '& p': {
        fontSize: (props) => (props.isMobile ? '12px' : '14px'),
        lineHeight: (props) => (props.isMobile ? '16px' : '20px'),
      },
    },
  },
  warningMessage: {
    display: 'flex',
    color: theme.palette.uicolors.fireOpal,
    alignItems: 'flex-start',
    marginBottom: '16px',
  },
  normalTextField: {
    width: (props) => (props.isMobile ? '100%' : 170),
    marginBottom: (props) => (props.isMobile ? '5px' : 0),
  },
}));

export default useStyles;
