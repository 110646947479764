import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {AppNavBarMob} from './AppNavBarMob';
import {AppNavBarWeb} from './AppNavBarWeb';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {selectMember} from '../../../store/selectors/homepage.selectors';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import {
  Devices,
  GeneralPaths,
  MOBILE_SIZE,
  TABLET_SMALL_SIZE,
  TABLET_BIG_SIZE,
} from '../../../utils/constants';
import {EngagementPointType, NavBarSelectedType} from '../../../utils/enums';
import TwilioService from '../../../services/TwilioService';
import {addEngagementPointDatabase} from '../../../api/engagement.api';
import {handleMiniOrangeLogOut, logoutUserApi} from '../../../api/users.api';
import * as nourishActions from '../../../store/actions/nourish/nourish-actions';
// import MobileAppDownload from '../mobile-app-download/MobileAppDownload';
// import {getTokenData} from '../../../utils/locale-storage-service';

interface Props {
  title?: string;
  type: Devices.TABLET | Devices.DESKTOP;
  bottom?: boolean;
}

export const AppNavBar: React.FC<Props> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedMember = useSelector(selectMember);
  const [selectedNavBarItem, setNavBarItem] = useState(NavBarSelectedType.NAVBAR_HOME);
  const currentUser = useSelector(selectCurrentUser);
  const [openNotification, setOpenNotification] = useState(false);
  const [openFilters, setOpenFilters] = useState(true);
  // const tokenData = getTokenData();
  // const token = tokenData.idToken;
  // const jwtUrl = `${process.env.REACT_APP_JWT_URL}/${token}?relay=`;
  const deselectMember = () => {
    // dispatch(homepageActions.selectMember(null));
    // dispatch(homepageActions.selectConversation(null));
  };

  const selectNavBarItem = (
    selectedType: NavBarSelectedType,
    path: GeneralPaths,
    activatedOnClick = true,
  ) => {
    if (selectedType === selectedNavBarItem) {
      return;
    }

    if (path !== GeneralPaths.HomePage) {
      TwilioService.removeListeners();
    }
    dispatch(homepageActions.resetHistoryLogs());
    if (selectedType === NavBarSelectedType.NAVBAR_MARKET) {
      window.open(`${process.env.REACT_APP_SHOPIFY_LINK}`, '_blank');
      return;
    }
    setNavBarItem(selectedType);
    if (selectedNavBarItem === NavBarSelectedType.NAVBAR_NOURISH) {
      dispatch(nourishActions.clearRecipesTags());
      dispatch(nourishActions.clearMealPlansTags());
    }

    if (activatedOnClick) {
      history.push(path);
    }
  };

  useEffect(() => {
    if (history.location.pathname === GeneralPaths.HomePage) {
      selectNavBarItem(NavBarSelectedType.NAVBAR_HOME, GeneralPaths.HomePage, false);
      addEngagementPointDatabase({
        eventType: EngagementPointType.INSIGHTS,
        description: '',
      });
    }

    if (history.location.pathname === GeneralPaths.CommunityPage) {
      selectNavBarItem(NavBarSelectedType.NAVBAR_COMMUNITY, GeneralPaths.CommunityPage, false);
      addEngagementPointDatabase({
        eventType: EngagementPointType.COMMUNITY,
        description: '',
      });
      deselectMember();
    }

    if (history.location.pathname === GeneralPaths.LogsPage) {
      selectNavBarItem(NavBarSelectedType.NAVBAR_PLUS, GeneralPaths.LogsPage, false);
      deselectMember();
    }

    if (
      history.location.pathname === GeneralPaths.NourishPage ||
      history?.location?.pathname.includes('/movement')
    ) {
      selectNavBarItem(NavBarSelectedType.NAVBAR_NOURISH, GeneralPaths.NourishPage, false);
      addEngagementPointDatabase({
        eventType: EngagementPointType.NOURISH,
        description: '',
      });
      deselectMember();
    }

    if (history.location.pathname === GeneralPaths.ProfilePage) {
      selectNavBarItem(NavBarSelectedType.NAVBAR_PROFILE, GeneralPaths.ProfilePage, false);
      deselectMember();
    }
  }, [history.location.pathname]);

  const callLogOut = async () => {
    await addEngagementPointDatabase({
      eventType: EngagementPointType.LOGOUT,
      description: '',
    });
    logoutUserApi();
    handleMiniOrangeLogOut();
  };

  useEffect(() => {
    if (openNotification) {
      setOpenNotification(false);
    } else if (openFilters) {
      setOpenFilters(false);
    }
  }, [location]);
  if (props.type === Devices.TABLET) {
    return (
      <>
        {/* {(isAndroid || isIOS) && !props.bottom === true && <MobileAppDownload />} */}
        <AppNavBarMob
          selectedMember={selectedMember}
          currentUser={currentUser}
          isMobileOrTablet={isMobileOrTablet}
          callLogOut={callLogOut}
          smallScreens={smallScreens}
        />
      </>
    );
  } else {
    return (
      <>
        {/* {(isAndroid || isIOS) && !props.bottom === true && <MobileAppDownload />} */}
        <AppNavBarWeb
          selectNavBarItem={selectNavBarItem}
          selectedNavBarItem={selectedNavBarItem}
          currentUser={currentUser}
          isMobileOrTablet={isMobileOrTablet}
          callLogOut={callLogOut}
        />
      </>
    );
  }
};
