import * as React from 'react';
import {Tooltip} from '@material-ui/core';
interface ICircleProps {
  tooltip: string;
  circleClass: string;
  innerClass: string;
  tooltipClass: string;
  selected: boolean;
}
export const Circle: React.FC<ICircleProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    setOpen(props.selected);
  }, [props.selected]);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableTouchListener
      disableHoverListener
      title={props.tooltip}
      arrow
      classes={{tooltip: props.tooltipClass}}
    >
      <div
        className={props.circleClass}
        onMouseOver={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onFocus={handleTooltipOpen}
        aria-hidden="true"
      >
        <div className={props.innerClass} />
      </div>
    </Tooltip>
  );
};
