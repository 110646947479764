import React, {useState} from 'react';
import useStyles from './watch-video.styles';
import {Button} from '../button/Button';
import {OnBoardingScheduleType} from '../../../utils/enums';
import {useTranslation} from 'react-i18next';
import Vimeo from '@u-wave/react-vimeo';
import {Typography, useMediaQuery} from '@material-ui/core';
import {isMobileOnly} from 'react-device-detect';
import {MOBILE_SIZE} from '../../../utils/constants';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';

interface IWatchVideoProps {
  onContinueClick: (type: OnBoardingScheduleType) => void;
}

const WatchVideo: React.FC<IWatchVideoProps> = (props) => {
  const {onContinueClick} = props;
  const [isEnabledContinue, setEnabledContinue] = useState(false);
  const [t] = useTranslation();

  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
    };
    return size;
  }, [window.innerWidth]);

  const classes = useStyles({
    isMobileOrTablet,
  });

  return (
    <div className={classes.container}>
      {!isMobileOrTablet.isMobile && (
        <div>
          <img src={BetterHealthIcon} alt="better health icon" width={164} height={164} />
        </div>
      )}

      <div>
        <Typography variant={'h5'} className={classes.header}>
          {t('OnBoardingSchedulePage.watchVideo.almostDone')}
        </Typography>
        <Typography variant={'body1'} className={classes.textContent}>
          {t('OnBoardingSchedulePage.watchVideo.watchVideo')}
        </Typography>

        <Vimeo
          video="https://player.vimeo.com/video/687981255"
          width={isMobileOrTablet.isMobile ? '320px' : '548px'}
          height={isMobileOrTablet.isMobile ? '220px' : '356px'}
          onPlay={() => {
            setEnabledContinue(true);
          }}
        />
      </div>

      <Button
        id="continue"
        className={classes.continueButton}
        onClick={() => onContinueClick(OnBoardingScheduleType.FINISH)}
        disabled={!isEnabledContinue}
      >
        {t('OnBoardingSchedulePage.continue')}
      </Button>
    </div>
  );
};
export default WatchVideo;
