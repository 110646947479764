import {makeStyles, Theme} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: any;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  toolbar: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: (props) => (props.isMobileOrTablet.isMobile ? '0 16px' : '0px 32px'),
    minHeight: 52,
  },
  navbar: {
    background: theme.palette.background.paper,
    top: (props) => (props.smallScreens ? ' auto !important' : '0'),
    left: '0',
    height: 48,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
    marginBottom: 16,
  },
  smallBar: {
    background: theme.palette.background.paper,
    top: 'auto !important',
    left: '0',
    height: (props) => (props.isMobileOrTablet.isMobile ? 56 : 80),
    boxShadow: (props) =>
      props.smallScreens
        ? '0px 2px 4px rgba(100, 101, 102, 0.2), 0px 4px 5px rgba(100, 101, 102, 0.14), 0px 1px 10px rgba(100, 101, 102, 0.12)'
        : '0px 2px 6px rgba(0, 0, 0, 0.12)',
    bottom: '0 !important',
    marginBottom: (props) => (props.smallScreens ? 0 : 16),
  },
  smallTopBar: {
    background: theme.palette.background.paper,
    top: '0 !important',
    left: '0',
    height: (props) => (props.isMobileOrTablet.isMobile ? 56 : 80),
    boxShadow: (props) =>
      props.smallScreens
        ? '0px 2px 4px rgba(100, 101, 102, 0.2), 0px 4px 5px rgba(100, 101, 102, 0.14), 0px 1px 10px rgba(100, 101, 102, 0.12)'
        : '0px 2px 6px rgba(0, 0, 0, 0.12)',
    bottom: 'auto !important',
    marginBottom: (props) => (props.smallScreens ? 0 : 16),
  },
  navbarButton: {
    border: 'none',
    background: 'transparent',
    paddingRight: 0,
  },
  navigationLeftPartContainer: {
    width: '20%',
    zIndex: -1,
  },
  navigationPartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: (props) => (props.isMobileOrTablet.isMobile ? 0 : 9),
    maxWidth: (props) => (props.isMobileOrTablet.isMobile ? '100%' : 580),
    width: '100%',
  },
  notificationCenterPanel: {
    width: '60%',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      height: 25,
      cursor: 'pointer',
    },
  },
  notificationRightPanel: {
    display: 'flex',
    justifyContent: 'end',
    width: '20%',
    '& img': {
      height: 'fit-content',
      marginLeft: 7,
      marginRight: 7,
    },
  },
  notificationPanel: {
    transition: '.5s',
  },
  notificationPanelBackground: {
    zIndex: 12200000000,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.24)',
  },
  notificationPanelContent: {
    zIndex: 12200000001,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 'calc( 100% - 366px)',
    background: theme.palette.background.default,
    padding: 16,
  },
  notificationPanelHeader: {
    position: 'relative',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 700,
    marginBottom: 30,
    '& button': {
      position: 'absolute',
      top: '-3px',
      left: 0,
      padding: 0,
    },
  },
  profilePanel: {
    transition: '.5s',
  },
  profilePanelBackground: {
    zIndex: 12200000000,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.24)',
  },
  profilePanelContent: {
    zIndex: 12200000001,
    position: 'fixed',
    top: 'auto',
    right: 0,
    bottom: 0,
    left: 0,
    background: theme.palette.background.default,
    padding: 16,
    borderRadius: '12px 12px 0px 0px',
    '& button': {
      display: 'flex',
      color: theme.palette.uicolors.fireOpal,
      fontSize: 16,
      lineHight: 24,
      letterSpacing: '0.5px',
      '& img': {
        marginRight: 13,
      },
      '& div': {
        paddingTop: 3,
      },
    },
  },
  profilePanelLine: {
    height: '4px',
    width: '64px',
    background: theme.palette.blacksAndGrays.jet,
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    marginBottom: 44,
  },
}));

export default useStyles;
