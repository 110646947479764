import {Box, Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import QuickUserProfileWidget from '../../../common/components/quick-user-profile/QuickUserProfileWidget';
import {selectMember} from '../../../store/selectors/homepage.selectors';
import useStyles from './user-profile-page.styles';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import * as loggingActions from '../../../store/actions/logging-page/logging-page-action';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {AdminPaths} from '../../../utils/constants';

type RouteParamProps = {
  userId: string;
};

const UserProfilePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedMember = useSelector(selectMember);
  const params: RouteParamProps = useParams();
  const [t] = useTranslation();
  const loadData = async () => {
    await dispatch(homepageActions.selectMember(params.userId));
    await dispatch(loggingActions.loadUsersCategoryWithTags());
    await dispatch(homepageActions.loadUserPhases());
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.container}>
      <Link to={AdminPaths.HomePage} className={classes.link}>
        <Typography variant="subtitle1">{'<Back'}</Typography>
      </Link>
      <div className={classes.content}>
        <Box mb={5}>
          <Typography variant={'h3'}>{`${selectedMember && selectedMember.firstName}${t(
            'AdminUserProfilePage.title',
          )}`}</Typography>
        </Box>

        <QuickUserProfileWidget />
      </div>
    </div>
  );
};
export default UserProfilePage;
