import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    
   
    StepOneFrom:{
        position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 846,
          width:"100%",
          textAlign:"center",
          height:"auto", 
          background: 'radial-gradient(175.67% 163.13% at -47.08% -37.08%, #BAF5F5 0%, #E9FFFF 39.06%, #EBF4FF 64.58%, #D2DAFA 100%)', 
          borderRadius: "12px",
          boxShadow: "0px 2px 1px rgba(100, 101, 102, 0.2), 0px 1px 1px rgba(100, 101, 102, 0.14), 0px 1px 3px rgba(100, 101, 102, 0.12)",
          padding:"64px 109px 96px",

    '& .ptag':{
        margin:"16px 0 0 0",
        fontSize:"20px",
        lineHeight:"32px",
    },
    '& .poppupForm':{
        width:"100%",
        height:"auto",
        background:"#fff",
        margin:"56px 0 0 0",
        padding:"40px 40px 32px 40px",
        borderRadius: "12px",
        boxShadow: "0px 11px 15px #D4DEEA, 0px 24px 38px #EDF6FF, 0px 9px 46px rgba(234, 236, 249, 0.12)",
        '& h4':{

            fontWeight:"700",
            fontSize:"28px",
            lineHeight:"32px",
            margin:"0px"
        },

        '& .stepper':{
            margin:"56px auto 44px ",
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            maxWidth:"460px",
            position:"relative",
            '&::after':{
                content: '""',
                position: 'absolute',               
                left: "0",
                top: "40%",
                right:"0",
                margin:"0 auto",           
                width: '98%',
                height: '2px',
                background: "#D2D3D6",
                zIndex:"1"            
            },
            
            '& span':{
            
                zIndex:"22222",
                cursor:"pointer"  
    
            }
        },
        '& .btnArea':{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
        },

        '& .btnSubmit':{
            background:"#000000",
            borderRadius: "4px",
            width: "194px",
            height: "36px",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "17px",
            color: "#fff",
            margin:"0 0 26px 0",
            '&:hover':{
                color: "#fff",
                background:"#000000",
            },
            '&:disabled':{
                background:"#E6E7EB",
                color: "#7A7673",
            },

        },
        
        '& .btnnotnow':{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "17px",
            color: "#000000",
            padding:"0", 
            letterSpacing: "1.25px",
            textTransform: "capitalize",
            '&:hover':{
                background:"none",
            }

        },

    },
   
    ['@media (max-width:1440px)']: {
        overflowY:"auto",
        height:"90vh",
        maxHeight:"628px"
        
    },
    ['@media (max-width:1280px)']: {

        padding:"56px 48px",
            maxWidth: 696,
            overflowY:"auto",
            height:"100vh",
            maxHeight:"564px",

            '& h1':{
              
                lineHeight:"48px",
            },   

     
        '& .poppupForm':{
            margin:"36px 0 0 0",
            padding:"40px 48px",
        },
      },
      ['@media (max-width:767px)']: {
        padding:"32px 16px",
            height:"100vh",
            borderRadius:"0",
            maxWidth: "100%", 
            maxHeight:"100%",
            
        '& h1':{
            fontSize:"28px !important",
            lineHeight:"32px",
        },    
        '& .ptag':{
            fontSize:"14px !important",
            lineHeight:"20px",
            margin:"8px 0 0 0"
        },    
     
     
        '& .poppupForm':{
            margin:"24px 0 0 0",
            padding:"24px 16px",
            '& h4':{

                fontSize:"20px !important",
                lineHeight:"24px !important",
            },
           '& .btnSubmit':{

            width:"100% !important",
            height:"48px !important"
           }

        },
      },
},
   

  });

  export default useStyles;