import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useCommonStyles from '../common-for-widgets.styles';
import {TextField} from '../../text-field/TextField';
import {getError, hasError} from '../../../../validation/Validation';

interface ICholesterolSubformProps {
  validateFormField: any;
  data?: any;
  errors: any;
  changeData: any;
  validationRulesCholesterol: any;
  isMobile: boolean;
}

const CholesterolSubform: React.FC<ICholesterolSubformProps> = (props) => {
  const commonClasses = useCommonStyles({
    isMobile: props.isMobile,
  });
  const [t] = useTranslation();
  const [data, setData] = useState({
    totalCholesterolValue: '',
    nonHdl: '',
    ldl: '',
    hdl: '',
  });

  useEffect(() => {
    const initialObject = {
      totalCholesterolValue: props.data?.totalCholesterolValue || '',
      nonHdl: props.data?.nonHdl || '',
      ldl: props.data?.ldl || '',
      hdl: props.data?.hdl || '',
    };
    setData(initialObject);
  }, []);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    const newData = {...data, [name]: value};
    setData(newData);
    props.changeData(newData);
  };

  return (
    <React.Fragment>
      <div className={commonClasses.centerContainer}>
        <TextField
          name={'totalCholesterolValue'}
          containerClass={commonClasses.normalTextField}
          required={true}
          onBlur={() =>
            props.validateFormField(data, 'totalCholesterolValue', props.validationRulesCholesterol)
          }
          value={data.totalCholesterolValue}
          label={t('LogsPage.bloodLabsWidget.cholesterolLabel')}
          placeholder={t('LogsPage.bloodLabsWidget.cholesterolTextFieldPlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(props.errors, 'totalCholesterolValue')}
          helperText={getError(props.errors, 'totalCholesterolValue')}
        />
        <TextField
          name={'nonHdl'}
          containerClass={commonClasses.normalTextField}
          required={true}
          onBlur={() => props.validateFormField(data, 'nonHdl', props.validationRulesCholesterol)}
          value={data.nonHdl}
          label={t('LogsPage.bloodLabsWidget.nonHDLLabel')}
          placeholder={t('LogsPage.bloodLabsWidget.cholesterolTextFieldPlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(props.errors, 'nonHdl')}
          helperText={getError(props.errors, 'nonHdl')}
        />
      </div>
      <div className={commonClasses.centerContainer}>
        <TextField
          name={'ldl'}
          containerClass={commonClasses.normalTextField}
          required={true}
          onBlur={() => props.validateFormField(data, 'ldl', props.validationRulesCholesterol)}
          value={data.ldl}
          label={t('LogsPage.bloodLabsWidget.ldlLabel')}
          placeholder={t('LogsPage.bloodLabsWidget.cholesterolTextFieldPlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(props.errors, 'ldl')}
          helperText={getError(props.errors, 'ldl')}
        />
        <TextField
          name={'hdl'}
          containerClass={commonClasses.normalTextField}
          required={true}
          onBlur={() => props.validateFormField(data, 'hdl', props.validationRulesCholesterol)}
          value={data.hdl}
          label={t('LogsPage.bloodLabsWidget.hdlLabel')}
          placeholder={t('LogsPage.bloodLabsWidget.cholesterolTextFieldPlaceholder')}
          onChange={(event) => handleChange(event)}
          error={hasError(props.errors, 'hdl')}
          helperText={getError(props.errors, 'hdl')}
        />
      </div>
    </React.Fragment>
  );
};

export default CholesterolSubform;
