import * as React from 'react';
import {Card, CardContent, CardMedia, Typography} from '@material-ui/core';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import FitnessImage from '../../../assets/images/Fitness.png';
import RehabImage from '../../../assets/images/Rehab.png';
import YogaImage from '../../../assets/images/yoga.svg';
import useStyles from './fitness-card.styles';
import {GeneralPaths} from '../../../utils/constants';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';

interface Props {
  containerStyle?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

export const MovementLanding: React.FC<Props> = ({
  containerStyle,
  isMobileOrTablet,
  smallScreens,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
  });
  const history = useHistory();
  const isRehabEnabled = useSelector((state) => state?.nourish?.rehabEnabled);

  const linkRedirect = (link: any) => {
    history.replace({
      pathname: `${GeneralPaths.MovementPage}/${link}/`,
    });
  };

  return (
    <>
      <Card className={`${classes.card} ${containerStyle}`} onClick={() => linkRedirect('fitness')}>
        <CardMedia
          component="img"
          height={isMobileOrTablet.isMobile ? '72' : '148'}
          image={FitnessImage}
          alt="recipe-image"
        />
        <div className={classes.infoContainer}>
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="div">
              Fitness
            </Typography>
          </CardContent>
        </div>
      </Card>
      {isRehabEnabled ? (
        <Card className={`${classes.card} ${containerStyle}`} onClick={() => linkRedirect('rehab')}>
          <CardMedia
            component="img"
            height={isMobileOrTablet.isMobile ? '72' : '148'}
            image={RehabImage}
            alt="recipe-image"
          />
          <div className={classes.infoContainer}>
            <CardContent>
              <Typography gutterBottom variant="subtitle1" component="div">
                Rehab
              </Typography>
            </CardContent>
          </div>
        </Card>
      ) : null}
      <Card className={`${classes.card} ${containerStyle}`} onClick={() => linkRedirect('yoga')}>
        <CardMedia
          component="img"
          height={isMobileOrTablet.isMobile ? '72' : '148'}
          image={YogaImage}
          alt="recipe-image"
        />
        <div className={classes.infoContainer}>
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="div">
              Yoga
            </Typography>
          </CardContent>
        </div>
      </Card>
    </>
  );
};
