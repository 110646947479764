import {makeStyles, Theme} from '@material-ui/core/styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  fullWidth: {
    width: '100%',
  },
  fitnessList: {
    margin: (props) => (props.isMobileOrTablet.isTabletBig ? '16px -12px' : '16px 0px'),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    cursor: 'pointer',
    overflow: 'auto',
    alignContent: 'flex-start',
  },
  fitnessBanner: {
    background:
      'linear-gradient(129deg, rgba(212,249,249,1) 2%, rgba(251,253,255,1) 45%, rgba(201,223,250,1) 97%)',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0px 2px 10px #dedede',
    marginRight: '16px',
    marginBottom: '30px',
    '& h1': {
      fontSize: '28px',
      margin: '0 0 10px 0',
    },
    '& sub': {
      fontSize: '20px',
      fontWeight: '300',
      display: 'block',
      marginBottom: '20px',
    },
    '& h2': {
      fontSize: '20px',
      fontWeight: '600',
      margin: '20px 0 10px 0',
    },
    '& h3': {
      margin: '0',
      fontSize: '16px',
      fontWeight: '600',
    },
    '& ul': {
      margin: 0,
      padding: '20px 0 0 0',
      listStyle: 'none',
      '& li': {
        position: 'relative',
        paddingLeft: '30px',
        paddingBottom: '10px',
      },
      '& li::before': {
        content: '"1"',
        position: 'absolute',
        border: '1px solid #001329',
        left: 0,
        top: 0,
        borderRadius: '50%',
        width: '22px',
        height: '22px',
        lineHeight: '22px',
        textAlign: 'center',
      },
      '& li:nth-child(2)::before': {
        content: '"2"',
      },
      '& li:nth-child(3)::before': {
        content: '"3"',
      },
      '& li::after': {
        content: '""',
        position: 'absolute',
        borderLeft: '1px solid #001329',
        height: 'calc(100% - 20px)',
        left: '10px',
        top: '22px',
      },
      '& li:nth-child(3)::after': {
        display: 'none',
      },
      '& b': {
        display: 'block',
      },
    },
    '& .bannerBottom': {
      marginTop: '30px',
      textAlign: 'center',
      '& button': {
        display: 'block',
        textAlign: 'center',
        maxWidth: '290px',
        border: '1px solid',
        borderRadius: '30px',
        textDecoration: 'none',
        lineHeight: '46px',
        fontSize: '18px',
        letterSpacing: '1px',
        fontWeight: 500,
        color: '#3f93e2',
        margin: '0 auto',
        '&.howWorks': {
          //.banner-bottom .button.how-works{
          color: '#fff',
          borderColor: '#3f93e2',
          background:
            'linear-gradient(90deg, rgba(63,150,226,1) 4%, rgba(69,164,230,1) 52%, rgba(56,107,216,1) 97%)',
          margin: '25px auto 15px auto',
          width: 290,
          cursor: 'pointer',
        },
        '&::hover': {
          //.banner-bottom .button.how-works{
          color: '#fff',
          borderColor: '#3f93e2',
          background:
            'linear-gradient(90deg, rgba(63,150,226,1) 4%, rgba(69,164,230,1) 52%, rgba(56,107,216,1) 97%)',
        },
      },
    },
  },
  button: {},
  fitnessLoader: {
    position: 'fixed',
    left: 0,
    top: 0,
    background: 'rgba(255,255,255,0.7)',
    'z-index': '9',
  },
  movementLanding: {
    '& .headingh3': {
      color: '#140802',
      fontSize: 30,
      fontWeight: 600,
      margin: '0 0 24px 0',
      letterSpacing: 0.25,
      lineHeight: '40px',
    },
    '& .MuiCardContent-root': {
      paddingTop: '16px',
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: 600,
      },
    },
  },
  subtitle: {
    color: '#184C88',
    fontSize: 16,
    fontWeight: 600,
    margin: '0 0 24px 0',
    letterSpacing: 0.1,
    lineHeight: '20px',
    '& span': {
      cursor: 'pointer',
    },
  },
  dialogMargin: {
    marginTop: (props) => (props.smallScreens ? 0 : 40),
  },
  dialogClose: {
    position: 'fixed',

    '& svg path': {
      // fill: theme.palette.blacksAndWhites.pureWhite,
    },
  },
  closeIconRecipesClass: {
    top: 16,
    right: 35,
    border: '1px solid black',
    cursor: 'pointer',
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 9,
  },
}));

export default useStyles;
