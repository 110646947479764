import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  smallScreens: boolean;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  container: {
    width: (props) => (props.smallScreens ? '100%' : 628),
    marginBottom: 24,
    marginTop: 24,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    scroll:'auto',
  },
  centeredContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textColor: {
    position: 'absolute',
    top: 90,
    color: "#fff",
  },
  textColorSmall: {
    position: 'absolute',
    color: "#fff",
  },
  image: {
    borderRadius: 12,
  },
}));

export default useStyles;
