import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
import {ChipTag} from '../chip-tag/ChipTag';
import {IUserTagDto} from '../../../models/ITag';
import {isTagVisible} from '../../../utils/enums';
import {INITIAL_EMPTY_VALUE, TABLET_BIG_SIZE} from '../../../utils/constants';
import {IUserCategoryTagDto} from '../../../dtos/ICategoryTagDto';

import useStyles from './category-chip-tags-with-box.styles';

interface Props {
  disabled?: boolean;
  className?: string;
  id: string;
  name: string;
  categoryTagsList: IUserCategoryTagDto[] | [];
  ref: any;
  validateParent?: any;
  notSelectedColor?: React.CSSProperties['color'];
  isTagVisibleProp?: any;
  handleTagsChanges?: () => void;
  sidebar?: boolean;
  selectedStateTags?: any;
}

const CategoryChipTagsWithBox: React.FC<Props> = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const {id, categoryTagsList, className, disabled, isTagVisibleProp, handleTagsChanges, sidebar, selectedStateTags = null} =
    props;
  const [selectedTagsList, setSelectedTagsList] = useState<IUserTagDto[]>([]);
  const [showMore, setShowMore] = useState<boolean>(true);
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, {noSsr: true});
  const isMobileOrTablet = React.useMemo(() => {
    return isMobile || isMobileSize;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
  });

  let categoryTagsListLength = 0;

  useEffect(() => {
    categoryTagsListLength = 0;
    const retValList: IUserTagDto[] = [];
    categoryTagsList.forEach((oneCategoryTag) => {
      oneCategoryTag.tags.forEach((oneTag) => {
        const isSelectedTag = (selectedStateTags?.tags ? selectedStateTags?.tags?.findIndex((tagID: any) => tagID === oneTag?.id) === -1 : -1); 
        const tag: IUserTagDto = {
          id: oneTag.id,
          name: oneTag.name,
          categoryId: oneTag.categoryId,
          color: oneCategoryTag.color,
          hoverColor: oneCategoryTag.hoverColor,
          isVisible: isSelectedTag && oneTag.isVisible,
          isSystemOnly: oneTag.isSystemOnly,
        };
        categoryTagsListLength = categoryTagsListLength + 1;
        if (tag.isVisible === false) {
          retValList.push(tag);
        }
      });
    });
    setSelectedTagsList(retValList);
  }, [categoryTagsList, selectedStateTags?.tags]);

  const handleClick = (e: any) => {
    const selectedCategory: IUserCategoryTagDto | undefined = categoryTagsList?.find((oneCategory) =>
      oneCategory.tags.some((oneTag) => oneTag.id === e),
    );

    const selectedTag: IUserTagDto | undefined = selectedCategory?.tags.find(
      (oneTag) => oneTag.id == e,
    );

    if (selectedTag !== undefined && selectedCategory != undefined) {
      selectedTag.isVisible = false;

      const tag: IUserTagDto = {
        id: selectedTag.id,
        name: selectedTag.name,
        categoryId: selectedTag.categoryId,
        color: selectedCategory.color,
        hoverColor: selectedCategory.hoverColor,
      };

      const newList = [...selectedTagsList, tag];
      setSelectedTagsList(newList);
      props.validateParent && props.validateParent(newList);
    }
    handleTagsChanges && handleTagsChanges();
  };

  const handleDelete = (e: any) => {
    const selectedCategory: IUserCategoryTagDto | undefined = categoryTagsList?.find((oneCategory) =>
      oneCategory.tags.some((oneTag) => oneTag.id === e),
    );

    const selectedTag: IUserTagDto | undefined = selectedCategory?.tags.find(
      (oneTag) => oneTag.id == e,
    );

    if (selectedTag !== undefined && selectedCategory != undefined) {
      selectedTag.isVisible = true;

      const newList = [...selectedTagsList.filter((oneTag) => oneTag.id !== e)];
      setSelectedTagsList(newList);
      props.validateParent && props.validateParent(newList);
    }
    handleTagsChanges && handleTagsChanges();
  };

  useImperativeHandle(ref, () => ({
    getSelectedTags(): IUserTagDto[] {
      return selectedTagsList;
    },

    clearSelectedTags(): void {
      setSelectedTagsList([]);
    },
  }));

  const showList = (show: boolean) => {
    let i = 0;
    const j = 20;
    const increase = sidebar ? 1 : 0;
    categoryTagsList.forEach((oneCategoryTag: any) => {
      oneCategoryTag.tags.forEach((oneTag: any) => {
        oneTag.show = show ? i < j : true;
        i = i + increase;
      });
    });
  };

  useEffect(() => {
    if (showMore) {
      showList(showMore);
    }
  }, [categoryTagsList]);

  return (
    <div className={classNames(className, classes.root)} key={props.name + ' ' + id}>
      <div className={`${classes.tagsContainer}`}>
        {categoryTagsList.map((oneTagCategory) =>
          oneTagCategory.tags.map(
            (oneTag) =>
              oneTag.isVisible &&
              oneTag.show &&
              ((isTagVisibleProp === isTagVisible?.NOT_VISIBLE && !oneTag.isSystemOnly) ||
                isTagVisibleProp != isTagVisible?.NOT_VISIBLE) && (
                <ChipTag
                  key={`tag-${oneTagCategory.id}-${oneTag.id}`}
                  color={oneTagCategory.color}
                  borderColor={oneTagCategory.hoverColor}
                  hoverColor={oneTagCategory.hoverColor}
                  name="chip"
                  id={oneTag.id || INITIAL_EMPTY_VALUE}
                  label={oneTag.name}
                  onClick={handleClick}
                  disabled={
                    (oneTag.isSystemOnly && isTagVisibleProp === isTagVisible.VISIBLE_DISABLED) ||
                    disabled
                  }
                />
              ),
          ),
        )}
      </div>
      {sidebar && (
        <button
          className={classes.buttonLoadMore}
          onClick={() => {
            showList(!showMore);
            setShowMore(!showMore);
          }}
        >
          {showMore ? t('Homepage.sideMenuBar.showMore') : t('Homepage.sideMenuBar.showLess')}
        </button>
      )}
      {isMobileOrTablet ? (
        selectedTagsList &&
        selectedTagsList?.length > 0 && (
          <div className={`${classes.boxTags} ${sidebar ? 'tagsContainerBlue' : ''}`}>
            {selectedTagsList.map(
              (oneSelectedTag) =>
                !oneSelectedTag.isVisible && (
                  <ChipTag
                    key={`tag-selected-${oneSelectedTag.id}`}
                    color={oneSelectedTag.color}
                    borderColor={oneSelectedTag.hoverColor}
                    hoverColor={oneSelectedTag.hoverColor}
                    name="chip"
                    id={oneSelectedTag.id || 0}
                    label={oneSelectedTag.name}
                    onDelete={handleDelete}
                    disabled={
                      (oneSelectedTag.isSystemOnly &&
                        isTagVisibleProp === isTagVisible.VISIBLE_DISABLED) ||
                      disabled
                    }
                  />
                ),
            )}
          </div>
        )
      ) : (
        <div className={classes.boxTags}>
          {selectedTagsList &&
            selectedTagsList.map(
              (oneSelectedTag) =>
                !oneSelectedTag.isVisible && (
                  <ChipTag
                    key={`tag-selected-${oneSelectedTag.id}`}
                    color={oneSelectedTag.color}
                    borderColor={oneSelectedTag.hoverColor}
                    hoverColor={oneSelectedTag.hoverColor}
                    name="chip"
                    id={oneSelectedTag.id || 0}
                    label={oneSelectedTag.name}
                    onDelete={handleDelete}
                    disabled={
                      (oneSelectedTag.isSystemOnly &&
                        isTagVisibleProp === isTagVisible.VISIBLE_DISABLED) ||
                      disabled
                    }
                  />
                ),
            )}
        </div>
      )}
    </div>
  );
});

CategoryChipTagsWithBox.displayName = 'CategoryChipTagsWithBox';
export default CategoryChipTagsWithBox;
