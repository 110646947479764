/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {IIngredientDto} from '../../dtos/ICategoryIngredientsDto';
import {
  ISelectedMealPlansFiltersDto,
  ISelectedReipesFiltersDto,
} from '../../dtos/IRecipeFiltersDto';
import {nonHiddenTags} from '../../utils/store-helpers';
import {ICourseDto} from '../../dtos/ICourseDto';
import {ICuisineDto} from '../../dtos/ICuisineDto';
import {IDailyContentCategoryDto} from '../../dtos/IDailyContentCategoryDto';

export const selectAllRecipes = (state: any) => {
    return {
    content: state.nourish.recipes,
    count: state.nourish.recipesCount,
  };
};
export const selectAllMindfulness = (state: any) => {
  
  return {
    content: state.nourish.mindfulnessData,
    // count: state.nourish.recipesCount,
  };
};
export const selectPodCasts = (state: any) => {
 
  return {
    content: state.nourish.podCastData,
    // count: state.nourish.recipesCount,
  };
};
export const selectYogaData = (state: any) => {
 
  return {
    content: state.nourish.yogaData,
    // count: state.nourish.recipesCount,
  };
};
export const selectRecipe = (state: any) => state.nourish.recipe;

export const selectSelectedMenuItem = (state: any) => state.nourish.selectedMenuItem;
export const selectSelectedMealPlansFilters = (state: any): ISelectedMealPlansFiltersDto =>
  state.nourish.selectedMealPlansFilters;
export const selectAllMealPlansTags = (state: any) => state.nourish.mealPlansTags;
export const selectSelectedRecipesFilters = (state: any): ISelectedReipesFiltersDto =>
  state.nourish.selectedRecipesFilters;

export const selectSelectableIngredients = (state: any): IIngredientDto[] =>
  state.nourish.selectableIngredients;

export const selectRecipeFilters = (state: any) => state.nourish.recipeFilters;
export const selectMealPlansFilters = (state: any) => state.nourish.mealPlansFilters;

export const selectAllRecipesTags = (state: any) => state.nourish.recipesTags;
export const selectAllCuisines = (state: any) => state.nourish.cuisines;

export const selectNoHiddenSelectableIngredients = (state: any): IIngredientDto[] => {
  return nonHiddenTags<IIngredientDto[]>(state, 'selectableIngredients');
};

export const selectNoHiddenCuisines = (state: any) => {
  return nonHiddenTags<ICuisineDto[]>(state, 'cuisines');
};

export const selectNoHiddenCourses = (state: any) => {
  return nonHiddenTags<ICourseDto[]>(state, 'courses');
};

export const selectAllCourses = (state: any) => {
  return state.nourish.courses;
};

export const selectAllLevels = (state: any) => state.nourish.levels;

export const selectAllIngredientsCategories = (state: any) => state.nourish.ingredientsCategories;
export const selectAllCategoriesWithIngredients = (state: any) =>
  state.nourish.categoriesWithIngredients;

export const selectAllMealPlans = (state: any) => state.nourish.mealPlans;
export const selectMealPlan = (state: any) => state.nourish.mealPlan[0];

export const selectAllCategories = (state: any) => state.nourish.categories;

export const selectAllCards = (state: any) => state.nourish.cards;
export const selectAllRightCards = (state: any) => state.nourish.rightCards;
export const tagsData = (state: any) => state.nourish.movementRestricted;
export const appointmentEnableUsers = (state: any) => state.nourish.appointmentEnabled
export const selectAllDailyContentCategories = (state: any) => state.nourish.dailyContentCategories;
export const selectAllDailyContentCategoriesForBetrHealth = (state: any) =>
  state.nourish.dailyContentCategories.find(
    (dailyCategory: IDailyContentCategoryDto) => dailyCategory.name === 'Betr Program',
  )?.children;
export const selectAllDailyContentCategoriesForMasterClasses = (state: any) =>
  state.nourish.dailyContentCategories.find(
    (dailyCategory: IDailyContentCategoryDto) => dailyCategory.name === 'Master Classes',
  )?.children;
export const selectAllDailyContents = (state: any) => {
  return {
    content: state.nourish.dailyContents,
    count: state.nourish.dailyContentsCount,
  };
};
export const selectBetrProgramFilters = (state: any) => state.nourish.betrProgramFilters;
export const selectDailyContent = (state: any) => state.nourish.dailyContent[0];
