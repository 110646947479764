import React from 'react';
import {useTranslation} from 'react-i18next';
import NotesWrapper from '../notes/NotesWrapper';
import ReminderWrapper from '../reminder/ReminderWrapper';
import CloseIcon from '../../../assets/icons/rest/close-icon.svg';

import useStyles from './app-nav-bar-notification.styles';

interface IAppNavBarNotification {
  handleClose: () => void;
  selectedMember: any;
}

export const AppNavBarNotification: React.FC<IAppNavBarNotification> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.notificationPanel}>
      <div className={classes.notificationPanelBackground}> </div>
      <div className={classes.notificationPanelContent}>
        <div className={classes.notificationPanelHeader}>
          <button className={classes.navbarButton} onClick={() => props.handleClose()}>
            <img src={CloseIcon} alt="close notifications" />
          </button>
          {t('AppNavBar.notifications')}
        </div>
        {props.selectedMember ? <NotesWrapper /> : <ReminderWrapper />}
      </div>
    </div>
  );
};
