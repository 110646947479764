import {makeStyles, Theme} from '@material-ui/core';
import React from 'react';

interface Props {
  color: React.CSSProperties['color'];
  borderColor: React.CSSProperties['color'];
  hoverColor: React.CSSProperties['color'];
  textColor: React.CSSProperties['color'];
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  chipContainer: {
    marginTop: 8,
    marginRight: 8,
  },
  chip: {
    backgroundColor: (props) => props.color,
     border: (props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none'),
    // border: "1px solid black !important",
    color: (props) => props.textColor || theme.palette.blacksAndWhites.smokyBlack,
    fontSize: 12,
    height: 24,

    '&:hover': {
      // backgroundColor: '#D6D6D6',
      backgroundColor: (props) => props.hoverColor,
    },
    '&:focus': {
      backgroundColor: (props) => props.color,
    },
    '& .MuiChip-deleteIcon ': {
      width: 15,
      height: 15,
      color: theme.palette.blacksAndWhites.smokyBlack,
    },
  },
  disabled: {
    backgroundColor: theme.palette.blacksAndGrays.gainsboro,
    color: theme.palette.blacksAndWhites.sonicSilver,
  },
}));

export default useStyles;
