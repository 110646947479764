import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

const useCommonAddedRowStyles = makeStyles<Theme, Props>((theme) => ({
  line: {
    backgroundColor: theme.palette.blacksAndGrays.gainsboro,
    height: 1,
  },
  dateTime: {
    display: 'flex',
    paddingTop: (props) => (props.isMobile ? 8 : 0),
    paddingBottom: (props) => (props.isMobile ? 8 : 0),
    '& > div': {
      width: '2px',
      background: theme.palette.blacksAndGrays.gainsboro,
      margin: '0 8px',
    },
  },
  dataContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingLeft: (props) => (props.isMobile ? 16 : 0),
    paddingTop: 16,
    paddingBottom: 16,
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: (props) => (props.isMobile ? 0 : 8),
  },
  dateContent: {
    color: theme.palette.blacksAndWhites.sonicSilver,
    maxWidth: 95,
  },
  textContent: {
    color: theme.palette.blacksAndWhites.smokyBlack,
    marginLeft: 4,
  },
  buttonsContainer: {
    marginTop: 8,
  },
}));

export default useCommonAddedRowStyles;
