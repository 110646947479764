/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {del, get, post, put} from './api-calls';
import {ICasualTag} from '../models/ITag';
import {ISleepDto} from '../dtos/ISleepDto';

export const getSleepTags = (): Promise<ICasualTag[]> => {
  return get(`/sleep/tags`);
};

export const getSleepLogs = (
  id: string,
  fromDate?: string,
  toDate?: string,
): Promise<ISleepDto[]> => {
  return get(`/sleep?UserId=${id}&from=${fromDate}&to=${toDate}`);
};

export const addSleepDatabase = (data: ISleepDto): Promise<any> => {
  return post('/sleep', data);
};

export const editSleepDatabase = (data: ISleepDto): Promise<any> => {
  return put(`/sleep/${data.id}`, data);
};

export const deleteSleepDatabase = (id: number): Promise<any> => {
  return del(`/sleep/${id}`);
};

export const getSleepLogById = (id: number): Promise<ISleepDto> => {
  return get(`/sleep/${id}`);
};
