import {RecipeTagKeys} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const nonHiddenTags = <T>(
  state: any,
  tagKey: RecipeTagKeys | 'selectableIngredients',
): T => {
  const all = state.nourish?.[tagKey];
  const _modifiedTagKey = tagKey === 'selectableIngredients' ? 'ingredients' : tagKey;
  const selected = state.nourish.recipesTags?.[_modifiedTagKey];
  const selectedIds = selected.map((item: any) => item.id);
  return all?.map((tagProps: any) => {
    const {id} = tagProps;
    return {
      ...tagProps,
      isHidden: selectedIds.includes(id),
    };
  });
};
