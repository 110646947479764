import * as React from 'react';
import useStyles from './time-picker-styles';
import {FormHelperText, Typography} from '@material-ui/core';
import TimePickerIcon from '../../../assets/icons/rest/time-picker-icon.svg';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import classNames from 'classnames';

interface Props {
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  className?: string;
  onChange: any;
  label?: string;
  required?: boolean;
  name: string;
  value: string;
}

export const TimePicker: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {label, helperText, value, required, className, ...rest} = props;
  return (
    <div className={classNames(classes.timeWrapper, className)}>
      <div className={classes.labelContainer}>
        {label && (
          <Typography variant="caption" component="div" className={classes.labelText}>
            {label}
          </Typography>
        )}

        {required && (
          <Typography variant="caption" component="div" className={classes.requiredText}>
            *
          </Typography>
        )}
      </div>

      {/*variant="inline"*/}
      <div className={classes.timePickerCont}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            ampm={true}
            inputVariant="outlined"
            value={value || ''}
            required={required}
            mask="__:__ _M"
            {...rest}
            keyboardIcon={<img src={TimePickerIcon} alt={'keyboard-time-picker-icon'} />}
          />
        </MuiPickersUtilsProvider>
      </div>
      <FormHelperText error={rest.error} className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </div>
  );
};
