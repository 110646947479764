import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import * as drinkLoggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';

import useStyles from './drink-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface DrinkRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
}

const DrinkRecentlyAddedRow: React.FC<DrinkRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleDismiss = () => {
    dispatch(drinkLoggingActions.deleteDrinkLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };

  const handleOunceWord = (): string => {
    const retVal: string = data.value.toString() + ' ';
    if (data.value > 1) {
      return retVal + t('LogsPage.drinkWidget.ouncesLabelRecently');
    } else {
      return retVal + t('LogsPage.drinkWidget.ounceLabelRecently');
    }
  };

  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        {isMobile ? (
          <div>
            <Typography variant={'subtitle1'} className={classes.textContent}>
              {handleOunceWord()}
            </Typography>
            <DateTimeString date={data.date} isMobile={isMobile} />
          </div>
        ) : (
          <div className={commonClasses.infoContainer}>
            <DateTimeString date={data.date} isMobile={isMobile} />
            <Typography variant={'subtitle1'} className={classes.textContent}>
              {handleOunceWord()}
            </Typography>
          </div>
        )}
        <div className={commonClasses.buttonsContainer}>
          <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
        </div>
      </div>
    </div>
  );
};
export default DrinkRecentlyAddedRow;
