import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      borderRadius: '12px',
    },
  },
  container: {
    padding: '32px 16px 40px 16px',
  },
  header: {
    letterSpacing: '0.15px',
  },
  content: {
    paddingBottom: '32px',
    letterSpacing: '0.5px',
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  button: {
    height: '48px',
    width: '100%',
  },
}));

export default useStyles;
