import {makeStyles, Theme} from '@material-ui/core';

interface Props {
  isSelected: boolean;
  isMobileOrTablet: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  chipContainer: {
    marginTop: 8,
    marginRight: 8,
    '&.sidebarTag > div': { 
      backgroundColor: (props) =>
        props.isSelected
          ? `${theme.palette.tintsAndShades.beauBlue}` 
          : props.isMobileOrTablet
            ? theme.palette.tintsAndShades.beauBlue
            : '#FFFFFF',
    }
  },
  chip: {
    backgroundColor: (props) =>
      props.isSelected ? `${theme.palette.tintsAndShades.beauBlue}` : '#FFFFFF',
    // border: `1px solid ${theme.palette.primary.dark}`,
    border: "1px solid black !important",
    // color: theme.palette.primary.dark,
    fontSize: 12,
    height: 24,
    color:"#000000",
    '&:hover': {
      // backgroundColor: theme.palette.tintsAndShades.beauBlue,
      backgroundColor: '#D6D6D6',
      color:"#000000"
    },
    '&:focus': {
      // backgroundColor: theme.palette.tintsAndShades.beauBlue,
      backgroundColor: '#D6D6D6',
      color:"#000000"
    },
    '& .MuiChip-deleteIcon ': {
      width: 15,
      height: 15,
      color: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
