import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Typography} from '@material-ui/core';
import {MealPlanAdvisoryComponent} from './MealPlanAdvisoryComponent';
import {MealPlanDailyMeal} from './MealPlanDailyMeal';
import {MealPlanSharedComponent} from './MealPlanSharedComponent';
import {Button} from '../button/Button';
import {LevelFlag} from '../level-flag/LevelFlag';
import {PaperWrapper} from '../paper-wrapper/PaperWrapper';
import BackIcon from '../../../assets/icons/rest/left-arrow.svg';
import {selectMealPlan} from '../../../store/selectors/nourish.selectors';
import {IResponsiveSize} from '../../../models/IResponsiveSize';
import {MealPlanComponentType, MealPlanSharedComponentType} from '../../../utils/enums';
import {handlePrint} from '../../../utils/helpers';
import {IMealPlanComponentDetails} from '../../../dtos/IMealPlanComponentDetails';

import useStyles from './meal-plan-details-view.styles';

interface Props {
  slug?: string;
  isMobileOrTablet: IResponsiveSize;
  smallScreens: boolean;
  handleDismiss: () => void;
}
export const MealPlanDetailsView: React.FC<Props> = ({
  isMobileOrTablet,
  smallScreens,
  handleDismiss,
}) => {
  const classes = useStyles({
    isMobileOrTablet,
  });
  const mealPlan = useSelector(selectMealPlan);
  const [t] = useTranslation();
  const getMealPlanComponent = (detail: IMealPlanComponentDetails) => {
    switch (detail.__component) {
      case MealPlanComponentType.SHARED_COMPONENT:
        return detail.type === MealPlanSharedComponentType.CARD ? (
          <MealPlanSharedComponent detail={detail} key={detail.id} />
        ) : (
          <MealPlanAdvisoryComponent detail={detail} key={detail.id} />
        );
      case MealPlanComponentType.MEAL_PLAN_COMPONENT:
        return <MealPlanDailyMeal dailyMeal={detail} key={detail.id} />;
      default:
        return <></>;
    }
  };

  return (
    <PaperWrapper>
      {smallScreens && (
        <div className={classes.header}>
          <Button
            className={classes.back}
            id="back-to-meal-plans"
            variant="text"
            onClick={() => handleDismiss()}
          >
            <img src={BackIcon} alt="Back to meal plans" />
            <Typography variant={'subtitle1'}>{t('NourishPage.mealPlans.title')}</Typography>
          </Button>
        </div>
      )}
      {mealPlan ? (
        <div className={classes.contentContainer}>
          <div className={classes.title}>
            <Typography gutterBottom variant="h6" component="div">
              {mealPlan?.title}
            </Typography>
            <Typography variant="subtitle1">{mealPlan?.description}</Typography>
          </div>
          <LevelFlag
            title={mealPlan?.level_category?.name}
            levelCategory={mealPlan?.level_category}
            top={0}
            right={1}
          />
          {mealPlan?.details.map((detail: IMealPlanComponentDetails) =>
            getMealPlanComponent(detail),
          )}
          <div className={classes.buttonContainer}>
            <Button
              id="print-plan"
              className={classes.button}
              onClick={() => handlePrint(mealPlan?.printFriendlyUrl?.url)}
            >
              {t('NourishPage.mealPlans.printMealPlan')}
            </Button>
            {/*<Button id="print-list"> {t('NourishPage.mealPlans.printShoppingList')}</Button>*/}
          </div>
        </div>
      ) : (
        <Typography align="center">{t('NourishPage.mealPlans.noData')}</Typography>
      )}
    </PaperWrapper>
  );
};
