import * as React from 'react';
import {useTranslation} from 'react-i18next';
import Carousel from 'react-multi-carousel';
import {Typography} from '@material-ui/core';
import {MealPlanCard} from './MealPlanCard';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

import useStyles from './meal-plans-view.styles';
import {MOBILE_SIZE} from '../../../utils/constants';

interface Props {
  mealPlans: any[];
  mealPlansMode: boolean;
  smallScreens: boolean;
  isMobileOrTablet: IResponsiveSize;
}

export const MealPlansView: React.FC<Props> = ({
  mealPlans,
  mealPlansMode,
  smallScreens,
  isMobileOrTablet,
  // cardLists,
}) => {
  const classes = useStyles({
    smallScreens,
    marginBottom: !mealPlansMode && isMobileOrTablet.isMobile,
  });
  const [t] = useTranslation();

  const responsive = {
    mobile: {
      breakpoint: {max: MOBILE_SIZE, min: 0},
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const renderMealPlans = () =>
    mealPlans.map((mealPlan) => <MealPlanCard mealPlan={mealPlan} key={mealPlan.id} />);

  const renderMealPlansCarousel = () => {
    const rows = [];
    for (let i = 0; i < mealPlans?.length; i = i + 2) {
      rows.push(
        <div className={classes.slide} key={i}>
          <MealPlanCard mealPlan={mealPlans[i]} key={mealPlans[i].id} />
          <MealPlanCard mealPlan={mealPlans[i + 1]} key={mealPlans[i + 1].id} />
        </div>,
      );
    }
    return rows;
  };

  return (
    <div className={classes.mainContainer}> 
      {mealPlans?.length ? (
        <>
          <Typography variant={'h6'} align="left">
            {t('NourishPage.mealPlans.title')}
          </Typography>
          {!mealPlansMode && isMobileOrTablet.isMobile ? (
            <Carousel
              className={classes.carousel}
              swipeable={true}
              draggable={true}
              arrows={false}
              showDots={isMobileOrTablet.isMobile}
              responsive={responsive}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={10000}
              keyBoardControl={true}
              containerClass="carousel-container"
              renderDotsOutside={true}
              renderButtonGroupOutside={true}
              dotListClass={classes.customDotList}
            >
              {renderMealPlansCarousel()}
            </Carousel>
          ) : (
            <div className={classes.plansList}>{renderMealPlans()}</div>
          )}
        </>
      ) : (
        <Typography variant="subtitle1">{t('NourishPage.mealPlansFilter.noResult')}</Typography>
      )}
    </div>
  );
};
