import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {useMediaQuery} from '@material-ui/core';
//import ProgressChartWidget from '../../../common/components/progress/ProgressChartWidget';
import ProfileInfo from '../../../common/components/profile-components/ProfileInfo';
import {Footer} from '../../../common/components/footer/Footer';
import Loader from '../../../common/components/loader/Loader';
import SimpleTagsWidget from '../../../common/components/profile-components/SimpleTagsWidget';
import * as loggingActions from '../../../store/actions/logging-page/logging-page-action';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
// import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import {TagsWidgetType} from '../../../utils/enums';
import {MOBILE_SIZE, TABLET_SMALL_SIZE, TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './profile-page.styles';

const ProfilePage: React.FC = () => {
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const classes = useStyles({
    isMobileOrTablet,
  });
  const [loading, setLoading] = useState(false);
  // const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const loadData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        // dispatch(loggingActions.loadWeightLogs(currentUser?.id)),
        dispatch(loggingActions.loadUsersCategoryWithTags()),
        dispatch(homepageActions.loadUserPhases()),
        // dispatch(homepageActions.selectMeasurementUnit(currentUser.measurementUnitId)),
      ]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        {loading ? (
          <Loader />
        ) : (
          <div className={classes.mainCenterContent}>
            {/*<ProgressChartWidget type={ProgressType.BODY_WEIGHT} />*/}
            <ProfileInfo />
            <SimpleTagsWidget type={TagsWidgetType.GOALS} />
            <SimpleTagsWidget type={TagsWidgetType.PREFERENCES} />
          </div>
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ProfilePage;
