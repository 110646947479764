import {useTranslation} from 'react-i18next';
import React from 'react';
import {Typography} from '@material-ui/core';

interface Props {
  value: any;
  average?: boolean;
}
export const MoodLevelText: React.FC<Props> = ({value, average}) => {
  const [t] = useTranslation();

  const renderText = () => {
    const convertedValue = parseInt(value);
    switch (convertedValue) {
      case 0:
        return t('LogsPage.stressWidget.moodMarks.iFeelAwful');
      case 1:
        return t('LogsPage.stressWidget.moodMarks.iFeelBad');
      case 2:
        return t('LogsPage.stressWidget.moodMarks.iFeelSoSo');
      case 3:
        return t('LogsPage.stressWidget.moodMarks.iFeelGood');
      case 4:
        return t('LogsPage.stressWidget.moodMarks.iFeelGreat');
      default:
        return '0';
    }
  };
  return <Typography variant={average ? 'h6' : 'body2'}>{renderText()}</Typography>;
};
