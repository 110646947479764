import React from 'react';
import useStyles from './table.styles';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
} from '@material-ui/core';
import {IMeta} from '../../../utils/table-meta-helper';
import {useTranslation} from 'react-i18next';

interface Props {
  onRowClick?: (row: any) => void;
  rows: any[];
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  meta: IMeta;
  extended?: boolean;
  extendableContent?: (row: any) => React.ReactNode;
  renderRowContent?: (props: any) => React.ReactNode;
}

export const TableList: React.FC<Props> = ({
  page,
  onRowClick,
  rowsPerPage,
  handleChangePage,
  rows,
  meta,
  count,
  renderRowContent,
}) => {
  const {schema, columnModifiers} = meta;
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label={t('AdminHomePage.table')}>
        <TableBody>
          {rows.map(
            (row) =>
              renderRowContent && renderRowContent({row, onRowClick, schema, columnModifiers}),
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={schema.columns?.length}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {'aria-label': t('AdminHomePage.tablePagination')},
                native: true,
              }}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
