import React from 'react';
import {useDispatch} from 'react-redux';
import {Typography} from '@material-ui/core';
import DateTimeString from '../DateTimeString';
import {EllipsisOptions} from '../../elipsis-options/EllipsisOptions';
import * as stepsLoggingActions from '../../../../store/actions/logging-page/logging-page-action';
import {FormType} from '../../../../utils/enums';

import useStyles from './steps-recently-added-row.styles';
import useCommonAddedRowStyles from '../common-for-added-row.styles';

interface StepsRecentlyAddedRowProps {
  data: any;
  changeState: (formType: FormType) => void;
  selectLog: () => void;
  isMobile: boolean;
}

const StepsRecentlyAddedRow: React.FC<StepsRecentlyAddedRowProps> = (props) => {
  const {data, isMobile} = props;
  const classes = useStyles({
    isMobile,
  });
  const commonClasses = useCommonAddedRowStyles({
    isMobile,
  });
  const dispatch = useDispatch();
  const handleDismiss = () => {
    dispatch(stepsLoggingActions.deleteStepsLog(data.id || 0));
    props.changeState(FormType.ADDING_MODE);
  };
  const handleEdit = () => {
    props.selectLog();
    props.changeState(FormType.EDITING_MODE);
  };
  return (
    <div>
      <div className={commonClasses.line} />
      <div className={commonClasses.dataContainer}>
        {isMobile ? (
          <div>
            <Typography variant={'subtitle1'} className={classes.textContent}>
              {data.value}
            </Typography>
            <DateTimeString date={data.date} isMobile={isMobile} />
          </div>
        ) : (
          <div className={commonClasses.infoContainer}>
            <DateTimeString date={data.date} isMobile={isMobile} />
            <Typography variant={'subtitle1'} className={classes.textContent}>
              {data.value}
            </Typography>
          </div>
        )}
        <div className={commonClasses.buttonsContainer}>
          <EllipsisOptions handleEdit={handleEdit} handleDismiss={handleDismiss} />
        </div>
      </div>
    </div>
  );
};
export default StepsRecentlyAddedRow;
