import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useIntercom} from 'react-use-intercom';
import {SnackbarProvider} from 'notistack';
import {isMobileOnly, isTablet} from 'react-device-detect';
import {Typography, useMediaQuery} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {AppNavBar} from '../app-nav-bar/AppNavBar';
import {SnackbarUtils} from '../notistack/SnackbarUtils';
import {Button} from '../button/Button';
import {TermsAndPolicy} from '../terms-and-policy/TermsAndPolicy';
import {selectCurrentUser} from '../../../store/selectors/users.selectors';
import CloseIconWhite from '../../../assets/icons/rest/close-icon-white.svg';
import CheckedIcon from '../../../assets/icons/rest/checked-bubble-white-icon.svg';
import {useTheme} from '../../hooks/use-theme';
import {
  Devices,
  GeneralPaths,
  MOBILE_SIZE,
  TABLET_BIG_SIZE,
  TABLET_SMALL_SIZE,
} from '../../../utils/constants';
import {getHmacHash} from '../../../api/intercom.api';
import {checkWildcardRoute} from '../../../routes';
// import {ReactComponent as PlusIcon} from '../../../assets/icons/app-nav-bar/plus-icon22.svg';

import useStyles from './base-layout.styles';
import {getIsMobileDownload} from '../../../store/selectors/homepage.selectors';

interface IBaseLayoutProps {
  renderRoutes: () => React.ReactElement;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const BaseLayout: React.FC<IBaseLayoutProps> = (props) => {
  const isMobileDownload = useSelector(getIsMobileDownload);
  const isMobileSize = useMediaQuery(`(max-width:${MOBILE_SIZE}px)`, {noSsr: true});
  const isTabletSmallSize = useMediaQuery(
    `(min-width:${MOBILE_SIZE + 1}px) and (max-width:${TABLET_SMALL_SIZE - 1}px)`,
    {noSsr: true},
  );
  const isTabletBigSize = useMediaQuery(
    `(min-width:${TABLET_SMALL_SIZE}px) and (max-width:${TABLET_BIG_SIZE}px)`,
    {noSsr: true},
  );
  const isMobileOrTablet = React.useMemo(() => {
    const size = {
      isMobile: isMobileOnly || isMobileSize,
      isTabletSmall: (isTablet && isTabletSmallSize) || isTabletSmallSize,
      isTabletBig: (isTablet && isTabletBigSize) || isTabletBigSize,
    };
    return size;
  }, [window.innerWidth]);
  const smallScreens =
    isMobileOrTablet.isMobile || isMobileOrTablet.isTabletSmall || isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet,
    smallScreens,
    isMobileDownload,
  });
  const [theme] = useTheme();
  const location = useLocation();
  const [visible] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const [t] = useTranslation();
  // const history = useHistory();
  const {boot, hardShutdown, update} = useIntercom();
  const [modalTermsAndPolicy, setModalTermsAndPolicy] = useState(false);

  const checkRoutes = (locations: string[]): boolean => {
    if (locations.includes(location.pathname)) {
      return false;
    }

    for (let i = 0; i < locations?.length; i++) {
      if (checkWildcardRoute(locations[i], location.pathname)) {
        return false;
      }
    }

    return true;
  };

  const showNavBar = useMemo(() => {
    const forbiddenLocationsNavBar = [
      GeneralPaths.ChatPage.toString(),
      GeneralPaths.OnBoardingPage.toString(),
      GeneralPaths.LandingPage.toString(),
      GeneralPaths.OnBoardingSchedulePage.toString(),
      GeneralPaths.IntercomPage.toString(),
      GeneralPaths.SponsoredUserOnboardingPage.toString(),
      GeneralPaths.ReEnrollmentPage.toString(),
      GeneralPaths.EligibilityPage.toString(),
      GeneralPaths.Eligibility.toString(),
      GeneralPaths.Terms.toString(),
      GeneralPaths.Privacy.toString(),
      GeneralPaths.HLQ.toString(),
      GeneralPaths.AuthenticationPage.toString(),
    ];

    return checkRoutes(forbiddenLocationsNavBar);
  }, [location]);

  const showIntercom = useMemo(() => {
    const forbiddenLocationsIntercom = [
      GeneralPaths.LogOutPage.toString(),
      GeneralPaths.OnBoardingPage.toString(),
      GeneralPaths.OnBoardingSchedulePage.toString(),
      GeneralPaths.EligibilityPage.toString(),
      GeneralPaths.Terms.toString(),
      GeneralPaths.Privacy.toString(),
    ];

    return checkRoutes(forbiddenLocationsIntercom);
  }, [location]);

  useEffect(() => {
    const loadIntercom = async () => {
      if (showIntercom && !currentUser) {
        boot({
          customLauncherSelector: '#intercom-id-name',
          name: '',
        });
      } else if (showIntercom && currentUser) {
        const hashDto = await getHmacHash();

        update({
          customLauncherSelector: '#intercom-id-name',
          name: currentUser.firstName + ' ' + currentUser.lastName,
          email: currentUser.email,
          userId: currentUser.id,
          userHash: hashDto.hash,
          customAttributes: {
            user_ID: currentUser.id,
          },
        });
      } else {
        hardShutdown();
      }
    };

    loadIntercom();
  }, [showIntercom, currentUser]);

  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: any) => () => {
    // eslint-disable-next-line
    // @ts-ignore
    notistackRef.current.closeSnackbar(key);
  };

  const renderNavigateBar = (): React.ReactNode => {
    if (!showNavBar) {
      return '';
    }

    if (smallScreens) {
      if (location.pathname === GeneralPaths.CommunityPage) {
        return '';
      }

      return <AppNavBar type={Devices.TABLET} />;
    } else {
      return <AppNavBar type={Devices.DESKTOP} />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        iconVariant={{
          success: (
            <img
              src={CheckedIcon}
              alt="snackbar-success-icon"
              width={19.5}
              height={19.5}
              style={{marginRight: '8.5px'}}
            />
          ),
        }}
        classes={{
          variantSuccess: classes.snackSuccess,
        }}
        ref={notistackRef}
        action={(key) => (
          <Button
            id="snackbar-button"
            onClick={onClickDismiss(key)}
            className={classes.snackButton}
          >
            <img src={CloseIconWhite} alt="close-icon-snackbar" />
          </Button>
        )}
      >
        <SnackbarUtils />
        {modalTermsAndPolicy && (
          <TermsAndPolicy
            handleDismiss={() => setModalTermsAndPolicy(false)}
            dialogState={modalTermsAndPolicy}
          />
        )}
        <div className={classes.root}>
          <div  className={`${classes.content} rooContainer`}>
            {renderNavigateBar()}

            {props.renderRoutes()}
            {showIntercom && visible && (
              <div className={classes.intercomExtension}>
                <Typography variant={'subtitle2'} className={classes.header}>
                  {t('baseLayout.intercom.header')}
                </Typography>
                <Typography className={classes.text} variant={'body2'}>
                  {t('baseLayout.intercom.text')}
                </Typography>
              </div>
            )}
            {showNavBar && smallScreens && (
              <AppNavBar type={Devices.DESKTOP || Devices.MOBILE || Devices.TABLET} bottom={true} />
            )}
            {/*{showNavBar && (*/}
            {/*  <div*/}
            {/*    className={classes.plusIcon}*/}
            {/*    onClick={() => {*/}
            {/*      if (currentUser) {*/}
            {/*        history.push(GeneralPaths.LogsPage);*/}
            {/*      }*/}
            {/*    }}*/}
            {/*    onKeyDown={() => {}}*/}
            {/*    tabIndex={-1}*/}
            {/*    role={'button'}*/}
            {/*    id={'logs-page-button'}*/}
            {/*  >*/}
            {/*    <PlusIcon title={'plus-icon'} />*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default BaseLayout;
