/* eslint-disable import/no-named-as-default */
import axios, {AxiosRequestConfig} from 'axios';
import history from '../history';
import {clearLocaleStorage, getTokenData} from './locale-storage-service';
import store from '../store/store';
import * as usersActionTypes from '../store/actions/users/users-types';
import SnackbarUtils from '../common/components/notistack/SnackbarUtils';
import {GeneralPaths} from './constants';
import { logoutUserApi } from '../api/users.api';

/* eslint-disable no-console */
interface Props {
  path: string;
  method?: 'get' | 'post' | 'put' | 'delete';
  requestBody?: any;
  headers?: {
    [key: string]: string;
  };
  cdn?: string;
}

const source = axios.CancelToken.source();
export const SERVER_URL = process.env.REACT_APP_API_URL;
export const STRAPI_SERVER_URL = process.env.REACT_APP_STRAPI_URL;
// const dispatch = useDispatch();
axios.interceptors.request.use((config) => {
  const tokenData = getTokenData();

  //TODO: This flag should be removed in the future when STRAPI becomes private,
  // for now auth is disabled for STRAPI CDN
  const shouldDisableAuth = config.baseURL === STRAPI_SERVER_URL;
  const shouldEnableAuth = config.baseURL === SERVER_URL;

  if (!config.headers['Authorization'] && !shouldDisableAuth) {
    const idToken: string = tokenData['idToken'] || '';
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }

  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  if (!config.headers['X-Version'] && shouldEnableAuth) {
    config.headers['X-Version'] = '2';
  }

  return config;
});

axios.interceptors?.response?.use(
  (response) => {
    const {location} = history;

    if (response?.status >= 200 && response?.status < 300) {
      if (location.pathname === GeneralPaths.LandingPage.toString()) {
        const {search} = location;

        if (search) {
          const params = new URLSearchParams(search);
          history.replace(params.get('redirect') || '');
        }
      }
    }

    return response;
  },
  (error) => {
    try {
      // console.log('Request Failed:', error.config);
      if (error?.response) {
        // Request was made but server responded with something
        // other than 2xx
        // console.log('Status:', error?.response?.status);
        // console.log('Data:', error?.response?.data);
        // console.log('Headers:', error?.response?.headers);

        //backend error
        let message = '';
        const errors = error?.response?.data?.errors;
        if (errors) {
          for (const propertyKey of Object.keys(errors)) {
            message += propertyKey + ': ' + errors[propertyKey] + '; ';
          }

          SnackbarUtils.error(message);
        }

        const simpleErrors = error?.response?.data?.error;
        if (simpleErrors) {
          SnackbarUtils.error(simpleErrors?.message || simpleErrors);
        }
      } else {
        // Something else happened while setting up the request
        // triggered the error
        // console.log('Error Message:', error?.message);
        throw error;
      }
      //
      //  console.log("error?.response",error?.response?.status);
      // const {
      //   response: {status},
      // } = error?.response || {};
      const {location} = history;
      if (error?.response?.status === undefined || error?.response?.status  === 0) {
        store.dispatch({
          type: usersActionTypes.STATUS_ERROR,
          payload: {isError: true, data: 'statusNotDefined'},
        });
      }
      if (error?.response?.status ) {
        if (error?.response?.status  === 404) {
          const url = new URL(error?.response?.config?.url);

          const hashCode = url.searchParams.get('hashCode');
          if (hashCode) {
            history.push(GeneralPaths.LandingPage);
          } else {
            throw error(error);
            // history.push('/not-found');
          }
        } else if (error?.response?.status  === 500) {
          history.push('/error');
        } else if (error?.response?.status  === 401) {
          if (![GeneralPaths.LandingPage.toString()].includes(location.pathname)) {
            logoutUserApi();
            setTimeout(() => {
              clearLocaleStorage();
              store.dispatch({type: usersActionTypes.LOGOUT, payload: null}); 
              history.push(GeneralPaths.LandingPage);
            }, 100);
          } else {
            history.push(GeneralPaths.LandingPage);
          }
        } else if (error?.response?.status  === 403) {
          history.push('/forbidden');
        }
      } else {
        // console.log('Error Message:', error);
        clearLocaleStorage();
        store.dispatch({type: usersActionTypes.LOGOUT, payload: null});
        throw error(error);
      }
      return Promise.reject(error);
    } catch (error) {
      // console.log('Request Failed:', error);
      
      // store.dispatch({
      //   type: usersActionTypes.STATUS_ERROR,
      //   payload: {isError: true, data: 'statusCaughtError'},
      // });
       return Promise.reject(error);
       
    }
  },
);

// eslint-disable-next-line
export default async ({
  path,
  method = 'get',
  requestBody,
  headers = {},
  cdn = SERVER_URL,
}: Props) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const url: string = cdn + path;
    const config: AxiosRequestConfig = {
      baseURL: cdn,
      url,
      method,
      cancelToken: method === 'get' ? source.token : undefined,
      headers,
      data: requestBody,
    };
    return await axios(config);
  } catch (err) {
    throw err;
  }
};

// eslint-disable-next-line
export const handleRequestError = (error: any, callback: (message: string) => void): any => {
  const {
    response: {data},
  } = error;

  if (callback) {
    let message = error.message;

    if (data) {
      const {errors} = data;

      if (errors) {
        message = '';

        for (const propertyKey of Object.keys(errors)) {
          message += propertyKey + ': ' + errors[propertyKey] + '; ';
        }
      }
    }

    callback(message);
  }
};
