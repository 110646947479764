import React from 'react';
import {isMobile} from 'react-device-detect';
import {Paper, Typography, useMediaQuery} from '@material-ui/core';
import {Button} from '../button/Button';
import {ReminderType, FriendlyReminderType} from '../../../utils/enums';
import {TABLET_BIG_SIZE} from '../../../utils/constants';

import useStyles from './reminder.styles';

interface IReminderProps {
  title: string;
  titleIcon: string;
  content: string;
  buttonText: string;
  color: string;
  reminderType: ReminderType | FriendlyReminderType;
  handleClick: () => void;
}

const Reminder: React.FC<IReminderProps> = (props) => {
  const isMobileSize = useMediaQuery(`(max-width:${TABLET_BIG_SIZE}px)`, { noSsr: true })
  const isMobileOrTablet = React.useMemo(() => {
    return  isMobile || isMobileSize
  }, [window.innerWidth])
  const classes = useStyles({
    isMobileOrTablet: isMobileOrTablet
  });
  const {title, titleIcon, content, buttonText, handleClick, color, reminderType} = props;

  return (
    <Paper className={classes.card} elevation={0} style={{background: `${color}`}}>
      <div className={classes.header}>
        <img
          className={classes.headerIcon}
          src={titleIcon}
          alt={reminderType.toString() + '-icon'}
        />
        <Typography variant={'subtitle2'}>{title}</Typography>
      </div>

      <Typography variant={'body1'} className={classes.content}>
        {content}
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          id={`reminder-button-id-${title}`}
          className={classes.button}
          variant="outlined"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </div>
    </Paper>
  );
};

export default Reminder;
