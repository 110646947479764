import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import {composeWithDevTools} from 'redux-devtools-extension';

const middlewares: Array<any> = [thunkMiddleware];
const enhancers = composeWithDevTools(applyMiddleware(...middlewares));

const store = createStore(rootReducer, enhancers);

export default store;
