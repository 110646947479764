/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react';
import Loader from '../../common/components/loader/Loader';
import {makeStyles} from '@material-ui/core/styles';
import {logOut} from '../../store/actions/users/users-action';
import {useDispatch} from 'react-redux';
import {useIntercom} from 'react-use-intercom';
import {useCookies} from 'react-cookie';

const useStyles = makeStyles(() => ({
  loaderFixation: {
    height: 'calc(100% - 160px)',
  },
}));

const LogOutPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {hardShutdown} = useIntercom();
  // const [cookies, setCookie, removeCookie] = useCookies([
  //   'intercom-id-wmtiazo4',
  //   'intercom-session-wmtiazo4',
  //   'CloudFront-Key-Pair-Id',
  //   'CloudFront-Policy',
  //   'CloudFront-Signature',
  // ]);
  const [, removeCookie] = useCookies([
    'intercom-id-wmtiazo4',
    'intercom-session-wmtiazo4',
  ]);
  // const clearAllCookies = () => {
  //   const cookies = document.cookie.split(';');
  //   for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i];
  //     const eqPos = cookie.indexOf('=');
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  //   }
  // };
  // const domain ="betrhealth.com"
  // const fetchCookies = async () => {
  //   try {
  //      // Fetch your cookies from the API
     
  //     // Set each cookie
  //     setCookie("CloudFront-Key-Pair-Id", '', {
  //       path: '/',
  //       domain,
  //       maxAge: 0
  //     });
  //     setCookie("CloudFront-Policy", '', {
  //       path: '/',
  //       domain,
  //       maxAge: 0
  //     });
  //     setCookie("CloudFront-Signature", '', {
  //       path: '/',
  //       domain,
  //       maxAge: 0
  //     });
  //   } catch (error) {
  //     console.error("Error fetching cookies:", error, cookies);
  //   }
  // };

  useEffect(() => {
    hardShutdown();
    // clearAllCookies();
    // sessionStorage.clear();
    // removeCookie('intercom-id-wmtiazo4', {
    //   path: '/',
    //   domain,
    //   // maxAge: 0
    // });
    // removeCookie('intercom-session-wmtiazo4',  {
    //   path: '/',
    //   domain,
    //   // maxAge: 0
    // });
    removeCookie('intercom-id-wmtiazo4', '');
     removeCookie('intercom-session-wmtiazo4', '');
    // removeCookie('CloudFront-Key-Pair-Id',{
    //   path: '/',
    //   domain,
    //   // maxAge: 0
    // });
    // removeCookie('CloudFront-Policy',  {
    //   path: '/',
    //   domain,
    //   // maxAge: 0
    // });
    // removeCookie('CloudFront-Signature',  {
    //   path: '/',
    //   domain,
    //   // maxAge: 0
    // });
    //  fetchCookies();
    
    dispatch(logOut());
  }, []);

  return (
    <React.Fragment>
      <Loader className={classes.loaderFixation} />
    </React.Fragment>
  );
};

LogOutPage.displayName = 'LogOutPage';
export default LogOutPage;
