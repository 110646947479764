// import { useEffect } from 'react';
// import moment from 'moment';
// import { logoutUserApi } from '../../api/users.api';
// import { clearLocaleStorage } from '../../utils/locale-storage-service';
// import * as usersActionTypes from '../../store/actions/users/users-types';
// import { GeneralPaths } from '../../utils/constants';
// import store from '../../store/store';
// import history from '../../history';

// const useIdleTimeout = (timeOutInterval = 1800000) => {
//   const events = ['click', 'scroll', 'load', 'keydown'];
//   let timer: any | undefined = undefined;
//   const startTimer = () => {
//     timer = setTimeout(() => {
//       const lastInteractionTime = localStorage.getItem('lastInteractionTime');
//       const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
//       console.log("lastInteractionTime", diff, lastInteractionTime);

//       if (diff._milliseconds < timeOutInterval) {
//         startTimer();
//       } else {
//         // Perform your logout action here
//        const token = localStorage.getItem('accessToken');
//         if(token){
//           logoutUserApi();
//           setTimeout(() => {
//             clearLocaleStorage();
//             store.dispatch({type: usersActionTypes.LOGOUT, payload: null});
//              history.push(GeneralPaths.LandingPage);
//           }, 100);
//         }

//       }
//     }, timeOutInterval);
//   };

//   const eventHandler = () => {
//     localStorage.setItem('lastInteractionTime', moment());
//     if (timer) {
//       startTimer();
//     }
//   };

//   const addEvents = () => {
//     events.forEach(eventName => {
//       window.addEventListener(eventName, eventHandler);
//     });
//     startTimer();
//   };

//   const removeEvents = () => {
//     events.forEach(eventName => {
//       window.removeEventListener(eventName, eventHandler);
//     });
//   };

//   useEffect(() => {
//     addEvents();

//     return () => {
//       removeEvents();
//     };
//   }, []);
// };

// export default useIdleTimeout;

import moment from 'moment';
import {useState, useEffect} from 'react';
import {getTokenData} from '../../utils/locale-storage-service';
import {logoutUserApi} from '../../api/users.api';
import {GeneralPaths} from '../../utils/constants';
import {useHistory} from 'react-router';

interface ActivityTrackerProps {
  timeoutInSeconds: number; // Define the period of inactivity timeout in seconds
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useIdleTimeout = ({timeoutInSeconds}: ActivityTrackerProps) => {
  const history = useHistory();
  const [isActive, setIsActive] = useState(true);
  // Convert epochs to minutes

  // Converting Epoch timestamp
  const getMinutes = (epoch: number) => Math.floor((epoch % 3600) / 60);
  const tokenData = getTokenData();
  const token = tokenData.idToken;
  // Handel Logout 
  const onLogout = () => {
    // console.log('User logged out due token', token);
    if (token !== null) {
      // console.log('User logged out due to logout Successfully');
      history?.replace({
        pathname: `${GeneralPaths.LandingPage}`,
      });
      logoutUserApi();
     
      // localStorage.clear();
    }
  };
  useEffect(() => {
    let inactivityTimer: number | null = null;
    const resetTimer = () => {
      if (inactivityTimer !== null) {
        clearTimeout(inactivityTimer);
      }
      const checkLastActive = () => {
        const lastActiveString = localStorage.getItem('lastActive') || '1';
        // Convert the string back to a number
        const lastActive = parseInt(lastActiveString ? lastActiveString : '1'); 
        const nowMinutes = moment().unix(); // now in minutes
        const diffMinutes = getMinutes(nowMinutes - lastActive);
        // Difference in minutes
        // const diffMins = diffMinutes/1000
        // console.log(
        //   'Time difference in minutes:',
        //   diffMinutes,
        //   'nowMinutes',
        //   nowMinutes,
        //   'lastActive',
        //   lastActive,
        // );

        if (diffMinutes > 20) {
          onLogout();
        }
      };

      inactivityTimer = window.setTimeout(() => {
        checkLastActive(); // Call the logout function when inactivity period is reached
        setIsActive(false);
      }, timeoutInSeconds * 1000);
    };

    const handleActivity = () => {
      setIsActive(true);
      const activeTime = moment().unix();
      localStorage.setItem('lastActive', activeTime);
      resetTimer();
    };

    // Add event listeners to track user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Initial timer setup
    resetTimer();

    // Cleanup when the component unmounts
    return () => {
      if (inactivityTimer !== null) {
        clearTimeout(inactivityTimer);
      }
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [ timeoutInSeconds  ]);

  return isActive;
};

export default useIdleTimeout;
