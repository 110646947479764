import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import { Typography } from '@material-ui/core';
import ContentDialogProfileContent from './ContentDialogProfileContent';
import DataItem from './DataItem';
import {Button} from '../button/Button';
import { ContentDialog } from '../dialog/ContentDialog';
import Loader from '../loader/Loader';
import * as homepageActions from '../../../store/actions/homepage/homepage-action';
import { searchCoachUsers } from '../../../store/actions/users/users-action';
import {
  checkIfUserIsMember,
  checkIfUserIsTrainer,
  checkIfUserIsSuperCoach,
  checkIfUserHasHigherRoles,
} from '../../../utils/users-managment';
import {hasAdminRoles} from '../../../utils/role-helper';
import {addDaysToDate, dateToString} from '../../../utils/date-time-utils';
import {
  selectAllUsers,
  selectCoachUsers,
  selectCurrentUser,
} from '../../../store/selectors/users.selectors';
import { IKeyValuePair } from '../../../models/IKeyValuePair';
import {ISecondaryCoachAssignDto} from '../../../dtos/ISecondaryCoachAssignDto';

import useStyles from './coaches-item-styles';

interface IData {
  primaryCoach: IKeyValuePair;
  secondaryCoach: IKeyValuePair;
  startDate: string | null | Date;
  endDate: string | null | Date;
}

interface ICoachingItemProps {
  user: any;
  myProfile: boolean;
  isMobile: boolean;
}

/*eslint-disable @typescript-eslint/no-empty-function*/
const CoachingItem: React.FC<ICoachingItemProps> = (props) => {
  const { user, myProfile, isMobile } = props;
  const classes = useStyles({
    isMobile
  });
  const [t] = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const allUsers = useSelector(selectAllUsers);
  const coachMembers = useSelector(selectCoachUsers);
  const [coaches, setCoaches] = useState<ISecondaryCoachAssignDto[]>([]);
  const [open, setOpen] = React.useState(false);
  const [personalCoachContentDialog, setPersonalCoachContentDialog] = useState(false);
  const [data, setData] = useState<IData>({
    primaryCoach: {key: '', value: ''},
    secondaryCoach: {key: '', value: ''},
    startDate: null,
    endDate: null,
  });
  const [assignedToPrimaryCoach, setAssignedToPrimaryCoach] = useState<ISecondaryCoachAssignDto[]>(
    [],
  );
  const [numberOfSecondaryMembers, setNumberOfSecondaryMembers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getSecondaryCoaches = async () => {
    if (user) {
      const res = await homepageActions.getSecondaryCoaches(user?.id);
      const data = await res;
      let deleted = false;
      data.forEach((coach) => {
        if (coach.deleted) deleted = true;
      });
      if (deleted) return null;
      else {
        const sum = data.reduce((sum, item) => sum + (item?.countMemberAssigned || 0), 0);
        setNumberOfSecondaryMembers(sum);
        return data;
      }
    }
    return null;
  };
  const getSecondaryCoach = async () => {
    const data = await homepageActions.getOneSecondaryCoach(user?.id);
    if (data.deleted) return null;
    return data;
  };

  const getSecondaryAssignedToMe = async () => {
    const data = await homepageActions.getMySecondaryAssign(user.id);
    if (data?.length > 0 && data[0].deleted) return [];
    if (data) return data;
    return [];
  };

  const loadData = async () => {
    setIsLoading(true);
    if (
      ((checkIfUserIsSuperCoach(currentUser) || hasAdminRoles(currentUser)) &&
        checkIfUserIsTrainer(user)) ||
      (checkIfUserHasHigherRoles(currentUser, false) && myProfile)
    ) {
      const data = await getSecondaryCoaches();
      if (data) {
        setCoaches(data);
      }
    } else {
      const data = await getSecondaryCoach();
      const arr = [];
      if (data) {
        arr.push(data);
      }
      setCoaches(arr);
    }
    if (checkIfUserHasHigherRoles(currentUser, true)) {
      const assigned = await getSecondaryAssignedToMe();
      setAssignedToPrimaryCoach(assigned);
    }
    setIsLoading(false);
  };

  const reloadUser = async () => {
    await dispatch(homepageActions.selectMember(user.id));
  };

  useEffect(() => {
    if (user) {
      loadData();
      if (
        checkIfUserIsSuperCoach(currentUser) &&
        user.id != currentUser.id &&
        checkIfUserIsTrainer(user)
      ) {
        dispatch(searchCoachUsers({trainerId: user.id}));
      }
    }
  }, [user]);

  useEffect(() => {
    if (checkIfUserHasHigherRoles(currentUser, false) && myProfile) {
      const initialObject = {
        primaryCoach: {
          key: user?.trainer?.id || '',
          value: user?.trainer?.firstName + ' ' + user?.trainer?.lastName || '',
        },
        secondaryCoach: {
          key: coaches?.length > 0 ? coaches[0].secondaryCoachId : '',
          value: coaches?.length > 0 ? coaches[0].fullName || '' : '',
        },
        startDate:
          coaches?.length > 0 ? coaches[0].startDate : addDaysToDate(new Date().toString(), 1),
        endDate: coaches?.length > 0 ? coaches[0].endDate : addDaysToDate(new Date().toString(), 1),
      };
      setData(initialObject);
    }
  }, [user, coaches, allUsers, coachMembers, assignedToPrimaryCoach]);

  const handleClickDismiss = () => {
    setOpen(false);
    setPersonalCoachContentDialog(false);
  };

  const handleEndNow = async () => {
    await dispatch(homepageActions.endNowSecondaryCoach({primaryCoachId: user?.id}));
    loadData();
  };

  const checkIfAllUsersHaveSecondaryCoach = () => {
    return (
      checkIfUserHasHigherRoles(user, true) &&
      numberOfSecondaryMembers !== (coachMembers?.length > 0 ? coachMembers?.length : allUsers?.length)
    );
  };

  const renderButtonForSecondaryCoach = () => {
    if (
      (checkIfUserIsSuperCoach(currentUser) || coaches[0]?.primaryCoachId === currentUser.id) &&
      coaches?.length > 0 &&
      coaches[0].isActiveSecondaryCoach && !checkIfUserIsMember(user)
    ) {
      return (
        <Button
          variant={'outlined'}
          className={classes.editButton}
          id={'secondary-coach-endNow'}
          onClick={() => handleEndNow()}
        >
          {t('Homepage.quickProfileUser.endNowButton')}
        </Button>
      );
    }

    if (
      (checkIfUserIsTrainer(user) &&
        numberOfSecondaryMembers ===
          (coachMembers?.length > 0 ? coachMembers?.length : allUsers?.length)) ||
      (checkIfUserHasHigherRoles(currentUser, true) &&
        checkIfUserIsMember(user) &&
        coaches?.length > 0) ||
      (checkIfUserIsMember(user) && checkIfUserIsSuperCoach(currentUser))
    ) {
      return (
        <Button
          variant={'outlined'}
          className={classes.editButton}
          id={'secondary-coach-edit'}
          onClick={() => setOpen(true)}
        >
          {t('Homepage.quickProfileUser.editButton')}
        </Button>
      );
    }

    return <></>;
  };

  const renderSecondaryCoachesData = () => {
    if (coaches?.length > 0) {
      return coaches.map((item) => (
        <div className={classes.rowContainer} key={item.id}>
          <DataItem
            label={t('Homepage.quickProfileUser.secondaryCoachSelectLabel')}
            value={item ? item.fullName : null}
            itemClassName={classes.secondaryCoachName}
          />
          {!checkIfUserIsMember(user) && (
            <DataItem
              label={t('Homepage.quickProfileUser.members')}
              value={item.countMemberAssigned}
            />
          )}
          <DataItem
            label={t('Homepage.quickProfileUser.startDate')}
            value={item && item.startDate && dateToString(item.startDate)}
          />
          <DataItem
            label={t('Homepage.quickProfileUser.endDate')}
            value={item && item.endDate && dateToString(item.endDate)}
          />
        </div>
      ));
    }
    if (checkIfUserIsMember(user)) {
      return (
        <div className={classes.rowContainer}>
          <DataItem
            label={t('Homepage.quickProfileUser.secondaryCoachSelectLabel')}
            value={data.secondaryCoach?.key != '' ? data.secondaryCoach.value : null}
            itemClassName={classes.secondaryCoachName}
          />
          <DataItem
            label={t('Homepage.quickProfileUser.startDate')}
            value={data.secondaryCoach?.key != '' && data.startDate && dateToString(data.startDate)}
          />
          <DataItem
            label={t('Homepage.quickProfileUser.endDate')}
            value={data.secondaryCoach?.key != '' && data.endDate && dateToString(data.endDate)}
          />
        </div>
      );
    }
    return <></>;
  };

  return isLoading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <div className={classes.titleContainer}>
        {checkIfUserIsMember(user) ? (
          <Typography variant={'subtitle1'} className={classes.rowContainer}>
            {t('Homepage.quickProfileUser.coachingTitle')}
          </Typography>
        ) : (
          <div className={classes.membersInfo}>
            <Typography variant={'subtitle1'} className={classes.rowContainer}>
              {t('Homepage.quickProfileUser.connectionsTitle')}
            </Typography>
            <Typography variant={'overline'}>
              {t('Homepage.quickProfileUser.primaryClients')}
            </Typography>
            <Typography variant={'caption'} className={classes.captionMembers}>
              {t('Homepage.quickProfileUser.members')}
            </Typography>
            <Typography variant={'body2'}>
              {coachMembers?.length > 0 ? coachMembers?.length : allUsers?.length}
            </Typography>
          </div>
        )}
      </div>

      <div className={classes.rowContainer}>
        <DataItem
          label={t('Homepage.quickProfileUser.personalCoach')}
          value={user?.trainer ? user?.trainer?.firstName + ' ' + user?.trainer?.lastName : null}
        />
        {(checkIfUserIsSuperCoach(currentUser) || hasAdminRoles(currentUser)) && user?.trainer && (
          <Button
            id={'edit-personal-coach'}
            variant={'outlined'}
            onClick={() => setPersonalCoachContentDialog(true)}
          >
            {t('Homepage.quickProfileUser.changePersonalCoach')}
          </Button>
        )}
      </div>
      <React.Fragment>
        {checkIfUserIsTrainer(currentUser) && coaches?.length > 0 && myProfile && (
          <Typography variant={'overline'} className={classes.rowContainerTop}>
            {t('Homepage.quickProfileUser.connectionsSubtitleAppointed')}
          </Typography>
        )}
        <div className={classes.rowContainer}>
          <div className={classes.columns}>{renderSecondaryCoachesData()}</div>
          {renderButtonForSecondaryCoach()}
        </div>
      </React.Fragment>
      {checkIfAllUsersHaveSecondaryCoach() && (
        <React.Fragment>
          <Typography variant={'overline'} className={classes.rowContainerTop}>
            {t('Homepage.quickProfileUser.connectionsSubtitle')}
          </Typography>
          <Button
            id={'choose-secondary-coach-button-profile'}
            variant={'outlined'}
            className={classes.chooseSecondary}
            onClick={() => setOpen(true)}
          >
            {t('Homepage.quickProfileUser.secondaryCoachButton')}
          </Button>
        </React.Fragment>
      )}
      {checkIfUserHasHigherRoles(user, false) &&
        assignedToPrimaryCoach?.length > 0 &&
        assignedToPrimaryCoach.map((item) => (
          <React.Fragment key={item.id}>
            <Typography variant={'overline'} className={classes.rowContainerTop}>
              {t('Homepage.quickProfileUser.appointedAsSecondaryCoach')}
            </Typography>
            <div className={classes.rowContainer}>
              <DataItem
                label={t('Homepage.quickProfileUser.personalCoach')}
                value={item.fullName || null}
                itemClassName={classes.secondaryCoachName}
              />

              <DataItem
                label={t('Homepage.quickProfileUser.members')}
                value={item.countMemberAssigned}
              />

              <DataItem
                label={t('Homepage.quickProfileUser.startDate')}
                value={item.startDate && dateToString(item.startDate)}
              />
              <DataItem
                label={t('Homepage.quickProfileUser.endDate')}
                value={item.endDate && dateToString(item.endDate)}
              />
            </div>
          </React.Fragment>
        ))}
      <ContentDialog
        handleDismiss={handleClickDismiss}
        dialogState={open}
        className={classes.contentDialog}
        content={
          <ContentDialogProfileContent
            user={user}
            setOpen={setOpen}
            myProfile={myProfile}
            reloadData={() => loadData()}
            initialData={
              (checkIfUserIsMember(user) && coaches.length > 0) ||
              (coaches.length > 0 &&
                numberOfSecondaryMembers ===
                  (coachMembers?.length > 0 ? coachMembers?.length : allUsers?.length))
                ? coaches[0]
                : data
            }
            secondaryCoach={true}
            isMobile={isMobile}
          />
        }
        applyMaxWidthFalse={true}
      />
      <ContentDialog
        handleDismiss={handleClickDismiss}
        dialogState={personalCoachContentDialog}
        className={classes.contentDialog}
        content={
          <ContentDialogProfileContent
            user={user}
            setOpen={setPersonalCoachContentDialog}
            primaryCoach={user?.trainer}
            myProfile={myProfile}
            reloadData={() => reloadUser()}
            secondaryCoach={false}
            isMobile={isMobile}
          />
        }
        applyMaxWidthFalse={true}
      />
    </React.Fragment>
  );
};

export default CoachingItem;
