import * as React from 'react';
import {Backdrop, Box, Modal, Fade} from '@material-ui/core';
import useStyles from './DisclosureAgreement.styles';
import { Button} from '../../button/Button';
import LetsStart from '../lets-start/LetsStart';
import { useDispatch, useSelector } from 'react-redux';
import { postKemTaiUserWorkoutHistory } from '../../../../store/actions/kemtai/kemtai-action';

interface Props {
    isDisclosure: boolean;
    setIsDisclosure: (status: boolean) => void;
    // setIsAgreement: (status: boolean) => void;
    selectedWorkoutID: any;
    setBannerWorkout?: (a:any) => void
  }

const DisclosureAgreement: React.FC<Props> = ({isDisclosure, setIsDisclosure, selectedWorkoutID, setBannerWorkout}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [isLetsStart, setIsLetsStart] = React.useState(false);

    const dispatch = useDispatch();
    const userData: any = useSelector((state: any) => state.users.currentUser)
    
    const handleClose = () => {
        setOpen(false);
        setIsDisclosure(false)
    }
    const handleContinue = () => {
        dispatch(postKemTaiUserWorkoutHistory({
            UserID: userData.id,
            WorkoutGroupID: selectedWorkoutID,
            DisclosureAgreement: true
        })).then((res) => {
            if(res){
                setIsDisclosure(true)
                setOpen(false);
                setIsDisclosure(false)
                // setIsAgreement(true)
                setIsLetsStart(true)
                setBannerWorkout([res])
            }
        })
        // selectedWorkoutID
    }

    React.useEffect(() => {
        setOpen(isDisclosure)
    }, [isDisclosure])

    return (
    <div>
        {/* <Button id="disclosure-agreement-button" onClick={()=>setOpen(true)}>Disclosure Agreement</Button> */}
        <LetsStart isLetsStart={isLetsStart} setIsLetsStart={setIsLetsStart} selectedWorkoutID={selectedWorkoutID} />
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Box>
            <Fade in={open}>
                <Box className={classes.modalContainr}>
                    <h3>Liablility Waiver</h3>
                    <p>By clicking continue, you agree that you are voluntarily participating in physical exercise and also agree to the content stated in our <a href="https://betrhealth.com/liability-waiver/" target="_blank" rel="noreferrer">Liability Waiver</a>.</p>
                    <div className='footer-buttons'>
                        <Button onClick={handleClose} id="no-thanks" variant="text">No, Thanks</Button>
                        <Button onClick={handleContinue} id="continue">Continue</Button>
                        </div>
                </Box>
            </Fade>
          </Box>
        </Modal>
        </div>
    );
}



export default DisclosureAgreement;