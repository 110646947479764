import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {format} from 'date-fns';
import {Typography} from '@material-ui/core';
import {progressOptions} from './ProgressOptions';
import {AverageValue} from './AverageValue';
import {BaseAreaChart} from '../charts/BaseAreaChart';
import {SimpleScatterChart} from '../charts/SimpleScatterChart';
import {SimpleBarChart} from '../charts/SimpleBarChart';
import {CustomizedDotLineChart} from '../charts/CustomizedDotLineChart';
import Loader from '../loader/Loader';
import {IUser} from '../../../models/IUser';
import {ProgressType} from '../../../utils/enums';
import {ProgressModifier} from '../../../utils/modifiers';
import {DATE_LONGER_FORMAT} from '../../../utils/constants';
import {convertTime12to24} from '../../../utils/date-time-utils';

import useStyles from './progress-chart.styles';
import {useSelector} from 'react-redux';
import {selectInsights} from '../../../store/selectors/homepage.selectors';

interface Props {
  unit?: string;
  user?: IUser;
  type: ProgressType;
  fromDate: Date;
  toDate: Date;
  logs: any;
  isSelected: boolean;
  isMobile: boolean;
  includeStartWeight: boolean;
}

const ProgressChart: React.FC<Props> = ({
  type,
  fromDate,
  toDate,
  logs,
  isSelected,
  isMobile,
  includeStartWeight,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState<any>([]);
  const userInsights = useSelector(selectInsights);

  const sumLogsByDay = (logs: any) => {
    const time = type === ProgressType.FOOD || type === ProgressType.BOWEL_MOVEMENT;
    const groupedLogs = ProgressModifier.modifyData(logs, type, time);
    setData(groupedLogs);
  };

  useEffect(() => {
    if (type === ProgressType.BODY_WEIGHT && (includeStartWeight || logs?.length === 0))
      logs.push({value: userInsights?.initialWeight, date: userInsights?.initialWeightDate});
    if (logs?.length > 0) {
      sumLogsByDay(logs);
    } else {
      setData([]);
    }
  }, [logs]);

  const addTimeFiled = () => {
    const convertedData: any = [].concat(data);
    for (let i = 0; i < data?.length; i++) {
      convertedData[i].value = convertTime12to24(data[i].time);
    }
    return convertedData;
  };

  const handleChart = () => {
    switch (type) {
      case ProgressType.BODY_WEIGHT:
        return (
          <BaseAreaChart data={data} options={progressOptions.bodyWeight} isMobile={isMobile} />
        );
      case ProgressType.FOOD:
        return (
          <SimpleScatterChart
            type={type}
            data={addTimeFiled()}
            options={progressOptions.food}
            isMobile={isMobile}
          />
        );
      case ProgressType.WATER:
        return (
          <SimpleBarChart
            type={type}
            data={data}
            options={progressOptions.water}
            isMobile={isMobile}
          />
        );
      case ProgressType.SLEEP:
        return (
          <SimpleBarChart
            type={type}
            data={data}
            options={progressOptions.sleep}
            isMobile={isMobile}
          />
        );
      case ProgressType.ACTIVITY:
        return (
          <SimpleBarChart
            type={type}
            data={data}
            options={progressOptions.activity}
            isMobile={isMobile}
          />
        );
      case ProgressType.STEPS:
        return (
          <SimpleBarChart
            type={type}
            data={data}
            options={progressOptions.steps}
            isMobile={isMobile}
          />
        );
      case ProgressType.MENTAL_HEALTH:
        return (
          <CustomizedDotLineChart
            data={data}
            options={progressOptions.mentalHealth}
            isMobile={isMobile}
          />
        );
      case ProgressType.BOWEL_MOVEMENT:
        return (
          <SimpleScatterChart
            type={type}
            data={addTimeFiled()}
            options={progressOptions.bowelMovement}
            isMobile={isMobile}
          />
        );
      case ProgressType.BLOOD_GLUCOSE:
        return (
          <SimpleScatterChart
            type={type}
            data={data}
            options={progressOptions.bloodGlucose}
            isMobile={isMobile}
          />
        );

      default:
        return <></>;
    }
  };

  return data ? (
    <React.Fragment>
      <div className={classes.average}>
        <Typography variant={'caption'} className={classes.caption}>
          {t('Homepage.progress.average')}
        </Typography>
        <AverageValue
          logs={logs}
          type={type}
          fromDate={fromDate}
          toDate={toDate}
          isSelected={isSelected}
        />
        <Typography variant="caption" className={classes.caption}>
          {format(fromDate, DATE_LONGER_FORMAT).slice(0, -4)} - {format(toDate, DATE_LONGER_FORMAT)}
        </Typography>
      </div>
      <div className={classes.chart}>{handleChart()}</div>
    </React.Fragment>
  ) : (
    <Loader />
  );
};

export default ProgressChart;
