import * as React from 'react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {isSafari} from 'react-device-detect';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Box, Typography} from '@material-ui/core';
import {AppNavBarItem} from './AppNavBarItem';
import BetterHealthIcon from '../../../assets/icons/betr-health-logo.svg';
import {ReactComponent as HomeIcon} from '../../../assets/icons/app-nav-bar/home-icon.svg';
import {ReactComponent as CommunityIcon} from '../../../assets/icons/app-nav-bar/community-icon.svg';
import {ReactComponent as MarketIcon} from '../../../assets/icons/app-nav-bar/market-icon.svg';
// import {ReactComponent as ProfileIcon} from '../../../assets/icons/app-nav-bar/profile-icon.svg';
// import {ReactComponent as MealPlanIcon} from '../../../assets/icons/app-nav-bar/meal-plan-icon.svg';
import {ReactComponent as PlusIcon} from '../../../assets/icons/app-nav-bar/plus-icon.svg';
import {ReactComponent as DiscoverIcon} from '../../../assets/icons/app-nav-bar/discover-icon.svg';
import {GeneralPaths} from '../../../utils/constants';
import {NavBarSelectedType} from '../../../utils/enums';
import {hasGeneralRoles} from '../../../utils/role-helper';
import {getUrlForCommunity} from '../../../utils/community-helpers';
// import {addEngagementPointDatabase} from '../../../api/engagement.api';

import useStyles from './app-nav-bar-web.styles';
import {IResponsiveSize} from '../../../models/IResponsiveSize';

interface Props {
  selectNavBarItem: any;
  selectedNavBarItem: any;
  currentUser: any;
  isMobileOrTablet: IResponsiveSize;
  callLogOut: () => void;
}

export const AppNavBarWeb: React.FC<Props> = (props) => {
  const smallScreens =
    props.isMobileOrTablet.isMobile ||
    props.isMobileOrTablet.isTabletSmall ||
    props.isMobileOrTablet.isTabletBig;
  const classes = useStyles({
    isMobileOrTablet: props.isMobileOrTablet,
    smallScreens,
  });
  const [t] = useTranslation();

  return (
    <Fragment>
      <AppBar
        position={smallScreens ? 'fixed' : 'sticky'}
        className={smallScreens ? `${classes.smallBar}` : classes.navbar}
      >
        <Toolbar className={classes.toolbar}>
          {!smallScreens && (
            <div>
              <img src={BetterHealthIcon} alt="better health icon" />
            </div>
          )}

          {props.currentUser && hasGeneralRoles(props.currentUser) && (
            <div className={classes.navigationPartContainer} role="menu">
              <AppNavBarItem
                Icon={HomeIcon}
                iconClassName="iconHeight"
                elementType={NavBarSelectedType.NAVBAR_HOME}
                selectedNavBarItem={props.selectedNavBarItem}
                text={t('AppNavBar.home')}
                onClick={(elementType: NavBarSelectedType) =>
                  props.selectNavBarItem(elementType, GeneralPaths.HomePage)
                }
              />

              <AppNavBarItem
                Icon={CommunityIcon}
                iconClassName="iconHeight"
                elementType={NavBarSelectedType.NAVBAR_COMMUNITY}
                selectedNavBarItem={props.selectedNavBarItem}
                text={t('AppNavBar.community')}
                onClick={async (elementType: NavBarSelectedType) => {
                  if (!isSafari) {
                    props.selectNavBarItem(elementType, GeneralPaths.CommunityPage);
                  } else {
                    // setNavBarItem(elementType);
                    const url = getUrlForCommunity();
                    window.open(url, '_blank');
                  }

                  // await addEngagementPointDatabase({
                  //   eventType: EngagementPointType.COMMUNITY,
                  //   description: '',
                  // });
                }}
              />

              <AppNavBarItem
                Icon={PlusIcon}
                iconClassName="iconHeight"
                elementType={NavBarSelectedType.NAVBAR_PLUS}
                selectedNavBarItem={props.selectedNavBarItem}
                text={t('AppNavBar.dailyLogs')}
                onClick={(elementType: NavBarSelectedType) =>
                  props.selectNavBarItem(elementType, GeneralPaths.LogsPage)
                }
              />

              <AppNavBarItem
                Icon={DiscoverIcon}
                iconClassName="iconHeight"
                elementType={NavBarSelectedType.NAVBAR_NOURISH}
                selectedNavBarItem={props.selectedNavBarItem}
                text={t('AppNavBar.mealPlan')}
                onClick={async (elementType: NavBarSelectedType) => {
                  props.selectNavBarItem(elementType, GeneralPaths.NourishPage);
                  // await addEngagementPointDatabase({
                  //   eventType: EngagementPointType.COMMUNITY,
                  //   description: '',
                  // });
                }}
              />
              <AppNavBarItem
                Icon={MarketIcon}
                iconClassName="iconHeight"
                elementType={NavBarSelectedType.NAVBAR_MARKET}
                selectedNavBarItem={props.selectedNavBarItem}
                text={t('AppNavBar.market')}
                onClick={async (elementType: NavBarSelectedType) => {
                  props.selectNavBarItem(elementType, NavBarSelectedType.NAVBAR_MARKET);
                }}
              />
              {/*<AppNavBarItem*/}
              {/*  Icon={ProfileIcon}*/}
              {/*  iconClassName="iconHeight"*/}
              {/*  elementType={NavBarSelectedType.NAVBAR_PROFILE}*/}
              {/*  selectedNavBarItem={props.selectedNavBarItem}*/}
              {/*  text={t('AppNavBar.profile')}*/}
              {/*  onClick={(elementType: NavBarSelectedType) =>*/}
              {/*    props.selectNavBarItem(elementType, GeneralPaths.ProfilePage)*/}
              {/*  }*/}
              {/*/>*/}
            </div>
          )}

          {!smallScreens && props.currentUser && (
            <div id={'logOutButton'} className={classes.notificationRightPanel}>
              {/*{hasGeneralRoles(currentUser) && (*/}
              {/*  <div className={classes.badgeContainer}>*/}
              {/*    <img className={classes.badgeIcon} src={BellIcon} alt="bell icon" />*/}
              {/*    <div className={classes.infoCircle}>40</div>*/}
              {/*  </div>*/}
              {/*)}*/}

              <Box ml={4}>
                <Typography variant="button" className={classes.logOut} onClick={props.callLogOut}>
                  {t('AppNavBar.logOut')}
                </Typography>
              </Box>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};
