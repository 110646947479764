import {IBloodPressureDto} from '../dtos/IBloodPressureDto';
import {del, get, post, put} from './api-calls';

export const getBloodPressureLogs = (
  id: string,
  dateTime: string,
): Promise<IBloodPressureDto[]> => {
  return get(`/bloodpressure?UserId=${id}&from=${dateTime}`);
};

export const addBloodPressureDatabase = (data: IBloodPressureDto): Promise<IBloodPressureDto> => {
  return post('/bloodpressure', data);
};

export const editBloodPressureDatabase = (data: IBloodPressureDto): Promise<IBloodPressureDto> => {
  return put(`/bloodpressure/${data.id}`, data);
};

export const deleteBloodPressureDatabase = (id: number): Promise<any> => {
  return del(`/bloodpressure/${id}`);
};

export const getBloodPressureLogById = (id: number): Promise<IBloodPressureDto> => {
  return get(`/bloodpressure/${id}`);
};
export const getBloodPressureDataLogs = (
  id: string,
  fromDate: string,
  toDate: string,
): Promise<IBloodPressureDto[]> => {
  return get(`/bloodpressure?UserId=${id}&From=${fromDate}&To=${toDate}`);
};
